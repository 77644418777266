import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Button, Alert, Spinner, Input } from 'reactstrap';

import { connect } from 'react-redux';

import {
  getEmailGretingData,
  updateEmailGreetingData,
  getGiftingPrice,
  updateGiftingPrice,
  sendTestGiftingEmail,
  updatePreviewLink,
  getPreviewLink,
} from '../../../../actions/admin';

import notifications from '../../../../const/notifications';

class EmailGreeting extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    //  pendingTabData: PropTypes.shape(),
    fetchData: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
    updateGiftingPriceFunc: PropTypes.func.isRequired,
    updatePreviewLinkFunc: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
  //  dashboardTooltips: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      data: '',
      emailGreeting: '',
      emailGreetingFrom: '',
      emailGreetingFromEmail: '',
      emailGreetingSubject: '',
      giftingPrice: null,
      toEmail: '',
      preview: false,
      previewLink: null,
    // editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    const { fetchData, fetchGiftingPrice, fetchPreviewLink } = this.props;

    fetchGiftingPrice().then((res) => {
      if (res) {
        this.setState({ giftingPrice: res });
      }
    });

    fetchPreviewLink().then((res) => {
      if (res) {
        this.setState({ previewLink: res });
      }
    });

    fetchData().then((res) => {
      if (res) {
        console.log(res);

        this.setState({ emailGreeting: res.emailGreeting, emailGreetingFrom: res.emailGreetingFrom, emailGreetingFromEmail: res.emailGreetingFromEmail, emailGreetingSubject: res.emailGreetingSubject });

        //  let contentState = stateFromHTML(res);

     //   const blocksFromHtml = htmlToDraft(res);
     //   const { contentBlocks, entityMap } = blocksFromHtml;
     //   const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

      //  if (contentState) {
      //    this.setState({ editorState: EditorState.createWithContent(contentState) });
      //  }
      } else {
      //  this.setState({ editorState: EditorState.createEmpty() });
      }
    });
  }

  onChange = (editorState) => {
    this.setState({ editorState })
  };

  handleChange = (e) => {
    e.preventDefault();

    if (e.target) {
      const { name, value } = e.target;
      this.setState((prevState) => ({
        [name]: value,
        error: null,
        testError: null,
        success: null,
      }))
    }
  };

  updateData = () => {
    const { updateData, user } = this.props;
    const { emailGreeting, emailGreetingFrom, emailGreetingFromEmail, emailGreetingSubject } = this.state;


    if (emailGreeting) {
    //  let html = stateToHTML(editorState.getCurrentContent());

    //  const rawContentState = convertToRaw(editorState.getCurrentContent());

    //  const markup = draftToHtml(
    //    rawContentState
    //  );

      this.setState({ processing: true });

      let adminName;

      if (user.name || (user.firstName && user.lastName)) {
        adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
      }

      updateData(emailGreeting, emailGreetingFrom, emailGreetingFromEmail, emailGreetingSubject, adminName).then((res) => {
        if (res === 'success') {
          this.setState({ processing: null, success: true, error: null });
        } else if (res === 'error') {
          this.setState({ processing: null, success: null, error: true });
        }
      });
    }
  }

  updateGiftingPrice = () => {
    const { updateGiftingPriceFunc, user } = this.props;
    const { giftingPrice } = this.state;

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    updateGiftingPriceFunc(giftingPrice, adminName);
  }

  updatePreviewLink = () => {
    const { updatePreviewLinkFunc, user } = this.props;
    const { previewLink } = this.state;

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    this.setState({ processingPreviewLink: true });

    updatePreviewLinkFunc(previewLink, adminName).then(() => {
      this.setState({ processingPreviewLink: false });
    });
  }

  sendTestEmail = () => {
    const { sendTestEmail } = this.props;
    const { toEmail } = this.state;

    if (!toEmail) {
      this.setState({ testError: 'missingEmail' });
    }

    this.setState({ processing3: true, testError: null });

    sendTestEmail(toEmail).then(() => {
      this.setState({ processing3: false, testError: null });
    });
  }

  togglePreview = () => {
    const { preview } = this.state;

    this.setState({ preview: !preview });
  }

  render() {
    const { showNoPermission } = this.props;
    const {
      error,
      success,
      testError,
      toEmail,
    //  editorState,
      processing,
      processing2,
      processing3,
      data,
      emailGreeting,
      emailGreetingFrom,
      emailGreetingFromEmail,
      emailGreetingSubject,
      giftingPrice,
      preview,
      previewLink,
      processingPreviewLink,
    } = this.state;

    console.log('emailGreetingFromEmail', emailGreetingFromEmail);

    return (
      <div>
        {(!!success) && <Alert color="success">{notifications.admin.contentManagement.updateSuccessful}</Alert>}
        {(!!error) && <Alert color="danger">{notifications.admin.contentManagement.updateFailed}</Alert>}
        {(!!testError) && <Alert color="danger">{notifications.admin.contentManagement.updateFailed}</Alert>}
        
        <Row>
          <Col xs="3">
            <div>Bespoke email (GBP)</div>
          </Col>
          <Col sm="2">
            <Input
              type="text"
              name="giftingPrice"
              id="giftingPrice"
              value={giftingPrice}
              onChange={this.handleChange}
            />
          </Col>
          <Col sm="2">
            <Button disabled={showNoPermission} onClick={this.updateGiftingPrice}>
              Save
              {(processing2) ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ) : (null)}
            </Button>
          </Col>
        </Row>

        <Row>
          <Col sm="2">
            <div>From</div>
          </Col>
          <Col sm="5">
            <Input
              type="text"
              name="emailGreetingFrom"
              id="emailGreetingFrom"
              value={emailGreetingFrom}
              onChange={this.handleChange}
            />

          </Col>

          <Col sm="3">
            <Input
              type="text"
              name="emailGreetingFromEmail"
              id="emailGreetingFromEmail"
              value={emailGreetingFromEmail}
              onChange={this.handleChange}
            />
          </Col>
          <Col sm="2">
            <div style={{ paddingTop: 5, marginLeft: -10 }}>
              @app.steppr.com
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm="2">
            <div>Subject</div>
          </Col>
          <Col sm="10">
            <Input
              type="text"
              name="emailGreetingSubject"
              id="emailGreetingSubject"
              value={emailGreetingSubject}
              onChange={this.handleChange}
            />

          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <Input
              type="textarea"
              name="emailGreeting"
              id="emailGreeting"
              value={emailGreeting}
              style={{ width: '100%', height: '300px' }}
              onChange={this.handleChange }
            />

          </Col>
        </Row>

        <Button disabled={showNoPermission} onClick={this.updateData}>
          Save
          {(processing) ? (
            <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
          ) : (null)}
        </Button>

        <Input
          type="text"
          name="toEmail"
          id="toEmail"
          value={toEmail}
          placeholder="Test email"
          style={{ display: 'inline-block', width: '20%', maxWidth: 300, minWidth: 150, marginRight: 15, marginLeft: 15, paddingTop: 2, marginTop: 3 }}
          onChange={this.handleChange}
        />

        <Button disabled={showNoPermission} onClick={this.sendTestEmail}>
          Test
          {(processing3) ? (
            <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
          ) : (null)}
        </Button>

        <Button disabled={showNoPermission} onClick={this.togglePreview}>
          Preview
        </Button>

        {preview ? (
          <Row>
            <Col sm="12">
              <div>Preview</div>
              <div
                dangerouslySetInnerHTML={{ __html: emailGreeting }}
              />

            </Col>
          </Row>
        ) : (null)}

        <Row style={{ marginTop: 8 }}>
          <Col xs="3">
            <div>Preview link for gift in app</div>
          </Col>
          <Col sm="2">
            <Input
              type="text"
              name="previewLink"
              id="previewLink"
              value={previewLink}
              onChange={this.handleChange}
            />
          </Col>
          <Col sm="2">
            <Button disabled={showNoPermission} onClick={this.updatePreviewLink}>
              Save
              {(processingPreviewLink) ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ) : (null)}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  greetingEmail: state.admin.greetingEmail || null,
});

const mapDispatchToProps = {
  fetchData: getEmailGretingData,
  updateData: updateEmailGreetingData,
  updateGiftingPriceFunc: updateGiftingPrice,
  fetchGiftingPrice: getGiftingPrice,
  updatePreviewLinkFunc: updatePreviewLink,
  fetchPreviewLink: getPreviewLink,
  sendTestEmail: sendTestGiftingEmail,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailGreeting));
