
import React from 'react';
import PropTypes from 'prop-types';

import { ResponsiveContainer, XAxis, YAxis, Tooltip, BarChart, Bar, LineChart, Line, ReferenceLine, CartesianGrid, LabelList } from 'recharts';

function getTicks(array) {
  /* var factor, result, desiredSize = 10;

  for (let i=0; i < array.length; i++) {
    // calculate the delta factor
    factor = Math.floor(array[i].length / desiredSize) + 1;
    factor = (desiredSize % 2 === 0) ? --factor : factor;
    result = [];

    console.log('factor', factor);

    // iterate with the step size of the calculated facor
    for (let j = 0; j < array[i].length; j = j + factor) {
      result.push(array[i][j]);
    }
    console.log('getTicks result', result);
  } */

  const step = Math.ceil(array.length / 10);
  console.log('step', step);

  let ticks = [];

  array.map((item, index) => {
    if (index % step === 0) {
      console.log('push item', item.key);
      ticks.push(item.key);
    }
  });

  console.log('ticks', ticks);

  return ticks;
}

const renderCustomizedLabel = (props, data) => {
  const { x, y, width, value, index } = props;
  const radius = 10;

  if (index === data.length - 1 && value) {
    return (
      <g>
        <text x={x + (width || 1) / 2} y={y - radius - 1} fill="#000" textAnchor="middle" dominantBaseline="middle">
          {value}
        </text>
      </g>
    );
  }

  return null;
};

const LineGraph = React.memo(({
  data, interval, dataKey, name, grid
}) => (
  <ResponsiveContainer width='92%' height={270} marginLeft={'auto'} marginRight={'auto'} overflow={'visible'}>
    <LineChart data={data} margin={{ left: -10, right: 10 }}>
    {console.log('interval', (data.length <= 10) ? 1 : ((data.length / 10) - 1))}
      <XAxis dataKey="key" angle={-60} interval={(data.length <= 10) ? 0 : (Math.ceil(data.length / 10) - 1)} tickCount={10} textAnchor='end' tick={{ fontSize: 13 }} /> {/*interval={interval - 1}  ticks={getTicks(data)} minTickGap={1}*/}

      <YAxis width={80} padding={{ top: 12 }} tick={{ fontSize: 10 }} yAxisId="left" >
      </YAxis>

      <Tooltip />
      {grid ? (
        <CartesianGrid vertical={false} />
      ):(null)}

      <Line isAnimationActive={false} yAxisId="left" type="monotone" dataKey={dataKey} name={name} stroke="#5CB4FF" strokeWidth="2">
        <LabelList dataKey={dataKey} position="top" content={(props) => renderCustomizedLabel(props, data)} />
      </Line>
    </LineChart>
  </ResponsiveContainer>
));


LineGraph.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

LineGraph.defaultProps = {
  checked: false,
};

export default LineGraph;
