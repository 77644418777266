import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Button, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Spinner, Alert } from 'reactstrap';

import Toggle from '../../../components/Toggle/Toggle';

const CreateModal = ({
  processing, createRoleModal, closeCreateRole, createRole, acceptButtonText, headerText, handleChange, name, description, permissionsType, companyPermissions, adminPermissions, permissions, togglePermission, showDelete, openDeleteRole, showNoPermission, error,
}) => (
  <>
    <Modal size="xl" isOpen={createRoleModal} toggle={closeCreateRole}>
      <ModalHeader toggle={closeCreateRole}>
        {headerText}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            {(!!error) && <Alert color="danger">{error}</Alert>}
            <Label for="name">
              Name<div className="mandatory">*</div>
            </Label>
            <Input
              type="text"
              name="name"
              id="name"
              value={name}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              Permissions<div className="mandatory">*</div>
            </Label>
            <Row>
            {permissionsType === 'admin' ? (
              <>
                <Col xs="12" className="permissions-levels-create-highlighted">
                  Companies
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="4"> {adminPermissions.companies.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('companies', 'view')} checked={permissions ? permissions.companies : false} />
                    </Col>
                    <Col xs="2">
                      <Toggle disabled={permissions ? !permissions.companies : true} onChange={() => togglePermission('companies', 'edit')} checked={permissions ? permissions.companies === 2 : false} />
                    </Col>
                    <Col xs="4"> {(permissions && permissions.companies) ? (permissions && permissions.companies === 2) ? 'View & Edit (Excludes Company Access)' : 'View only' : 'No Access'} </Col>
                  </Row>
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="4"> {adminPermissions.campaigns.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('campaigns', 'view')} checked={permissions ? permissions.campaigns : false} />
                    </Col>
                    <Col xs="2">
                      <Toggle disabled={permissions ? !permissions.campaigns : true} onChange={() => togglePermission('campaigns', 'edit')} checked={permissions ? (permissions.campaigns === 2) : false} />
                    </Col>
                    <Col xs="4"> {(permissions && permissions.campaigns) ? (permissions && permissions.campaigns === 2) ? 'View & Edit (Excludes Company Access)' : 'View only' : 'No Access'} </Col>
                  </Row>
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="4"> {adminPermissions.companyAccess.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('companyAccess', 'view')} checked={permissions ? permissions.companyAccess : false} />
                    </Col>
                    <Col xs="2">
                      <Toggle disabled onChange={() => togglePermission('companyAccess', 'edit')} checked={false} />
                    </Col>
                    <Col xs="4"> {(permissions && permissions.companyAccess) ? 'Yes' : 'No'} </Col>
                  </Row>
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="12" className="permissions-levels-create-padded permissions-levels-create-highlighted">
                      Vouchers
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4" className="permissions-levels-create-padded"> {adminPermissions.companyAccessDashboard.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('companyAccessDashboard', 'view')} checked={permissions ? permissions.companyAccessDashboard : false} />
                    </Col>
                    <Col xs="2">
                      <Toggle disabled={permissions ? !permissions.companyAccessDashboard : true} onChange={() => togglePermission('companyAccessDashboard', 'edit')} checked={permissions ? permissions.companyAccessDashboard === 2 : false} />
                    </Col>
                    <Col xs="4"> {(permissions && permissions.companyAccessDashboard) ? (permissions && permissions.companyAccessDashboard === 2) ? 'View & Edit (Pause, Stop, Edit campaigns)' : 'View only' : 'No Access'} </Col>
                  </Row>
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="4" className="permissions-levels-create-padded"> {adminPermissions.companyAccessCreateNewVoucher.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('companyAccessCreateNewVoucher', 'view')} checked={permissions ? permissions.companyAccessCreateNewVoucher : false} />
                    </Col>
                    <Col xs="2">
                      <Toggle disabled={permissions ? !permissions.companyAccessCreateNewVoucher : true} onChange={() => togglePermission('companyAccessCreateNewVoucher', 'edit')} checked={permissions ? permissions.companyAccessCreateNewVoucher === 2 : false} />
                    </Col>
                    <Col xs="4"> {(permissions && permissions.companyAccessCreateNewVoucher) ? (permissions && permissions.companyAccessCreateNewVoucher === 2) ? 'View & Edit (excluding Personal Information)' : 'View only' : 'No Access'} </Col>
                  </Row>
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                    <Row>
                      <Col xs="4" className="permissions-levels-create-padded"> {adminPermissions.companyAccessBasicInformation.name} </Col>
                      <Col xs="2">
                        <Toggle onChange={() => togglePermission('companyAccessBasicInformation', 'view')} checked={permissions ? permissions.companyAccessBasicInformation : false} />
                      </Col>
                      <Col xs="2">
                        <Toggle disabled={permissions ? !permissions.companyAccessBasicInformation : true} onChange={() => togglePermission('companyAccessBasicInformation', 'edit')} checked={permissions ? permissions.companyAccessBasicInformation === 2 : false} />
                      </Col>
                      <Col xs="4"> {(permissions && permissions.companyAccessBasicInformation) ? (permissions && permissions.companyAccessBasicInformation === 2) ? 'View & Edit (excluding Personal Information)' : 'View only' : 'No Access'} </Col>
                    </Row>
                  </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row >
                    <Col xs="12" className="permissions-levels-create-padded">
                      All other company tabs are view only
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" className="permissions-levels-create-highlighted">
                  App customers
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="4"> {adminPermissions.customers.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('customers', 'view')} checked={permissions ? permissions.customers : false} />
                    </Col>
                    <Col xs="2">
                      <Toggle disabled={permissions ? !permissions.customers : true} onChange={() => togglePermission('customers', 'edit')} checked={permissions ? permissions.customers === 2 : false} />
                    </Col>
                    <Col xs="4"> {(permissions && permissions.customers) ? (permissions && permissions.customers === 2) ? 'View & Edit' : 'View only' : 'No Access'} </Col>
                  </Row>
                </Col>
                <Col xs="12" className="permissions-levels-create-highlighted">
                  Steppr admin
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="4"> {adminPermissions.dashboard.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('dashboard', 'view')} disabled checked={true} />
                    </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('dashboard', 'edit')} disabled checked={true} />
                    </Col>
                    <Col xs="4"> View only </Col>
                  </Row>
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="4"> {adminPermissions.admins.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('admins', 'view')} checked={permissions ? permissions.admins : false} />
                    </Col>
                    <Col xs="2">
                      <Toggle disabled={permissions ? !permissions.admins : true} onChange={() => togglePermission('admins', 'edit')} checked={permissions ? permissions.admins === 2 : false} />
                    </Col>
                    <Col xs="4"> {(permissions && permissions.admins) ? (permissions && permissions.admins === 2) ? 'View & Edit' : 'View only' : 'No Access'} </Col>
                  </Row>
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="4"> {adminPermissions.permissionLevels.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('permissionLevels', 'view')} checked={permissions ? permissions.permissionLevels : false} />
                    </Col>
                    <Col xs="2">
                      <Toggle disabled={permissions ? !permissions.permissionLevels : true} onChange={() => togglePermission('permissionLevels', 'edit')} checked={permissions ? permissions.permissionLevels === 2 : false} />
                    </Col>
                    <Col xs="4"> {(permissions && permissions.permissionLevels) ? (permissions && permissions.permissionLevels === 2) ? 'View & Edit' : 'View only' : 'No Access'} </Col>
                  </Row>
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="4"> {adminPermissions.payments.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('payments', 'view')} checked={permissions ? permissions.payments : false} />
                    </Col>
                    <Col xs="2">
                      <Toggle disabled={permissions ? !permissions.payments : true} onChange={() => togglePermission('payments', 'edit')} checked={permissions ? permissions.payments === 2 : false} />
                    </Col>
                    <Col xs="4"> {(permissions && permissions.payments) ? (permissions && permissions.payments === 2) ? 'View & Edit (Stripe link access)' : 'View only' : 'No Access'} </Col>
                  </Row>
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="4"> {adminPermissions.greetingsCards.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('greetingsCards', 'view')} checked={permissions ? permissions.greetingsCards : false} />
                    </Col>
                    <Col xs="2">
                      <Toggle disabled={permissions ? !permissions.greetingsCards : true} onChange={() => togglePermission('greetingsCards', 'edit')} checked={permissions ? permissions.greetingsCards === 2 : false} />
                    </Col>
                    <Col xs="4"> {(permissions && permissions.greetingsCards) ? (permissions && permissions.greetingsCards === 2) ? 'View & Edit' : 'View only' : 'No Access'} </Col>
                  </Row>
                </Col>
                <Col xs="12" className="permissions-levels-create-highlighted">
                  Content
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="4"> {adminPermissions.contentManagement.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('contentManagement', 'view')} checked={permissions ? permissions.contentManagement : false} />
                    </Col>
                    <Col xs="2">
                      <Toggle disabled={permissions ? !permissions.contentManagement : true} onChange={() => togglePermission('contentManagement', 'edit')} checked={permissions ? permissions.contentManagement === 2 : false} />
                    </Col>
                    <Col xs="4"> {(permissions && permissions.contentManagement) ? (permissions && permissions.contentManagement === 2) ? 'View & Edit' : 'View only' : 'No Access'} </Col>
                  </Row>
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="4"> {adminPermissions.resourceCentre.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('resourceCentre', 'view')} checked={permissions ? permissions.resourceCentre : false} />
                    </Col>
                    <Col xs="2">
                      <Toggle disabled={permissions ? !permissions.resourceCentre : true} onChange={() => togglePermission('resourceCentre', 'edit')} checked={permissions ? permissions.resourceCentre === 2 : false} />
                    </Col>
                    <Col xs="4"> {(permissions && permissions.resourceCentre) ? (permissions && permissions.resourceCentre === 2) ? 'View & Edit' : 'View only' : 'No Access'} </Col>
                  </Row>
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="4"> {adminPermissions.notifications.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('notifications', 'view')} checked={permissions ? permissions.notifications : false} />
                    </Col>
                    <Col xs="2">
                      <Toggle disabled={permissions ? !permissions.notifications : true} onChange={() => togglePermission('notifications', 'edit')} checked={permissions ? permissions.notifications === 2 : false} />
                    </Col>
                    <Col xs="4"> {(permissions && permissions.notifications) ? (permissions && permissions.notifications === 2) ? 'View & Edit' : 'View only' : 'No Access'} </Col>
                  </Row>
                </Col>
              </>
            ):(
              <>
                <Col xs="12" className="permissions-levels-create-highlighted">
                  Vouchers
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="4"> {companyPermissions.dashboard.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('dashboard', 'view')} checked={permissions ? permissions.dashboard : false} />
                    </Col>
                    <Col xs="2">
                      <Toggle disabled={permissions ? !permissions.dashboard : true} onChange={() => togglePermission('dashboard', 'edit')} checked={permissions ? permissions.dashboard === 2 : false} />
                    </Col>
                    <Col xs="4"> {(permissions && permissions.dashboard) ? (permissions && permissions.dashboard === 2) ? 'View & Edit (Pause, Stop, Edit campaigns)' : 'View only' : 'No Access'} </Col>
                  </Row>
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="4"> {companyPermissions.createNewVoucher.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('createNewVoucher', 'view')} checked={permissions ? permissions.createNewVoucher : false} />
                    </Col>
                    <Col xs="2">
                      <Toggle disabled={permissions ? !permissions.createNewVoucher : true} onChange={() => togglePermission('createNewVoucher', 'edit')} checked={permissions ? permissions.createNewVoucher === 2 : false} />
                    </Col>
                    <Col xs="4"> {(permissions && permissions.createNewVoucher) ? (permissions && permissions.createNewVoucher === 2) ? 'View & Edit' : 'View only' : 'No Access'} </Col>
                  </Row>
                </Col>
                <Col xs="12" className="permissions-levels-create-highlighted">
                  Company
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="4"> {companyPermissions.basicInformation.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('basicInformation', 'view')} checked={permissions ? permissions.basicInformation : false} />
                    </Col>
                    <Col xs="2">
                      <Toggle disabled={permissions ? !permissions.basicInformation : true} onChange={() => togglePermission('basicInformation', 'edit')} checked={permissions ? permissions.basicInformation === 2 : false} />
                    </Col>
                    <Col xs="4"> {(permissions && permissions.basicInformation) ? (permissions && permissions.basicInformation === 2) ? 'View & Edit' : 'View only' : 'No Access'} </Col>
                  </Row>
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="4"> {companyPermissions.resourceCentre.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('resourceCentre', 'view')} disabled checked={true} />
                    </Col>
                    <Col xs="2">
                      <Toggle disabled={true} onChange={() => togglePermission('resourceCentre', 'edit')} checked={false} />
                    </Col>
                    <Col xs="4"> View only </Col>
                  </Row>
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="4"> {companyPermissions.contact.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('contact', 'view')} disabled checked={true} />
                    </Col>
                    <Col xs="2">
                      <Toggle disabled={true} onChange={() => togglePermission('contact', 'edit')} checked={false} />
                    </Col>
                    <Col xs="4"> View only </Col>
                  </Row>
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="4"> {companyPermissions.payments.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('payments', 'view')} checked={permissions ? permissions.payments : false} />
                    </Col>
                    <Col xs="2">
                      <Toggle disabled={permissions ? !permissions.payments : true} onChange={() => togglePermission('payments', 'edit')} checked={permissions ? permissions.payments === 2 : false} />
                    </Col>
                    <Col xs="4"> {(permissions && permissions.payments) ? (permissions && permissions.payments === 2) ? 'View & Edit' : 'View only' : 'No Access'} </Col>
                  </Row>
                </Col>

                <Col xs="12" className="permissions-levels-create-highlighted">
                  Users
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="4"> {companyPermissions.users.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('users', 'view')} checked={permissions ? permissions.users : false} />
                    </Col>
                    <Col xs="2">
                      <Toggle disabled={permissions ? !permissions.users : true} onChange={() => togglePermission('users', 'edit')} checked={permissions ? permissions.users === 2 : false} />
                    </Col>
                    <Col xs="4"> {(permissions && permissions.users) ? (permissions && permissions.users === 2) ? 'View & Edit' : 'View only' : 'No Access'} </Col>
                  </Row>
                </Col>

                <Col xs="12" className="permissions-levels-create-highlighted">
                  Developers
                </Col>
                <Col xs="12" className="permissions-levels-create-padded">
                  <Row>
                    <Col xs="4"> {companyPermissions.api.name} </Col>
                    <Col xs="2">
                      <Toggle onChange={() => togglePermission('api', 'view')} checked={permissions ? permissions.api : false} />
                    </Col>
                    <Col xs="2">
                      <Toggle disabled={permissions ? !permissions.api : true} onChange={() => togglePermission('api', 'edit')} checked={permissions ? permissions.api === 2 : false} />
                    </Col>
                    <Col xs="4"> {(permissions && permissions.api) ? (permissions && permissions.api === 2) ? 'View & Edit' : 'View only' : 'No Access'} </Col>
                  </Row>
                </Col>
              </>
            )}
            </Row>
          </FormGroup>
          <FormGroup>
            <Label for="description">
              Description<div className="mandatory">*</div>
            </Label>
            <Input
              type="textarea"
              name="description"
              id="description"
              value={description}
              onChange={handleChange}
            />
          </FormGroup>
        </Form>
        <div> <div className="mandatory">*</div>Mandatory fields </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={createRole}>
          {acceptButtonText}
          {(processing === 'create') ? (
            <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
          ):(null)}
        </Button>
        <Button color="secondary" onClick={closeCreateRole}>Close</Button>
        {showDelete ? (
          <Button color="danger" disabled={showNoPermission} onClick={openDeleteRole}>
            Delete
          </Button>
        ):(null)}

      </ModalFooter>
    </Modal>
  </>
);


CreateModal.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string,
};

CreateModal.defaultProps = {
  checked: false,
  text: null,
};

export default CreateModal;
