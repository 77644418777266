import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import {
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap';

import 'rsuite-table/dist/css/rsuite-table.css';

import { connect } from 'react-redux';

import {
  getPerks,
  getDisablePerksTab,
  getPerksTabTitle,
  resetPerksHighlight,
} from '../../../actions/perks';

class Notifications extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    fetchPerks: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    fetchDisablePerksTab: PropTypes.func.isRequired,
    fetchPerksTabTitle: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      enabled: false,
      deleteSettingModal: false,
      deleteSettingErr: null,
      settingToDelete: null,
      activeTab: '1',
      companyNotification: '',
      allChecked: true,
      checked: {},
      companyNotifications: [],
      companyInformation: '',
    };
  }

  componentDidMount() {
    const { user, history, resetHighlight } = this.props;

    if (user && Object.keys(user).length > 0) {
      this.fetchPerks();
      this.fetchDisablePerksTab();
      this.fetchPerksTabTitle();

      console.log('user.perksHighlight', user.perksHighlight);

      if (user && user.company && !user.perksHighlight) {
        resetHighlight();
      }
    } else {
      history.push('/');
    }
  }

  fetchPerks = () => {
    const { fetchPerks } = this.props;

    fetchPerks().then((res2) => {
      let perks = {};

      console.log('fetchPerks', res2);
      if (res2) {
        Object.keys(res2).map((key, index) => {
          perks[key] = res2[key];
          return null;
        });
      }

      this.setState({ perks });
    })
  }

  fetchDisablePerksTab = () => {
    const { fetchDisablePerksTab } = this.props;

    fetchDisablePerksTab().then((res2) => {
      console.log('fetchDisablePerksTab', res2);

      this.setState({ disablePerks: res2 });
    })
  }

  fetchPerksTabTitle = () => {
    const { fetchPerksTabTitle } = this.props;

    fetchPerksTabTitle().then((res) => {
      console.log('fetchPerksTabTitle', res);

      this.setState({ perksTabTitle: res });
    })
  }

  goToLink = (link) => {
    const { history } = this.props;

    console.log('goToLink', link);

    window.open(link, '_blank').focus();
  }

  render() {
    const { user } = this.props;
    const {
      activeTab,
      dontShowPage,
      perks,
      perksTabTitle,
    } = this.state;

    console.log('perksTabTitle', perksTabTitle, perks);

    return (
      <div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div>
              {/* <Row style={{ marginBottom: 15 }}>
                <Col sm="12">
                  <h2 className="body-title" style={{ textAlign: 'center' }}>
                    {perksTabTitle}
                  </h2>
                </Col>
              </Row> */}
              <Row>
                {perks ? Object.keys(perks).map(perkKey => (
                  <Col xs="12" sm="6" md="4" lg="3">
                    <Card
                      className="perks-card"
                      onClick={() => this.goToLink(perks[perkKey].link)}
                    >
                      <div style={{ borderBottom: '1px solid #EEEEEE', background: '#FFF' }}>
                        <img
                          alt={perks[perkKey].name}
                          src={perks[perkKey].image}
                          className="perks-card-image"
                        />
                      </div>
                      <CardBody style={{ background: '#6FC0F9' }}>
                        <CardTitle tag="h5" style={{ color: '#FFF', textDecoration: 'none' }}>
                          <a
                            href={perks[perkKey].link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: '#FFF', textDecoration: 'none' }}
                          >
                            {perks[perkKey].name}
                          </a>
                        </CardTitle>
                        <CardSubtitle
                          style={{ color: '#FFF' }}
                          className="mb-2"
                          tag="h6"
                        >
                          {perks[perkKey].description}
                        </CardSubtitle>
                        <CardText style={{ color: '#FFF', fontWeight: 'bold' }}>
                          {perks[perkKey].summary}
                        </CardText>
                      </CardBody>
                    </Card>

                  </Col>
                )) : null}
              </Row>
            </div>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  emailSettings: state.notifications.emailSettings || {},
  adminsRoles: state.admin.adminsRoles || {},
});

const mapDispatchToProps = {
  fetchPerks: getPerks,
  fetchDisablePerksTab: getDisablePerksTab,
  fetchPerksTabTitle: getPerksTabTitle,
  resetHighlight: resetPerksHighlight,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications));