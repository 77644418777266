import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import Moment from 'react-moment';

import { Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label, Form, FormGroup, Alert, Media } from 'reactstrap';

import { Table, Column, HeaderCell, Cell, ColumnGroup } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css'; // or 'rsuite-table/dist/css/rsuite-table.css'

class CustomerPaymentsTable extends Component {
  static propTypes = {
    user: PropTypes.shape(),
  };

  static defaultProps = {
    user: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
    };
  }

  componentDidMount() {

  }

  render() {
    const { sortType, sortColumn, payments, sort, refundPayment } = this.props;

    console.log('payments', { payments });

    return (
      <Table
        style={{ fontSize: 14 }}
        sortType={sortType}
        sortColumn={sortColumn}
        data={payments}
        virtualized
        minHeight={650}
        height={650}
        headerHeight={92}
        onSortColumn={this.sort}
        shouldUpdateScroll={false}
      >
        <Column width={130} sortable resizable>
          <HeaderCell>ID</HeaderCell>
          <Cell dataKey="id" />
        </Column>

        <Column width={120} sortable resizable>
          <HeaderCell>Purchase Date</HeaderCell>
          <Cell dataKey="created">
          {(rowData, rowIndex) => {
            return (
              <div>
                {rowData.paymentDate ? (
                  <Moment format="DD-MM-YYYY" locale="=uk">
                    {rowData.paymentDate.seconds * 1000}
                  </Moment>
                ):(null)}
              </div>
            )
          }}
          </Cell>
        </Column>
        <ColumnGroup className="text-center" header="Purchase info">

          <Column width={100} sortable resizable>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="status">
              {(rowData, rowIndex) => {
                return <div> - </div>
              }}
            </Cell>
          </Column>
          <Column width={200} sortable resizable>
            <HeaderCell>Customer Name</HeaderCell>
            <Cell dataKey="customerName" />
          </Column>
          <Column width={200} sortable resizable>
            <HeaderCell>Customer Email</HeaderCell>
            <Cell dataKey="customerEmail" />
          </Column>

          <Column width={150} sortable resizable>
            <HeaderCell>Face Value</HeaderCell>
            <Cell dataKey="faceValue" />
          </Column>
        </ColumnGroup>

        <ColumnGroup className="text-center" header="Payment in">
          <Column width={150} sortable resizable>
            <HeaderCell>Voucher payment</HeaderCell>
            <Cell dataKey="amount_captured">
              {(rowData, rowIndex) => {
                if (rowData.amount_captured) {
                  return <div> {(rowData.amount_captured / 100).toFixed(2)} </div>
                } else {
                  return <div />
                }
              }}
            </Cell>
          </Column>
          <Column width={150} sortable resizable>
            <HeaderCell>Payments count</HeaderCell>
            <Cell dataKey="capturedCount" />
          </Column>
        </ColumnGroup>

        <ColumnGroup className="text-center" header="Payment out">
          <Column width={150} sortable resizable>
            <HeaderCell>Pay to company</HeaderCell>
            <Cell dataKey="amount_captured">
              {(rowData, rowIndex) => {
                if (rowData.application_fee_amount) {
                  return <div> {`${((rowData.amount_captured - rowData.application_fee_amount) / 100).toFixed(2)}`} </div>
                } else {
                  return <div> {(rowData.amount_captured / 100).toFixed(2)} </div>
                }
              }}
            </Cell>
          </Column>

          <Column width={150} sortable resizable>
            <HeaderCell>Pay to POD</HeaderCell>
            <Cell dataKey="amount_captured">
              {(rowData, rowIndex) => {
                if (rowData.gift && rowData.podPrice) {
                  return <div> {rowData.podPrice} </div>
                } else {
                  return <div/>
                }
              }}
            </Cell>
          </Column>

          <Column width={150} sortable resizable>
            <HeaderCell>Pay to Steppr</HeaderCell>
            <Cell dataKey="amount_captured">
              {(rowData, rowIndex) => {
                if (rowData.application_fee_amount) {
                  if (rowData.gift && rowData.podPrice) {
                    return <div> {(rowData.podPrice + (rowData.application_fee_amount / 100)).toFixed(2)} </div>
                  } else {
                    return <div> {(rowData.application_fee_amount / 100).toFixed(2)} </div>
                  }
                } else {
                  return <div/>
                }
              }}
            </Cell>
          </Column>
        </ColumnGroup>

        <ColumnGroup className="text-center" header="Refunds">
          <Column width={150} sortable resizable>
            <HeaderCell>Amount refunded</HeaderCell>
            <Cell dataKey="amount_refunded">
              {(rowData, rowIndex) => {
                if (rowData.amount_refunded) {
                  return <div> {(rowData.amount_refunded / 100).toFixed(2)} </div>
                } else {
                  return <div />
                }
              }}
            </Cell>
          </Column>
          <Column width={0} sortable resizable>
            <HeaderCell>Refunded count</HeaderCell>
            <Cell dataKey="refundedCount" />
          </Column>
        </ColumnGroup>
      </Table>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  users: state.admin.companyOwners || {},
});

const mapDispatchToProps = {

};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerPaymentsTable));
