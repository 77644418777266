import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Label, Media, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Check } from 'react-feather';

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

class SetupAssistance extends Component {
  static propTypes = {
    basicInformationSetup: PropTypes.number,
    apiSetup: PropTypes.number,
    paymentsSetup: PropTypes.number,
    vouchersLength: PropTypes.number,
  };

  static defaultProps = {
    basicInformationSetup: 0,
    apiSetup: 0,
    paymentsSetup: 0,
    vouchersLength: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      nextRefresh: Date.now() + (props.refreshTime * 1000),
    };
  }

  componentDidMount() {
    const { refreshTime } = this.props;

  }

  refresh = () => {
    const { refreshFunction } = this.props;

    if (refreshFunction) {
      refreshFunction();
    }
  }

  render() {
    const {
      basicInformationSetup,
      apiSetup,
      paymentsSetup,
      vouchersLength,
    } = this.props;

  // console.log('SetupAssistance', basicInformationSetup, apiSetup, paymentsSetup);

    return (
      <Card className="admin-dashboard-setup-assistance-card">
        <CardBody className="admin-dashboard-setup-assistance-card-body">
          <Row>
            <Col xs="12" sm="3" className="admin-dashboard-setup-assistance-info">
              <div className="admin-dashboard-setup-assistance-info-header">
                Setup tracker
              </div>
              <div className="admin-dashboard-setup-assistance-info-text">
                <div className="admin-dashboard-setup-assistance-info-text-icon">
                  i
                </div>
                <div className="admin-dashboard-setup-assistance-info-text-text">
                  Top right indicators may take a minute to update
                </div>
              </div>
              <div className="admin-dashboard-setup-assistance-info-link">
                <Link className="admin-dashboard-setup-assistance-info-link" to="/setup-help">Setup Help</Link>
              </div>
            </Col>
            <Col xs="12" sm="9" style={{ position: 'relative', dispay: 'block' }}>
              <Row style={{ position: 'relative', dispay: 'block', marginBottom: 15 }}>
                <Col xs="3" className="admin-dashboard-setup-assistance-col">
                  <Link className="admin-dashboard-setup-assistance-label-link" to="/payments">
                    <Label className="admin-dashboard-setup-assistance-label">
                      Payments Connection
                    </Label>
                      <div className={`admin-dashboard-setup-assistance-cicle ${paymentsSetup === 100 ? 'admin-dashboard-setup-assistance-cicle-complete' : ''}`}>
                        <div className="admin-dashboard-setup-assistance-cicle-check">
                          <Check size={48} className="admin-dashboard-setup-assistance-cicle-check-icon" />
                        </div>
                      </div>
                  </Link>
                </Col>
                <Col xs="3" className="admin-dashboard-setup-assistance-col">
                  <Link className="admin-dashboard-setup-assistance-label-link" to="/api">
                    <div>
                      <Label className="admin-dashboard-setup-assistance-label">
                        Website Connection
                      </Label>
                    </div>
                    <div className={`admin-dashboard-setup-assistance-cicle ${apiSetup === 100 ? 'admin-dashboard-setup-assistance-cicle-complete' : ''}`}>
                      <div className="admin-dashboard-setup-assistance-cicle-check">
                        <Check size={64} className="admin-dashboard-setup-assistance-cicle-check-icon" />
                      </div>
                  </div>
                </Link>
                </Col>
                <Col xs="3" className="admin-dashboard-setup-assistance-col">
                  <Link className="admin-dashboard-setup-assistance-label-link" to="/basic-information">
                    <div>
                      <Label className="admin-dashboard-setup-assistance-label">
                        Basic Information
                      </Label>
                    </div>
                    <div className={`admin-dashboard-setup-assistance-cicle ${basicInformationSetup === 100 ? 'admin-dashboard-setup-assistance-cicle-complete' : ''}`}>
                      <div className="admin-dashboard-setup-assistance-cicle-check">
                        <Check size={64} className="admin-dashboard-setup-assistance-cicle-check-icon" />
                      </div>
                    </div>
                  </Link>
                </Col>
                <Col xs="3" className="admin-dashboard-setup-assistance-col">
                  <Link className="admin-dashboard-setup-assistance-label-link" to="/new-voucher">
                    <div>
                      <Label className="admin-dashboard-setup-assistance-label">
                        Create New Campaign
                      </Label>
                    </div>
                    <div className={`admin-dashboard-setup-assistance-cicle ${vouchersLength > 0 ? 'admin-dashboard-setup-assistance-cicle-complete' : ''}`}>
                      <div className="admin-dashboard-setup-assistance-cicle-check">
                        <Check size={64} className="admin-dashboard-setup-assistance-cicle-check-icon" />
                      </div>
                    </div>
                  </Link>
                </Col>
                <div className="admin-dashboard-setup-assistance-line" />
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

//<div className="admin-dashboard-setup-assistance-line" />

export default SetupAssistance;
