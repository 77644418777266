import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Media, Card, CardBody, Table } from 'reactstrap';

class AutoRefresh extends Component {
  static propTypes = {
    refreshTime: PropTypes.number,
    refreshFunction: PropTypes.func.isRequired,
    hidden: PropTypes.bool,
  };

  static defaultProps = {
    refreshTime: 60,
    hidden: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      hours: '00',
      minutes: '00',
      seconds: '00',
      nextRefresh: Date.now() + (props.refreshTime * 1000),
    };
  }

  componentDidMount() {
    const { refreshTime, hidden } = this.props;

    this.setState({ nextRefresh: Date.now() + (refreshTime * 1000) })

    this.timeoutID = setTimeout(() => {
      this.intervalID = setInterval(() => {
        const { nextRefresh } = this.state;

        if (nextRefresh) {
          const minutes = Math.floor(((nextRefresh - (Date.now())) % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor(((nextRefresh - (Date.now())) % (1000 * 60)) / 1000);

        //  console.log('AutoRefresh ref', nextRefresh, minutes, seconds);

          if (minutes <= 0 && seconds <= 0) {
            console.log('refresh');
            this.refresh();

            this.setState({
              nextRefresh: Date.now() + (refreshTime * 1000),
              minutes: `00`,
              seconds: `00`,
            });
          } else {
            this.setState({
              minutes: (minutes < 10 && minutes >= 0) ? `0${minutes}` : minutes,
              seconds: (seconds < 10 && seconds >= 0) ? `0${seconds}` : seconds,
            });
          }
        }
      }, 1000);
    }, 50);
  }


  componentWillUnmount() {
    clearTimeout(this.timeoutID);
    clearInterval(this.intervalID);
  }

  refresh = () => {
    const { refreshFunction } = this.props;

    if (refreshFunction) {
      refreshFunction();
    }
  }

  render() {
    const { hidden } = this.props;
    const {
      minutes,
      seconds,
    } = this.state;

  //  console.log('AutoRefresh render', minutes, seconds);
    if (hidden) {
      return null;
    }

    return (
      <div style={{ textAlign: 'right', position: 'absolute', top: -25, right: 0 }} className="btn-link game-stats-timer game-stats-timer-tournament">
        {`Refresh in ${minutes}:${seconds}`}
      </div>
    );
  }
}

export default AutoRefresh;
