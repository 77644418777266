import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import * as Feather from 'react-feather';
import { Label } from 'reactstrap';
// import ReactTooltip from 'react-tooltip';
import { Popover, ArrowContainer } from 'react-tiny-popover';

const NavExternal = ({ link, name, branding, shadeColor, indent }) => {
  const [hoverActive, setHoverActive] = React.useState(false);

  let bgColor = '#0b1628';

  if (branding.sidebarPrimaryColor) {
    bgColor = branding.sidebarPrimaryColor;
  }

  return (
    <>
      <div
        className="nav-item"
        onMouseEnter={() => setHoverActive(true)}
        onMouseLeave={() => setHoverActive(false)}
      >
        <a
          color="link"
          className="logout-button"
          style={{
            paddingLeft: indent ? 40 : 20,
            color: branding ? branding.sidebarTextColor : '#FFF',
            background: hoverActive ? shadeColor(bgColor, 20) : bgColor,
          }}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {name}
        </a>
      </div>
    </>
  );
};

export default NavExternal;
