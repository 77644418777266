import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label, Alert, Media, Spinner } from 'reactstrap';

import { connect } from 'react-redux';
import Moment from 'react-moment';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';

import ReactTooltip from 'react-tooltip';

import {
  requestCampaignStatusChange,
  requestCampaignEdit,
  cancelRequestStop,
  getCompanyVouchers,
} from '../../../../actions/vouchers';

import formattedNumber from '../../../components/FormattedNumber/FormattedNumber';

import notifications from '../../../../const/notifications';

class CampaignDetails extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    vouchers: PropTypes.shape(),
    requestStatusChange: PropTypes.func.isRequired,
    cancelStop: PropTypes.func.isRequired,
    requestEdit: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired,
  };

  static defaultProps = {
    vouchers: {},
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      previewModal: false,
      readMore: false,
    };
  }

  componentDidMount() {
  }

  openPreview = async () => {
    this.setState({ previewModal: true });
  }

  closePreview = () => {
    this.setState({ previewModal: false, primaryVoucherImage: null, logo: null });
  }

  toggleReadMore = () => {
    this.setState((prevState) => ({ readMore: !prevState.readMore }))
  }

  openPauseModal = () => {
    this.setState({ pauseModalOpen: true });
  }

  closePauseModal = () => {
    this.setState({ pauseModalOpen: false });
  }

  requestPause = () => {
    const { campaignDetailsId, requestStatusChange, reload } = this.props;

    this.setState({ buttonPressed: 'pause' });

    console.log('requestPause');

    requestStatusChange(campaignDetailsId, 2).then(() => {
      this.setState({ pauseModalOpen: false, buttonPressed: null });
      reload();
    }).catch(err => {
      this.setState({ pauseModalOpen: false, buttonPressed: null });
    });
  }

  openUnpauseModal = () => {
    this.setState({ unpauseModalOpen: true });
  }

  closeUnpauseModal = () => {
    this.setState({ unpauseModalOpen: false });
  }

  requestUnpause = () => {
    const { campaignDetailsId, requestStatusChange, reload } = this.props;

    this.setState({ buttonPressed: 'unpause' });

    console.log('requestUnpause');

    requestStatusChange(campaignDetailsId, 1).then(() => {
      this.setState({ unpauseModalOpen: false, buttonPressed: null });
      reload();
    }).catch(err => {
      this.setState({ unpauseModalOpen: false, buttonPressed: null });
    });
  }

  openExpiredModal = () => {
    this.setState({ expiredModalOpen: true });
  }

  closeExpiredModal = () => {
    this.setState({ expiredModalOpen: false });
  }

  requestExpired = () => {
    const { campaignDetailsId, requestStatusChange, reload } = this.props;

    this.setState({ buttonPressed: 'expired' });

    console.log('requestExpired');

    requestStatusChange(campaignDetailsId, 4).then(() => {
      this.setState({ expiredModalOpen: false, buttonPressed: null });
      reload();
    }).catch(err => {
      this.setState({ expiredModalOpen: false, buttonPressed: null });
    });
  }

  cancelExpired = () => {
    const { campaignDetailsId, cancelStop, reload } = this.props;

    this.setState({ buttonPressed: 'cancelExpired' });

    console.log('cancelExpired');

    cancelStop(campaignDetailsId).then(() => {
      this.setState({ expiredModalOpen: false, buttonPressed: null });
      reload();
    }).catch(err => {
      this.setState({ expiredModalOpen: false, buttonPressed: null });
    });
  }

  openEditModal = () => {
    this.setState({ editModalOpen: true });
  }

  closeEditModal = () => {
    this.setState({ editModalOpen: false });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeTotalValue = (e) => {
    this.setState({ [e.target.name]: parseFloat(e.target.value.replace(/,/g, '')), success: false, error: false })
  };

  requestEdit = () => {
    const { campaignDetailsId, requestEdit, close, campaignDetails, reload } = this.props;
    const { editValue } = this.state;

    if (parseInt(editValue, 10) % 5 !== 0) {
      this.setState({ requestEditError: notifications.company.dashboard.multipleOf5 || 'New value must be multiple of 5' })
      return null;
    }

    if (campaignDetails && campaignDetails.totalValue && parseInt(campaignDetails.totalValue, 10) > parseInt(editValue, 10)) {
      this.setState({ requestEditError: notifications.company.dashboard.mustBeGreater || 'New value must be greater than current value' })
      return null;
    }

    this.setState({ buttonPressed: 'edit' });

    console.log('requestEdit');

    requestEdit(campaignDetailsId, parseInt(editValue, 10)).then(() => {
      this.setState({ editModalOpen: false, buttonPressed: null });

      reload();
      close();
    }).catch(err => {
      this.setState({ editModalOpen: false, buttonPressed: null });
    });
  }


  render() {
    const { close, campaignDetails, categories, companyChanges, companyPopoutTooltips, showNoPermission, newVoucherTooltips } = this.props;
    const {
      pauseModalOpen,
      unpauseModalOpen,
      expiredModalOpen,
      editModalOpen,
      editValue,
      requestEditError,
      buttonPressed,
    } = this.state;

    const { status, name, startDate, totalValue, primaryVoucherImage, logo, voucherImages, discount, availableVouchers, company, voucherPendingChanges, expiry } = campaignDetails;

    return (
      <div>
        <Modal style={{ maxHeight: 800 }} size="xl" isOpen={true} toggle={close}>
          <ModalHeader toggle={close}>
            Campaign Details
          </ModalHeader>
          <ModalBody>
            <div className="campaign-details">
              <Row className="campaign-details-section1">
                <Col sm="12">
                  <h3>
                    Campaign Details
                  </h3>
                </Col>
                  <Col sm="12" className="campaign-details-section">
                    <Row>
                      <Col sm="1">
                        <Label data-tip data-for='name' for="name">
                          Campaign Name
                        </Label>
                        <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='name'>
                          <span>{newVoucherTooltips.campaignName ? newVoucherTooltips.campaignName.value : ''}</span>
                        </ReactTooltip>
                      </Col>
                      <Col sm="11">
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          value={name}
                          readOnly
                          disabled
                        />
                      </Col>
                    </Row>

                    <Label style={{ paddingTop: 5, paddingBottom: 5 }} data-tip data-for='availableVouchers' for="availableVouchers">
                      Face values
                    </Label>
                    <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='availableVouchers'>
                      <span>{newVoucherTooltips.faceValues ? newVoucherTooltips.faceValues.value : ''}</span>
                    </ReactTooltip>
                    <Row>
                      <Col sm="4">
                        <Row>
                          <Col style={{ textAlign: 'right', paddingTop: 5, paddingLeft: 5, paddingRight: 0, fontSize: 15 }} sm="3">
                            Voucher 1 (£)
                          </Col>
                          <Col sm="9" style={{ paddingRight: 5 }}>
                            <Input
                              type="text"
                              name="availableVouchers1"
                              id="availableVouchers1"
                              value={availableVouchers['0']}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="4">
                        <Row>
                          <Col style={{ textAlign: 'right', paddingTop: 5, paddingLeft: 5, paddingRight: 0, fontSize: 15 }} sm="3">
                            Voucher 2 (£)
                          </Col>
                          <Col sm="9" style={{ paddingRight: 5 }}>
                            <Input
                              type="text"
                              name="availableVouchers2"
                              id="availableVouchers2"
                              value={availableVouchers['1']}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="4">
                        <Row>
                          <Col style={{ textAlign: 'right', paddingTop: 5, paddingLeft: 5, paddingRight: 0, fontSize: 15 }} sm="3">
                            Voucher 3 (£)
                          </Col>
                          <Col sm="9" style={{ paddingLeft: 10 }}>
                            <Input
                              type="text"
                              name="availableVouchers3"
                              id="availableVouchers3"
                              value={availableVouchers['2']}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Label style={{ paddingTop: 5, paddingBottom: 5 }} data-tip data-for='discount' for="discount">
                      Loyalty steps
                    </Label>
                    <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='discount'>
                      <span>{newVoucherTooltips.discounts ? newVoucherTooltips.discounts.value : ''}</span>
                    </ReactTooltip>
                    <Row>
                      <Col sm="4">
                        <Row>
                          <Col style={{ textAlign: 'right', paddingTop: 5, paddingRight: 0, paddingLeft: 5, paddingRight: 0, fontSize: 15 }} sm="3">
                            Step 1 (%)
                          </Col>
                          <Col sm="9" style={{ paddingRight: 5 }}>
                            <Input
                              type="text"
                              style={{ border: 'none' }}
                              name="discount1"
                              id="discount1"
                              value={discount['1']}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="4">
                        <Row>
                          <Col style={{ textAlign: 'right', paddingTop: 5, paddingLeft: 5, fontSize: 15 }} sm="3">
                            Step 2 (%)
                          </Col>
                          <Col sm="9" style={{ paddingRight: 5 }}>
                            <Input
                              type="text"
                              style={{ border: 'none' }}
                              name="discount2"
                              id="discount2"
                              value={discount['2']}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="4">
                        <Row>
                          <Col style={{ textAlign: 'right', paddingTop: 5, paddingLeft: 5, paddingRight: 0, fontSize: 15 }} sm="3">
                            Step 3 (%)
                          </Col>
                          <Col sm="9" style={{ paddingLeft: 10 }}>
                            <Input
                              style={{ border: 0, display: 'inline-block' }}
                              type="text"
                              name="discount3"
                              id="discount3"
                              value={discount['3']}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: 15 }}>
                      <Col sm="4">
                        <Label data-tip data-for='totalValue' for="totalValue">
                          Campaign value (per step)
                        </Label>
                        <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='totalValue'>
                          <span>{newVoucherTooltips.totalValue ? newVoucherTooltips.totalValue.value : ''}</span>
                        </ReactTooltip>
                        <Row>
                          <Col  sm="3" style={{ textAlign: 'right', paddingTop: 10, paddingLeft: 5, fontSize: 15 }}>
                          </Col>
                          <Col sm="9">
                            <Input
                              type="text"
                              name="totalValue"
                              id="totalValue"
                              value={totalValue >= 99999999 ? 'unlimited' : formattedNumber(parseInt(totalValue, 10))}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="4">
                        <Label data-tip data-for='starting-date' for="starting-date">
                          Starting Date
                        </Label>
                        <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='starting-date'>
                          <span>{newVoucherTooltips.startDate ? newVoucherTooltips.startDate.value : ''}</span>
                        </ReactTooltip>
                        <Row>
                          <Col sm="3" style={{ textAlign: 'right', paddingTop: 5, paddingLeft: 5, paddingRight: 0, fontSize: 15 }}>
                          </Col>
                          <Col sm="9" style={{ paddingLeft: 10 }}>
                          {startDate && startDate.seconds ? (
                            <DateTimePicker
                              className="date-picker"
                              time={false}
                              culture="en"
                              disabled
                              readOnly
                              format="MMM DD YYYY"
                              value={new Date(startDate.seconds *1000)}
                            />
                          ):(null)}
                          </Col>
                        </Row>

                      </Col>
                      <Col sm="4">
                        <Label data-tip data-for='expiry' for="expiry">
                          Expiry
                        </Label>
                        <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='expiry'>
                          <span>{newVoucherTooltips.expiry ? newVoucherTooltips.expiry.value : ''}</span>
                        </ReactTooltip>
                        <Row>
                          <Col sm="3" style={{ textAlign: 'right', paddingTop: 5, paddingLeft: 5, paddingRight: 0, fontSize: 15 }}>
                          </Col>
                          <Col sm="9" style={{ paddingLeft: 10 }}>
                            <Input
                              style={{ border: 0, display: 'inline-block' }}
                              type="text"
                              name="expiry"
                              id="expiry"
                              value={expiry ? `${expiry} ${expiry === '1' ? 'year' : 'years'}` : '5 years'}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Row>
                      </Col>
                  {/*    <Col sm="4">
                        <Row>
                          <Col style={{ textAlign: 'right', paddingTop: 5, paddingLeft: 5, paddingRight: 0, fontSize: 15 }} sm="4">
                            Expiry
                          </Col>
                          <Col sm="8" style={{ paddingLeft: 10 }}>
                            <Input
                              style={{ border: 0, display: 'inline-block' }}
                              type="text"
                              name="expiry"
                              id="expiry"
                              value={expiry ? `${expiry} ${expiry === '1' ? 'year' : 'years'}` : '5 years'}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Row>
                      </Col>  */}
                    </Row>
                  </Col>
              </Row>
              <Row className="campaign-details-section2">
                <Col sm="12">
                <h3 style={{ paddingTop: 15 }}>
                  Company Details
                </h3>
                </Col>
                <Col sm="12" className="campaign-details-section">
                  <Row>
                    <Col sm="12">
                      <Label style={{ width: '30%' }} for="name">Company Name</Label>
                      <div style={{ display: 'inline-block', width: '40%'  }}>
                        {company.name}
                      </div>
                    </Col>
                    <Col sm="12">
                      <Label style={{ width: '30%' }} for="companyNumber">Company Number</Label>
                      <div style={{ display: 'inline-block', width: '50%' }}>
                        {company.companyNumber}
                      </div>
                    </Col>

                    <Col sm="12">
                      <Label style={{ width: '30%' }} for="website">Website</Label>
                      <div style={{ display: 'inline-block', width: '50%'  }}>
                        {company.website}
                      </div>
                    </Col>

                    <Col sm="12">
                      <Label style={{ width: '30%' }} for="description">Company Description</Label>
                      <div style={{ display: 'inline-block', width: '50%'  }}>
                        {company.description}
                      </div>
                    </Col>

                    <Col sm="12">
                      <Label style={{ width: '30%' }} for="category1">Category 1 for vouchers</Label>
                      <div style={{ display: 'inline-block', width: '40%'  }}>
                        {(categories && company.category1 && categories[company.category1]) ? categories[company.category1].name : ''}
                      </div>
                    </Col>

                    <Col sm="12">
                      <Label style={{ width: '30%' }} for="category2">Category 2 for vouchers</Label>
                      <div style={{ display: 'inline-block', width: '50%'  }}>
                        {(categories && company.category2 && categories[company.category2]) ? categories[company.category2].name : ''}
                      </div>
                    </Col>

                    <Col sm="12">
                      <Label style={{ width: '30%', paddingTop: 15 }} for="category2">Logo</Label>
                      <div style={{ display: 'inline-block', width: '50%', verticalAlign: 'top' }}>
                        <Media style={{ maxHeight: 75, maxWidth: 300 }} src={logo} />
                      </div>
                    </Col>

                    <Col sm="12">
                      <Label style={{ width: '30%', paddingTop: 15 }} for="primaryVoucherImage">Primary Voucher Image</Label>
                      <div style={{ display: 'inline-block', width: '40%', verticalAlign: 'top' }}>
                        <Media style={{ maxHeight: 75 }} src={primaryVoucherImage} />
                      </div>
                    </Col>

                    <Col sm="12" style={{ paddingTop: 15 }}>
                      <Label style={{ width: '30%', paddingTop: 15 }} for="voucherImages">Voucher Images</Label>
                      <div style={{ display: 'inline-block', width: '70%', verticalAlign: 'top' }}>
                        {voucherImages ? voucherImages.map(image => (
                          <>
                          <Media style={{ width: '25%', display: 'inline-block' }} src={image} />
                          </>
                        )) : (null)}
                      </div>
                    </Col>
                    <Col sm="12">
                    <div className="mandatory">
                      To request a change to your Company Information, please navigate to "Basic Information" tab.
                    </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="campaign-details-section3">
                <Col sm="12">
                  <h3 style={{ paddingTop: 15 }}>
                    Campaign Changes
                  </h3>
                </Col>
                <Col sm="12" className="campaign-details-section">
                  <Row className="text-bold">
                    <Col>
                      Date
                    </Col>
                    <Col>
                      Change type
                    </Col>
                    <Col>
                      Status
                    </Col>
                    <Col sm="3">
                      Old value
                    </Col>
                    <Col sm="3">
                      New value
                    </Col>
                  </Row>
                  {companyChanges ? companyChanges.map((change) => (
                    <Row>
                      <Col>
                        {change.time ? (
                          <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                            {change.time.seconds * 1000}
                          </Moment>
                        ):(null)}
                      </Col>
                      <Col>
                        {change.id}
                      </Col>
                      <Col>
                        {change.status}
                      </Col>
                      <Col sm="3">
                        {(change.id === 'logo' || change.id === 'primaryVoucherImage' || change.id === 'voucherImages') ? (
                          <>
                            {change.id === 'voucherImages' ? (
                              <>
                                {change.old && change.old.map((item) => (
                                  <Media style={{ maxWidth: '50%' }} src={item} />
                                ))}
                              </>
                            ):(
                              <>
                                <Media style={{ maxWidth: '50%' }} src={change.old} />
                              </>
                            )}
                          </>
                        ):(
                          <>
                            {(change.id === 'category1' || change.id === 'category2') ? (
                              <>
                                {(categories && change.old && categories[change.old]) ? categories[change.old].name : null}
                              </>
                            ):(
                              <> {change.old} </>
                            )}
                          </>
                        )}
                      </Col>
                      <Col sm="3">
                        {(change.id === 'logo' || change.id === 'primaryVoucherImage' || change.id === 'voucherImages') ? (
                          <>
                            {change.id === 'voucherImages' ? (
                              <>
                                {change.new && change.new.map((item) => (
                                  <Media style={{ maxWidth: '50%' }} src={item} />
                                ))}
                              </>
                            ):(
                              <>
                                <Media style={{ maxWidth: '50%' }} src={change.new} />
                              </>
                            )}
                          </>
                        ):(
                          <>
                            {(change.id === 'category1' || change.id === 'category2') ? (
                              <>
                                {(categories && change.new && categories[change.new]) ? categories[change.new].name : null}
                              </>
                            ):(
                              <> {change.new} </>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  )):(null)}
                </Col>
              </Row>

            </div>
          </ModalBody>
          <ModalFooter>
            <Row className="campaign-details-footer">
              <Col sm="3">
                {(status && status === 2) ? (
                  <Button
                    disabled={showNoPermission || buttonPressed}
                    onClick={this.requestUnpause}
                    className="campaign-details-button custom-primary-button"
                    color="primary"
                    data-tip
                    data-for='pause-button'
                  >
                    Unpause
                  </Button>
                ) : (
                  <>
                    <Button
                      disabled={(buttonPressed || showNoPermission || (voucherPendingChanges && voucherPendingChanges.status === 2) || status >= 4) ? true : false}
                      className={`campaign-details-button ${status > 4 ? 'disabled' : ''} custom-primary-button`}
                      color="primary"
                      onClick={this.openPauseModal}
                      data-tip
                      data-for='pause-button'
                    >
                      Pause
                    </Button>
                  </>
                )}
                {companyPopoutTooltips.pauseButton ? (
                  <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='pause-button'>
                    <span>{companyPopoutTooltips.pauseButton ? companyPopoutTooltips.pauseButton.value : ''}</span>
                  </ReactTooltip>
                ) : (null)}
              </Col>
              
              <Col sm="3">
                {(status && status > 4) ? (
                  <Button disabled className="campaign-details-button disabled custom-primary-button" color="primary" data-tip data-for='stop-button'> Stoped </Button>
                ) : (
                  <>
                    {(voucherPendingChanges && voucherPendingChanges.status === 4) ? (
                      <Button
                        disabled={(buttonPressed || showNoPermission || status >= 4) ? true : false}
                        className="campaign-details-button custom-primary-button"
                        onClick={this.cancelExpired}
                        color="primary"
                        data-tip
                        data-for='stop-button'
                      >
                        Cancel stop request
                      </Button>
                    ) : (
                      <Button
                        disabled={((buttonPressed || showNoPermission || (voucherPendingChanges && voucherPendingChanges.status === 4)) || status >= 4) ? true : false}
                        className="campaign-details-button custom-primary-button"
                        color="primary"
                        onClick={this.openExpiredModal}
                        data-tip
                        data-for='stop-button'
                      >
                        Request stop
                      </Button>
                    )}
                  </>

                )}

                {companyPopoutTooltips.stopButton ? (
                  <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='stop-button'>
                    <span>{companyPopoutTooltips.stopButton ? companyPopoutTooltips.stopButton.value : ''}</span>
                  </ReactTooltip>
                ) : (null)}
              </Col>

              <Col sm="3">
                {(voucherPendingChanges && voucherPendingChanges.totalValue) ? (
                  <>
                    <Button
                      disabled
                      className="campaign-details-button disabled custom-primary-button"
                      color="primary"
                      data-tip
                      data-for='pending-button'
                    >
                      Pending
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      disabled={(buttonPressed || showNoPermission || status > 4) ? true : false}
                      className={`campaign-details-button ${status > 4 ? 'disabled' : ''} custom-primary-button`}
                      onClick={this.openEditModal}
                      color="primary"
                      data-tip
                      data-for='edit-button'
                    >
                      Edit
                    </Button>
                  </>
                )}

                <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='pending-button'>
                  <span>{companyPopoutTooltips.pendingButton ? companyPopoutTooltips.pendingButton.value : ''}</span>
                </ReactTooltip>

                <ReactTooltip place="top" className='react-tooltip-custom-class' effect="solid" id='edit-button'>
                  <span>{companyPopoutTooltips.editCampaignButton ? companyPopoutTooltips.editCampaignButton.value : ''}</span>
                </ReactTooltip>
              </Col>

              <Col sm="3">
                <Button className="campaign-details-button custom-primary-button" color="primary" onClick={close}>Close</Button>
              </Col>
            </Row>

          </ModalFooter>
        </Modal>

        <Modal size="sm" isOpen={pauseModalOpen} toggle={this.closePauseModal}>
          <ModalHeader toggle={this.closePauseModal}>
            <span>{companyPopoutTooltips.pauseExplanation ? companyPopoutTooltips.pauseExplanation.value : ''}</span>
          </ModalHeader>
          <ModalFooter>
            <Button style={{ marginLeft: 'auto', marginRight: 'auto' }} disabled={buttonPressed} className="custom-primary-button" color="primary" onClick={this.requestPause}>
              Confirm pause
              {buttonPressed === 'pause' ? (
                <Spinner animation="border" variant="primary"  style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ):(null)}
            </Button>
            { }
          </ModalFooter>
        </Modal>

        <Modal isOpen={unpauseModalOpen} toggle={this.closeUnpauseModal}>
          <ModalHeader toggle={this.closeUnpauseModal}>
            <span>{companyPopoutTooltips.pauseExplanation ? companyPopoutTooltips.pauseExplanation.value : ''}</span>
          </ModalHeader>
          <ModalBody>
          </ModalBody>
          <ModalFooter>
            <Button disabled={buttonPressed} className="custom-primary-button" color="primary" onClick={this.requestUnpause}>
            Continue
            {buttonPressed === 'unpause' ? (
              <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''}  </Spinner>
            ):(null)}
            </Button>
            <Button className="custom-primary-button" color="primary" onClick={this.closeUnpauseModal}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={expiredModalOpen} toggle={this.closeExpiredModal}>
          <ModalHeader toggle={this.closeExpiredModal}>
            <span>{companyPopoutTooltips.stopExplanation ? companyPopoutTooltips.stopExplanation.value : ''}</span>
          </ModalHeader>
          <ModalFooter>
            <Button style={{ marginLeft: 'auto', marginRight: 'auto' }} disabled={buttonPressed} className="custom-primary-button" color="primary" onClick={this.requestExpired}>
            Confirm request
            {buttonPressed === 'expired' ? (
              <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
            ):(null)}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={editModalOpen} toggle={this.closeEditModal}>
          <ModalHeader toggle={this.closeEditModal}>
            {companyPopoutTooltips.editExplanation ? companyPopoutTooltips.editExplanation.value : ''}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="12">
                {(!!requestEditError) && <Alert color="danger">{requestEditError}</Alert>}
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                {`Current value: £${totalValue >= 99999999 ? 'unlimited' : totalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
              </Col>
            </Row>
            <Row style={{ paddingTop: 5, marginBottom: 10 }}>
              <Col xs="3" style={{ paddingTop: 5 }}>
                New value:
              </Col>
              <Col xs="6" style={{ paddingLeft: 0 }}>
                <span style={{ width: '7%', paddingLeft: '2.6%' }}>
                  £
                </span>
                <Input
                  type="text"
                  name="editValue"
                  id="editValue"
                  disabled={totalValue >= 99999999 ? true : false}
                    //  value={editValue}
                  value={editValue ? formattedNumber(parseInt(editValue, 10)) : ''}
                  onChange={this.handleChangeTotalValue}
                  style={{ width: '90%', display: 'inline-block', marginLeft: 5 }}
                />
              </Col>
              <Col xs="3">
                <Button disabled={buttonPressed} className="custom-primary-button" color="primary" onClick={this.requestEdit} style={{ float: 'right' }}>
                  Request
                  {buttonPressed === 'edit' ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                  ) : (null)}
                </Button>
              </Col>
            </Row>
          </ModalBody>
        {/*  <ModalFooter>
            <Button disabled={buttonPressed} color="secondary" onClick={this.requestEdit}>
            Request
            {buttonPressed === 'edit' ? (
              <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
            ):(null)}
            </Button>
            <Button color="secondary" onClick={this.closeEditModal}>Close</Button>
          </ModalFooter> */}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  categories: state.company.categories || {},
  companyChanges: state.company.companyChanges || [],
  companyPopoutTooltips: state.contentManagement.companyPopoutTooltips || {},
  newVoucherTooltips: state.contentManagement.newVoucherTooltips || {},
});

const mapDispatchToProps = {
  requestStatusChange: requestCampaignStatusChange,
  requestEdit: requestCampaignEdit,
  cancelStop: cancelRequestStop,
  getVouchers: getCompanyVouchers,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignDetails));
