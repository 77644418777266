import { GET_VOUCHER_CATEGORIES, GET_VOUCHER_COMPANIES, GET_VOUCHER_LIST, GET_COMPANY_VOUCHERS, SORT_COMPANY_VOUCHERS, GET_VOUCHER_SALES_DATA } from '../../actions';

const defaultState = {
    list: {},
    companies: {},
    categories: {},
    vouchers: {},
};

const voucherReducer = (state=defaultState, action) => {
    switch(action.type) {
        case GET_VOUCHER_LIST:
            return {...state, list: {...state.list, ...action.payload}};
        case GET_COMPANY_VOUCHERS: {
          //  console.log('GET_COMPANY_VOUCHERS', action.payload);

            const vouchers = { ...state.list, ...action.payload };

            let vouchersArr = [];
            Object.keys(vouchers).map(key => {
              let step1SalesPerc = (0).toFixed(2);
              let step2SalesPerc = (0).toFixed(2);
              let step3SalesPerc = (0).toFixed(2);
              let salesValuePerc = (0).toFixed(2);

              if (vouchers[key].step1Sales) {
              //  console.log('Math.round(vouchers[key].step1Sales / vouchers[key].totalValue * 100)', Math.round(vouchers[key].step1Sales / vouchers[key].totalValue * 100));

                step1SalesPerc = ((vouchers[key].step1Sales / vouchers[key].totalValue) * 100).toFixed(2);
              }
              if (vouchers[key].step2Sales) {
                step2SalesPerc = ((vouchers[key].step2Sales / vouchers[key].totalValue) * 100).toFixed(2);
              }
              if (vouchers[key].step3Sales) {
                step3SalesPerc = ((vouchers[key].step3Sales / vouchers[key].totalValue) * 100).toFixed(2);
              }
              if (vouchers[key].salesValue) {
                salesValuePerc = ((vouchers[key].salesValue / (vouchers[key].totalValue * 3)) * 100).toFixed(2);
              }

              vouchersArr.push({ id: key, ...vouchers[key], step1SalesPerc, step2SalesPerc, step3SalesPerc, salesValuePerc });
            });

            vouchersArr.sort((a, b) => {
              if (b.created_at > a.created_at) {
                return 1;
              } else {
                return -1;
              }
            });

            return {...state, vouchers: {...state.list, ...action.payload}, vouchersArr };
        }
        case SORT_COMPANY_VOUCHERS: {
          const { sortColumn, sortType } = action;
          const { vouchersArr } = state;

          console.log('SORT_COMPANY_VOUCHERS', sortColumn, sortType);
          console.log('vouchersArr', vouchersArr);

            if (sortType && sortColumn) {
              if (sortType === 'desc') {
                vouchersArr.sort((a, b) => {
                  if (b[sortColumn] && a[sortColumn]) {
                    if (b[sortColumn] > a[sortColumn]) {
                      console.log('sort up', b[sortColumn], a[sortColumn]);
                      return 1;
                    } else if (b[sortColumn] < a[sortColumn]) {
                      console.log('sort down', b[sortColumn], a[sortColumn]);
                      return -1;
                    } else {
                      console.log('dont sort', b[sortColumn], a[sortColumn]);
                      return 0;
                    }
                  } else if (b[sortColumn]) {
                    return 1;
                  } else if (a[sortColumn]) {
                    return -1;
                  }
                });
              } else if (sortType === 'asc') {
                vouchersArr.sort((a, b) => {
                  if (b[sortColumn] && a[sortColumn]) {
                    if (a[sortColumn] > b[sortColumn]) {
                      console.log('sort up', b[sortColumn], a[sortColumn]);
                      return 1;
                    } else if (a[sortColumn] < b[sortColumn]) {
                      console.log('sort down', b[sortColumn], a[sortColumn]);
                      return -1;
                    } else {
                      console.log('dont sort', b[sortColumn], a[sortColumn]);
                      return 0;
                    }
                  } else if (b[sortColumn]) {
                    return -1;
                  } else if (a[sortColumn]) {
                    return 1;
                  }
                });
              }
            }

            console.log('vouchersArr sorted', vouchersArr);

            return { ...state, vouchersArr: vouchersArr };
        }

        case GET_VOUCHER_COMPANIES:
            return {...state, companies: {...state.companies, ...action.payload}};
        case GET_VOUCHER_CATEGORIES:
            return {...state, categories: {...state.categories, ...action.payload}};
        case GET_VOUCHER_SALES_DATA:
            return {
              ...state,
              vouchersCount: action.vouchersCount,
              vouchersUsedCount: action.vouchersUsedCount,
              vouchersValue: action.vouchersValue,
              vouchersByDate: action.vouchersByDate,
              voucherTotalFee: action.voucherTotalFee,
              vouchersByDateLastUpdate: Date.now(),
            };
        default:
            return state;
    }
};

export default voucherReducer;
