import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Card, CardBody, Button, Media, Spinner } from 'reactstrap';

import Moment from 'react-moment';

import { connect } from 'react-redux';

import {
  getCompanyChanges,
  cancelPendingData,
} from '../../../../actions/company';

const changeTypes = {
  question1: "Have you been trading for past 12 months?",
  question1Description: "Have you been trading for past 12 months? (Description)",
  question2: "Are you solvent, able and intending to continue trading for the foreseeable future?",
  question2Description: "Are you solvent, able and intending to continue trading for the foreseeable future? (Description)",
  question3: "Have you had any issues with lenders/creditors/HMRC over the past 12 months?",
  question3Description: "Have you had any issues with lenders/creditors/HMRC over the past 12 months? (Description)",
  question4: "What was your turnover bracket for the last 12 months?",
  question5: "What was your net income bracket for the last 12 months?",
  question5Description: "What was your net income bracket for the last 12 months? (Description)",
  logo: "Logo",
  primaryVoucherImage: "Primary voucher image",
  voucherImages: "Voucher images",
  category1: "Category 1 for vouchers",
  category2: "Category 2 for vouchers",
  description: "Company description",
  website: "Website",
  companyNumber: "Company number",
  hasDisplayName: "Different trading name?",
  displayName: "Trading name",
  totalValue: "Total value",
};

const question4Options = ['£0-£25,000', '£25,001-£50,000', '£50,001-£100,000', '£100,001-£250,000', '£250,001-£500,000', '£500,001-£1,000,000', '£1,000,001-£5,000,000', '£5,000,001+'];
const question5Options = ['<£0', '£0-£25,000', '£25,001-£50,000', '£50,001-£100,000', '£100,001-£250,000', '£250,001-£500,000', '£500,001-£1,000,000', '£1,000,001-£5,000,000', '£5,000,001+'];

class Company extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    companyChanges: PropTypes.shape(),
    company: PropTypes.shape(),
    fetchCompanyChanges: PropTypes.func.isRequired,
    cancelPendingChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    companyChanges: {},
    company: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      showNoPermission: false,
    };
  }

  componentDidMount() {
    const { fetchCompanyChanges, user, isLoggedIn, history, adminViewAsCompany } = this.props;

    if (adminViewAsCompany) {
      fetchCompanyChanges(adminViewAsCompany);

      this.setState({ showNoPermission: false });

    } else if (user && Object.keys(user).length > 0) {
      if (!user || !isLoggedIn || !user.company) {
        history.push('/');
      } else {
        fetchCompanyChanges(user.company);
        if (user.role === 'teamOwner') {
          this.setState({ showNoPermission: false });
        } else {
          const { roles } = this.props;

          if (roles && roles[user.role] && roles[user.role].permissions && roles[user.role].permissions.manageCompany) {
            this.setState({ showNoPermission: false });
          } else {
            this.setState({ showNoPermission: true });
          }
        }
      }
    } else {
      history.push('/');
    }
  }

  cancelPendingChange = (id, changeId) => {
    const { cancelPendingChange, user, fetchCompanyChanges } = this.props;

    this.setState({ buttonPressed: id });

    cancelPendingChange(id, changeId, user.company).then(() => {
      this.setState({ buttonPressed: null });
      fetchCompanyChanges(user.company);
    });
  }

  render() {
    const { companyChanges, categories, showNoPermission } = this.props;
    const { buttonPressed } = this.state;

    return (
      <div>
        <Card>
            <CardBody>
        <Row className="basic-information-changes">
          <Col sm="12">
            <Row className="bold basic-information-changes-row">
              <Col>
                Date
              </Col>
              <Col xs="3">
                Change type
              </Col>
              <Col>
                User
              </Col>
              <Col>
                Status
              </Col>
              <Col xs="2">
                Old value
              </Col>
              <Col xs="2">
                New value
              </Col>
              <Col>
                Cancel
              </Col>
            </Row>
            {companyChanges ? companyChanges.map((change) => (
              <Row className="basic-information-changes-row">
                <Col>
                  {change.time ? (
                    <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                      {change.time.seconds * 1000}
                    </Moment>
                  ) : (null)}
                </Col>
                <Col xs="3">
                  {changeTypes[change.id] || change.id}
                </Col>
                <Col>
                  {change.user}
                </Col>
                <Col style={{ textTransform: 'capitalize' }}>
                  {change.status === 'canceled' ? 'Cancelled' : change.status}
                </Col>
                <Col xs="2">
                  {(change.id === 'logo' || change.id === 'primaryVoucherImage' || change.id === 'voucherImages') ? (
                    <>
                      {change.id === 'voucherImages' ? (
                        <>
                          {change.old && change.old.map((item) => (
                            <Media src={item} />
                          ))}
                        </>
                      ) : (
                        <>
                          <Media src={change.old} />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {change.id === 'category1' || change.id === 'category2' ? (
                        <>
                          {`${(categories && change.old && categories[change.old]) ? categories[change.old].name : change.old}`}
                        </>
                      ) : (
                        <>
                          {(change.id === 'hasDisplayName' || change.id === 'question1' || change.id === 'question2' || change.id === 'question3') ? (
                            <>
                              {`${change.old ? 'Yes' : 'No'}`}
                            </>
                          ) : (
                            <>
                              {(change.id === 'question4' || change.id === 'question5') ? (
                                <>
                                  {(change.id === 'question4') ? (
                                    <>
                                      {question4Options[change.old - 1]}
                                    </>
                                  ) : (
                                    <>
                                      {question5Options[change.old - 1]}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {change.old}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Col>
                <Col xs="2">
                  {(change.id === 'logo' || change.id === 'primaryVoucherImage' || change.id === 'voucherImages') ? (
                    <>
                      {change.id === 'voucherImages' ? (
                        <>
                          {change.new && change.new.map((item) => (
                            <Media src={item} />
                          ))}
                        </>
                      ) : (
                        <>
                          <Media src={change.new} />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {change.id === 'category1' || change.id === 'category2' ? (
                        <>
                          {`${(categories && change.new && categories[change.new]) ? categories[change.new].name : change.new}`}
                        </>
                      ) : (
                        <>
                          {(change.id === 'hasDisplayName' || change.id === 'question1' || change.id === 'question2' || change.id === 'question3') ? (
                            <>
                              {`${change.new ? 'Yes' : 'No'}`}
                            </>
                          ) : (
                            <>
                              {(change.id === 'question4' || change.id === 'question5') ? (
                                <>
                                  {(change.id === 'question4') ? (
                                    <>
                                      {question4Options[change.new - 1]}
                                    </>
                                  ) : (
                                    <>
                                      {question5Options[change.new - 1]}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {change.new}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Col>
                <Col>
                  {change.status === 'pending' ? (
                    <>
                      <Button disabled={showNoPermission} color="link" className="basic-information-changes-cancel-button" onClick={() => this.cancelPendingChange(change.id, change.id2)}>
                        <div className="basic-information-changes-cancel">
                          x
                        </div>
                      </Button>
                      {buttonPressed === change.id ? (
                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                      ) : (null)}
                    </>
                  ) : (null)}
                </Col>
              </Row>
            )) : (null)}
          </Col>
        </Row>
            </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  company: state.company.company || {},
  companyChanges: state.company.companyChanges || [],
  categories: state.company.categories || {},
  isLoggedIn: state.user.isLoggedIn || false,
  adminViewAsCompany: state.admin.adminViewAsCompany || null,
});

const mapDispatchToProps = {
  fetchCompanyChanges: getCompanyChanges,
  cancelPendingChange: cancelPendingData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Company));
