import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Button } from 'reactstrap';

import { connect } from 'react-redux';

import Personal from './Personal';
import Company from './Company';
import Changes from './Changes';


import {
  updateLastActive,
} from '../../../../actions/company';

class BasicInformation extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    updateData: PropTypes.func.isRequired,
    startTab: PropTypes.string,
  };

  static defaultProps = {
    user: {},
    admin: {},
    startTab: '1',
  };

  constructor(props) {
    super(props);

    const query = new URLSearchParams(this.props.location.search);

    const tab = query.get('tab')
    this.state = {
      error: null,
      loading: false,
      activeTab: (tab && tab === '1') ? tab : '2',
      showNoPermission: false,
      dontShowPage: false,
    };
  }

  componentDidMount() {
    const { roles, user, isLoggedIn, history, adminViewAsCompany, updateLastActiveFunc } = this.props;

    if (user && Object.keys(user).length > 0) {
      if (!user || !isLoggedIn) {
        history.push('/');
      } else {
        if (adminViewAsCompany) {
          this.setState({ activeTab: '2' });

          if (adminViewAsCompany && user.roleClass === 'admin') {
            const { adminsRoles } = this.props;

            if (user.role === 'admin') {
              this.setState({ showNoPermission: false, dontShowPage: false });
            } else {
              if (adminsRoles && adminsRoles[user.role] && adminsRoles[user.role].permissions && adminsRoles[user.role].permissions.companyAccess && adminsRoles[user.role].permissions.companyAccessBasicInformation) {
                if (adminsRoles[user.role].permissions.companyAccessBasicInformation === 2) {
                  this.setState({ showNoPermission: false, dontShowPage: false });
                } else {
                  this.setState({ showNoPermission: true, dontShowPage: false });
                }
              } else {
                this.setState({ showNoPermission: true, dontShowPage: true });
              }
            }
          }
        } else if (user.company) {
          updateLastActiveFunc(user.company);

          if (user.role === 'teamOwner') {
            this.setState({ showNoPermission: false, dontShowPage: false });
          } else {
            if (roles && roles[user.role] && roles[user.role].permissions && roles[user.role].permissions.basicInformation) {
              if (roles[user.role].permissions.basicInformation === 2) {
                this.setState({ showNoPermission: false, dontShowPage: false });
              } else {
                this.setState({ showNoPermission: true, dontShowPage: false });
              }
            } else {
              this.setState({ showNoPermission: true, dontShowPage: true });
            }
          }
        } else {
          history.push('/');
        }
      }
    } else {
      history.push('/');
    }
  }

  handleChange = (e) => { this.setState({ [e.target.name]: e.target.value }) };

  toggle = (tab) => {
    const { activeTab } = this.state;

    if (activeTab !== tab) {

      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { adminViewAsCompany } = this.props;
    const {
      activeTab,
      showNoPermission,
      dontShowPage,
    } = this.state;

    console.log('showNoPermission', showNoPermission, dontShowPage);

    if (dontShowPage) {
      return (
        <div>
          <Card>
            <CardBody>
              You don't have permissions to view company information
            </CardBody>
          </Card>
        </div>
      );
    }

    return (
      <div>
        {/* <Card>
          <CardBody> */}
        <Row style={{ paddingBottom: 0, background: '#fff', marginLeft: 0, marginRight: 0, paddingTop: 15 }}>
          <Col xs="12">
            <Button
              color={`${activeTab === '1' ? 'primary' : 'secondary'}`}
              disabled={!!adminViewAsCompany}
              className={`${activeTab === '1' ? 'active' : null}`}
              onClick={() => { this.toggle('1'); }}
              className={`company-tab-change-button ${activeTab === '1' ? 'company-tab-change-button-active' : null}`}
            >
              Personal information
            </Button>
            <Button
              color={`${activeTab === '2' ? 'primary' : 'secondary'}`}
              className={`${activeTab === '2' ? 'active' : null}`}
              onClick={() => { this.toggle('2'); }}
              className={`company-tab-change-button ${activeTab === '2' ? 'company-tab-change-button-active' : null}`}
            >
              Company information
            </Button>
            <Button
              color={`${activeTab === '3' ? 'primary' : 'secondary'}`}
              className={`${activeTab === '3' ? 'active' : null}`}
              onClick={() => { this.toggle('3'); }}
              style={{}}
              className={`company-tab-change-button ${activeTab === '3' ? 'company-tab-change-button-active' : null}`}
            >
              Changes to company information
            </Button>
          </Col>
        </Row>
        <TabContent activeTab={activeTab} style={{ padding: 0, background: 'transparent' }}>
          <TabPane tabId="1">
            <Personal />
          </TabPane>
          <TabPane tabId="2">
            {activeTab === '2' ? (
              <Company showNoPermission={showNoPermission} />
            ) : (null)}
          </TabPane>
          <TabPane tabId="3">
            {activeTab === '3' ? (
              <Changes showNoPermission={showNoPermission} />
            ) : (null)}
          </TabPane>
        </TabContent>
        {/*  </CardBody>
        </Card> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  roles: state.users.companiesRoles || {},
  adminsRoles: state.admin.adminsRoles || {},
  adminViewAsCompany: state.admin.adminViewAsCompany || null,
});

const mapDispatchToProps = {
  updateLastActiveFunc: updateLastActive,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BasicInformation));
