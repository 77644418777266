import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import Moment from 'react-moment';

import { Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Row, Col, Input } from 'reactstrap';

import { Table, Column, HeaderCell, Cell, ColumnGroup } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css'; // or 'rsuite-table/dist/css/rsuite-table.css'

class AllPaymentsTable extends Component {
  static propTypes = {
    user: PropTypes.shape(),
  };

  static defaultProps = {
    user: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
    };
  }

  componentDidMount() {

  }

  /*
  resendEmail = (chargeId, dbId) => {
    const { resendEmail } = this.props;

    this.setState({ resendEmailProcessing: chargeId });

    resendEmail(chargeId, dbId).then(() => {
      this.setState({ resendEmailProcessing: null });
    }).catch(err => {
      this.setState({ resendEmailProcessing: null });
    });
  } */

  openResendEmail = (chargeId, dbId) => {
    this.setState({ resendEmailOpen: true, resendEmailChargeId: chargeId, resendEmailDbId: dbId, resendEmailProcessing: null, resendEmailError: null });
  }

  closeResendEmail = () => {
    this.setState({ resendEmailOpen: null, resendEmailChargeId: null, resendEmailDbId: null, resendEmailProcessing: null, resendEmailError: null });
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  resendEmail = () => {
    const { resendEmail } = this.props;
    const { resendEmailChargeId, resendEmailDbId, resendEmailValue } = this.state;

    this.setState({ resendEmailProcessing: true });

    if (resendEmailValue) {
      resendEmail(resendEmailChargeId, resendEmailDbId, resendEmailValue).then(() => {
        this.closeResendEmail();
      }).catch(err => {
        this.setState({ resendEmailError: err, resendEmailProcessing: null });
        // this.closeResendEmail();
      });
    } else {
      this.setState({ resendEmailError: 'Enter email address' });
    }
  }

  openConfirmModal = (type, chargeId, dbId) => { //refundType
    console.log('openConfirmModal', type, chargeId, dbId);

    this.setState({
      confirmModalOpen: true,
      confirmModalType: type,
      confirmModalChargeId: chargeId,
      confirmModalDbId: dbId,
      // confirmModalRefundType: refundType,
      onfirmModalError: null,
      confirmModalProcessing: null,
    });
  }

  closeConfirmModal = () => {
    console.log('closeConfirmModal');
    this.setState({
      confirmModalOpen: false,
      confirmModalType: null,
      confirmModalChargeId: null,
      confirmModalDbId: null,
     //  confirmModalRefundType: null,
      confirmModalError: null,
      confirmModalProcessing: null,
    });
  }

  finishConfirmModal = () => {
    const { confirmModalType, confirmModalChargeId, confirmModalDbId } = this.state;

    this.setState({ confirmModalProcessing: true });

    console.log('finishConfirmModal', confirmModalType, confirmModalDbId)

    if (confirmModalType === 'cancel') {
      this.cancelPayment(confirmModalChargeId, confirmModalDbId);
    } else {
      this.refundPayment(confirmModalChargeId, confirmModalDbId, confirmModalType);
    }

    /*
    if (confirmModalType === 'refund') {
      if (confirmModalRefundType === 'voucher') {
        this.refundPayment(confirmModalChargeId, confirmModalDbId, confirmModalRefundType);
      } else if (confirmModalRefundType === 'gifting') {
        this.refundPayment(confirmModalChargeId, confirmModalDbId, confirmModalRefundType);
      } else {
        this.refundPayment(confirmModalChargeId, confirmModalDbId, confirmModalRefundType);
      }
      
    } else if (confirmModalType === 'cancel') {
      this.cancelPayment(confirmModalChargeId, confirmModalDbId);
    } else if (confirmModalType === 'refundAndCancel') {
    //  this.refundCancelPayment(confirmModalChargeId, confirmModalDbId, confirmModalRefunded, confirmModalCanceled);
      this.closeConfirmModal();
    } else {
      this.closeConfirmModal();
    } */
  }


  refundPayment = (chargeId, dbId, refundType) => {
    const { refundPayment } = this.props;

    this.setState({ refundProcessing: chargeId });

    console.log('refundType', chargeId, dbId, refundType);

    // if (type === 'all' || type === 'voucher') {
    refundPayment(chargeId, dbId, refundType).then((res) => {
      console.log('refundPayment then', res);
      this.setState({ refundProcessing: null, confirmModalProcessing: null });
      this.closeConfirmModal();
    }).catch(err => {
      console.log('refundPayment err', err);
      this.setState({ refundProcessing: null, confirmModalProcessing: null });
    })
    // } else {
    //  this.setState({ refundProcessing: null, confirmModalProcessing: null });
    //  this.closeConfirmModal();
    // }
  }

  cancelPayment = (chargeId, dbId) => {
    const { cancelPayment } = this.props;

    this.setState({ cancelProcessing: chargeId });

    cancelPayment(chargeId, dbId).then(() => {
      this.setState({ cancelProcessing: null, confirmModalProcessing: null });
      this.closeConfirmModal();
    }).catch(err => {
      this.setState({ cancelProcessing: null, confirmModalProcessing: null });
    })
  }

  /*
  refundCancelPayment = (chargeId, dbId, refunded, canceled) => {
    const { refundPayment, cancelPayment } = this.props;

    this.setState({ refundCancelProcessing: chargeId });

    let promises = [];

    if (!refunded) {
      promises.push(refundPayment(chargeId, dbId));
    }
    if (!canceled) {
      promises.push(cancelPayment(chargeId, dbId));
    }

    Promise.all(promises).then(() => {
      this.setState({ refundCancelProcessing: null, confirmModalProcessing: null });
    }).catch(err => {
      this.setState({ refundCancelProcessing: null, confirmModalProcessing: null });
    })
  } */

  downloadData = async () => {
    const { payments, downloadData } = this.props;

    this.setState({ downloadSpinner: true });
    await downloadData(payments);

    this.setState({ downloadSpinner: false });
  }

  render() {
    const { sortType, sortColumn, payments, sort, refundPayment, cancelPayment, showNoPermission, onScroll, downloadData } = this.props;
    const {
      refundProcessing,
      cancelProcessing,
      refundCancelProcessing,
      resendEmailProcessing,
      confirmModalOpen,
      confirmModalError,
      confirmModalProcessing,
      resendEmailValue,
      resendEmailOpen,
      resendEmailError,
      downloadSpinner,
    } = this.state;

    console.log('payments', payments);

    return (
      <>
        <Table
          style={{ fontSize: 14 }}
          sortType={sortType}
          sortColumn={sortColumn}
          data={payments || []}
          shouldUpdateScroll={false}
          virtualized
          minHeight={650}
          height={650}
          headerHeight={92}
          onSortColumn={sort}
          onScroll={onScroll}
          rowClassName={"admin-payments-table admin-payments-table"}
        >
          <Column width={130} sortable resizable>
            <HeaderCell>Order ID</HeaderCell>
              <Cell dataKey="chargeId">
                {(rowData, rowIndex) => {
                return <div className="admin-payments-table-default"> {rowData.chargeId ? rowData.chargeId : '-'} </div>
                }}
              </Cell>
          </Column>

          <Column width={170} sortable resizable>
            <HeaderCell>Date</HeaderCell>
            <Cell dataKey="paymentDate">
            {(rowData, rowIndex) => {
              return (
                <div className="admin-payments-table-default">
                  {rowData.paymentDate ? (
                    <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                      {rowData.paymentDate.seconds * 1000}
                    </Moment>
                  ):(null)}
                </div>
              )
            }}
            </Cell>
          </Column>

          <Column width={160} sortable resizable>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="used">
              {(rowData, rowIndex) => {
                  if (rowData.canceled) {
                    if (rowData.refunded) {
                      return (
                        <div className="admin-payments-table-default">
                          <span>Cancelled (refunded)</span>
                          {/*  {rowData.canceledDate ? (
                            <span>
                              {` - `}
                              <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                                {rowData.canceledDate.seconds ? (rowData.canceledDate.seconds * 1000) : rowData.canceledDate}
                              </Moment>
                            </span>
                          ) : (null)} */}
                        </div>
                      )
                    } else {
                      return (
                        <div className="admin-payments-table-default">
                          <span>Cancelled</span>
                          {/*  {rowData.canceledDate ? (
                            <span>
                              {` - `}
                              <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                                {rowData.canceledDate.seconds ? (rowData.canceledDate.seconds * 1000) : rowData.canceledDate}
                              </Moment>
                            </span>
                          ) : (null)}  */}
                        </div>
                      )
                    }
                  } else if (rowData.used) {
                    if (rowData.refunded) {
                      return (
                        <div className="admin-payments-table-default">
                          <span>Redeemed (refunded)</span>
                          {/* {rowData.usedDate ? (
                            <span>
                              {` - `}
                              <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                                {rowData.usedDate.seconds ? (rowData.usedDate.seconds * 1000) : rowData.usedDate}
                              </Moment>
                            </span>
                          ) : (null)}  */}
                        </div>
                      )
                    } else {
                      return (
                        <div className="admin-payments-table-default">
                          <span>Redeemed</span>
                          {/* {rowData.usedDate ? (
                            <span>
                              {` - `}
                              <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                                {rowData.usedDate.seconds ? (rowData.usedDate.seconds * 1000) : rowData.usedDate}
                              </Moment>
                            </span>
                          ) : (null)} */}
                        </div>
                      )
                    }
                  } else {
                    if (rowData.refunded) {
                      if (rowData.canceled) {
                        return (
                          <div className="admin-payments-table-default">
                            <span>Cancelled (refunded)</span>
                            {/* {rowData.canceledDate ? (
                              <span>
                                {` - `}
                                <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                                  {rowData.canceledDate}
                                </Moment>
                              </span>
                            ) : (null)} */}
                          </div>
                        )
                      } else {
                        return <div className="admin-payments-table-default"> Unused (refunded) </div>
                      }
                    } else {
                      if (rowData.canceled) {
                        return (
                          <div className="admin-payments-table-default">
                            <span>Cancelled</span>
                            {/*  {rowData.canceledDate ? (
                              <span>
                                {` - `}
                                <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                                  {rowData.canceledDate}
                                </Moment>
                              </span>
                            ) : (null)}  */}
                          </div>
                        )
                      } else {
                        return <div className="admin-payments-table-default"> Unused </div>
                      }
                    }
                  }
              }}
            </Cell>
          </Column>

          <Column width={160} sortable resizable>
            <HeaderCell>Redemption date</HeaderCell>
            <Cell dataKey="used">
              {(rowData, rowIndex) => {
                if (rowData.used && rowData.usedDate) {
                  return (
                    <div className="admin-payments-table-default">
                      <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                        {rowData.usedDate.seconds ? (rowData.usedDate.seconds * 1000) : rowData.usedDate}
                      </Moment>
                    </div>
                  )
                }
              }}
            </Cell>
          </Column>

          <Column width={200} sortable resizable>
            <HeaderCell>Customer Name</HeaderCell>
            <Cell dataKey="customerName">
              {(rowData, rowIndex) => {
                return <div className="admin-payments-table-default"> {rowData.customerName ? rowData.customerName : '-'} </div>
              }}
            </Cell>
          </Column>
          <Column width={200} sortable resizable>
            <HeaderCell>Customer Email</HeaderCell>
            <Cell dataKey="customerEmail">
              {(rowData, rowIndex) => {
                return <div className="admin-payments-table-default"> {rowData.customerEmail ? rowData.customerEmail : '-'} </div>
              }}
            </Cell>
          </Column>
          <Column width={200} sortable resizable>
            <HeaderCell>Company</HeaderCell>
            <Cell dataKey="companyName">
              {(rowData, rowIndex) => {
                return <div className="admin-payments-table-default"> {rowData.companyName ? rowData.companyName : '-'} </div>
              }}
            </Cell>
          </Column>
          <Column width={200} sortable resizable>
            <HeaderCell>Campaign</HeaderCell>
            <Cell dataKey="voucherName">
              {(rowData, rowIndex) => {
                return <div className="admin-payments-table-default"> {rowData.voucherName ? rowData.voucherName : '-'} </div>
              }}
            </Cell>
          </Column>
          <Column width={200} resizable>
            <HeaderCell>Gifting</HeaderCell>
            <Cell>
              {(rowData, rowIndex) => {
                if (rowData.gift) {
                  return <div className="admin-payments-table-default"> Bespoke Email Service </div>
                } else {
                  return <div className="admin-payments-table-default"> - </div>
                }
              }}
            </Cell>
          </Column>
            {/*  <Column width={100} resizable>
                <HeaderCell>POD order number</HeaderCell>
                <Cell>
                  <div>  </div>
                </Cell>
              </Column>  */}

          <Column width={150} sortable resizable>
            <HeaderCell>Face Value (£)</HeaderCell>
            <Cell dataKey="faceValue">
              {(rowData, rowIndex) => {
                return <div className="admin-payments-table-highlight"> {rowData.faceValue ? rowData.faceValue : '-'} </div>
              }}
            </Cell>
          </Column>
          <Column width={150} sortable resizable>
            <HeaderCell>Discount (%)</HeaderCell>
            <Cell dataKey="discount">
              {(rowData, rowIndex) => {
                return <div className="admin-payments-table-highlight"> {rowData.discount ? `${rowData.discount}%` : '-'} </div>
              }}
            </Cell>
          </Column>
          <Column width={150} sortable resizable>
            <HeaderCell>Step</HeaderCell>
            <Cell dataKey="discountStep" >
              {(rowData, rowIndex) => {
                if (rowData.discountStep) {
                  return <div className="admin-payments-table-highlight"> {`Step ${rowData.discountStep}`} </div>
                } else {
                  return <div className="admin-payments-table-highlight"> - </div>
                }
              }}
            </Cell>
          </Column>

          <ColumnGroup className="text-center" header="Customer payment (£)">
            <Column width={150} sortable resizable>
              <HeaderCell>Voucher price (£)</HeaderCell>
              <Cell dataKey="amount_captured_voucher">
                {(rowData, rowIndex) => {
                  if (rowData.amount_captured_voucher) {
                    return <div className="admin-payments-table-highlight"> {(rowData.amount_captured_voucher / 100).toFixed(2)} </div>
                  } else {
                    return <div className="admin-payments-table-highlight"> - </div>
                  }
                }}
              </Cell>
            </Column>
            <Column width={150} sortable resizable>
              <HeaderCell>Gifting price (£)</HeaderCell>
              <Cell dataKey="amount_captured_gift">
                {(rowData, rowIndex) => {
                  if (rowData.amount_captured_gift) {
                    return <div className="admin-payments-table-highlight"> {(rowData.amount_captured_gift / 100).toFixed(2)} </div>
                  } else {
                    return <div className="admin-payments-table-highlight"> - </div>
                  }
                }}
              </Cell>
            </Column>
            <Column width={150} sortable resizable>
              <HeaderCell>Total (£)</HeaderCell>
              <Cell dataKey="amount_captured">
                {(rowData, rowIndex) => {
                  if (rowData.amount_captured) {
                    return <div className="admin-payments-table-highlight"> {(rowData.amount_captured / 100).toFixed(2)} </div>
                  } else {
                    return <div className="admin-payments-table-highlight"> - </div>
                  }
                }}
              </Cell>
            </Column>
          </ColumnGroup>

          {/*  <ColumnGroup className="text-center" header="Company (£)">  */}
            <Column width={150} sortable resizable>
              <HeaderCell>Payment to company (£)</HeaderCell>
              <Cell dataKey="amount_captured">
                {(rowData, rowIndex) => {
                  if (rowData.amount_captured) {
                    return <div className="admin-payments-table-default"> {((rowData.amount_captured_voucher - (rowData.commission || 0)) / 100).toFixed(2)} </div>
                  } else {
                    return <div className="admin-payments-table-default"> - </div>
                  }
                }}
              </Cell>
            </Column>
            {/* <Column width={0}>
              <HeaderCell></HeaderCell>
              <Cell dataKey="amount_captured">
                {(rowData, rowIndex) => {
                  return <div/>
                }}
              </Cell>
            </Column>
          </ColumnGroup> */}

        {/*  <ColumnGroup className="text-center" header="POD (£)">
            <Column width={150} sortable resizable>
              <HeaderCell>Card</HeaderCell>
              <Cell dataKey="amount_captured">
                {(rowData, rowIndex) => {
                  return <div> 0 </div>
                }}
              </Cell>
            </Column>
            <Column width={0}>
              <HeaderCell></HeaderCell>
              <Cell dataKey="amount_captured">
                {(rowData, rowIndex) => {
                  return <div/>
                }}
              </Cell>
            </Column>
          </ColumnGroup> */}

          <ColumnGroup className="text-center" header="Payment to Steppr">
            <Column width={150} sortable resizable>
              <HeaderCell>Commission (%)</HeaderCell>
              <Cell dataKey="commissionPercent">
                {(rowData, rowIndex) => {
                  return <div className="admin-payments-table-highlight"> {rowData.commissionPercent ? rowData.commissionPercent * 100 : '-'} </div>
                }}
              </Cell>
            </Column>
            <Column width={150} sortable resizable>
              <HeaderCell>Commission (£)</HeaderCell>
              <Cell dataKey="commission">
                {(rowData, rowIndex) => {
                  if (rowData.commission) {
                    return <div className="admin-payments-table-highlight"> {(rowData.commission / 100).toFixed(2)} </div>
                  } else {
                    return <div className="admin-payments-table-highlight"> - </div>
                  }
                }}
              </Cell>
            </Column>
            <Column width={150} sortable resizable>
              <HeaderCell>Gifting (£)</HeaderCell>
              <Cell dataKey="card_fee">
                {(rowData, rowIndex) => {
                  return <div className="admin-payments-table-highlight"> {rowData.amount_captured_gift ? ((rowData.amount_captured_gift) / 100).toFixed(2) : '-'} </div>
                }}
              </Cell>
            </Column>
            <Column width={150} sortable resizable>
              <HeaderCell>Total (£)</HeaderCell>
              <Cell dataKey="commission">
                {(rowData, rowIndex) => {
                  if (rowData.commission || rowData.amount_captured_gift) {
                    return <div className="admin-payments-table-highlight"> {(((rowData.commission || 0) + (rowData.amount_captured_gift || 0)) / 100).toFixed(2)} </div>
                  } else {
                    return <div className="admin-payments-table-highlight"> - </div>
                  }
                }}
              </Cell>
            </Column>
          </ColumnGroup>


          <ColumnGroup className="text-center" header="Gifting information">
            <Column width={150} resizable>
              <HeaderCell>Recipient name</HeaderCell>
              <Cell dataKey="recipientName">
                {(rowData, rowIndex) => {
                  return <div className="admin-payments-table-default"> {rowData.giftingData && rowData.giftingData.name ? rowData.giftingData.name : '-'} </div>
                }}
              </Cell>
            </Column>
            <Column width={150} resizable>
              <HeaderCell>Recipient email</HeaderCell>
              <Cell dataKey="recipientEmail">
                {(rowData, rowIndex) => {
                  return <div className="admin-payments-table-default" > {rowData.giftingData && rowData.giftingData.email ? rowData.giftingData.email : '-'} </div>
                }}
              </Cell>
            </Column>
            <Column width={210} resizable>
              <HeaderCell>Scheduled</HeaderCell>
              <Cell dataKey="giftDate">
                {(rowData, rowIndex) => {
                  if (rowData.giftingData && rowData.giftingData.date) {
                    if (rowData.scheduledEmailSent) {
                      return <div className="admin-payments-table-default">
                        <span>{'Sent ('}</span>
                        <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                          {rowData.giftingData.date.seconds * 1000}
                        </Moment>
                        <span>{')'}</span>
                      </div>
                    }
                    return (
                      <div className="admin-payments-table-default">
                        <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                          {rowData.giftingData.date.seconds * 1000}
                        </Moment>
                      </div>
                    );
                  }

                  return <div className="admin-payments-table-default"> {'-'} </div>
                }}
              </Cell>
            </Column>
            <Column width={150} resizable>
              <HeaderCell>Resend email</HeaderCell>
              <Cell dataKey="commission">
                {(rowData, rowIndex) => {
                  if (rowData.giftingData && rowData.gift) {
                    return (
                      <div className="admin-payments-table-default">
                        <Button color="link" disabled={showNoPermission} style={{ paddingTop: 3, paddingBottom: 3 }} onClick={() => this.openResendEmail(rowData.chargeId, rowData.dbId)}>
                          Enter email
                          {(resendEmailProcessing === rowData.chargeId) ? (
                            <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                          ) : (null)}
                        </Button>
                      </div>
                    )
                  }
                  return <div className="admin-payments-table-default"> {'-'} </div>
                }}
              </Cell>
            </Column>
          </ColumnGroup>

          <ColumnGroup className="text-center" header="Refund">
            <Column width={150} resizable>
              <HeaderCell>Voucher</HeaderCell>
              <Cell dataKey="refund button">
                {(rowData, rowIndex) => {
                    if (rowData.voucherRefunded) {
                      return (
                        <div className="admin-payments-table-default">
                          {rowData.voucherRefundDate && rowData.voucherRefundDate.seconds ? (
                            <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                              {rowData.voucherRefundDate.seconds ? (rowData.voucherRefundDate.seconds * 1000) : rowData.voucherRefundDate}
                            </Moment>
                          ):(null)}
                         </div>
                       )
                    } else {
                      return (
                        <div className="admin-payments-table-default">
                          <Button
                            color="link"
                            disabled={showNoPermission}
                            style={{ paddingTop: 3, paddingBottom: 3 }}
                            onClick={() => this.openConfirmModal('refundVoucher', rowData.chargeId, rowData.dbId)}
                          >
                            Refund
                            {(refundProcessing === rowData.chargeId) ? (
                              <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                            ):(null)}
                          </Button>
                        </div>
                      )
                    }
                }}
              </Cell>
            </Column>
            <Column width={150} resizable>
              <HeaderCell>Gifting</HeaderCell>
              <Cell dataKey="refund button">
                {(rowData, rowIndex) => {
                  if (!rowData.gift) {
                    return <div> - </div>
                  } else if (rowData.giftRefunded) {
                    return (
                      <div className="admin-payments-table-default">
                        {rowData.giftRefundDate && rowData.giftRefundDate.seconds ? (
                          <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                            {rowData.giftRefundDate.seconds ? (rowData.giftRefundDate.seconds * 1000) : rowData.giftRefundDate}
                          </Moment>
                        ) : (null)}
                      </div>
                    )
                  } else {
                    return (
                      <div className="admin-payments-table-default">
                        <Button
                          color="link"
                          disabled={showNoPermission}
                          style={{ paddingTop: 3, paddingBottom: 3 }}
                          onClick={() => this.openConfirmModal('refundGifting', rowData.chargeId, rowData.dbId)}
                        >
                          Refund
                          {(refundProcessing === rowData.chargeId) ? (
                            <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                          ) : (null)}
                        </Button>
                      </div>
                    )
                  }
                }}
              </Cell>
            </Column>
            <Column width={150} resizable>
              <HeaderCell>Both</HeaderCell>
              <Cell dataKey="refund button">
                {(rowData, rowIndex) => {
                  if (!rowData.gift) {
                    return <div> - </div>
                  } if (rowData.refunded) {
                    return (
                      <div className="admin-payments-table-default">
                        {rowData.refundDate && rowData.refundDate.seconds ? (
                          <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                            {rowData.refundDate.seconds ? (rowData.refundDate.seconds * 1000) : rowData.refundDate}
                          </Moment>
                        ) : (null)}
                      </div>
                    )
                  } else if (rowData.voucherRefunded || rowData.giftRefunded) {
                    return <div> - </div>
                  } else {
                    return (
                      <div className="admin-payments-table-default">
                        <Button
                          color="link"
                          disabled={showNoPermission}
                          style={{ paddingTop: 3, paddingBottom: 3 }}
                          onClick={() => this.openConfirmModal('refundAll', rowData.chargeId, rowData.dbId, 'all')}
                        >
                          Refund
                          {(refundProcessing === rowData.chargeId) ? (
                            <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                          ) : (null)}
                        </Button>
                      </div>
                    )
                  }
                }}
              </Cell>
            </Column>
          </ColumnGroup>
            <Column width={150} resizable>
              <HeaderCell>Cancel</HeaderCell>
              <Cell dataKey="refund button">
                {(rowData, rowIndex) => {
                    if (rowData.canceled) {
                      console.log(rowData);
                      return (
                        <div className="admin-payments-table-default">
                          {rowData.canceledDate ? (
                            <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                              {rowData.canceledDate}
                            </Moment>
                          ):(null)}
                        </div>
                      )
                    } else if (rowData.used) {
                      return <div> Used </div>
                    } else {
                      return (
                        <div className="admin-payments-table-default">
                          <Button
                            color="link"
                            disabled={showNoPermission}
                            style={{ paddingTop: 3, paddingBottom: 3 }}
                            onClick={() => this.openConfirmModal('cancel', rowData.chargeId, rowData.dbId)}
                          >
                            Cancel
                            {(cancelProcessing === rowData.chargeId) ? (
                              <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                            ):(null)}
                          </Button>
                        </div>
                      )
                    }
                }}
              </Cell>
            </Column>
          {/*  <Column width={150} resizable>
              <HeaderCell>Refund & Cancel</HeaderCell>
              <Cell dataKey="refund button">
                {(rowData, rowIndex) => {
                  if (rowData.refunded && rowData.canceled) {

                    return <div> Done </div>
                  } else if (rowData.used && rowData.usedDate) {
                    return <div> Used </div>
                  } else {
                    return (
                      <Button color="link" disabled={showNoPermission} style={{ paddingTop: 3, paddingBottom: 3 }} onClick={() => this.refundCancelPayment(rowData.chargeId, rowData.dbId, rowData.refunded, rowData.canceled)}>
                        Refund & Cancel
                        {(refundCancelProcessing === rowData.chargeId) ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ):(null)}
                      </Button>
                    )
                  }
                }}
              </Cell>
            </Column> */}
        </Table>
        <Button className="admin-table-more-button" color="primary" onClick={() => this.downloadData()}>
          Download Data
          {downloadSpinner ? (
            <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
          ) : (null)}
        </Button>
        <Modal isOpen={confirmModalOpen} toggle={this.closeConfirmModal}>
          <ModalBody toggle={this.closeDeleteUser}>
            <Row>
              <Col xs="12">
                {(!!confirmModalError) && <Alert color="danger">{`Failed to finish action - ${confirmModalError}`}</Alert>}
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                Are you sure?
              </Col>
              <Col xs="6">
                <Button color="secondary" style={{ float: 'right' }} onClick={this.closeConfirmModal}>No</Button>
                <Button color="primary" style={{ float: 'right', marginRight: 8 }} className="custom-modal-button" onClick={this.finishConfirmModal}>
                  Yes
                  {(confirmModalProcessing) ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                  ) : (null)}
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Modal isOpen={resendEmailOpen} toggle={this.closeResendEmail} className="centered-modal">
          <ModalBody toggle={this.closeResendEmail}>
            <Row>
              <Col xs="12">
                {(!!resendEmailError) && <Alert color="danger">{`Failed to finish action - ${resendEmailError}`}</Alert>}
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Input
                  type="text"
                  name="resendEmailValue"
                  id="resendEmailValue"
                  placeholder="Enter email address"
                  value={resendEmailValue}
                  onChange={this.handleChange}
                />
              </Col>
              <Col xs="12" style={{ marginTop: 8 }}>
                <Button color="secondary" style={{ float: 'right' }} onClick={this.closeResendEmail}>Close</Button>
                <Button color="primary" style={{ float: 'right', marginRight: 8 }} className="custom-modal-button" onClick={this.resendEmail}>
                  Send
                  {(resendEmailProcessing) ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                  ) : (null)}
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  users: state.admin.companyOwners || {},
});

const mapDispatchToProps = {

};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AllPaymentsTable));
