import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';

const env = {
  STRIPE_KEY: "sk_test_UhqmoyeD8ZJ5BHbC0waG2F6x",
  FIREBASE_API_KEY: "AIzaSyDm5JrYZAjhq6Gsryg5Fvt5nW0Qy30K5QQ",
  FIREBASE_AUTH_DOMAINS: "steppr-development.firebaseapp.com",
  FIREBASE_DATABASE_UR: "https://steppr-development-default-rtdb.firebaseio.com",
  FIREBASE_PROJECT_ID: "steppr-development",
  FIREBASE_STORAGE_BUCKET: "steppr-development.appspot.com",
  FIREBASE_MESSAGE_SENDER_ID: "960700490201",
  FIREBASE_APP_ID: "1:960700490201:web:e56511f889eca4f16979c8",
  REACT_APP_STRIPE_KEY: "sk_test_UhqmoyeD8ZJ5BHbC0waG2F6x",
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAINS,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();
const storage = firebase.storage();
const auth = firebase.auth();
const functions = firebase.functions();

export { storage, firestore, auth, functions, firebase }
