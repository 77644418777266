import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState , convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';

import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { Row, Col, Button, Input, Label, Form, FormGroup, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { connect } from 'react-redux';

import FileUploader from "react-firebase-file-uploader";
import ReactPlayer from 'react-player/lazy';

// import RLDD from 'react-list-drag-and-drop/lib/RLDD';

import {
  getTutorialVideos,
  uploadTutorialVideo,
  editTutorialVideo,
  deleteTutorialVideo,
  changeOrderIdTutorialVideo,
  getResourceCentre,
  updateResourceCentre,
  getResourceCentreTopLinks,
  updateResourceCentreTopLinks,
} from '../../../../../actions/resourceCenter';

import { storage } from '../../../../../lib/firebase';

import notifications from '../../../../../const/notifications';

class Videos extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    fetchTutorialVideos: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
    fetchResourceCentre: PropTypes.func.isRequired,
    editResourceCentre: PropTypes.func.isRequired,
    fetchResourceCentreTopLinks: PropTypes.func.isRequired,
    editResourceCentreTopLinks: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      videos: [],
      editorState: EditorState.createEmpty(),
      editorStateTopLinks: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    const { fetchTutorialVideos, fetchResourceCentre, fetchResourceCentreTopLinks } = this.props;

  //  fetchResourceCentre().then((res) => {
  //    this.setState({ resourceCentreText: res.payload || '' });
  //  });
    fetchResourceCentre().then((res) => {
      if (res.payload && res.payload) {
      //  let contentState = stateFromHTML(res.payload);

        const blocksFromHtml = htmlToDraft(res.payload);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        if (contentState) {
          this.setState({ editorState: EditorState.createWithContent(contentState) });
        }
      }
    });

    fetchResourceCentreTopLinks().then((res) => {
      if (res.payload && res.payload) {
        //  let contentState = stateFromHTML(res.payload);

        const blocksFromHtml = htmlToDraft(res.payload);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        if (contentState) {
          this.setState({ editorStateTopLinks: EditorState.createWithContent(contentState) });
        }
      }
    });

    fetchTutorialVideos().then((res) => {
      if (res && res.payload) {
        let videos = [];

        if (res.payload.length) {
        }
      }
    });
  }

  handleChangeText = (editorState) => {
    this.setState({ editorState })
  };

  handleChangeTopLinks = (editorState) => {
    this.setState({ editorStateTopLinks: editorState })
  };

  saveText = (type) => {
    const { editResourceCentre, editResourceCentreTopLinks } = this.props;
    const { editorState, editorStateTopLinks } = this.state;

    if (type === 'topLinks') {
      if (editorStateTopLinks) {
        let html = stateToHTML(editorStateTopLinks.getCurrentContent());

        const rawContentState = convertToRaw(editorStateTopLinks.getCurrentContent());

        const markup = draftToHtml(
          rawContentState
        );

        editResourceCentreTopLinks(markup).then().catch(err => {
          console.log(err);
          this.setState({ error: err });
        });
      }
    } else {
      if (editorState) {
        let html = stateToHTML(editorState.getCurrentContent());

        const rawContentState = convertToRaw(editorState.getCurrentContent());

        const markup = draftToHtml(
          rawContentState
        );

        editResourceCentre(markup).then().catch(err => {
          console.log(err);
          this.setState({ error: err });
        });


      }
    }
  }

/*  handleChangeText = event => {
    this.setState({ resourceCentreText: event.target.value });
  }

  saveText = () => {
    const { editResourceCentre, user } = this.props;
    const { resourceCentreText } = this.state;

    if (!resourceCentreText) {
      this.setState({ error: 'Missing resourceCentreText' });
      return null;
    }

    editResourceCentre(resourceCentreText).then(() => this.setState({ success: true, error: null })).catch(err => {
      if (err && err.message) {
        this.setState({ success: false, error: err.message });
      } else {
        this.setState({ success: false, error: err });
      }
    });
  } */

  handleChangeTitle = event =>
    this.setState({ title: event.target.value });

//  handleChangeDescription = event =>
//    this.setState({ description: event.target.value });

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = progress => this.setState({ progress });

  handleUploadError = error => {
    this.setState({ isUploading: false, error });
    console.error(error);
  };

  handleUploadSuccess = filename => {
    console.log('filename', filename);
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    storage
      .ref("tutorialVideos")
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ url, filename }));
  };

  saveVideo = () => {
    const { uploadVideo, user } = this.props;
    const { url, title, description } = this.state;

    if (!title) {
      this.setState({ error: notifications.admin.resourceCentre.missingTitle || 'Missing title' });
      return null;
    }

  //  if (!description) {
  //    this.setState({ error: 'Missing description' });
  //    return null;
  //  }

    if (!url) {
      this.setState({ error: notifications.admin.resourceCentre.missingVideo || 'Missing video' });
      return null;
    }

    this.setState({ addVideoModal: false });

    uploadVideo(url, title).then(() => this.setState({ success: true, error: null, addVideoModal: false, url: null, filename: null, title: null })).catch(err => {
      if (err && err.message) {
        this.setState({ success: false, error: err.message });
      } else {
        this.setState({ success: false, error: err });
      }
    });
  }

  deleteVideo = (id) => {
    const { deleteVideo } = this.props;

    if (id) {
      deleteVideo(id);
    }
  }

  openAdd = () => {
    this.setState({ addVideoModal: true });
  }

  closeAddModal = () => {
    const { filename } = this.state;

    this.setState({ error: null, addVideoModal: false, url: null, filename: null, title: null });

    storage
      .ref("tutorialVideos")
      .child(filename)
      .delete();
  }

  openView = (url) => {
    this.setState({ viewVideoModal: true, activeVideo: url });
  }

  closeViewModal = () => {
    this.setState({ viewVideoModal: false, activeVideo: null });
  }

  openEdit = (video) => {
    this.setState({ editVideoModal: true, editId: video.id, url: video.video, filename: null, title: video.title });
  }

  closeEditModal = () => {
    const { filename } = this.state;

    this.setState({ editVideoModal: false, editId: null, url: null, filename: null, title: null });

    if (filename) {
      storage
        .ref("tutorialVideos")
        .child(filename)
        .delete();
    }
  }

  saveEditedVideo = () => {
    const { editVideo } = this.props;
    const { url, title, editId } = this.state;


    if (!title) {
      this.setState({ error: notifications.admin.resourceCentre.missingTitle || 'Missing title' });
      return null;
    }

  //  if (!description) {
  //    this.setState({ error: 'Missing description' });
  //    return null;
  //  }

    if (!url) {
      this.setState({ error: notifications.admin.resourceCentre.missingVideo || 'Missing video' });
      return null;
    }

    this.setState({ editVideoModal: false });

    editVideo(editId, url, title).then(() => this.setState({ success: true, error: null, editVideoModal: false, editId: null, url: null, filename: null, title: null })).catch(err => {
      console.log(err);

      if (err && err.message) {
        this.setState({ success: false, error: err.message });
      } else {
        this.setState({ success: false, error: err });
      }
    });
  }

  openDelete = (id) => {
    this.setState({ deleteVideoModal: true, deleteId: id });
  }

  closeDeleteModal = () => {
    this.setState({ deleteVideoModal: false, deleteId: null });
  }

  deleteVideo = () => {
    const { deleteVideo } = this.props;
    const { deleteId } = this.state;

    if (deleteId) {
      deleteVideo(deleteId).then(() => {
        this.setState({ deleteVideoModal: false, deleteId: null });
      })
    }
  }

  handleChangeTitle = event =>
    this.setState({ title: event.target.value });

/*
  handleRLDDChange = (newItems) => {
    const { changeOrderId, fetchTutorialVideos } = this.props;
    const { isDragging } = this.state;
    console.log('handleRLDDChange', { newItems, isDragging });

    this.setState({ videos: newItems });
    changeOrderId(newItems).then((res) => {
      if (res === 'success') {
        fetchTutorialVideos().then((res2) => {
          console.log('fetchTutorialVideos res', { res2 });
          if (res2 && res2.payload && res2.payload.length) {
            this.setState({ videos: res2.payload });
          }
        });
      }
    });
  } */

  toggleReadMore = (id) => {
    const { readMore } = this.state;

    if (readMore) {
      if (readMore === id) {
        this.setState((prevState) => ({ readMore: null, expandedRowKeys: [] }))
      } else {
        this.setState((prevState) => ({ readMore: id, expandedRowKeys: [id] }))
      }
    } else {
      this.setState((prevState) => ({ readMore: id, expandedRowKeys: [id] }))
    }
  }

  startDrag = () => {
    this.setState({ isDragging: true });
  }

  endDrag = () => {
    this.setState({ isDragging: false });
  }

  handleDrag = (e, id) => {
    this.setState({ dragId: id });
  }

  handleDrop = (e, id) => {
    const { videos, changeOrderId, fetchTutorialVideos } = this.props;
    const { dragId } = this.state;

    const newVideos = [...videos];

    var element = newVideos[dragId];
    newVideos.splice(dragId, 1);
    newVideos.splice(id, 0, element);

    changeOrderId(newVideos).then((res) => {
      if (res === 'success') {
        fetchTutorialVideos().then((res2) => {
          if (res2 && res2.payload && res2.payload.length) {
            this.setState({ videos: res2.payload });
          }
        });
      }
    });
  }

  render() {
    const { videos, showNoPermission } = this.props;
    const {
      error,
      title,
    //  description,
      addVideoModal,
      viewVideoModal,
      activeVideo,
      editVideoModal,
      deleteVideoModal,
      readMore,
      resourceCentreText,
      editorState,
      editorStateTopLinks,
    } = this.state;

    return (
      <div>
      {/* <Row>
        <Col xs="12">
          <FormGroup>
            <Editor
              className="content-management-text-area"
              editorState={editorStateTopLinks}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={this.handleChangeTopLinks}
            />
          </FormGroup>
          <Button disabled={showNoPermission} onClick={() => this.saveText('topLinks')}>
            Save text
          </Button>
        </Col>
        </Row> */}

        <Row>
          <Col xs="12">
            <FormGroup>
              <Editor
                className="content-management-text-area"
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={this.handleChangeText}
              />
            </FormGroup>
            <Button disabled={showNoPermission} onClick={() => this.saveText('text')}>
              Save text
            </Button>
          </Col>
        </Row>

      <Row>
        <Col xs="12">
          <h3>
            Videos
          </h3>
        </Col>
      </Row>

      {videos ? (videos.map((video, index) => {
        if (video && video.count && !video.video) {
          return null;
        }

        return (
          <Row
            id={video.id}
            onDragOver={(ev) => ev.preventDefault()}
            onDrop={(e) => this.handleDrop(e, index)}
            style={{ paddingTop: 10 }}
            key={video.video}
          >
            <Col sm="1">
              <Button disabled={showNoPermission} className="drag-order-toggle" draggable={true} onDragStart={(e) => this.handleDrag(e, index)} key={`drag-${video.video}`}>
                +
              </Button>
            </Col>
            <Col sm="5">
              {video.title}
            </Col>
          {/*  <Col sm="5">
             <div className={(readMore && readMore === video.id) ? 'text-read-more-text' : 'text-read-more-text-hidden'}>
                {video.description}
              </div>
              <Button className="text-read-more" color="link" onClick={() => this.toggleReadMore(video.id)}>
                {(readMore && readMore === video.id) ? 'show less' : 'read more'}
              </Button>
            </Col>  */}
            <Col>
              <Button style={{ marginRight: 5 }} onClick={() => this.openView(video.video)}>
                View
              </Button>
              <Button disabled={showNoPermission} style={{ marginRight: 5 }} onClick={() => this.openEdit(video)}>
                Edit
              </Button>
              <Button disabled={showNoPermission} onClick={() => this.openDelete(video.id)}>
                Delete
              </Button>
            </Col>
          </Row>
        )}
      )) : (null)}

      <Button disabled={showNoPermission} onClick={() => this.openAdd()}>
        Add video
      </Button>

      <Modal isOpen={viewVideoModal} toggle={this.closeViewModal}>
        <ModalHeader toggle={this.closeViewModal}>
        </ModalHeader>
        <ModalBody>
          <ReactPlayer id="myVedio"
            url={activeVideo}
            width='100%'
            height='100%'
            playing={false}
            muted={false}
            controls={true}
            volume={1}
            progressInterval={5000}
            pip={true}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.closeViewModal}>Close</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={addVideoModal} toggle={this.closeAddModal}>
        <ModalHeader toggle={this.closeAddModal}>
          Add video
        </ModalHeader>
        <ModalBody>
          <Form>
            {!!error ? (<Alert color="danger"> {error} </Alert>) : (null)}
            <FormGroup>
              <Label>Title</Label>
              <Input
                type="text"
                value={title}
                name="title"
                onChange={this.handleChangeTitle}
              />
            {/*  <Label>Description</Label>
              <Input
                type="text"
                value={description}
                name="description"
                onChange={this.handleChangeDescription}
              /> */}
              <Label>Video</Label>
              {this.state.url ? (
                <ReactPlayer id="myVideo"
                  url={this.state.url}
                  width='40%'
                  height='40%'
                  playing={false}
                  muted={true}
                  controls={true}
                  volume={0}
                  progressInterval={5000}
                  pip={false}
                />
              ) : null}
              {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
              <FileUploader
                accept="video/*"
                name="video"
                randomizeFilename
                storageRef={storage.ref("tutorialVideos")}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.saveVideo}>Save</Button>
          <Button color="secondary" onClick={this.closeAddModal}>Close</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={editVideoModal} toggle={this.closeEditModal}>
        <ModalHeader toggle={this.closeEditModal}>
          Edit video
        </ModalHeader>
        <ModalBody>
          <Form>
            {!!error ? (<Alert color="danger"> {error} </Alert>) : (null)}
            <FormGroup>
              <Label>Title</Label>
              <Input
                type="text"
                value={title}
                name="title"
                onChange={this.handleChangeTitle}
              />
            {/*  <Label>Description</Label>
              <Input
                type="text"
                value={description}
                name="description"
                onChange={this.handleChangeDescription}
              />  */}
              <Label>Video</Label>
              {this.state.url ? (
                <ReactPlayer id="myVideo"
                  url={this.state.url}
                  width='40%'
                  height='40%'
                  playing={false}
                  muted={true}
                  controls={true}
                  volume={0}
                  progressInterval={5000}
                  pip={false}
                />
              ) : null}
              {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
              <FileUploader
                accept="video/*"
                name="video"
                randomizeFilename
                storageRef={storage.ref("tutorialVideos")}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.saveEditedVideo}>Save</Button>
          <Button color="secondary" onClick={this.closeEditModal}>Close</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={deleteVideoModal} toggle={this.closeDeleteModal}>
        <ModalHeader toggle={this.closeDeleteModal}>
          Are you sure you want to delete this video?
        </ModalHeader>
        <ModalBody>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.deleteVideo}>Delete</Button>
          <Button color="secondary" onClick={this.closeEditModal}>Close</Button>
        </ModalFooter>
      </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  videos: state.resourceCenter.videos || [],
});

const mapDispatchToProps = {
  fetchTutorialVideos: getTutorialVideos,
  uploadVideo: uploadTutorialVideo,
  editVideo: editTutorialVideo,
  deleteVideo: deleteTutorialVideo,
  changeOrderId: changeOrderIdTutorialVideo,
  fetchResourceCentre: getResourceCentre,
  editResourceCentre: updateResourceCentre,
  fetchResourceCentreTopLinks: getResourceCentreTopLinks,
  editResourceCentreTopLinks: updateResourceCentreTopLinks,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Videos));

// export default Dashboard;
