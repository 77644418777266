import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';

import { Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label, Form, FormGroup, Alert, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';

import { connect } from 'react-redux';

import {
  getCompanyList,
} from '../../../actions/admin';

import {
  getAllEmailSettings,
  addEmailSetting,
  updateEmailSetting,
  removeEmailSetting,
  addCompanyNotification,
  deleteCompanyNotification,
  getCompanyNotifications,
  getCompanyEmailInformation,
  updateCompanyEmailInformation,
} from '../../../actions/notifications';

import {
  getUserData,
} from '../../../actions/user';

import notifications from '../../../const/notifications';

class Notifications extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    getEmailSettings: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    createEmailSetting: PropTypes.func.isRequired,
    deleteEmailSetting: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired,
    deleteNotification: PropTypes.func.isRequired,
    fetchCompanyNotifications: PropTypes.func.isRequired,
    fetchCompanyList: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      trigger: "companyDetailsUpdated",
      enabled: false,
      triggers: {
        companyDetailsUpdated: 'Company Details Updated',
        voucherCreated: 'Voucher campaign creation',
        voucherUpdate: 'Changes to voucher campaign',
        voucherRemoved: 'Voucher campaign removed',
        companyUserAdded: 'Company users added',
        companyUserDeleted: 'Company users deleted',
        companyOwnerAdded: 'Main company account created',
        companyOwnerDeleted: 'Main company account deleted',
        accountStatusChange: 'Account verification status change',
        accountChange: 'Account change',
      },
      deleteSettingModal: false,
      deleteSettingErr: null,
      settingToDelete: null,
      activeTab: '1',
      companyNotification: '',
      allChecked: true,
      checked: {},
      companyNotifications: [],
      companyInformation: '',
    };
  }

  componentDidMount() {
    const { fetchCompanyList, getEmailSettings, fetchCompanyInformation, user, isLoggedIn, history } = this.props;

    if (user && Object.keys(user).length > 0) {
      if (!user || !isLoggedIn || !user || !user.roleClass || user.roleClass !== 'admin') {
        history.push('/');
      } else {
        fetchCompanyList();
        this.getCompanyNotifications();
        fetchCompanyInformation().then((res) => {
          this.setState({ companyInformation: res || '' });
        });

        if (user.role === 'admin') {
          this.setState({ showNoPermission: false, dontShowPage: false });
        } else {
          const { adminsRoles } = this.props;

          if (adminsRoles && adminsRoles[user.role] && adminsRoles[user.role].permissions && adminsRoles[user.role].permissions.notifications) {
            if (adminsRoles[user.role].permissions.notifications === 2) {
              this.setState({ showNoPermission: false, dontShowPage: false });
            } else {
              this.setState({ showNoPermission: true, dontShowPage: false });
            }
          } else {
            this.setState({ showNoPermission: true, dontShowPage: true });
          }
        }

        getEmailSettings().then((res2) => {
          let emailSettings = {};

          Object.keys(res2).map((key, index) => {
            emailSettings[key] = res2[key].name;
            return null;
          });

          if (emailSettings) {
            this.setState(prevState => ({ emailSettings: { ...prevState.emailSettings, ...emailSettings } }))
          }
        })
      }
    } else {
      history.push('/');
    }
  }

  getCompanyNotifications = () => {
    const { fetchCompanyNotifications } = this.props;

    fetchCompanyNotifications().then((res) => {
      let companyNotifications = res.payload || [];

      this.setState({ companyNotifications });
    })
  }

  handleChange = e => this.setState({ error: null, [e.target.name]: e.target.value });

  handleChangeEnabled = e => this.setState({ error: null, enabled: e.target.checked });

  handleChangeCompanyInformation = e => this.setState({ error: null, companyInformation: e.target.value });

  updateCompanyInformation = () => {
    const { updateCompanyInformation } = this.props;
    const { companyInformation } = this.state;

    if (companyInformation) {
      updateCompanyInformation(companyInformation);
    }
  }

  toggleEnabled = (id) => {
    console.log(id);
  }

  selectTrigger = (e) => {
    this.setState({ error: null, trigger: e.target.value });
  }

  openCreateEmailSetting = () => {
    this.setState({ error: null, createEmailSettingModal: true, trigger: "companyDetailsUpdated" });
  }

  closeCreateEmailSetting = () => {
    this.setState({ createEmailSettingModal: false });
  }

  createEmailSetting = () => {
    const { createEmailSetting } = this.props;
    const { trigger, subject, content, enabled } = this.state;

    console.log('createEmailSetting', trigger, subject, content, enabled);

    createEmailSetting(trigger, subject, content, enabled ).then(res => {
      this.setState({ createEmailSettingModal: false, trigger: null, subject: null, content: null, enabled: false });
    }).catch(err => {
      console.log(err);
      this.setState({ error: notifications.admin.notifications.emailSettings[err] || err });
    })
  }

  openEditEmailSetting = (e, id) => {
    e.preventDefault();
    const { emailSettings } = this.props;

    if (emailSettings && emailSettings[id]) {
      const setting = emailSettings[id];

      this.setState({ editEmailSettingModal: true, trigger: setting.trigger, subject: setting.subject, content: setting.content, enabled: setting.enabled, editId: id });
    }
  }

  closeEditEmailSetting = () => {
    this.setState({ editEmailSettingModal: false });
  }

  editEmailSetting = () => {
    const { editEmailSetting } = this.props;
    const { trigger, subject, content, enabled, editId } = this.state;

    console.log('editEmailSetting', trigger, subject, content, enabled, editId);

    editEmailSetting(trigger, subject, content, enabled, editId ).then(res => {
      this.setState({ editEmailSettingModal: false, trigger: null, subject: null, content: null, enabled: false });
    }).catch(err => {
      console.log(err);
    })
  }

  toggleDeleteSetting = (e, id) => {
    e.stopPropagation();

    const { deleteSettingModal } = this.state;
    if (deleteSettingModal) {
      this.setState({ deleteSettingModal: false, settingToDelete: null, deleteSettingErr: null });
    } else {
      this.setState({ deleteSettingModal: true, settingToDelete: id, deleteSettingErr: null });
    }
  }

  closeDeleteSetting = () => {
    this.setState({ deleteSettingModal: false, settingToDelete: null, deleteSettingErr: null });
  }


  deleteEmailSetting = () => {
    const { deleteEmailSetting } = this.props;
    const { settingToDelete } = this.state;

    deleteEmailSetting(settingToDelete).then(res => {
      this.setState({ deleteSettingModal: false, settingToDelete: null, deleteSettingErr: null });
    }).catch(err => {
      console.log(err);
    })
  }

  toggle = (tab) => {
    const { activeTab } = this.state;

    if (activeTab !== tab) {
      this.setState({
        error: null,
        activeTab: tab,
      });
    }
  }

  addNotification = () => {
    const { addNotification } = this.props;
    const { companyNotification, allChecked, checked } = this.state;

    console.log('addNotification', companyNotification, allChecked, checked);

    if (!companyNotification) {
      this.setState({ error: notifications.admin.notifications.companyAccounts.noNotification || "Missing notification" });

      return null;
    }

    let companies = checked;

    if (allChecked) {
      companies = 'all';
    }

    addNotification(companyNotification, companies).then((res) => {
      console.log('addNotification res', res);
      if (res === 'success') {
        this.getCompanyNotifications();
      }
    }).catch(err => {
      console.log(err);

      if (err === 'no data') {
        this.setState({ error: notifications.admin.notifications.companyAccounts.noData || err });
      } else if (err === 'no companies') {
        this.setState({ error: notifications.admin.notifications.companyAccounts.noCompanies || err });
      } else {
        this.setState({ error: err });
      }
    });
  }

  deleteCompanyNotification = (id) => {
    const { deleteNotification } = this.props;

    deleteNotification(id).then((res) => {
      if (res === 'success') {
        this.getCompanyNotifications();
      } else {
        this.setState({ error: notifications.admin.notifications.companyAccounts.failedDelete || 'Failed to delete notification' });
      }
    });
  }

  checkAll = (e) => {
    const { companiesList } = this.props;
    const { allChecked } = this.state;

    const isChecked = e.target.checked;

    if (isChecked) {
      const checked = {};

      Object.keys(companiesList).map(key => {
        checked[key] = true;
      })
      this.setState({ allChecked: true, checked });
    } else {
      const checked = {};

      Object.keys(companiesList).map(key => {
        checked[key] = false;
      })

      this.setState({ allChecked: false, checked });
    }
  }

  checkCompany = (e, id) => {
    const { allChecked } = this.state;
    const checked = { ...this.state.checked };

    checked[id] = e.target.checked;

    if (allChecked) {
      this.setState({ allChecked: false, checked });
    } else {
      this.setState({ checked });
    }
  }

  toggleCompaniesList = () => {
    this.setState((prevState) => ({
      companiesListOpen: !prevState.companiesListOpen,
    }));
  }

  render() {
    const { emailSettings, user, companiesList } = this.props;
    const {
      editEmailSettingModal,
      createEmailSettingModal,
      trigger,
      subject,
      content,
      enabled,
      triggers,
      deleteSettingModal,
      deleteSettingErr,
      activeTab,
      companyNotification,
      companyNotifications,
      allChecked,
      checked,
      showNoPermission,
      dontShowPage,
      companiesListOpen,
      error,
      companyInformation
    } = this.state;

    if (!user.role || user.roleClass !== 'admin') {
      return (
        <div>
          <Card>
            <CardBody>
              You need admin permissions to view Email settings
            </CardBody>
          </Card>
        </div>
      );
    }

    if (dontShowPage) {
      return (
        <div>
          <Card>
            <CardBody>
              You need admin permissions to view Email settings
            </CardBody>
          </Card>
        </div>
      );
    }

    return (
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={`${activeTab === '1' ? 'active' : null}`}
              onClick={() => { this.toggle('1'); }}
            >
              Notification to company accounts
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${activeTab === '2' ? 'active' : null}`}
              onClick={() => { this.toggle('2'); }}
            >
              Email Settings
            </NavLink>
          </NavItem>
        {/*  <NavItem>
            <NavLink
              className={`${activeTab === '3' ? 'active' : null}`}
              onClick={() => { this.toggle('3'); }}
            >
              App notifications
            </NavLink>
          </NavItem> */}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
          {(!!error) && <Alert color="danger">{error}</Alert>}
          <Row>
          <Col sm="12">
            <div className="body-title">
              Notification to selected companies
            </div>
            <Input
              type="textarea"
              name="companyNotification"
              id="companyNotification"
              value={companyNotification}
              onChange={this.handleChange}
            />
          </Col>
          <Col sm="12"> Select companies </Col>
          <Col sm={{ size: 11, offset: 1 }}>
              <Row>
                <Col sm="12">
                  {allChecked ? (
                    <div> All </div>
                  ) : (
                    <div>
                    {companiesList ? (Object.keys(companiesList).map((key) => (
                      `${allChecked ? true : (((checked && checked[key]) ? checked[key] : false) ? `${companiesList[key].companyName}, ` : '')}`
                    ))) : (null)}
                    </div>
                  )}
                </Col>

              </Row>

            <Modal isOpen={companiesListOpen} toggle={this.toggleCompaniesList}>
              <ModalHeader toggle={this.toggleCompaniesList}>
                Select companies
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col sm="12">
                    <Label check>
                      <Input type="checkbox" checked={allChecked} onChange={this.checkAll} />
                      All
                    </Label>
                  </Col>
                  {companiesList ? (Object.keys(companiesList).map((key) => (
                    <Col sm="3">
                      <Label check>
                        <Input type="checkbox" checked={allChecked ? true : ((checked && checked[key]) ? checked[key] : false)} onChange={(e) => this.checkCompany(e, key)} />
                        {companiesList[key].companyName}
                      </Label>
                    </Col>
                  ))) : (null)}
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.toggleCompaniesList}>Close</Button>
              </ModalFooter>
            </Modal>

            <Button color="primary" onClick={this.toggleCompaniesList}>
              Change
            </Button>
            <Button disabled={showNoPermission} className="body-add-button" onClick={this.addNotification}>
              Send
            </Button>
          </Col>
          <Col sm="12">

          </Col>
          </Row>

          {(companyNotifications && companyNotifications.length) ? (
          <Table
            style={{ fontSize: 14 }}
            data={companyNotifications}
            virtualized
                minHeight={480}
            height={480}
            onSortColumn={this.sort}
            rowKey='uid'
            rowHeight={92}
                headerHeight={64}
          >
            <Column width={150} sortable resizable>
              <HeaderCell>Date Sent</HeaderCell>
              <Cell dataKey="date">
                {(rowData, rowIndex) => {
                  return (
                    <div>
                      {rowData.date ? (
                        <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                          {rowData.date.seconds * 1000}
                        </Moment>
                      ) : (null)}
                    </div>
                  )
                }}
              </Cell>
            </Column>
            <Column width={450} resizable>
              <HeaderCell>Notification</HeaderCell>
              <Cell dataKey="text" />
            </Column>
            <Column width={450} resizable>
              <HeaderCell>Sent to</HeaderCell>
              <Cell dataKey="approve">
                {(rowData, rowIndex) => {
                  return (
                    <div>
                      {(companiesList && rowData.companies) ? (
                        <>
                         {rowData.companies === 'all' ? (
                           <span>
                            All
                           </span>
                          ):(
                            <>
                              {rowData.companies.map((companyId, index) => {
                                if (index === 0) {
                                  return (
                                    <span>
                                      {`${companiesList[companyId] ? (companiesList[companyId].companyName) : (companyId)}`}
                                    </span>
                                  )
                                } else {
                                  return (
                                    <span>
                                      {`, ${companiesList[companyId] ? (companiesList[companyId].companyName) : (companyId)}`}
                                    </span>
                                  )
                                }
                              })}
                            </>
                          )}
                        </>
                      ):(null)}
                    </div>
                  )
                }}
              </Cell>
            </Column>
            <Column width={100} resizable>
              <HeaderCell>Delete</HeaderCell>
              <Cell dataKey="status">
                {(rowData, rowIndex) => {
                  return (
                    <div>
                    {rowData.status === 'active' ? (
                      <Button disabled={showNoPermission} onClick={() => this.deleteCompanyNotification(rowData.id)}>
                        Delete
                      </Button>
                    ):(null)}
                    {rowData.status === 'deleted' ? (
                      <div>
                        Deleted
                      </div>
                    ):(null)}
                    </div>
                  )
                }}
              </Cell>
            </Column>
          </Table>
        ):(null)}

          </TabPane>
          <TabPane tabId="2">
          <div className="body-title">
            Email Settings
          </div>
          <Button disabled={showNoPermission} className="body-add-button" onClick={this.openCreateEmailSetting}>
            Add new Setting
          </Button>
          <div class="table-responsive">

          {(emailSettings) ? (
            <Table
              style={{ fontSize: 14 }}
              data={Object.keys(emailSettings)}
              virtualized
              minHeight={480}
              height={480}
              onSortColumn={this.sort}
              rowKey='uid'
              rowHeight={55}
                  headerHeight={64}
            >
              <Column width={100} resizable>
                <HeaderCell>#</HeaderCell>
                <Cell dataKey="date">
                  {(rowData, rowIndex) => {
                    return (
                      <div> {rowIndex} </div>
                    )
                  }}
                </Cell>
              </Column>
              <Column width={140} resizable>
                <HeaderCell>Trigger</HeaderCell>
                <Cell dataKey="trigger">
                  {(rowData, rowIndex) => {
                    return (emailSettings[rowData].trigger)
                  }}
                </Cell>
              </Column>
              <Column width={180} resizable>
                <HeaderCell>Subject</HeaderCell>
                <Cell dataKey="subject">
                  {(rowData, rowIndex) => {
                    return (emailSettings[rowData].subject)
                  }}
                </Cell>
              </Column>
              <Column width={270} resizable>
                <HeaderCell>Content</HeaderCell>
                <Cell dataKey="content">
                  {(rowData, rowIndex) => {
                    return (emailSettings[rowData].content)
                  }}
                </Cell>
              </Column>
              <Column width={150} resizable>
                <HeaderCell>Type</HeaderCell>
                <Cell dataKey="type">
                  {(rowData, rowIndex) => {
                    return (emailSettings[rowData].type)
                  }}
                </Cell>
              </Column>
              <Column width={150} resizable>
                <HeaderCell>Status</HeaderCell>
                <Cell dataKey="enabled">
                  {(rowData, rowIndex) => {
                    return (
                      <Button disabled={showNoPermission} onClick={() => this.toggleEnabled(rowData)}>
                        {emailSettings[rowData].enabled ? (
                          'Enabled'
                        ):(
                          'Disabled'
                        )}
                      </Button>
                    )
                  }}
                </Cell>
              </Column>
              <Column width={120} resizable>
                <HeaderCell>Edit</HeaderCell>
                <Cell dataKey="approve">
                  {(rowData, rowIndex) => {
                    return (
                      <Button disabled={showNoPermission} onClick={(e) => this.openEditEmailSetting(e, rowData)}>
                        Edit
                      </Button>
                    )
                  }}
                </Cell>
              </Column>
              <Column width={120} resizable>
                <HeaderCell>Delete</HeaderCell>
                <Cell dataKey="approve">
                  {(rowData, rowIndex) => {
                    return (
                      <Button disabled={showNoPermission} onClick={(e) => this.toggleDeleteSetting(e, rowData)}>
                        Delete
                      </Button>
                    )
                  }}
                </Cell>
              </Column>
            </Table>
          ):(null)}

          <Row>
            <Col xs="12">
              <Label for="subject">
                Company information<div className="mandatory">*</div>
              </Label>
            </Col>
          </Row>
          <Row>
            <Col xs="9">
              <Input
                type="text"
                name="companyInformation"
                id="companyInformation"
                value={companyInformation}
                onChange={this.handleChangeCompanyInformation}
              />
            </Col>
            <Col xs="3">
              <Button onClick={this.updateCompanyInformation}>
                Save
              </Button>
            </Col>
          </Row>
          </div>
          </TabPane>
        {/*  <TabPane tabId="3">
            <div className="body-title">
              Notification to company accounts
            </div>
          </TabPane>  */}
        </TabContent>

        <Modal isOpen={createEmailSettingModal} toggle={this.closeCreateEmailSetting}>
          <ModalHeader toggle={this.closeCreateEmailSetting}>
            Create new email setting
          </ModalHeader>
          <ModalBody>
            {(!!error) && <Alert color="danger">{error}</Alert>}
            <Form>
              <FormGroup>
                <Label for="role">
                  Trigger<div className="mandatory">*</div>
                </Label>
                <Input type="select" value={trigger} onChange={this.selectTrigger}>
                  {triggers && Object.keys(triggers).map(key => (
                    <option value={key}>{triggers[key]}</option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="subject">
                  Subject<div className="mandatory">*</div>
                </Label>
                <Input
                  type="text"
                  name="subject"
                  id="subject"
                  value={subject}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="content">
                  Content<div className="mandatory">*</div>
                </Label>
                <Input
                  type="textarea"
                  name="content"
                  id="content"
                  value={content}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="enabled">
                  Enabled
                </Label>
                <Input
                  type="checkbox"
                  name="enabled"
                  id="enabled"
                  checked={enabled}
                  onChange={this.handleChangeEnabled}
                />
              </FormGroup>
              <div> <div className="mandatory">*</div>Mandatory fields </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.createEmailSetting}>Create</Button>
            <Button color="secondary" onClick={this.closeCreateEmailSetting}>Close</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={editEmailSettingModal} toggle={this.closeEditEmailSetting}>
          <ModalHeader toggle={this.closeEditEmailSetting}>
            Edit email setting
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="role">
                  Trigger<div className="mandatory">*</div>
                </Label>
                <Input type="select" value={trigger} onChange={this.selectTrigger}>
                  {triggers && Object.keys(triggers).map(key => (
                    <option value={key}>{triggers[key]}</option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="subject">
                  Subject<div className="mandatory">*</div>
                </Label>
                <Input
                  type="text"
                  name="subject"
                  id="subject"
                  value={subject}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="content">
                  Content<div className="mandatory">*</div>
                </Label>
                <Input
                  type="textarea"
                  name="content"
                  id="content"
                  value={content}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="enabled">
                  Enabled
                </Label>
                <Input
                  type="checkbox"
                  name="enabled"
                  id="enabled"
                  checked={enabled}
                  onChange={this.handleChangeEnabled}
                />
              </FormGroup>
              <div> <div className="mandatory">*</div>Mandatory fields </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.editEmailSetting}>Edit</Button>
            <Button color="secondary" onClick={this.closeEditEmailSetting}>Close</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={deleteSettingModal} toggle={this.closeDeleteSetting}>
          <ModalHeader toggle={this.closeDeleteSetting}>
            Are you sure you want to delete this email setting?
          </ModalHeader>
          <ModalBody>
            {(!!deleteSettingErr) && <Alert color="danger">{deleteSettingErr}</Alert>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.deleteEmailSetting}>Delete Email Setting</Button>
            <Button color="secondary" onClick={this.closeDeleteSetting}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  emailSettings: state.notifications.emailSettings || {},
  companyNotifications: state.notifications.companyNotifications || [],
  companiesList: state.admin.companiesList || {},
  adminsRoles: state.admin.adminsRoles || {},
});

const mapDispatchToProps = {
  getEmailSettings: getAllEmailSettings,
  createEmailSetting: addEmailSetting,
  editEmailSetting: updateEmailSetting,
  deleteEmailSetting: removeEmailSetting,
  getUser: getUserData,
  addNotification: addCompanyNotification,
  fetchCompanyNotifications: getCompanyNotifications,
  fetchCompanyList: getCompanyList,
  fetchCompanyInformation: getCompanyEmailInformation,
  updateCompanyInformation: updateCompanyEmailInformation,
  deleteNotification: deleteCompanyNotification,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications));

// export default Dashboard;
