
import React from 'react';
import PropTypes from 'prop-types';

import { ResponsiveContainer, XAxis, YAxis, Tooltip, BarChart, Bar, LineChart, Line, ReferenceLine, CartesianGrid, LabelList } from 'recharts';

const renderCustomizedLabel = (props, data) => {
  const { x, y, width, value, index } = props;
  const radius = 10;

  if (index === data.length - 1 && value) {
    return (
      <g>
        <text x={x + (width || 1) / 2} y={y - radius - 1} fill="#000" textAnchor="middle" dominantBaseline="middle">
          {value}
        </text>
      </g>
    );
  }

  return null;
};

const BarGraph = React.memo(({
  data, interval, dataKey, name, grid
}) => (
  <ResponsiveContainer width='92%' height={270} marginLeft={'auto'} marginRight={'auto'} overflow={'visible'}>
    <BarChart data={data} margin={{ left: -10, right: 10 }}>
      {console.log('interval 2', (Math.ceil(data.length / 10) - 1), data.length, (data.length <= 10) ? 0 : (Math.ceil(data.length / 10) - 1))}
      <XAxis dataKey="key" interval={(data.length <= 10) ? 0 : (Math.ceil(data.length / 10) - 1)} tickCount={10} tick={{ fontSize: 13 }} angle={-60} textAnchor='end' />

      <YAxis width={80} padding={{ top: 12 }} tick={{ fontSize: 10 }} yAxisId="left" >
      </YAxis>

      <Tooltip />
      {grid ? (
        <CartesianGrid vertical={false} />
      ):(null)}
      <Bar isAnimationActive={false} yAxisId="left" type="monotone" dataKey={dataKey} name={name} fill="#5CB4FF" >
        <LabelList dataKey={dataKey} position="top" content={(props) => renderCustomizedLabel(props, data)} />
      </Bar>
    </BarChart>
  </ResponsiveContainer>
));


BarGraph.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

BarGraph.defaultProps = {
  checked: false,
};

export default BarGraph;
