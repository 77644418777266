import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Media, Button, Input, Alert, Spinner } from 'reactstrap';

import { connect } from 'react-redux';

import FileUploader from "react-firebase-file-uploader";

import {
  getFooters,
  updateFooter,
  getFooterIcons,
  updateFooterIconUrl,
} from '../../../../actions/contentManagement';

import { storage } from '../../../../lib/firebase';

import notifications from '../../../../const/notifications';

class FooterTab extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    fetchFooters: PropTypes.func.isRequired,
    fetchFooterIcons: PropTypes.func.isRequired,
    updateFooters: PropTypes.func.isRequired,
    updateIconUrl: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      data: {},
    };
  }

  componentDidMount() {
    const { fetchFooters, fetchFooterIcons } = this.props;

    fetchFooterIcons();
    fetchFooters().then((res) => {
      if (res && res.data) {
        this.setState({ data: res.data });
      }
    });
  }

  handleChange = (e) => {
    e.preventDefault();

    if (e.target) {
      const { name, value } = e.target;
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          [name]: {
            ...prevState.data[name],
            value,
          },
        },
        error: null,
        success: null,
      }))
    }
  };

  updateFooters = () => {
    const { updateFooters, user } = this.props;
    const { data } = this.state;

    this.setState({ processing: true });

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    updateFooters(data, adminName).then((res) => {
      if (res === 'success') {
        this.setState({ processing: null, success: true, error: null });
      } else if (res === 'error') {
        this.setState({ processing: null, success: null, error: true });
      }
    });
  }

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = progress => this.setState({ progress });

  handleUploadError = error => {
    this.setState({ isUploading: false, error });
    console.error(error);
  };

  handleUploadSuccess = (filename, type) => {
    const { updateIconUrl } = this.props;

    this.setState({ avatar: filename, progress: 100, isUploading: false });
    storage
      .ref(`footerIcons/${type}`)
      .child(`${type}.png`)
      .getDownloadURL()
      .then(url => updateIconUrl(url, type));
  };

  render() {
    const { footerIcons, showNoPermission } = this.props;
    const {
      error,
      success,
      data,
      processing,
    } = this.state;

    return (
      <div>
        {(!!success) && <Alert color="success">{notifications.admin.contentManagement.updateSuccessful}</Alert>}
        {(!!error) && <Alert color="danger">{notifications.admin.contentManagement.updateFailed}</Alert>}
        {data ? Object.keys(data).sort((a, b) => (data[a].index - data[b].index)).map((key) => (
          <Row>
            <Col sm="6">
              {data[key].name}
            </Col>
            <Col sm="6">
              <Input
                style={{ marginBottom: 5 }}
                type="textarea"
                name={key}
                id={`data-${key}`}
                value={data[key].value}
                onChange={(e) => this.handleChange(e)}
              />
            </Col>
          </Row>
        )) : (null)}

        <Button disabled={showNoPermission} style={{ paddingBottom: 15 }} onClick={this.updateFooters}>
          Save
          {(processing) ? (
            <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
          ):(null)}
        </Button>

        <Row>
          <Col sm="4">
            Twitter icon
          </Col>
          <Col sm="4">
            {(footerIcons && footerIcons.twitter && footerIcons.twitter.url) ? (
              <Media style={{ maxHeight: 40 }} src={footerIcons.twitter.url} />
            ) : null}
          </Col>
          <Col sm="4">
            {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
            <FileUploader
              accept="image/*"
              name="image"
              filename="twitter.png"
              disabled={showNoPermission}
              storageRef={storage.ref("footerIcons/twitter")}
              onUploadStart={this.handleUploadStart}
              onUploadError={this.handleUploadError}
              onUploadSuccess={(filename) => this.handleUploadSuccess(filename, 'twitter')}
              onProgress={this.handleProgress}
            />
          </Col>
        </Row>

        <Row>
          <Col sm="4">
            Facebook icon
          </Col>
          <Col sm="4">
            {(footerIcons && footerIcons.facebook && footerIcons.facebook.url) ? (
              <Media style={{ maxHeight: 40 }} src={footerIcons.facebook.url} />
            ) : null}
          </Col>
          <Col sm="4">
            {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
            <FileUploader
              accept="image/*"
              name="image"
              filename="facebook.png"
              disabled={showNoPermission}
              storageRef={storage.ref("footerIcons/facebook")}
              onUploadStart={this.handleUploadStart}
              onUploadError={this.handleUploadError}
              onUploadSuccess={(filename) => this.handleUploadSuccess(filename, 'facebook')}
              onProgress={this.handleProgress}
            />
          </Col>
        </Row>

        <Row>
          <Col sm="4">
            Youtube icon
          </Col>
          <Col sm="4">
            {(footerIcons && footerIcons.youtube && footerIcons.youtube.url) ? (
              <Media style={{ maxHeight: 40 }} src={footerIcons.youtube.url} />
            ) : null}
          </Col>
          <Col sm="4">
            {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
            <FileUploader
              accept="image/*"
              name="image"
              filename="youtube.png"
              disabled={showNoPermission}
              storageRef={storage.ref("footerIcons/youtube")}
              onUploadStart={this.handleUploadStart}
              onUploadError={this.handleUploadError}
              onUploadSuccess={(filename) => this.handleUploadSuccess(filename, 'youtube')}
              onProgress={this.handleProgress}
            />
          </Col>
        </Row>

        <Row>
          <Col sm="4">
            LinkedIn icon
          </Col>
          <Col sm="4">
            {(footerIcons && footerIcons.linkedIn && footerIcons.linkedIn.url) ? (
              <Media style={{ maxHeight: 40 }} src={footerIcons.linkedIn.url} />
            ) : null}
          </Col>
          <Col sm="4">
            {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
            <FileUploader
              accept="image/*"
              name="image"
              filename="linkedIn.png"
              disabled={showNoPermission}
              storageRef={storage.ref("footerIcons/linkedIn")}
              onUploadStart={this.handleUploadStart}
              onUploadError={this.handleUploadError}
              onUploadSuccess={(filename) => this.handleUploadSuccess(filename, 'linkedIn')}
              onProgress={this.handleProgress}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  footerIcons: state.contentManagement.footerIcons || {},
});

const mapDispatchToProps = {
  fetchFooters: getFooters,
  updateFooters: updateFooter,
  fetchFooterIcons: getFooterIcons,
  updateIconUrl: updateFooterIconUrl,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FooterTab));
