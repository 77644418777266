import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

//import reactFeature from '../../assets/images/react-feature.svg';
//import sassFeature from '../../assets/images/sass-feature.svg';
//import bootstrapFeature from '../../assets/images/bootstrap-feature.svg';
//import responsiveFeature from '../../assets/images/responsive-feature.svg';
import { Card, CardBody, Row, Col, Input, Label, Form, FormGroup, Button, Alert, Spinner } from 'reactstrap';

import { connect } from 'react-redux';

import {
  resetPassword,
} from '../../actions/user';

class ForgotPassword extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    login: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      email: null,
      password: null,
    };
  }

  componentDidMount() {
    console.log('forgotPassword componentDidMount');
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value, success: false });

  handleSubmit = (e) => {
    e.preventDefault();

    const { forgotPassword, history } = this.props;
    const { email, password, loading } = this.state;

    if(loading) return;

    this.setState({ loading: true });

    console.log('handleSubmit', email);

    return forgotPassword(email).then(() => {
      console.log('forgotPassword then');
    //  history.push('/login');
      this.setState({ loading: false, success: true, error: null });
    }).catch((err) => {
      console.log('forgotPassword error', { err });
      this.setState({ loading: false, success: false, error: err.message });
    });
  }

  render() {
  //  const { success, error } = this.props;
    const { email, password, error, success, loading } = this.state;

    return (
      <div>
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <Card>
              <CardBody>
                {(!!success) && <Alert color="success">If your email address has been recognised then a password reset email has been sent. Please check your junk folder just in case.</Alert>}
                {(!!error) && <Alert color="danger">{error}</Alert>}
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      name="email"
                      id="email"
                      placeholder="john@doe.corp"
                      value={email}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <Button disabled={loading} id="forgot-password-button" className="custom-primary-button" color="primary">
                    Reset
                    {loading ? (
                      <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem', marginTop: 3 }}> {''} </Spinner>
                    ) : (null)}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user || {},
});

const mapDispatchToProps = {
  forgotPassword: resetPassword,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword));

// export default Dashboard;
