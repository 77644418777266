import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Input, Label, Card, CardBody, Button, Spinner } from 'reactstrap';

import { connect } from 'react-redux';

import { Table, Column, HeaderCell, Cell, ColumnGroup } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';

import {
  useJsonToCsv
} from 'react-json-csv';

import moment from 'moment';
import Moment from 'react-moment';

import momentLocalizer from 'react-widgets-moment';

moment.locale('en-GB');
momentLocalizer();

function calculateInterval(interval, valuesArray, logData) {
  let counter = 1;
  let temp = {};

  let newValues = [];
  const valuesArrayCloned = [...valuesArray];

  if (logData) {
    console.log('calculateInterval', interval, valuesArray);
  }

  valuesArrayCloned.reverse().map((value, index) => {
    if (counter >= interval || index === valuesArrayCloned.length - 1) {

      if (logData) {
        console.log('set key', interval, counter);
      }

      if (parseInt(interval, 10) === 1 || counter === 1 || index === valuesArrayCloned.length - 1) {
        Object.keys(value).map(key => {
          //  if (counter !== 1 && (key === 'Total clients' || key === 'Total companies' || key === 'Total campaigns')) {
          //    return null;
          //  }

          if (counter !== 1 && (key === 'Total clients' || key === 'Total companies' || key === 'Total campaigns')) {
            return null;
          }

          if (key !== 'key' && key !== 'date') {
            if (logData) {
              console.log('set temp +', key, value.date, value[key]);
            }
            temp[key] = (temp[key] || 0) + (value[key] || 0);
          }

          return null;
        });

        temp.key = value.key;
        temp.date = value.date;
      }
      //  temp.key = value.key;

      //  console.log('temp', temp, counter, valuesArrayCloned.length, index, interval);

      if (temp['Redemptions count percentage']) {
        //  console.log('update Redemptions count percentage temp', (temp['Redemptions count percentage'] / interval).toFixed(2));
        temp['Redemptions count percentage'] = (((temp['Redemptions count percentage'] / interval) * 100).toFixed(2) * 100) / 100;
      }

      if (temp['Redemptions value percentage']) {
        //  console.log('update Redemptions value percentage temp', (temp['Redemptions value percentage'] / interval).toFixed(2));
        temp['Redemptions value percentage'] = (((temp['Redemptions value percentage'] / interval) * 100).toFixed(2) * 100) / 100;
      }

      if (temp['Commission']) {
        //  console.log('update Commission temp', (temp['Commission'] / interval).toFixed(2));
        temp['Commission'] = ((temp['Commission']).toFixed(2) * 100) / 100;
      }
      if (logData) {
        console.log('push temp', temp);
      }

      newValues.push(temp);
      temp = {};
      counter = 1;
    } else {
      if (logData) {
        console.log('dont push temp', value.key, value.date);
      }

      Object.keys(value).map(key => {
        //  if ((key === 'Total clients' || key === 'Total companies' || key === 'Total campaigns')) {
        //    return null;
        //  }


        //  if (key === 'Redemptions count percentage') {
        //    console.log('Redemptions count percentage', temp, counter, valuesArrayCloned.length, index);
        //  }

        if (key === 'Total clients' || key === 'Total companies' || key === 'Total campaigns') {
          if (counter === 1) {
            if (logData) {
              console.log('set temp', key, value.date, value[key]);
            }
            temp[key] = value[key] || 0;
          }

          return null;
        }

        if (key !== 'key' && key !== 'date') {
          if (logData) {
            console.log('set temp +', key, value.date, value[key]);
          }
          temp[key] = (temp[key] || 0) + (value[key] || 0);
        }
        return null;
      });
      if (counter === 1) {
        temp.key = value.key;
        temp.date = value.date;
      }

      //Redemptions count percentage

      counter = counter + 1;
    }
  });
  if (logData) {
    console.log('newValues', newValues);
  }

  return newValues.reverse();
}



class DataChart extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    fetchCompanyPaymentsTotals: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    let d = new Date();
    d.setDate(d.getDate() - 1);

    this.state = {
      error: null,
      loading: false,
      showNoPermission: false,
      startDate: d,
      endDate: new Date(),
      interval: 7,
      campaignFilter: 'all',
      timeFilter: 'month',
      metricFilter: 'vouchersCount',
      statisticsSalesValue: [],
      statisticsSalesCount: [],
      statisticsRedemptions: [],
      statisticsRefunds: [],
      statisticsCancelledVouchers: [],

      statisticsCompanies: [],
      statisticsCampaigns: [],
      statisticsCustomers: [],
    };
  }

  componentDidMount() {
    const { user, fetchVoucherSales, adminViewAsCompany, fetchCompanyPaymentsTotals } = this.props;

  }

  componentDidUpdate(prevProps, prevState) {
    const {
      adminStatisticsSalesValue,
      adminStatisticsSalesCount,
      adminStatisticsRedemptionsCount,
      adminStatisticsRedemptions,
      adminStatisticsRefunds,
      adminStatisticsCancelledVouchers,
      adminStatisticsCompanies,
      adminStatisticsCampaigns,
      adminStatisticsCustomers,
      interval,
      adminDashboardLastUpdate,
    } = this.props;

    if (adminStatisticsSalesValue && interval && (adminDashboardLastUpdate !== prevProps.adminDashboardLastUpdate || prevProps.interval !== interval || (!prevProps.adminStatisticsSalesValue || adminStatisticsSalesValue.length !== prevProps.adminStatisticsSalesValue.length))) {
      console.log('adminStatisticsSalesValue 3333', adminStatisticsSalesValue);

      let statisticsSalesValue = calculateInterval(interval, adminStatisticsSalesValue, false) || [];
      let statisticsSalesCount = calculateInterval(interval, adminStatisticsSalesCount, false) || [];
      let statisticsRedemptions = calculateInterval(interval, adminStatisticsRedemptions, false) || [];
      let statisticsRefunds = calculateInterval(interval, adminStatisticsRefunds, false) || [];
      let statisticsCancelledVouchers = calculateInterval(interval, adminStatisticsCancelledVouchers, false) || [];

      let statisticsCompanies = calculateInterval(interval, adminStatisticsCompanies, false) || [];
      let statisticsCampaigns = calculateInterval(interval, adminStatisticsCampaigns, false) || [];
      let statisticsCustomers = calculateInterval(interval, adminStatisticsCustomers, false) || [];


      console.log('adminStatisticsCompanies 4444 statisticsCompanies', statisticsCompanies);

      this.setState({
        statisticsSalesValue: statisticsSalesValue.reverse(),
        statisticsSalesCount: statisticsSalesCount.reverse(),
        statisticsRedemptions: statisticsRedemptions.reverse(),
        statisticsRefunds: statisticsRefunds.reverse(),
        statisticsCancelledVouchers: statisticsCancelledVouchers.reverse(),
        statisticsCompanies: statisticsCompanies.reverse(),
        statisticsCampaigns: statisticsCampaigns.reverse(),
        statisticsCustomers: statisticsCustomers.reverse()
      });
    }
  }

  handleChangeFilter = (e, type) => {
    const { interval } = this.state;
    const val = e.target.value;

    this.setState({ [type]: e.target.value }, () => {
      if (type !== 'interval') {
      }
    });
  };

  changeStartDate = (date) => {
    const todayDate = new Date();

    if (date > todayDate) {
      this.setState({ startDate: todayDate }, () => {
      });
    } else {
      this.setState({ startDate: date }, () => {
      });
    }

  }

  changeEndDate = (date) => {
    const todayDate = new Date();

    if (date > todayDate) {
      this.setState({ endDate: todayDate }, () => {
      });
    } else {
      this.setState({ endDate: date }, () => {
      });
    }
  }

  filter = () => {
    const { user, fetchVoucherSales, adminViewAsCompany } = this.props;
    const { campaignFilter, timeFilter, startDate, endDate } = this.state;

    if (campaignFilter === 'all') {
      if (user.company) {
        if (timeFilter === 'custom') {
          fetchVoucherSales(user.company, null, timeFilter, startDate, endDate);
        } else if (timeFilter === 'allTime') {
          let allTimeStartDate = new Date('2022-08-01T00:00:00');

          if (user.dateCreated) {
            allTimeStartDate = new Date(user.dateCreated);
          }

          fetchVoucherSales(user.company, null, timeFilter, allTimeStartDate, null);
        } else {
          fetchVoucherSales(user.company, null, timeFilter, null, null);
        }
      } else if (adminViewAsCompany) {
        if (timeFilter === 'custom') {
          fetchVoucherSales(adminViewAsCompany, null, timeFilter, startDate, endDate);
        } else if (timeFilter === 'allTime') {
          let allTimeStartDate = new Date('2022-08-01T00:00:00');

          fetchVoucherSales(user.company, null, timeFilter, allTimeStartDate, null);
        } else {
          fetchVoucherSales(adminViewAsCompany, null, timeFilter, null, null);
        }
      }
    } else {
      if (timeFilter === 'custom') {
        fetchVoucherSales(null, campaignFilter, timeFilter, startDate, endDate);
      } else {
        fetchVoucherSales(null, campaignFilter, timeFilter, null, null);
      }
    }
  }



  downloadData = async (data) => {
    const {
      statisticsSalesValue,
      statisticsSalesCount,
      statisticsRedemptions,
      statisticsRefunds,
      statisticsCancelledVouchers,

      statisticsCompanies,
      statisticsCampaigns,
      statisticsCustomers,
    } = this.state;
    const { saveAsCsv } = useJsonToCsv();

    const dataFiltered = [];

    this.setState({ downloadSpinner: true });

    /* 
    const fields2 = {
      date: 'Date',
      'Sales count': 'Sales count',
      'Commission': 'Commission',
      'Customer payments': 'Customer payments',
      'Face value sales': "Face value sales",
      'Refunds count': 'Refunds count',
      'Refunds value': 'Refunds value',
      'Cancelled vouchers count': 'Cancelled vouchers count',
      'Cancelled vouchers value': 'Cancelled vouchers valuee',
      'Redemptions count': 'Redemptions count',
      'Redemptions value': 'Redemptions value',
      'Redemptions count percentage': 'Redemptions count percentage',
      'Redemptions value percentage': 'Redemptions value percentage',
      'Total companies': 'Total companies',
      'New companies': 'New companies',
      'Deleted companies': 'Deleted companies',
      'Total campaigns': 'Total campaigns',
      'New campaigns': 'New campaignst',
      'Ended campaigns': 'Ended campaigns',
      'Total clients': 'Total clients',
      'New clients': 'New clients',
      'Deleted clients': 'Deleted clients',
    } */

    const fields = {
      date: 'Date',
      'Units (number) Self': 'Units (number) Self',
      'Units (number) Gifting': 'Units (number) Gifting',
      'Units (number) Total': 'Units (number) Total',
      'Payments (value) Commission': "Payments (value) Commission",
      'Payments (value) All Vouchers': 'Payments (value) All Vouchers',
      'Payments (value) All Gifting': 'Payments (value) All Gifting',
      'Payments (value) Total (Vouchers + Gifting)': 'Payments (value) Total (Vouchers + Gifting)',
      'Payments (value) Total face value': 'Payments (value) Total face value',
      'Refunds (payments) Vouchers (count)': 'Refunds (payments) Vouchers (count)',
      'Refunds (payments) Vouchers (value)': 'Refunds (payments) Vouchers (value)',
      'Refunds (payments) Gifting (count)': 'Refunds (payments) Gifting (count)',
      'Refunds (payments) Gifting (value)': 'Refunds (payments) Gifting (value)',
      'Refunds (payments) Total(value)': 'Refunds (payments) Total(value)',
      'Cancellations (face values) - Count': "Cancellations (face values) - Count",
      'Cancellations (face values) - Value': "Cancellations (face values) - Value",
      'Redemptions - Number': "Redemptions - Number",
      'Redemptions - Value': "Redemptions - Value",
      'Redemptions - % by number': "Redemptions - % by numbere",
      'Redemptions - % by value': "Redemptions - % by value",

      'Total companies': 'Total companies',
      'New companies': 'New companies',
      'Deleted companies': 'Deleted companies',
      'Total campaigns': 'Total campaigns',
      'New campaigns': 'New campaignst',
      'Ended campaigns': 'Ended campaigns',
      'Total clients': 'Total clients',
      'New clients': 'New clients',
      'Deleted clients': 'Deleted clients',
    }

    console.log('downloadData', data);

    data.map((row, rowIndex) => {
      let dateCreated = new Date(0);

      if (row.dateCreated) {
        dateCreated.setUTCSeconds(row.dateCreated / 1000);
        dateCreated = dateCreated.toISOString().substring(0, 16).replace('T', ' ');
      } else {
        dateCreated = null;
      }

      dataFiltered.push({
        date: row.date,
        'Units (number) Self': row['Self count'] || '-',
        'Units (number) Gifting': row['Gifting count'] || '-',
        'Units (number) Total': row['Sales count'] || '-',
        'Payments (value) Commission': statisticsSalesValue[rowIndex] ? (statisticsSalesValue[rowIndex].Commission || '-') : '-',
        'Payments (value) All Vouchers': statisticsSalesValue[rowIndex] ? (statisticsSalesValue[rowIndex]['Customer payments Voucher'] || '-') : '-',
        'Payments (value) All Gifting': statisticsSalesValue[rowIndex] ? (statisticsSalesValue[rowIndex]['Customer payments Gifting'] || '-') : '-',
        'Payments (value) Total (Vouchers + Gifting)': statisticsSalesValue[rowIndex] ? (statisticsSalesValue[rowIndex]['Customer payments'] || '-') : '-',
        'Payments (value) Total face value': statisticsSalesValue[rowIndex] ? (statisticsSalesValue[rowIndex]['Face value sales'] || '-') : '-',
        'Refunds (payments) Vouchers (count)': statisticsRefunds[rowIndex] ? (statisticsRefunds[rowIndex]['Refunds voucher count'] || '-') : '-',
        'Refunds (payments) Vouchers (value)': statisticsRefunds[rowIndex] ? (statisticsRefunds[rowIndex]['Refunds voucher value'] || '-') : '-',
        'Refunds (payments) Gifting (count)': statisticsRefunds[rowIndex] ? (statisticsRefunds[rowIndex]['Refunds gift count'] || '-') : '-',
        'Refunds (payments) Gifting (value)': statisticsRefunds[rowIndex] ? (statisticsRefunds[rowIndex]['Refunds gift value'] || '-') : '-',
        'Refunds (payments) Total(value)': statisticsRefunds[rowIndex] ? (statisticsRefunds[rowIndex]['Refunds value'] || '-') : '-',
        'Cancellations (face values) - Count': statisticsCancelledVouchers[rowIndex] ? (statisticsCancelledVouchers[rowIndex]['Cancelled vouchers count'] || '-') : '-',
        'Cancellations (face values) - Value': statisticsCancelledVouchers[rowIndex] ? (statisticsCancelledVouchers[rowIndex]['Cancelled vouchers value'] || '-') : '-',
        'Redemptions - Number': statisticsRedemptions[rowIndex] ? (statisticsRedemptions[rowIndex]['Redemptions count'] || '-') : '-',
        'Redemptions - Value': statisticsRedemptions[rowIndex] ? (statisticsRedemptions[rowIndex]['Redemptions value'] || '-') : '-',
        'Redemptions - % by number': statisticsRedemptions[rowIndex] ? (statisticsRedemptions[rowIndex]['Redemptions count percentage'] || '-') : '-',
        'Redemptions - % by value': statisticsRedemptions[rowIndex] ? (statisticsRedemptions[rowIndex]['Redemptions value percentage'] || '-') : '-',

        'Total companies': statisticsCompanies[rowIndex] ? (statisticsCompanies[rowIndex]['Total companies'] || '-') : '-',
        'New companies': statisticsCompanies[rowIndex] ? (statisticsCompanies[rowIndex]['New companies'] || '-') : '-',
        'Deleted companies': statisticsCompanies[rowIndex] ? (statisticsCompanies[rowIndex]['Deleted companies'] || '-') : '-',
        'Total campaigns': statisticsCampaigns[rowIndex] ? (statisticsCampaigns[rowIndex]['Total campaigns'] || '-') : '-',
        'New campaigns': statisticsCampaigns[rowIndex] ? (statisticsCampaigns[rowIndex]['New campaigns'] || '-') : '-',
        'Ended campaigns': statisticsCampaigns[rowIndex] ? (statisticsCampaigns[rowIndex]['Ended campaigns'] || '-') : '-',
        'Total clients': statisticsCustomers[rowIndex] ? (statisticsCustomers[rowIndex]['Total clients'] || '-') : '-',
        'New clients': statisticsCustomers[rowIndex] ? (statisticsCustomers[rowIndex]['New clients'] || '-') : '-',
        'Deleted clients': statisticsCustomers[rowIndex] ? (statisticsCustomers[rowIndex]['Deleted clients'] || '-') : '-',
      });

      return null;
    })

    await saveAsCsv({ data: dataFiltered, fields, filename: 'Dashboard' });

    this.setState({ downloadSpinner: false });
  }

  render() {
    /* const {
      vouchers,
      vouchersCount,
      vouchersUsedCount,
      vouchersValue,
      vouchersByDate,
      dashboardTooltips,

      adminStatisticsFaceValueSales,
      adminStatisticsSalesValue,

      adminStatisticsSalesCount,
      adminStatisticsCommission,
      adminStatisticsRedemptionsCount,
      adminStatisticsRedemptionsValue,
      adminStatisticsRedemptionsPercentages,
      adminStatisticsRedemptions,
      adminStatisticsRefundsCount,
      adminStatisticsRefundsValue,
      adminStatisticsRefunds,
      adminStatisticsGiftedCount,
      adminStatisticsGiftedValue,

      adminStatisticsCompanies,
      adminStatisticsCampaigns,
      adminStatisticsCustomers,
      interval,
      adminDashboardLastUpdate,
    } = this.props; */
    const {
     // showNoPermission,
    //  campaignFilter,
    //  timeFilter,
     // metricFilter,
     // startDate,
     // endDate,

      statisticsSalesValue,
      statisticsSalesCount,
      statisticsRedemptions,
      statisticsRefunds,
      statisticsCancelledVouchers,

      statisticsCompanies,
      statisticsCampaigns,
      statisticsCustomers,
      sortColumn,
      sortType,
      downloadSpinner,
    } = this.state;

    console.log('statisticsSalesValue', statisticsSalesValue);

    return (
      <>
        <Card className="live-metrics-card" style={{ marginTop: 10 }}>
          <CardBody>
            <Row className="live-metrics">
              <div class="table-responsive">
                <Table
                  style={{ fontSize: 14 }}
                  sortType={sortType}
                  sortColumn={sortColumn}
                  data={statisticsSalesCount}
                  virtualized
                  minHeight={590}
                  height={590}
                  onSortColumn={this.sort}
                  shouldUpdateScroll={false}
                  rowKey='date'
                  rowHeight={34}
                  headerHeight={64}
                >
                  <Column width={100} resizable fixed>
                    <HeaderCell>Date</HeaderCell>
                    <Cell dataKey="date" />
                  </Column>

                  <ColumnGroup className="text-center" header="Units (number)">
                    <Column width={130} resizable>
                      <HeaderCell>Self</HeaderCell>
                      <Cell dataKey="Sales count">
                        {(rowData, rowIndex) => {
                          console.log('rowData', rowData);
                          return (
                            <div>
                              {rowData['Self count'] || '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                    <Column width={130} resizable>
                      <HeaderCell>Gifting</HeaderCell>
                      <Cell dataKey="dateCreated">
                        {(rowData, rowIndex) => {
                          console.log('rowData', rowData);
                          return (
                            <div>
                              {rowData['Gifting count'] || '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                    <Column width={130} resizable>
                      <HeaderCell>Total</HeaderCell>
                      <Cell dataKey="Sales count">
                        {(rowData, rowIndex) => {
                          console.log('rowData', rowData);
                          return (
                            <div>
                              {rowData['Sales count'] || '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                  </ColumnGroup>
                  
                  <ColumnGroup className="text-center" header="Payments (value)">
                    <Column width={100} resizable>
                      <HeaderCell>Commission</HeaderCell>
                        <Cell dataKey="commission">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsSalesValue[rowIndex] ? (statisticsSalesValue[rowIndex].Commission || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                    <Column width={100} resizable>
                      <HeaderCell>All Vouchers</HeaderCell>
                      <Cell dataKey="date">
                        {(rowData, rowIndex) => {
                          console.log('statisticsSalesValue[rowIndex]', statisticsSalesValue[rowIndex]);
                          return (
                            <div>
                              {statisticsSalesValue[rowIndex] ? (statisticsSalesValue[rowIndex]['Customer payments Voucher'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>


                    <Column width={100} resizable>
                      <HeaderCell>All Gifting</HeaderCell>
                      <Cell dataKey="date">
                        {(rowData, rowIndex) => {
                          console.log('statisticsSalesValue[rowIndex]', statisticsSalesValue[rowIndex]);
                          return (
                            <div>
                              {statisticsSalesValue[rowIndex] ? (statisticsSalesValue[rowIndex]['Customer payments Gifting'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>

                    <Column width={170} resizable>
                      <HeaderCell>Total (Vouchers + Gifting)</HeaderCell>
                      <Cell dataKey="date">
                        {(rowData, rowIndex) => {
                          console.log('statisticsSalesValue[rowIndex]', statisticsSalesValue[rowIndex]);
                          return (
                            <div>
                              {statisticsSalesValue[rowIndex] ? (statisticsSalesValue[rowIndex]['Customer payments'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                    <Column width={170} resizable>
                      <HeaderCell>Total face value</HeaderCell>
                      <Cell dataKey="date">
                        {(rowData, rowIndex) => {
                          console.log('statisticsSalesValue[rowIndex]', statisticsSalesValue[rowIndex]);
                          return (
                            <div>
                              {statisticsSalesValue[rowIndex] ? (statisticsSalesValue[rowIndex]['Face value sales'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                  </ColumnGroup>

                  <ColumnGroup className="text-center" header="Refunds (payments)">
                    <Column width={150} resizable>
                      <HeaderCell>Vouchers (count)</HeaderCell>
                      <Cell dataKey="commission">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsRefunds[rowIndex] ? (statisticsRefunds[rowIndex]['Refunds voucher count'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                    <Column width={150} resizable>
                      <HeaderCell>Vouchers (value)</HeaderCell>
                      <Cell dataKey="date">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsRefunds[rowIndex] ? (statisticsRefunds[rowIndex]['Refunds voucher value'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                    <Column width={120} resizable>
                      <HeaderCell>Gifting (count)</HeaderCell>
                      <Cell dataKey="commission">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsRefunds[rowIndex] ? (statisticsRefunds[rowIndex]['Refunds gift count'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                    <Column width={120} resizable>
                      <HeaderCell>Gifting (value)</HeaderCell>
                      <Cell dataKey="date">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsRefunds[rowIndex] ? (statisticsRefunds[rowIndex]['Refunds gift value'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                    {/* <Column width={120} resizable>
                      <HeaderCell>Total (count)</HeaderCell>
                      <Cell dataKey="commission">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsRefunds[rowIndex] ? (statisticsRefunds[rowIndex]['Refunds count'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column> */}
                    <Column width={120} resizable>
                      <HeaderCell>Total (value)</HeaderCell>
                      <Cell dataKey="date">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsRefunds[rowIndex] ? (statisticsRefunds[rowIndex]['Refunds value'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                  </ColumnGroup>


                  <ColumnGroup className="text-center" header="Cancellations (face values)">
                    <Column width={100} resizable>
                      <HeaderCell>Count</HeaderCell>
                      <Cell dataKey="commission">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsCancelledVouchers[rowIndex] ? (statisticsCancelledVouchers[rowIndex]['Cancelled vouchers count'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                    <Column width={100} resizable>
                      <HeaderCell>Value</HeaderCell>
                      <Cell dataKey="date">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsCancelledVouchers[rowIndex] ? (statisticsCancelledVouchers[rowIndex]['Cancelled vouchers value'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                  </ColumnGroup>

                  <ColumnGroup className="text-center" header="Redemptions">
                    <Column width={120} resizable>
                      <HeaderCell>Number</HeaderCell>
                      <Cell dataKey="commission">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsRedemptions[rowIndex] ? (statisticsRedemptions[rowIndex]['Redemptions count'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                    <Column width={120} resizable>
                      <HeaderCell>Value</HeaderCell>
                      <Cell dataKey="date">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsRedemptions[rowIndex] ? (statisticsRedemptions[rowIndex]['Redemptions value'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>

                    <Column width={140} resizable>
                      <HeaderCell>% by number</HeaderCell>
                      <Cell dataKey="date">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsRedemptions[rowIndex] ? (statisticsRedemptions[rowIndex]['Redemptions count percentage'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                    <Column width={140} resizable>
                      <HeaderCell>% by value</HeaderCell>
                      <Cell dataKey="date">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsRedemptions[rowIndex] ? (statisticsRedemptions[rowIndex]['Redemptions value percentage'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                  </ColumnGroup>

                  <ColumnGroup className="text-center" header="Companies">
                    <Column width={100} resizable>
                      <HeaderCell>Total</HeaderCell>
                      <Cell dataKey="commission">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsCompanies[rowIndex] ? (statisticsCompanies[rowIndex]['Total companies'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                    <Column width={100} resizable>
                      <HeaderCell>New</HeaderCell>
                      <Cell dataKey="date">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsCompanies[rowIndex] ? (statisticsCompanies[rowIndex]['New companies'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                    <Column width={100} resizable>
                      <HeaderCell>Deleted</HeaderCell>
                      <Cell dataKey="date">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsCompanies[rowIndex] ? (statisticsCompanies[rowIndex]['Deleted companies'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                  </ColumnGroup>



                  <ColumnGroup className="text-center" header="Campaigns">
                    <Column width={100} resizable>
                      <HeaderCell>Total</HeaderCell>
                      <Cell dataKey="commission">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsCampaigns[rowIndex] ? (statisticsCampaigns[rowIndex]['Total campaigns'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                    <Column width={100} resizable>
                      <HeaderCell>New</HeaderCell>
                      <Cell dataKey="date">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsCampaigns[rowIndex] ? (statisticsCampaigns[rowIndex]['New campaigns'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                    <Column width={100} resizable>
                      <HeaderCell>Ended</HeaderCell>
                      <Cell dataKey="date">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsCampaigns[rowIndex] ? (statisticsCampaigns[rowIndex]['Ended campaigns'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                  </ColumnGroup>




                  <ColumnGroup className="text-center" header="Customers">
                    <Column width={100} resizable>
                      <HeaderCell>Total</HeaderCell>
                      <Cell dataKey="commission">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsCustomers[rowIndex] ? (statisticsCustomers[rowIndex]['Total clients'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                    <Column width={100} resizable>
                      <HeaderCell>New</HeaderCell>
                      <Cell dataKey="date">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsCustomers[rowIndex] ? (statisticsCustomers[rowIndex]['New clients'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                    <Column width={100} resizable>
                      <HeaderCell>Deleted</HeaderCell>
                      <Cell dataKey="date">
                        {(rowData, rowIndex) => {
                          return (
                            <div>
                              {statisticsCustomers[rowIndex] ? (statisticsCustomers[rowIndex]['Deleted clients'] || '-') : '-'}
                            </div>
                          )
                        }}
                      </Cell>
                    </Column>
                  </ColumnGroup>



                </Table>
                <Button className="admin-table-more-button" color="primary" onClick={() => this.downloadData(statisticsSalesCount)}>
                  Download Data
                  {downloadSpinner ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                  ) : (null)}
                </Button>
              </div>
            </Row>
          </CardBody>
        </Card>
      </>
    );
  }
}

const mapStateToProps = state => {
  return ({
    user: state.user.userData || {},
    isLoggedIn: state.user.isLoggedIn || {},
    vouchersData: state.vouchers || {},
    //  vouchers: state.vouchers.vouchers || {},
    vouchersCount: state.vouchers.vouchersCount || 0,
    vouchersUsedCount: state.vouchers.vouchersUsedCount || 0,
    vouchersValue: state.vouchers.vouchersValue || 0,
    vouchersByDate: state.vouchers.vouchersByDate || [],
    adminViewAsCompany: state.admin.adminViewAsCompany || null,

    // adminDashboard: state.admin.adminDashboard || {},

    adminStatisticsLastRefresh: state.admin.adminStatisticsLastRefresh || null,

    adminStatisticsFaceValueSales: state.admin.adminStatisticsFaceValueSales || [],
    adminStatisticsSalesValue: state.admin.adminStatisticsSalesValue || [],
    //  adminStatisticsRefunds: state.admin.adminStatisticsRefunds || [],
    adminStatisticsSalesCount: state.admin.adminStatisticsSalesCount || [],
    adminStatisticsCommission: state.admin.adminStatisticsCommission || [],
    adminStatisticsRedemptionsCount: state.admin.adminStatisticsRedemptionsCount || [],
    adminStatisticsRedemptionsValue: state.admin.adminStatisticsRedemptionsValue || [],
    adminStatisticsRefundsCount: state.admin.adminStatisticsRefundsCount || [],
    adminStatisticsRefundsValue: state.admin.adminStatisticsRefundsValue || [],
    adminStatisticsRefunds: state.admin.adminStatisticsRefunds || [],
    adminStatisticsCancelledVouchers: state.admin.adminStatisticsCancelledVouchers || [],
    adminStatisticsRedemptionsPercentages: state.admin.adminStatisticsRedemptionsPercentages || [],
    adminStatisticsRedemptions: state.admin.adminStatisticsRedemptions || [],
    adminStatisticsGiftedCount: state.admin.adminStatisticsGiftedCount || [],
    adminStatisticsGiftedValue: state.admin.adminStatisticsGiftedValue || [],

    adminStatisticsCompanies: state.admin.adminStatisticsCompanies || [],
    adminStatisticsCampaigns: state.admin.adminStatisticsCampaigns || [],
    adminStatisticsCustomers: state.admin.adminStatisticsCustomers || [],

    // adminDashboardAgregated: state.admin.adminDashboardAgregated || {},
    // adminDashboardCustom: state.admin.adminDashboardCustom || {},
    adminStatistics: state.admin.adminStatistics || {},
    adminDashboardLastUpdate: state.admin.adminDashboardLastUpdate || null,
    // adminDashboardTotals: state.admin.adminDashboardTotals || {},
    // adminDashboardStripe: state.admin.adminDashboardStripe || {},
    // adminDashboardStripeAgregated: state.admin.adminDashboardStripeAgregated || {},
    // adminDashboardStripeCustom: state.admin.adminDashboardStripeCustom || {},
    // adminAnalyticsTemplate: state.admin.adminAnalyticsTemplate || {},

    dashboardTooltips: state.contentManagement.dashboardTooltips || {},
  });
}

const mapDispatchToProps = {
  //  fetchVoucherSales: getVoucherSales,
  //  fetchCompanyPayouts: getCompanyPayouts,
  //  fetchCompanyPaymentsTotals: getCompanyPaymentsTotals,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataChart);
