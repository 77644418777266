import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import ScrollArea from 'react-scrollbar';
import { Eye, EyeOff } from 'react-feather';

import { Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label, Form, FormGroup, Alert, Spinner } from 'reactstrap';

import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';

import { connect } from 'react-redux';
import Moment from 'react-moment';

import {
  approvePendingUser,
  pauseActiveUser,
} from '../../../actions/admin';

import {
  getTeamUsers,
  createUser,
  changeUserRole,
  deleteUser,
  createLinkedCompanyUser,
} from '../../../actions/users';

import {
  updateLastActive,
} from '../../../actions/company';

import notifications from '../../../const/notifications';

import Toggle from '../../components/Toggle/Toggle';

class Company extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    roles: PropTypes.shape(),
    getUsers: PropTypes.func.isRequired,
    createU: PropTypes.func.isRequired,
    changeRole: PropTypes.func.isRequired,
    deleteU: PropTypes.func.isRequired,
    approveUser: PropTypes.func.isRequired,
    pauseUser: PropTypes.func.isRequired,
  };

  static defaultProps = {
    roles: {},
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      roles: {
        teamOwner: 'Team Owner',
      },
      deleteUserModal: false,
      deleteUserErr: null,
      userToDelete: null,
      createUserErr: null,
      showNoPermission: false,
      dontShowPage: false,
      showPassword1: 'password',
      showPassword2: 'password',
      createProcessing: null,
      updateProcessing: null,
      deleteProcessing: null,
    };
  }

  componentDidMount() {
    const { getUsers, user, history, adminViewAsCompany, updateLastActiveFunc } = this.props;

    if (adminViewAsCompany && user.roleClass === 'admin') {
      const { adminsRoles } = this.props;

      if (user.role === 'admin') {
        getUsers(adminViewAsCompany);

        this.setState({ dontShowPage: false, showNoPermission: true });
      } else {
        if (adminsRoles && adminsRoles[user.role] && adminsRoles[user.role].permissions && adminsRoles[user.role].permissions.companyAccess) {
          getUsers(adminViewAsCompany);

          this.setState({ dontShowPage: false, showNoPermission: true });
        } else {
          this.setState({ dontShowPage: true });
        }
      }
    } else if (user && Object.keys(user).length > 0) {
      if (user.company) {
        getUsers(user.company);
        updateLastActiveFunc(user.company);

        if (user.role === 'teamOwner') {
          this.setState({ showNoPermission: false, dontShowPage: false });
        } else {
          const { roles } = this.props;

          if (roles && roles[user.role] && roles[user.role].permissions && roles[user.role].permissions.users) {
            if (roles[user.role].permissions.users === 2) {
              this.setState({ showNoPermission: false, dontShowPage: false });
            } else {
              this.setState({ showNoPermission: true, dontShowPage: false });
            }
          } else {
            this.setState({ showNoPermission: true, dontShowPage: true });
          }
        }
      } else {
        history.push('/');
      }
    } else {
      history.push('/');
    }
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  openCreateUser = () => {
    const { roles, users } = this.props;
    const ownerId = Object.keys(users).find(key => users[key].role === 'teamOwner');

    if (ownerId) {
      let startingIndex = 0;
      let startingRole = Object.keys(roles)[0];
      Object.keys(roles).map((roleKey, index) => {
        if (roles[roleKey].name === "Admin") {
          startingIndex = index;
        }
      })

      if (startingIndex) {
        startingRole = Object.keys(roles)[startingIndex];
      }

      const owner = users[ownerId];

      if (owner && (!owner.subUserCount || owner.subUserCount < 3)) {
        this.setState({ createUserModal: true, createUserModalLimitReached: false, email: null, password: null, name: null, phoneNumber: null, role: startingRole, createUserErr: null });
      } else {
        this.setState({ createUserModal: true, createUserModalLimitReached: true, email: null, password: null, name: null, phoneNumber: null, role: startingRole, createUserErr: null });
      }
    }
  }

  closeCreateUser = () => {
    this.setState({ createUserModal: false });
  }

  createUser = () => {
    const { createU, roles } = this.props;
    const { email, password, confirmPassword, name, phoneNumber, role, linkedApi, linkedApiName } = this.state;

    // const startingRole = Object.keys(roles)[0];

    let startingIndex = 0;
    let startingRole = Object.keys(roles)[0];
    Object.keys(roles).map((roleKey, index) => {
      if (roles[roleKey].name === "Admin") {
        startingIndex = index;
      }
    })

    if (startingIndex) {
      startingRole = Object.keys(roles)[startingIndex];
    }

    if (!email) {
      this.setState({ createUserErr: notifications.company.users.missingEmail || 'Missing email' });

      if (this.modalScrollbar) {
        this.modalScrollbar.scrollTop();
      }
    } else if (!confirmPassword || !password) {
      this.setState({ createUserErr: notifications.company.users.missingPassword || 'Missing password' });

      if (this.modalScrollbar) {
        this.modalScrollbar.scrollTop();
      }
    } else if (confirmPassword !== password) {
      this.setState({ createUserErr: notifications.company.users.passwordsDontMatch || 'Passwords dont match' });

      if (this.modalScrollbar) {
        this.modalScrollbar.scrollTop();
      }
    } else if (!name) {
      this.setState({ createUserErr: notifications.company.users.missingName || 'Missing name' });

      if (this.modalScrollbar) {
        this.modalScrollbar.scrollTop();
      }
    } else if (!role) {
      this.setState({ createUserErr: notifications.company.users.missingRole || 'Missing role' });

      if (this.modalScrollbar) {
        this.modalScrollbar.scrollTop();
      }
    } else {
      if (password.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$/)) {
        this.setState({ createUserErr: null, createProcessing: true });

        createU(email, password, name, phoneNumber, role, linkedApi, linkedApiName).then(res => {
          this.setState({ createProcessing: null, createUserModal: false, email: null, password: null, name: null, phoneNumber: null, role: startingRole, createUserErr: null });
        }).catch(err => {
          this.setState({ createProcessing: null, createUserErr: notifications.company.users.failedCreate || err });
        })
      } else {
        this.setState({ createProcessing: null, createUserErr: notifications.company.users.insecurePassword || 'Password needs to be at least 8 characters, have at least 1 uppercase, lowercase, number, symbol.' });

        if (this.modalScrollbar) {
          this.modalScrollbar.scrollTop();
        }
      }
    }
  }

  /*
  createLinkedUser = () => {
    const { createU, roles } = this.props;
    const { email, password, confirmPassword, name, phoneNumber, role } = this.state;
  } */

  openChangeRole = (uid, role) => {
    this.setState({ changeRoleModal: true, changeRoleUid: uid, role });
  }

  closeChangeRole = () => {
    this.setState({ changeRoleModal: false, changeRoleUid: null, role: null });
  }

  selectRole = (e) => {
    const { roles } = this.props;

    const selectedRole = roles[e.target.value];

    console.log('selectedRole', selectedRole);

    if (selectedRole && selectedRole.name && selectedRole.name === "Voucher Portal") {
      this.setState({ role: e.target.value, selectedVoucherPortal: true, selectedLinkedAPI: null, linkedApi: null, linkedApiName: null });
    } else {
      this.setState({ role: e.target.value, selectedVoucherPortal: null, selectedLinkedAPI: null, linkedApi: null, linkedApiName: null });
    }
  }

  selectLinkedCompanyAPI = (e) => {
    console.log('selectLinkedCompanyAPI', e.target.value);
    const { linkedCompaniesAPIs } = this.props;

    const selectedAPI = linkedCompaniesAPIs[e.target.value];

    console.log('selectedAPI', selectedAPI);

    if (selectedAPI && selectedAPI.name) {
      this.setState({ selectedLinkedAPI: e.target.value, linkedApi: e.target.value, linkedApiName: selectedAPI.name });
    }
  }

  changeRole = () => {
    const { changeRole, user, getUsers } = this.props;
    const { changeRoleUid, role } = this.state;

    this.setState({ updateProcessing: true });

    changeRole(changeRoleUid, role).then(res => {
      if (user && user.company) {
        getUsers(user.company);
      }

      this.setState({ updateProcessing: false, changeRoleModal: false, changeRoleUid: null, role: null });
    }).catch(err => {
      this.setState({ updateProcessing: false });
    });
  }

  toggleDeleteUser = (e, uid) => {
    e.stopPropagation();
    const { deleteUserModal, changeRoleUid } = this.state;
    if (deleteUserModal) {
      this.setState({ deleteUserModal: false, userToDelete: null, deleteUserErr: null });
    } else {
      this.setState({ deleteUserModal: true, userToDelete: changeRoleUid, deleteUserErr: null });
    }
  }

  closeDeleteUser = () => {
    this.setState({ deleteUserModal: false, userToDelete: null, deleteUserErr: null });
  }

  deleteUser = () => {
    const { deleteU } = this.props;
    const { userToDelete } = this.state;

    this.setState({ deleteProcessing: true });

    deleteU(userToDelete).then(res => {
      this.setState({ deleteProcessing: false, deleteUserModal: false, userToDelete: null, deleteUserErr: null });
    }).catch(err => {
      this.setState({ deleteProcessing: false, deleteUserErr: notifications.company.users.deleteUserErr || err });
    })
  }

  toggleShowPassword = (id) => {
    if (id === 1) {
      this.setState({
        showPassword1: this.state.showPassword1 === 'input' ? 'password' : 'input'
      })
    } else if (id === 2) {
      this.setState({
        showPassword2: this.state.showPassword2 === 'input' ? 'password' : 'input'
      })
    }
  }

  closeApproveModal = () => {
    this.setState({ approveUserModal: false, approveUserUid: null, approveUserValue: null, approveUserIndex: null, approveUserRole: null });
  }

  changeUserApproval = (e, approveUserValue, role, uid, userIndex) => {
    e.stopPropagation();
    console.log('changeUserApproval', approveUserValue, role, uid, userIndex);
    const { getUsers, adminViewAsCompany, approveUser, pauseUser } = this.props;

    if (uid && approveUserValue && (userIndex || userIndex === 0)) {
      let type = 'user';
      if (role === 'teamOwner') {
        type = 'teamOwner'
      }

      console.log('approveUserValue', approveUserValue, type);

      if (approveUserValue === 'pending') {
        approveUser(uid, type, userIndex).then(res => {
          getUsers(adminViewAsCompany);
          //  this.setState({ approveUserModal: false, approveUserUid: null, approveUserValue: null, approveUserIndex: null, role: null  });
        }).catch(err => {
          console.log(err);
        });
      } else if (approveUserValue === 'approved') {
        pauseUser(uid, type, userIndex).then(res => {
          getUsers(adminViewAsCompany);
          //  this.setState({ approveUserModal: false, approveUserUid: null, approveUserValue: null, approveUserIndex: null, role: null  });
        }).catch(err => {
          console.log(err);
        });
      }
    }
  }


  render() {
    const { user, company, users, roles, adminViewAsCompany, linkedCompaniesAPIs } = this.props;
    const {
      changeRoleModal,
      createUserModal,
      createUserModalLimitReached,
      email,
      password,
      confirmPassword,
      showPassword1,
      showPassword2,
      name,
      role,
      deleteUserModal,
      deleteUserErr,
      createUserErr,
      showNoPermission,
      dontShowPage,
      createProcessing,
      updateProcessing,
      deleteProcessing,
      approveUserModal,
      approveUserValue,
      selectedVoucherPortal,
      selectedLinkedAPI,
    } = this.state;

    console.log('this.props', this.props);
    console.log('this.state', this.state);

    if (dontShowPage) {
      return (
        <div>
          <Card>
            <CardBody>
              You don't have permissions to view company users
            </CardBody>
          </Card>
        </div>
      );
    }

    let usersArr = [];

    Object.keys(users).map(key => {
      usersArr.push({ ...users[key], key });
      return null;
    })

    return (
      <div>
        <Card>
          <CardBody>
            <div style={{ height: 55 }}>
              <Button
                disabled={showNoPermission || adminViewAsCompany}
                className="body-add-button custom-modal-button"
                color="primary"
                style={{ marginLeft: 0 }}
                onClick={this.openCreateUser}
              >
                +
              </Button>
            </div>
            <div class="table-responsive">

              {(usersArr && usersArr.length) ? (
                <Table
                  style={{ fontSize: 14 }}
                  data={usersArr}
                  minHeight={350}
                  height={350}
                  rowKey='uid'
                  rowClassName={(rowData) => {
                    return (`${rowData && rowData.role === 'teamOwner' ? ('admin-table-company-owner') : ('admin-table-company-member')}`)
                  }}
                >
                  <Column width={120} resizable>
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey="name">
                      {(rowData, rowIndex) => {
                        console.log('rowData', rowData);
                        return (
                          rowData.name ? rowData.name : ((rowData.firstName || rowData.lastName) ? (`${rowData.firstName || ''} ${rowData.lastName || ''}`) : (''))
                        )
                      }}
                    </Cell>

                  </Column>
                  <Column width={180} resizable>
                    <HeaderCell>Email</HeaderCell>
                    <Cell dataKey="email" />
                  </Column>

                  <Column width={120} resizable>
                    <HeaderCell>Role</HeaderCell>
                    <Cell dataKey="roleInCompany" />
                  </Column>
                  {adminViewAsCompany ? (
                    <Column width={130} resizable>
                      <HeaderCell>Account access</HeaderCell>
                      <Cell dataKey="approve">
                        {(rowData, rowIndex) => {
                          console.log('rowData', rowData);
                          return (
                            <Toggle disabled={!adminViewAsCompany} checked={rowData.status !== 'pending'} onChange={(e) => this.changeUserApproval(e, rowData.status, rowData.role, rowData.uid, rowIndex)} />
                          )
                        }}
                      </Cell>
                    </Column>
                  ) : (null)}
                  <Column width={170} sortable resizable>
                    <HeaderCell>Last Activity</HeaderCell>
                    <Cell dataKey="lastActive">
                      {(rowData, rowIndex) => {
                        return (
                          <div>
                            {console.log('rowData.lastActive', rowData.lastActive)}
                            {(rowData.lastActive) ? (
                              <>
                                {rowData.lastActive.seconds ? (
                                  <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                                    {rowData.lastActive.seconds * 1000}
                                  </Moment>
                                ) : (
                                  <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                                    {rowData.lastActive}
                                  </Moment>
                                )}
                              </>
                            ) : (null)}
                          </div>
                        )
                      }}
                    </Cell>
                  </Column>

                  <Column width={170} resizable>
                    <HeaderCell>Date Created</HeaderCell>
                    <Cell dataKey="dateCreated" >
                      {(rowData, rowIndex) => {
                        return (
                          <>
                            {rowData.dateCreated ? (
                              <>
                                {rowData.dateCreated.seconds ? (
                                  <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                                    {rowData.dateCreated.seconds * 1000}
                                  </Moment>
                                ) : (
                                  <Moment format="DD-MM-YYYY HH:mm" locale="lv">
                                    {rowData.dateCreated}
                                  </Moment>
                                )}
                              </>
                            ) : (null)}
                          </>
                        )
                      }}
                    </Cell>
                  </Column>

                  <Column width={140} resizable>
                    <HeaderCell>Permission level</HeaderCell>
                    <Cell>
                      {(rowData, rowIndex) => {
                        return (
                          <>
                            {rowData.role !== 'teamOwner' ? (
                              <>
                                {(rowData.role === 'teamOwner' || (roles && roles[rowData.role] && roles[rowData.role].permissions && roles[rowData.role].permissions.users)) ? (
                                  <Button
                                    disabled={showNoPermission}
                                    color="link"
                                    onClick={() => this.openChangeRole(rowData.key, rowData.role)}
                                  >
                                    {(rowData.role === 'teamOwner') ? 'Admin' : (rowData.role === 'viewOnly' ? 'No access' : ((roles[rowData.role] ? roles[rowData.role].name : null)))}
                                  </Button>
                                ) : (
                                  <>
                                    <Button
                                      disabled={showNoPermission || rowData.role === 'linkedCompanyUser'}
                                      color="link"
                                      onClick={() => this.openChangeRole(rowData.key, 'viewOnly')}
                                    >
                                      {(rowData.role === 'linkedCompanyUser') ? 'Voucher Portal' : (rowData.role === 'viewOnly' ? 'No access' : ((roles[rowData.role] ? roles[rowData.role].name : 'No access')))}
                                    </Button>
                                  </>
                                )}
                              </>
                            ) : (
                              <Button
                                disabled={true}
                                color="link"
                              >
                                Admin
                              </Button>
                            )}
                          </>
                        )
                      }}
                    </Cell>
                  </Column>
                </Table>
              ) : (null)}
            </div>
          </CardBody>
        </Card>


        {/*  <Modal isOpen={approveUserModal && adminViewAsCompany} toggle={this.closeApproveModal}>
          <ModalHeader toggle={this.closeApproveModal}>
            Change user approval
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="role">
                  User approval<div className="mandatory">*</div>
                </Label>
                <Input type="select" value={approveUserValue} onChange={this.changeUserApproval}>
                  <option value="approved">Approved</option>
                  <option value="pending">Pending</option>
                </Input>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.closeApproveModal}>Close</Button>
          </ModalFooter>
        </Modal> */}



        <Modal size="lg" isOpen={createUserModal} toggle={this.closeCreateUser} contentClassName="modal-max-height-page">
          <ModalHeader toggle={this.closeCreateUser}>
            Create new user
          </ModalHeader>
          <ScrollArea
            speed={0.6}
            smoothScrolling={true}
            className="area"
            contentClassName="content"
            horizontal={false}
            ref={(el) => { this.modalScrollbar = el; }}
          >
            <ModalBody>
              {createUserModalLimitReached ? (
                <div> User limit (3) reached </div>
              ) : (
                <>
                  {(!!createUserErr) && <Alert color="danger">{createUserErr}</Alert>}
                  <Form>
                    <Row>
                      <Col xs="12">
                        <FormGroup>
                          <Label for="name">
                            Name<div className="mandatory">*</div>
                          </Label>
                          <Input
                            type="text"
                            name="name"
                            id="name"
                            value={name}
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label for="email">
                        Email<div className="mandatory">*</div>
                      </Label>
                      <Input
                        type="text"
                        name="email"
                        id="email"
                        value={email}
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                    <FormGroup style={{ position: 'relative' }}>
                      <Label for="password">Password<div className="mandatory">*</div></Label>
                      <Input
                        type={showPassword1}
                        name="password"
                        id="password"
                        placeholder="••••••••"
                        value={password}
                        onChange={this.handleChange}
                      />
                      {showPassword1 === 'input' ? (
                        <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={() => this.toggleShowPassword(1)} />
                      ) : (
                        <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={() => this.toggleShowPassword(1)} />
                      )}

                    </FormGroup>
                    <FormGroup style={{ position: 'relative' }}>
                      <Label for="confirmPassword">Confirm Password<div className="mandatory">*</div></Label>
                      <Input
                        type={showPassword2}
                        name="confirmPassword"
                        id="confirmPassword"
                        placeholder="••••••••"
                        value={confirmPassword}
                        onChange={this.handleChange}
                      />
                      {showPassword2 === 'input' ? (
                        <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={() => this.toggleShowPassword(2)} />
                      ) : (
                        <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={() => this.toggleShowPassword(2)} />
                      )}
                    </FormGroup>
                      <FormGroup>
                        <Label for="role">
                          Permission level<div className="mandatory">*</div>
                        </Label>
                        {roles && Object.keys(roles).sort((a, b) => { return roles[b].sortValue - roles[a].sortValue }).map(key => {
                          if (key === 'teamOwner') {
                            return null;
                          }

                          if (roles[key].name === "Voucher Portal" && !company.linkedCompaniesAccount) {
                            return null;
                          }

                          return (
                            <Row className="roles-select-row">
                              <Col xs="1" />
                              <Col xs="1" className="roles-select-input">
                                <Input type="radio" name="role" value={key} checked={role === key} onChange={this.selectRole} />
                              </Col>
                              <Col xs="3" className="roles-select-name">
                                {roles[key].name}
                              </Col>
                              <Col xs="7" className="roles-select-description">
                                {roles[key].description}
                              </Col>
                            </Row>
                          )
                        })}
                      </FormGroup>
                      {company.linkedCompaniesAccount && linkedCompaniesAPIs && selectedVoucherPortal ? (
                        <FormGroup>
                          <Label for="selectedLinkedAPI">Select Linked API<div className="mandatory">*</div></Label>
                          {linkedCompaniesAPIs && Object.keys(linkedCompaniesAPIs).map(key => {
                          //  if (linkedCompaniesAPIs[key].name === "Voucher Portal" && !company.linkedCompaniesAccount) {
                          //    return null;
                          //  }

                            return (
                              <Row className="roles-select-row">
                                <Col xs="1">
                                  <div className={`${(linkedCompaniesAPIs[key].valid) ? 'status-indicators-active' : 'status-indicators-inactive'}`} />
                                </Col>
                                <Col xs="1" className="roles-select-input">
                                  <Input type="radio" name="selectedLinkedAPI" value={key} checked={selectedLinkedAPI === key} onChange={this.selectLinkedCompanyAPI} />
                                </Col>
                                <Col xs="3" className="roles-select-name">
                                  {linkedCompaniesAPIs[key].name}
                                </Col>
                                <Col xs="7" className="roles-select-description">
                                  {linkedCompaniesAPIs[key].email}
                                </Col>
                              </Row>
                            )
                          })}
                          {/* <Input
                            type="text"
                            name="selectedLinkedAPI"
                            id="selectedLinkedAPI"
                            placeholder=""
                            value={selectedLinkedAPI}
                            onChange={this.handleChange}
                          /> */}
                        </FormGroup>
                      ) : (null)}
                      
                  </Form>
                  <div> <div className="mandatory">*</div>Mandatory fields </div>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              {!createUserModalLimitReached ? (
                <Button color="primary" className="custom-modal-button" onClick={this.createUser}>
                  Create
                  {(createProcessing) ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                  ) : (null)}
                </Button>
              ) : (null)}
              <Button color="secondary" style={{ boxShadow: 'none' }} onClick={this.closeCreateUser}>Close</Button>
            </ModalFooter>
          </ScrollArea>
        </Modal>

        <Modal size="lg" isOpen={changeRoleModal} toggle={this.closeChangeRole}>
          <ModalHeader toggle={this.closeChangeRole}>
            Change permission level
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="role">
                  Permission level
                </Label>
                {roles && Object.keys(roles).sort((a, b) => { return roles[b].sortValue - roles[a].sortValue }).map(key => {
                  if (key === 'teamOwner') {
                    return null;
                  }

                  if (roles[key].name === "Voucher Portal" && !company.linkedCompaniesAccount) {
                    return null;
                  }

                  return (
                    <Row className="roles-select-row">
                      <Col xs="1" />
                      <Col xs="1" className="roles-select-input">
                        <Input type="radio" name="role" value={key} checked={role === key} onChange={this.selectRole} />
                      </Col>
                      <Col xs="3" className="roles-select-name">
                        {roles[key].name}
                      </Col>
                      <Col xs="7" className="roles-select-description">
                        {roles[key].description}
                      </Col>
                    </Row>
                  )
                })}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" className="custom-modal-button" onClick={this.changeRole}>
              {(updateProcessing) ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ) : (null)}
              Update
            </Button>
            {/*  <Button style={{ boxShadow: 'none' }} color="secondary" onClick={this.closeChangeRole}>Close</Button> */}
            <Button disabled={showNoPermission} color="danger" onClick={this.toggleDeleteUser}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={deleteUserModal} toggle={this.closeDeleteUser}>
          <ModalHeader toggle={this.closeDeleteUser}>
            Are you sure you want to delete this user?
          </ModalHeader>
          <ModalBody>
            {(!!deleteUserErr) && <Alert color="danger">{deleteUserErr}</Alert>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" className="custom-modal-button" onClick={this.deleteUser}>
              Delete User
              {(deleteProcessing) ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ) : (null)}
            </Button>
            <Button color="secondary" onClick={this.closeDeleteUser}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || {},
  users: state.users.users || {},
  roles: state.users.companiesRoles || {},
  company: state.company.company || {},
  adminViewAsCompany: state.admin.adminViewAsCompany || null,
  adminsRoles: state.admin.adminsRoles || {},
  linkedCompaniesAPIs: state.company.linkedCompaniesAPIs || null,
});

const mapDispatchToProps = {
  getUsers: getTeamUsers,
  createU: createUser,
  changeRole: changeUserRole,
  deleteU: deleteUser,
  approveUser: approvePendingUser,
  pauseUser: pauseActiveUser,
  updateLastActiveFunc: updateLastActive,
  createLinkedUser: createLinkedCompanyUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Company));
