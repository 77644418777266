import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Check, X } from 'react-feather';

import { Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label, Form, FormGroup, Alert, Media, Spinner } from 'reactstrap';
import { ChevronUp, ChevronDown } from 'react-feather';

import { Table, Column, HeaderCell, Cell, ColumnGroup } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';

import { connect } from 'react-redux';

import moment from 'moment';
import Moment from 'react-moment';

import {
  useJsonToCsv
} from 'react-json-csv';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';

import momentLocalizer from 'react-widgets-moment';

import { storage } from '../../../../lib/firebase';

import CampaignChangesVoucherImages from './CampaignChangesVoucherImages';

import Toggle from '../../../components/Toggle/Toggle';
import AutoRefresh from '../../../components/AutoRefresh/AutoRefresh';
import formattedNumber from '../../../components/FormattedNumber/FormattedNumber';

import {
  getRequestingCampaigns,
  approveVoucherCampaign,
  approveVoucherCampaignChanges,
  approveVoucherCampaignChange,
  denyVoucherCampaign,
  denyVoucherCampaignChanges,
  denyVoucherCampaignChange,
  getCampaignsFiltered,
  getCampaignsFilteredNextPage,
  getCampaignCompaniesFiltered,
  getCampaignCompaniesFilteredNextPage,
  getCampaignData,
  changeCampaignStatus,
  changeCampaignStatusCompany,
  openCompanyDashboard,
  toggleRedemptionsStatus,
  changeVoucherCampaignCommission,
  dismissComplianceDataChange,
  dismissAllComplianceDataChanges,
  getCompaniesComplianceData,
} from '../../../../actions/admin';

moment.locale('en-GB');
momentLocalizer();

const complianceTexts = {
  question1: 'Have you been trading for past 12 months?',
  question1Description: 'Have you been trading for past 12 months? (Description)',
  question2: 'Are you solvent, able and intending to continue trading for the foreseeable future?',
  question2Description: 'Are you solvent, able and intending to continue trading for the foreseeable future? (Description)',
  question3: 'Have you had any issues with lenders/creditors/HMRC over the past 12 months?',
  question3Description: 'Have you had any issues with lenders/creditors/HMRC over the past 12 months? (Description)',
  question4: 'What was your turnover bracket for the last 12 months?',
  question5Description: 'What was your net income bracket for the last 12 months? (Description)',
  question5: 'What was your net income bracket for the last 12 months?',
}

const question4Options = ['£0-£25,000', '£25,001-£50,000', '£50,001-£100,000', '£100,001-£250,000', '£250,001-£500,000', '£500,001-£1,000,000', '£1,000,001-£5,000,000', '£5,000,001+'];
const question5Options = ['<£0', '£0-£25,000', '£25,001-£50,000', '£50,001-£100,000', '£100,001-£250,000', '£250,001-£500,000', '£500,001-£1,000,000', '£1,000,001-£5,000,000', '£5,000,001+'];


class Campaigns extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    voucherCampaigns: PropTypes.arrayOf(PropTypes.shape()),
    fetchAllCampaigns: PropTypes.func.isRequired,
    approveCampaign: PropTypes.func.isRequired,
    approveCampaignChanges: PropTypes.func.isRequired,
    denyCampaign: PropTypes.func.isRequired,
    denyCampaignChanges: PropTypes.func.isRequired,
    accessCompanyDashboard: PropTypes.func.isRequired,
    changeRedemptionsStatus: PropTypes.func.isRequired,
    changeStatus: PropTypes.func.isRequired,
    changeStatusCompany: PropTypes.func.isRequired,
    changeCampaignCommission: PropTypes.func.isRequired,
    dismissComplianceChange: PropTypes.func.isRequired,
    dismissAllComplianceChanges: PropTypes.func.isRequired,
    fetchCompaniesComplianceData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
    voucherCampaigns: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      startDate: new Date(),
      endDate: new Date(),
      timeFilter: 'all',
      currentOrdering: 'created_at',
      isAscending: false,
      showInCampaignMode: true,
      dontShowPage: false,
      showNoPermission: false,
      companyAccessPermission: false,
    };
  }

  componentDidMount() {
    const { fetchAllCampaigns, admin, user, isLoggedIn, history, fetchCampaignsFiltered, fetchCompaniesComplianceData } = this.props;

    if (user && Object.keys(user).length > 0) {
      if (!user || !isLoggedIn || !user || !user.roleClass || user.roleClass !== 'admin') {
        history.push('/');
      } else {
        fetchCompaniesComplianceData();
        fetchCampaignsFiltered('created_at', 'descending', null, 'all', null, null);

        this.setState({ sortType: 'desc', sortColumn: 'created_at' });

        if (user.role === 'admin') {
          this.setState({ showNoPermission: false, companyAccessPermission: false });
        } else {
          const { adminsRoles } = this.props;

          if (adminsRoles && adminsRoles[user.role] && adminsRoles[user.role].permissions && adminsRoles[user.role].permissions.campaigns) {
            if (adminsRoles[user.role].permissions.campaigns === 2) {
              this.setState({ showNoPermission: false, dontShowPage: false });
            } else {
              this.setState({ showNoPermission: true, dontShowPage: false });
            }
          } else {
            this.setState({ showNoPermission: true, dontShowPage: true });
          }

          if (adminsRoles && adminsRoles[user.role] && adminsRoles[user.role].permissions && adminsRoles[user.role].permissions.companyAccess) {
            this.setState({ companyAccessPermission: false });
          } else {
            this.setState({ companyAccessPermission: true });
          }
        }
      }
    } else {
      history.push('/');
    }
  }

  handleChange = (event) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      filterValue: event.target.value,
      typingTimeout: setTimeout(() => {
        this.filter();
      }, 1200)
    });
  }

  openApprove = (id) => {
    this.setState({ approveCampaignModal: true, approveCampaignErr: null });
  }

  closeApprove = () => {
    this.setState({ approveCampaignModal: false });
  }

  closeDeny = () => {
    this.setState({ denyCampaignModal: false });
  }

  approveCampaign = async (id, companyId) => {
    const { approveCampaign, fetchCampaignsFiltered } = this.props;
    const { filterValue, timeFilter, startDate, endDate, currentOrdering, isAscending } = this.state;

    if (id && companyId) {
      console.log('approveCampaign', currentOrdering, filterValue, timeFilter, startDate, endDate);
      await approveCampaign(id, companyId).then(res => {
        if (isAscending) {
          fetchCampaignsFiltered(currentOrdering, 'ascending', filterValue, timeFilter, startDate, endDate);
        } else {
          fetchCampaignsFiltered(currentOrdering, 'descending', filterValue, timeFilter, startDate, endDate);
        }

      }).catch(err => {
        console.log(err);
      });
    }
  }

  denyCampaign = async (id, companyId) => {
    const { denyCampaign, fetchCampaignsFiltered } = this.props;
    const { filterValue, timeFilter, startDate, endDate, currentOrdering, isAscending } = this.state;

    if (id && companyId) {
      await denyCampaign(id, companyId).then(res => {
        if (isAscending) {
          fetchCampaignsFiltered(currentOrdering, 'ascending', filterValue, timeFilter, startDate, endDate);
        } else {
          fetchCampaignsFiltered(currentOrdering, 'descending', filterValue, timeFilter, startDate, endDate);
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }

  viewCampaignChanges = async (id, companyId, companyChanges, voucherChanges, companyComplianceChanges, companyComplianceBefore, companyComplianceData, oldData) => {
    if (id && companyId && (companyChanges || voucherChanges || companyComplianceChanges)) {

      const changesData = { ...companyChanges, ...voucherChanges };
      let currentLogo;
      let currentPrimary;
      let currentVoucherImages = [];

      if (changesData.logo) {
        const logoReference = storage.ref(changesData.logo);

        await logoReference.getDownloadURL().then((url) => {
          changesData.logo = url;
        }).catch((error) => {
          console.log(error);
        });

        if (oldData.company && oldData.company.logo) {
          const currentLogoRef = storage.ref(oldData.company.logo);

          await currentLogoRef.getDownloadURL().then((url) => {
            currentLogo = url;
          });
        }
      }

      if (changesData.primaryVoucherImage) {
        const imageReference = storage.ref(changesData.primaryVoucherImage);

        await imageReference.getDownloadURL().then((url) => {
          changesData.primaryVoucherImage = url;
        }).catch((error) => {
          console.log(error);
        });

        if (oldData.company && oldData.company.primaryVoucherImage) {
          const currentPrimaryRef = storage.ref(oldData.company.primaryVoucherImage);

          await currentPrimaryRef.getDownloadURL().then((url) => {
            currentPrimary = url;
          });
        }
      }

      if (changesData.voucherImages) {
        const newVoucherImages = [];
        await changesData.voucherImages.map(async (image, index) => {
          const imageReference = storage.ref(image);

          await imageReference.getDownloadURL().then((url) => {
            newVoucherImages[index] = url;

            this.setState((prevState) => ({
              newVoucherImages: (prevState.newVoucherImages && prevState.newVoucherImages.length < newVoucherImages.length) ? newVoucherImages : prevState.newVoucherImages,
            }));
          }).catch((error) => {
            console.log(error);
          });
        })

        changesData.voucherImages = newVoucherImages;
        this.setState({
          newVoucherImages: newVoucherImages,
        });

        if (oldData.company && oldData.company.voucherImages) {
          await oldData.company.voucherImages.map(async (image, index) => {
            const currentPrimaryRef = storage.ref(image);

            await currentPrimaryRef.getDownloadURL().then((url) => {
              currentVoucherImages[index] = url;

              this.setState({
                oldVoucherImages: currentVoucherImages,
              });

              this.setState((prevState) => ({
                oldVoucherImages: (prevState.oldVoucherImages && prevState.oldVoucherImages.length < currentVoucherImages.length) ? currentVoucherImages : prevState.oldVoucherImages,
              }));
            });
          });

          this.setState({
            oldVoucherImages: currentVoucherImages,
          });
        }
      }

      let currentDescription, currentCompanyName, currentCompanyNumber, currentWebsite, currentHasDisplayName, currentDisplayName;

      if (oldData.company) {
        currentDescription = oldData.company.description;
        currentCompanyName = oldData.company.companyName;
        currentCompanyNumber = oldData.company.companyNumber;
        currentWebsite = oldData.company.website;
        currentHasDisplayName = oldData.company.hasDisplayName;
        currentDisplayName = oldData.company.displayName;
      }

      this.setState({
        changesOldData: {
          ...oldData,
          companyNumber: currentCompanyNumber,
          name: currentCompanyName,
          displayName: currentDisplayName || null,
          description: currentDescription || null,
          website: currentWebsite || null,
          hasDisplayName: currentHasDisplayName || null,
          logo: currentLogo || null,
          primaryVoucherImage: currentPrimary || null,
          voucherImages: [...currentVoucherImages] || null,
        },
        changes: changesData,
        companyComplianceChanges: companyComplianceChanges,
        companyComplianceBefore: companyComplianceBefore,
        companyComplianceData: companyComplianceData,
        changesId: id,
        changesCompanyId: companyId,
      });
    }
  }

  closeCampaignChanges = () => {
    this.setState({ campaignChangesModal: false, changes: null, changesId: null, changesCompanyId: null });
  }

  filter = () => {
    const { fetchCampaignsFiltered, fetchCampaignCompaniesFiltered } = this.props;
    const { showInCampaignMode, filterValue, timeFilter, startDate, endDate, currentOrdering, isAscending } = this.state;

    if (showInCampaignMode) {
      if (isAscending) {
        fetchCampaignsFiltered(currentOrdering, 'ascending', filterValue, timeFilter, startDate, endDate);
      } else {
        fetchCampaignsFiltered(currentOrdering, 'descending', filterValue, timeFilter, startDate, endDate);
      }
    } else {
      if (isAscending) {
        fetchCampaignCompaniesFiltered(currentOrdering, 'ascending', filterValue, timeFilter, startDate, endDate);
      } else {
        fetchCampaignCompaniesFiltered(currentOrdering, 'descending', filterValue, timeFilter, startDate, endDate);
      }
    }
    this.setState({ currentOrdering: null });
  }

  fetchCampaignsFiltered = (e, orderId) => {
    e.stopPropagation();
    const { fetchCampaignsFiltered } = this.props;
    const { currentOrdering, isAscending, filterValue, timeFilter, startDate, endDate } = this.state;

    if (currentOrdering !== orderId) {
      fetchCampaignsFiltered(orderId, 'ascending', filterValue, timeFilter, startDate, endDate);
      this.setState({ currentOrdering: orderId, isAscending: true });
    } else {
      if (isAscending) {
        fetchCampaignsFiltered(orderId, 'descending', filterValue, timeFilter, startDate, endDate);
        this.setState({ currentOrdering: orderId, isAscending: false });
      } else {
        fetchCampaignsFiltered(orderId, 'ascending', filterValue, timeFilter, startDate, endDate);
        this.setState({ currentOrdering: orderId, isAscending: true });
      }
    }
  }

  fetchCampaignsFilteredNextPage = (limit) => {
    const { fetchCampaignsFilteredNextPage } = this.props;
    const { currentOrdering, isAscending, filterValue, timeFilter, startDate, endDate } = this.state;

    if (isAscending) {
      fetchCampaignsFilteredNextPage(currentOrdering, 'ascending', filterValue, limit, timeFilter, startDate, endDate);
    } else {
      fetchCampaignsFilteredNextPage(currentOrdering, 'descending', filterValue, limit, timeFilter, startDate, endDate);
    }
  }

  fetchCampaignCompaniesFiltered = (e, orderId) => {
    e.stopPropagation();
    const { fetchCampaignCompaniesFiltered } = this.props;
    const { currentOrdering, isAscending, filterValue, timeFilter, startDate, endDate } = this.state;

    if (currentOrdering !== orderId) {
      fetchCampaignCompaniesFiltered(orderId, 'ascending', filterValue, timeFilter, startDate, endDate);
      this.setState({ currentOrdering: orderId, isAscending: true });
    } else {
      if (isAscending) {
        fetchCampaignCompaniesFiltered(orderId, 'descending', filterValue, timeFilter, startDate, endDate);
        this.setState({ currentOrdering: orderId, isAscending: false });
      } else {
        fetchCampaignCompaniesFiltered(orderId, 'ascending', filterValue, timeFilter, startDate, endDate);
        this.setState({ currentOrdering: orderId, isAscending: true });
      }
    }
  }

  fetchCampaignCompaniesFilteredNextPage = (limit) => {
    const { fetchCampaignCompaniesFilteredNextPage } = this.props;
    const { currentOrdering, isAscending, filterValue, timeFilter, startDate, endDate } = this.state;

    if (isAscending) {
      fetchCampaignCompaniesFilteredNextPage(currentOrdering, 'ascending', filterValue, limit, timeFilter, startDate, endDate);
    } else {
      fetchCampaignCompaniesFilteredNextPage(currentOrdering, 'descending', filterValue, limit, timeFilter, startDate, endDate);
    }
  }

  /*
  toggleShowInCampaignMode = (val) => {
    const { fetchCampaignsFiltered, fetchCampaignCompaniesFiltered } = this.props;
    const { showInCampaignMode, timeFilter, startDate, endDate } = this.state;

    if (val === true && showInCampaignMode === false) {
      fetchCampaignsFiltered(null, null, null, timeFilter, startDate, endDate);
      this.setState({ showInCampaignMode: val, sortType: null, sortColumn: null });
    } else if (val === false && showInCampaignMode === true) {
      fetchCampaignCompaniesFiltered(null, null, null, timeFilter, startDate, endDate);
      this.setState({ showInCampaignMode: val, sortType: null, sortColumn: null });
    }
  }
  */

  changeStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      this.filter();
    });
  }

  changeEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      this.filter();
    });
  }

  handleChangeFilter = (e, type) => {
    if (type === 'campaignFilter') {
      this.setState({ campaignFilter: e.target.value }, () => {
        this.filter();
      });
    } else if (type === 'timeFilter') {
      this.setState({ timeFilter: e.target.value }, () => {
        this.filter();
      });
    }
  };

  toggleReadMore = (id) => {
    const { readMore } = this.state;

    if (readMore) {
      if (readMore === id) {
        this.setState((prevState) => ({ readMore: null, expandedRowKeys: [] }))
      } else {
        this.setState((prevState) => ({ readMore: id, expandedRowKeys: [id] }))
      }
    } else {
      this.setState((prevState) => ({ readMore: id, expandedRowKeys: [id] }))
    }
  }

  handleExpanded = (rowData, dataKey) => {
    const { fetchCompanyMembers } = this.props;
    const { expandedRow } = this.state;

    if (dataKey) {
      if (dataKey === expandedRow) {
        this.setState({
          expandedRow: null,
        });
      } else {
        if (rowData.company) {
          fetchCompanyMembers(rowData.company).then(() => {
            this.setState({
              expandedRow: [dataKey],
            });
          });
        }
      }
    }
  }

  sort = (sortColumn, sortType) => {
    const { fetchCampaignsFiltered, fetchCampaignCompaniesFiltered } = this.props;
    const { filterValue, showInCampaignMode, timeFilter, startDate, endDate } = this.state;

    let ordering = 'ascending';
    let isAscending = true;
    if (sortType === 'desc') {
      ordering = 'descending';
      isAscending = false;
    }

    let orderId;
    if (sortColumn === 'dateCreated') {
      orderId = 'dateCreated';
    } else {
      orderId = sortColumn;
    }

    if (showInCampaignMode) {
      fetchCampaignsFiltered(orderId, ordering, filterValue, timeFilter, startDate, endDate).then(() => {
        this.setState({ sortType, sortColumn, currentOrdering: orderId, isAscending });
      });
    } else {
      fetchCampaignCompaniesFiltered(orderId, ordering, filterValue, timeFilter, startDate, endDate).then(() => {
        this.setState({ sortType2: sortType, sortColumn2: sortColumn, currentOrdering: orderId, isAscending });
      });
    }
  }

  openActionModal = (id, status, type) => {
    const { voucherCampaigns } = this.props;

    if (type === 'campaign') {
      if (id) {
        const campaign = voucherCampaigns.find(element => element.id === id);

        if (campaign) {
          if (status <= 4 && !campaign.connectionError) {
            this.setState({ actionModalOpen: true, actionModalId: id, actionModalStatus: status, changes: null, changesId: id, changesCompanyId: campaign.companyId });
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
    } else if (type === 'company') {
      if (id) {
        this.setState({ companyActionModalOpen: true, companyActionModalId: id, companyActionModalStatus: 1, changes: null });
      }
    }
  }

  closeActionModal = () => {
    this.setState({ companyActionModalOpen: false, companyActionModalId: null, companyActionModalStatus: null, actionModalOpen: false, actionModalId: null, actionModalStatus: null });
  }

  changeSelectedStatus = (e) => {
    this.setState({ actionModalStatus: e.target.value });
  }

  changeSelectedStatusCompany = (e) => {
    this.setState({ companyActionModalStatus: e.target.value });
  }

  changeStatus = () => {
    const { changeStatus, voucherCampaigns, fetchCampaignsFiltered } = this.props;
    const { actionModalId, actionModalStatus, filterValue, timeFilter, startDate, endDate, currentOrdering, isAscending } = this.state;

    const campaign = voucherCampaigns.find(element => element.id === actionModalId);

    if (campaign) {
      if (campaign.status === actionModalStatus) {
        return null;
      }

      this.setState({ buttonPressed: 'status-change' });

      if (campaign.status === 8 && actionModalStatus === 1) {
        this.approveCampaign(actionModalId, campaign.companyId).then((res) => {
          if (isAscending) {
            fetchCampaignsFiltered(currentOrdering, 'ascending', filterValue, timeFilter, startDate, endDate);
          } else {
            fetchCampaignsFiltered(currentOrdering, 'descending', filterValue, timeFilter, startDate, endDate);
          }

          this.setState({ buttonPressed: null, actionModalOpen: false, actionModalId: null, actionModalStatus: null });
        });
      } else if (campaign.status === 8 && actionModalStatus === 4) {
        this.denyCampaign(actionModalId, campaign.companyId).then((res) => {
          if (isAscending) {
            fetchCampaignsFiltered(currentOrdering, 'ascending', filterValue, timeFilter, startDate, endDate);
          } else {
            fetchCampaignsFiltered(currentOrdering, 'descending', filterValue, timeFilter, startDate, endDate);
          }

          this.setState({ buttonPressed: null, actionModalOpen: false, actionModalId: null, actionModalStatus: null });
        });
      } else {
        changeStatus(actionModalId, actionModalStatus).then((res) => {
          if (isAscending) {
            fetchCampaignsFiltered(currentOrdering, 'ascending', filterValue, timeFilter, startDate, endDate);
          } else {
            fetchCampaignsFiltered(currentOrdering, 'descending', filterValue, timeFilter, startDate, endDate);
          }

          this.setState({ buttonPressed: null, actionModalOpen: false, actionModalId: null, actionModalStatus: null });
        });
      }
    } else {
      changeStatus(actionModalId, actionModalStatus).then((res) => {
        if (isAscending) {
          fetchCampaignsFiltered(currentOrdering, 'ascending', filterValue, timeFilter, startDate, endDate);
        } else {
          fetchCampaignsFiltered(currentOrdering, 'descending', filterValue, timeFilter, startDate, endDate);
        }

        this.setState({ buttonPressed: null, actionModalOpen: false, actionModalId: null, actionModalStatus: null });
      });
    }
  }

  changeStatusCompany = () => {
    const { changeStatusCompany } = this.props;
    const { companyActionModalId, companyActionModalStatus } = this.state;

    if (companyActionModalId) {
      changeStatusCompany(companyActionModalId, companyActionModalStatus).then(() => {
        this.setState({ companyActionModalOpen: false, companyActionModalId: null, actionModalStatus: null });
      });
    } else {
      this.setState({ companyActionModalOpen: false, companyActionModalId: null, actionModalStatus: null });
    }
  }

  openDecisionModal = (id, index) => {
    const { fetchCampaignData } = this.props;

    if (id) {
      fetchCampaignData(id, index).then(async (res) => {
        const { voucherCampaigns } = this.props;
        const campaign = voucherCampaigns.find(element => element.id === id);

        console.log('campaign.hasPendingChanges', campaign);

        if (campaign.status !== 8) {
          //  this.setState({ dismissingAllComplianceChanges: true });
          await this.dismissAllComplianceChanges(campaign.companyId);
        }

        this.setState({ dismissingAllComplianceChanges: false });

        if (campaign && (campaign.hasPendingChanges || campaign.companyPendingChanges || campaign.voucherPendingChanges || campaign.companyCompliancePendingChanges)) {
          if (campaign.hasPendingChanges && (campaign.companyPendingChanges || campaign.voucherPendingChanges || campaign.companyCompliancePendingChanges)) {
            this.viewCampaignChanges(id, campaign.companyId, campaign.companyPendingChanges, campaign.voucherPendingChanges, campaign.companyCompliancePendingChanges, campaign.companyComplianceBeforeChanges, campaign.complianceData, campaign).then(() => {
              this.setState({ decisionModalOpen: true, decisionModalId: id, decisionModalIndex: index, decisionModalStatus: campaign.status });
            });
          } else {
            this.setState({ decisionModalOpen: true, decisionModalId: id, decisionModalIndex: index, decisionModalStatus: campaign.status, changes: null, changesId: id, changesCompanyId: campaign.companyId });
          }

        } else {
          this.setState({ decisionModalOpen: false, decisionModalId: null, decisionModalIndex: null, decisionModalStatus: null, changes: null, changesId: null, changesCompanyId: null });
        }
      })
    }
  }

  closeDecisionModal = () => {
    this.setState({ decisionModalOpen: false, decisionModalId: null, decisionModalIndex: null, decisionModalStatus: null });
  }

  dismissComplianceChange = (id) => {
    const { dismissComplianceChange, voucherCampaigns } = this.props;
    const { decisionModalId } = this.state;

    const campaign = voucherCampaigns.find(element => element.id === decisionModalId);

    dismissComplianceChange(campaign.companyId, decisionModalId, id);
  }

  //  Test login
  //  blueskychocolates@yopmail.com
  //  1234!qwerQWER

  dismissAllComplianceChanges = (companyId) => new Promise(async (resolve) => {
    const { dismissAllComplianceChanges } = this.props;
    const { dismissingAllComplianceChanges } = this.state;

    if (dismissingAllComplianceChanges) {
      console.log('dismissComplianceDataChange ignore');
      return resolve();
    } else {
      this.setState({ dismissingAllComplianceChanges: true });
    }

    console.log('dismissAllComplianceChanges', companyId);

    await dismissAllComplianceChanges(companyId);

    console.log('dismissComplianceDataChange after');

    return resolve();
  });

  changeDecision = (type, decision) => {
    const { voucherCampaigns, approveCampaignChanges, denyCampaignChanges } = this.props;
    const { decisionModalId } = this.state;

    const campaign = voucherCampaigns.find(element => element.id === decisionModalId);

    this.setState({ buttonPressed: `all-${decision}` });

    if (campaign) {
      if (type === 'change') {
        if (decision === 'approved') {
          if (campaign.hasPendingChanges) {
            const { changesId, changesCompanyId } = this.state;

            approveCampaignChanges(changesId, changesCompanyId).then(res => {
              this.setState({ buttonPressed: null, decisionModalOpen: false, decisionModalId: null, decisionModalStatus: null, changes: null, changesId: null, changesCompanyId: null });
            }).catch(err => {
              console.log(err);
            });
          } else {
            this.setState({ decisionModalOpen: null, decisionModalId: null, decisionModalStatus: null, changes: null, changesId: null, changesCompanyId: null });
          }
        } else if (decision === 'denied') {
          if (campaign.hasPendingChanges) {
            const { changesId, changesCompanyId } = this.state;

            denyCampaignChanges(changesId, changesCompanyId).then(res => {
              this.setState({ buttonPressed: null, decisionModalOpen: false, decisionModalId: null, decisionModalStatus: null, changes: null, changesId: null, changesCompanyId: null });
            }).catch(err => {
              console.log(err);
            });
          } else {
            this.setState({ buttonPressed: null, decisionModalOpen: false, decisionModalId: null, decisionModalStatus: null, changes: null, changesId: null, changesCompanyId: null });
          }
        }
      } else if (type === 'approveCampaign' && campaign.status === 8) {
        if (decision === 'approved') {
          this.approveCampaign(decisionModalId, campaign.companyId).then(() => {
            this.setState({ buttonPressed: null, decisionModalOpen: false, decisionModalId: null, decisionModalStatus: null });
          });
        } else if (decision === 'denied') {
          this.denyCampaign(decisionModalId, campaign.companyId).then(() => {
            this.setState({ buttonPressed: null, decisionModalOpen: false, decisionModalId: null, decisionModalStatus: null });
          });
        }
      }
    }
  }

  changeSingleDecision = (changeId, type, decision) => {
    const { voucherCampaigns, approveCampaignChange, denyCampaignChange } = this.props;
    const { decisionModalId, pendingUpdate, decisionModalIndex } = this.state;

    const campaign = voucherCampaigns.find(element => element.id === decisionModalId);

    if (campaign) {
      if (pendingUpdate) {
        return null;
      }

      this.setState({ pendingUpdate: true, buttonPressed: `${changeId}-${decision}` });

      setTimeout(() => {
        this.setState({ pendingUpdate: false });
      }, 800);

      if (type === 'change') {
        if (decision === 'approved') {
          if (campaign.hasPendingChanges) {
            const { changesId, changesCompanyId } = this.state;

            approveCampaignChange(changeId, changesId, changesCompanyId).then(() => {
              this.setState({ buttonPressed: null });

              this.openDecisionModal(decisionModalId, decisionModalIndex);
            }).catch(err => {
              console.log(err);
            });
          }
        } else if (decision === 'denied') {
          if (campaign.hasPendingChanges) {
            const { changesId, changesCompanyId } = this.state;

            denyCampaignChange(changeId, changesId, changesCompanyId).then(() => {
              this.setState({ buttonPressed: null });

              this.openDecisionModal(decisionModalId, decisionModalIndex);
            }).catch(err => {
              console.log(err);
            });
          }
        }
      }
    }
  }

  accessCompanyDashboard = (e, companyId, companyName) => {
    e.preventDefault();
    const { accessCompanyDashboard, history, match } = this.props;

    const { path } = match;

    if (companyId && companyName) {
      accessCompanyDashboard(companyId, companyName, path).then((res) => {
        if (res && res.status && res.status === 'success') {
          history.push('/dashboard');
        }
      })
    }
  }

  openChangeRedemptionsStatus = (id, type, index) => {
    this.setState({ redemtionModal: true, redemptionId: id, redemptionType: type, redemptionIndex: index });
  }

  closeChangeRedemptionsStatus = () => {
    this.setState({ redemtionModal: false, redemptionId: null, redemptionType: null, redemptionIndex: null });
  }

  changeRedemptionsStatus = (voucherId, index) => {
    const { changeRedemptionsStatus } = this.props;
    const { redemptionId, redemptionType, redemptionIndex } = this.state;

    changeRedemptionsStatus(redemptionId, redemptionType, redemptionIndex).then(() => {
      this.setState({ redemtionModal: false, redemptionId: null, redemptionType: null, redemptionIndex: null });
    });

    this.setState({ redemtionModal: false, redemptionId: null, redemptionType: null, redemptionIndex: null });
  }

  changeRedemptionsStatus2 = (redemptionId, redemptionType, redemptionIndex) => {
    const { changeRedemptionsStatus } = this.props;
    const { showNoPermission } = this.state;

    if (showNoPermission) {
      return null;
    }

    changeRedemptionsStatus(redemptionId, redemptionType, redemptionIndex).then(() => {
      this.setState({ redemtionModal: false, redemptionId: null, redemptionType: null, redemptionIndex: null });
    });

    this.setState({ redemtionModal: false, redemptionId: null, redemptionType: null, redemptionIndex: null });
  }

  changeRedemptionsStatusCompany = (companyId, index) => {
    const { changeRedemptionsStatus } = this.props;

    changeRedemptionsStatus(companyId, 'company', index);
  }

  openChangeCommission = (id, commission, index) => {
    if (commission) {
      this.setState({ commissionEntered: commission, commissionCampaignId: id, commissionIndex: index });
    } else {
      this.setState({ commissionEntered: 0, commissionCampaignId: id, commissionIndex: index });
    }
  }

  closeChangeCommission = () => {
    this.setState({ commissionEntered: null, commissionCampaignId: null, commissionIndex: null });
  }

  changeCampaignCommission = () => {
    const { changeCampaignCommission, user } = this.props;
    const { commissionEntered, commissionCampaignId, commissionIndex } = this.state;

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    changeCampaignCommission(commissionCampaignId, commissionEntered, commissionIndex, adminName).then((res) => {
      this.setState({ commissionEntered: null, commissionCampaignId: null, commissionIndex: null });
    });
  }

  handleChangecommission = (e) => {
    if (e.target && e.target.value) {
      let newVal = parseInt(e.target.value, 10);

      if (newVal > 100) {
        newVal = 100;
      } else if (newVal < 0) {
        newVal = 0;
      }

      this.setState({
        commissionEntered: newVal,
      });
    }
  };

  downloadData = async (data, mode) => {
    const { saveAsCsv } = useJsonToCsv();

    this.setState({ downloadSpinner: true });

    if (mode === 'campaign') {
      const dataFiltered = [];

      const fields = {
        api: 'API',
        status: 'Status',
        dateCreated: "Date Created",
        companyName: 'Company Name',
        campaignName: 'Campaign Name',
        rating: 'Rating',
        ratingCount: 'Rating #',
        commission: 'commission %',
        campaignStart: 'Campaign Start',
        campaignEnd: 'Campaign End',
        redemptionsStatus: 'Redemptions Status',
        faceValueSales: 'Face value sales (£)',
        sales: 'Sales £',
        units: 'Units #',
        customers: 'Customers #',
        completionStep1: 'Campaign Completetion step 1',
        completionStep2: 'Campaign Completetion step 2',
        completionStep3: 'Campaign Completetion step 3',
        completionTotal: 'Campaign Completetion total',
        redemptionsCount: 'Redemptions #',
        redemptionsValue: 'Redemptions £',
        redemptionsCountPerc: 'Redemptions %#',
        redemptionsValuePerc: 'Redemptions %£',
        giftedCount: 'Gifted #',
        giftedValue: 'Gifted £',
        giftedCountPerc: 'Gifted %#',
        giftedValuePerc: 'Gifted %£',
        refundsCount: 'Refunds #',
        refundsValue: 'Refunds £',
        earningsPaid: 'Earnings paid',
        earningsOwed: 'Earnings owed',
        earningsTotal: 'Earnings total',
        availableVouchers: 'Available Vouchers',
        totalValue: 'Total value per step (£)',
        discounts: 'Discounts',
        expiry: 'Expiry',
        category1: 'Category 1',
        category2: 'Category 2',
      }

      data.map((row) => {
        let status = 'Live';
        if (row.status === 1) { status = 'Live'; }
        else if (row.status === 8) { status = '-'; }
        else if (row.status === 2) { status = 'Paused new'; }
        else if (row.status === 3) { status = 'Paused all'; }
        else if (row.status === 4) { status = 'Expired (stopped)'; }
        else if (row.status === 5) { status = 'Expired (completed)'; }
        else if (row.status === 6) { status = 'Expired (denied)'; }
        else if (row.status === 7) { status = 'Connection error'; }

        let sales = 0;

        if (row.salesValue) {
          if (row.applicationFeeValue) {
            sales = row.salesValue - ((row.applicationFeeValue / 100)).toFixed(2);
          } else {
            sales = row.salesValue;
          }
        }

        let availableVouchers = '';
        let discounts = '';

        if (row.availableVouchers) {
          row.availableVouchers.map((voucher) => {
            availableVouchers = availableVouchers + ` £${voucher}`;
            return null;
          })
        }
        if (row.discount) {
          Object.keys(row.discount).map((key) => {
            discounts = discounts + ` ${row.discount[key]}%`;
            return null;
          })
        }

        let campaignStart = new Date(0);
        if (row.startDate) {
          campaignStart.setUTCSeconds(row.startDate.seconds);
          campaignStart = campaignStart.toISOString().substring(0, 16).replace('T', ' ');
        } else {
          campaignStart = null;
        }

        let campaignEnd = new Date(0);

        if (row.endDate && row.endDate.seconds && row.endDate.seconds < 28375023200) {
          campaignEnd.setUTCSeconds(row.startDate.seconds);
          campaignEnd = campaignEnd.toISOString().substring(0, 16).replace('T', ' ');
        } else {
          campaignEnd = 'Ongoing';
        }

        let dateCreated = new Date(0);

        if (row.created_at && row.created_at.seconds) {
          dateCreated.setUTCSeconds(row.created_at.seconds);
          dateCreated = dateCreated.toISOString().substring(0, 16).replace('T', ' ');
        } else {
          dateCreated = null;
        }

        dataFiltered.push({
          api: true,
          status: status,
          dateCreated: dateCreated,
          companyName: row.companyName,
          campaignName: row.name,
          rating: (row.ratingsCount && row.ratingsTotalVal) ? (Math.round((row.ratingsTotalVal / row.ratingsCount) * 10) / 10) : ('0'),
          ratingCount: row.ratingsCount || 0,
          commission: row.commission,
          campaignStart: campaignStart,
          campaignEnd: campaignEnd,
          redemptionsStatus: row.enableRedemptions || false,
          faceValueSales: row.salesValue,
          sales: sales,
          units: row.salesCount,
          customers: row.customers,
          completionStep1: `${row.step1Sales ? ((row.step1Sales / row.totalValue) * 100).toFixed(2) : (0).toFixed(2)}%`,
          completionStep2: `${row.step2Sales ? ((row.step2Sales / row.totalValue) * 100).toFixed(2) : (0).toFixed(2)}%`,
          completionStep3: `${row.step3Sales ? ((row.step3Sales / row.totalValue) * 100).toFixed(2) : (0).toFixed(2)}%`,
          completionTotal: `${row.salesValue ? ((row.salesValue / (row.totalValue * 3)) * 100).toFixed(2) : (0).toFixed(2)}%`,
          giftedCount: row.giftedCount ? row.giftedCount : 0,
          giftedValue: row.giftedValue ? row.giftedValue.toFixed(2) : 0,
          giftedCountPerc: `${(row.salesCount && row.giftedCount) ? (Math.round(row.giftedCount / row.salesCount * 100)) : 0}%`,
          giftedValuePerc: `${(row.salesValue && row.giftedValue) ? (Math.round(row.giftedValue / row.salesValue * 100)) : 0}%`,
          redemptionsCount: row.redemptionCount ? row.redemptionCount : 0,
          redemptionsValue: row.redemptionValue ? row.redemptionValue.toFixed(2) : 0,
          redemptionsCountPerc: `${(row.salesCount && row.redemptionCount) ? (Math.round(row.redemptionCount / row.salesCount * 100)) : 0}%`,
          redemptionsValuePerc: `${(row.salesValue && row.redemptionValue) ? (Math.round(row.redemptionValue / row.salesValue * 100)) : 0}%`,
          refundsCount: row.refundsCount ? row.refundsCount : 0,
          refundsValue: row.refundsValue ? (row.refundsValue / 100).toFixed(2) : 0,
          earningsPaid: 0,
          earningsOwed: 0,
          earningsTotal: 0,
          availableVouchers: availableVouchers,
          totalValue: row.totalValue,
          discounts: discounts,
          expiry: row.expiry ? (row.expiry === '1' ? "1 year" : `${row.expiry} years`) : '5 years',
          category1: row.category1 ? row.category1.name : '',
          category2: row.category2 ? row.category2.name : '',
        });

        return null;
      })

      saveAsCsv({ data: dataFiltered, fields, filename: 'Campaigns' });
    } else {
      const dataFiltered = [];

      const fields = {
        companyName: 'Company Name',
        api: 'API',
        redemptionsStatus: 'Redemptions Status',
        liveCampaigns: 'Live Campaigns',
        faceValueSales: 'Face value sales (£)',
        sales: 'Sales £',
        units: 'Units #',
        customers: 'Customers #',
        redemptionsCount: 'Redemptions #',
        redemptionsValue: 'Redemptions £',
        redemptionsCountPerc: 'Redemptions %#',
        redemptionsValuePerc: 'Redemptions %£',
        giftedCount: 'Gifted #',
        giftedValue: 'Gifted £',
        giftedCountPerc: 'Gifted %#',
        giftedValuePerc: 'Gifted %£',
        refundsCount: 'Refunds #',
        refundsValue: 'Refunds £',
        earningsPaid: 'Earnings paid',
        earningsOwed: 'Earnings owed',
        earningsTotal: 'Earnings total',
        category1: 'Category 1',
        category2: 'Category 2',
      }

      data.map((row) => {
        let sales = 0;

        if (row.salesValue) {
          if (row.applicationFeeValue) {
            sales = row.salesValue - ((row.applicationFeeValue / 100)).toFixed(2);
          } else {
            sales = row.salesValue;
          }
        }

        dataFiltered.push({
          companyName: row.companyName,
          api: true,
          redemptionsStatus: row.enableRedemptions,
          liveCampaigns: row.liveVouchersCount ? 'Yes' : "No",
          faceValueSales: row.salesValue,
          sales: sales,
          units: row.salesCount,
          customers: row.customers,
          redemptionsCount: row.giftedCount ? row.giftedCount : 0,
          redemptionsValue: row.giftedValue ? row.giftedValue.toFixed(2) : 0,
          redemptionsCountPerc: `${(row.salesCount && row.giftedCount) ? (Math.round(row.giftedCount / row.salesCount * 100)) : 0}%`,
          redemptionsValuePerc: `${(row.salesValue && row.giftedValue) ? (Math.round(row.giftedValue / row.salesValue * 100)) : 0}%`,
          giftedCount: row.redemptionCount ? row.redemptionCount : 0,
          giftedValue: row.redemptionValue ? row.redemptionValue.toFixed(2) : 0,
          giftedCountPerc: `${(row.salesCount && row.redemptionCount) ? (Math.round(row.redemptionCount / row.salesCount * 100)) : 0}%`,
          giftedValuePerc: `${(row.salesValue && row.redemptionValue) ? (Math.round(row.redemptionValue / row.salesValue * 100)) : 0}%`,
          refundsCount: row.refundsCount ? row.refundsCount : 0,
          refundsValue: row.refundsValue ? (row.refundsValue / 100).toFixed(2) : 0,
          earningsPaid: 0,
          earningsOwed: 0,
          earningsTotal: 0,
          category1: row.category1 ? row.category1.name : '',
          category2: row.category2 ? row.category2.name : '',
        });

        return null;
      })

      await saveAsCsv({ data: dataFiltered, fields, filename: 'Company campaigns' });

      this.setState({ downloadSpinner: false });
    }
  }

  refresh = () => {
    const { fetchCampaignsFiltered } = this.props;
    const { currentOrdering, isAscending, filterValue, timeFilter, startDate, endDate } = this.state;

    if (isAscending) {
      fetchCampaignsFiltered(currentOrdering, 'ascending', filterValue, timeFilter, startDate, endDate);
    } else {
      fetchCampaignsFiltered(currentOrdering, 'descending', filterValue, timeFilter, startDate, endDate);
    }
  }

  render() {
    const { voucherCampaigns, user, voucherCampaignsCompany, categories, companiesComplianceData } = this.props;
    const {
      approveCampaignModal,
      approveCampaignErr,
      denyCampaignModal,
      denyCampaignErr,
      campaignChangesModal,
      campaignChangesErr,
      changes,
      changesOldData,
      filterValue,
      timeFilter,
      startDate,
      endDate,
      showInCampaignMode,
      sortType,
      sortColumn,
      sortType2,
      sortColumn2,
      expandedRowKeys,
      actionModalOpen,
      actionModalStatus,
      companyActionModalOpen,
      companyActionModalStatus,
      decisionModalOpen,
      decisionModalStatus,
      oldVoucherImages,
      newVoucherImages,
      redemtionModal,
      commissionEntered,
      commissionCampaignId,
      showNoPermission,
      dontShowPage,
      companyAccessPermission,
      buttonPressed,
      companyComplianceChanges,
      companyComplianceBefore,
      companyComplianceData,
      downloadSpinner,
    } = this.state;

    if (!user.role || user.roleClass !== 'admin') {
      return (
        <div>
          <Card>
            <CardBody>
              You need admin permissions to view all campaigns
            </CardBody>
          </Card>
        </div>
      );
    }

    if (dontShowPage) {
      return (
        <div>
          <Card>
            <CardBody>
              You don't have permissions to view all campaigns
            </CardBody>
          </Card>
        </div>
      );
    }

    console.log('voucherCampaigns', voucherCampaigns);

    return (
      <div>
        <Card>
          <CardBody>
            <Row className="body-filters">
              <Col sm="3">
                <Input
                  type="text"
                  name="filterValue"
                  id="filterValue"
                  value={filterValue}
                  placeholder="Search"
                  onChange={this.handleChange}
                />
              </Col>
              <Col sm="3">
                <Input name="timeFilter" type="select" className="form-control" value={timeFilter} onChange={(e) => this.handleChangeFilter(e, 'timeFilter')}>
                  <option value="day">1 Day</option>
                  <option value="1week">1 Week</option>
                  <option value="2weeks">2 Weeks</option>
                  <option value="4weeks">4 Weeks</option>
                  <option value="6months">6 Months</option>
                  <option value="all">All Time</option>
                  <option value="custom">Custom</option>
                </Input>
                {(timeFilter && timeFilter === 'custom') ? (
                  <Row>
                    <Col sm="6">
                      <DateTimePicker
                        className="date-picker"
                        time={false}
                        culture="en"
                        format="MMM DD YYYY"
                        onChange={this.changeStartDate}
                        value={startDate}
                        defaultValue={new Date()}
                      />
                    </Col>
                    <Col sm="6">
                      <DateTimePicker
                        className="date-picker"
                        time={false}
                        culture="en"
                        format="MMM DD YYYY"
                        onChange={this.changeEndDate}
                        value={endDate}
                        defaultValue={new Date()}
                      />
                    </Col>
                  </Row>
                ) : null}
              </Col>
              <Col xs="6">
                <AutoRefresh refreshFunction={this.refresh} refreshTime={60} />
              </Col>
              {/*  <Col sm="6">
                  <Button className={`filter-button ${showInCampaignMode ? 'filter-button-active' : ''}`} onClick={() => this.toggleShowInCampaignMode(true)}>
                    All Campaigns
                  </Button>
                  <Button className={`filter-button ${!showInCampaignMode ? 'filter-button-active' : ''}`} onClick={() => this.toggleShowInCampaignMode(false)}>
                    Agregated by company
                  </Button>
                </Col> */}
            </Row>
            {/*    {showInCampaignMode ? (   */}
            <div class="table-responsive">
              <Table
                style={{ fontSize: 14 }}
                sortType={sortType}
                sortColumn={sortColumn}
                data={voucherCampaigns}
                minHeight={590}
                height={590}
                onSortColumn={this.sort}
                shouldUpdateScroll={false}
                rowKey='uid'
                headerHeight={92}
                rowHeight={46}
                expandedRowKeys={expandedRowKeys}
                rowClassName={(rowData) => {
                  return (`${(rowData && (rowData.status < 4 && rowData.hasPendingChanges && ((rowData.companyPendingChanges || rowData.voucherPendingChanges || rowData.companyCompliancePendingChanges)) || (rowData.isNew && rowData.status === 8))) ? ' admin-table-new-user admin-campaigns-table' : 'admin-campaigns-table'}`)
                }}

              >
                <ColumnGroup className="text-center" header="Setup Indicators">
                  <Column width={130} resizable>
                    <HeaderCell>Basic</HeaderCell>
                    <Cell dataKey="validShopify">
                      {(rowData, rowIndex) => {
                        let basicInformationSetup = false;

                        if (rowData.company && Object.keys(rowData.company) && Object.keys(rowData.company).length) {
                          console.log('rowData.company', rowData.company);
                          console.log('companiesComplianceData', companiesComplianceData);

                          const { owner, companyName, companyType, companyNumber, website, primaryVoucherImage, logo, category1, description } = rowData.company;

                          let complianceDataQ1 = false;
                          let complianceDataQ2 = false;
                          let complianceDataQ3 = false;
                          let complianceDataQ4 = false;
                          let complianceDataQ5 = false;

                          if (companiesComplianceData && companiesComplianceData[owner]) {
                            if (companiesComplianceData[owner].question1 || companiesComplianceData[owner].question1 === false) complianceDataQ1 = true;
                            if (companiesComplianceData[owner].question2 || companiesComplianceData[owner].question2 === false) complianceDataQ2 = true;
                            if (companiesComplianceData[owner].question3 || companiesComplianceData[owner].question3 === false) complianceDataQ3 = true;
                            if (companiesComplianceData[owner].question4 || companiesComplianceData[owner].question4 === false) complianceDataQ4 = true;
                            if (companiesComplianceData[owner].question5 || companiesComplianceData[owner].question5 === false) complianceDataQ5 = true;
                          }

                          if ((companyName && (companyType && (companyNumber || companyType === 'individual')) && website && primaryVoucherImage && logo && category1 && description
                            && complianceDataQ1 && complianceDataQ2 && complianceDataQ3 && complianceDataQ4 && complianceDataQ5)) {
                            basicInformationSetup = true;
                          }
                        }

                        return (
                          <div className={`${(basicInformationSetup) ? 'status-indicators-active' : 'status-indicators-inactive'}`} />
                        )
                      }}
                    </Cell>
                  </Column>

                {/* 
                <Column width={130} resizable sortable>
                  <HeaderCell>Website</HeaderCell>
                  <Cell dataKey="validShopify">
                    {(rowData, rowIndex) => {
                      return (
                        <div className={`${(rowData.company && (rowData.company.validShopify || rowData.company.validWoocommerce)) ? 'status-indicators-active' : 'status-indicators-inactive'}`} />
                      )
                    }}
                  </Cell>
                </Column> 
                */}
                <Column width={130} resizable sortable>
                  <HeaderCell>Website</HeaderCell>
                  <Cell dataKey="validShopify">
                      {(rowData, rowIndex) => {
                        if (!rowData.company) {
                          return null;
                        }
                      return (
                        <>
                          {(rowData.company.linkedCompaniesAccount) ? (
                            <>
                              {(rowData.company.allValidApis && rowData.company.someValidApis) && (
                                <div className='status-indicators-active' />
                              )}
                              {(!rowData.company.allValidApis && rowData.company.someValidApis) && (
                                <div className='status-indicators-yellow' />
                              )}
                              {((!rowData.company.allValidApis && !rowData.company.someValidApis) || (rowData.company.allValidApis && !rowData.company.someValidApis)) && (
                                <div className='status-indicators-inactive' />
                              )}
                            </>
                          ) : (
                              <div className={`${(rowData.company && (rowData.company.validShopify || rowData.company.validWoocommerce)) ? 'status-indicators-active' : 'status-indicators-inactive'}`} />
                          )}
                        </>
                      )
                    }}
                  </Cell>
                </Column>

                <Column width={130} resizable sortable>
                  <HeaderCell>Payments</HeaderCell>
                  <Cell dataKey="validStripe">
                    {(rowData, rowIndex) => {
                      return (
                        <div className={`${(rowData.company && rowData.company.validStripe) ? 'status-indicators-active' : 'status-indicators-inactive'}`} />
                      )
                    }}
                  </Cell>
                  </Column>
                </ColumnGroup>
                <Column width={180} resizable sortable>
                  <HeaderCell>Status</HeaderCell>
                  <Cell dataKey="status">
                    {(rowData, rowIndex) => {
                      return (
                        <div className="admin-campaigns-table-default">
                          <Button disabled={showNoPermission} color="link" onClick={() => this.openActionModal(rowData.id, rowData.status, 'campaign')}>
                            <div>
                              {rowData.testMode ? (
                                <>
                                  {rowData.status === 1 ? 'Live - test' : null}
                                  {(rowData.status === 8) ? '-' : null}
                                  {(rowData.status === 2) ? 'Paused new - test' : null}
                                  {(rowData.status === 3) ? (rowData.connectionError ? 'Connection error - test' : 'Paused all - test') : null}
                                  {(rowData.status === 4) ? 'Expired (stopped) - test' : null}
                                  {(rowData.status === 5) ? 'Expired (completed) - test' : null}
                                  {(rowData.status === 6) ? 'Expired (denied) - test' : null}
                                  {(rowData.status === 7) ? 'Connection error - test' : null}
                                </>
                              ) : (
                                <>
                                  {rowData.status === 1 ? 'Live' : null}
                                  {(rowData.status === 8) ? '-' : null}
                                  {(rowData.status === 2) ? 'Paused new' : null}
                                  {(rowData.status === 3) ? (rowData.connectionError ? 'Connection error' : 'Paused all') : null}
                                  {(rowData.status === 4) ? 'Expired (stopped)' : null}
                                  {(rowData.status === 5) ? 'Expired (completed)' : null}
                                  {(rowData.status === 6) ? 'Expired (denied)' : null}
                                  {(rowData.status === 7) ? 'Connection error' : null}
                                </>
                              )}

                            </div>
                          </Button>
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={180} resizable>
                  <HeaderCell>Request</HeaderCell>
                  <Cell dataKey="status">
                    {(rowData, rowIndex) => {
                      return (
                        <div className="admin-campaigns-table-default">
                          <Button disabled={showNoPermission} color="link" onClick={() => this.openDecisionModal(rowData.id, rowIndex)}>
                            <div>
                              {rowData.status === 8 ? (
                                <>
                                  {rowData.testMode ? (
                                    <div> Requesting test campaign </div>
                                  ) : (
                                    <div> Requesting campaign </div>
                                  )}
                                </>
                              ) : null}
                              {rowData.status < 4 && rowData.hasPendingChanges ? (
                                <>
                                  {(rowData.voucherPendingChanges && rowData.voucherPendingChanges.status === 4) ? (
                                    <div> Requesting stop </div>
                                  ) : (null)}
                                  {(rowData.companyPendingChanges || (rowData.voucherPendingChanges && !(rowData.voucherPendingChanges.status === 4 && Object.keys(rowData.voucherPendingChanges).length === 1))) ? (
                                    <div> Requesting change </div>
                                  ) : (null)}
                                  {(rowData.companyCompliancePendingChanges) ? (
                                    <div> Compliance changes </div>
                                  ) : (null)}
                                </>
                              ) : null}
                              {(!(rowData.status === 8 || (rowData.status < 4 && rowData.hasPendingChanges)) ? '-' : null)}
                            </div>
                          </Button>
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={130} sortable resizable>
                  <HeaderCell>Date Created</HeaderCell>
                  <Cell dataKey="created_at">
                    {(rowData, rowIndex) => {
                      return (
                        <div className="admin-campaigns-table-default">
                          {rowData.created_at ? (
                            <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                              {rowData.created_at.seconds * 1000}
                            </Moment>
                          ) : (null)}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={220} sortable resizable>
                  <HeaderCell>Company Name</HeaderCell>
                  <Cell dataKey="companyNameLower">
                    {(rowData, rowIndex) => (
                      <div className="admin-campaigns-table-default">
                        <Button disabled={companyAccessPermission} color="link" onClick={(e) => this.accessCompanyDashboard(e, rowData.companyId, rowData.companyName)}>
                          {rowData.companyName || ''}
                        </Button>
                      </div>
                    )}
                  </Cell>
                </Column>
                <Column width={220} sortable resizable>
                  <HeaderCell>Campaign Name</HeaderCell>
                  <Cell dataKey="nameLower">
                    {(rowData, rowIndex) => {
                      return (
                        <div className="admin-campaigns-table-default">
                          {rowData.name ? (rowData.name) : (null)}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={100} sortable resizable>
                  <HeaderCell>Rating</HeaderCell>
                  <Cell dataKey="ratingsAverage">
                    {(rowData, rowIndex) => {
                      return (
                        <div className="admin-campaigns-table-default">
                          {(rowData.ratingsCount && rowData.ratingsTotalVal) ? (
                            Math.round((rowData.ratingsTotalVal / rowData.ratingsCount) * 10) / 10
                          ) : ('-')}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={100} sortable resizable>
                  <HeaderCell>Rating #</HeaderCell>
                  <Cell dataKey="ratingsCount">
                    {(rowData, rowIndex) => {
                      return (
                        <div className="admin-campaigns-table-default">
                          {(rowData.ratingsCount) ? (
                            rowData.ratingsCount
                          ) : ('-')}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={160} sortable resizable>
                  <HeaderCell>commission (%)</HeaderCell>
                  <Cell dataKey="commission" >
                    {(rowData, rowIndex) => {
                      return (
                        <div className="admin-campaigns-table-default">
                          {(commissionCampaignId === rowData.id) ? (
                            <>
                              <div onClick={(e) => e.stopPropagation()} style={{ display: 'inline-block', width: '50%' }}>
                                <Input
                                  type="number"
                                  name="commissionEntered"
                                  value={commissionEntered}
                                  onChange={this.handleChangecommission}
                                />
                              </div>
                              <Button style={{ display: 'inline-block', width: '25%', padding: 3 }} onClick={(e) => this.changeCampaignCommission()}>
                                <Check />
                              </Button>
                              <Button style={{ display: 'inline-block', width: '25%', padding: 3 }} onClick={(e) => this.closeChangeCommission()}>
                                <X />
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                color="link"
                                disabled={showNoPermission}
                                className="basic-information-edit-button"
                                onClick={(e) => this.openChangeCommission(rowData.id, rowData.commission, rowIndex)}
                              >
                                {rowData.commission ? `${rowData.commission}%` : '0%'}
                              </Button>
                            </>
                          )}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>

                <Column width={140} sortable resizable>
                  <HeaderCell>Campaign Start</HeaderCell>
                  <Cell dataKey="startDate" >
                    {(rowData, rowIndex) => {
                      return (
                        <div className="admin-campaigns-table-default">
                          {rowData.startDate ? (
                            <Moment format="DD-MM-YYYY HH:mm" locale="lv">
                              {rowData.startDate.seconds * 1000}
                            </Moment>
                          ) : (null)}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>

                <Column width={140} sortable resizable>
                  <HeaderCell>Campaign End</HeaderCell>
                  <Cell dataKey="endDate" >
                    {(rowData, rowIndex) => {
                      return (
                        <div className="admin-campaigns-table-default">
                          {(rowData.endDate && rowData.endDate.seconds && rowData.endDate.seconds < 28375023200) ? (
                            <Moment format="DD-MM-YYYY HH:mm" locale="lv">
                              {rowData.endDate.seconds * 1000}
                            </Moment>
                          ) : ('Ongoing')}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                {/*  <Column width={180} resizable>
                      <HeaderCell>Redemptions status</HeaderCell>
                      <Cell dataKey="button">
                        {(rowData, rowIndex) => (
                          <label class="switch">
                            <input disabled={showNoPermission} type="checkbox" checked={rowData.enableRedemptions ? true : false} onChange={() => this.changeRedemptionsStatus2(rowData.id, 'voucher', rowIndex)}/>
                            <span class="slider round"></span>
                          </label>
                        )}
                      </Cell>
                    </Column> */}
                <Column width={160} sortable resizable>
                  <HeaderCell>Face value sales (£)</HeaderCell>
                  <Cell dataKey="salesValue">
                    {(rowData, rowIndex) => (
                      <div className="admin-campaigns-table-highlight"> {rowData.salesValue ? formattedNumber(rowData.salesValue ? rowData.salesValue : 0, 2) : '-'} </div>
                    )}
                  </Cell>
                </Column>
                <ColumnGroup className="text-center" header="Customer payment">
                  <Column width={165} sortable resizable>
                    <HeaderCell>Voucher sales (£)</HeaderCell>
                    <Cell dataKey="salesValue">
                      {(rowData, rowIndex) => {
                        if (rowData.customerSales) {
                          return (
                            <div className="admin-campaigns-table-highlight"> {formattedNumber(rowData.customerSales / 100, 2)} </div>
                          )
                        }
                        return (
                          <div className="admin-campaigns-table-highlight"> - </div>
                        )
                      }}
                    </Cell>
                  </Column>

                  <Column width={165} sortable resizable>
                    <HeaderCell>Gifting sales (£)</HeaderCell>
                    <Cell dataKey="salesValue">
                      {(rowData, rowIndex) => {
                        if (rowData.customerSales) {
                          return (
                            <div className="admin-campaigns-table-highlight"> {rowData.giftingSales ? formattedNumber(rowData.giftingSales / 100, 2) : '-'} </div>
                          )
                        }
                        return (
                          <div className="admin-campaigns-table-highlight"> - </div>
                        )
                      }}
                    </Cell>
                  </Column>

                  <Column width={165} sortable resizable>
                    <HeaderCell>Total (£)</HeaderCell>
                    <Cell dataKey="salesValue">
                      {(rowData, rowIndex) => {
                        if (rowData.customerSales) {
                          return (
                            <div className="admin-campaigns-table-highlight"> {formattedNumber((rowData.customerSales / 100) + (rowData.giftingSales ? (rowData.giftingSales / 100) : 0), 2)} </div>
                          )
                        }
                        return (
                          <div className="admin-campaigns-table-highlight"> - </div>
                        )
                      }}
                    </Cell>
                  </Column>
                </ColumnGroup>

                <Column width={165} sortable resizable>
                  <HeaderCell>Payment to company (£)</HeaderCell>
                  <Cell dataKey="salesValue">
                    {(rowData, rowIndex) => {
                      if (rowData.customerSales) {
                        if (rowData.applicationFeeValue) {
                          return (
                            <div className="admin-campaigns-table-default"> {formattedNumber((rowData.customerSales - rowData.applicationFeeValue) / 100, 2)} </div>
                          )
                        }

                        return (
                          <div className="admin-campaigns-table-default"> {formattedNumber(rowData.customerSales / 100, 2)} </div>
                        )
                      }

                      return (
                        <div className="admin-campaigns-table-default"> - </div>
                      )
                    }}
                  </Cell>
                </Column>

                <ColumnGroup className="text-center" header="Payment to Steppr">
                  <Column width={165} sortable resizable>
                    <HeaderCell>Commission (£)</HeaderCell>
                    <Cell dataKey="salesValue">
                      {(rowData, rowIndex) => {
                        if (rowData.applicationFeeValue) {
                          return (
                            <div className="admin-campaigns-table-highlight"> {formattedNumber(rowData.applicationFeeValue / 100, 2)} </div>
                          )
                        }

                        return (
                          <div className="admin-campaigns-table-highlight"> - </div>
                        )
                      }}
                    </Cell>
                  </Column>
                  <Column width={165} sortable resizable>
                    <HeaderCell>Gifting (£)</HeaderCell>
                    <Cell dataKey="salesValue">
                      {(rowData, rowIndex) => {
                        if (rowData.giftingSales) {
                          return (
                            <div className="admin-campaigns-table-highlight"> {rowData.giftingSales ? formattedNumber(rowData.giftingSales / 100, 2) : '-'} </div>
                          )
                        }

                        return (
                          <div className="admin-campaigns-table-highlight"> - </div>
                        )
                      }}
                    </Cell>
                  </Column>
                  <Column width={165} sortable resizable>
                    <HeaderCell>Total (£)</HeaderCell>
                    <Cell dataKey="salesValue">
                      {(rowData, rowIndex) => {
                        if (rowData.applicationFeeValue || rowData.giftingSales) {
                          return (
                            <div className="admin-campaigns-table-highlight"> {formattedNumber(((rowData.applicationFeeValue || 0) + (rowData.giftingSales || 0)) / 100, 2)} </div>
                          )
                        }

                        return (
                          <div className="admin-campaigns-table-highlight"> - </div>
                        )
                      }}
                    </Cell>
                  </Column>
                </ColumnGroup>

                {/*  <Column width={130} sortable resizable>
                        <HeaderCell>Sales (£)</HeaderCell>
                        <Cell dataKey="salesValue">
                          {(rowData, rowIndex) => {
                            if (rowData.salesValue) {
                              if (rowData.applicationFeeValue) {
                                return(
                                  <div> {formattedNumber(rowData.salesValue ? (rowData.salesValue - ((rowData.applicationFeeValue / 100))).toFixed(2) : 0)} </div>
                                )
                              }
                              return(
                                <div> {formattedNumber(rowData.salesValue)} </div>
                              )
                            }
                            return(
                              <div> {0} </div>
                            )
                          }}
                        </Cell>
                      </Column> */}
                <ColumnGroup className="text-center" header="Units (#)">
                  <Column width={130} sortable resizable>
                    <HeaderCell>Self</HeaderCell>
                    <Cell dataKey="salesCount">
                      {(rowData, rowIndex) => (
                        <div className="admin-campaigns-table-default"> {formattedNumber(rowData.salesCount ? (rowData.salesCount - (rowData.giftedCount || 0)) : '-')} </div>
                      )}
                    </Cell>
                  </Column>
                  <Column width={130} sortable resizable>
                    <HeaderCell>Gifting</HeaderCell>
                    <Cell dataKey="salesCount">
                      {(rowData, rowIndex) => (
                        <div className="admin-campaigns-table-default"> {formattedNumber(rowData.giftedCount ? rowData.giftedCount : '-')} </div>
                      )}
                    </Cell>
                  </Column>
                  <Column width={130} sortable resizable>
                    <HeaderCell>Total</HeaderCell>
                    <Cell dataKey="salesCount">
                      {(rowData, rowIndex) => (
                        <div className="admin-campaigns-table-default"> {formattedNumber(rowData.salesCount ? rowData.salesCount : '-')} </div>
                      )}
                    </Cell>
                  </Column>
                </ColumnGroup>
                <Column width={130} sortable resizable>
                  <HeaderCell>Customers (#)</HeaderCell>
                  <Cell dataKey="customers">
                    {(rowData, rowIndex) => (
                      <div className="admin-campaigns-table-default"> {formattedNumber(rowData.customers ? rowData.customers : '-')} </div>
                    )}
                  </Cell>
                </Column>
                <ColumnGroup header="Campaign Completetion">
                  <Column width={90} resizable>
                    <HeaderCell>
                      <Row>
                        <Col sm="12"> {`Step 1`} </Col>
                      </Row>
                    </HeaderCell>
                    <Cell dataKey="completion">
                      {(rowData, rowIndex) => (
                        <Row className="admin-campaigns-table-default">
                          <Col sm="12"> {`${rowData.step1Sales ? ((rowData.step1Sales / rowData.totalValue) * 100).toFixed(2) : '-'}%`} </Col>
                        </Row>
                      )}
                    </Cell>
                  </Column>
                  <Column width={90} resizable>
                    <HeaderCell>
                      <Row>
                        <Col sm="12"> {`Step 2`} </Col>
                      </Row>
                    </HeaderCell>
                    <Cell dataKey="completion">
                      {(rowData, rowIndex) => (
                        <Row className="admin-campaigns-table-default">
                          <Col sm="12"> {`${rowData.step2Sales ? ((rowData.step2Sales / rowData.totalValue) * 100).toFixed(2) : '-'}%`} </Col>
                        </Row>
                      )}
                    </Cell>
                  </Column>
                  <Column width={90} resizable>
                    <HeaderCell>
                      <Row>
                        <Col sm="12"> {`Step 3`} </Col>
                      </Row>
                    </HeaderCell>
                    <Cell dataKey="completion">
                      {(rowData, rowIndex) => (
                        <Row className="admin-campaigns-table-default">
                          <Col sm="12"> {`${rowData.step3Sales ? ((rowData.step3Sales / rowData.totalValue) * 100).toFixed(2) : '-'}%`} </Col>
                        </Row>
                      )}
                    </Cell>
                  </Column>
                  <Column width={90} resizable>
                    <HeaderCell>
                        <Row>
                          <Col sm="12"> {`Total`} </Col>
                        </Row>
                    </HeaderCell>
                    <Cell dataKey="completion">
                      {(rowData, rowIndex) => (
                        <Row className="admin-campaigns-table-default">
                          <Col sm="12"> {`${rowData.salesValue ? ((rowData.salesValue / (rowData.totalValue * 3)) * 100).toFixed(2) : '-'}%`} </Col>
                        </Row>
                      )}
                    </Cell>
                  </Column>
                </ColumnGroup>
                <ColumnGroup header="Redemptions (face value)">
                  <Column width={90} sortable resizable>
                    <HeaderCell>
                      <Row>
                        <Col sm="12"> {`#`} </Col>
                      </Row>
                    </HeaderCell>
                    <Cell dataKey="redemptionCount">
                      {(rowData, rowIndex) => (
                        <Row className="admin-campaigns-table-highlight">
                          <Col sm="12"> {formattedNumber(rowData.redemptionCount ? rowData.redemptionCount : '-')} </Col>
                        </Row>
                      )}
                    </Cell>
                  </Column>
                  <Column width={90} sortable resizable>
                    <HeaderCell>
                      <Row>
                        <Col sm="12"> {`£`} </Col>
                      </Row>
                    </HeaderCell>
                    <Cell dataKey="redemptionValue">
                      {(rowData, rowIndex) => (
                        <Row className="admin-campaigns-table-highlight">
                          <Col sm="12"> {formattedNumber(rowData.redemptionValue ? rowData.redemptionValue.toFixed(2) : '-')} </Col>
                        </Row>
                      )}
                    </Cell>
                  </Column>
                  <Column width={90} sortable resizable>
                    <HeaderCell>
                      <Row>
                        <Col sm="12"> {`% #`} </Col>
                      </Row>
                    </HeaderCell>
                    <Cell dataKey="redemptionCount">
                      {(rowData, rowIndex) => (
                        <Row className="admin-campaigns-table-highlight">
                          <Col sm="12"> {`${(rowData.salesCount && rowData.redemptionCount) ? (Math.round(rowData.redemptionCount / rowData.salesCount * 100)) : '-'}%`} </Col>
                        </Row>
                      )}
                    </Cell>
                  </Column>
                  <Column width={90} sortable resizable>
                    <HeaderCell>
                      <Row>
                        <Col sm="12"> {`% £`} </Col>
                      </Row>
                    </HeaderCell>
                    <Cell dataKey="redemptionValue">
                      {(rowData, rowIndex) => (
                        <Row className="admin-campaigns-table-highlight">
                          <Col sm="12"> {`${(rowData.salesValue && rowData.redemptionValue) ? (Math.round(rowData.redemptionValue / rowData.salesValue * 100)) : '-'}%`} </Col>
                        </Row>
                      )}
                    </Cell>
                  </Column>
                  {/* <Column width={0}>
                    <HeaderCell>
                      <Row className="text-center">
                      </Row>
                    </HeaderCell>
                    <Cell dataKey="redemptionCount">
                      {(rowData, rowIndex) => (
                        <Row className="text-center admin-campaigns-table-highlight">
                        </Row>
                      )}
                    </Cell>
                  </Column> */}
                </ColumnGroup>
              {/*  <Column width={370} sortable resizable>
                  <HeaderCell>
                    <Row className="text-center">
                      <Col sm="12">
                        Gifted
                      </Col>
                    </Row>
                    <Row className="text-center">
                      <Col sm="3"> {`#`} </Col>
                      <Col sm="3"> {`£`} </Col>
                      <Col sm="3"> {`% #`} </Col>
                      <Col sm="3"> {`% £`} </Col>
                    </Row>
                  </HeaderCell>
                  <Cell dataKey="giftedCount">
                    {(rowData, rowIndex) => (
                      <Row className="text-center admin-campaigns-table-highlight">
                        <Col sm="3"> {formattedNumber(rowData.giftedCount ? rowData.giftedCount : '-')} </Col>
                        <Col sm="3"> {formattedNumber(rowData.giftedValue ? rowData.giftedValue.toFixed(2) : '-')} </Col>
                        <Col sm="3"> {`${(rowData.salesCount && rowData.giftedCount) ? (Math.round(rowData.giftedCount / rowData.salesCount * 100)) : '-'}%`} </Col>
                        <Col sm="3"> {`${(rowData.salesValue && rowData.giftedValue) ? (Math.round(rowData.giftedValue / rowData.salesValue * 100)) : '-'}%`} </Col>
                      </Row>
                    )}
                  </Cell>
                </Column> */}
                <ColumnGroup header={
                  <>
                    <Row>
                      <Col sm="12">
                        Refunds (customer payment)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4">
                        Vouchers
                      </Col>
                      <Col sm="4">
                        Gifting
                      </Col>
                      <Col sm="4">
                        Total
                      </Col>
                    </Row>
                    </>
                }>
                  <Column width={80} sortable resizable>
                    <HeaderCell>
                      <Row>
                        <Col sm="12"> {`#`} </Col>
                      </Row>
                    </HeaderCell>
                    <Cell dataKey="refundsCountVoucher">
                      {(rowData, rowIndex) => (
                        <Row className="admin-campaigns-table-highlight">
                          <Col sm="12"> {formattedNumber(rowData.refundsCountVoucher ? rowData.refundsCountVoucher : '-')} </Col>
                        </Row>
                      )}
                    </Cell>
                  </Column>
                  <Column width={80} sortable resizable>
                    <HeaderCell>
                      <Row>
                        <Col sm="12"> {`£`} </Col>
                      </Row>
                    </HeaderCell>
                    <Cell dataKey="refundsValueVoucher">
                      {(rowData, rowIndex) => (
                        <Row className="admin-campaigns-table-highlight">
                          <Col sm="12"> {formattedNumber(rowData.refundsValueVoucher ? (rowData.refundsValueVoucher / 100).toFixed(2) : '-')} </Col>
                        </Row>
                      )}
                    </Cell>
                  </Column>
                  {/* <Column width={170} sortable resizable>
                    <HeaderCell>
                      <Row className="text-center">
                        <Col sm="12">
                          Gifting
                        </Col>
                      </Row>
                      <Row className="text-center">
                        <Col sm="6"> {`#`} </Col>
                        <Col sm="6"> {`£`} </Col>
                      </Row>
                    </HeaderCell>
                    <Cell dataKey="refundsCount">
                      {(rowData, rowIndex) => (
                        <Row className="text-center admin-campaigns-table-highlight">
                          <Col sm="6"> {rowData.refundsCountGifting ? formattedNumber(rowData.refundsCountGifting) : '-'} </Col>
                          <Col sm="6"> {rowData.refundsValueGifting ? formattedNumber((rowData.refundsValueGifting / 100).toFixed(2)): '-'} </Col>
                        </Row>
                      )}
                    </Cell>
                  </Column> */}

                  <Column width={80} sortable resizable>
                    <HeaderCell>
                      <Row>
                        <Col sm="12"> {`#`} </Col>
                      </Row>
                    </HeaderCell>
                    <Cell dataKey="refundsCountGifting">
                      {(rowData, rowIndex) => (
                        <Row className="admin-campaigns-table-highlight">
                          <Col sm="12"> {rowData.refundsCountGifting ? formattedNumber(rowData.refundsCountGifting) : '-'} </Col>
                        </Row>
                      )}
                    </Cell>
                  </Column>
                  <Column width={80} sortable resizable>
                    <HeaderCell>
                      <Row>
                        <Col sm="12"> {`£`} </Col>
                      </Row>
                    </HeaderCell>
                    <Cell dataKey="refundsValueGifting">
                      {(rowData, rowIndex) => (
                        <Row className="admin-campaigns-table-highlight">
                          <Col sm="12"> {rowData.refundsValueGifting ? formattedNumber((rowData.refundsValueGifting / 100).toFixed(2)) : '-'} </Col>
                        </Row>
                      )}
                    </Cell>
                  </Column>

                  <Column width={160} sortable resizable>
                    <HeaderCell>
                      <Row>
                        <Col sm="12"> {`£`} </Col>
                      </Row>
                    </HeaderCell>
                    <Cell dataKey="refundsValue">
                      {(rowData, rowIndex) => (
                        <Row className="admin-campaigns-table-highlight">
                          {console.log('rowData', rowData) }
                          {/* <Col sm="6"> {formattedNumber(rowData.refundsCount ? rowData.refundsCount : '-')} </Col> */}
                          <Col sm="12"> {formattedNumber((rowData.refundsValueGifting || rowData.refundsValueVoucher) ? (((rowData.refundsValueGifting || 0) + (rowData.refundsValueVoucher || 0)) / 100).toFixed(2) : '-')} </Col>
                        </Row>
                      )}
                    </Cell>
                  </Column>
                </ColumnGroup>

                <ColumnGroup header="Cancelled (face value)">
                  <Column width={80} sortable resizable>
                    <HeaderCell>
                      <Row>
                        <Col sm="6"> {`#`} </Col>
                      </Row>
                    </HeaderCell>
                    <Cell dataKey="cancelledCount">
                      {(rowData, rowIndex) => (
                        <Row className="admin-campaigns-table-highlight">
                          {console.log('rowData', rowData)}
                          <Col sm="12"> {formattedNumber(rowData.cancelledCount ? rowData.cancelledCount : '-')} </Col>
                        </Row>
                      )}
                    </Cell>
                  </Column>
                  <Column width={80} sortable resizable>
                    <HeaderCell>
                      <Row>
                        <Col sm="6"> {`£`} </Col>
                      </Row>
                    </HeaderCell>
                    <Cell dataKey="cancelledCount">
                      {(rowData, rowIndex) => (
                        <Row className="admin-campaigns-table-highlight">
                          {console.log('cancelledValue', rowData.cancelledValue) }
                          <Col sm="12"> {formattedNumber(rowData.cancelledValue ? (rowData.cancelledValue).toFixed(2) : '-')} </Col>
                        </Row>
                      )}
                    </Cell>
                  </Column>
                  <Column width={0}>
                    <HeaderCell>
                      <Row>
                      </Row>
                    </HeaderCell>
                    <Cell dataKey="cancelledCount">
                      {(rowData, rowIndex) => (
                        <Row className="admin-campaigns-table-highlight">
                        </Row>
                      )}
                    </Cell>
                  </Column>
                </ColumnGroup>

                <ColumnGroup className="text-center" header="Inputs">
                  <Column width={160} resizable>
                    <HeaderCell>Available Vouchers</HeaderCell>
                    <Cell dataKey="availableVouchers">
                      {(rowData, rowIndex) => (
                        <div className="admin-campaigns-table-default">
                          {rowData.availableVouchers ? (rowData.availableVouchers.map((voucher) => (
                            <span> {`£${voucher}`} </span>
                          ))) : (null)}
                        </div>
                      )}
                    </Cell>
                  </Column>

                  <Column width={180} sortable resizable>
                    <HeaderCell>Total value per step (£)</HeaderCell>
                    <Cell dataKey="totalValue">
                      {(rowData, rowIndex) => (
                        <div className="admin-campaigns-table-default"> {formattedNumber(rowData.totalValue ? rowData.totalValue : '-')} </div>
                      )}
                    </Cell>
                  </Column>

                  <Column width={130} resizable>
                    <HeaderCell>Discounts</HeaderCell>
                    <Cell dataKey="discount">
                      {(rowData, rowIndex) => (
                        <div className="admin-campaigns-table-default">
                          {rowData.discount ? (Object.keys(rowData.discount).map((key2) => (
                            <span> {`${rowData.discount[key2]}%`} </span>
                          ))) : (null)}
                        </div>
                      )}
                    </Cell>
                  </Column>

                  <Column width={130} sortable resizable>
                    <HeaderCell>Expiry</HeaderCell>
                    <Cell dataKey="expiry">
                      {(rowData, rowIndex) => (
                        <div className="admin-campaigns-table-default"> {rowData.expiry ? (rowData.expiry === '1' ? "1 year" : `${rowData.expiry} years`) : '5 years'} </div>
                      )}
                    </Cell>
                  </Column>

                  <Column width={200} resizable>
                    <HeaderCell>Category 1</HeaderCell>
                    <Cell dataKey="category1">
                      {(rowData, rowIndex) => {
                        return (
                          <div className="admin-campaigns-table-default">
                            {rowData.category1 ? rowData.category1.name : ''}
                          </div>
                        )
                      }}
                    </Cell>
                  </Column>
                  <Column width={200} resizable>
                    <HeaderCell>Category 2</HeaderCell>
                    <Cell dataKey="category2">
                      {(rowData, rowIndex) => {
                        return (
                          <div className="admin-campaigns-table-default">
                            {rowData.category2 ? rowData.category2.name : ''}
                          </div>
                        )
                      }}
                    </Cell>
                  </Column>
                  <Column width={280} resizable>
                    <HeaderCell>Id</HeaderCell>
                    <Cell dataKey="id">
                      {(rowData, rowIndex) => {
                        return (
                          <div className="admin-campaigns-table-default">
                            {rowData.id ? rowData.id : ''}
                          </div>
                        )
                      }}
                    </Cell>
                  </Column>
                </ColumnGroup>
              </Table>
              <Button className="admin-table-more-button" onClick={(e) => this.fetchCampaignsFilteredNextPage(10)}>
                Get 10 more
              </Button>
              <Button className="admin-table-more-button" onClick={(e) => this.fetchCampaignsFilteredNextPage(100)}>
                Get 100 more
              </Button>
              <Button className="admin-table-more-button" onClick={(e) => this.fetchCampaignsFilteredNextPage('all')}>
                Get all
              </Button>
              <Button className="admin-table-more-button" color="primary" onClick={() => this.downloadData(voucherCampaigns, 'campaign')}>
                Download Data
                {downloadSpinner ? (
                  <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                ) : (null)}
              </Button>
            </div>

            {/*
              ):(
                <div class="table-responsive">
                  <Table
                    style={{ fontSize: 14 }}
                    sortType={sortType2}
                    sortColumn={sortColumn2}
                    data={voucherCampaignsCompany}
                    minHeight={500}
                    height={500}
                    onSortColumn={this.sort}
                    shouldUpdateScroll={false}
                    rowKey='uid'
                    headerHeight={92}
                    rowHeight={46}
                    expandedRowKeys={expandedRowKeys}
                    wordWrap
                  >
                    <ColumnGroup className="text-center" header="Overview">
                      <Column width={130} resizable sortable>
                        <HeaderCell>Website</HeaderCell>
                        <Cell dataKey="validShopify">
                          {(rowData, rowIndex) => {
                            return (
                              <div className={`${(rowData.company && rowData.company.validShopify) ? 'status-indicators-active' : 'status-indicators-inactive'}`} />
                            )
                          }}
                        </Cell>
                      </Column>

                      <Column width={130} resizable sortable>
                        <HeaderCell>Payments</HeaderCell>
                        <Cell dataKey="validStripe">
                          {(rowData, rowIndex) => {
                            return (
                              <div className={`${(rowData.company && rowData.company.validStripe) ? 'status-indicators-active' : 'status-indicators-inactive'}`} />
                            )
                          }}
                        </Cell>
                      </Column>
                      <Column width={220} sortable resizable>
                        <HeaderCell>Company Name</HeaderCell>
                        <Cell dataKey="button">
                          {(rowData, rowIndex) => (
                            <Button disabled={companyAccessPermission} color="link" onClick={(e) => this.accessCompanyDashboard(e, rowData.id, rowData.companyName)}>
                              {rowData.companyName || ''}
                            </Button>
                          )}
                        </Cell>
                      </Column>
                      <Column width={170} resizable>
                        <HeaderCell>Change all statuses</HeaderCell>
                        <Cell dataKey="action button">
                          {(rowData, rowIndex) => (
                            <Button disabled={showNoPermission} onClick={() => this.openActionModal(rowData.id, null, 'company')}>
                              -
                            </Button>
                          )}
                        </Cell>
                      </Column>
                      <Column width={130} sortable resizable>
                        <HeaderCell>Live campaign</HeaderCell>
                        <Cell dataKey="liveVouchersCount">
                          {(rowData, rowIndex) => (
                            <div> {rowData.liveVouchersCount ? 'Yes' : "No"} </div>
                          )}
                        </Cell>
                      </Column>
                    </ColumnGroup>
                    <ColumnGroup className="text-center" header="Metrics">
                      <Column width={170} sortable resizable>
                        <HeaderCell>Face value sales (£)</HeaderCell>
                        <Cell dataKey="salesValue">
                          {(rowData, rowIndex) => (
                            <div> {formattedNumber(rowData.salesValue ? rowData.salesValue : 0)} </div>
                          )}
                        </Cell>
                      </Column>
                      <Column width={130} sortable resizable>
                        <HeaderCell>Sales (£)</HeaderCell>
                        <Cell dataKey="salesValue">
                          {(rowData, rowIndex) => {
                            if (rowData.salesValue) {
                              if (rowData.applicationFeeValue) {
                                return(
                                  <div> {formattedNumber(rowData.salesValue ? (rowData.salesValue - ((rowData.applicationFeeValue / 100))).toFixed(2) : 0)} </div>
                                )
                              }
                              return(
                                <div> {formattedNumber(rowData.salesValue)} </div>
                              )
                            }
                            return(
                              <div> {0} </div>
                            )
                          }}
                        </Cell>
                      </Column>

                      <Column width={130} sortable resizable>
                        <HeaderCell>Units (#)</HeaderCell>
                        <Cell dataKey="salesValue">
                          {(rowData, rowIndex) => (
                            <div> {formattedNumber(rowData.salesCount ? rowData.salesCount : 0)} </div>
                          )}
                        </Cell>
                      </Column>

                      <Column width={130} sortable resizable>
                        <HeaderCell>Customers (#)</HeaderCell>
                        <Cell dataKey="customers">
                          {(rowData, rowIndex) => (
                            <div> {formattedNumber(rowData.customers ? rowData.customers : 0)} </div>
                          )}
                        </Cell>
                      </Column>
                      <Column width={270} resizable>
                        <HeaderCell>
                          <Row className="text-center">
                            <Col sm="12">
                              Redemptions
                            </Col>
                          </Row>
                          <Row className="text-center">
                            <Col sm="3"> {`#`} </Col>
                            <Col sm="3"> {`£`} </Col>
                            <Col sm="3"> {`% #`} </Col>
                            <Col sm="3"> {`% £`} </Col>
                          </Row>
                        </HeaderCell>
                        <Cell dataKey="redemptionCount">
                          {(rowData, rowIndex) => (
                            <Row className="text-center">
                              <Col sm="3"> {formattedNumber(rowData.redemptionCount ? rowData.redemptionCount : 0)} </Col>
                              <Col sm="3"> {formattedNumber(rowData.redemptionValue ? rowData.redemptionValue.toFixed(2) : 0)} </Col>
                              <Col sm="3"> {`${(rowData.salesCount && rowData.redemptionCount) ? (Math.round(rowData.redemptionCount / rowData.salesCount * 100)) : 0}%`} </Col>
                              <Col sm="3"> {`${(rowData.salesValue && rowData.redemptionValue) ? (Math.round(rowData.redemptionValue / rowData.salesValue * 100)) : 0}%`} </Col>
                            </Row>
                          )}
                        </Cell>
                      </Column>
                      <Column width={270} resizable>
                        <HeaderCell>
                          <Row className="text-center">
                            <Col sm="12">
                              Gifted
                            </Col>
                          </Row>
                          <Row className="text-center">
                            <Col sm="3"> {`#`} </Col>
                            <Col sm="3"> {`£`} </Col>
                            <Col sm="3"> {`% #`} </Col>
                            <Col sm="3"> {`% £`} </Col>
                          </Row>
                        </HeaderCell>
                        <Cell dataKey="giftedCount">
                          {(rowData, rowIndex) => (
                            <Row className="text-center">
                              <Col sm="3"> {formattedNumber(rowData.giftedCount ? rowData.giftedCount : 0)} </Col>
                              <Col sm="3"> {formattedNumber(rowData.giftedValue ? rowData.giftedValue.toFixed(2) : 0)} </Col>
                              <Col sm="3"> {`${(rowData.salesCount && rowData.giftedCount) ? (Math.round(rowData.giftedCount / rowData.salesCount * 100)) : 0}%`} </Col>
                              <Col sm="3"> {`${(rowData.salesValue && rowData.giftedValue) ? (Math.round(rowData.giftedValue / rowData.salesValue * 100)) : 0}%`} </Col>
                            </Row>
                          )}
                        </Cell>
                      </Column>
                      <Column width={270} sortable resizable>
                        <HeaderCell>
                          <Row className="text-center">
                            <Col sm="12">
                              Refunds
                            </Col>
                          </Row>
                          <Row className="text-center">
                            <Col sm="6"> {`#`} </Col>
                            <Col sm="6"> {`£`} </Col>
                          </Row>
                        </HeaderCell>
                        <Cell dataKey="refundsCount">
                          {(rowData, rowIndex) => (
                            <Row className="text-center">
                              <Col sm="6"> {formattedNumber(rowData.refundsCount ? rowData.refundsCount : 0)} </Col>
                              <Col sm="6"> {formattedNumber(rowData.refundsValue ? (rowData.refundsValue / 100).toFixed(2) : 0)} </Col>
                            </Row>
                          )}
                        </Cell>
                      </Column>

                      <Column width={170} sortable resizable>
                        <HeaderCell>Customer payment</HeaderCell>
                        <Cell dataKey="salesValue">
                          {(rowData, rowIndex) => {
                            if (rowData.salesValue) {
                              if (rowData.applicationFeeValue) {
                                return(
                                  <div> {formattedNumber(rowData.salesValue ? (rowData.salesValue - ((rowData.applicationFeeValue / 100))).toFixed(2) : 0)} </div>
                                )
                              }
                              return(
                                <div> {formattedNumber(rowData.salesValue)} </div>
                              )
                            }
                            return(
                              <div> {0} </div>
                            )
                          }}
                        </Cell>
                      </Column>
                    </ColumnGroup>
                    <ColumnGroup className="text-center" header="Inputs">
                      <Column width={200} resizable>
                        <HeaderCell>Category 1</HeaderCell>
                        <Cell dataKey="category1">
                          {(rowData, rowIndex) => {
                            return (
                              <div>
                                {rowData.category1 ? rowData.category1.name : ''}
                              </div>
                            )
                          }}
                        </Cell>
                      </Column>
                      <Column width={200} resizable>
                        <HeaderCell>Category 2</HeaderCell>
                        <Cell dataKey="category2">
                          {(rowData, rowIndex) => {
                            return (
                              <div>
                                {rowData.category2 ? rowData.category2.name : ''}
                              </div>
                            )
                          }}
                        </Cell>
                      </Column>
                    </ColumnGroup>
                  </Table>
                  <Button className="admin-table-more-button" onClick={(e) => this.fetchCampaignCompaniesFilteredNextPage(10)}>
                    Get 10 more
                  </Button>
                  <Button className="admin-table-more-button" onClick={(e) => this.fetchCampaignCompaniesFilteredNextPage(100)}>
                    Get 100 more
                  </Button>
                  <Button className="admin-table-more-button" onClick={(e) => this.fetchCampaignCompaniesFilteredNextPage('all')}>
                    Get all
                  </Button>
                  <Button color="primary" onClick={(e) => this.downloadData(voucherCampaignsCompany, 'company')}>
                    Download Data
                  </Button>
                </div>
              )}  */}
          </CardBody>
        </Card>

        <Modal className="campaign-actions-modal" size="lg" isOpen={actionModalOpen} toggle={this.closeActionModal}>
          <ModalHeader toggle={this.closeActionModal}>
            {changes ? ('Requested changes') : ('Actions')}
          </ModalHeader>
          <ModalBody className="campaign-actions-modal-body">
            <Row>
              <Col sm="12">
                <Input id="status" type="select" className="form-control" value={actionModalStatus} onChange={this.changeSelectedStatus}>
                  <>
                    <option value={1}>Live</option>
                    <option value={2}>Pause new (24h)</option>
                    <option value={3}>Pause all</option>
                    <option value={4}>Stop</option>
                  </>
                </Input>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.changeStatus}>
              Accept
              {buttonPressed === `status-change` ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ) : (null)}
            </Button>
            <Button color="secondary" onClick={this.closeActionModal}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal className="campaign-actions-modal" size="lg" isOpen={companyActionModalOpen} toggle={this.closeActionModal}>
          <ModalHeader toggle={this.closeActionModal}>
            {'Actions'}
          </ModalHeader>
          <ModalBody className="campaign-actions-modal-body">
            <Row>
              <Col sm="12">
                <Input id="status" type="select" className="form-control" value={companyActionModalStatus} onChange={this.changeSelectedStatusCompany}>
                  <>
                    <option value={1}>Live</option>
                    <option value={2}>Pause new (24h)</option>
                    <option value={3}>Pause all</option>
                    <option value={4}>Stop</option>
                  </>
                </Input>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.changeStatusCompany}>Accept</Button>
            <Button color="secondary" onClick={this.closeActionModal}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal className="campaign-actions-modal" size="lg" isOpen={decisionModalOpen} toggle={this.closeDecisionModal}>
          <ModalHeader toggle={this.closeDecisionModal}>
            {(changes && decisionModalStatus !== 8) ? ('Requested changes') : ('Actions')}
          </ModalHeader>
          <ModalBody className="campaign-actions-modal-body">
            <Row>

              {(decisionModalStatus === 8) ? (
                <Col xs="12">
                  <Button disabled={buttonPressed} color="success" style={{ marginRight: 15 }} onClick={() => { this.changeDecision('approveCampaign', 'approved') }}>
                    {buttonPressed === `all-approved` ? (
                      <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                    ) : (null)}
                    Accept
                  </Button>
                  <Button disabled={buttonPressed} color="danger" onClick={() => { this.changeDecision('approveCampaign', 'denied') }}>
                    {buttonPressed === `all-denied` ? (
                      <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                    ) : (null)}
                    Deny
                  </Button>
                </Col>
              ) : (
                <>
                  {(changes && Object.keys(changes).length) ? (
                    <>
                      <Col sm="12" className="text-bold">
                        <Row>
                          <Col sm='4'> Type </Col>
                          <Col sm='4'> Old </Col>
                          <Col sm='4'> New </Col>
                        </Row>
                      </Col>
                      <Col sm="12">
                        {changes ? Object.keys(changes).map(key => {
                          if (key === 'logo') {
                            return (
                              <>
                                <Row>
                                  <Col sm='4'> Logo </Col>
                                  <Col sm='4'> <Media src={changesOldData[key]} /> </Col>
                                  <Col sm='4'> <Media src={changes[key]} /> </Col>
                                </Row>
                                <Row>
                                  <Col sm="12">
                                    <Button disabled={buttonPressed} color="success" style={{ marginRight: 15 }} onClick={() => { this.changeSingleDecision('logo', 'change', 'approved') }}>
                                      {buttonPressed === `${key}-approved` ? (
                                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                                      ) : (null)}
                                      Accept
                                    </Button>
                                    <Button disabled={buttonPressed} color="danger" onClick={() => { this.changeSingleDecision('logo', 'change', 'denied') }}>
                                      {buttonPressed === `${key}-denied` ? (
                                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                                      ) : (null)}
                                      Deny
                                    </Button>
                                  </Col>
                                </Row>
                              </>
                            )
                          } else if (key === 'primaryVoucherImage') {
                            return (
                              <>
                                <Row>
                                  <Col sm='4'> Primary voucher image </Col>
                                  <Col sm='4'> <Media src={changesOldData[key]} /> </Col>
                                  <Col sm='4'> <Media src={changes[key]} /> </Col>
                                </Row>
                                <Row>
                                  <Col sm="12">
                                    <Button disabled={buttonPressed} color="success" style={{ marginRight: 15 }} onClick={() => { this.changeSingleDecision('primaryVoucherImage', 'change', 'approved') }}>
                                      {buttonPressed === `${key}-approved` ? (
                                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                                      ) : (null)}
                                      Accept
                                    </Button>
                                    <Button disabled={buttonPressed} color="danger" onClick={() => { this.changeSingleDecision('primaryVoucherImage', 'change', 'denied') }}>
                                      {buttonPressed === `${key}-denied` ? (
                                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                                      ) : (null)}
                                      Deny
                                    </Button>
                                  </Col>
                                </Row>
                              </>
                            )
                          } else if (key === 'voucherImages') {
                            return (
                              <>
                                <CampaignChangesVoucherImages newVoucherImages={newVoucherImages} oldVoucherImages={oldVoucherImages} />
                                <Row>
                                  <Col sm="12">
                                    <Button disabled={buttonPressed} color="success" style={{ marginRight: 15 }} onClick={() => { this.changeSingleDecision('voucherImages', 'change', 'approved') }}>
                                      {buttonPressed === `${key}-approved` ? (
                                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                                      ) : (null)}
                                      Accept
                                    </Button>
                                    <Button disabled={buttonPressed} color="danger" onClick={() => { this.changeSingleDecision('voucherImages', 'change', 'denied') }}>
                                      {buttonPressed === `${key}-denied` ? (
                                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                                      ) : (null)}
                                      Deny
                                    </Button>
                                  </Col>
                                </Row>
                              </>
                            )
                          } else if (key === 'category1' || key === 'category2') {
                            return (
                              <>
                                <Row>
                                  <Col sm='4'> {`${key === 'category1' ? 'Category 1' : 'Category 2'}`} </Col>
                                  <Col sm='4'> {`${changesOldData[key] ? changesOldData[key].name : 'None'}`} </Col>
                                  <Col sm='4'> {`${(categories && changes[key] && categories[changes[key]]) ? categories[changes[key]].name : 'None'}`} </Col>
                                </Row>
                                <Row>
                                  <Col sm="12">
                                    <Button disabled={buttonPressed} color="success" style={{ marginRight: 15 }} onClick={() => { this.changeSingleDecision(key, 'change', 'approved') }}>
                                      {buttonPressed === `${key}-approved` ? (
                                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                                      ) : (null)}
                                      Accept
                                    </Button>
                                    <Button disabled={buttonPressed} color="danger" onClick={() => { this.changeSingleDecision(key, 'change', 'denied') }}>
                                      {buttonPressed === `${key}-denied` ? (
                                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                                      ) : (null)}
                                      Deny
                                    </Button>
                                  </Col>
                                </Row>
                              </>
                            )
                          } else if (key === 'status') {
                            return (
                              <>
                                <Row>
                                  <Col sm='4'> {`${key}`} </Col>
                                  <Col sm='4'>
                                    {changesOldData[key] === 1 ? `Live` : null}
                                    {changesOldData[key] === 2 ? `Pause new (24h)` : null}
                                    {changesOldData[key] === 3 ? `Pause all` : null}
                                    {changesOldData[key] === 4 ? `Expired (stopped)` : null}
                                    {changesOldData[key] === 5 ? `Expired (completed)` : null}
                                    {changesOldData[key] === 6 ? `Expired (denied)` : null}
                                    {changesOldData[key] === 7 ? 'Connection error' : null}
                                    {changesOldData[key] === 8 ? `Requesting` : null}
                                  </Col>
                                  <Col sm='4'>
                                    {changes[key] === 1 ? `Live` : null}
                                    {changes[key] === 2 ? `Pause new (24h)` : null}
                                    {changes[key] === 3 ? `Pause all` : null}
                                    {changes[key] === 4 ? `Expired (stopped)` : null}
                                    {changes[key] === 5 ? `Expired (completed)` : null}
                                    {changes[key] === 6 ? `Expired (denied)` : null}
                                    {changes[key] === 7 ? 'Connection error' : null}
                                    {changes[key] === 8 ? `Requesting` : null}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm="12">
                                    <Button disabled={buttonPressed} color="success" style={{ marginRight: 15 }} onClick={() => { this.changeSingleDecision('status', 'change', 'approved') }}>
                                      {buttonPressed === `${key}-approved` ? (
                                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                                      ) : (null)}
                                      Accept
                                    </Button>
                                    <Button disabled={buttonPressed} color="danger" onClick={() => { this.changeSingleDecision('status', 'change', 'denied') }}>
                                      {buttonPressed === `${key}-denied` ? (
                                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                                      ) : (null)}
                                      Deny
                                    </Button>
                                  </Col>
                                </Row>
                              </>
                            )
                          } else if (key === 'hasDisplayName') {
                            return (
                              <>
                                <Row>
                                  <Col sm='4'> Has trading name? </Col>
                                  <Col sm='4'> {`${changesOldData[key] === false ? 'No' : (changesOldData[key] ? 'Yes' : 'None')}`} </Col>
                                  <Col sm='4'> {`${changes[key] === false ? 'No' : (changes[key] ? 'Yes' : 'None')}`} </Col>

                                </Row>
                                <Row>
                                  <Col sm="12">
                                    <Button disabled={buttonPressed} color="success" style={{ marginRight: 15 }} onClick={() => { this.changeSingleDecision('hasDisplayName', 'change', 'approved') }}>
                                      {buttonPressed === `${key}-approved` ? (
                                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                                      ) : (null)}
                                      Accept
                                    </Button>
                                    <Button disabled={buttonPressed} color="danger" onClick={() => { this.changeSingleDecision('hasDisplayName', 'change', 'denied') }}>
                                      {buttonPressed === `${key}-denied` ? (
                                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                                      ) : (null)}
                                      Deny
                                    </Button>
                                  </Col>
                                </Row>
                              </>
                            )
                          } else if (key === 'displayName') {
                            return (
                              <>
                                <Row>
                                  <Col sm='4'> Display name </Col>
                                  <Col sm='4'> {`${changesOldData[key] || ''}`} </Col>
                                  <Col sm='4'> {`${changes[key] || ''}`} </Col>

                                </Row>
                                <Row>
                                  <Col sm="12">
                                    <Button disabled={buttonPressed} color="success" style={{ marginRight: 15 }} onClick={() => { this.changeSingleDecision('displayName', 'change', 'approved') }}>
                                      {buttonPressed === `${key}-approved` ? (
                                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                                      ) : (null)}
                                      Accept
                                    </Button>
                                    <Button disabled={buttonPressed} color="danger" onClick={() => { this.changeSingleDecision('displayName', 'change', 'denied') }}>
                                      {buttonPressed === `${key}-denied` ? (
                                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                                      ) : (null)}
                                      Deny
                                    </Button>
                                  </Col>
                                </Row>
                              </>
                            )
                          } else if (key === 'totalValue') {
                            return (
                              <>
                                <Row>
                                  <Col sm='4'> Total value </Col>
                                  <Col sm='4'> {`${changesOldData[key] || ''}`} </Col>
                                  <Col sm='4'> {`${changes[key] || ''}`} </Col>

                                </Row>
                                <Row>
                                  <Col sm="12">
                                    <Button disabled={buttonPressed} color="success" style={{ marginRight: 15 }} onClick={() => { this.changeSingleDecision('totalValue', 'change', 'approved') }}>
                                      {buttonPressed === `${key}-approved` ? (
                                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                                      ) : (null)}
                                      Accept
                                    </Button>
                                    <Button disabled={buttonPressed} color="danger" onClick={() => { this.changeSingleDecision('totalValue', 'change', 'denied') }}>
                                      {buttonPressed === `${key}-denied` ? (
                                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                                      ) : (null)}
                                      Deny
                                    </Button>
                                  </Col>
                                </Row>
                              </>
                            )
                          } else {
                            return (
                              <>
                                <Row>
                                  <Col sm='4'> {`${key}`} </Col>
                                  <Col sm='4'> {`${changesOldData[key]}`} </Col>
                                  <Col sm='4'> {`${changes[key]}`} </Col>
                                </Row>
                                <Row>
                                  <Col sm="12">
                                    <Button disabled={buttonPressed} color="success" style={{ marginRight: 15 }} onClick={() => { this.changeSingleDecision(key, 'change', 'approved') }}>
                                      {buttonPressed === `${key}-approved` ? (
                                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                                      ) : (null)}
                                      Accept
                                    </Button>
                                    <Button disabled={buttonPressed} color="danger" onClick={() => { this.changeSingleDecision(key, 'change', 'denied') }}>
                                      {buttonPressed === `${key}-denied` ? (
                                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                                      ) : (null)}
                                      Deny
                                    </Button>
                                  </Col>
                                </Row>
                              </>
                            )
                          }
                        }) : (null)}
                      </Col>
                      <Col sm="12" className="text-bold campaign-actions-modal-header">
                        Actions
                      </Col>
                      <Col sm="12">
                        {(decisionModalStatus !== 8) ? (
                          <>
                            <Button disabled={buttonPressed} color="success" style={{ marginRight: 15 }} onClick={() => { this.changeDecision('change', 'approved') }}>
                              {buttonPressed === `all-approved` ? (
                                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                              ) : (null)}
                              Accept All
                            </Button>
                            <Button disabled={buttonPressed} color="danger" onClick={() => { this.changeDecision('change', 'denied') }}>
                              {buttonPressed === `all-denied` ? (
                                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                              ) : (null)}
                              Deny All
                            </Button>
                          </>
                        ) : (null)}
                        {/*  <Button color="success" style={{ marginRight: 15 }} onClick={() => {this.changeDecision('change', 'approved')}}>Accept All</Button>
                      <Button color="danger" onClick={() => {this.changeDecision('change', 'denied')}}>Deny All</Button>  */}
                      </Col>
                    </>
                  ) : (null)}
                </>
              )}

              {(companyComplianceChanges && decisionModalStatus !== 8) ? (
                <>
                  <Row style={{ fontWeight: 700 }}>
                    <Col sm='6'> Question </Col>
                    <Col sm='3'> Old </Col>
                    <Col sm='3'> New </Col>
                  </Row>
                  {Object.keys(companyComplianceChanges).map((key, index) => {
                    if (key === 'question1Description' && companyComplianceData.question1 === true) {
                      return null;
                    }

                    if (key === 'question2Description' && companyComplianceData.question2 === true) {
                      return null;
                    }

                    if (key === 'question3Description' && companyComplianceData.question3 === false) {
                      return null;
                    }

                    if (key === 'question5Description' && companyComplianceData.question5 !== '1') {
                      return null;
                    }

                    return (
                      <Row>
                        <Col sm='6'> {complianceTexts[key]} </Col>
                        <Col sm='3'>
                          {(key === 'question1' || key === 'question2' || key === 'question3') ? (
                            <>
                              {companyComplianceBefore[key] ? "Yes" : "No"}
                            </>
                          ) : (
                            <>
                              {(key === 'question4' || key === 'question5') ? (
                                <>
                                  {key === 'question4' ? (
                                    <>
                                      {question4Options[parseInt(companyComplianceBefore[key], 10) - 1]}
                                    </>
                                  ) : (
                                    <>
                                      {question5Options[parseInt(companyComplianceBefore[key], 10) - 1]}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {companyComplianceBefore[key]}
                                </>
                              )}
                            </>
                          )}
                        </Col>
                        <Col sm='3'>
                          {(key === 'question1' || key === 'question2' || key === 'question3') ? (
                            <>
                              {companyComplianceChanges[key] ? "Yes" : "No"}
                            </>
                          ) : (
                            <>
                              {(key === 'question4' || key === 'question5') ? (
                                <>
                                  {key === 'question4' ? (
                                    <>
                                      {question4Options[parseInt(companyComplianceChanges[key], 10) - 1]}
                                    </>
                                  ) : (
                                    <>
                                      {question5Options[parseInt(companyComplianceChanges[key], 10) - 1]}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {companyComplianceChanges[key]}
                                </>
                              )}
                            </>
                          )}
                        </Col>
                        {/*  <Col sm='4'> {companyComplianceChanges[key]} </Col> */}
                        {/*  <Col sm='3'>
                        <Button onClick={() => this.dismissComplianceChange(key)}>
                          Dismiss
                        </Button>
                      </Col>  */}
                      </Row>
                    )
                  }
                  )}
                </>
              ) : (null)}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.closeDecisionModal}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={approveCampaignModal} toggle={this.closeApprove}>
          <ModalHeader toggle={this.closeApprove}>
            Are you sure you want to approve this campaign?
          </ModalHeader>
          <ModalBody>
            {(!!approveCampaignErr) && <Alert color="danger">{approveCampaignErr}</Alert>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.approveCampaign}>Approve</Button>
            <Button color="secondary" onClick={this.closeApprove}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={redemtionModal} toggle={this.closeChangeRedemptionsStatus}>
          <ModalHeader toggle={this.closeChangeRedemptionsStatus}>

          </ModalHeader>
          <ModalBody>
            Are you sure?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.changeRedemptionsStatus}>Approve</Button>
            <Button color="secondary" onClick={this.closeChangeRedemptionsStatus}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={denyCampaignModal} toggle={this.closeApprove}>
          <ModalHeader toggle={this.closeApprove}>
            Are you sure?
          </ModalHeader>
          <ModalBody>
            {(!!denyCampaignErr) && <Alert color="danger">{denyCampaignErr}</Alert>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.denyCampaign}>Approve</Button>
            <Button color="secondary" onClick={this.closeDeny}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={campaignChangesModal} toggle={this.closeCampaignChanges}>
          <ModalHeader toggle={this.closeCampaignChanges}>
            Are you sure?
          </ModalHeader>
          <ModalBody>
            {(!!campaignChangesErr) && <Alert color="danger">{campaignChangesErr}</Alert>}
            {changes ? Object.keys(changes).map(key => {
              if (key === 'logo') {
                return (
                  <>
                    <Row>
                      <Col sm='3'> Logo </Col>
                      <Col> <Media src={changesOldData[key]} /> </Col>
                      <Col> <Media src={changes[key]} /> </Col>
                    </Row>
                  </>
                )
              } else if (key === 'primaryVoucherImage') {
                return (
                  <>
                    <Row>
                      <Col sm='3'> Primary voucher image </Col>
                      <Col> <Media src={changesOldData[key]} /> </Col>
                      <Col> <Media src={changes[key]} /> </Col>
                    </Row>
                  </>

                )
              } else if (key === 'totalValue') {
                return (
                  <Row>
                    <Col sm='4'> Total Value </Col>
                    <Col> {`${changesOldData[key]}`} </Col>
                    <Col> {`${changes[key]}`} </Col>
                  </Row>
                )
              } else {
                return (
                  <Row>
                    <Col sm='4'> {`${key}`} </Col>
                    <Col> {`${changesOldData[key]}`} </Col>
                    <Col> {`${changes[key]}`} </Col>
                  </Row>
                )
              }
            }) : (null)}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.approveCampaignChanges}>Approve</Button>
            <Button color="secondary" onClick={this.closeCampaignChanges}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  voucherCampaigns: state.admin.voucherCampaigns || [],
  voucherCampaignsCompany: state.admin.voucherCampaignsCompany || [],
  categories: state.company.categories || {},
  adminsRoles: state.admin.adminsRoles || {},
  companiesComplianceData: state.admin.companiesComplianceData || {},
});

const mapDispatchToProps = {
  fetchAllCampaigns: getRequestingCampaigns,
  approveCampaign: approveVoucherCampaign,
  approveCampaignChanges: approveVoucherCampaignChanges,
  approveCampaignChange: approveVoucherCampaignChange,
  denyCampaign: denyVoucherCampaign,
  denyCampaignChanges: denyVoucherCampaignChanges,
  denyCampaignChange: denyVoucherCampaignChange,
  fetchCampaignsFiltered: getCampaignsFiltered,
  fetchCampaignsFilteredNextPage: getCampaignsFilteredNextPage,
  fetchCampaignData: getCampaignData,
  fetchCampaignCompaniesFiltered: getCampaignCompaniesFiltered,
  fetchCampaignCompaniesFilteredNextPage: getCampaignCompaniesFilteredNextPage,
  changeStatus: changeCampaignStatus,
  changeStatusCompany: changeCampaignStatusCompany,
  accessCompanyDashboard: openCompanyDashboard,
  changeRedemptionsStatus: toggleRedemptionsStatus,
  changeCampaignCommission: changeVoucherCampaignCommission,
  dismissComplianceChange: dismissComplianceDataChange,
  dismissAllComplianceChanges: dismissAllComplianceDataChanges,
  fetchCompaniesComplianceData: getCompaniesComplianceData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Campaigns));
