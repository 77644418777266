import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Card, CardBody, Row, Col, Button } from 'reactstrap';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState } from 'draft-js';
import 'draft-js/dist/Draft.css';

//import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const heroStyles = {
  padding: '50px 0 70px'
};

class Pending extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    pendingTab: PropTypes.shape(),
    fetchPendingTabData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    pendingTab: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    const { fetchPendingTabData } = this.props;

    fetchPendingTabData().then((res) => {
      if (res) {
      //  let contentState = stateFromHTML(res);

        const blocksFromHtml = htmlToDraft(res);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        if (contentState) {
          this.setState({ editorState: EditorState.createWithContent(contentState) });
        }
      }
    });
  }

  componentWillUnmount() {
    const { history } = this.props;

    console.log('pending componentWillUnmount');

    history.push('/');
  }


  render() {
    const { user, pendingTab, footers } = this.props;
    const { editorState } = this.state;

    return (
      <div>
        <Row>
          <Col md={6}>
            <div className="home-hero" style={heroStyles}>
              <Editor
                className="content-management-text-area"
                editorState={editorState}
                toolbarHidden
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                readOnly
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  pendingTab: state.contentManagement.pendingTab || '',
  footers: state.contentManagement.footers || {},
});

export default withRouter(connect(mapStateToProps, null)(Pending));
