import moment from 'moment';

const test1 = [12, 21, 24, 45, 130, 43, 56, 24, 50, 76, 51, 20, 41, 32, 45, 13, 33, 51, 10, 15, 60, 82, 91, 35, 76, 52, 130, 78, 70, 71];
const test2 = [720, 850, 1300, 930, 610, 800, 1540, 800, 120, 630, 910, 350, 1260, 240, 610, 1100, 720, 1210, 1240, 500, 2120, 1070, 380, 400, 1600, 1580, 1400, 910, 1120, 1910];
const test3 = [10, 28, 40, 10, 15, 30, 10, 20, 94, 40, 21, 22, 45, 10, 12, 21, 21, 24, 30, 12, 10, 66, 51, 40, 41, 35, 46, 44, 23, 56];

export const getTempVoucherSales = ( timeFrame, customStartDate, customEndDate) => {
//  console.log('getTempVoucherSales', { timeFrame, customStartDate, customEndDate });

  let dateLength = 1;
  let startDate;
  let endDate;

  if (timeFrame === 'custom') {
    startDate = moment(customStartDate);

    endDate = moment(customEndDate).add(1, 'days');
  } else if (timeFrame === 'allTime') {

    let allTimeStartDate = customStartDate;
    let allTimeEndDate = new Date();

    startDate = moment(allTimeStartDate);
    endDate = moment(allTimeEndDate).add(1, 'days');
  } else {
    if (timeFrame === 'day') {
      dateLength = 1;
    } else if (timeFrame === 'week') {
      dateLength = 7;
    } else if (timeFrame === 'month') {
      dateLength = 30;
    } else if (timeFrame === 'year') {
      dateLength = 265;
    }

    startDate = moment().subtract(dateLength, 'days');
    endDate = moment().add(1, 'days');
  }

  const startDateSplit = startDate.format("DD-MM-YYYY").split("-");

  let startDay = startDateSplit[0];
  let startMonth = startDateSplit[1];
  const startYear = startDateSplit[2];

      let vouchersCount = 0;
      let vouchersValue = 0;
      let voucherTotalFee = 0;
      let vouchersUsedCount = 0;
      let vouchersByDate = {};
      let vouchersUsedByDate = {};
      let vouchersValueByDate = {};

  //  console.log('getTempVoucherSales vouchersByDate', { vouchersByDate, vouchersValueByDate, vouchersUsedByDate });

      let dates = [];

      let vouchersByDateNew = {};

    //  console.log('startDate', startDate, endDate);

      let currDate = startDate.clone().startOf("day");
    let lastDate = endDate.clone();
      const todayDate = moment();

   //   console.log('lastDate', lastDate);

      let keyFormat = "DD MMM";

  let index = 0;

      do {
     //   console.log('currDate do', currDate);
        vouchersByDateNew[currDate.clone().format("DD-MM-YYYY")] = { vouchersCount }


        if (todayDate.clone().format(keyFormat) !== currDate.clone().format(keyFormat)) {
       //   console.log('dates push', currDate.clone().format(keyFormat));
          dates.push({
            key: currDate.clone().format(keyFormat),
            day: currDate.clone().toDate(),
            value: test1[index] || 0,
            vouchersCount: test1[index] || 0,
            vouchersValue: test2[index] || 0,
            vouchersUsed: test3[index] || 0,
          });

          if (index === 29) {
            index = 0;
          } else {
            index = index + 1;
          }
        }

      } while (currDate.add(1, "days").diff(lastDate) < -(60 * 60 * 24 * 1000 * 1));

    //  console.log('dates 1', dates.length, currDate);

 // console.log('getTempVoucherSales GET_VOUCHER_SALES_DATA call 1', { dates });

      if (todayDate.clone().format(keyFormat) !== currDate.clone().format(keyFormat) && (currDate.clone().format(keyFormat) !== lastDate.clone().format(keyFormat))) {
      //  console.log('dates push', currDate.clone().format(keyFormat));

        dates.push({
          key: currDate.clone().format(keyFormat),
          day: currDate.clone().toDate(),
          value: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
          vouchersCount: vouchersByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
          vouchersUsed: vouchersUsedByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
          vouchersValue: vouchersValueByDate[currDate.clone().format("DD-MM-YYYY")] || 0,
        });
      }

  return (dates);
};