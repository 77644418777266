import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
// import * as Feather from 'react-feather';

import { Eye, EyeOff } from 'react-feather';

import { Card, CardBody, Row, Col, Input, Label, Form, FormGroup, Button, Alert, Spinner } from 'reactstrap';

import { connect } from 'react-redux';

import {
  doSignup,
} from '../../actions/user';

import notifications from '../../const/notifications';

class Signup extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    signup: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      loading: false,
      email: null,
      password: null,
      roleInCompany: 'unselected',
      showPassword1: 'password',
      showPassword2: 'password',
      companyType: 'individual',
    };
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

/*  handleImageUpload = (e) => {
    console.log('handleImageUpload');
    console.log(e.target.files);

    if (e.target && e.target.files) {
      this.setState({ logo: e.target.files[0] });
    }
  } */

  selectRoleinCompany = (e) => {
    this.setState({ roleInCompany: e.target.value });
  }

  handleSubmit = (e) => {
    console.log('handleSubmit');
    e.preventDefault();

    const { signup, history } = this.props;
    const { email, password, confirmPassword, loading, name, roleInCompany, phoneNumber, companyName, companyNumber, companyType } = this.state; //, website, logo

    console.log(email);
    console.log(loading);

    console.log(password);

    if (!email) {
     this.setState({ loading: false, error: notifications.signUp.noEmail || 'Missing email', success: null });

     window.scrollTo({
       top: 0,
       left: 0,
       behavior: 'smooth'
     });
    } else if (!name) {
      this.setState({ loading: false, error: notifications.signUp.noName || 'Missing name', success: null });

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else if (!roleInCompany) {
      this.setState({ loading: false, error: notifications.signUp.noRoleInCompany || 'Missing role in company', success: null });

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else if (!companyName) {
      this.setState({ loading: false, error: notifications.signUp.noCompanyName || 'Missing company name', success: null });

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else if (companyType === 'company' && !companyNumber) {
      this.setState({ loading: false, error: notifications.signUp.noCompanyNumber || 'Missing company number', success: null });

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else if (!password) { //!confirmPassword || 
      this.setState({ loading: false, error: notifications.signUp.noPassword || 'Missing password', success: null });

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    /* } else if (confirmPassword !== password) {
      this.setState({ loading: false, error: notifications.signUp.passwordsDontMatch || 'Passwords dont match', success: null });

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      }); */
    } else {
      if(loading) return;

      this.setState({ loading: true });

     // if (password.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$/)) {
      if (password.match(/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/)) { ///^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$/
        console.log('valid password');
        this.setState({ error: null, success: null });

        return signup({ email: email, password: password, name, roleInCompany, phoneNumber, companyName, companyNumber, companyType }) //, website, logo
          .then((res) => {
            console.log('signup res');
            console.log(res);

            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });

            if (res === 'success') {
              this.setState({ loading: false, success: true, error: false });
            //  history.push('/login');
            } else {
              if (res === 'existing company id') {
                this.setState({ loading: false, success: false, error: 'Company number already in use' });
              } else {
                this.setState({ loading: false, success: false, error: res });
              }
            }
          })
          .catch((err) => {
            console.log(err);
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });

            if (err === 'The email address is badly formatted.') {
              this.setState({ loading: false, error: notifications.signUp.badEmail || err, success: null })
            } else if (err === 'The email address is already in use by another account.') {
              this.setState({ loading: false, error: notifications.signUp.usedEmail || err, success: null })
            } else {
              this.setState({ loading: false, error: notifications.signUp.failedToCreate || err, success: null });
            }

          });
      } else {
        this.setState({ loading: false, success: null, error: notifications.signUp.invalidPassword || 'Password needs to be at least 8 characters, have at least 1 uppercase, lowercase, number, symbol.' });

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
    }
  }

  toggleShowPassword = (id) => {
    console.log('toggleShowPassword');
    console.log(id);
    if (id === 1) {
    //  this.setState((prevState) => ({ showPassword1: !prevState.showPassword1 }));

      this.setState({
        showPassword1: this.state.showPassword1 === 'input' ? 'password' : 'input'
      })
    } else if (id === 2) {
    //  this.setState((prevState) => ({ showPassword2: !prevState.showPassword2 }));
      this.setState({
        showPassword2: this.state.showPassword2 === 'input' ? 'password' : 'input'
      })
    }
  }

  handleChangeCompanyType = (event) => {
    console.log('onChangeCompanyType', event.target.value);

    this.setState({ companyType: event.target.value });
  }

  render() {
  //  const { success, error } = this.props;
    const {
      email,
      password,
      error,
      success,
      name,
      roleInCompany,
      phoneNumber,
      companyName,
      companyNumber,
      companyType,
      website,
    //  confirmPassword,
      showPassword1,
    //  showPassword2,
      loading,
     } = this.state;

    console.log('success', { error, success });

    return (
      <div>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <Card>
              <CardBody>
                {(!!error) && <Alert color="danger">{error}</Alert>}
                {(!!success) && <Alert color="success"> Thank you for signing up. To access your account please click the verification link in the email sent to you. You may need to check your junk folder. </Alert>}
                <Row style={{ marginBottom: 12 }}>
                  <Col xs="12">
                    <div><b style={{ color: '#6fc0f9' }}>Super-platform for selling vouchers.</b> Easy and free to use.</div>
                  </Col>
                  {/* <Col xs="3">
                    <div style={{ textAlign: 'right' }}> <div className="mandatory">*</div>Mandatory fields </div>
                  </Col> */}
                </Row>

                <Form onSubmit={this.handleSubmit}>
                  <Row>
                      <Col xs="12">
                      <FormGroup>
                        {!name ? (
                          <Label className="custom-input-placeholder" for="name">Name</Label>
                        ) : (null)}
                        <Input
                          name="name"
                          id="name"
                          value={name}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    {!email ? (
                      <Label className="custom-input-placeholder" for="email">Email</Label>
                    ) : (null)}
                    <Input
                      name="email"
                      id="email"
                      value={email}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <Row>
                    <Col xs="6">
                      <FormGroup>
                        {!companyName ? (
                          <Label className="custom-input-placeholder" for="companyName">Business name</Label>
                        ) : (null)}
                        <Input
                          name="companyName"
                          id="companyName"
                          value={companyName}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>

                        {(!roleInCompany || roleInCompany === 'unselected') ? (
                          <Label className="custom-input-placeholder" for="roleInCompany">Role in business</Label>
                        ) : (null)}
                        <Input id="roleInCompany" type="select" value={roleInCompany} onChange={this.selectRoleinCompany}>
                          <option selected disabled hidden value={'unselected'}></option>
                          <option value={'owner'}>Owner</option>
                          <option value={'CEO'}>CEO</option>
                          <option value={'MD'}>MD</option>
                          <option value={'director'}>Director</option>
                          <option value={'manager'}>Manager</option>
                          <option value={'employee'}>Employee</option>
                          <option value={'agency/agent'}>Agency/Agent</option>
                          <option value={'intern'}>Intern</option>
                          <option value={'other'}>Other</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <FormGroup>
                    <Row>
                      <Col xs="12">
                        <div style={{ width: '170', display: 'inline-block' }}>
                          <Input
                            type="radio"
                            name="companyType"
                            id="companyType1"
                            value={'company'}
                            checked={companyType === 'company'}
                            onChange={this.handleChangeCompanyType}
                          />
                          <Label check style={{ marginLeft: 5 }}>
                            Limited company
                          </Label>
                        </div>
                        <Input
                          type="text"
                          name="companyNumber"
                          id="companyNumber"
                          placeholder="Company number"
                          value={companyNumber}
                          onChange={this.handleChange}
                          style={{ width: 'calc(100% - 175px)', display: 'inline-block' }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <Input
                          type="radio"
                          name="companyType"
                          id="companyType2"
                          value={'individual'}
                          checked={companyType === 'individual'}
                          onChange={this.handleChangeCompanyType}
                        />
                        <Label check style={{ marginLeft: 5 }}>
                          Sole trader
                        </Label>
                      </Col>
                    </Row>
                  </FormGroup> */}

                  <FormGroup>
                    <Row>
                      <Col xs="6">
                        <Button
                          name="companyType"
                          id="companyType2"
                          color={companyType === 'individual' ? 'primary' : 'secondary'}
                          value={'individual'}
                          onClick={this.handleChangeCompanyType}
                          style={{ width: '100%' }}
                        >
                          Sole trader
                        </Button>
                      </Col>
                      <Col xs="6">
                        {companyType === 'company' ? (
                          <Input
                            type="text"
                            name="companyNumber"
                            id="companyNumber"
                            placeholder="Company number (if limited company)"
                            value={companyNumber}
                            onChange={this.handleChange}
                            style={{ width: '100%', border: '2px solid #6FC0F9' }}
                          />
                        ): (
                          <Button
                              name="companyType"
                              id="companyType1"
                              color={companyType === 'company' ? 'primary' : 'secondary'}
                              value={'company'}
                              onClick={this.handleChangeCompanyType}
                              style={{ width: '100%' }}
                            >
                            Limited company
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>

                  {/* <FormGroup>
                    {!phoneNumber ? (
                      <Label className="custom-input-placeholder" for="phoneNumber">Phone Number (UK)</Label>
                    ) : (null)}
                    <Input
                      name="phoneNumber"
                      id="phoneNumber"
                      value={phoneNumber}
                      onChange={this.handleChange}
                    />
                  </FormGroup> */}
                  <Row>
                    <Col xs="12">
                      <FormGroup style={{ position: 'relative' }}>
                        {!password ? (
                          <Label className="custom-input-placeholder" for="password">Password</Label>
                        ) : (null)}
                        <Input
                          type={showPassword1}
                          name="password"
                          id="password"
                          value={password}
                          onChange={this.handleChange}
                        />
                        {showPassword1 === 'input' ? (
                          <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem', width: 20 }} onClick={() => this.toggleShowPassword(1)}/>
                        ):(
                          <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem', width: 20 }} onClick={() => this.toggleShowPassword(1)}/>
                        )}
                      </FormGroup>
                    </Col>
                    {/* <Col xs="6">
                      <FormGroup style={{ position: 'relative' }}>
                        {!confirmPassword ? (
                          <Label className="custom-input-placeholder" for="confirmPassword">Confirm Password</Label>
                        ) : (null)}
                        <Input
                          type={showPassword2}
                          name="confirmPassword"
                          id="confirmPassword"
                          value={confirmPassword}
                          onChange={this.handleChange}
                        />
                        {showPassword2 === 'input' ? (
                          <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem', width: 20 }} onClick={() => this.toggleShowPassword(2)}/>
                        ):(
                          <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem', width: 20 }} onClick={() => this.toggleShowPassword(2)}/>
                        )}
                      </FormGroup>
                    </Col> */}
                  </Row>
                  <Button disabled={loading} id="sign-up-button" className="custom-primary-button" color="primary">
                    Create your account
                    {loading ? (
                      <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem', marginTop: 3 }}> {''} </Spinner>
                    ):(null)}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user || {},
});

const mapDispatchToProps = {
  signup: doSignup,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));