import {
  GET_EMAIL_SETTINGS,
  REMOVE_EMAIL_SETTING,
  GET_COMPANY_NOTIFICATION,
} from '../../actions';

import { auth } from '../../lib/firebase';

const defaultState = {
    emailSettings: {},
    companyNotification: [],
};

const userReducer = (state=defaultState, action) => {
    switch(action.type) {
        case GET_EMAIL_SETTINGS:
            return { ...state, emailSettings: action.payload };
        case REMOVE_EMAIL_SETTING: {
            let newSettings = { ...state.emailSettings };
            delete newSettings[action.key];

            return {
              ...state,
              emailSettings: newSettings,
            };
        }
        case GET_COMPANY_NOTIFICATION:
            return { ...state, companyNotification: action.payload };

        default:
            return state;
    }
};

export default userReducer;
