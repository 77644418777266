import React from 'react';
import PropTypes from 'prop-types';

const Toggle = React.memo(({
  checked, onChange, readOnly, disabled,
}) => (
  <>
    <label class="switch">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        readOnly={readOnly}
        disabled={disabled}
      />
      <span class="slider round"></span>
    </label>
  </>
));


Toggle.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Toggle.defaultProps = {
  checked: false,
};

export default Toggle;
