import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Card, CardBody, Button, Input } from 'reactstrap';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';

import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';

import { connect } from 'react-redux';
import Moment from 'react-moment';

import {
  getAdminActions,
  getDataFiltered,
  getCampaignsFiltered,
} from '../../../actions/admin';

const campaignStatuses = {
  '1': 'Live',
  '2': 'Paused new',
  '3': 'Paused all',
  '4': 'Expired (stopped)',
  }

class AdminActions extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admins: PropTypes.shape(),
    adminActions: PropTypes.shape(),
    companyOwnersObj: PropTypes.shape(),
    voucherCampaigns: PropTypes.shape(),
    getActions: PropTypes.func.isRequired,
    fetchDataFiltered: PropTypes.func.isRequired,
    fetchCampaignsFiltered: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    adminActions: {},
    voucherCampaigns: {},
    companyOwnersObj: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      deleteUserModal: false,
      deleteUserErr: null,
      userToDelete: null,
      showNoPermission: false,
      dontShowPage: false,
      createAdminErr: null,
      showPassword1: 'password',
      showPassword2: 'password',
      companyFilter: 'all',
      startDate: null,
      endDate: null,
      roles: {
        teamOwner: 'Admin',
      },
      actionTypes: {
        voucherRefund: { name: "Voucher refund" },
        addEmailSetting: { name: "Add email setting" },
        deleteEmailSetting: { name: "Delete email setting" },
        updateEmailSetting: { name: "Update email setting" },
        voucherCancelled: { name: "Voucher cancelled" },
        changeCustomerStatus: { name: "Change customer status" },
        changeCustomerBuyingStatus: { name: "Change customer buying status" },
        changeCustomerRedemptionsStatus: { name: "Change customer redemptions status" },
        changeCustomerTester: { name: "Change customer tester" },
        changeRolePermissions: { name: "Change role permissions" },
        changeAdminRole: { name: "Change admin role" },
        createAdmin: { name: "Create admin" },
        createRole: { name: "Create role" },
        deleteAdmin: { name: "Delete admin" },
        deleteRole: { name: "Delete role" },
        campaignRequestAccepted: { name: "Campaign request accepted" },
        approveVoucherChange: { name: "Approve voucher change" },
        approveVoucherChanges: { name: "Approve voucher changes" },
        changeCampaignStatus: { name: "changeCampaignStatus" },
        denyVoucherChanges: { name: "denyVoucherChanges" },

        changeVoucherCampaignCommission: { name: "Change voucher campaign commission" },
        changeCompanyDefaultCommission: { name: "Change company default commission" },
        approvePendingUser: { name: "Approve pending user" },
        pauseActiveUser: { name: "Pause active user" },
        changeTesterStatus: { name: "Change tester status" },
        changeCompanyShowCommission: { name: "Change company show commission" },
        dismissAllComplianceDataChanges: { name: "Dismiss all compliance data changes" },

        updateContactPageData: { name: "Update contact page data" },
        updateAPIPageData: { name: "Update API page data" },
        updateNewVoucherPageData: { name: "Update new voucher page data" },
        updateContentManagementData: { name: "Update content management data" },
        updateDashboardTooltip: { name: "Update dashboard tooltip" },
        updateNewVoucherTooltip: { name: "Update new voucher tooltip" },
        updateAPITooltip: { name: "Update API tooltip" },
        updateCompanyPopoutData: { name: "Update company popout data" },
        updateCompanyTabsData: { name: "Update company tabs data" },
        updateCompanyInfoData: { name: "Update company info data" },
        updatePendingTabData: { name: "Update pending tab data" },
        updateFooter: { name: "Update footer" },
        updateBrandingData: { name: "Update branding data" },
        updateVoucherCategory: { name: "Update voucher category" },
        addVoucherCategory: { name: "Add voucher category" },
        editVoucherCategory: { name: "Edit voucher category" },
        updateEventCategory: { name: "Update event category" },
        updateEventCategories: { name: "Update event categories" },
        addEventCategory: { name: "Add event category" },
        sendVerificationEmailAsAdmin: { name: "Send verification email as admin" },
        cancelUnverifiedCompany: { name: "Cancel unverified company" },
      }
    };
  }

  componentDidMount() {
    const { user, isLoggedIn, history, getActions, fetchDataFiltered, fetchCampaignsFiltered } = this.props;

    console.log('componentDidMount', user);

    if (user && Object.keys(user).length > 0) {
      if (!user || !isLoggedIn || !user || !user.roleClass || user.roleClass !== 'admin') {
        history.push('/');
      } else {
        if (user.roleClass === 'admin') {
          this.setState({ showNoPermission: false, dontShowPage: false });
      //  } else {

          fetchDataFiltered('companies', null, null, null);
          fetchCampaignsFiltered('created_at', 'descending', null, 'allTime', null, null);
          getActions().then(() => {
            const { adminActions } = this.props;

          //  console.log('adminActions', adminActions);

          //  this.setState({ adminActions });
          });
        }
      }
    } else {
      history.push('/');
    }
  }

  fetchActions = () => {
    const { getActions } = this.props;
    const { startDate, endDate, companyFilter } = this.state;

    getActions(startDate, endDate, companyFilter).then(() => {
      const { adminActions } = this.props;

    //  console.log('adminActions', adminActions);

    //  this.setState({ adminActions });
    });
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  changeStartDate = (date) => {
    this.setState({ startDate: date });
  }

  changeEndDate = (date) => {
    this.setState({ endDate: date });
  }

  handleChangeCompanyFilter = (e) => {
    console.log('handleChangeFilter', e.target.value);

  //  const { fetchAdminDashboard } = this.props;

  //  fetchAdminDashboard(e.target.value, null, null);

    if (e.target.value && e.target.value !== 'all') {
      this.setState({ companyFilter: e.target.value });
    } else {
      this.setState({ companyFilter: 'all' });
    }

  };

  render() {
    const { user, adminActions, voucherCampaigns, companyOwnersObj, categories } = this.props;
    const {
      sortType,
      sortColumn,
      actionTypes,

      startDate,
      endDate,
      companyFilter,
    } = this.state;

    console.log('companyOwnersObj', companyOwnersObj, companyFilter, startDate, endDate);

    if (!user.role || user.roleClass !== 'admin') {
      return (
        <div>
          <Card>
            <CardBody>
              You need admin permissions to view all admins
            </CardBody>
          </Card>
        </div>
      );
    }

   // console.log('adminActions', adminActions);

    return (
      <div>
        <Card>
          <CardBody>
            <Row>
              <Col>
              <Input name="companyFilter" type="select" className="form-control" value={companyFilter} onChange={(e) => this.handleChangeCompanyFilter(e)}>
                <option value="all">All</option>
                {companyOwnersObj ? Object.keys(companyOwnersObj).map((key => (
                  <>
                    {companyOwnersObj[key].companyName ? (<option value={key}> {companyOwnersObj[key].companyName} </option>) : null }
                  </>
                ))) : (null) }
                </Input>
              </Col>
              <Col>
                <DateTimePicker
                  className="date-picker"
                  time={false}
                  culture="en"
                  format="MMM DD YYYY"
                  onChange={this.changeStartDate}
                  value={startDate}
                  defaultValue={new Date()}
                />
              </Col>
              <Col>
                <DateTimePicker
                  className="date-picker"
                  time={false}
                  culture="en"
                  format="MMM DD YYYY"
                  onChange={this.changeEndDate}
                  value={endDate}
                  defaultValue={new Date()}
                />
              </Col>
              <Col sm="6">
                <Button color="primary" onClick={this.fetchActions}> Filter </Button>
              </Col>
            </Row>
            <div class="table-responsive">
              <Table
                style={{ fontSize: 14 }}
                sortType={sortType}
                sortColumn={sortColumn}
                data={adminActions}
                minHeight={650}
                height={650}
                onSortColumn={this.sort}
                shouldUpdateScroll={false}
                rowKey='dbId'
                rowHeight={76}
              >
                {/* <Column width={260} sortable resizable>
                  <HeaderCell>Id</HeaderCell>
                  <Cell dataKey="dbId">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {rowData.dbId}
                        </div>
                      )
                    }}
                  </Cell>
                </Column> */}
                <Column width={140} sortable resizable>
                  <HeaderCell>Date</HeaderCell>
                  <Cell dataKey="time">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {(rowData.time) ? (
                            <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                              {rowData.time.seconds * 1000}
                            </Moment>
                          ) : (null)}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={180} sortable resizable>
                  <HeaderCell>Type</HeaderCell>
                  <Cell dataKey="id">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {`${actionTypes[rowData.id] ? actionTypes[rowData.id].name : rowData.id}`}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>

                <Column width={260} sortable resizable>
                  <HeaderCell>Admin</HeaderCell>
                  <Cell dataKey="uid">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {`${rowData.name}`}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={160} sortable resizable>
                  <HeaderCell>Company Id</HeaderCell>
                  <Cell dataKey="companyId">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {rowData.companyName ? rowData.companyName : (companyOwnersObj[rowData.companyId] ? companyOwnersObj[rowData.companyId].companyName : '-')}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={160} sortable resizable>
                  <HeaderCell>Campaign Id</HeaderCell>
                  <Cell dataKey="voucherId">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {voucherCampaigns[rowData.voucherId] ? voucherCampaigns[rowData.voucherId].name : (rowData.voucherId || '-')}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={260} sortable resizable>
                  <HeaderCell>Customer Id</HeaderCell>
                  <Cell dataKey="customerId">
                    {(rowData, rowIndex) => {
                      return (
                        <div>
                          {rowData.customerId || '-'}
                        </div>
                      )
                    }}
                  </Cell>
                </Column>
                <Column width={520} sortable resizable>
                  <HeaderCell>Data</HeaderCell>
                  <Cell dataKey="uid">
                    {(rowData, rowIndex) => {
                      return (
                        <>
                          {Object.keys(rowData).map((key) => {
                            if (key !== 'name' && key !== 'uid' && key !== 'id' && key !== 'time' && key !== 'dbId' && key !== 'companyId' && key !== 'customerId' && key !== 'voucherId' && key !== 'companyName' && (key !== 'changeId' && rowData.id !== 'approveVoucherChangeDelete') && (key !== 'changeId' && rowData.id !== 'denyVoucherChangeDelete')) {
                              if (rowData.id === 'changeCampaignStatus') {
                                return (
                                  <div>
                                    {`${key === 'oldValue' ? 'Old status' : 'New status'} - ${campaignStatuses[rowData[key]]}`}
                                  </div>
                                )
                              } else if (rowData.id === 'changeCustomerBuyingStatus' || rowData.id === 'changeCustomerStatus' || rowData.id === 'changeCustomerRedemptionsStatus') {
                                if (key === 'status') {
                                  return (
                                    <div>
                                      {`${key} - ${(rowData[key] === true) ? 'false' : 'true'}`}
                                    </div>
                                  );
                                } else {
                                  return null;
                                }
                              } else if (rowData.id === 'approveVoucherChange' || rowData.id === 'denyVoucherChange') {
                                console.log('testdata approveVoucherChange', rowData);

                                if (key === 'changeId') {
                                  return (
                                    <div>
                                      {`Change id - ${key}`}
                                    </div>
                                  );
                                } if (categories && Object.keys(categories).length && (rowData.changeId === 'category1' || rowData.changeId === 'category2')) {
                                  return (
                                    <div>
                                      {`${key === 'oldValue' ? `Old ${rowData.changeId}` : `New ${rowData.changeId}`} - ${categories[rowData[key]] ? categories[rowData[key]].name : rowData[key]}`}
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div>
                                      {`${key === 'oldValue' ? `Old ${rowData.changeId}` : `New ${rowData.changeId}`} - ${rowData[key]}`}
                                    </div>
                                  );
                                }

                              } else if (rowData.id === 'approveVoucherChanges' || rowData.id === 'denyVoucherChanges') {
                                if (key === 'companyData') {
                                  //  console.log('testdata companyData', rowData.companyData);
                                  return null;
                                } else if (key === 'voucherData') {
                                  // console.log('testdata voucherData', rowData.voucherData);
                                  return null;
                                } else if (key === 'companyPendingData') {
                                  console.log('testdata companyPendingData', rowData.companyPendingData);
                                  return (
                                    <>
                                      {rowData[key] ? Object.keys(rowData[key]).map(pendingChangeKey => {
                                        if (categories && Object.keys(categories).length && (pendingChangeKey === 'category1' || pendingChangeKey === 'category2')) {
                                          console.log('testdata 211', categories);
                                          console.log('testdata 222', rowData.companyData[pendingChangeKey]);
                                          console.log('testdata 233', rowData[key][pendingChangeKey]);

                                          return (
                                            <div>
                                              {`Old ${pendingChangeKey} - ${(rowData.companyData && categories[rowData.companyData[pendingChangeKey]]) ? categories[rowData.companyData[pendingChangeKey]].name : 'null'}, New ${pendingChangeKey} - ${categories[rowData[key][pendingChangeKey]] ? categories[rowData[key][pendingChangeKey]].name : 'null'}`}
                                            </div>
                                          )
                                        }
                                        return (
                                          <div>
                                            {`Old ${pendingChangeKey} - ${rowData.voucherData ? rowData.companyData[pendingChangeKey] : 'null'}, New ${pendingChangeKey} - ${rowData[key][pendingChangeKey]}`}
                                          </div>
                                        )
                                      }) : (null)}
                                    </>
                                  )
                                  return null;
                                } else if (key === 'vouchersPendingData') {
                                  console.log('testdata vouchersPendingData', rowData.vouchersPendingData);

                                  return (
                                    <>
                                      {rowData[key] ? Object.keys(rowData[key]).map(pendingChangeKey => {
                                        return (
                                          <div>
                                            {`Old ${pendingChangeKey} - ${rowData.voucherData ? rowData.voucherData[pendingChangeKey] : 'null'}, New ${pendingChangeKey} - ${rowData[key][pendingChangeKey]}`}
                                          </div>
                                        )
                                      }) : (null)}
                                    </>
                                  )
                                  return null;
                                } else {

                                }

                              } else if (rowData.id === 'campaignRequestAccepted') {
                                console.log('testdata campaignRequestAccepted', rowData);

                                return null;
                              }
                              return (<div> {`${key} - ${rowData[key]}`} </div>);
                            } else {
                              return null;
                            }
                          })}
                        </>
                      )
                      
                    }}
                  </Cell>
                </Column>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  voucherCampaigns: state.admin.voucherCampaignsObj || {},
  companyOwnersObj: state.admin.companyOwnersObj || {},
  adminActions: state.admin.adminActions || {},
  categories: state.company.categories || {},
});

const mapDispatchToProps = {
  getActions: getAdminActions,
  fetchDataFiltered: getDataFiltered,
  fetchCampaignsFiltered: getCampaignsFiltered,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminActions));
