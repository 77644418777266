import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Card, CardBody, Button, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState , convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';

import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { connect } from 'react-redux';

import {
  getContentManagementData,
  updateContentManagementData,
} from '../../../../actions/contentManagement';

import { getRewardsUnlocked } from '../../../../actions/company';

import PendingTab from './PendingTab';
import ContactPage from './ContactPage';
import APIPage from './APIPage';
import FooterTab from './FooterTab';
import BrandingTab from './BrandingTab';
import DashboardTooltips from './Tooltips/DashboardTooltips';
import NewVoucherTooltips from './Tooltips/NewVoucherTooltips';
import APITooltips from './Tooltips/APITooltips';
import PaymentTooltips from './Tooltips/PaymentTooltips';
import CompanyPopout from './Tooltips/CompanyPopout';
import CompanyTabs from './Tooltips/CompanyTabs';
import CompanyInfo from './Tooltips/CompanyInfo';
import VoucherCategoriesTab from './VoucherCategoriesTab';
import EventCategoriesTab from './EventCategoriesTab';
import SignpostsTab from './Signposts';

import SetupHelpTab from './SetupHelp/SetupHelp';
import FAQTab from './FAQTab';

class ContentManagement extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    fetchContentManagementData: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
    fetchRewardsUnlocked: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      activeTab: '1',
      editorState: EditorState.createEmpty(),
      showNoPermission: false,
    };
  }

  componentDidMount() {
    const { fetchContentManagementData, fetchRewardsUnlocked, admin, user, isLoggedIn, history } = this.props;

    if (user && Object.keys(user).length > 0) {
      if (!user || !isLoggedIn || !user || !user.roleClass || user.roleClass !== 'admin') {
        history.push('/');
      } else {
        fetchRewardsUnlocked();
        fetchContentManagementData().then((res) => {
          if (res.terms && res.terms.text) {
            const data = res.terms.text;

            if (data) {
            //  let contentState = stateFromHTML(data);

              const blocksFromHtml = htmlToDraft(data);
              const { contentBlocks, entityMap } = blocksFromHtml;
              const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

              if (contentState) {
                this.setState({ editorState: EditorState.createWithContent(contentState) });
              }
            } else {
              this.setState({ editorState: EditorState.createEmpty() });
            }
          } else {
            this.setState({ editorState: EditorState.createEmpty() });
          }
        });

        if (user.role === 'admin') {
          this.setState({ showNoPermission: false });
        } else {
          const { adminsRoles } = this.props;

          if (adminsRoles && adminsRoles[user.role] && adminsRoles[user.role].permissions && adminsRoles[user.role].permissions.contentManagement) {
            if (adminsRoles[user.role].permissions.contentManagement === 2) {
              this.setState({ showNoPermission: false, dontShowPage: false });
            } else {
              this.setState({ showNoPermission: true, dontShowPage: false });
            }
          } else {
            this.setState({ showNoPermission: true, dontShowPage: true });
          }
        }
      }
    } else {
      history.push('/');
    }
  }

  updatePageContent = () => {
    const { updatePageContent, id } = this.props;
    const { editorState } = this.state;

    if (editorState && id) {
      updatePageContent({ content: convertToRaw(editorState.getCurrentContent()), id });
    }
  }

  onChange = (editorState) => {
    this.setState({ editorState })
  };

  handleChange = (e) => {this.setState({ [e.target.name]: e.target.value })};

  save = () => {
    const { contentManagementData, updateData, user } = this.props;
    const { activeTab, editorState } = this.state;
      let type;
      let id;

      if (editorState) {
        if (activeTab === '1') {
          type = 'terms';
          if (contentManagementData.terms) {
            id = contentManagementData.terms.id;
          }
        } else if (activeTab === '2') {
          type = 'privacy';
          if (contentManagementData.privacy) {
            id = contentManagementData.privacy.id;
          }
        } else if (activeTab === '3') {
          type = 'QandA';
          if (contentManagementData.QandA) {
            id = contentManagementData.QandA.id;
          }
        } else if (activeTab === '4') {
          type = 'webTC';
          if (contentManagementData.webTC) {
            id = contentManagementData.webTC.id;
          }
        } else if (activeTab === '5') {
          type = 'webPrivacy';
          if (contentManagementData.webPrivacy) {
            id = contentManagementData.webPrivacy.id;
          }
        } else if (activeTab === '6') {
          type = 'webCookies';
          if (contentManagementData.webCookies) {
            id = contentManagementData.webCookies.id;
          }
        }

        let html = stateToHTML(editorState.getCurrentContent());

        const rawContentState = convertToRaw(editorState.getCurrentContent());

        const markup = draftToHtml(
          rawContentState
        );

        let adminName;

        if (user.name || (user.firstName && user.lastName)) {
          adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
        }

        updateData(type, markup, adminName).then(res => {
        }).catch(err => {
          console.log(err);
          this.setState({ error: err });
        });
      }
  }

  toggle = (tab) => {
    const { contentManagementData } = this.props;
    const { activeTab } = this.state;

    if (activeTab !== tab) {
      let data;

      if (tab === '1') {
        if (contentManagementData.terms) {
          data = contentManagementData.terms.text;
        } else {
          data = '';
        }
      } else if (tab === '2') {
        if (contentManagementData.privacy) {
          data = contentManagementData.privacy.text ;
        } else {
          data = '';
        }
      } else if (tab === '3') {
        if (contentManagementData.QandA) {
          data = contentManagementData.QandA.text;
        } else {
          data = '';
        }
      } else if (tab === '4') {
        if (contentManagementData.webTC) {
          data = contentManagementData.webTC.text;
        } else {
          data = '';
        }
      } else if (tab === '5') {
        if (contentManagementData.webPrivacy) {
          data = contentManagementData.webPrivacy.text;
        } else {
          data = '';
        }
      } else if (tab === '6') {
        if (contentManagementData.webCookies) {
          data = contentManagementData.webCookies.text;
        } else {
          data = '';
        }
      }

      if (data || data === '') {
        let contentState = stateFromHTML(data);

        if (contentState) {
          this.setState({ editorState: EditorState.createWithContent(contentState) });
        }
      }

      this.setState({
        activeTab: tab,
      });
    }
  }

  renderTab = (render) => {
    if (!render) {
      return null;
    }

    const { editorState, showNoPermission } = this.state;

    return (
      <Card>
        <CardBody>
          <FormGroup>
            <Editor
              className="content-management-text-area"
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={this.onChange}
            />
          </FormGroup>
          <Button disabled={showNoPermission} onClick={() => this.save()}>
            Save data
          </Button>
        </CardBody>
      </Card>
    )
  }

  render() {
    const { user, categories, rewardsUnlocked } = this.props;
    const {
      activeTab,
      showNoPermission,
      dontShowPage,
    } = this.state;

    if (!user.role || user.roleClass !== 'admin') {
      return (
        <div>
          <Card>
            <CardBody>
              You need admin permissions to view all roles
            </CardBody>
          </Card>
        </div>
      );
    }

    if (dontShowPage) {
      return (
        <div>
          <Card>
            <CardBody>
              You need admin permissions to view content Management
            </CardBody>
          </Card>
        </div>
      );
    }

    return (
      <div>
      <Nav tabs>
          <NavItem>
            <NavLink
              className={`${(activeTab === '1' || activeTab === '2' || activeTab === '3' || activeTab === '4' || activeTab === '5' || activeTab === '6') ? 'active' : null}`}
              onClick={() => { this.toggle('1'); }}
            >
            Legals
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={`${(activeTab === '7' || activeTab === '11' || activeTab === '17' || activeTab === '18' || activeTab === '19' || activeTab === '20' || activeTab === '21') ? 'active' : null}`}
              onClick={() => { this.toggle('7'); }}
            >
              Tooltips
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={`${activeTab === '8' ? 'active' : null}`}
              onClick={() => { this.toggle('8'); }}
            >
              Pending Page
            </NavLink>
          </NavItem>

          {/* <NavItem>
            <NavLink
              className={`${activeTab === '14' ? 'active' : null}`}
              onClick={() => { this.toggle('14'); }}
            >
              Contact Page
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink
              className={`${activeTab === '16' ? 'active' : null}`}
              onClick={() => { this.toggle('16'); }}
            >
              API Page
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={`${activeTab === '9' ? 'active' : null}`}
              onClick={() => { this.toggle('9'); }}
            >
              Footer
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={`${activeTab === '10' ? 'active' : null}`}
              onClick={() => { this.toggle('10'); }}
            >
              Branding
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={`${activeTab === '12' ? 'active' : null}`}
              onClick={() => { this.toggle('12'); }}
            >
              Voucher categories
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={`${activeTab === '13' ? 'active' : null}`}
              onClick={() => { this.toggle('13'); }}
            >
              Event categories
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink
              className={`${activeTab === '22' ? 'active' : null}`}
              onClick={() => { this.toggle('22'); }}
            >
              Setup Help
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${activeTab === '23' ? 'active' : null}`}
              onClick={() => { this.toggle('23'); }}
            >
              FAQ`s
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${activeTab === '24' ? 'active' : null}`}
              onClick={() => { this.toggle('24'); }}
            >
              Signposts
            </NavLink>
          </NavItem>
        </Nav>

        {(activeTab === '1' || activeTab === '2' || activeTab === '3' || activeTab === '4' || activeTab === '5' || activeTab === '6') ? (
          <Nav pills>
              <NavItem>
                <NavLink
                  className={`${activeTab === '1' ? 'active' : null}`}
                  onClick={() => { this.toggle('1'); }}
                >
                  App: T&Cs
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === '2' ? 'active' : null}`}
                  onClick={() => { this.toggle('2'); }}
                >
                  App: Privacy policy
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === '3' ? 'active' : null}`}
                  onClick={() => { this.toggle('3'); }}
                >
                  App: FAQs
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === '4' ? 'active' : null}`}
                  onClick={() => { this.toggle('4'); }}
                >
                  Web: T&Cs
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === '5' ? 'active' : null}`}
                  onClick={() => { this.toggle('5'); }}
                >
                  Web: Privacy policy
                </NavLink>
              </NavItem>
            </Nav>
        ):(null)}

        {(activeTab === '7' || activeTab === '11' || activeTab === '17' || activeTab === '18' || activeTab === '19' || activeTab === '20' || activeTab === '21') ? (
          <Nav pills>
            {/* <NavItem>
              <NavLink
                className={`${activeTab === '17' ? 'active' : null}`}
                onClick={() => { this.toggle('17'); }}
              >
                Company Tabs
              </NavLink>
            </NavItem> */}

            <NavItem>
              <NavLink
                className={`${activeTab === '7' ? 'active' : null}`}
                onClick={() => { this.toggle('7'); }}
              >
                Company Dashboard (CD)
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={`${activeTab === '19' ? 'active' : null}`}
                onClick={() => { this.toggle('19'); }}
              >
                New Voucher
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={`${activeTab === '11' ? 'active' : null}`}
                onClick={() => { this.toggle('11'); }}
              >
                CD popout
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={`${activeTab === '18' ? 'active' : null}`}
                onClick={() => { this.toggle('18'); }}
              >
                API
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${activeTab === '20' ? 'active' : null}`}
                onClick={() => { this.toggle('20'); }}
              >
                Company Info
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${activeTab === '21' ? 'active' : null}`}
                onClick={() => { this.toggle('21'); }}
              >
                Payments
              </NavLink>
            </NavItem>
          </Nav>
        ):(null)}

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {this.renderTab(activeTab === '1' ? true : false)}
          </TabPane>
          <TabPane tabId="2">
            {this.renderTab(activeTab === '2' ? true : false)}
          </TabPane>
          <TabPane tabId="3">
            {this.renderTab(activeTab === '3' ? true : false)}
          </TabPane>
          <TabPane tabId="4">
            {this.renderTab(activeTab === '4' ? true : false)}
          </TabPane>
          <TabPane tabId="5">
            {this.renderTab(activeTab === '5' ? true : false)}
          </TabPane>
        {/*  <TabPane tabId="6">
            {this.renderTab(activeTab === '6' ? true : false)}
          </TabPane>  */}

          <TabPane tabId="7">
            <DashboardTooltips showNoPermission={showNoPermission} />
          </TabPane>

          <TabPane tabId="19">
            <NewVoucherTooltips showNoPermission={showNoPermission} />
          </TabPane>

          <TabPane tabId="8">
            <PendingTab showNoPermission={showNoPermission} />
          </TabPane>

          <TabPane tabId="9">
            <FooterTab showNoPermission={showNoPermission} />
          </TabPane>

          <TabPane tabId="10">
            <BrandingTab showNoPermission={showNoPermission} />
          </TabPane>

          <TabPane tabId="11">
            <CompanyPopout showNoPermission={showNoPermission} />
          </TabPane>

          {categories ? (
            <TabPane tabId="12">
              <VoucherCategoriesTab showNoPermission={showNoPermission} categories={categories} rewardsUnlocked={rewardsUnlocked} />
            </TabPane>
          ):(null)}

          <TabPane tabId="13">
            <EventCategoriesTab showNoPermission={showNoPermission} />
          </TabPane>

          {/* <TabPane tabId="14">
            <ContactPage showNoPermission={showNoPermission} />
          </TabPane> */}

        {/*  <TabPane tabId="15">
            <NewVoucherPage showNoPermission={showNoPermission} />
          </TabPane>  */}

          <TabPane tabId="16">
            <APIPage showNoPermission={showNoPermission} />
          </TabPane>
          {/* <TabPane tabId="17">
            <CompanyTabs showNoPermission={showNoPermission} />
          </TabPane> */}

          <TabPane tabId="18">
            <APITooltips showNoPermission={showNoPermission} />
          </TabPane>
          <TabPane tabId="20">
            <CompanyInfo showNoPermission={showNoPermission} />
          </TabPane>
          <TabPane tabId="21">
            <PaymentTooltips showNoPermission={showNoPermission} />
          </TabPane>
          <TabPane tabId="22">
            <SetupHelpTab showNoPermission={showNoPermission} />
          </TabPane>
          <TabPane tabId="23">
            <FAQTab showNoPermission={showNoPermission} />
          </TabPane>
          <TabPane tabId="24">
            <SignpostsTab showNoPermission={showNoPermission} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  contentManagementData: state.contentManagement.contentManagementData || {},
  categories: state.company.categories || null,
  rewardsUnlocked: state.company.rewardsUnlocked || null,
  adminsRoles: state.admin.adminsRoles || {},
});

const mapDispatchToProps = {
  fetchContentManagementData: getContentManagementData,
  updateData: updateContentManagementData,
  fetchRewardsUnlocked: getRewardsUnlocked,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentManagement));

// export default Dashboard;
