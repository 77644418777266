import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Card, CardBody, Row, Col } from 'reactstrap';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState } from 'draft-js';
import 'draft-js/dist/Draft.css';

import {stateFromHTML} from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  getContactPageData,
} from '../../../actions/contentManagement';

class Contact extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    contactPage: PropTypes.shape(),
    fetchContactPageData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    contactPage: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    const { fetchContactPageData } = this.props;

    fetchContactPageData().then((res) => {
      if (res) {
      //  let contentState = stateFromHTML(res);

        const blocksFromHtml = htmlToDraft(res);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        if (contentState) {
          this.setState({ editorState: EditorState.createWithContent(contentState) });
        }
      }
    });
  }


  render() {
    const { editorState } = this.state;

    return (
      <Card>
        <CardBody>
          <div>
            <Row>
              <Col sm="12">
                <div >
                  <Editor
                    className="content-management-text-area"
                    editorState={editorState}
                    toolbarHidden
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    readOnly
                  />
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  contactPage: state.contentManagement.contact || '',
});

const mapDispatchToProps = {
  fetchContactPageData: getContactPageData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contact));
