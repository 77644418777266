import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Button, Input, Label, Form, FormGroup, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';

import { connect } from 'react-redux';

import {
  getFAQLinks,
  updateFAQLinks,
} from '../../../../actions/contentManagement';

import notifications from '../../../../const/notifications';

class EventCategoriesTab extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    categories: PropTypes.arrayOf(PropTypes.shape()),
    getFAQ: PropTypes.func.isRequired,
    updateFAQ: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    faqLinks: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      faqLinks: {},
      editData: null,
    };
  }

  componentDidMount() {
    const { getFAQ } = this.props;

    console.log('call getFAQ');

    getFAQ().then((res) => {
      if (res && res.faqLinks) {
        this.setState({ faqLinks: res.faqLinks });
      }
    });
  }

  handleChange = (e) => {
    e.preventDefault();

    if (e.target) {
      const { value } = e.target;
      this.setState((prevState) => ({
        editData: {
          ...prevState.editData,
          value: value,
        },
        error: null,
        success: null,
      }))
    }
  };

  openEditModal = (id) => {
    const { faqLinks } = this.props;

    this.setState({ editId: id, editModal: true, editData: faqLinks[id] });
  }

  closeEditModal = () => {
    this.setState({ editId: null, editModal: false, editData: null });
  }

  editFAQ = () => {
    const { faqLinks, updateFAQ, user } = this.props;
    const { editId, editData } = this.state;

    this.setState({ processing: true });

    updateFAQ(editId, editData).then((res) => {
      console.log('updateFAQ res', res);

      if (res === 'success') {
        this.setState({ processing: null, editId: null, editData: null, success: true, error: false, editModal: false });
      } else {
        this.setState({ processing: null, editId: null, editData: null, success: false, error: true, editModal: false });
      }
    }).catch(err => {
      console.log('updateFAQ err', err);

      if (err === 'no value') {
        this.setState({ processing: null, error: false, editId: null, editData: null, success: false, addError: notifications.admin.contentManagement.noValue, editModal: false });
      } else {
        this.setState({ processing: null, error: false, editId: null, editData: null, success: false, addError: err, editModal: false });
      }
    });
  }

  render() {
    const { showNoPermission, faqLinks } = this.props;
    const {
      error,
      addError,
      success,
      editModal,
      data,
      processing,
      editData,
    } = this.state;

    console.log('faqLinks', faqLinks, editData);

    return (
      <div>
        {(!!success) && <Alert color="success">{notifications.admin.contentManagement.updateSuccessful}</Alert>}
        {(!!error) && <Alert color="danger">{notifications.admin.contentManagement.updateFailed}</Alert>}

        <Row>
          <Col sm="3">
            Name
          </Col>
          <Col sm="6">
            Link
          </Col>
          <Col sm="3">
            Edit
          </Col>
        </Row>
        {faqLinks ? Object.keys(faqLinks).map((key, index) => (
          <Row>
            <Col sm="3">
              {faqLinks[key].name}
            </Col>
            <Col sm="6">
              {faqLinks[key].value}
            </Col>
            <Col sm="3">
              <Button disabled={showNoPermission} onClick={() => this.openEditModal(key)}>
                Edit
              </Button>
            </Col>
          </Row>
        )) : (null)}

        <Modal isOpen={editModal} toggle={this.closeEditModal}>
          <ModalHeader toggle={this.closeEditModal}>
            Edit Category
          </ModalHeader>
          <ModalBody>
            <Form>
              {!!addError ? (<Alert color="danger"> {addError} </Alert>) : (null)}
              <FormGroup>
                <Label>Value</Label>
                <Input
                  type="text"
                  value={editData ? editData.value : ''}
                  name="value"
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.editFAQ}>
              Edit
              {(processing) ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ) : (null)}
            </Button>
            <Button color="secondary" onClick={this.closeEditModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return ({
    user: state.user.userData || {},
    faqLinks: state.contentManagement.faqLinks || {},
  })
};

const mapDispatchToProps = {
  getFAQ: getFAQLinks,
  updateFAQ: updateFAQLinks,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventCategoriesTab));
