import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Card, CardBody, Table, Button, Input, Label, Form, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane, Alert } from 'reactstrap';

import { connect } from 'react-redux';

import {
//  getGreetingCards,
} from '../../../../actions/admin';

// import GreetingCardsInputs from './GreetingCardsInputs';
import GreetingCardsPrice from './GreetingCardsPrice';

class GreetingCards extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    updateData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      activeTab: '2',
      showNoPermission: false,
      dontShowPage: false,
    };
  }

  componentDidMount() {
    const { admin, user, isLoggedIn, history } = this.props;

    if (user && Object.keys(user).length > 0) {
      if (!user || !isLoggedIn || !user || !user.roleClass || user.roleClass !== 'admin') {
        history.push('/');
      } else {
      //  fetchCardQuote();

        if (user.role === 'admin') {
          this.setState({ showNoPermission: false, dontShowPage: false });
        } else {
          const { adminsRoles } = this.props;

          console.log('adminsRoles', { adminsRoles });

          if (adminsRoles && adminsRoles[user.role] && adminsRoles[user.role].permissions && adminsRoles[user.role].permissions.greetingsCards) {
            if (adminsRoles[user.role].permissions.greetingsCards === 2) {
              this.setState({ showNoPermission: false, dontShowPage: false });
            } else {
              this.setState({ showNoPermission: true, dontShowPage: false });
            }
          //  this.setState({ showNoPermission: false });
          } else {
            this.setState({ showNoPermission: true, dontShowPage: true });
          }
        }
      }
    } else {
      history.push('/');
    }
  }

  toggle = (tab) => {
    const { activeTab } = this.state;

    if (activeTab !== tab) {

      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { user, cardTemplates, cardCategories } = this.props;
    const {
      name,
      activeTab,
      showNoPermission,
      dontShowPage,
    } = this.state;

    if (!user.role || user.roleClass !== 'admin') {
      return (
        <div>
          <Card>
            <CardBody>
              You need admin permissions to view greetings cards
            </CardBody>
          </Card>
        </div>
      );
    }

    if (dontShowPage) {
      return (
        <div>
          <Card>
            <CardBody>
              You need admin permissions to view greetings cards
            </CardBody>
          </Card>
        </div>
      );
    }

    return (
      <div>
      <Nav tabs>
        {/*  <NavItem>
            <NavLink
              className={`${activeTab === '1' ? 'active' : null}`}
              onClick={() => { this.toggle('1'); }}
            >
              Greeting Cards Inputs
            </NavLink>
          </NavItem>  */}
          <NavItem>
            <NavLink
              className={`${activeTab === '2' ? 'active' : null}`}
              onClick={() => { this.toggle('2'); }}
            >
              Greeting Cards Price
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
        {/*  <TabPane tabId="1">
            <GreetingCardsInputs />
          </TabPane> */}
          <TabPane tabId="2">
            <GreetingCardsPrice showNoPermission={showNoPermission} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  cardTemplates: state.admin.cardTemplates || {},
  cardCategories: state.admin.cardCategories || {},
  adminsRoles: state.admin.adminsRoles || {},
});

const mapDispatchToProps = {
//  fetchGreetingCards: getGreetingCards,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GreetingCards));

// export default Dashboard;
