import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Col, Row, Card, CardBody, Button, Spinner, Modal, ModalBody, ModalHeader, ModalFooter, Alert, Input, Form, FormGroup, Label } from 'reactstrap';

import { connect } from 'react-redux';
import Moment from 'react-moment';

import ReactTooltip from 'react-tooltip';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';

import { Table, Column, HeaderCell, Cell, ColumnGroup } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css'; // or 'rsuite-table/dist/css/rsuite-table.css'

import AutoRefresh from '../../components/AutoRefresh/AutoRefresh';

// import formattedNumber from '../../components/FormattedNumber/FormattedNumber';

import {
  createStripeAccount,
  getStripeLoginLink,
  checkStripeCompanyStatus,
  reAuthenticateUser,
  completePhoneVerification,
} from '../../../actions/user';

import {
  getCompanyPayments,
  getMoreCompanyPayments,
  sortCompanyPayments,
  getSingleCompanyPayment,
  updateLastActive,
  manuallyRedeemPayment,
} from '../../../actions/company';

import {
  refundStripePayment,
  cancelVoucher,
  resendGiftingEmail,
  openVoucherCodePreview,
} from '../../../actions/admin';

import {
  getPaymentTooltips,
} from '../../../actions/contentManagement';

const TableHeight = 500;

class Dashboard extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    fetchStripeLoginLink: PropTypes.func.isRequired,
    getStripeCompanyStatus: PropTypes.func.isRequired,
    addStripeAccount: PropTypes.func.isRequired,
    getSinglePayment: PropTypes.func.isRequired,
    fetchPaymentTooltips: PropTypes.func.isRequired,
    resendEmail: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
  };

  constructor(props) {
    super(props);

    const startDate = new Date();
    startDate.setDate(startDate.getDate()-30);

    const endDate = new Date();
    endDate.setDate(endDate.getDate()+1);

    this.state = {
      error: null,
      loading: false,
      showNoPermission: false,
      dontShowPage: false,
      startDate: startDate,
      endDate: endDate,
      sortColumn: 'paymentDate',
      sortType: 'desc',
      codePreviews: {},
      phoneCode: [],
    };

    this.digit1 = React.createRef();
    this.digit2 = React.createRef();
    this.digit3 = React.createRef();
    this.digit4 = React.createRef();
    this.digit5 = React.createRef();
    this.digit6 = React.createRef();
  }

  handleCodeChange2 = (e) => {
    const { phoneCode } = this.state;

    if (e.target) {
      const { value, name } = e.target;

      let newVal;

      console.log('value', name, value);

      if (value.length) {
        newVal = value[value.length - 1];
      } else {
        newVal = null;
      }

      console.log('newVal', newVal);

      if (!newVal || !isNaN(newVal)) {
        if (newVal || newVal === 0) {
          phoneCode[name] = newVal.toString();
        } else {
          phoneCode[name] = null;
        }

        if (newVal || newVal === 0) {
          if (name === 0 || name === '0') {
            if (this.digit2) {
              this.digit2.current.focus();
            }
          } else if (name === 1 || name === '1') {
            if (this.digit3) {
              this.digit3.current.focus();
            }
          } else if (name === 2 || name === '2') {
            if (this.digit4) {
              this.digit4.current.focus();
            }
          } else if (name === 3 || name === '3') {
            if (this.digit5) {
              this.digit5.current.focus();
            }
          } else if (name === 4 || name === '4') {
            if (this.digit6) {
              this.digit6.current.focus();
            }
          }
        } else {
          if (name === 1 || name === '1') {
            if (this.digit1) {
              if (value || value === 0 || value === '0') {
                this.digit2.current.focus();
              } else {
                this.digit1.current.focus();
              }
            }
          } else if (name === 2 || name === '2') {
            if (this.digit2) {
              if (value || value === 0 || value === '0') {
                this.digit3.current.focus();
              } else {
                this.digit2.current.focus();
              }
            }
          } else if (name === 3 || name === '3') {
            if (this.digit3) {
              if (value || value === 0 || value === '0') {
                this.digit4.current.focus();
              } else {
                this.digit3.current.focus();
              }
            }
          } else if (name === 4 || name === '4') {
            if (this.digit4) {
              if (value || value === 0 || value === '0') {
                this.digit5.current.focus();
              } else {
                this.digit4.current.focus();
              }
            }
          } else if (name === 5 || name === '5') {
            if (this.digit5) {
              if (value || value === 0 || value === '0') {
                this.digit6.current.focus();
              } else {
                this.digit5.current.focus();
              }
            }
          }
        }

        this.setState({
          phoneCode,
        });
      }
    }
  }

  componentDidMount() {
    const { user, history, getStripeCompanyStatus, getPayments, adminViewAsCompany, updateLastActiveFunc, fetchPaymentTooltips } = this.props;
    fetchPaymentTooltips();

    if (user && Object.keys(user).length > 0) {
      if (user.company) {
        updateLastActiveFunc(user.company);

        if (user.role === 'teamOwner' || user.role === 'linkedCompanyUser') {
          getStripeCompanyStatus(user.company).then(() => {
            console.log('getStripeCompanyStatus then');
            this.setState({ stripeStatusFetched: true });
          });
          getPayments(user.company, '4weeks', null, null, 'paymentDate', 'desc');

          this.setState({ showNoPermission: false, dontShowPage: false });
        } else {
          const { roles } = this.props;

          if (roles && roles[user.role] && roles[user.role].permissions && roles[user.role].permissions.payments) {
            getStripeCompanyStatus(user.company).then(() => {
              this.setState({ stripeStatusFetched: true });
            });
            getPayments(user.company, '4weeks', null, null, 'paymentDate', 'desc');

            if (roles[user.role].permissions.payments === 2) {
              this.setState({ showNoPermission: false, dontShowPage: false });
            } else {
              this.setState({ showNoPermission: true, dontShowPage: false });
            }
          } else {
            this.setState({ showNoPermission: true, dontShowPage: true });
          }
        }
      } else if (adminViewAsCompany) {
        getStripeCompanyStatus(adminViewAsCompany);

        getPayments(adminViewAsCompany, '4weeks', null, null, 'paymentDate', 'desc');
      }
    } else {
      history.push('/');
    }
  }

  refresh = () => {
    const { user, getStripeCompanyStatus } = this.props;

    console.log('refresh getStripeCompanyStatus');

    if (user && user.company) {
      getStripeCompanyStatus(user.company);
    }
  }

  addStripeAccount = () => {
    const { user, addStripeAccount } = this.props;

    this.setState({ connectStripeProcessing: true, setupActive: true });

    addStripeAccount(user.company).then((res) => {
      if (res && res.data && res.data.accountLinks) {
        this.setState({ connectStripeProcessing: false });

        const newWindow = window.open(res.data.accountLinks.url, '_blank');

        console.log('newWindow', newWindow);

        setTimeout(() => {
          this.setState({ setupActive: false });
        }, 60000);

        if(!newWindow || newWindow.closed || typeof newWindow.closed=='undefined') {
          //POPUP BLOCKED
          this.setState({ popupBlocked: true });
        } else {
          this.setState({ popupBlocked: false });
        }

        if (newWindow) newWindow.opener = null;
      }
    }).catch(err => {
      this.setState({ connectStripeProcessing: false, popupBlocked: false, setupActive: false });
    });
  }

  sort2 = (sortColumn, sortType) => {
    this.setState({ sortType, sortColumn });

    this.fetchPayments();
  }

  sort = (sortColumn, sortType) => {
    const { user, getPayments, adminViewAsCompany } = this.props;
    const { startDate, endDate } = this.state;

    this.setState({ sortType, sortColumn });

    if (startDate && endDate) {
      if (adminViewAsCompany) {
        getPayments(adminViewAsCompany, 'custom', startDate, endDate, sortColumn, sortType);
      } else {
        getPayments(user.company, 'custom', startDate, endDate, sortColumn, sortType);
      }

    }
  }


  changeStartDate = (date) => {
    this.setState({ startDate: date });
  }

  changeEndDate = (date) => {
    this.setState({ endDate: date });
  }

  fetchPaymentsRange = () => {
    const { user, getPayments, adminViewAsCompany } = this.props;
    const { startDate, endDate } = this.state;

    if (startDate && endDate) {
      this.setState({ sortColumn: 'paymentDate', sortType: 'desc' });

      if (adminViewAsCompany) {
        getPayments(adminViewAsCompany, 'custom', startDate, endDate, 'paymentDate', 'desc');
      } else {
        getPayments(user.company, 'custom', startDate, endDate, 'paymentDate', 'desc');
      }

    }
  }

  fetchPayments = () => {
    const { user, getPayments, adminViewAsCompany } = this.props;
    const { startDate, endDate, sortColumn, sortType } = this.state;

    console.log('fetchPayments', user.company, 'custom', startDate, endDate, sortColumn, sortType);

    if (startDate && endDate) {
      if (adminViewAsCompany) {
        getPayments(adminViewAsCompany, 'custom', startDate, endDate, sortColumn, sortType);
      } else {
        getPayments(user.company, 'custom', startDate, endDate, sortColumn, sortType);
      }

    }
  }

  getMorePayments = () => {
    const { user, getMorePayments, adminViewAsCompany } = this.props;
    const { startDate, endDate, sortColumn, sortType, fetchingPayments } = this.state;

    if (!fetchingPayments) {
    this.setState({ fetchingPayments: true });

      if (startDate && endDate) {
        if (adminViewAsCompany) {
          getMorePayments(adminViewAsCompany, 'custom', startDate, endDate, sortColumn, sortType).then(() => {
            this.setState({ fetchingPayments: false });
          });
        } else {
          getMorePayments(user.company, 'custom', startDate, endDate, sortColumn, sortType).then(() => {
            this.setState({ fetchingPayments: false });
          });
        }
      }
    }
  }

  onScroll = (x, y) => {
    const { payments } = this.props;
    let contextHeight = payments.length * 46;
    let top = Math.abs(y);
    if (contextHeight - top - TableHeight < 0) {

      if (this.state.scrollTimeout) {
        clearTimeout(this.state.scrollTimeout);
      }

      this.setState({
        scrollTimeout: setTimeout(() => {
          this.getMorePayments();
        }, 700)
      });
    }
  };

  openConfirmRedeemModal = (chargeId, dbId) => {
    const { refundPayment, getSinglePayment, adminViewAsCompany, user } = this.props;

    console.log('openConfirmRedeemModal', chargeId, dbId);
    this.setState({
      confirmRedeemModalOpen: true,
      confirmRedeemModalChargeId: chargeId,
      confirmRedeemModalDbId: dbId,
      confirmRedeemModalError: null,
      redeemModalProcessing: null,
    });
  }

  closeConfirmRedeemModal = () => {
    console.log('closeConfirmRedeemModal');
    this.setState({
      confirmRedeemModalOpen: false,
      confirmRedeemModalChargeId: null,
      confirmRedeemModalDbId: null,
      confirmRedeemModalError: null,
      redeemModalProcessing: null,
    });
  }

  finishConfirmRedeemModal = () => {
    console.log('finishConfirmRedeemModal');
    const { manuallyRedeemPaymentFunction, getSinglePayment, adminViewAsCompany, user } = this.props;
    const { confirmRedeemModalChargeId, confirmRedeemModalDbId } = this.state;

    this.setState({ redeemModalProcessing: true });

    console.log('chargeId', confirmRedeemModalChargeId, confirmRedeemModalDbId);

    let companyId;

    this.setState({ redeemProcessing: confirmRedeemModalChargeId });

    if (adminViewAsCompany) {
      companyId = adminViewAsCompany;
    } else {
      companyId = user.company;
    }

    let redeemedBy;

    if (user.linkedApiName) {
      redeemedBy = user.linkedApiName;
    } else if (user.linkedApi) {
      redeemedBy = user.linkedApi;
    } else if (!adminViewAsCompany) {
      redeemedBy = user.companyName;
    }

    manuallyRedeemPaymentFunction(confirmRedeemModalChargeId, confirmRedeemModalDbId, redeemedBy).then(() => {
      console.log('manuallyRedeemPaymentFunction then');

      this.setState({ redeemProcessing: null, redeemModalProcessing: null });

      //  this.refresh();
      //  this.fetchPayments();

      setTimeout(() => {
        console.log('refresh');
        this.refresh();
        this.fetchPayments();
      }, 4300);

      setTimeout(() => {
        console.log('refresh2');
      //  this.refresh();
        this.fetchPayments();
      }, 9500);

      // getSinglePayment(companyId, confirmRedeemModalDbId);
      this.closeConfirmRedeemModal();
    }).catch(err => {
      this.setState({ redeemProcessing: null, confirmRedeemModalError: err, redeemModalProcessing: null });
    });

    /*
    this.setState({
      confirmRedeemModalOpen: false,
      confirmRedeemModalChargeId: null,
      confirmRedeemModalDbId: null,
      confirmRedeemModalError: null,
      redeemModalProcessing: null,
      redeemProcessing: null,
    }); */
  }

  openConfirmModal = (type, chargeId, dbId) => {
    console.log('openConfirmModal', type, chargeId, dbId);
    this.setState({
      confirmModalOpen: true,
      confirmModalType: type,
      confirmModalChargeId: chargeId,
      confirmModalDbId: dbId,
      onfirmModalError: null,
      confirmModalProcessing: null,
    });
  }

  closeConfirmModal = () => {
    console.log('closeConfirmModal');
    this.setState({
      confirmModalOpen: false,
      confirmModalType: null,
      confirmModalChargeId: null,
      confirmModalDbId: null,
      confirmModalError: null,
      confirmModalProcessing: null,
    });
  }

  finishConfirmModal = () => {
    const { confirmModalType, confirmModalChargeId, confirmModalDbId } = this.state;

    this.setState({ confirmModalProcessing: true });

    if (confirmModalType === 'refundVoucher') {
      this.refundPayment(confirmModalChargeId, confirmModalDbId, 'refundVoucher');
    } else if (confirmModalType === 'refundAll') {
      this.refundPayment(confirmModalChargeId, confirmModalDbId, 'refundAll');
    } else if (confirmModalType === 'refundGifting') {
      this.refundPayment(confirmModalChargeId, confirmModalDbId, 'refundGifting');
    } else if (confirmModalType === 'cancel') {
      this.cancelPayment(confirmModalChargeId, confirmModalDbId);
    } else {
      this.closeConfirmModal();
    }
  }

  refundPayment = (chargeId, dbId, refundType) => {
    const { refundPayment, getSinglePayment, adminViewAsCompany, user } = this.props;

    console.log('refundType', chargeId, dbId, refundType);

    let companyId;

    this.setState({ refundProcessing: chargeId });

    if (adminViewAsCompany) {
       companyId = adminViewAsCompany;
    } else {
      companyId = user.company;
    }

    refundPayment(chargeId, dbId, refundType).then(() => {
      this.setState({ refundProcessing: null });
      getSinglePayment(companyId, dbId);
      this.closeConfirmModal();
    }).catch(err => {
      this.setState({ refundProcessing: null, confirmModalError: err, confirmModalProcessing: null, });
    });
  }

  cancelPayment = (chargeId, dbId) => {
    const { cancelPayment, getSinglePayment, adminViewAsCompany, user } = this.props;

    this.setState({ cancelProcessing: chargeId });

    let companyId;

    if (adminViewAsCompany) {
       companyId = adminViewAsCompany;
    } else {
      companyId = user.company;
    }

    cancelPayment(chargeId, dbId).then((res) => {
      console.log('cancelPayment', res);
      this.setState({ cancelProcessing: null });
      getSinglePayment(companyId, dbId);
      this.closeConfirmModal();
    }).catch(err => {
      console.log('cancelPayment err', err);
      this.setState({ cancelProcessing: null, confirmModalError: err, confirmModalProcessing: null, });
    })
  }

  /*
  refundCancelPayment = (chargeId, dbId, refunded, canceled) => {
    const { refundPayment, cancelPayment, getSinglePayment, user, adminViewAsCompany } = this.props;

    this.setState({ refundCancelProcessing: chargeId });

    let companyId;

    if (adminViewAsCompany) {
      companyId = adminViewAsCompany;
    } else {
      companyId = user.company;
    }

    let promises = [];

    if (!refunded) {
      promises.push(refundPayment(chargeId, dbId));
    }
    if (!canceled) {
      promises.push(cancelPayment(chargeId, dbId));
    }

    Promise.all(promises).then(() => {
      this.setState({ refundCancelProcessing: null });
      getSinglePayment(companyId, dbId);
      this.closeConfirmModal();
    }).catch(err => {
      this.setState({ refundCancelProcessing: null, confirmModalError: err, confirmModalProcessing: null, });
      getSinglePayment(companyId, dbId);
    })
  } */

  openResendEmail = (chargeId, dbId) => {
    console.log('openResendEmail');
    this.setState({ resendEmailOpen: true, resendEmailChargeId: chargeId, resendEmailDbId: dbId, resendEmailProcessing: null, resendEmailError: null });
  }

  closeResendEmail = () => {
    console.log('closeResendEmail');
    this.setState({ resendEmailOpen: null, resendEmailChargeId: null, resendEmailDbId: null, resendEmailProcessing: null, resendEmailError: null });
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  resendEmail = () => {
    console.log('resendEmail');
    const { resendEmail } = this.props;
    const { resendEmailChargeId, resendEmailDbId, resendEmailValue } = this.state;

    this.setState({ resendEmailProcessing: true });

    if (resendEmailValue) {
      resendEmail(resendEmailChargeId, resendEmailDbId, resendEmailValue).then(() => {
        this.closeResendEmail();
      }).catch(err => {
        this.setState({ resendEmailError: err, resendEmailProcessing: null });
        // this.closeResendEmail();
      });
    } else {
      this.setState({ resendEmailError: 'Enter email address' });
    }
  }

  openCodePreview = (dbId) => {
    const { openCodePreview, loginTime } = this.props;

    console.log('openCodePreview', loginTime);

    if (loginTime && (loginTime + (1000 * 60 * 60 * 5) > Date.now())) {
      openCodePreview(dbId).then((res) => {
        this.setState((prevState) => ({ codePreviews: { ...prevState.codePreviews, [dbId]: res.data } }));
      });
      console.log('openCodePreview', dbId);
    } else {
      this.setState({ openReAuthModal: true, reAuthPassword: null, reAuthSpinner: null, reAuthPassword: null });
    }
  }

  reAuthenticate = () => {
    const { reAuthenticate } = this.props;
    const { reAuthPassword } = this.state;

    if (reAuthPassword) {
      this.setState({ reAuthSpinner: true });

      reAuthenticate(reAuthPassword).then((res) => {
        console.log('reAuthenticate res', res);

        if (res.verificationId) {
          this.setState({ loading: false, errorGreen: false, show2faModal: true, verificationId: res.verificationId, verificationType: res.verificationType, resolver: res.resolver });
        }

        this.setState({ openReAuthModal: null, reAuthPassword: null, reAuthSpinner: null, reAuthPassword: null });
      }).catch(err => {

        this.setState({ reAuthError: true });
      });
    }
  }

  closeReAuthModal = () => {
    this.setState({ openReAuthModal: null, reAuthPassword: null, reAuthSpinner: null, reAuthPassword: null });
  }

  submitVerificationCode = () => {
    console.log('submitVerificationCode');
    const { completeVerification } = this.props;
    const { verificationId, verificationCode, resolver, phoneCode } = this.state;

    this.setState({ processingSend2fa: true });

    console.log('submitVerificationCode verificationCode', verificationCode, phoneCode);

    if (phoneCode && phoneCode.length === 6) {
      if (phoneCode.includes(null) || phoneCode.includes(undefined)) {
        console.log('invalid values');
      } else {
        console.log('submitVerificationCode phoneCode', phoneCode);

        const phoneCodeString = phoneCode.join('');

        console.log('phoneCodeString phoneCode', phoneCodeString);

        /* if (verificationType === 'enrollment') {
          completeEnrollment({ verificationId, verificationCode: phoneCodeString }).then((res) => {
            console.log('completeEnrollment', res);
            this.setState({ processingSend2fa: null });
          });
        } else { */
          completeVerification({ verificationId, verificationCode: phoneCodeString, resolver }).then((res) => {
            console.log('completeVerification', res);
            this.setState({ processingSend2fa: null, openReAuthModal: null, show2faModal: null, reAuthPassword: null, reAuthError: null });
          });
      //  }
      }
    }

    if (verificationCode && verificationCode.length === 6) {
    } else {
      this.setState({ processingSend2fa: null });
    }
  }

  close2faModal = () => {
    this.setState({ show2faModal: false, verificationId: null, verificationType: null, resolver: null });
  }

  render() {
    const {
      company,
      companyOwnerData,
      stripeAccountStatus,
      stripeChargesEnabled,
      stripePayoutsEnabled,
      adminViewAsCompany,
      payments,
      refundPayment,
      cancelPayment,
      paymentTooltips,
      user,
    } = this.props;
    const {
      showNoPermission,
      dontShowPage,
      sortType,
      sortColumn,
      startDate,
      endDate,
      cancelProcessing,
      refundProcessing,
      refundCancelProcessing,
      connectStripeProcessing,
      popupBlocked,
      setupActive,
      stripeStatusFetched,

      confirmModalError,
      confirmModalOpen,
      confirmModalProcessing,
      resendEmailProcessing,
      resendEmailValue,
      resendEmailOpen,
      resendEmailError,

      confirmRedeemModalOpen,
      confirmRedeemModalError,
      redeemProcessing,
      redeemModalProcessing,
      codePreviews,
      openReAuthModal,
      reAuthPassword,
      reAuthError,
      reAuthSpinner,
      show2faModal,
      phoneCode,
      processingSend2fa,
    } = this.state;

    console.log('resendEmailOpen', resendEmailOpen);

    if (dontShowPage) {
      return (
        <div>
          <Card>
            <CardBody>
              You don't have permissions to view payments
            </CardBody>
          </Card>
        </div>
      );
    }

    console.log('payments payments', payments, popupBlocked, stripeStatusFetched, setupActive);

    console.log('companyOwnerData', companyOwnerData, stripeAccountStatus);

    if (adminViewAsCompany) {
      return (
        <div>
          <Card>
            <CardBody>
              <Row>
                <Col xs="10" />
                <Col xs="2">
                  <AutoRefresh refreshFunction={this.refresh} refreshTime={60} />
                </Col>
              </Row>
              <Row style={{ height: 55 }}>
                <Col xs="3" md="3">
                  <DateTimePicker
                    className="date-picker"
                    time={false}
                    culture="en"
                    format="MMM DD YYYY"
                    onChange={this.changeStartDate}
                    value={startDate}
                    defaultValue={new Date()}
                  />
                </Col>
                <Col xs="3" md="3">
                  <DateTimePicker
                    className="date-picker"
                    time={false}
                    culture="en"
                    format="MMM DD YYYY"
                    onChange={this.changeEndDate}
                    value={endDate}
                    defaultValue={new Date()}
                  />
                </Col>
                <Col xs="6" md="6">
                  <Button color="primary" onClick={this.fetchPaymentsRange}> Filter </Button>

                  {(companyOwnerData && companyOwnerData.companyStripeId && stripeAccountStatus) ? (
                    <>
                      {stripeAccountStatus === 'verified' ? (
                        <a href={'https://dashboard.stripe.com/'} style={{ float: 'right' }} target="_blank" rel="noopener noreferrer">
                          <Button color="link"> View payments </Button>
                        </a>
                      ):(
                        <a href={'https://dashboard.stripe.com/'} style={{ float: 'right' }} target="_blank" rel="noopener noreferrer">
                          <Button color="link"> {`View payments ${!stripeChargesEnabled ? '(Payments Disabled)' : ''} ${!stripePayoutsEnabled ? '(Payouts Disabled)' : ''}`} </Button>
                        </a>
                      )}
                    </>
                  ):(
                    <Button color="link" style={{ float: 'right' }} disabled> Connect Stripe Account. </Button>
                  )}
                </Col>
              </Row>

              <Table
                style={{ fontSize: 14 }}
                sortType={sortType}
                sortColumn={sortColumn}
                data={payments || []}
                virtualized
                minHeight={TableHeight}
                height={TableHeight}
                headerHeight={64}
                shouldUpdateScroll={false}
                onSortColumn={this.sort}
                onScroll={this.onScroll}
              >
                <Column width={130} sortable resizable>
                  <HeaderCell>Order ID</HeaderCell>
                  <Cell dataKey="chargeId" />
                </Column>

                <Column width={170} sortable resizable>
                  <HeaderCell>Date</HeaderCell>
                  <Cell dataKey="paymentDate">
                  {(rowData, rowIndex) => {
                    return (
                      <div>
                        {rowData.paymentDate ? (
                          <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                            {rowData.paymentDate.seconds * 1000}
                          </Moment>
                        ):(null)}
                      </div>
                    )
                  }}
                  </Cell>
                </Column>

                {/*
                  {(rowData, rowIndex) => {
                    if (rowData.used) {
                      return <div> Redeemed </div>
                    } else {
                      if (rowData.refunded) {
                        if (rowData.canceled) {
                          return <div> Refunded, Cancelled </div>
                        } else {
                          return <div> Refunded </div>
                        }
                      } else {
                        if (rowData.canceled) {
                          return <div> Cancelled </div>
                        } else {
                          return <div> Unused </div>
                        }
                      }
                    }
                  }}
                  */}

                <Column width={260} sortable resizable>
                  <HeaderCell>Status</HeaderCell>
                  <Cell dataKey="used">
                    {(rowData, rowIndex) => {
                      if (rowData.canceled) {
                        if (rowData.refunded) {
                          return (
                            <div>
                              <span>Cancelled (refunded)</span>
                              {rowData.canceledDate ? (
                                <span>
                                  {` - `}
                                  <Moment format="DD/MM/YYYY" locale="=uk">
                                    {rowData.canceledDate.seconds ? (rowData.canceledDate.seconds * 1000) : rowData.canceledDate}
                                  </Moment>
                                </span>
                              ) : (null)}
                            </div>
                          )
                        } else {
                          return (
                            <div>
                              <span>Cancelled</span>
                              {rowData.canceledDate ? (
                                <span>
                                  {` - `}
                                  <Moment format="DD/MM/YYYY" locale="=uk">
                                    {rowData.canceledDate.seconds ? (rowData.canceledDate.seconds * 1000) : rowData.canceledDate}
                                  </Moment>
                                </span>
                              ) : (null)}
                            </div>
                          )
                        }
                      } else if (rowData.used) {
                        if (rowData.refunded) {
                          return (
                            <div>
                              <span>Redeemed (refunded) {`${rowData.redeemedBy ? `(${rowData.redeemedBy})` : ''}`}</span>
                              {rowData.usedDate ? (
                                <span>
                                  {` - `}
                                  <Moment format="DD/MM/YYYY" locale="=uk">
                                    {rowData.usedDate.seconds ? (rowData.usedDate.seconds * 1000) : rowData.usedDate}
                                  </Moment>
                                </span>
                              ) : (null)}
                            </div>
                          )
                        } else {
                          return (
                            <div>
                              <span>Redeemed {`${rowData.redeemedBy ? `(${rowData.redeemedBy})` : ''}`}</span>
                              {rowData.usedDate ? (
                                <span>
                                  {` - `}
                                  <Moment format="DD/MM/YYYY" locale="=uk">
                                    {rowData.usedDate.seconds ? (rowData.usedDate.seconds * 1000) : rowData.usedDate}
                                  </Moment>
                                </span>
                              ) : (null)}
                            </div>
                          )
                        }
                      } else {
                        if (rowData.refunded) {
                          if (rowData.canceled) {
                            return (
                              <div>
                                <span>Cancelled (refunded)</span>
                                {rowData.canceledDate ? (
                                  <span>
                                    {` - `}
                                    <Moment format="DD/MM/YYYY" locale="=uk">
                                      {rowData.canceledDate}
                                    </Moment>
                                  </span>
                                ) : (null)}
                              </div>
                            )
                          } else {
                            return <div> Unused (refunded) </div>
                          }
                        } else {
                          if (rowData.canceled) {
                            return (
                              <div>
                                <span>Cancelled</span>
                                {rowData.canceledDate ? (
                                  <span>
                                    {` - `}
                                    <Moment format="DD/MM/YYYY" locale="=uk">
                                      {rowData.canceledDate}
                                    </Moment>
                                  </span>
                                ) : (null)}
                              </div>
                            )
                          } else {
                            return <div> Unused </div>
                          }
                        }
                      }
                    }}
                  </Cell>
                </Column>

                <Column width={200} sortable resizable>
                  <HeaderCell>Customer Name</HeaderCell>
                  <Cell dataKey="customerName" />
                </Column>
                <Column width={200} sortable resizable>
                  <HeaderCell>Customer Email</HeaderCell>
                  <Cell dataKey="customerEmail" />
                </Column>
                <Column width={200} sortable resizable>
                  <HeaderCell>Voucher code</HeaderCell>
                  <Cell dataKey="voucherCodePreview">
                    {(rowData, rowIndex) => {
                      return <div onClick={() => { this.openCodePreview(rowData.dbId) }}> {(codePreviews && codePreviews[rowData.dbId]) ? codePreviews[rowData.dbId] : (rowData.voucherCodePreview ? `${rowData.voucherCodePreview}` : '-')} </div>
                    }}
                  </Cell>
                </Column>
                {/*  <Column width={200} sortable resizable>
                  <HeaderCell>Company</HeaderCell>
                  <Cell dataKey="companyName" />
                </Column> */}
                <Column width={200} sortable resizable>
                  <HeaderCell>Campaign</HeaderCell>
                  <Cell dataKey="voucherName" />
                </Column>
              {/*  <Column width={100} resizable>
                  <HeaderCell>POD order number</HeaderCell>
                  <Cell>
                    <div>  </div>
                  </Cell>
                </Column>  */}

                <Column width={150} sortable resizable>
                  <HeaderCell>Face Value (£)</HeaderCell>
                  <Cell dataKey="faceValue">
                    {(rowData, rowIndex) => {
                      if (rowData.faceValue) {
                        return <div> {rowData.faceValue ? (rowData.faceValue).toFixed(2) : '-'} </div>
                      } else {
                        return <div />
                      }
                    }}
                  </Cell>
                </Column>
                <Column width={150} sortable resizable>
                  <HeaderCell>Discount (%)</HeaderCell>
                  <Cell dataKey="discount">
                    {(rowData, rowIndex) => {
                      return <div> {rowData.discount ? `${rowData.discount}%` : '-'} </div>
                    }}
                  </Cell>
                </Column>
                <Column width={150} sortable resizable>
                  <HeaderCell>Step</HeaderCell>
                  <Cell dataKey="discountStep" >
                    {(rowData, rowIndex) => {
                      if (rowData.discountStep) {
                        return <div> {`Step ${rowData.discountStep}`} </div>
                      } else {
                        return <div />
                      }
                    }}
                  </Cell>
                </Column>

              {/*  <ColumnGroup className="text-center" header="Customer payment (£)"> */}
                {/* <Column width={170} sortable resizable>
                    <HeaderCell>Voucher payment (£)</HeaderCell>
                    <Cell dataKey="amount_captured">
                      {(rowData, rowIndex) => {
                        if (rowData.amount_captured) {
                          return <div> {(rowData.amount_captured / 100).toFixed(2)} </div>
                        } else {
                          return <div />
                        }
                      }}
                    </Cell>
                </Column>
                */}
                <Column width={170} sortable resizable>
                  <HeaderCell>Voucher Price (£)</HeaderCell>
                  <Cell dataKey="amount_captured_voucher">
                    {(rowData, rowIndex) => (
                      <>
                        <div> {rowData.amount_captured_voucher ? (rowData.amount_captured_voucher / 100).toFixed(2) : (rowData.amount_captured ? (rowData.amount_captured / 100).toFixed(2) : '-')} </div>
                      </>
                    )}
                  </Cell>
                </Column>
                  {(companyOwnerData && companyOwnerData.showCommission) ? (
                    <Column width={150} sortable resizable>
                      <HeaderCell>Commission (%)</HeaderCell>
                      <Cell dataKey="commission">
                        {(rowData, rowIndex) => {
                        return <div> {rowData.commissionPercent ? `${(rowData.commissionPercent * 100)}%` : `-%`} </div>
                        }}
                      </Cell>
                    </Column>
                  ) : (null)}

                {/*
                  <Column width={170} resizable>
                    <HeaderCell><div data-tip data-for='customer-payment-tooltip'>Company Payment (£)</div></HeaderCell>
                    <Cell dataKey="amount_captured">
                      {(rowData, rowIndex) => (
                        <>
                          {console.log('rowData', rowData)}
                          <div> {rowData.amount_captured ? formattedNumber(Math.round(((rowData.amount_captured / 100) - (rowData.application_fee_amount ? (rowData.application_fee_amount / 100) : 0)) * 100) / 100, 2) : 0.00} </div>
                        </>
                      )}
                    </Cell>
                  </Column> */}
                {/*  <Column width={150} sortable resizable>
                    <HeaderCell>Voucher</HeaderCell>
                    <Cell dataKey="amount_captured">
                      {(rowData, rowIndex) => {
                        if (rowData.amount_captured) {
                          return <div> {(rowData.amount_captured / 100).toFixed(2)} </div>
                        } else {
                          return <div />
                        }
                      }}
                    </Cell>
                  </Column>
                  <Column width={150} sortable resizable>
                    <HeaderCell>Commission (%)</HeaderCell>
                    <Cell dataKey="commission">
                      {(rowData, rowIndex) => {
                        if (rowData.commission) {
                          return <div> {rowData.commission ? `${(rowData.commission)}%` : `0%`} </div>
                        } else {
                          return <div />
                        }
                      }}
                    </Cell>
                  </Column>

                  <Column width={170} resizable>
                    <HeaderCell><div data-tip data-for='customer-payment-tooltip'>Company Payment (£)</div></HeaderCell>
                    <Cell dataKey="amount_captured">
                      {(rowData, rowIndex) => (
                        <>
                          {console.log('rowData', rowData)}
                          <div> {rowData.amount_captured ? formattedNumber(Math.round(((rowData.amount_captured / 100) - ((rowData.application_fee_amount / 100))) * 100) / 100, 2) : 0.00} </div>
                        </>
                      )}
                    </Cell>
                  </Column> */}

                {/*  <Column width={150} sortable resizable>
                    <HeaderCell>Card</HeaderCell>
                    <Cell dataKey="amount_captured">
                      {(rowData, rowIndex) => {
                        return <div> 0 </div>
                      }}
                    </Cell>
                  </Column>
                  <Column width={150} sortable resizable>
                    <HeaderCell>Total</HeaderCell>
                    <Cell dataKey="amount_captured">
                      {(rowData, rowIndex) => {
                        if (rowData.amount_captured) {
                          return <div> {(rowData.amount_captured / 100).toFixed(2)} </div>
                        } else {
                          return <div />
                        }
                      }}
                    </Cell>
                  </Column>
                </ColumnGroup>  */}

                <Column width={200} resizable>
                  <HeaderCell>Gifting</HeaderCell>
                  <Cell>
                    {(rowData, rowIndex) => {
                      if (rowData.gift) {
                        return <div> Bespoke Email Service </div>
                      } else {
                        return <div> - </div>
                      }
                    }}
                  </Cell>
                </Column>
                <Column width={160} resizable>
                  <HeaderCell>Gifting Price (£)</HeaderCell>
                  <Cell>
                    {(rowData, rowIndex) => {
                      if (rowData.gift && rowData.amount_captured_gift) {
                        return <div> {(rowData.amount_captured_gift / 100).toFixed(2)} </div>
                      } else {
                        return <div> - </div>
                      }
                    }}
                  </Cell>
                </Column>

                <Column width={210} resizable>
                  <HeaderCell>Scheduled</HeaderCell>
                  <Cell>
                    {(rowData, rowIndex) => {
                      if (rowData.giftingData && rowData.giftingData.date) {
                        if (rowData.scheduledEmailSent) {
                          return (
                            <div>
                              <span>{'Sent ('}</span>
                              <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                                {rowData.giftingData.date.seconds * 1000}
                              </Moment>
                              <span>{')'}</span>
                            </div>
                          )
                        }
                        return (
                          <div>
                            <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                              {rowData.giftingData.date.seconds * 1000}
                            </Moment>
                          </div>
                        );
                      }

                      return <div> {'-'} </div>
                    }}
                  </Cell>
                </Column>
                <Column width={150} resizable>
                  <HeaderCell>Resend email</HeaderCell>
                  <Cell dataKey="commission">
                    {(rowData, rowIndex) => {
                      if (rowData.gift) {
                        return (
                          <div>
                            <Button color="link" disabled={showNoPermission} style={{ paddingTop: 3, paddingBottom: 3 }} onClick={() => this.openResendEmail(rowData.chargeId, rowData.dbId)}>
                              Enter email
                              {(resendEmailProcessing === rowData.chargeId) ? (
                                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                              ) : (null)}
                            </Button>
                          </div>
                        )
                      } else {
                        return <div> - </div>
                      }
                    }}
                  </Cell>
                </Column>

                {(user && (user.role === 'linkedCompanyUser' || company.linkedCompaniesAccount || user.linkedCompaniesAccount)) ? (
                  <Column width={150} resizable>
                    <HeaderCell>Manual Redemption</HeaderCell>
                    <Cell dataKey="refund button">
                      {(rowData, rowIndex) => {
                        if (rowData.used || rowData.redeemed) {
                          return <div> - </div>
                        } else {
                          return (
                            <Button
                              color="link"
                              disabled={showNoPermission}
                              style={{ paddingTop: 3, paddingBottom: 3 }}
                              onClick={() => this.openConfirmRedeemModal(rowData.chargeId, rowData.dbId)}
                            >
                              Redeem
                              {(redeemProcessing === rowData.chargeId) ? (
                                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                              ) : (null)}
                            </Button>
                          )
                        }
                      }}
                    </Cell>
                  </Column>
                ) : (null)}

                {/* <Column width={150} resizable>
                  <HeaderCell>Refund</HeaderCell>
                  <Cell dataKey="refund button">
                    {(rowData, rowIndex) => {
                      console.log('rowData', rowData);
                        if (rowData.refunded) {
                          return (
                            <div>
                              {rowData.refundDate && rowData.refundDate.seconds ? (
                                <Moment format="DD/MM/YYYY" locale="=uk">
                                  {rowData.refundDate.seconds ? (rowData.refundDate.seconds * 1000) : rowData.refundDate}
                                </Moment>
                              ):(null)}
                             </div>
                           )
                        } else {
                          return (
                            <Button color="link" disabled={showNoPermission} style={{ paddingTop: 3, paddingBottom: 3 }} onClick={() => this.refundPayment(rowData.chargeId, rowData.dbId)}>
                            Refund
                            {(refundProcessing === rowData.chargeId) ? (
                              <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                            ):(null)}
                            </Button>
                          )
                        }
                    }}
                  </Cell>
                </Column> */}


                <ColumnGroup className="text-center" header="Refund">
                <Column width={150} resizable>
                  <HeaderCell>Voucher</HeaderCell>
                  <Cell dataKey="refund button">
                    {(rowData, rowIndex) => {
                      if (rowData.refunded) {
                        return (
                          <div>
                            {rowData.refundDate && rowData.refundDate.seconds ? (
                              <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                                {rowData.refundDate.seconds ? (rowData.refundDate.seconds * 1000) : rowData.refundDate}
                              </Moment>
                            ) : (null)}
                          </div>
                        )
                      } else if (rowData.voucherRefunded) {
                        return (
                          <div>
                            {rowData.voucherRefundDate && rowData.voucherRefundDate.seconds ? (
                              <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                                {rowData.voucherRefundDate.seconds ? (rowData.voucherRefundDate.seconds * 1000) : rowData.voucherRefundDate}
                              </Moment>
                            ) : (null)}
                          </div>
                        )
                      } else {
                        return (
                          <div>
                            <Button
                              color="link"
                              disabled={showNoPermission || (user && user.role === 'linkedCompanyUser')}
                              style={{ paddingTop: 3, paddingBottom: 3 }}
                              onClick={() => this.openConfirmModal('refundVoucher', rowData.chargeId, rowData.dbId)}
                            >
                              Refund
                              {(refundProcessing === rowData.chargeId) ? (
                                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                              ) : (null)}
                            </Button>
                          </div>
                        )
                      }
                    }}
                  </Cell>
                </Column>
                <Column width={150} resizable>
                    <HeaderCell>
                      Voucher & Gifting
                    </HeaderCell>
                    <Cell dataKey="refund button">
                      {(rowData, rowIndex) => {
                        if (!rowData.gift) {
                          return <div> - </div>
                        } else if (rowData.refunded) {
                          return (
                            <div>
                              {rowData.refundDate && rowData.refundDate.seconds ? (
                                <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                                  {rowData.refundDate.seconds ? (rowData.refundDate.seconds * 1000) : rowData.refundDate}
                                </Moment>
                              ) : (null)}
                            </div>
                          )
                        } else if (rowData.giftRefunded) {
                          return <div> - </div>
                        } else {
                          return (
                            <div>
                              <Button
                                color="link"
                                disabled={showNoPermission || (user && user.role === 'linkedCompanyUser')}
                                className={(((rowData.paymentDate.seconds * 1000) + (1000 * 60 * 60 * 24 * 14)) <= Date.now()) ? 'company-payments-disabled-button' : ''}
                                style={{ paddingTop: 3, paddingBottom: 3 }}
                                onClick={() => (((rowData.paymentDate.seconds * 1000) + (1000 * 60 * 60 * 24 * 14)) <= Date.now()) ? null : this.openConfirmModal(rowData.voucherRefunded ? 'refundGifting' : 'refundAll', rowData.chargeId, rowData.dbId)}
                                data-tip
                                data-for='refund-tooltip'
                                for="refund-tooltip"
                              >
                                Refund
                                {(refundProcessing === rowData.chargeId) ? (
                                  <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                                ) : (null)}
                              </Button>
                              {console.log('paymentTooltips', paymentTooltips)}
                              {paymentTooltips && paymentTooltips.companyPAymentRefund14days && (((rowData.paymentDate.seconds * 1000) + (1000 * 60 * 60 * 24 * 14)) <= Date.now()) ? (
                                <ReactTooltip place="left" className='react-tooltip-custom-class react-tooltip-custom-class-company-payment' effect="solid" id='refund-tooltip'>
                                  <span>{paymentTooltips.companyPAymentRefund14days ? paymentTooltips.companyPAymentRefund14days.value : ''}</span>
                                </ReactTooltip>
                              ) : (null)}
                            </div>
                          )
                        }
                      }}
                    </Cell>
                </Column>
              </ColumnGroup>

                <Column width={150} resizable>
                  <HeaderCell>Cancel</HeaderCell>
                  <Cell dataKey="refund button">
                    {(rowData, rowIndex) => {
                        if (rowData.canceled) {
                          return (
                            <div>
                              {rowData.canceledDate ? (
                                <Moment format="DD/MM/YYYY" locale="=uk">
                                  {rowData.canceledDate}
                                </Moment>
                              ):(null)}
                            </div>
                          )
                        } else if (rowData.used) {
                          return <div> Used </div>
                        } else {
                          return (
                            <Button
                              color="link"
                              disabled={showNoPermission || (user && user.role === 'linkedCompanyUser')}
                              style={{ paddingTop: 3, paddingBottom: 3 }}
                              onClick={() => this.openConfirmModal('cancel', rowData.chargeId, rowData.dbId)}
                            >
                              Cancel
                              {(cancelProcessing === rowData.chargeId) ? (
                                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                              ):(null)}
                            </Button>
                          )
                        }
                    }}
                  </Cell>
                </Column>
              </Table>
              <div style={{ textAlign: 'right', color: '#6d6d6d' }}>
                These values are only indicative and may change.
              </div>
            </CardBody>
          </Card>
          <Modal isOpen={confirmModalOpen} toggle={this.closeConfirmModal}>
            <ModalBody toggle={this.closeDeleteUser}>
              <Row>
                <Col xs="12">
                  {(!!confirmModalError) && <Alert color="danger">{`Failed to finish action - ${confirmModalError}`}</Alert>}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  Are you sure?
                </Col>
                <Col xs="6">
                  <Button color="secondary" style={{ float: 'right' }} onClick={this.closeConfirmModal}>No</Button>
                  <Button color="primary" style={{ float: 'right', marginRight: 8 }} className="custom-modal-button" onClick={this.finishConfirmModal}>
                    Yes
                    {(confirmModalProcessing) ? (
                      <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                    ) : (null)}
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          <Modal isOpen={resendEmailOpen} toggle={this.closeResendEmail}>
            <ModalBody toggle={this.closeResendEmail}>
              <Row>
                <Col xs="12">
                  {(!!resendEmailError) && <Alert color="danger">{`Failed to finish action - ${resendEmailError}`}</Alert>}
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <Input
                    type="text"
                    name="resendEmailValue"
                    id="resendEmailValue"
                    value={resendEmailValue}
                    onChange={this.handleChange}
                  />
                </Col>
                <Col xs="12">
                  <Button color="secondary" style={{ boxShadow: 'none', float: 'right' }} onClick={this.closeResendEmail}>Close</Button>
                  <Button color="primary" style={{ float: 'right', marginRight: 8 }} className="custom-modal-button" onClick={this.resendEmail}>
                    Send
                    {(resendEmailProcessing) ? (
                      <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                    ) : (null)}
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>


          <Modal isOpen={openReAuthModal} toggle={this.closeReAuthModal}>
            <ModalHeader> Re-enter password </ModalHeader>
            <ModalBody toggle={this.closeResendEmail}>
              <Row>
                <Col xs="12">
                  {(!!reAuthError) && <Alert color="danger">{`Failed to authenticate`}</Alert>}
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <Input
                    type="password"
                    name="reAuthPassword"
                    id="reAuthPassword"
                    value={reAuthPassword}
                    onChange={this.handleChange}
                  />
                </Col>
                <Col xs="12">
                  <Button color="primary" style={{ float: 'right', marginRight: 8, marginTop: 10 }} className="custom-modal-button" onClick={this.reAuthenticate}>
                    Confirm
                    {(reAuthSpinner) ? (
                      <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                    ) : (null)}
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>


          <Modal isOpen={show2faModal} toggle={this.close2faModal} size="md">
            <ModalHeader toggle={this.close2faModal}>
              <div style={{ fontSize: 22 }}>Two-Factor Authentication</div>
            </ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup>
                  <Label for="role">
                    For security, please enter your SMS verification code.
                  </Label>
                </FormGroup>
              </Form>
              <Row>
                <Col style={{ paddingLeft: '1vw', paddingRight: '1vw', minWidth: 37, width: 37 }}>
                  <Input
                    className="room-password-digit"
                    style={{ paddingLeft: '0.2em', paddingRight: '0.2em', textAlign: 'center', width: 37 }}
                    type="text"
                    name={0}
                    id="digit1"
                    value={phoneCode[0] || ''}
                    onChange={this.handleCodeChange2}
                    innerRef={this.digit1}
                  />
                </Col>
                <Col style={{ paddingLeft: '1vw', paddingRight: '1vw', minWidth: 37, width: 37 }}>
                  <Input
                    style={{ paddingLeft: '0.2em', paddingRight: '0.2em', textAlign: 'center', width: 37 }}
                    type="text"
                    name={1}
                    id="digit2"
                    value={phoneCode[1] || ''}
                    onChange={this.handleCodeChange2}
                    innerRef={this.digit2}
                  />
                </Col>
                <Col style={{ paddingLeft: '1vw', paddingRight: '1vw', minWidth: 37, width: 37 }}>
                  <Input
                    style={{ paddingLeft: '0.2em', paddingRight: '0.2em', textAlign: 'center', width: 37 }}
                    type="text"
                    name={2}
                    id="digit3"
                    value={phoneCode[2] || ''}
                    onChange={this.handleCodeChange2}
                    innerRef={this.digit3}
                  />
                </Col>
                <Col style={{ paddingLeft: '1vw', paddingRight: '1vw', minWidth: 37, width: 37 }}>
                  <Input
                    style={{ paddingLeft: '0.2em', paddingRight: '0.2em', textAlign: 'center', width: 37 }}
                    type="text"
                    name={3}
                    id="digit4"
                    value={phoneCode[3] || ''}
                    onChange={this.handleCodeChange2}
                    innerRef={this.digit4}
                  />
                </Col>
                <Col style={{ paddingLeft: '1vw', paddingRight: '1vw', minWidth: 37, width: 37 }}>
                  <Input
                    style={{ paddingLeft: '0.2em', paddingRight: '0.2em', textAlign: 'center', width: 37 }}
                    type="text"
                    name={4}
                    id="digit5"
                    value={phoneCode[4] || ''}
                    onChange={this.handleCodeChange2}
                    innerRef={this.digit5}
                  />
                </Col>
                <Col style={{ paddingLeft: '1vw', paddingRight: '1vw', minWidth: 37, width: 37 }}>
                  <Input
                    style={{ paddingLeft: '0.2em', paddingRight: '0.2em', textAlign: 'center', width: 37 }}
                    type="text"
                    name={5}
                    id="digit6"
                    value={phoneCode[5] || ''}
                    onChange={this.handleCodeChange2}
                    innerRef={this.digit6}
                  />
                </Col>
                <Col>
                  <Button color="primary" onClick={this.submitVerificationCode} style={{ float: 'right' }}>
                    Submit
                    {processingSend2fa ? (
                      <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem', marginTop: 3 }}> {''} </Spinner>
                    ) : (null)}
                  </Button>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>

            </ModalFooter>
          </Modal>
          <div style={{ width: 0, height: 0 }} id="reauth-container"></div>
        </div>
      );
    }

    return (
      <div>
        <Card>
          <CardBody>
            <Row>
              <Col xs="10" />
              <Col xs="2">
                <AutoRefresh refreshFunction={this.refresh} refreshTime={60} hidden />
              </Col>
            </Row>
            <Row style={{ height: 55 }}>
              <Col md="3">
                <DateTimePicker
                  className="date-picker"
                  time={false}
                  culture="en"
                  format="MMM DD YYYY"
                  onChange={this.changeStartDate}
                  value={startDate}
                  defaultValue={new Date()}
                />
              </Col>
              <Col md="3">
                <DateTimePicker
                  className="date-picker"
                  time={false}
                  culture="en"
                  format="MMM DD YYYY"
                  onChange={this.changeEndDate}
                  value={endDate}
                  defaultValue={new Date()}
                />
              </Col>
              <Col xs="7" md="6">
                <Button color="primary" onClick={this.fetchPaymentsRange}> Filter </Button>
                {(!setupActive && !popupBlocked && companyOwnerData && companyOwnerData.companyStripeId && stripeAccountStatus) ? (
                  <>
                    {stripeAccountStatus === 'verified' ? (
                      <a href={'https://dashboard.stripe.com/'} style={{ float: 'right' }} target="_blank" rel="noopener noreferrer">
                        <Button color="link"> View payments </Button>
                      </a>
                    ):(
                      <>
                    {/*  <a href={'https://dashboard.stripe.com/'} target="_blank" rel="noopener noreferrer"> */}
                        <Button color="link" style={{ float: 'right' }} disabled={showNoPermission} onClick={this.addStripeAccount}>
                          {`View payments ${!stripeChargesEnabled ? '(Payments Disabled)' : ''} ${!stripePayoutsEnabled ? '(Payouts Disabled)' : ''}`}
                          {(connectStripeProcessing) ? (
                            <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                          ):(null)}
                        </Button>
                    {/*  </a>  */}
                      </>
                    )}
                  </>
                ):(
                  <>
                  {(!setupActive && !popupBlocked && stripeStatusFetched && companyOwnerData && companyOwnerData.companyStripeId) ? (
                    <Button color="link" style={{ float: 'right', fontSize: 11, maxWidth: '80%', paddingTop: 4 }} disabled={showNoPermission} onClick={this.addStripeAccount}>
                      Stripe account disconnected. To connect a new Stripe account, you need to create a new account on Steppr.
                      {(connectStripeProcessing) ? (
                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                      ):(null)}
                    </Button>
                  ):(
                    <Button color="link" style={{ float: 'right' }} disabled={showNoPermission} onClick={this.addStripeAccount}>
                      Connect Stripe Account
                      {(connectStripeProcessing) ? (
                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                      ):(null)}
                    </Button>
                  )}
                  </>
                )}
              </Col>
            </Row>

            <Table
              style={{ fontSize: 14 }}
              sortType={sortType}
              sortColumn={sortColumn}
              data={payments || []}
              virtualized
              minHeight={TableHeight}
              height={TableHeight}
              headerHeight={64}
              shouldUpdateScroll={false}
              onSortColumn={this.sort}
              onScroll={this.onScroll}
            >
            <Column width={130} sortable resizable>
                <HeaderCell>Order ID</HeaderCell>
              <Cell dataKey="chargeId" />
            </Column>

            <Column width={170} sortable resizable>
              <HeaderCell>Date</HeaderCell>
              <Cell dataKey="paymentDate">
              {(rowData, rowIndex) => {
                return (
                  <div>
                    {rowData.paymentDate ? (
                      <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                        {rowData.paymentDate.seconds * 1000}
                      </Moment>
                    ):(null)}
                  </div>
                )
              }}
              </Cell>
            </Column>

                <Column width={260} sortable resizable>
                  <HeaderCell>Status</HeaderCell>
                  <Cell dataKey="redeemed">
                    {(rowData, rowIndex) => {
                      if (rowData.canceled) {
                        if (rowData.refunded) {
                          return (
                            <div>
                              <span>Cancelled (refunded)</span>
                              {rowData.canceledDate ? (
                                <span>
                                  {` - `}
                                  <Moment format="DD/MM/YYYY" locale="=uk">
                                    {rowData.canceledDate.seconds ? (rowData.canceledDate.seconds * 1000) : rowData.canceledDate}
                                  </Moment>
                                </span>
                              ) : (null)}
                            </div>
                          )
                        } else {
                          return (
                            <div>
                              <span>Cancelled</span>
                              {rowData.canceledDate ? (
                                <span>
                                  {` - `}
                                  <Moment format="DD/MM/YYYY" locale="=uk">
                                    {rowData.canceledDate.seconds ? (rowData.canceledDate.seconds * 1000) : rowData.canceledDate}
                                  </Moment>
                                </span>
                              ) : (null)}
                            </div>
                          )
                        }
                      } else if (rowData.used) {
                        if (rowData.refunded) {
                          return (
                            <div>
                              <span>Redeemed (refunded) {`${rowData.redeemedBy ? `(${rowData.redeemedBy})` : ''}`}</span>
                              {rowData.usedDate ? (
                                <span>
                                  {` - `}
                                  <Moment format="DD/MM/YYYY" locale="=uk">
                                    {rowData.usedDate.seconds ? (rowData.usedDate.seconds * 1000) : rowData.usedDate}
                                  </Moment>
                                </span>
                              ) : (null)}
                            </div>
                          )
                        } else {
                          return (
                            <div>
                              <span>Redeemed {`${rowData.redeemedBy ? `(${rowData.redeemedBy})` : ''}`}</span>
                              {rowData.usedDate ? (
                                <span>
                                  {` - `}
                                  <Moment format="DD/MM/YYYY" locale="=uk">
                                    {rowData.usedDate.seconds ? (rowData.usedDate.seconds * 1000) : rowData.usedDate}
                                  </Moment>
                                </span>
                              ) : (null)}
                            </div>
                          )
                        }
                      } else {
                        if (rowData.refunded) {
                          if (rowData.canceled) {
                            return (
                              <div>
                                <span>Cancelled (refunded)</span>
                                {rowData.canceledDate ? (
                                  <span>
                                    {` - `}
                                    <Moment format="DD/MM/YYYY" locale="=uk">
                                      {rowData.canceledDate}
                                    </Moment>
                                  </span>
                                ) : (null)}
                              </div>
                            )
                          } else {
                            return <div> Unused (refunded) </div>
                          }
                        } else {
                          if (rowData.canceled) {
                            return (
                              <div>
                                <span>Cancelled</span>
                                {rowData.canceledDate ? (
                                  <span>
                                    {` - `}
                                    <Moment format="DD/MM/YYYY" locale="=uk">
                                      {rowData.canceledDate}
                                    </Moment>
                                  </span>
                                ) : (null)}
                              </div>
                            )
                          } else {
                            return <div> Unused </div>
                          }
                        }
                      }
                    }}
                  {/*  {(rowData, rowIndex) => {
                        if (rowData.used) {
                          return <div> Used </div>
                        } else {
                          if (rowData.refunded) {
                            if (rowData.canceled) {
                              return <div> Refunded, Cancelled </div>
                            } else {
                              return <div> Refunded </div>
                            }
                          } else {
                            if (rowData.canceled) {
                              return <div> Cancelled </div>
                            } else {
                              return <div> Unused </div>
                            }
                          }
                        }
                    }}  */}
                  </Cell>
                </Column>
                    <Column width={200} sortable resizable>
                      <HeaderCell>Customer Name</HeaderCell>
                      <Cell dataKey="customerName" />
                    </Column>
                    <Column width={200} sortable resizable>
                      <HeaderCell>Customer Email</HeaderCell>
                      <Cell dataKey="customerEmail" />
              </Column>
              <Column width={200} sortable resizable>
                <HeaderCell>Voucher code</HeaderCell>
                <Cell dataKey="voucherCodePreview">
                  {(rowData, rowIndex) => {
                    return <div onClick={() => { this.openCodePreview(rowData.dbId) }}> {(codePreviews && codePreviews[rowData.dbId]) ? codePreviews[rowData.dbId] : (rowData.voucherCodePreview ? `${rowData.voucherCodePreview}...` : '-')} </div>
                  }}
                </Cell>
              </Column>
                {/*  <Column width={200} sortable resizable>
                    <HeaderCell>Company</HeaderCell>
                    <Cell dataKey="companyName" />
                  </Column> */}
                  <Column width={200} sortable resizable>
                    <HeaderCell>Campaign</HeaderCell>
                    <Cell dataKey="voucherName" />
                  </Column>
                {/*  <Column width={100} resizable>
                    <HeaderCell>POD order number</HeaderCell>
                    <Cell>
                      <div>  </div>
                    </Cell>
                  </Column>  */}

                <Column width={150} sortable resizable>
                  <HeaderCell>Face Value (£)</HeaderCell>
                  <Cell dataKey="faceValue">
                    {(rowData, rowIndex) => {
                    if (rowData.faceValue) {
                      return <div> {rowData.faceValue ? (rowData.faceValue).toFixed(2) : '-'} </div>
                      } else {
                        return <div />
                      }
                    }}
                  </Cell>
                </Column>
                  <Column width={150} sortable resizable>
                    <HeaderCell>Discount (%)</HeaderCell>
                    <Cell dataKey="discount">
                      {(rowData, rowIndex) => {
                    return <div> {rowData.discount ? `${rowData.discount}%` : '-'} </div>
                      }}
                    </Cell>
                  </Column>
                  <Column width={150} sortable resizable>
                    <HeaderCell>Step</HeaderCell>
                    <Cell dataKey="discountStep" >
                      {(rowData, rowIndex) => {
                        if (rowData.discountStep) {
                          return <div> {`Step ${rowData.discountStep}`} </div>
                        } else {
                          return <div />
                        }
                      }}
                    </Cell>
                  </Column>

            {/*  <ColumnGroup className="text-center" header="Customer payment (£)">
                <Column width={150} sortable resizable>
                  <HeaderCell>Voucher</HeaderCell>
                  <Cell dataKey="amount_captured">
                    {(rowData, rowIndex) => {
                      if (rowData.amount_captured) {
                        return <div> {(rowData.amount_captured / 100).toFixed(2)} </div>
                      } else {
                        return <div />
                      }
                    }}
                  </Cell>
                </Column>
                <Column width={150} sortable resizable>
                  <HeaderCell>Card</HeaderCell>
                  <Cell dataKey="amount_captured">
                    {(rowData, rowIndex) => {
                      return <div> 0 </div>
                    }}
                  </Cell>
                </Column>  */}

              {/*
                <Column width={170} sortable resizable>
                  <HeaderCell>Voucher payment (£)</HeaderCell>
                  <Cell dataKey="amount_captured">
                    {(rowData, rowIndex) => {
                      if (rowData.amount_captured) {
                        return <div> {(rowData.amount_captured / 100).toFixed(2)} </div>
                      } else {
                        return <div />
                      }
                    }}
                  </Cell>
              </Column> */}
              <Column width={170} sortable resizable>
                <HeaderCell>Voucher Price (£)</HeaderCell>
                <Cell dataKey="amount_captured_voucher">
                  {(rowData, rowIndex) => (
                    <>
                      <div> {rowData.amount_captured_voucher ? (rowData.amount_captured_voucher / 100).toFixed(2) : (rowData.amount_captured ? (rowData.amount_captured / 100).toFixed(2) : '-')} </div>
                      {/*<div> {rowData.amount_captured_voucher ? formattedNumber(Math.round(((rowData.amount_captured_voucher / 100) - (rowData.application_fee_amount ? (rowData.application_fee_amount / 100) : 0)) * 100) / 100, 2) : '-'} </div>
                    */}
                    </>
                    )}
                </Cell>
              </Column>
              

                {(companyOwnerData && companyOwnerData.showCommission) ? (
                  <Column width={150} sortable resizable>
                    <HeaderCell>Commission (%)</HeaderCell>
                    <Cell dataKey="commission">
                      {(rowData, rowIndex) => {
                      return <div> {rowData.commissionPercent ? `${(rowData.commissionPercent * 100)}%` : `-%`} </div>
                      }}
                    </Cell>
                  </Column>
                ) : (null)}

              {/*
                <Column width={170} resizable>
                  <HeaderCell><div data-tip data-for='customer-payment-tooltip'>Company Payment (£)</div></HeaderCell>
                  <Cell dataKey="amount_captured">
                    {(rowData, rowIndex) => (
                      <>
                        {console.log('rowData', rowData)}
                        <div> {rowData.amount_captured ? formattedNumber(Math.round(((rowData.amount_captured / 100) - (rowData.application_fee_amount ? (rowData.application_fee_amount / 100) : 0)) * 100) / 100, 2) : 0.00} </div>
                      </>
                    )}
                  </Cell>
                </Column> */}
              {/*  </ColumnGroup>  */}

              <Column width={200} resizable>
                <HeaderCell>Gifting</HeaderCell>
                <Cell>
                  {(rowData, rowIndex) => {
                    if (rowData.gift) {
                      return <div> Bespoke Email Service </div>
                    } else {
                      return <div> - </div>
                    }
                  }}
                </Cell>
              </Column>

              <Column width={160} resizable>
                <HeaderCell>Gifting Price (£)</HeaderCell>
                <Cell>
                  {(rowData, rowIndex) => {
                    if (rowData.gift && rowData.amount_captured_gift) {
                      return <div> {(rowData.amount_captured_gift / 100).toFixed(2)} </div>
                    } else {
                      return <div> - </div>
                    }
                  }}
                </Cell>
              </Column>

              <Column width={210} resizable>
                <HeaderCell>Scheduled</HeaderCell>
                <Cell>
                  {(rowData, rowIndex) => {
                    if (rowData.giftingData && rowData.giftingData.date) {
                      if (rowData.scheduledEmailSent) {
                        return (
                          <div>
                            <span>{'Sent ('}</span>
                            <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                              {rowData.giftingData.date.seconds * 1000}
                            </Moment>
                            <span>{')'}</span>
                          </div>
                        )
                      }
                      return (
                        <div>
                          <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                            {rowData.giftingData.date.seconds * 1000}
                          </Moment>
                        </div>
                      );
                    }

                    return <div> {'-'} </div>
                  }}
                </Cell>
              </Column>
              <Column width={150} resizable>
                <HeaderCell>Resend email</HeaderCell>
                <Cell dataKey="commission">
                  {(rowData, rowIndex) => {
                    if (rowData.gift) {
                    return (
                      <div>
                        <Button color="link" disabled={showNoPermission} style={{ paddingTop: 3, paddingBottom: 3 }} onClick={() => this.openResendEmail(rowData.chargeId, rowData.dbId)}>
                          Enter email
                          {(resendEmailProcessing === rowData.chargeId) ? (
                            <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                          ) : (null)}
                        </Button>
                      </div>
                      )
                    } else {
                      return <div> - </div>
                    }
                  }}
                </Cell>
              </Column>

              {(user && (user.role === 'linkedCompanyUser' || company.linkedCompaniesAccount || user.linkedCompaniesAccount)) ? (
                <Column width={150} resizable>
                  <HeaderCell>Manual Redemption</HeaderCell>
                  <Cell dataKey="refund button">
                    {(rowData, rowIndex) => {
                      if (rowData.used || rowData.redeemed) {
                        return <div> - </div>
                      } else {
                        return (
                          <Button
                            color="link"
                            disabled={showNoPermission}
                            style={{ paddingTop: 3, paddingBottom: 3 }}
                            onClick={() => this.openConfirmRedeemModal(rowData.chargeId, rowData.dbId)}
                          >
                            Redeem
                            {(redeemProcessing === rowData.chargeId) ? (
                              <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                            ) : (null)}
                          </Button>
                        )
                      }
                    }}
                  </Cell>
                </Column>
              ) : (null)}

              <ColumnGroup className="text-center" header="Refund">
                <Column width={150} resizable>
                  <HeaderCell>Voucher</HeaderCell>
                  <Cell dataKey="refund button">
                    {(rowData, rowIndex) => {
                      if (rowData.refunded) {
                        return (
                          <div>
                            {rowData.refundDate && rowData.refundDate.seconds ? (
                              <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                                {rowData.refundDate.seconds ? (rowData.refundDate.seconds * 1000) : rowData.refundDate}
                              </Moment>
                            ) : (null)}
                          </div>
                        )
                      } else if (rowData.voucherRefunded) {
                        return (
                          <div>
                            {rowData.voucherRefundDate && rowData.voucherRefundDate.seconds ? (
                              <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                                {rowData.voucherRefundDate.seconds ? (rowData.voucherRefundDate.seconds * 1000) : rowData.voucherRefundDate}
                              </Moment>
                            ) : (null)}
                          </div>
                        )
                      } else {
                        return (
                          <div>
                            <Button
                              color="link"
                              disabled={showNoPermission || (user && user.role === 'linkedCompanyUser')}
                              style={{ paddingTop: 3, paddingBottom: 3 }}
                              onClick={() => this.openConfirmModal('refundVoucher', rowData.chargeId, rowData.dbId)}
                            >
                              Refund
                              {(refundProcessing === rowData.chargeId) ? (
                                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                              ) : (null)}
                            </Button>
                          </div>
                        )
                      }
                    }}
                  </Cell>
                </Column>
                <Column width={150} resizable>
                  <HeaderCell>
                    Voucher & Gifting
                  </HeaderCell>
                  <Cell dataKey="refund button">
                    {(rowData, rowIndex) => {
                      if (!rowData.gift) {
                        return <div> - </div>
                      } else if (rowData.giftRefunded) {
                        return <div> - </div>
                      } else if (rowData.refunded) {
                        return (
                          <div>
                            {rowData.refundDate && rowData.refundDate.seconds ? (
                              <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                                {rowData.refundDate.seconds ? (rowData.refundDate.seconds * 1000) : rowData.refundDate}
                              </Moment>
                            ) : (null)}
                          </div>
                        )
                      } else {
                        return (
                          <div>
                            <Button
                              color="link"
                              disabled={showNoPermission || (user && user.role === 'linkedCompanyUser')}
                              className={(((rowData.paymentDate.seconds * 1000) + (1000 * 60 * 60 * 24 * 14)) <= Date.now()) ? 'company-payments-disabled-button' : ''}
                              style={{ paddingTop: 3, paddingBottom: 3 }}
                              onClick={() => this.openConfirmModal((((rowData.paymentDate.seconds * 1000) + (1000 * 60 * 60 * 24 * 14)) <= Date.now()) ? null : rowData.voucherRefunded ? 'refundGifting' : 'refundAll', rowData.chargeId, rowData.dbId)}
                              data-tip
                              data-for='refund-tooltip'
                              for="refund-tooltip"
                            >
                              Refund
                              {(refundProcessing === rowData.chargeId) ? (
                                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                              ) : (null)}
                            </Button>
                            {paymentTooltips && paymentTooltips.companyPAymentRefund14days && (((rowData.paymentDate.seconds * 1000) + (1000 * 60 * 60 * 24 * 14)) <= Date.now()) ? (
                              <ReactTooltip place="left" className='react-tooltip-custom-class react-tooltip-custom-class-company-payment' effect="solid" id='refund-tooltip'>
                                <span>{paymentTooltips.companyPAymentRefund14days ? paymentTooltips.companyPAymentRefund14days.value : ''}</span>
                              </ReactTooltip>
                            ) : (null)}
                          </div>
                        )
                      }
                    }}
                  </Cell>
                </Column>
              </ColumnGroup>
                <Column width={150} resizable>
                  <HeaderCell>Cancel</HeaderCell>
                  <Cell dataKey="refund button">
                    {(rowData, rowIndex) => {
                        if (rowData.canceled) {
                          return (
                            <div>
                              {rowData.canceledDate ? (
                                <Moment format="DD/MM/YYYY" locale="=uk">
                                  {rowData.canceledDate}
                                </Moment>
                              ):(null)}
                            </div>
                          )
                        } else if (rowData.used) {
                          return <div> Used </div>
                        } else {
                          return (
                            <Button
                              color="link"
                              disabled={showNoPermission || (user && user.role === 'linkedCompanyUser')}
                              style={{ paddingTop: 3, paddingBottom: 3 }}
                              onClick={() => this.openConfirmModal('cancel', rowData.chargeId, rowData.dbId)}
                            >
                              Cancel
                              {(cancelProcessing === rowData.chargeId) ? (
                                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                              ):(null)}
                            </Button>
                          )
                        }
                    }}
                  </Cell>
                </Column>
            </Table>
            <div style={{ textAlign: 'right', color: '#6d6d6d' }}>
              Values, which update each minute, are only indicative and may change.
            </div>
          </CardBody>
        </Card>

        <Modal isOpen={confirmRedeemModalOpen} toggle={this.closeConfirmRedeemModal}>
          <ModalBody toggle={this.closeDeleteUser}>
            <Row>
              <Col xs="12">
                {(!!confirmRedeemModalError) && <Alert color="danger">{`Failed to finish action - ${confirmRedeemModalError}`}</Alert>}
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                Are you sure?
              </Col>
              <Col xs="6">
                <Button color="secondary" style={{ boxShadow: 'none', float: 'right' }} onClick={this.closeConfirmRedeemModal}>No</Button>
                <Button color="primary" style={{ float: 'right', marginRight: 8 }} className="custom-modal-button" onClick={this.finishConfirmRedeemModal}>
                  Yes
                  {(redeemModalProcessing) ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                  ) : (null)}
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>


        <Modal isOpen={confirmModalOpen} toggle={this.closeConfirmModal}>
          <ModalBody toggle={this.closeDeleteUser}>
            <Row>
              <Col xs="12">
                {(!!confirmModalError) && <Alert color="danger">{`Failed to finish action - ${confirmModalError}`}</Alert>}
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                Are you sure?
              </Col>
              <Col xs="6">
                <Button color="secondary" style={{ boxShadow: 'none', float: 'right' }} onClick={this.closeConfirmModal}>No</Button>
                <Button color="primary" style={{ float: 'right', marginRight: 8 }} className="custom-modal-button" onClick={this.finishConfirmModal}>
                  Yes
                  {(confirmModalProcessing) ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                  ) : (null)}
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Modal isOpen={resendEmailOpen} toggle={this.closeResendEmail} className="centered-modal">
          <ModalBody toggle={this.closeResendEmail}>
            <Row>
              <Col xs="12">
                {(!!resendEmailError) && <Alert color="danger">{`Failed to finish action - ${resendEmailError}`}</Alert>}
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Input
                  type="text"
                  name="resendEmailValue"
                  id="resendEmailValue"
                  placeholder="Enter email address"
                  value={resendEmailValue}
                  onChange={this.handleChange}
                />
              </Col>
              <Col xs="12" style={{ marginTop: 8 }} >
                <Button color="secondary" style={{ float: 'right' }} onClick={this.closeResendEmail}>Close</Button>
                <Button color="primary" style={{ float: 'right', marginRight: 8 }} className="custom-modal-button" onClick={this.resendEmail}>
                  Send
                  {(resendEmailProcessing) ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                  ) : (null)}
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Modal isOpen={openReAuthModal} toggle={this.closeReAuthModal}>
          <ModalHeader> Re-enter password </ModalHeader>
          <ModalBody toggle={this.closeResendEmail}>
            <Row>
              <Col xs="12">
                {(!!reAuthError) && <Alert color="danger">{`Failed to authenticate`}</Alert>}
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Input
                  type="password"
                  name="reAuthPassword"
                  id="reAuthPassword"
                  value={reAuthPassword}
                  onChange={this.handleChange}
                />
              </Col>
              <Col xs="12">
                <Button color="primary" style={{ float: 'right', marginRight: 8, marginTop: 10 }} className="custom-modal-button" onClick={this.reAuthenticate}>
                  Confirm
                  {(reAuthSpinner) ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                  ) : (null)}
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>


        <Modal isOpen={show2faModal} toggle={this.close2faModal} size="md">
          <ModalHeader toggle={this.close2faModal}>
            <div style={{ fontSize: 22 }}>Two-Factor Authentication</div>
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="role">
                  For security, please enter your SMS verification code.
                </Label>
              </FormGroup>
            </Form>
            <Row>
              <Col style={{ paddingLeft: '1vw', paddingRight: '1vw', minWidth: 37, width: 37 }}>
                <Input
                  className="room-password-digit"
                  style={{ paddingLeft: '0.2em', paddingRight: '0.2em', textAlign: 'center', width: 37 }}
                  type="text"
                  name={0}
                  id="digit1"
                  value={phoneCode[0] || ''}
                  onChange={this.handleCodeChange2}
                  innerRef={this.digit1}
                />
              </Col>
              <Col style={{ paddingLeft: '1vw', paddingRight: '1vw', minWidth: 37, width: 37 }}>
                <Input
                  style={{ paddingLeft: '0.2em', paddingRight: '0.2em', textAlign: 'center', width: 37 }}
                  type="text"
                  name={1}
                  id="digit2"
                  value={phoneCode[1] || ''}
                  onChange={this.handleCodeChange2}
                  innerRef={this.digit2}
                />
              </Col>
              <Col style={{ paddingLeft: '1vw', paddingRight: '1vw', minWidth: 37, width: 37 }}>
                <Input
                  style={{ paddingLeft: '0.2em', paddingRight: '0.2em', textAlign: 'center', width: 37 }}
                  type="text"
                  name={2}
                  id="digit3"
                  value={phoneCode[2] || ''}
                  onChange={this.handleCodeChange2}
                  innerRef={this.digit3}
                />
              </Col>
              <Col style={{ paddingLeft: '1vw', paddingRight: '1vw', minWidth: 37, width: 37 }}>
                <Input
                  style={{ paddingLeft: '0.2em', paddingRight: '0.2em', textAlign: 'center', width: 37 }}
                  type="text"
                  name={3}
                  id="digit4"
                  value={phoneCode[3] || ''}
                  onChange={this.handleCodeChange2}
                  innerRef={this.digit4}
                />
              </Col>
              <Col style={{ paddingLeft: '1vw', paddingRight: '1vw', minWidth: 37, width: 37 }}>
                <Input
                  style={{ paddingLeft: '0.2em', paddingRight: '0.2em', textAlign: 'center', width: 37 }}
                  type="text"
                  name={4}
                  id="digit5"
                  value={phoneCode[4] || ''}
                  onChange={this.handleCodeChange2}
                  innerRef={this.digit5}
                />
              </Col>
              <Col style={{ paddingLeft: '1vw', paddingRight: '1vw', minWidth: 37, width: 37 }}>
                <Input
                  style={{ paddingLeft: '0.2em', paddingRight: '0.2em', textAlign: 'center', width: 37 }}
                  type="text"
                  name={5}
                  id="digit6"
                  value={phoneCode[5] || ''}
                  onChange={this.handleCodeChange2}
                  innerRef={this.digit6}
                />
              </Col>
              <Col>
                <Button color="primary" onClick={this.submitVerificationCode} style={{ float: 'right' }}>
                  Submit
                  {processingSend2fa ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem', marginTop: 3 }}> {''} </Spinner>
                  ) : (null)}
                </Button>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>

          </ModalFooter>
        </Modal>
        <div style={{ width: 0, height: 0 }} id="reauth-container"></div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  company: state.company.company || {},
  payments: state.company.companyPayments || [],
  stripeAccountStatus: state.user.stripeAccountStatus || null,
  stripeChargesEnabled: state.user.stripeChargesEnabled || null,
  stripePayoutsEnabled: state.user.stripePayoutsEnabled || null,
  roles: state.users.companiesRoles || {},
  adminsRoles: state.admin.adminsRoles || {},
  companyOwnerData: state.company.companyOwnerData || {},
  adminViewAsCompany: state.admin.adminViewAsCompany || null,
  paymentTooltips: state.contentManagement.paymentTooltips || {},
  loginTime: state.user.loginTime || null,
});

const mapDispatchToProps = {
  getPayments: getCompanyPayments,
  getMorePayments: getMoreCompanyPayments,
  addStripeAccount: createStripeAccount,
  fetchStripeLoginLink: getStripeLoginLink,
  getStripeCompanyStatus: checkStripeCompanyStatus,
  refundPayment: refundStripePayment,
  cancelPayment: cancelVoucher,
  sortPayments: sortCompanyPayments,
  getSinglePayment: getSingleCompanyPayment,
  updateLastActiveFunc: updateLastActive,
  fetchPaymentTooltips: getPaymentTooltips,
  resendEmail: resendGiftingEmail,
  manuallyRedeemPaymentFunction: manuallyRedeemPayment,
  openCodePreview: openVoucherCodePreview,
  reAuthenticate: reAuthenticateUser,
  completeVerification: completePhoneVerification,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
