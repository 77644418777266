import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Input, Button } from 'reactstrap';

class Analytics extends Component {
  static propTypes = {
    image: PropTypes.string,
  };

  static defaultProps = {
    image: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      url: null,
      data: null,
      newData: { metric1: '-', metric2: '-' },
    };
  }

  componentDidUpdate(prevProps) {

  }

  changeNewMetric = (e, type) => {
    const { addAnalyticsTemplate } = this.props;
    const { newData } = this.state;

    if (type === 'metric1') {
      if (newData.metric2 && newData.metric2 !== '-') {
        // save as new existing
        addAnalyticsTemplate({...newData, [type]: e.target.value });
        this.setState({ newData: { metric1: '-', metric2: '-' } });
      } else {
        this.setState({ newData: { ...newData, [type]:  e.target.value } });
      }
    } else if (type === 'metric2') {
      if (newData.metric1 && newData.metric1 !== '-') {
        // save as new existing
        addAnalyticsTemplate({...newData, [type]: e.target.value });
        this.setState({ newData: { metric1: '-', metric2: '-' } });
      } else {
        this.setState({ newData: { ...newData, [type]:  e.target.value } });
      }
    }
  };

  changeMetric = (e, type, id) => {
    const { editAnalyticsTemplate, adminAnalyticsTemplate } = this.props;

    const val = adminAnalyticsTemplate[id];

    if (val) {
      const newVal = { ...val, [type]: e.target.value };

      editAnalyticsTemplate(id, newVal);
    }
  }

  render() {
    const { dashboardData, adminDashboardStripe, adminDashboardTotals, adminAnalyticsTemplate, removeAnalyticsTemplate } = this.props;
    const { newData } = this.state;

    let newResult;

    if (newData && newData.metric1 && newData.metric2 && newData.metric1 !== '-' && newData.metric2 !== '-') {
      if (newData.metric1 === 'totalCompanies' || newData.metric1 === 'totalCampaigns' || newData.metric1 === 'totalClients') {
        if (newData.metric2 === 'totalCompanies' || newData.metric2 === 'totalCampaigns' || newData.metric2 === 'totalClients') {
          newResult = adminDashboardTotals[newData.metric1] / adminDashboardTotals[newData.metric2];
        } else if (newData.metric2 === 'amountCaptured' || newData.metric2 === 'salesCount' || newData.metric2 === 'commisionTaken' || newData.metric2 === 'amountRefunded') {
          newResult = adminDashboardTotals[newData.metric1] / adminDashboardStripe[newData.metric2];
        } else {
          newResult = adminDashboardTotals[newData.metric1] / dashboardData[newData.metric2];
        }
      } else if (newData.metric1 === 'amountCaptured' || newData.metric1 === 'salesCount' || newData.metric1 === 'commisionTaken' || newData.metric1 === 'amountRefunded') {
        if (newData.metric2 === 'totalCompanies' || newData.metric2 === 'totalCampaigns' || newData.metric2 === 'totalClients') {
          newResult = adminDashboardStripe[newData.metric1] / adminDashboardTotals[newData.metric2];
        } else if (newData.metric2 === 'amountCaptured' || newData.metric2 === 'salesCount' || newData.metric2 === 'commisionTaken' || newData.metric2 === 'amountRefunded') {
          newResult = adminDashboardStripe[newData.metric1] / adminDashboardStripe[newData.metric2];
        } else {
          newResult = adminDashboardStripe[newData.metric1] / dashboardData[newData.metric2];
        }
      } else {
        if (newData.metric2 === 'totalCompanies' || newData.metric2 === 'totalCampaigns' || newData.metric2 === 'totalClients') {
          newResult = dashboardData[newData.metric1] / adminDashboardTotals[newData.metric2];
        } else if (newData.metric2 === 'amountCaptured' || newData.metric2 === 'salesCount' || newData.metric2 === 'commisionTaken' || newData.metric2 === 'amountRefunded') {
          newResult = dashboardData[newData.metric1] / adminDashboardStripe[newData.metric2];
        } else {
          newResult = dashboardData[newData.metric1] / dashboardData[newData.metric2];
        }
      }
    }

      return (
        <>
          <Row>
            <Col sm="12">
              Analytics
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Row className="analytics-row">
                <Col sm="4">
                  <Input name="chartFilter" type="select" className="form-control" value={newData.metric1} onChange={(e) => this.changeNewMetric(e, 'metric1')}>
                    <option disabled readonly value="-">Select</option>
                    <option value="salesValue">Face value sales(£)</option>
                    <option value="amountCaptured">Sales(£)</option>
                    <option value="salesCount">units(#)</option>
                    <option value="commisionTaken">Commission(£)</option>
                    <option value="amountRefunded">Refunds(£)</option>
                    <option value="redemptionsCount">Redemptions(#)</option>
                    <option value="redemptionsValue">Redemptions(£)</option>
                    <option value="giftedCount">Gifted(#)</option>
                    <option value="giftedValue">Gifted(£)</option>
                    <option value="newCompanies">Companies – new(#)</option>
                    <option value="totalCompanies">Companies – total(#)</option>
                    <option value="newCampaigns">Campaigns – new(#)</option>
                    <option value="totalCampaigns">Campaigns – total(£)</option>
                    <option value="newClients">Customers – new(#)</option>
                    <option value="totalClients">Customers – total(£)</option>

                  </Input>
                </Col>
                <Col sm="1" className="admin-dashboard-analytics-text">
                  per
                </Col>
                <Col sm="4">
                  <Input name="chartFilter" type="select" className="form-control" value={newData.metric2} onChange={(e) => this.changeNewMetric(e, 'metric2')}>
                    <option disabled readonly value="-">Select</option>
                    <option value="salesValue">Face value sales(£)</option>
                    <option value="amountCaptured">Sales(£)</option>
                    <option value="salesCount">units(#)</option>
                    <option value="commisionTaken">Commission(£)</option>
                    <option value="amountRefunded">Refunds(£)</option>
                    <option value="redemptionsCount">Redemptions(#)</option>
                    <option value="redemptionsValue">Redemptions(£)</option>
                    <option value="giftedCount">Gifted(#)</option>
                    <option value="giftedValue">Gifted(£)</option>
                    <option value="newCompanies">Companies – new(#)</option>
                    <option value="totalCompanies">Companies – total(#)</option>
                    <option value="newCampaigns">Campaigns – new(#)</option>
                    <option value="totalCampaigns">Campaigns – total(£)</option>
                    <option value="newClients">Customers – new(#)</option>
                    <option value="totalClients">Customers – total(£)</option>

                  </Input>
                </Col>
                <Col sm="1" className="admin-dashboard-analytics-text">
                  {newResult ? newResult.toFixed(2) : null}
                </Col>
                <Col sm="1">
                </Col>
              </Row>
            </Col>
          </Row>
          {adminAnalyticsTemplate ? (Object.keys(adminAnalyticsTemplate).map((key, index) => {
            const data = adminAnalyticsTemplate[key];
            if (data && data.metric1 && data.metric2) {

              let result;
              if (data.metric1 === 'totalCompanies' || data.metric1 === 'totalCampaigns' || data.metric1 === 'totalClients') {
                if (data.metric2 === 'totalCompanies' || data.metric2 ==='totalCampaigns' || data.metric2 ==='totalClients') {
                  result = adminDashboardTotals[data.metric1] / adminDashboardTotals[data.metric2];
                } else if (data.metric2 === 'amountCaptured' || data.metric2 ==='salesCount' || data.metric2 ==='commisionTaken' || data.metric2 ==='amountRefunded') {
                  result = adminDashboardTotals[data.metric1] / adminDashboardStripe[data.metric2];
                } else {
                  result = adminDashboardTotals[data.metric1] / dashboardData[data.metric2];
                }
              } else if (data.metric1 === 'amountCaptured' || data.metric1 === 'salesCount' || data.metric1 === 'commisionTaken' || data.metric1 === 'amountRefunded') {
                if (data.metric2 === 'totalCompanies' || data.metric2 ==='totalCampaigns' || data.metric2 ==='totalClients') {
                  result = adminDashboardStripe[data.metric1] / adminDashboardTotals[data.metric2];
                } else if (data.metric2 === 'amountCaptured' || data.metric2 ==='salesCount' || data.metric2 ==='commisionTaken' || data.metric2 ==='amountRefunded') {
                  result = adminDashboardStripe[data.metric1] / adminDashboardStripe[data.metric2];
                } else {
                  result = adminDashboardStripe[data.metric1] / dashboardData[data.metric2];
                }
              } else {
                if (data.metric2 === 'totalCompanies' || data.metric2 ==='totalCampaigns' || data.metric2 ==='totalClients') {
                  result = dashboardData[data.metric1] / adminDashboardTotals[data.metric2];
                } else if (data.metric2 === 'amountCaptured' || data.metric2 ==='salesCount' || data.metric2 ==='commisionTaken' || data.metric2 ==='amountRefunded') {
                  result = dashboardData[data.metric1] / adminDashboardStripe[data.metric2];
                } else {
                  result = dashboardData[data.metric1] / dashboardData[data.metric2];
                }
              }

              return(
                <Row className="analytics-row">
                  <Col sm="4">
                    <Input name="chartFilter" type="select" className="form-control" value={data.metric1} onChange={(e) => this.changeMetric(e, 'metric1', key)}>
                      <option value="salesValue">Face value sales(£)</option>
                      <option value="amountCaptured">Sales(£)</option>
                      <option value="salesCount">units(#)</option>
                      <option value="commisionTaken">Commission(£)</option>
                      <option value="amountRefunded">Refunds(£)</option>
                      <option value="redemptionsCount">Redemptions(#)</option>
                      <option value="redemptionsValue">Redemptions(£)</option>
                      <option value="giftedCount">Gifted(#)</option>
                      <option value="giftedValue">Gifted(£)</option>
                      <option value="newCompanies">Companies – new(#)</option>
                      <option value="totalCompanies">Companies – total(#)</option>
                      <option value="newCampaigns">Campaigns – new(#)</option>
                      <option value="totalCampaigns">Campaigns – total(#)</option>
                      <option value="newClients">Customers – new(#)</option>
                      <option value="totalClients">Customers – total(#)</option>

                    </Input>
                  </Col>
                  <Col sm="1" className="admin-dashboard-analytics-text">
                    per
                  </Col>
                  <Col sm="4">
                    <Input name="chartFilter" type="select" className="form-control" value={data.metric2} onChange={(e) => this.changeMetric(e, 'metric2', key)}>
                      <option value="salesValue">Face value sales(£)</option>
                      <option value="amountCaptured">Sales(£)</option>
                      <option value="salesCount">units(#)</option>
                      <option value="commisionTaken">Commission(£)</option>
                      <option value="amountRefunded">Refunds(£)</option>
                      <option value="redemptionsCount">Redemptions(#)</option>
                      <option value="redemptionsValue">Redemptions(£)</option>
                      <option value="giftedCount">Gifted(#)</option>
                      <option value="giftedValue">Gifted(£)</option>
                      <option value="newCompanies">Companies – new (#)</option>
                      <option value="totalCompanies">Companies – total(#)</option>
                      <option value="newCampaigns">Campaigns – new(#)</option>
                      <option value="totalCampaigns">Campaigns – total(#)</option>
                      <option value="newClients">Customers – new(#)</option>
                      <option value="totalClients">Customers – total(#)</option>

                    </Input>
                  </Col>
                  <Col sm="1" className="admin-dashboard-analytics-text">
                    {result ? result.toFixed(2) : 0}
                  </Col>
                  <Col sm="1">
                    <Button onClick={() => { removeAnalyticsTemplate(key) }}>
                      X
                    </Button>
                  </Col>
                </Row>
              )
            }
          })) : (null)}
        </>
      );
  }
}

export default Analytics;
