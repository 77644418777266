import { auth, firestore, functions, storage, firebase } from '../../lib/firebase';
import {
  PERKS,
  UPDATE_PERK,
  REMOVE_PERK,
  SET_PERKS_ENABLED,
} from '../index';

export const resetPerksHighlight = () => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - resetPerksHighlight');

  const user = auth.currentUser;

  console.log(user);

  if (user && user.uid) {
    const userRef = firestore.collection('users').doc(user.uid);

    await userRef.update({
      perksHighlight: true,
    });

    return resolve();
  }
});

export const getPerks = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getPerks');
  const state = getState();

  const perksRef = firestore.collection('perks');
  const snapshot = await perksRef.get();

  if (snapshot.docs && snapshot.docs.length) {
    const data = snapshot.docs;
    const perksData = {};

    snapshot.docs.map((doc, index) => {
      perksData[doc.id] = doc.data();
    });

    console.log('getPerks data', perksData);

    dispatch({
      type: PERKS,
      payload: perksData,
    });

    return resolve(perksData);
  } else {
    console.log('getPerks no data');
    return resolve();
  }
});

export const addPerk = (name, link, description, summary, image, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - addPerk', { name, link, description, summary, image });

  if (!name) return reject('no data');

  const perksRef = firestore.collection('perks');

  await perksRef.add({ name, link, description, summary, image }).then(async (res) => {

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'addPerk',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      name: adminName || null,
      data: { name, link, description, summary, image }
    });

    dispatch({
      type: UPDATE_PERK,
      payload: { data: { name, link, description, summary, image }, id: res.id },
    });
    return resolve('success');
  }).catch(err => {
    return resolve('error');
  });
});


export const updatePerk = (id, name, link, description, summary, image, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - updatePerk', { id, name, link, description, summary, image, adminName });

  if (!id) return reject('no id');
  // if (!value) return reject('no value');

  const perksRef = firestore.collection('perks').doc(id);

  await perksRef.set({ name, link, description, summary, image }, { merge: true }).then(async (res) => {
    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'updatePerk',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      categoryId: id,
      data: { name, link, description, summary, image },
      name: adminName || null,
    });

    dispatch({
      type: UPDATE_PERK,
      payload: { data: { name, link, description, summary, image }, id },
    });

    return resolve('success');
  }).catch(err => {
    console.log(err);
    return resolve('error');
  });
});

export const removePerk = (id) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - removePerk', { id });

  if (!id) return reject('no id');

  const perksRef = firestore.collection('perks').doc(id);

  await perksRef.delete().then((res) => {

    dispatch({
      type: REMOVE_PERK,
      payload: { id: id },
    });
    return resolve('success');
  }).catch(err => {
    return resolve('error');
  });
});

export const getDisablePerksTab = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getDisablePerksTab');
  const state = getState();

  const perksRef = firestore.collection('adminSettings').doc('perksTabData');
  const snapshot = await perksRef.get();

  if (snapshot.exists) {
    const data = snapshot.data();

    console.log('getDisablePerksTab data', { data });

    dispatch({
      type: SET_PERKS_ENABLED,
      payload: data.disabled,
    });

    return resolve(data.disabled);
  } else {
    console.log('getPerks no data');
    return resolve();
  }
});

export const setDisablePerksTab = (value) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - setDisablePerksTab', { value });

  const perksRef = firestore.collection('adminSettings').doc('perksTabData');

  await perksRef.set({ disabled: value || false }, { merge: true }).then((res) => {

    dispatch({
      type: SET_PERKS_ENABLED,
      payload: value,
    });
    return resolve('success');
  }).catch(err => {
    return resolve('error');
  });
});





export const getPerksTabTitle = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getPerksTabTitle');
  const state = getState();

  const perksRef = firestore.collection('adminSettings').doc('perksTabData');
  const snapshot = await perksRef.get();

  if (snapshot.exists) {
    const data = snapshot.data();

    console.log('getPerksTabTitle data', { data });

    dispatch({
      type: SET_PERKS_ENABLED,
      payload: data.title,
    });

    return resolve(data.title);
  } else {
    console.log('getPerks no data');
    return resolve();
  }
});

export const setPerksTabTitle = (value) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - setPerksTabTitle', { value });

  const perksRef = firestore.collection('adminSettings').doc('perksTabData');

  await perksRef.set({ title: value }, { merge: true }).then((res) => {

    dispatch({
      type: SET_PERKS_ENABLED,
      payload: value,
    });
    return resolve('success');
  }).catch(err => {
    return resolve('error');
  });
});