import React from 'react';
import PropTypes from 'prop-types';

import { Button, Card, CardBody } from 'reactstrap';

import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';

const Admin = ({
  roles, type, showNoPermission, sortType, sortColumn, openChangePermissions, toggleDeleteRole, openCreateRole
}) => (
  <>
  <Card>
      <CardBody>
          <div style={{ display: 'inline-block' }}>
            Permission levels
          </div>
          <Button disabled={showNoPermission} className="body-add-button" onClick={() => openCreateRole(type)}>
            +
          </Button>

          <div class="table-responsive">
          <Table
            className="permissions-levels-table"
            sortType={sortType}
            sortColumn={sortColumn}
            data={roles}
            minHeight={590}
            height={590}
            rowKey='uid'
            rowHeight={55}
            rowClassName={(rowData) => {
              return (`${`${(rowData && rowData.newUser) ? ' admin-table-new-user' : ''}`}`)
            }}
          >
            <Column width={180} resizable>
              <HeaderCell>Name</HeaderCell>
              <Cell dataKey="change button">
                {(rowData, rowIndex) => (
                  <>
                    {(rowData.key !== 'Main' && rowData.key !== 'viewOnly') ? (
                      <Button color="link" disabled={showNoPermission} onClick={() => openChangePermissions(rowData.key, rowData.permissions, rowData.name, type, rowData.description)}>
                        {rowData.name}
                      </Button>
                    ):(<div> {rowData.name} </div>)}
                  </>
                )}
              </Cell>
            </Column>

            <Column width={180} resizable>
              <HeaderCell>Description</HeaderCell>
             <Cell dataKey="description" />
            </Column>

            <Column width={120} resizable>
              <HeaderCell>Companies</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.companies === 2 ? ('View & Edit'):(null)}
                        {rowData.permissions.companies === 1 ? ('View only'):(null)}
                        {rowData.permissions.companies === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={100} resizable>
              <HeaderCell>Campaigns</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.campaigns === 2 ? ('View & Edit'):(null)}
                        {rowData.permissions.campaigns === 1 ? ('View only'):(null)}
                        {rowData.permissions.campaigns === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={110} resizable>
              <HeaderCell>Company access</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.companyAccess === 2 ? ('Yes'):(null)}
                        {rowData.permissions.companyAccess === 1 ? ('Yes'):(null)}
                        {rowData.permissions.companyAccess === 0 ? ('No'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={170} resizable>
              <HeaderCell>Company access - Dashboard</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.companyAccessDashboard === 2 ? ('View & Edit'):(null)}
                        {rowData.permissions.companyAccessDashboard === 1 ? ('View only'):(null)}
                        {rowData.permissions.companyAccessDashboard === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={220} resizable>
              <HeaderCell>Company access - Create new voucher</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.companyAccessCreateNewVoucher === 2 ? ('View & Edit'):(null)}
                        {rowData.permissions.companyAccessCreateNewVoucher === 1 ? ('View only'):(null)}
                        {rowData.permissions.companyAccessCreateNewVoucher === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={210} resizable>
              <HeaderCell>Company access - Basic information</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.companyAccessBasicInformation === 2 ? ('View & Edit') : (null)}
                        {rowData.permissions.companyAccessBasicInformation === 1 ? ('View only'):(null)}
                        {rowData.permissions.companyAccessBasicInformation === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={100} resizable>
              <HeaderCell>Customers</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.customers === 2 ? ('View & Edit'):(null)}
                        {rowData.permissions.customers === 1 ? ('View only'):(null)}
                        {rowData.permissions.customers === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={100} resizable>
              <HeaderCell>Dashboard</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.dashboard === 2 ? ('View & Edit'):(null)}
                        {rowData.permissions.dashboard === 1 ? ('View only'):(null)}
                        {rowData.permissions.dashboard === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={100} resizable>
              <HeaderCell>Admins</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.admins === 2 ? ('View & Edit'):(null)}
                        {rowData.permissions.admins === 1 ? ('View only'):(null)}
                        {rowData.permissions.admins === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={110} resizable>
              <HeaderCell>Permission levels</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.permissionLevels === 2 ? ('View & Edit'):(null)}
                        {rowData.permissions.permissionLevels === 1 ? ('View only'):(null)}
                        {rowData.permissions.permissionLevels === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={100} resizable>
              <HeaderCell>payments</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.payments === 2 ? ('View & Edit'):(null)}
                        {rowData.permissions.payments === 1 ? ('View only'):(null)}
                        {rowData.permissions.payments === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={100} resizable>
              <HeaderCell>Greetings cards</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.greetingsCards === 2 ? ('View & Edit'):(null)}
                        {rowData.permissions.greetingsCards === 1 ? ('View only'):(null)}
                        {rowData.permissions.greetingsCards === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={135} resizable>
              <HeaderCell>Content management</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.contentManagement === 2 ? ('View & Edit'):(null)}
                        {rowData.permissions.contentManagement === 1 ? ('View only'):(null)}
                        {rowData.permissions.contentManagement === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={100} resizable>
              <HeaderCell>Setup Help</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.resourceCentre === 2 ? ('View & Edit'):(null)}
                        {rowData.permissions.resourceCentre === 1 ? ('View only'):(null)}
                        {rowData.permissions.resourceCentre === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={100} resizable>
              <HeaderCell>Notifications</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.notifications === 2 ? ('View & Edit'):(null)}
                        {rowData.permissions.notifications === 1 ? ('View only'):(null)}
                        {rowData.permissions.notifications === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
          </Table>
          </div>
      </CardBody>
  </Card>
  </>
);


Admin.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string,
};

Admin.defaultProps = {
  checked: false,
  text: null,
};

export default Admin;
