
import React from 'react';
import PropTypes from 'prop-types';

import { ResponsiveContainer, XAxis, YAxis, Tooltip, AreaChart, Area, CartesianGrid, LabelList } from 'recharts';

import formattedNumber from '../FormattedNumber/FormattedNumber';

function getTicks(array) {
  /* var factor, result, desiredSize = 10;

  for (let i=0; i < array.length; i++) {
    // calculate the delta factor
    factor = Math.floor(array[i].length / desiredSize) + 1;
    factor = (desiredSize % 2 === 0) ? --factor : factor;
    result = [];

    console.log('factor', factor);

    // iterate with the step size of the calculated facor
    for (let j = 0; j < array[i].length; j = j + factor) {
      result.push(array[i][j]);
    }
    console.log('getTicks result', result);
  } */

  const step = Math.ceil(array.length / 10);
//  console.log('step', step);

  let ticks = [];

  array.map((item, index) => {
    if (index % step === 0) {
      console.log('push item', item.key);
      ticks.push(item.key);
    }
  });

//  console.log('ticks', ticks);

  return ticks; //#6FC0F9
}

const CustomizedYTick = (props, data) => {
//  console.log('CustomizedYTick props', props);

  const { payload, yAxisSymbol } = props;

 // console.log('CustomizedYTick yAxisSymbol', yAxisSymbol, yAxisSymbol);

  //`�${payload.value}`;

  if (yAxisSymbol) {
    return (<text y={payload.coordinate + 3} x={-13} font-size="10">{'\u00A3'}{formattedNumber(payload.value)}</text>)
  } else {
    return (<text y={payload.coordinate + 3} x={-3} font-size="10">{formattedNumber(payload.value)}</text>);
  }
}

const renderCustomizedLabel = (props, data, yAxisSymbol) => {
  const { x, y, width, value, index } = props;
  const radius = 10;

  if (index === data.length - 1 && (value || value === 0)) {
    return (
      <g>
        <defs>
          <filter x="0" y="0" width="1" height="1" id="solid">
            <feFlood flood-color="#6FC0F9" />
            <feComposite in="SourceGraphic" operator="xor" />
          </filter>
        </defs>
        <rect x={x + ((width || 1) / 2) - 22} y={y - radius - 1 - 12} width="45" height="20" fill="#6FC0F9" rx="5" ry="5" />
        <text x={x + (width || 1) / 2} y={y - radius - 1} fill="#FFF" textAnchor="middle" dominantBaseline="middle" font-weight="bold">
          {yAxisSymbol ? `${'\u00A3'}${formattedNumber(value)}` : formattedNumber(value)}
          </text>
      </g>
    );
  }

  return null;
};

const AreaGraph = React.memo(({
  data, interval, dataKey, name, grid, fill, isSampleData, yAxisSymbol
}) => (
  <div>
    {console.log('yAxisSymbol', yAxisSymbol)}
  <ResponsiveContainer width='100%' height={270} marginLeft={'auto'} marginRight={'auto'} overflow={'visible'}>
    <AreaChart data={data} margin={{ left: -50, right: 10 }}>
        {console.log('interval', (data.length <= 10) ? 1 : ((data.length / 10) - 1))}
        {/*tick={{ fontSize: 10 }} */}
        <XAxis dataKey="key" angle={0} tick={{ fontSize: 10 }} interval={(data.length <= 10) ? 0 : (Math.ceil(data.length / 10) - 1)} tickCount={10} />

        <YAxis width={80} padding={{ top: 12 }} yAxisId="left" tick={<CustomizedYTick yAxisSymbol={yAxisSymbol} />} >
      </YAxis>

      <Tooltip />
      {grid ? (
        <CartesianGrid vertical={false} />
      ) : (null)}

      {console.log('dataKey', dataKey) }

      <Area isAnimationActive={false} yAxisId="left" type="monotone" dataKey={dataKey} name={name} stroke="#5CB4FF" strokeWidth="2" fillOpacity={1} fill={fill ? fill : '#D4E9F7'}>
          <LabelList dataKey={dataKey} position="top" content={(props) => renderCustomizedLabel(props, data, yAxisSymbol)} />
      </Area >
    </AreaChart>
  </ResponsiveContainer>
    {isSampleData?(
      <div style={{ color: '#7f8fa4', fontSize: 11, fontWeight: 'bold', position: 'absolute', bottom: 60, right: 55 }} > Sample values</div >
    ):(null)}
</div>
));


AreaGraph.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

AreaGraph.defaultProps = {
  checked: false,
};

export default AreaGraph;
