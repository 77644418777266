import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import './vibe/scss/styles.scss';
import './scss/styles.scss';

import { store } from './store/store';

export default function App() {
  console.log('process.env', process.env);
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route component={DashboardLayout} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}
