import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';

import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Label,
  Form,
  FormGroup,
  Alert,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  CardTitle,
  CardSubtitle,
  Spinner,
} from 'reactstrap';

import FileUploader from "react-firebase-file-uploader";

import { connect } from 'react-redux';

import {
  getPerks,
  addPerk,
  updatePerk,
  removePerk,
  setDisablePerksTab,
  getDisablePerksTab,
  getPerksTabTitle,
  setPerksTabTitle,
} from '../../../actions/perks';

import { storage } from '../../../lib/firebase';

class Notifications extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    fetchPerks: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    addPerkFunc: PropTypes.func.isRequired,
    updatePerkFunc: PropTypes.func.isRequired,
    removePerkFunc: PropTypes.func.isRequired,
    setDisablePerks: PropTypes.func.isRequired,
    fetchDisablePerksTab: PropTypes.func.isRequired,
    fetchPerksTabTitle: PropTypes.func.isRequired,
    updatePerksTabTitle: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      enabled: false,
      deleteSettingModal: false,
      deleteSettingErr: null,
      settingToDelete: null,
      activeTab: '1',
      companyNotification: '',
      allChecked: true,
      checked: {},
      companyNotifications: [],
      companyInformation: '',
    };
  }

  componentDidMount() {
    const { fetchPerks, user, isLoggedIn, history } = this.props;

    if (user && Object.keys(user).length > 0) {
      if (!user || !isLoggedIn || !user || !user.roleClass || user.roleClass !== 'admin') {
        history.push('/');
      } else {
        if (user.role === 'admin') {
          this.setState({ showNoPermission: false, dontShowPage: false });
        } else {
          const { adminsRoles } = this.props;

          if (adminsRoles && adminsRoles[user.role] && adminsRoles[user.role].permissions && adminsRoles[user.role].permissions.notifications) {
            if (adminsRoles[user.role].permissions.notifications === 2) {
              this.setState({ showNoPermission: false, dontShowPage: false });
            } else {
              this.setState({ showNoPermission: true, dontShowPage: false });
            }
          } else {
            this.setState({ showNoPermission: true, dontShowPage: true });
          }
        }

        this.fetchPerks();
        this.fetchDisablePerksTab();
        this.fetchPerksTabTitle();
      }
    } else {
      history.push('/');
    }
  }

  fetchPerks = () => {
    const { fetchPerks } = this.props;

    fetchPerks().then((res2) => {
      let perks = {};

      console.log('fetchPerks', res2);
      if (res2) {
        Object.keys(res2).map((key, index) => {
          perks[key] = res2[key];
          return null;
        });
      }

      this.setState({ perks });
    })
  }

  fetchDisablePerksTab = () => {
    const { fetchDisablePerksTab } = this.props;

    fetchDisablePerksTab().then((res2) => {
      console.log('fetchDisablePerksTab', res2);

      this.setState({ disablePerks: res2 });
    })
  }

  fetchPerksTabTitle = () => {
    const { fetchPerksTabTitle } = this.props;

    fetchPerksTabTitle().then((res) => {
      console.log('fetchPerksTabTitle', res);

      this.setState({ perksTabTitle: res });
    })
  }

  setDisablePerks = () => {
    const { setDisablePerks } = this.props;
    const { disablePerks } = this.state;

    this.setState({ disableSpinner: true });

    setDisablePerks(!disablePerks).then(() => {
      this.fetchDisablePerksTab();
      this.setState({ disableSpinner: false });
    }).catch(() => {
      this.setState({ disableSpinner: false });
    });
  }

  updatePerksTabTitle = () => {
    const { updatePerksTabTitle } = this.props;
    const { perksTabTitle } = this.state;

    this.setState({ saveSpinner: true });

    updatePerksTabTitle(perksTabTitle).then(() => {
      this.fetchPerksTabTitle();
      this.setState({ saveSpinner: false });
    }).catch(() => {
      this.setState({ saveSpinner: false });
    });
  }

  handleChange = e => this.setState({ error: null, [e.target.name]: e.target.value });

  handleChangeEnabled = e => this.setState({ error: null, enabled: e.target.checked });

  handleChangeCompanyInformation = e => this.setState({ error: null, companyInformation: e.target.value });

  updateCompanyInformation = () => {
    const { updateCompanyInformation } = this.props;
    const { companyInformation } = this.state;

    if (companyInformation) {
      updateCompanyInformation(companyInformation);
    }
  }

  toggleEnabled = (id) => {
    console.log(id);
  }

  selectTrigger = (e) => {
    this.setState({ error: null, trigger: e.target.value });
  }

  openCreatePerk = () => {
    this.setState({
      error: null,
      createPerkModal: true,
      newName: null,
      newLink: null,
      newDescription: null,
      newSummary: null,
      newImage: null,
      editId: null,
    });
  }

  closeCreatePerk = () => {
    this.setState({ createPerkModal: false });
  }


  openEditPerk = (e, id) => {
    e.preventDefault();
    const { perks } = this.state;

    if (perks && perks[id]) {
      const perk = perks[id];

      console.log('perk', perk, id);

      // newName, newLink, newDescription, newSummary, newImage

      this.setState({
        editPerkModal: true,
        newName: perk.name,
        newLink: perk.link,
        newDescription: perk.description,
        newSummary: perk.summary,
        newImage: perk.image,
        editId: id,
      });
    }
  }

  closeEditPerk = () => {
    this.setState({ editPerkModal: false });
  }

  editPerk = () => {
    const { updatePerkFunc } = this.props;
    const { newName, newLink, newDescription, newSummary, newImage, editId } = this.state;

    console.log('updatePerkFunc', newName, newLink, newDescription, newSummary, newImage, editId);

    updatePerkFunc(editId, newName, newLink, newDescription, newSummary, newImage).then(res => {
      console.log('updatePerkFunc', res);

      this.fetchPerks();
      this.setState({ deletePerkModal: false, createPerkModal: false, editPerkModal: false, newName: null, newLink: null, newDescription: null, newSummary: null, newImage: null  });
    }).catch(err => {
      console.log(err);
    })
  }

  toggleDeletePerk = (e, id) => {
    e.stopPropagation();

    const { deletePerkModal } = this.state;
    if (deletePerkModal) {
      this.setState({ deletePerkModal: false, settingToDelete: null, deleteSettingErr: null });
    } else {
      this.setState({ deletePerkModal: true, settingToDelete: id, deleteSettingErr: null });
    }
  }

  openDeletePerkModal = (id) => {
    this.setState({ deletePerkModal: true, perkToDelete: id, deleteSettingErr: null });
  }

  closeDeletePerk = () => {
    this.setState({ deletePerkModal: false, perkToDelete: null, deleteSettingErr: null });
  }


  createPerk = () => {
    const { addPerkFunc } = this.props;
    const { newName, newLink, newDescription, newSummary, newImage } = this.state;

    console.log('addNotification', newName, newLink, newDescription, newSummary, newImage);

    if (!newName || !newLink || !newDescription || !newSummary) {
      this.setState({ error: "Missing data" });

      return null;
    }

    this.setState({ createSpinner: true });

    addPerkFunc(newName, newLink, newDescription, newSummary, newImage).then((res) => {
      console.log('addNotification res', res);
      if (res === 'success') {
        this.fetchPerks();
      }
      this.setState({ createSpinner: false, deletePerkModal: false, createPerkModal: false, editPerkModal: false, newName: null, newLink: null, newDescription: null, newSummary: null, newImage: null });
    }).catch(err => {
      console.log(err);

      if (err === 'no data') {
        this.setState({ createSpinner: false, error: err });
      } else if (err === 'no companies') {
        this.setState({ createSpinner: false, error: err });
      } else {
        this.setState({ createSpinner: false, error: err });
      }
    });
  }

  deletePerk = (perkKey) => {
    const { removePerkFunc } = this.props;
    const { perkToDelete } = this.state;

    if (perkKey) {
      removePerkFunc(perkKey).then((res) => {
        if (res === 'success') {
          this.fetchPerks();
          this.setState({ deletePerkModal: false, createPerkModal: false, editPerkModal: false, newName: null, newLink: null, newDescription: null, newSummary: null, newImage: null });
        } else {
          this.setState({ error: 'Failed to delete perk' });
        }
      });
    }
  }

  goToLink = (link) => {
    const { history } = this.props;

    console.log('goToLink', link);

    window.open(link, '_blank').focus();
  }

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = progress => this.setState({ progress });

  handleUploadError = error => {
    this.setState({ isUploading: false, error });
    console.error(error);
  };

  handleUploadSuccess = filename => {
    console.log('filename', filename);
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    storage
      .ref("perksImages")
      .child(filename)
      .getDownloadURL()
      .then(url => {
        console.log('perksImages url', url);
        this.setState({ newImage: url, filename });
      });
  };

  render() {
    const { emailSettings, user } = this.props;
    const {
      editPerkModal,
      createPerkModal,
      trigger,
      subject,
      content,
      enabled,
      triggers,
      deletePerkModal,
      deleteSettingErr,
      activeTab,
      showNoPermission,
      dontShowPage,
      companiesListOpen,
      error,
      companyInformation,
      perks,
      newName,
      newLink,
      newDescription,
      newSummary,
      newImage,
      disablePerks,
      perksTabTitle,
      disableSpinner,
      saveSpinner,
      createSpinner,
    } = this.state;

    if (!user.role || user.roleClass !== 'admin') {
      return (
        <div>
          <Card>
            <CardBody>
              You need admin permissions to view Email settings
            </CardBody>
          </Card>
        </div>
      );
    }

    if (dontShowPage) {
      return (
        <div>
          <Card>
            <CardBody>
              You need admin permissions to view Email settings
            </CardBody>
          </Card>
        </div>
      );
    }

    console.log('perks', perks);

    return (
      <div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row style={{ marginBottom: 15 }}>
              <Col sm="12">
                <div className="body-title">
                  Title seen by companies in Perks tab
                </div>
                <Input
                  type="text"
                  name="perksTabTitle"
                  id="perksTabTitle"
                  value={perksTabTitle}
                  onChange={this.handleChange}
                />
                <Button disabled={showNoPermission} className="body-add-button" onClick={this.updatePerksTabTitle}>
                  Save
                  {saveSpinner ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                  ) : (null)}
                </Button>
              </Col>
            </Row>
            <Row style={{ marginBottom: 15 }}>
              <Col>
                <Button disabled={showNoPermission} className="body-add-button" onClick={this.openCreatePerk}>
                  Add new perk
                </Button>

                <Button disabled={showNoPermission} color={disablePerks ? 'danger' : 'success'} className="body-add-button" onClick={this.setDisablePerks}>
                  {disablePerks ? 'Enable' : 'Disable'}
                  {disableSpinner ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                  ) : (null)}
                </Button>
              </Col>
            </Row>
            <div>
              <Row>
                {perks ? Object.keys(perks).map(perkKey => (
                  <Col xs="12" md="6" lg="4" xl="3">
                    <Card
                      className="perks-card"
                      onClick={() => this.goToLink(perks[perkKey].link)}
                    >
                      <div style={{ borderBottom: '1px solid #EEEEEE', background: '#FFF' }}>
                        <img
                          alt={perks[perkKey].name}
                          src={perks[perkKey].image}
                          className="perks-card-image"
                        />
                      </div>
                      <CardBody style={{ background: '#F4F6FB' }}>
                        <CardTitle tag="h5" style={{ color: '#000', textDecoration: 'none' }}>
                          {perks[perkKey].name}
                        </CardTitle>
                        <CardSubtitle
                          style={{ color: '#222', fontWeight: 'bold' }}
                          className="mb-2 text-muted"
                          tag="h6"
                        >
                          {perks[perkKey].description}
                        </CardSubtitle>
                        <CardText style={{ color: '#222' }}>
                          {perks[perkKey].summary}
                        </CardText>
                      </CardBody>
                    </Card>
                    <Button disabled={showNoPermission} className="body-add-button" onClick={(e) => this.openEditPerk(e, perkKey)}>
                      Edit
                    </Button>
                    <Button disabled={showNoPermission} className="body-add-button" onClick={(e) => this.deletePerk(perkKey)}>
                      Delete
                    </Button>
                    
                  </Col>
                )) : null}
              </Row>
            </div>
          </TabPane>
        </TabContent>

        <Modal isOpen={createPerkModal} toggle={this.closeCreatePerk}>
          <ModalHeader toggle={this.closeCreatePerk}>
            Create new perk
          </ModalHeader>
          <ModalBody>
            {(!!error) && <Alert color="danger">{error}</Alert>}
            <Form>
              <FormGroup>
                <Label for="newName">
                  Title<div className="mandatory">*</div>
                </Label>
                <Input
                  type="text"
                  name="newName"
                  id="newName"
                  value={newName}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="newLink">
                  Link<div className="mandatory">*</div>
                  <div>link needs to include https:// in front</div>
                </Label>
                <Input
                  type="text"
                  name="newLink"
                  id="newLink"
                  value={newLink}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="newDescription">
                  Description<div className="mandatory">*</div>
                </Label>
                <Input
                  type="textarea"
                  name="newDescription"
                  id="newDescription"
                  value={newDescription}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="newSummary">
                  Offer Summary<div className="mandatory">*</div>
                </Label>
                <Input
                  type="textarea"
                  name="newSummary"
                  id="newSummary"
                  value={newSummary}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="newImage">
                  Image<div className="mandatory">*</div>
                  <div>Aspect ratio of 16:10 needed</div>
                </Label>
                {this.state.newImage ? (
                  <img
                    alt="uploadedImage"
                    src={this.state.newImage}
                    className="perks-modal-image"
                    style={{ maxWidth: 150, width: '60%', marginLeft: '20%', marginRight: '20%', objectFit: 'contain', aspectRatio: 1 }}
                  />
                ) : null}
                {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
                <FileUploader
                  accept="image/*"
                  name="image"
                  randomizeFilename
                  storageRef={storage.ref("perksImages")}
                  onUploadStart={this.handleUploadStart}
                  onUploadError={this.handleUploadError}
                  onUploadSuccess={this.handleUploadSuccess}
                  onProgress={this.handleProgress}
                />
              </FormGroup>

              <div> <div className="mandatory">*</div>Mandatory fields </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.createPerk}>
              Create
              {createSpinner ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ) : (null)}
            </Button>
            <Button color="secondary" onClick={this.closeCreatePerk}>Close</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={editPerkModal} toggle={this.closeEditPerk}>
          <ModalHeader toggle={this.closeEditPerk}>
            Edit email setting
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="newName">
                  Title<div className="mandatory">*</div>
                </Label>
                <Input
                  type="text"
                  name="newName"
                  id="newName"
                  value={newName}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="newLink">
                  Link<div className="mandatory">*</div>
                  <div>link needs to include https:// in front</div>
                </Label>
                <Input
                  type="text"
                  name="newLink"
                  id="newLink"
                  value={newLink}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="newDescription">
                  Description<div className="mandatory">*</div>
                </Label>
                <Input
                  type="textarea"
                  name="newDescription"
                  id="newDescription"
                  value={newDescription}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="newSummary">
                  Offer Summary<div className="mandatory">*</div>
                </Label>
                <Input
                  type="textarea"
                  name="newSummary"
                  id="newSummary"
                  value={newSummary}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="newImage">
                  Image<div className="mandatory">*</div>
                  <div>Aspect ratio of 16:10 needed</div>
                </Label>
                {this.state.newImage ? (
                  <img
                    alt="uploadedImage"
                    src={this.state.newImage}
                    className="perks-modal-image"
                    style={{ maxWidth: 150, width: '60%', marginLeft: '20%', marginRight: '20%', objectFit: 'contain', aspectRatio: 1 }}
                  />
                ) : null}
                {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
                <FileUploader
                  accept="image/*"
                  name="image"
                  randomizeFilename
                  storageRef={storage.ref("perksImages")}
                  onUploadStart={this.handleUploadStart}
                  onUploadError={this.handleUploadError}
                  onUploadSuccess={this.handleUploadSuccess}
                  onProgress={this.handleProgress}
                />
              </FormGroup>
              <div> <div className="mandatory">*</div>Mandatory fields </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.editPerk}>Edit</Button>
            <Button color="secondary" onClick={this.closeEditPerk}>Close</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={deletePerkModal} toggle={this.closeDeletePerk}>
          <ModalHeader toggle={this.closeDeletePerk}>
            Are you sure you want to delete this email setting?
          </ModalHeader>
          <ModalBody>
            {(!!deleteSettingErr) && <Alert color="danger">{deleteSettingErr}</Alert>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.deletePerk}>Delete Email Setting</Button>
            <Button color="secondary" onClick={this.closeDeletePerk}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  emailSettings: state.notifications.emailSettings || {},
  adminsRoles: state.admin.adminsRoles || {},
});

const mapDispatchToProps = {
  fetchPerks: getPerks,
  addPerkFunc: addPerk,
  updatePerkFunc: updatePerk,
  removePerkFunc: removePerk,
  setDisablePerks: setDisablePerksTab,
  fetchDisablePerksTab: getDisablePerksTab,
  fetchPerksTabTitle: getPerksTabTitle,
  updatePerksTabTitle: setPerksTabTitle,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications));

// export default Dashboard;
