import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Card, CardBody, Table, Button, Input, Label, Form, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane, Alert } from 'reactstrap';

import Videos from './Videos';
import ResourceCentreText from './ResourceCentreText';
import TopLinks from './TopLinks';
import FAQ from './FAQ';
// import Contract from './Contract';

import { connect } from 'react-redux';

import {
  resetRCHighlight,
} from '../../../../actions/resourceCenter';

class ResourceCenter extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    resetHighlight: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      activeTab: '1',
    };
  }

  componentDidMount() {
    const { user, resetHighlight } = this.props;

    if (user && user.company && !user.rcHighlight) {
      resetHighlight();
    }
  }

  toggle = (tab) => {
    const { activeTab } = this.state;

    if (activeTab !== tab) {

      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { user } = this.props;
    const {
      name,
      activeTab,
    } = this.state;

    console.log('user', user);

    return (
      <div>
       {/* <TabContent activeTab={activeTab} style={{ marginBottom: 15, paddingBottom: 5 }}>
          <TabPane tabId="1">
            <TopLinks />
          </TabPane>
        </TabContent> */}
        <TabContent activeTab={activeTab} style={{ marginBottom: 15, paddingBottom: 5 }}>
          <TabPane tabId="1">
            <ResourceCentreText />
          </TabPane>
        </TabContent>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Videos />
          </TabPane>
        </TabContent>
    {/*  <Nav tabs>
          <NavItem>
            <NavLink
              className={`${activeTab === '1' ? 'active' : null}`}
              onClick={() => { this.toggle('1'); }}
            >
              Videos
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${activeTab === '2' ? 'active' : null}`}
              onClick={() => { this.toggle('2'); }}
            >
              FAQ
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Videos />
          </TabPane>
          <TabPane tabId="2">
            <FAQ />
          </TabPane>
        </TabContent>  */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
});

const mapDispatchToProps = {
  resetHighlight: resetRCHighlight,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResourceCenter));

// export default Dashboard;
