import React from 'react';
import PropTypes from 'prop-types';

import { Button, Card, CardBody } from 'reactstrap';

import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';

const Company = React.memo(({
  roles, type, showNoPermission, sortType, sortColumn, openChangePermissions, toggleDeleteRole, openCreateRole,
}) => (
  <>
  <Card>
      <CardBody>
          <div style={{ display: 'inline-block' }}>
            Permission levels
          </div>
          <Button disabled={showNoPermission} className="body-add-button" onClick={() => openCreateRole(type)}>
            +
          </Button>

          <div class="table-responsive">
          <Table
            className="permissions-levels-table"
            sortType={sortType}
            sortColumn={sortColumn}
            data={roles}
            minHeight={590}
            height={590}
            rowKey='uid'
          //  rowHeight={55}
            rowHeight={46}
            rowClassName={(rowData) => {
              return (`${`${(rowData && rowData.newUser) ? ' admin-table-new-user' : ''}`}`)
            }}
          >
            <Column width={180} resizable>
              <HeaderCell>Name</HeaderCell>
              <Cell dataKey="change button">
                {(rowData, rowIndex) => (
                  <>
                    {(rowData.key !== 'Main' && rowData.key !== 'viewOnly') ? (
                      <Button color="link" disabled={showNoPermission} onClick={() => openChangePermissions(rowData.key, rowData.permissions, rowData.name, type, rowData.description)}>
                        {rowData.name}
                      </Button>
                    ):(<div> {rowData.name} </div>)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={180} resizable>
              <HeaderCell>Description</HeaderCell>
             <Cell dataKey="description" />
            </Column>

            <Column width={120} resizable>
              <HeaderCell>Dashboard</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {/*  <Checkbox checked={(rowData.permissions && rowData.permissions.dashboard) ? true : false} readOnly />  */}
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.dashboard === 2 ? ('View & Edit'):(null)}
                        {rowData.permissions.dashboard === 1 ? ('View only'):(null)}
                        {rowData.permissions.dashboard === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={135} resizable>
              <HeaderCell>Create new vouchers</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.createNewVoucher === 2 ? ('View & Edit'):(null)}
                        {rowData.permissions.createNewVoucher === 1 ? ('View only'):(null)}
                        {rowData.permissions.createNewVoucher === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={115} resizable>
              <HeaderCell>Basic information</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.basicInformation === 2 ? ('View & Edit'):(null)}
                        {rowData.permissions.basicInformation === 1 ? ('View only'):(null)}
                        {rowData.permissions.basicInformation === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={100} resizable>
              <HeaderCell>Setup Help</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.resourceCentre === 2 ? ('View & Edit'):(null)}
                        {rowData.permissions.resourceCentre === 1 ? ('View only'):(null)}
                        {rowData.permissions.resourceCentre === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={100} resizable>
              <HeaderCell>Contact</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.contact === 2 ? ('View & Edit'):(null)}
                        {rowData.permissions.contact === 1 ? ('View only'):(null)}
                        {rowData.permissions.contact === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={100} resizable>
              <HeaderCell>Payments</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.payments === 2 ? ('View & Edit'):(null)}
                        {rowData.permissions.payments === 1 ? ('View only'):(null)}
                        {rowData.permissions.payments === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={100} resizable>
              <HeaderCell>Users</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.users === 2 ? ('View & Edit'):(null)}
                        {rowData.permissions.users === 1 ? ('View only'):(null)}
                        {rowData.permissions.users === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
            <Column width={100} resizable>
              <HeaderCell>API</HeaderCell>
              <Cell dataKey="details button">
                {(rowData, rowIndex) => (
                  <>
                    {rowData.permissions ? (
                      <>
                        {rowData.permissions.api === 2 ? ('View & Edit'):(null)}
                        {rowData.permissions.api === 1 ? ('View only'):(null)}
                        {rowData.permissions.api === 0 ? ('No Access'):(null)}
                      </>
                    ):(null)}
                  </>
                )}
              </Cell>
            </Column>
          </Table>
          </div>
      </CardBody>
  </Card>
  </>
));


Company.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string,
};

Company.defaultProps = {
  checked: false,
  text: null,
};

export default Company;
