
import React from 'react';
import PropTypes from 'prop-types';

import LineGraph from './LineGraph';
import BarGraph from './BarGraph';
import MultiGraph from './MultiGraph';
import AreaGraph from './AreaGraph';

const DashboardGraph = React.memo(({
  type, data, interval, dataKey, name, grid, fill, isSampleData, yAxisSymbol,
}) => {
  if (type === 'line') {
    return (<LineGraph isSampleData={isSampleData} data={data} interval={interval} dataKey={dataKey} name={name} grid={grid} />)
  }

  if (type === 'bar') {
    return (<BarGraph isSampleData={isSampleData} data={data} interval={interval} dataKey={dataKey} name={name} grid={grid} />)
  }

  if (type === 'multi') {
    return (<MultiGraph isSampleData={isSampleData} data={data} interval={interval} dataKeys={dataKey} name={name} grid={grid} fill={fill} />)
  }

  if (type === 'area') {
    return (<AreaGraph isSampleData={isSampleData} data={data} interval={interval} dataKey={dataKey} name={name} grid={grid} yAxisSymbol={yAxisSymbol} />)
  }
});


DashboardGraph.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

DashboardGraph.defaultProps = {
  checked: false,
};

export default DashboardGraph;
