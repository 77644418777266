import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import * as Feather from 'react-feather';
import { Label } from 'reactstrap';
// import ReactTooltip from 'react-tooltip';
import { Popover, ArrowContainer } from 'react-tiny-popover';

import { matchPath, Link } from 'react-router-dom';

import NavBadge from './NavBadge';

const NavSingleItemWithPendingVoucher = ({ setupDone, isActive, item, count, branding, shadeColor, location, isCompanyPerksTab }) => {
  const Icon = item.icon && Feather[item.icon] ? Feather[item.icon] : null;

  /*
  let isOpen = true;
  const setIsOpen = (val) => {
    console.log('setIsOpen', val);
    isOpen = val;
  } */
//  console.log('NavSingleItemWithPendingVoucher isActive', isActive);

  const [isOpen, setIsOpen] = useState(true);

  if (item.external) {
    const rel = item.target && item.target === '_blank' ? 'noopener noreferrer' : null;

    return (
      <li className="nav-item nav-item-withCounter">
        <a href={item.url} target={item.target} rel={rel}>
          {item.icon && Icon && <Icon className="side-nav-icon" />}
          <span className="nav-item-label">{item.name}</span>
          {item.badge && <NavBadge color={item.badge.variant} text={item.badge.text} />}
        </a>
      </li>
    );
  } else {
    // Force relative URLs to start with a slash
    const url = item.url.charAt(0) === '/' ? item.url : `/${item.url}`;

    let isActiveLink = false;
    if (location === item.url) {
      isActiveLink = true;
    }

    let bgColor = '#0b1628';

    if (branding.sidebarPrimaryColor) {
      bgColor = branding.sidebarPrimaryColor;
    }

    if (isCompanyPerksTab && branding.perksBackgroundColor) {
      bgColor = branding.perksBackgroundColor;
    }

    let isActive2 = true;

    return (
      <>
        <Popover
          positions={['right']}
          padding={10}
          isOpen={isOpen}
          onClick={() => setIsOpen(false)}
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor={'#14c2ff'}
              arrowSize={6}
              arrowStyle={{ opacity: 0.9 }}
            >
              <div
                style={{
                  backgroundColor: '#14c2ff',
                  color: '#FFF',
                  opacity: 0.9,
                  padding: 5,
                  paddingRight: 25,
                  paddingLeft: 15,
                  borderRadius: 2,
                  fontFamily: 'system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                  fontSize: 15,
                }}
                onClick={() => setIsOpen(false)}
              >
                <div style={{ display: 'inline-block', paddingRight: 3 }}>Start selling vouchers </div>
              </div>
            </ArrowContainer>
          )}
        >

          <li className={`nav-item nav-item-withCounter ${isActive ? 'box' : ''}`}>
            <NavLink
              to={url}
              style={{
                color: (isCompanyPerksTab && branding.perksTextColor) ? branding.perksTextColor : branding.sidebarTextColor,
                background: (isActive || isActiveLink) ? shadeColor(bgColor, 20) : bgColor,
              }}
              activeClassName="active"

            >
              {item.icon && Icon && <Icon className="side-nav-icon" />}
              <span className="nav-item-label">{item.name}</span>
              {item.badge && <NavBadge color={item.badge.variant} text={item.badge.text} />}
            </NavLink>
          </li>
        </Popover>
      </>
    );
  }
};

export default NavSingleItemWithPendingVoucher;
