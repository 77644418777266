import React, { Component } from 'react';
import ToggleSidebarButton from './components/ToggleSidebarButton';
import PageLoader from '../PageLoader/PageLoader';

import ReactTooltip from 'react-tooltip';

import { Alert, Button, Navbar, NavbarToggler, Collapse, Nav, Media, Label } from 'reactstrap';
import { matchPath, Link } from 'react-router-dom';

// import questionmark from '../../../assets/images/questionmark.svg';

export default class Header extends Component {
  constructor(props) {
    super(props);

    const hidden = this.getPageHidden();

    this.state = {
      isOpen: false,
      hidden: hidden,
    };
  }

  componentDidUpdate(prevProps) {
    const { hidden } = this.state;

    const newHidden = this.getPageHidden();

    if (hidden !== newHidden) {
      this.setState({ hidden: newHidden });
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  getPageTitle = () => {
    const { perksDisabled, perkTitle } = this.props;

    let name;

  //  console.log('getPageTitle', perksDisabled, perkTitle, this.props.location.pathname);

    this.props.routes.map(prop => {
      if (
        matchPath(this.props.location.pathname, {
          path: prop.path,
          exact: true,
          strict: false
        })
      ) {
        if (this.props.location.pathname === '/perks' && perkTitle) {
          name = perkTitle;
        } else {
          name = prop.name;
        }
      }
      return null;
    });

    if (name === 'Api') {
      name = 'API';
    }

    return name;
  };

  getPageHidden = () => {
    let hidden;
    this.props.routes.map(prop => {
      if (
        matchPath(this.props.location.pathname, {
          path: prop.path,
          exact: true,
          strict: false
        })
      ) {
        hidden = prop.hidden;
      }
      return null;
    });

    return hidden;
  };

  getPageTooltip = () => {
    const { companyTabsTooltips } = this.props;

    let name;
    this.props.routes.map(prop => {
      if (
        matchPath(this.props.location.pathname, {
          path: prop.path,
          exact: true,
          strict: false
        })
      ) {
        name = prop.name;
      }
      return null;
    });

    if (name === 'Website Connection') {
      name = 'api';
    } else if (name === 'Dashboard') {
      name = 'dashboard';
    } else if (name === 'Create New Campaign') {
      name = 'createnewcampaign';
    } else if (name === 'Basic Information') {
      name = 'basicinformation';
    } else if (name === 'Payments') {
      name = 'payments';
    } else if (name === 'Users') {
      name = 'users';
    } else if (name === 'Setup Help') {
      name = 'setupHelp';
    } else if (name === 'Contact') {
      name = 'contact';
    }

    if (companyTabsTooltips && companyTabsTooltips[name]) {
      return companyTabsTooltips[name].value;
    } else {
      return name;
    }
  }

  leaveCompanyDashboard = () => {
    const { leaveCompanyDashboard, history } = this.props;

    leaveCompanyDashboard().then(res => {
      if (res) {
        if (res === '/admin/companies' || res === '/admin/campaigns') {
          history.push(res);
        }
      }
    });
  }

  openLink = (link) => {
  //  console.log('openLink', link);

    if (link) {
      window.open(link, '_blank').focus();
    }
  }

  copyLink = (link) => {
    try {
      navigator.clipboard.writeText(link).then(() => {
        this.setState({ copied: true });
      }, () => {
        this.setState({ copied: false });
      });
    } catch(err) {
      console.log(err);
    }
  }

  toggleOnClick = (e) => {
    const { toggleSidebar } = this.props;
    console.log('toggleOnClick');

    // e.preventDefault();
    console.log('call toggleSideCollapse');
    toggleSidebar();
  }

  render() {
    const { isLoggedIn, branding, companyTabsTooltips, isCompanyHeader, company, user, adminViewAsCompany, adminViewAsCompanyName, complianceData, signposts, isSidebarCollapsed } = this.props;
    const { hidden, copied } = this.state;

  //  console.log('Header props', this.props, signposts);

    let basicInformationSetup = false;

    let complianceDataQ1 = false;
    let complianceDataQ2 = false;
    let complianceDataQ3 = false;
    let complianceDataQ4 = false;
    let complianceDataQ5 = false;

    if (complianceData) {
      if (complianceData.question1 || complianceData.question1 === false) complianceDataQ1 = true;
      if (complianceData.question2 || complianceData.question2 === false) complianceDataQ2 = true;
      if (complianceData.question3 || complianceData.question3 === false) complianceDataQ3 = true;
      if (complianceData.question4 || complianceData.question4 === false) complianceDataQ4 = true;
      if (complianceData.question5 || complianceData.question5 === false) complianceDataQ5 = true;
    }

    const { companyName, companyType, companyNumber, website, primaryVoucherImage, logo, category1, description } = company;

    if (company && Object.keys(company) && Object.keys(company).length && company.dateCreated && company.owner) {
      if ((companyName && (companyType && (companyNumber || companyType === 'individual')) && website && primaryVoucherImage && logo && category1 && description
        && complianceDataQ1 && complianceDataQ2 && complianceDataQ3 && complianceDataQ4 && complianceDataQ5)) {
        basicInformationSetup = true;
      }
    }

   // console.log('basicInformationSetup', basicInformationSetup);

    return (
      <header style={{ backgroundColor: branding.headerBackgroundColor ? branding.headerBackgroundColor : '#FFF', opacity: hidden ? 0 : 1 }} className="app-header">
        <SkipToContentLink focusId="primary-content" />
        <div className="top-nav" >
          <Navbar color="faded" light expand="md">
            <ToggleSidebarButton
              toggleSidebar={this.toggleOnClick}
              isSidebarCollapsed={!isSidebarCollapsed}
            />
            {(!isCompanyHeader && !adminViewAsCompany) ? (
              <div>
                {/* <div className="page-heading">
                {this.getPageTitle()}
                {(this.getPageTitle() && isCompanyHeader && companyTabsTooltips) ? (
                  <>
                    <div style={{ display: 'inline-block' }}>
                      <div style={{ position: 'relative', top: -4, color: (branding.sidebarPrimaryColor || '#000'), fontWeight: 700, fontSize: 15, paddingLeft: 4, marginLeft: 4, width: 16, height: 16 }} data-tip data-for='headerTooltip' for="headerTooltip">
                        ?
                      </div>
                    </div>
                    <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='headerTooltip'>
                      <span>{this.getPageTooltip()}</span>
                    </ReactTooltip>
                  </>
                ) : (null)}
                </div> */}
              </div>
            ) : (
              <div className="userDropdown-wrapper" style={{ width: '100%', display: 'inline-block' }}>
                  <Button
                    color={copied ? "success" : "primary"}
                    style={{ fontSize: 12, display: 'inline-block', marginRight: 20, width: 238 }}
                    onClick={() => this.copyLink((signposts && signposts.buttonLink) ? signposts.buttonLink : null)}
                  >
                    {copied ? 'Copied' : (signposts && signposts.buttonText) ? signposts.buttonText : 'Click to copy app share link'}
                  </Button>
                  <Button
                    color="primary"
                    style={{ fontSize: 12, display: 'inline-block', marginRight: 20 }}
                    onClick={() => this.openLink((signposts && signposts.textLink) ? signposts.textLink : null)}
                  >
                    {(signposts && signposts.text) ? signposts.text : 'You can also use these images to signpost us on your website'}
                  </Button>
                  {/* <div style={{ fontSize: 12, display: 'inline-block' }}>
                    {(signposts && signposts.text) ? signposts.text : 'You can also use these images to signpost us on your website'}
                  </div> */}
              </div>
            )}
            {isLoggedIn && (
              <>
                {(adminViewAsCompany && adminViewAsCompanyName) ? (
                  <>
                    <Alert className="page-heading">{`You are viewing company dashboard for ${adminViewAsCompanyName}`}</Alert>
                    <Button onClick={this.leaveCompanyDashboard}> Leave </Button>
                  </>
                ) : (
                  null
                )}
              </>
            )}
            {(isLoggedIn && (adminViewAsCompany || (company && (company.name || company.companyName)))) ? (
              <div className="page-indicators">
                <div style={{ display: 'inline-block' }}>
                  <Link to="/basic-information">
                    <Label style={{ cursor: 'pointer' }}> Basic Information </Label>
                    <div className={`${(basicInformationSetup) ? 'status-indicators-active' : 'status-indicators-inactive'}`} />
                  </Link>
                </div>
                {console.log('company company', company)}
                <div style={{ display: 'inline-block' }}>
                  <Link to="/api">
                    <Label style={{ cursor: 'pointer' }}> Website Connection </Label>
                    {(user.linkedCompaniesAccount || company.linkedCompaniesAccount) ? (
                      <div className={`${(company.allValidApis && company.someValidApis) ? 'status-indicators-active' : ((company.someValidApis) ? 'status-indicators-yellow' : 'status-indicators-inactive')}`} />
                    ): (
                      <div className = {`${(company.validShopify || company.validWoocommerce) ? 'status-indicators-active' : 'status-indicators-inactive'}`} />
                    )}
                    
                  </Link>
                </div>
                <div style={{ display: 'inline-block' }}>
                  <Link to="/payments">
                    <Label style={{ cursor: 'pointer' }}> Payments Connection </Label>
                    <div className={`${company.validStripe ? 'status-indicators-active' : 'status-indicators-inactive'}`} />
                  </Link>
                </div>
              </div>
            ):(null)}
          {/*  <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar className="app-header-collapse">
              <Nav className="ml-auto app-header-collapse-nav" navbar>
                {this.props.children}
              </Nav>
            </Collapse> */}
            <PageLoader />
          </Navbar>
        </div>
      </header>
    );
  }
}

const SkipToContentLink = ({ focusId }) => {
  return (
    <a href={`#${focusId}`} tabIndex="1" className="skip-to-content">
      Skip to Content
    </a>
  );
};
