import React, { Component } from 'react';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const heroStyles = {
  padding: '50px 0 70px'
};

class Dashboard extends Component {

  componentDidMount() {
    const { user, isLoggedIn, history } = this.props;

    if (user && Object.keys(user).length > 0) {

    } else {
    //  console.log('history.push');
    //  history.push('/login');
    }
  }

  render() {
    const { user } = this.props;

    if (user && user.status === 'pending') {
      return (
        <div>
          <Row>
            <Col md={6}>
              <div className="home-hero" style={heroStyles}>
                <h1>Pending page goes here</h1>
                <Button>
                  Contact support
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <div>
        <Row>
          <Col md={6}>
            <div className="home-hero" style={heroStyles}>
              <h1>Home</h1>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
});

export default withRouter(connect(mapStateToProps, null)(Dashboard));

//export default Dashboard;
