import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Row, Col, Card, CardBody, Button } from 'reactstrap';

import Analytics from './Analytics';

import formattedNumber from '../../../components/FormattedNumber/FormattedNumber';

import {
  openCompanyDashboard,
} from '../../../../actions/admin';

class DataFields extends Component {
  static propTypes = {
    image: PropTypes.string,
  };

  static defaultProps = {
    image: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      url: null,
      data: {
        ordersCount: 0,
        salesCount: 0,
        salesValue: 0,
        giftedCount: 0,
        giftedValue: 0,
        redemptionsCount: 0,
        redemptionsValue: 0,
        newCompanies: 0,
        deletedCompanies: 0,
        newCampaigns: 0,
        newClients: 0,
      },
    };
  }

  componentDidUpdate(prevProps) {
    const { adminDashboardCustom, adminDashboardLastUpdate } = this.props;

    if (adminDashboardLastUpdate !== prevProps.adminDashboardLastUpdate) {
      let newData = {};

      Object.keys(adminDashboardCustom).map((key, index) => {
        Object.keys(adminDashboardCustom[key]).map((key2) => {
          if (key2 !== 'date' && key2 !== 'totalCampaignsCount' && key2 !== 'totalClientsCount' && key2 !== 'totalCompaniesCount') {
            newData[key2] = (newData[key2] || 0) + adminDashboardCustom[key][key2];
          }
          return null;
        });
        return null;
      });

      this.setState({ data: newData });
    }
  }

  renderData = (data) => (
    <div className="admin-dashboard-data-field">
      {data.map(val => {
        if (!val || isNaN(val.value)) {
          return (
            <div>
              <span> {val.name} </span>
              <span> </span>
            </div>
          )
        }
        return(
          <div>
            <span> {val.name} </span>
            <span> {val.value} </span>
          </div>
        )}
      )}
    </div>
  )

  renderTopData= (data) => {
    if (!data || !data.length) {
      return null;
    }
    return (
      <div className="admin-dashboard-data-field">
        {data.map(val => {
          if (!val || isNaN(val.sales)) {
            return (
              <Row>
                <Col sm="8"> {val.name || val.id} </Col>
                <Col sm="4"> </Col>
              </Row>
            )
          }
          return(
            <Row>
              <Col sm="8"> {val.name || val.id} </Col>
              <Col sm="4"> {val.sales} </Col>
            </Row>
          )}
        )}
      </div>
    )
  }

  goToCompany = (company) => {
    const { accessCompanyDashboard, history, match } = this.props;

    const { path } = match;

    console.log('goToCompany company', company);

    if (company.id && company.name) {
      accessCompanyDashboard(company.id, company.name, path).then((res) => {
        if (res && res.status && res.status === 'success') {
          history.push('/dashboard');
        }
      })
    }

  }

  render() {
    const {
      //  adminDashboardAgregated,
      //  adminDashboardCustom,
      //  adminDashboardTotals,
      //  adminDashboardStripe,
      adminDashboardStripeAgregated,
      adminDashboardStripeCustom,
      //  adminAnalyticsTemplate,
      //  addAnalyticsTemplate,
      //  editAnalyticsTemplate,
      //  removeAnalyticsTemplate,
      timeFilter,
    } = this.props;

    let topTimeFilter = 'data1Month';
    if (timeFilter === 'day') {
      topTimeFilter = 'data1Day';
    } else if (timeFilter === '1week') {
      topTimeFilter = 'data1Week';
  //  } else if (timeFilter === '2weeks') {
  //    topTimeFilter = 'data2Weeks';
    } else if (timeFilter === '4weeks') {
      topTimeFilter = 'data1Month';
    } else if (timeFilter === 'month') {
      topTimeFilter = 'data1Month';
    } else if (timeFilter === 'year') {
      topTimeFilter = 'data1Year';
    } else if (timeFilter === 'all') {
      topTimeFilter = 'dataAllTime';
    }

    //const { data } = this.state;

    console.log('adminDashboardStripeCustom', adminDashboardStripeCustom);

      return (
        <div className="admin-dashboard-data-fields">
        <Card className="live-metrics-card">
          <CardBody>
          <Row>
            <Col sm="4">
              <div className="admin-dashboard-data-field">
                <Row className="admin-dashboard-data-field-h">
                  <Col sm="6"> Top Companies – Sales (£) </Col>
                  <Col> Sales </Col>
                </Row>
                {(adminDashboardStripeCustom.topCompanies) ? (
                  <>
                    {adminDashboardStripeCustom.topCompanies ? adminDashboardStripeCustom.topCompanies.map((company) => (
                      <>
                        <Row>
                          <Col sm="6"> <Button style={{ fontSize: 13 }} color="link" onClick={() => this.goToCompany(company)}> {company.name} </Button> </Col>
                          <Col> {formattedNumber(company.sales ? company.sales : 0)} </Col>
                          </Row>
                      </>
                    )) : (null)}
                  </>
                ):(null)}
              </div>
            </Col>

            <Col sm="4">
              <div className="admin-dashboard-data-field">
                <Row className="admin-dashboard-data-field-h">
                  <Col sm="6"> Top Companies – Sales (#) </Col>
                  <Col sm="6"> Sales </Col>
                </Row>
                {(adminDashboardStripeCustom.topCompaniesCount) ? (
                  <>
                    {adminDashboardStripeCustom.topCompaniesCount ? adminDashboardStripeCustom.topCompaniesCount.map((company) => (
                      <>
                        <Row>
                          <Col sm="6"> <Button style={{ fontSize: 13 }} color="link" onClick={() => this.goToCompany(company)}> {company.name} </Button> </Col>
                          <Col> {formattedNumber(company.sales ? company.sales : 0)} </Col>
                          </Row>
                      </>
                    )) : (null)}
                  </>
                ):(null)}
              </div>
                </Col>

                <Col sm="4">
                  <div className="admin-dashboard-data-field">
                    <Row className="admin-dashboard-data-field-h">
                      <Col sm="6"> Top Companies – Gifting (#) </Col>
                      <Col sm="6"> Gifting </Col>
                    </Row>
                    {(adminDashboardStripeCustom.topCompaniesGiftCount) ? (
                      <>
                        {adminDashboardStripeCustom.topCompaniesGiftCount ? adminDashboardStripeCustom.topCompaniesGiftCount.map((company) => (
                          <>
                            <Row>
                              {console.log('testing company', company) }
                              <Col sm="6"> <Button style={{ fontSize: 13 }} color="link" onClick={() => this.goToCompany(company)}> {company.name} </Button> </Col>
                              <Col> {formattedNumber(company.sales ? company.sales : 0)} </Col>
                            </Row>
                          </>
                        )) : (null)}
                      </>
                    ) : (null)}
                  </div>
                </Col>

                {/*  <Col sm="3">
              <div className="admin-dashboard-data-field">
                <Row className="admin-dashboard-data-field-h">
                  <Col sm="6"> Top Campaigns – Sales (£) </Col>
                  <Col sm="6"> Sales </Col>
                </Row>
                {(adminDashboardStripeAgregated.data1Day && adminDashboardStripeAgregated.data1Year && adminDashboardStripeCustom.topCampaigns) ? (
                  <>
                    {adminDashboardStripeCustom.topCampaigns ? adminDashboardStripeCustom.topCampaigns.map((camapign) => (
                      <>
                        <Row>
                            <Col sm="6"> {camapign.name} </Col>
                            <Col> {formattedNumber((adminDashboardStripeAgregated[topTimeFilter].salesPerCampaign && adminDashboardStripeAgregated[topTimeFilter].salesPerCampaign[camapign.id]) ? adminDashboardStripeAgregated[topTimeFilter].salesPerCampaign[camapign.id] : 0)} </Col>
                          </Row>
                      </>
                    )) : (null)}
                  </>
                ):(null)}
              </div>
            </Col>

            <Col sm="3">
              <div className="admin-dashboard-data-field">
                <Row className="admin-dashboard-data-field-h">
                  <Col sm="6"> Top Campaigns – Sales (#) </Col>
                  <Col sm="6"> Sales </Col>
                </Row>
                {(adminDashboardStripeAgregated.data1Day && adminDashboardStripeAgregated.data1Year && adminDashboardStripeCustom.topCampaignsCount) ? (
                  <>
                    {adminDashboardStripeCustom.topCampaignsCount ? adminDashboardStripeCustom.topCampaignsCount.map((company) => (
                      <>
                        <Row>
                            <Col sm="6"> {company.name} </Col>
                            <Col> {formattedNumber((adminDashboardStripeAgregated[topTimeFilter].salesCountPerCampaign && adminDashboardStripeAgregated[topTimeFilter].salesCountPerCampaign[company.id]) ? adminDashboardStripeAgregated[topTimeFilter].salesCountPerCampaign[company.id] : 0)} </Col>
                          </Row>
                      </>
                    )) : (null)}
                  </>
                ):(null)}
              </div>
            </Col> */}

          </Row>
        {/*  <Row>
            <Col sm="12">
              <Analytics
                addAnalyticsTemplate={addAnalyticsTemplate}
                editAnalyticsTemplate={editAnalyticsTemplate}
                adminDashboardStripe={adminDashboardStripe}
                dashboardData={data}
                adminDashboardTotals={adminDashboardTotals}
                adminAnalyticsTemplate={adminAnalyticsTemplate}
                removeAnalyticsTemplate={removeAnalyticsTemplate}
              />
            </Col>
          </Row> */}
          </CardBody>
          </Card>
        </div>
      );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
});

const mapDispatchToProps = {
  accessCompanyDashboard: openCompanyDashboard,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataFields));

