import React from 'react';
import { Eye, EyeOff } from 'react-feather';

const CustomSortIcons = (sortType) => {
  console.log('CustomSortIcons', sortType);

  if (sortType === 'desc') {
    return (<Eye />);
  } else {
    return (<EyeOff />);
  }
};

export default CustomSortIcons;
