import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Nav, NavItem, NavLink, Alert, Spinner } from 'reactstrap';

import { connect } from 'react-redux';

import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';

import {
  getAllCompaniesRoles,
  getAllAdminsRoles,
  createRole,
  changeRolePermissions,
  deleteRole,
} from '../../../../actions/admin';

import {
  getUserData,
} from '../../../../actions/user';

import Admin from './Admin';
import Company from './Company';
import CreateModal from './CreateModal';

import notifications from '../../../../const/notifications';

class PermissionLevels extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    getRoles: PropTypes.func.isRequired,
    getAdminRoles: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    createR: PropTypes.func.isRequired,
    changePermissions: PropTypes.func.isRequired,
    deleteR: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      permissions: {},
      activeTab: '1',
      adminPermissions: {
        companies: { name: 'Companies', value: 2 },
        campaigns: { name: 'Campaigns', value: 2 },
        companyAccess: { name: 'Company access capability', value: 2 },
        companyAccessDashboard: { name: 'Dashboard', value: 2 },
        companyAccessCreateNewVoucher: { name: 'Create new vouchers', value: 2 },
        companyAccessBasicInformation: { name: 'Basic information', value: 2 },
        customers: { name: 'Customers', value: 2 },
        dashboard: { name: 'Dashboard', value: 2 },
        admins: { name: 'Admins', value: 2 },
        permissionLevels: { name: 'Permission levels', value: 2 },
        payments: { name: 'Payments', value: 2 },
        greetingsCards: { name: 'Greetings cards', value: 2 },
        contentManagement: { name: 'Content management', value: 2 },
        resourceCentre: { name: 'Setup Help', value: 2 },
        notifications: { name: 'Notifications', value: 2 },
      },
      companyPermissions: {
        dashboard: { name: 'Dashboard', value: 2 },
        createNewVoucher: { name: 'Create new vouchers', value: 2 },
        basicInformation: { name: 'Basic information', value: 2 },
        resourceCentre: { name: 'Setup Help', value: 2 },
        contact: { name: 'Contact', value: 2 },
        payments: { name: 'Payments', value: 2 },
        users: { name: 'Users', value: 2 },
        api: { name: 'API', value: 2 },
      },
      deleteRoleModal: false,
      deleteRoleErr: null,
      roleToDelete: null,
      dontShowPage: false,
    };
  }

  componentDidMount() {
    const { user, isLoggedIn, history, getAdminRoles } = this.props;  //getRoles, getAdminRoles, admin,

    if (user && Object.keys(user).length > 0) {
      if (!user || !isLoggedIn || !user || !user.roleClass || user.roleClass !== 'admin') {
        history.push('/');
      } else {
      if (user.role === 'admin') {
        this.setState({ showNoPermission: false, dontShowPage: false });
      } else {
        getAdminRoles().then(() => {
          const { adminsRoles } = this.props;

          if (adminsRoles && adminsRoles[user.role] && adminsRoles[user.role].permissions && adminsRoles[user.role].permissions.permissionLevels) {

            if (adminsRoles[user.role].permissions.permissionLevels === 2) {
              this.setState({ showNoPermission: false, dontShowPage: false });

            } else {
              this.setState({ showNoPermission: true, dontShowPage: false });
            }
          } else {
            this.setState({ showNoPermission: true, dontShowPage: true });
          }
        })
      }
      }
    } else {
      history.push('/');
    }
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  openCreateRole = (type) => {
    if (type === 'company') {
      this.setState({
        createRoleModal: true,
        error: null,
        createRolePermissionsType: type,
        permissions: {
          dashboard: 2,
          createNewVoucher: 2,
          basicInformation: 2,
          resourceCentre: 2,
          contact: 2,
          payments: 2,
          users: 2,
          api: 2,
        }
      });
    } else {
      this.setState({
        createRoleModal: true,
        error: null,
        createRolePermissionsType: type,
        permissions: {
          companies: 2,
          campaigns: 2,
          companyAccess: 2,
          companyAccessDashboard: 2,
          companyAccessCreateNewVoucher: 2,
          companyAccessBasicInformation: 2,
          customers: 2,
          dashboard: 2,
          admins: 2,
          permissionLevels: 2,
          payments: 2,
          greetingsCards: 2,
          contentManagement: 2,
          resourceCentre: 2,
          notifications: 2,
        }
      });
    }
  }

  closeCreateRole = () => {
    this.setState({ createRoleModal: false, createRolePermissionsType: null });
  }

  createRole = () => {
    const { createR } = this.props;
    const { name, permissions, activeTab, description } = this.state;

    let type = 'company';
    if (activeTab === '2') {
      type = 'admin';
    }

    this.setState({ processing: 'create' });

    createR(name, permissions, type, description).then(res => {
      this.setState({ processing: null, createRoleModal: false, createRolePermissionsType: null, name: null, description: null, permissions: {} });
    }).catch(err => {
      console.log(err);
      if (err === "no name") {
        this.setState({ processing: null, error: notifications.admin.permissionLevels.noName || 'no name' });
      } else if (err === "no description") {
        this.setState({ processing: null, error: notifications.admin.permissionLevels.noDescription || 'no description' });
      } else {
        this.setState({ processing: null, error: err });
      }
    })
  }

  openChangePermissions = (id, permissions, name, type, description) => {
    this.setState({ error: null, changePermissionsModal: true, changePermissionsId: id, permissions, name, description, changePermissionsType: type });
  }

  closeChangePermissions = () => {
    this.setState({ changePermissionsModal: false, changePermissionsId: null, permissions: {}, name: null, description: null });
  }

  selectRole = (e) => {
    this.setState({ role: e.target.value });
  }

  togglePermission = (type, editType) => {
    if (editType === 'edit') {
      this.setState(prevState => ({ permissions: {
        ...prevState.permissions,
        [type]: prevState.permissions[type] === 2 ? 1 : 2,
      } }));
    } else {
      this.setState(prevState => ({ permissions: {
        ...prevState.permissions,
        [type]: prevState.permissions[type] ? 0 : 1,
      } }));
    }
  }

  changeCompanyView = (e) => {
    if (e.target && e.target.value) {
      const value = e.target.value;
      this.setState(prevState => ({ permissions: {
        ...prevState.permissions,
        companyView: parseInt(value, 10),
      } }));
    }
  }

  changePermissions = () => {
    const { changePermissions } = this.props;
    const { changePermissionsId, permissions, name, description, activeTab } = this.state;

    let type = 'company';
    if (activeTab === '2') {
      type = 'admin';
    }

    this.setState({ processing: 'create' });

    if (!name) {
      this.setState({ processing: null, error: notifications.admin.permissionLevels.noName || 'no name' });
    } else if (!description) {
      this.setState({ processing: null, error: notifications.admin.permissionLevels.noDescription || 'no description' });
    }

    changePermissions(changePermissionsId, permissions, name, type, description).then(res => {
      this.setState({ processing: null, changePermissionsModal: false, changePermissionsId: null, permissions: {}, name: null, description: null });
    }).catch(err => {
      console.log(err);
    //  this.setState({ processing: null });
      if (err === "no name") {
        this.setState({ processing: null, error: notifications.admin.permissionLevels.noName || 'no name' });
      } else if (err === "no description") {
        this.setState({ processing: null, error: notifications.admin.permissionLevels.noDescription || 'no description' });
      } else {
        this.setState({ processing: null, error: err });
      }
    })
  }

  toggleDeleteRole = (e, uid) => {
    e.stopPropagation();

    const { deleteRoleModal } = this.state;
    if (deleteRoleModal) {
      this.setState({ deleteRoleModal: false, roleToDelete: null, deleteRoleErr: null });
    } else {
      this.setState({ deleteRoleModal: true, roleToDelete: uid, deleteRoleErr: null });
    }
  }

  openDeleteRole = (e) => {
    e.stopPropagation();

    const { changePermissionsId } = this.state;
    this.setState({ deleteRoleModal: true, roleToDelete: changePermissionsId, deleteRoleErr: null });
  }

  closeDeleteRole = () => {
    this.setState({ deleteRoleModal: false, roleToDelete: null, deleteRoleErr: null });
  }

  deleteRole = () => {
    const { deleteR } = this.props;
    const { activeTab, roleToDelete } = this.state;

    let type = 'company';
    if (activeTab === '2') {
      type = 'admin';
    }

    this.setState({ processing: 'delete' });

    deleteR(roleToDelete, type).then(res => {
      this.setState({ processing: null, deleteRoleModal: false, roleToDelete: null, deleteRoleErr: null });
    }).catch(err => {
      console.log(err);
      this.setState({ processing: null, error: notifications.admin.permissionLevels.deleteError || 'Failed to delete' });
    })
  }

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  renderTab = (roles, type, permissions, showNoPermission, sortType, sortColumn) => (
    <Card>
        <CardBody>
            <div style={{ display: 'inline-block' }}>
              Permission levels
            </div>
            <Button disabled={showNoPermission} className="body-add-button" onClick={() => this.openCreateRole(type)}>
              Add new permission level
            </Button>

            <div class="table-responsive">

            <Table
              style={{ fontSize: 14 }}
              sortType={sortType}
              sortColumn={sortColumn}
              data={roles}
              virtualized
              minHeight={590}
              height={590}
              rowKey='uid'
              rowHeight={55}
              rowClassName={(rowData) => {
                return (`${`${(rowData && rowData.newUser) ? ' admin-table-new-user' : ''}`}`)
              }}
            >
              <Column width={150} resizable>
                <HeaderCell>Name</HeaderCell>
                <Cell dataKey="name" />
              </Column>

              <Column width={100} resizable>
                <HeaderCell>Dashboard</HeaderCell>
                <Cell dataKey="details button">
                  {(rowData, rowIndex) => (
                    <Input type="checkbox" checked={(rowData.permissions && rowData.permissions.dashboard) ? true : false} readOnly disabled/>
                  )}
                </Cell>
              </Column>
              <Column width={100} resizable>
                <HeaderCell>Dashboard</HeaderCell>
                <Cell dataKey="details button">
                  {(rowData, rowIndex) => (
                    <Input type="checkbox" checked={(rowData.permissions && rowData.permissions.createNewVoucher) ? true : false} readOnly disabled/>
                  )}
                </Cell>
              </Column>
              <Column width={100} resizable>
                <HeaderCell>Dashboard</HeaderCell>
                <Cell dataKey="details button">
                  {(rowData, rowIndex) => (
                    <Input type="checkbox" checked={(rowData.permissions && rowData.permissions.dashboard) ? true : false} readOnly disabled/>
                  )}
                </Cell>
              </Column>
              <Column width={100} resizable>
                <HeaderCell>Dashboard</HeaderCell>
                <Cell dataKey="details button">
                  {(rowData, rowIndex) => (
                    <Input type="checkbox" checked={(rowData.permissions && rowData.permissions.dashboard) ? true : false} readOnly disabled/>
                  )}
                </Cell>
              </Column>
              <Column width={100} resizable>
                <HeaderCell>Dashboard</HeaderCell>
                <Cell dataKey="details button">
                  {(rowData, rowIndex) => (
                    <Input type="checkbox" checked={(rowData.permissions && rowData.permissions.dashboard) ? true : false} readOnly disabled/>
                  )}
                </Cell>
              </Column>
              <Column width={100} resizable>
                <HeaderCell>Dashboard</HeaderCell>
                <Cell dataKey="details button">
                  {(rowData, rowIndex) => (
                    <Input type="checkbox" checked={(rowData.permissions && rowData.permissions.dashboard) ? true : false} readOnly disabled/>
                  )}
                </Cell>
              </Column>

              <Column width={220} resizable>
                <HeaderCell>Change Permissions</HeaderCell>
                <Cell dataKey="change button">
                  {(rowData, rowIndex) => (
                    <>
                      {rowData.key !== 'Main' ? (
                        <Button disabled={showNoPermission} onClick={() => this.openChangePermissions(rowData.key, rowData.permissions, rowData.name, type, rowData.description)}>
                          Change Permissions
                        </Button>
                      ):(null)}
                    </>
                  )}
                </Cell>
              </Column>
              <Column width={170} resizable>
                <HeaderCell>Delete</HeaderCell>
                <Cell dataKey="delete button">
                  {(rowData, rowIndex) => (
                    <>
                      {rowData.key !== 'Main' ? (
                        <Button disabled={showNoPermission} onClick={(e) => this.toggleDeleteRole(e, rowData.key)}>
                          Delete
                        </Button>
                      ):(null)}
                    </>
                  )}
                </Cell>
              </Column>
            </Table>
            </div>
        </CardBody>
    </Card>
  )

  render() {
    const { companiesRoles, user, adminsRoles } = this.props;
    const {
      changePermissionsModal,
      changePermissionsType,
      createRoleModal,
      createRolePermissionsType,
      permissions,
      name,
      description,
      activeTab,
      adminPermissions,
      companyPermissions,
      deleteRoleModal,
      deleteRoleErr,
      roleToDelete,
      showNoPermission,
      dontShowPage,
      sortType,
      sortColumn,
      processing,
      error,
    } = this.state;

    let companiesRolesArr = [];
    let adminsRolesArr = [];

    companiesRolesArr.push({
      key: 'Main',
      name: "Admin",
      permissions: {
        dashboard: 2,
        createNewVoucher: 2,
        basicInformation: 2,
        resourceCentre: 2,
        contact: 2,
        payments: 2,
        users: 2,
        api: 2,
      }
    });

    companiesRolesArr.push({
      key: 'viewOnly',
      name: "No Access",
      permissions: {
        dashboard: 0,
        createNewVoucher: 0,
        basicInformation: 0,
        resourceCentre: 0,
        contact: 0,
        payments: 0,
        users: 0,
        api: 0,
      }
    });

    Object.keys(companiesRoles).map((key) => {
      companiesRolesArr.push({ ...companiesRoles[key], key });
      return null;
    });

    adminsRolesArr.push({
      key: 'Main',
      name: "Admin",
      permissions: {
        companies: 2,
        campaigns: 2,
        companyAccess: 2,
        companyAccessDashboard: 2,
        companyAccessCreateNewVoucher: 2,
        companyAccessBasicInformation: 2,
        customers: 2,
        dashboard: 2,
        admins: 2,
        permissionLevels: 2,
        payments: 2,
        greetingsCards: 2,
        contentManagement: 2,
        resourceCentre: 2,
        notifications: 2,
      }
    });

    adminsRolesArr.push({
      key: 'viewOnly',
      name: "No Access",
      permissions: {
        companies: 0,
        campaigns: 0,
        companyAccess: 0,
        companyAccessDashboard: 0,
        companyAccessCreateNewVoucher: 0,
        companyAccessBasicInformation: 0,
        customers: 0,
        dashboard: 0,
        admins: 0,
        permissionLevels: 0,
        payments: 0,
        greetingsCards: 0,
        contentManagement: 0,
        resourceCentre: 0,
        notifications: 0,
      }
    });

    Object.keys(adminsRoles).map((key) => {
      adminsRolesArr.push({ ...adminsRoles[key], key });
      return null;
    });

    if (!user.role || user.roleClass !== 'admin') {
      return (
        <div>
          <Card>
            <CardBody>
              You need admin permissions to view all permission levels
            </CardBody>
          </Card>
        </div>
      );
    }

    if (dontShowPage) {
      return (
        <div>
          <Card>
            <CardBody>
              You don't have permissions to view permission levels
            </CardBody>
          </Card>
        </div>
      );
    }

    return (
      <div>
      <Nav tabs>
          <NavItem>
            <NavLink
              className={`${activeTab === '1' ? 'active' : null}`}
              onClick={() => { this.toggle('1'); }}
            >
              Company
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${activeTab === '2' ? 'active' : null}`}
              onClick={() => { this.toggle('2'); }}
            >
              Admin
            </NavLink>
          </NavItem>
        </Nav>
        {activeTab === '1' ? (
          <Company
            roles={companiesRolesArr}
            type='company'
            showNoPermission={showNoPermission}
            sortType={sortType}
            sortColumn={sortColumn}
            openChangePermissions={this.openChangePermissions}
            toggleDeleteRole={this.toggleDeleteRole}
            openCreateRole={this.openCreateRole}
          />
        ):(null)}

        {activeTab === '2' ? (
          <Admin
            roles={adminsRolesArr}
            type='admin'
            showNoPermission={showNoPermission}
            sortType={sortType}
            sortColumn={sortColumn}
            openChangePermissions={this.openChangePermissions}
            toggleDeleteRole={this.toggleDeleteRole}
            openCreateRole={this.openCreateRole}
          />
        ):(null)}

        <CreateModal
          createRoleModal={createRoleModal}
          closeCreateRole={this.closeCreateRole}
          createRole={this.createRole}
          acceptButtonText='Create'
          headerText='Create new permission level'
          handleChange={this.handleChange}
          name={name}
          description={description}
          permissionsType={createRolePermissionsType}
          companyPermissions={companyPermissions}
          adminPermissions={adminPermissions}
          permissions={permissions}
          togglePermission={this.togglePermission}
          showDelete={false}
          openDeleteRole={this.openDeleteRole}
          showNoPermission={showNoPermission}
          processing={processing}
          error={error}
        />

        <CreateModal
          createRoleModal={changePermissionsModal}
          closeCreateRole={this.closeChangePermissions}
          createRole={this.changePermissions}
          acceptButtonText='Change permission level'
          headerText='Change permission level'
          handleChange={this.handleChange}
          name={name}
          description={description}
          permissionsType={changePermissionsType}
          companyPermissions={companyPermissions}
          adminPermissions={adminPermissions}
          permissions={permissions}
          togglePermission={this.togglePermission}
          showDelete={true}
          openDeleteRole={this.openDeleteRole}
          showNoPermission={showNoPermission}
          processing={processing}
          error={error}
        />

        <Modal isOpen={deleteRoleModal} toggle={this.closeDeleteRole}>
          <ModalHeader toggle={this.closeDeleteRole}>
            Are you sure you want to delete this permission level?
          </ModalHeader>
          <ModalBody>
            {(!!deleteRoleErr) && <Alert color="danger">{deleteRoleErr}</Alert>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.deleteRole}>
              Delete permission level
              {(processing === 'delete') ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ):(null)}
            </Button>
            <Button color="secondary" onClick={this.closeDeleteRole}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  companiesRoles: state.users.companiesRoles || {},
  adminsRoles: state.admin.adminsRoles || {},
});

const mapDispatchToProps = {
  getRoles: getAllCompaniesRoles,
  getAdminRoles: getAllAdminsRoles,
  createR: createRole,
  changePermissions: changeRolePermissions,
  deleteR: deleteRole,
  getUser: getUserData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PermissionLevels));

// export default Dashboard;
