import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Input, Label, Card, CardBody, Button } from 'reactstrap';

import { connect } from 'react-redux';

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { ResponsiveContainer, XAxis, YAxis, Tooltip, BarChart, Bar, LineChart, Line, ReferenceLine, CartesianGrid, LabelList } from 'recharts';

import moment from 'moment';
import Moment from 'react-moment';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';

import ReactTooltip from 'react-tooltip';

import momentLocalizer from 'react-widgets-moment';

import formattedNumber from '../../../components/FormattedNumber/FormattedNumber';

import {
  getVoucherSales,
} from '../../../../actions/vouchers';

import {
  //  getCompanyPayouts,
  getCompanyPaymentsTotals,
} from '../../../../actions/company';

import AutoRefresh from '../../../components/AutoRefresh/AutoRefresh';
import DashboardGraph from '../../../components/Graphs/DashboardGraph';
import Buttons from '../../../elements/Buttons';

import { getTempVoucherSales } from './getTempVoucherSales';

moment.locale('en-GB');
momentLocalizer();

function CustomizedTick(props) {
  const { x, y, stroke, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fill="#666">
        <tspan textAnchor="middle" x="0">
          Line 1
        </tspan>
        <tspan textAnchor="middle" x="0" dy="20">
          Line 2
        </tspan>
        <tspan textAnchor="middle" x="0" dy="40">
          Line 3
        </tspan>
      </text>
    </g>
  );
}

function calculateInterval(interval, valuesArray) {
  let counter = 1;
  let temp = {};

  let newValues = [];
  const valuesArrayCloned = [...valuesArray];

  valuesArrayCloned.reverse().map((value, index) => {
    if (counter >= interval || index === valuesArrayCloned.length - 1) {
      Object.keys(value).map(key => {
        if (key !== 'key') {
          temp[key] = (temp[key] || 0) + (value[key] || 0);
        }
        return null;
      });
      if (parseInt(interval, 10) === 1) {
        //  console.log('set key');
        temp.key = value.key;
      }

      //  console.log('temp', temp, counter, valuesArrayCloned.length);

      newValues.push(temp);
      temp = {};
      counter = 1;
    } else {
      Object.keys(value).map(key => {
        if (key !== 'key') {
          temp[key] = (temp[key] || 0) + (value[key] || 0);
        }
        return null;
      });
      if (counter === 1) {
        temp.key = value.key;
      }

      //  console.log('temp', temp, counter, valuesArrayCloned.length);

      counter = counter + 1;
    }
  });

  //  console.log('newValues', newValues);

  return newValues.reverse();
}

class LiveMetrics extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    vouchers: PropTypes.shape(),
    fetchCompanyPaymentsTotals: PropTypes.func.isRequired,
  };

  static defaultProps = {
    vouchers: {},
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    let d = new Date();
    d.setDate(d.getDate() - 1);

    this.state = {
      error: null,
      loading: false,
      showNoPermission: false,
      startDate: d,
      endDate: new Date(),
      interval: "7",
      campaignFilter: 'all',
      timeFilter: 'month',
      metricFilter: 'vouchersCount',
      vouchersByDate: [],
    };
  }

  componentDidMount() {
    const { user, fetchVoucherSales, adminViewAsCompany, fetchCompanyPaymentsTotals } = this.props;

    if (user.company) {
      fetchVoucherSales(user.company, null, 'month', null, null);
      fetchCompanyPaymentsTotals(user.company, null, 'month', null, null);
    } else if (user.roleClass === 'admin' && adminViewAsCompany) {
      fetchVoucherSales(adminViewAsCompany, null, 'month', null, null);
      fetchCompanyPaymentsTotals(adminViewAsCompany, null, 'month', null, null);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { vouchers, vouchersByDate, vouchersByDateLastUpdate } = this.props;
    const { interval, timeFilter, startDate, endDate } = this.state;

    console.log('vouchersByDate prop', vouchersByDate);

    const vouchersLength = vouchers ? Object.keys(vouchers).length : 0;
    let tempVoucherByDate;

    if (!vouchersLength) {
      tempVoucherByDate = getTempVoucherSales(timeFilter, startDate, endDate);
    }

    /*
    if (!vouchersLength) {
      if (vouchersByDate && interval && (vouchersByDateLastUpdate !== prevProps.vouchersByDateLastUpdate || vouchersByDate !== prevProps.vouchersByDate || prevState.interval !== interval || (!prevProps.vouchersByDate || vouchersByDate.length !== prevProps.vouchersByDate.length))) {
        let newVouchersByDate = calculateInterval(interval, tempVoucherByDate) || [];

        this.setState({ vouchersByDate: newVouchersByDate });
      }
    } else { */
      if (vouchersByDate && interval && (vouchersByDateLastUpdate !== prevProps.vouchersByDateLastUpdate || vouchersByDate !== prevProps.vouchersByDate || prevState.interval !== interval || (!prevProps.vouchersByDate || vouchersByDate.length !== prevProps.vouchersByDate.length))) {

        if (!vouchersLength && tempVoucherByDate) {
          console.log('tempVoucherByDate', tempVoucherByDate);

          let newVouchersByDate = calculateInterval(interval, tempVoucherByDate) || [];
          this.setState({ isSampleData: true, vouchersByDate: newVouchersByDate });
        } else {
          let newVouchersByDate = calculateInterval(interval, vouchersByDate) || [];
          this.setState({ isSampleData: false, vouchersByDate: newVouchersByDate });
        }
      }
    //}

    if (Object.keys(vouchers).length !== Object.keys(prevProps.vouchers).length) {
      console.log('call filter');
      this.filter();
    }
  }

  handleChangeFilter = (e, type) => {
    const { interval } = this.state;
    const val = e.target.value;

    this.setState({ [type]: e.target.value }, () => {
      /*  if (type === 'timeFilter') {
          if (val === 'month' || val === 'year') {
            if (interval <= 2) {
              this.setState({ interval: 7 });
            } else if (val === '6months' && interval <= 7) {
              this.setState({ interval: 14 });
            }
          } else if (val === 'day' || val === 'week') {
            if (interval >= 15) {
              this.setState({ interval: 14 });
            }
          }
        } */

      if (type !== 'interval') {
        console.log('call filter');
        this.filter();
      }
    });
  };

  changeStartDate = (date) => {
    const todayDate = new Date();

    if (date > todayDate) {
      this.setState({ startDate: todayDate }, () => {
        console.log('call filter');
        this.filter();
      });
    } else {
      this.setState({ startDate: date }, () => {
        console.log('call filter');
        this.filter();
      });
    }

  }

  changeEndDate = (date) => {
    const todayDate = new Date();

    if (date > todayDate) {
      this.setState({ endDate: todayDate }, () => {
        console.log('call filter');
        this.filter();
      });
    } else {
      this.setState({ endDate: date }, () => {
        console.log('call filter');
        this.filter();
      });
    }
  }

  filter = () => {
    const { user, fetchVoucherSales, adminViewAsCompany } = this.props;
    const { campaignFilter, timeFilter, startDate, endDate } = this.state;

    console.log('this.filter user.company timeFilter', timeFilter);

    if (campaignFilter === 'all') {
      if (user.company) {
        if (timeFilter === 'custom') {
          fetchVoucherSales(user.company, null, timeFilter, startDate, endDate);
        } else if (timeFilter === 'allTime') {
          let allTimeStartDate = new Date('2022-08-01T00:00:00');

          if (user.dateCreated) {
            allTimeStartDate = new Date(user.dateCreated);
          }

          console.log('call fetchVoucherSales', allTimeStartDate);

          fetchVoucherSales(user.company, null, timeFilter, allTimeStartDate, null);
        } else {
          fetchVoucherSales(user.company, null, timeFilter, null, null);
        }
      } else if (adminViewAsCompany) {
        if (timeFilter === 'custom') {
          fetchVoucherSales(adminViewAsCompany, null, timeFilter, startDate, endDate);
        } else if (timeFilter === 'allTime') {
          let allTimeStartDate = new Date('2022-08-01T00:00:00');

          console.log('call fetchVoucherSales', allTimeStartDate);

          fetchVoucherSales(adminViewAsCompany, null, timeFilter, allTimeStartDate, null);
        } else {
          fetchVoucherSales(adminViewAsCompany, null, timeFilter, null, null);
        }
      }
    } else {
      if (timeFilter === 'custom') {
        fetchVoucherSales(null, campaignFilter, timeFilter, startDate, endDate);
      } else {
        fetchVoucherSales(null, campaignFilter, timeFilter, null, null);
      }
    }
  }

  /*
    refresh = () => {
      const { user, fetchVoucherSales, adminViewAsCompany } = this.props;
      const { campaignFilter, timeFilter, startDate, endDate } = this.state;
  
      if (campaignFilter === 'all') {
        if (user.company) {
          if (timeFilter === 'custom') {
            fetchVoucherSales(user.company, null, timeFilter, startDate, endDate);
          } else {
            fetchVoucherSales(user.company, null, timeFilter, null, null);
          }
        } else if (adminViewAsCompany) {
          if (timeFilter === 'custom') {
            fetchVoucherSales(adminViewAsCompany, null, timeFilter, startDate, endDate);
          } else {
            fetchVoucherSales(adminViewAsCompany, null, timeFilter, null, null);
          }
        }
      } else {
        if (timeFilter === 'custom') {
          fetchVoucherSales(null, campaignFilter, timeFilter, startDate, endDate);
        } else {
          fetchVoucherSales(null, campaignFilter, timeFilter, null, null);
        }
      }
    } */

  refresh = () => {
    const { user, fetchVoucherSales, adminViewAsCompany, refreshLiveMetrics } = this.props;
    const { campaignFilter, timeFilter, startDate, endDate } = this.state;


    if (user.company) {
      if (timeFilter === 'allTime') {
        let allTimeStartDate = new Date('2022-08-01T00:00:00');

        if (user.dateCreated) {
          allTimeStartDate = new Date(user.dateCreated);
        }

        //   console.log('filter allTimeStartDate', allTimeStartDate);

        refreshLiveMetrics(user.company, campaignFilter, timeFilter, allTimeStartDate, null);
      } else {
        refreshLiveMetrics(user.company, campaignFilter, timeFilter, startDate, endDate);
      }
      //  refreshLiveMetrics(user.company, campaignFilter, timeFilter, startDate, endDate)
    } else if (adminViewAsCompany) {
      refreshLiveMetrics(adminViewAsCompany, campaignFilter, timeFilter, startDate, endDate);
    }
  }

  renderCustomizedLabel = (props) => {
    const { vouchersByDate } = this.props;
    const { x, y, width, value, index } = props;
    const radius = 10;

    if (index === vouchersByDate.length - 1) {
      return (
        <g>
          <text x={x + (width || 1) / 2} y={y - radius} fill="#000" textAnchor="middle" dominantBaseline="middle">
            {value}
          </text>
        </g>
      );
    }

    return null;
  };

  render() {
    const {
      vouchers,
      vouchersCount,
      vouchersUsedCount,
      vouchersValue,
      //  vouchersByDate,
      dashboardTooltips,
    } = this.props;
    const {
      showNoPermission,
      campaignFilter,
      timeFilter,
      interval,
      metricFilter,
      startDate,
      endDate,
      vouchersByDate,
      isSampleData,
    } = this.state;

    //  console.log('vouchersByDate', vouchersByDate);

    //  const vouchersCount = 3606060;

    //  const value1 = 100;
    //  const value2 = 100;
    //  const value3 = 100;

    //  console.log('vouchersByDate', vouchersByDate);

    if (showNoPermission) {
      return (
        <div>
          You dont have permission to view other company users
        </div>
      )
    }

    console.log('vouchersByDate', vouchersByDate);

    return (
      <>
        <div style={{ display: 'block' }}>
          <AutoRefresh refreshFunction={this.refresh} refreshTime={60} />
        </div>
        <Card>
          <CardBody style={{ paddingBottom: 0 }}>
            <Row className="live-metrics-filters">
              <Col sm="4" style={{ paddingRight: 20 }}>
                <Label data-tip data-for='campaign-filter' for="campaign-filter">
                  Campaign
                </Label>
                {dashboardTooltips.campaignFilter ? (
                  <ReactTooltip place="top" className='react-tooltip-custom-class' effect="solid" id='campaign-filter'>
                    <span>{dashboardTooltips.campaignFilter ? dashboardTooltips.campaignFilter.value : ''}</span>
                  </ReactTooltip>
                ) : (null)}
                <Input style={{ height: 37 }} name="campaignFilter" type="select" className="form-control" value={campaignFilter} onChange={(e) => this.handleChangeFilter(e, 'campaignFilter')}>
                  <option value="all">All</option>
                  {vouchers && Object.keys(vouchers).map((key) => (
                    <option value={key}>{vouchers[key].name}</option>
                  ))}
                </Input>
              </Col>
              <Col sm="4">
                <Row style={{ paddingRight: 5 }}>
                  <Col xs="12" style={{ paddingRight: 5 }}>
                    <Label style={{ width: 100 }} data-tip data-for='time-filter' for="time-filter">
                      Timeframe
                    </Label>
                    <div style={{ width: 'calc(100% - 100px)', borderTop: '1px solid #7f8fa4', height: 0, top: -4, position: 'relative', display: 'inline-block' }}></div>
                  </Col>
                </Row>
                {dashboardTooltips.timeFilter ? (
                  <ReactTooltip place="top" className='react-tooltip-custom-class' effect="solid" id='time-filter'>
                    <span>{dashboardTooltips.timeFilter ? dashboardTooltips.timeFilter.value : ''}</span>
                  </ReactTooltip>
                ) : (null)}
                <Row style={{ paddingRight: 5 }}>
                  <Col style={{ paddingLeft: 2, paddingRight: 2 }}>
                    <Button style={{ boxShadow: 'none', height: 37, paddingLeft: 2, paddingRight: 2, margin: 0, fontSize: 13, width: '100%' }} color={timeFilter === "day" ? "primary" : "secondary"} value="day" onClick={(e) => this.handleChangeFilter(e, 'timeFilter')}>
                      Day
                    </Button>
                  </Col>
                  <Col style={{ paddingLeft: 2, paddingRight: 2 }}>
                    <Button style={{ boxShadow: 'none', height: 37, paddingLeft: 2, paddingRight: 2, margin: 0, fontSize: 13, width: '100%' }} color={timeFilter === "week" ? "primary" : "secondary"} value="week" onClick={(e) => this.handleChangeFilter(e, 'timeFilter')}>
                      Week
                    </Button>
                  </Col>
                  <Col style={{ paddingLeft: 2, paddingRight: 2 }}>
                    <Button style={{ boxShadow: 'none', height: 37, paddingLeft: 2, paddingRight: 2, margin: 0, fontSize: 13, width: '100%' }} color={timeFilter === "month" ? "primary" : "secondary"} value="month" onClick={(e) => this.handleChangeFilter(e, 'timeFilter')}>
                      4 Weeks
                    </Button>
                  </Col>
                  {/* <Col>
                    <Button color={timeFilter === "year" ? "primary" : "secondary"} value="year" onClick={(e) => this.handleChangeFilter(e, 'timeFilter')}>
                      Year
                    </Button>
                  </Col> */}
                  <Col style={{ paddingLeft: 2, paddingRight: 2 }}>
                    <Button style={{ boxShadow: 'none', height: 37, paddingLeft: 2, paddingRight: 2, margin: 0, fontSize: 13, width: '100%' }} color={timeFilter === "allTime" ? "primary" : "secondary"} value="allTime" onClick={(e) => this.handleChangeFilter(e, 'timeFilter')}>
                      Lifetime
                    </Button>
                  </Col>
                  <Col style={{ paddingLeft: 2, paddingRight: 2 }}>
                    <Button style={{ boxShadow: 'none', height: 37, paddingLeft: 2, paddingRight: 2, margin: 0, fontSize: 13, width: '100%' }} color={timeFilter === "custom" ? "primary" : "secondary"} value="custom" onClick={(e) => this.handleChangeFilter(e, 'timeFilter')}>
                      Custom
                    </Button>
                  </Col>
                </Row>

                {/* <Input name="timeFilter" type="select" className="form-control" value={timeFilter} onChange={(e) => this.handleChangeFilter(e, 'timeFilter')}>
              <option value="day">1 Day</option>
              <option value="week">1 Week</option>
              <option value="month">4 Weeks</option>
              <option value="year">1 Year</option>
              <option value="allTime">All time</option>
              <option value="custom">Custom</option>
            </Input> */}
                {(timeFilter && timeFilter === 'custom') ? (
                  <Row>
                    <Col sm="6">
                      <DateTimePicker
                        className="date-picker"
                        time={false}
                        culture="en"
                        format="MMM DD YYYY"
                        onChange={this.changeStartDate}
                        value={startDate}
                        defaultValue={new Date()}
                      />
                    </Col>
                    <Col sm="6">
                      <DateTimePicker
                        className="date-picker"
                        time={false}
                        culture="en"
                        format="MMM DD YYYY"
                        onChange={this.changeEndDate}
                        value={endDate}
                        defaultValue={new Date()}
                      />
                    </Col>
                  </Row>
                ) : null}
              </Col>
              <Col sm="4">
                {/* <Label data-tip data-for='interval' for="interval">
                  Interval
                </Label> */}
                <Col xs="12" style={{ paddingRight: 5 }}>
                  <Label style={{ width: 80 }} data-tip data-for='interval' for="interval">
                    Interval
                  </Label>
                  <div style={{ width: 'calc(100% - 80px)', borderTop: '1px solid #7f8fa4', height: 0, top: -4, position: 'relative', display: 'inline-block' }}></div>
                </Col>
                {dashboardTooltips.interval ? (
                  <ReactTooltip place="top" className='react-tooltip-custom-class' effect="solid" id='interval'>
                    <span>{dashboardTooltips.interval ? dashboardTooltips.interval.value : ''}</span>
                  </ReactTooltip>
                ) : (null)}
                {console.log('interval', interval)}
                <Row style={{ paddingRight: 5 }}>
                  <Col style={{ paddingLeft: 2, paddingRight: 2 }}>
                    <Button style={{ boxShadow: 'none', height: 37, paddingLeft: 2, paddingRight: 2, margin: 0, fontSize: 13, width: '100%' }} color={interval === '1' ? "primary" : "secondary"} value="1" onClick={(e) => this.handleChangeFilter(e, 'interval')}>
                      Daily
                    </Button>
                  </Col>
                  <Col style={{ paddingLeft: 2, paddingRight: 2 }}>
                    <Button style={{ boxShadow: 'none', height: 37, paddingLeft: 2, paddingRight: 2, margin: 0, fontSize: 13, width: '100%' }} color={interval === '7' ? "primary" : "secondary"} value="7" onClick={(e) => this.handleChangeFilter(e, 'interval')}>
                      Weekly
                    </Button>
                  </Col>
                  <Col style={{ paddingLeft: 2, paddingRight: 2 }}>
                    <Button style={{ boxShadow: 'none', height: 37, paddingLeft: 2, paddingRight: 2, margin: 0, fontSize: 13, width: '100%' }} color={interval === '30' ? "primary" : "secondary"} value="30" onClick={(e) => this.handleChangeFilter(e, 'interval')}>
                      4 Weekly
                    </Button>
                  </Col>
                  <Col style={{ paddingLeft: 2, paddingRight: 2 }}>
                    <Button style={{ boxShadow: 'none', height: 37, paddingLeft: 2, paddingRight: 2, margin: 0, fontSize: 13, width: '100%' }} color={interval === '365' ? "primary" : "secondary"} value="365" onClick={(e) => this.handleChangeFilter(e, 'interval')}>
                      Yearly
                    </Button>
                  </Col>
                </Row>
                {/* <Input name="interval" type="select" className="form-control" value={interval} onChange={(e) => this.handleChangeFilter(e, 'interval')}>
              <option value={1}>Daily</option>
              <option value={7}>Weekly</option>
              <option value={30}>4 Weekly</option>
              <option value={365}>Yearly</option>
            </Input> */}
                {/*
            <Input name="interval" type="select" className="form-control" value={interval} onChange={(e) => this.handleChangeFilter(e, 'interval')}>
              {timeFilter !== '6months' ? (
                <>
                  {(timeFilter !== 'month') ? (
                    <>
                    <option value={1}>Daily</option>
                    </>
                  ):(null)}
                    <option value={2}>2 Days</option>
                </>
              ):(null)}
              {timeFilter !== 'day' && timeFilter !== '1week' ? (
                <>
                  {timeFilter !== '6months' ? (
                    <>
                    <option value={7}>Weekly</option>
                    </>
                  ):(null)}
                  {timeFilter !== '2weeks' ? (
                    <>
                    <option value={14}>2 Weeks</option>
                    {timeFilter === '6months' || timeFilter === 'custom' ? (
                      <>
                      <option value={30}>Monthly</option>
                      </>
                    ):(null)}
                    </>
                  ):(null)}
                </>
              ):(null)}
            </Input>  */}
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Row className="live-metrics"> {/*className="live-metrics"*/}
          <Col sm="4" data-tip data-for='voucher-sales-count'>
            <Card className="live-metrics-card">
              <CardBody>
                <Row>
                  <Col sm="12">
                    <Label for="voucher-sales-count" className="live-metric voucher-sales-count">
                      Number of voucher sales
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={{ size: 12 }}>
                    {dashboardTooltips.voucherSalesCount ? (
                      <ReactTooltip place="top" className='react-tooltip-custom-class' effect="solid" id='voucher-sales-count'>
                        <span>{dashboardTooltips.voucherSalesCount ? dashboardTooltips.voucherSalesCount.value : ''}</span>
                      </ReactTooltip>
                    ) : (null)}
                    <DashboardGraph isSampleData={isSampleData} type={'area'} fill="#D4E9F7" data={vouchersByDate} interval={1} dataKey={'vouchersCount'} name={'Voucher sales (#)'} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4" data-tip data-for='voucher-sales-sum'>
            <Card className="live-metrics-card">
              <CardBody>
                <Row>
                  <Col sm="12">
                    <Label className="live-metric voucher-sales-sum">
                      Value of voucher sales
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={{ size: 12 }}>
                    {dashboardTooltips.voucherSales ? (
                      <ReactTooltip place="top" className='react-tooltip-custom-class' effect="solid" id='voucher-sales-sum'>
                        <span>{dashboardTooltips.voucherSales ? dashboardTooltips.voucherSales.value : ''}</span>
                      </ReactTooltip>
                    ) : (null)}
                    <DashboardGraph yAxisSymbol={true} isSampleData={isSampleData} type={'area'} fill="#D4E9F7" data={vouchersByDate} interval={1} dataKey={'vouchersValue'} name={'Voucher sales (£)'} />

                    {/*  <ResponsiveContainer width='92%' height={270} marginLeft={'auto'} marginRight={'auto'}>
                    <BarChart data={vouchersByDate} margin={{ left: -10, right: 10 }}>
                      <XAxis dataKey="key" interval={interval - 1} tickCount={8} tick={{ fontSize: 2 }} angle={-60} textAnchor='end' />

                      <YAxis width={80} yAxisId="left" tick={{ fontSize: 8 }} >
                      </YAxis>
                      <CartesianGrid vertical={false} />

                      <Tooltip />
                      <Bar isAnimationActive={false} yAxisId="left" type="monotone"  dataKey={'vouchersValue'} name={'Voucher sales (£)'} fill="#5CB4FF" >
                        <LabelList dataKey="vouchersValue" position="top" content={this.renderCustomizedLabel} />
                      </Bar>
                  </BarChart>
                  </ResponsiveContainer> */}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4" data-tip data-for='voucher-redemtions'>
            <Card className="live-metrics-card">
              <CardBody>
                <Row>
                  <Col sm="12">
                    <Label className="live-metric voucher-redemtions">
                      Number of redemptions
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={{ size: 12 }}>
                    {dashboardTooltips.redemptionsCount ? (
                      <ReactTooltip place="top" className='react-tooltip-custom-class' effect="solid" id='voucher-redemtions'>
                        <span>{dashboardTooltips.redemptionsCount ? dashboardTooltips.redemptionsCount.value : ''}</span>
                      </ReactTooltip>
                    ) : (null)}
                    <DashboardGraph isSampleData={isSampleData} type={'area'} fill="#D4E9F7" data={vouchersByDate} interval={1} dataKey={'vouchersUsed'} name={'Voucher redemptions (#)'} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || {},
  vouchersData: state.vouchers || {},
  vouchers: state.vouchers.vouchers || {},
  vouchersCount: state.vouchers.vouchersCount || 0,
  vouchersUsedCount: state.vouchers.vouchersUsedCount || 0,
  vouchersValue: state.vouchers.vouchersValue || 0,
  vouchersByDate: state.vouchers.vouchersByDate || [],
  vouchersByDateLastUpdate: state.vouchers.vouchersByDateLastUpdate || null,
  adminViewAsCompany: state.admin.adminViewAsCompany || null,
});

const mapDispatchToProps = {
  fetchVoucherSales: getVoucherSales,
  //  fetchCompanyPayouts: getCompanyPayouts,
  fetchCompanyPaymentsTotals: getCompanyPaymentsTotals,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveMetrics);
