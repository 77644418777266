import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Card, CardBody, Table, Button, Input, Label, Form, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane, Alert } from 'reactstrap';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';

import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';

import { connect } from 'react-redux';

import {
  getResourceCenter,
} from '../../../../actions/resourceCenter';

class FAQ extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    fetchFAQ: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    const { fetchFAQ, admin, user, isLoggedIn, history } = this.props;

    fetchFAQ().then((res) => {
      if (res.payload && res.payload.faq) {
        let contentState = stateFromHTML(res.payload.faq);

        if (contentState) {
          this.setState({ editorState: EditorState.createWithContent(contentState) });
        }
      }
    });
  }

  render() {
    const { editorState } = this.state;

    return (
      <div>
        <FormGroup>
          <Editor
            className="content-management-text-area"
            editorState={editorState}
            toolbarHidden
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            readOnly
          />
        </FormGroup>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  faq: state.resourceCenter.data ? state.resourceCenter.data.faq : null,
});

const mapDispatchToProps = {
  fetchFAQ: getResourceCenter,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FAQ));

// export default Dashboard;
