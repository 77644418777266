import { auth } from '../../lib/firebase';

import {
  CONTENT_MANAGEMENT_DATA,
  UPDATE_CONTENT_MANAGEMENT_DATA,
  DASHBOARD_TOOLTIPS,
  NEW_VOUCHER_TOOLTIPS,
  API_TOOLTIPS,
  PAYMENT_TOOLTIPS,
  COMPANY_POPOUT_TOOLTIPS,
  COMPANY_TABS_TOOLTIPS,
  COMPANY_INFO_TOOLTIPS,
  PENDING_TAB,
  CONTACT_TAB,
  API_TAB,
  NEW_VOUCHER_DESCRIPTION,
  CONTENT_MANAGEMENT_FOOTERS,
  FOOTER_ICONS,
  BRANDING_DATA,
  UPDATE_BRANDING_DATA,
  EVENT_CATEGORIES,
  UPDATE_EVENT_CATEGORY,
  REMOVE_EVENT_CATEGORY,
  FAQ_LINKS,
  SIGNPOSTS,
} from '../../actions';

const defaultState = {
    contentManagementData: {},
};

const userReducer = (state=defaultState, action) => {
    switch(action.type) {
        case CONTENT_MANAGEMENT_DATA:
            return { ...state, contentManagementData: action.payload };

        case DASHBOARD_TOOLTIPS:
            return { ...state, dashboardTooltips: action.payload };

        case NEW_VOUCHER_TOOLTIPS:
            return { ...state, newVoucherTooltips: action.payload };

        case API_TOOLTIPS:
        return { ...state, apiTooltips: action.payload };

      case PAYMENT_TOOLTIPS:
        return { ...state, paymentTooltips: action.payload };

        case COMPANY_POPOUT_TOOLTIPS:
            return { ...state, companyPopoutTooltips: action.payload };

        case COMPANY_TABS_TOOLTIPS:
            return { ...state, companyTabsTooltips: action.payload };

      case COMPANY_INFO_TOOLTIPS: 
            return { ...state, companyInfoTooltips: action.payload };

        case PENDING_TAB:
            return { ...state, pendingTab: action.payload };

        case CONTACT_TAB:
            return { ...state, contactPage: action.payload };

        case API_TAB:
            return { ...state, APIPage: action.payload };

        case NEW_VOUCHER_DESCRIPTION:
            return { ...state, newVoucherPage: action.payload };

        case CONTENT_MANAGEMENT_FOOTERS:
            return { ...state, footers: action.payload };

        case BRANDING_DATA:
            return { ...state, branding: action.payload };

        case UPDATE_BRANDING_DATA: {
            return {
              ...state,
              branding: {
                ...state.branding,
                [action.payload.type]: action.payload.data,
              }
            };
        }


      case FAQ_LINKS: {
        console.log('FAQ_LINKS', action);

        const links = [];
        Object.keys(action.payload).map((key) => {
          links.push(action.payload[key]);
        });

        links.sort((a, b) => {
          if (a.index < b.index) {
            return -1;
          }
          if (a.index > b.index) {
            return -1;
          } else
            return 0;
        });

        console.log('faqLinksArray', links);

        return { ...state, faqLinks: action.payload, faqLinksArray: links };
      }

      case SIGNPOSTS: {
        console.log('SIGNPOSTS', action);
        return { ...state, signposts: action.payload };
      }

      case EVENT_CATEGORIES: {
        return { ...state, eventCategories: action.payload };
      }

        case UPDATE_EVENT_CATEGORY: {
            return {
              ...state,
              eventCategories: {
                ...state.eventCategories,
                [action.payload.id]: action.payload.data,
              }
            };
        }

        case REMOVE_EVENT_CATEGORY: {
          let newEventCategories = { ...state.eventCategories };
          delete newEventCategories[action.payload.id];

          return {
            ...state,
            eventCategories: newEventCategories,
          };
        }

        case FOOTER_ICONS: {
          if (state.footerIcons && state.footerIcons[action.payload.type]) {
            return {
              ...state,
              footerIcons: {
                ...state.footerIcons,
                [action.payload.type]: {
                  ...state.footerIcons[action.payload.type],
                  url: action.payload.url,
                },
              }
            };
          } else {
            return {
              ...state,
              footerIcons: {
                ...state.footerIcons,
                [action.payload.type]: {
                  url: action.payload.url,
                },
              }
            };
          }
        }

        case UPDATE_CONTENT_MANAGEMENT_DATA: {
            if (state.contentManagementData && state.contentManagementData[action.key]) {
              return {
                ...state,
                contentManagementData: {
                  ...state.contentManagementData,
                  [action.key]: {
                    ...state.contentManagementData[action.key],
                    text: action.payload,
                  },
                }
              };
            } else {
              return {
                ...state,
                contentManagementData: {
                  ...state.contentManagementData,
                  [action.key]: {
                    slug: action.key,
                    text: action.payload,
                  },
                }
              };
            }
        }
        default:
            return state;
    }
};

export default userReducer;
