import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { NavLink, Switch, Route, Redirect } from 'react-router-dom';
import { Button, Badge, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Header, SidebarNav, Footer, PageContent, Avatar, Chat, PageAlert, Page } from '../vibe';

import { Helmet } from "react-helmet";

import PendingPage from '../views/pages/Company/Pending';

// import ReactTooltip from 'react-tooltip';
import { matchPath } from 'react-router-dom';

import Logo from '../assets/images/vibe-logo.svg';

import nav from '../_nav';
import routes from '../views';
import ResetPasswordPage from '../views/pages/ResetPassword';
import EmailVerifyPage from '../views/pages/User/EmailVerify';

import ContextProviders from '../vibe/components/utilities/ContextProviders';
import handleKeyAccessibility, { handleClickAccessibility } from '../vibe/helpers/handleTabAccessibility';

import adminAsCompanyNav from '../_adminAsCompanyNav';

import { connect } from 'react-redux';

import { auth } from '../lib/firebase';
import { LogoutButton } from '../vibe';

import AdminAsCompanyLayout from './AdminAsCompanyLayout';
import FooterComponent from '../views/components/Footer/Footer';

 import {
//  getAllRoles,
  getAllAdminRoles,
  getAllAdminsRoles,
  getAdminStatistics,
  cancelAdminListeners,
  getAdminSettings,
  getAppSettings,
} from '../actions/admin';

import {
  getAllRoles,
  cancelListeners,
} from '../actions/users';

import {
  getCompanyData,
  getCompanyOwnerData,
  cancelCompanyListeners,
  getCategories,
  getCompanyNotifications,
  getComplianceData,
  getShopifyAPIData,
  getWoocommerceAPIData,
  getActiveAPIData,
  getLinkedAPIs,
} from '../actions/company';

import {
  getUserData,
  getStripeLoginLink,
  checkStripeCompanyStatus,
  doLogout,
  afterEmailLogin,
} from '../actions/user';

import {
  getCompanyVouchers,
  unsubscribeVouchers,
} from '../actions/vouchers';

import {
  getBrandingData,
  getPendingTabData,
  getCompanyTabsData,
  getFAQLinks,
  getSignposts,
} from '../actions/contentManagement';

import {
  getDisablePerksTab,
  getPerksTabTitle,
} from '../actions/perks';

const MOBILE_SIZE = 992;

class DashboardLayout extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    getRoles: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    getAdminRoles: PropTypes.func.isRequired,
    fetchCompanyData: PropTypes.func.isRequired,
    fetchComplianceData: PropTypes.func.isRequired,
    fetchCompanyOwnerData: PropTypes.func.isRequired,
    fetchCompanyNotifications: PropTypes.func.isRequired,
    cancelActiveListeners: PropTypes.func.isRequired,
    cancelActiveCompanyListeners: PropTypes.func.isRequired,
    fetchVoucherCategories: PropTypes.func.isRequired,
    fetchCompanyVouchers: PropTypes.func.isRequired,
    fetchAdminStatistics: PropTypes.func.isRequired,
    fetchAdminSettings: PropTypes.func.isRequired,
    fetchAppSettings: PropTypes.func.isRequired,
    unsubscribeAdminListeners: PropTypes.func.isRequired,
    fetchStripeLoginLink: PropTypes.func.isRequired,
    getStripeCompanyStatus: PropTypes.func.isRequired,
    fetchPerksTabTitle: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
  };

  constructor(props) {

  //  console.log('process.env', process.env);

    super(props);
    this.state = {
      sidebarCollapsed: false,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      showChat1: true,
      userFetched: false,
      perksDisabled: true,
      perkTitle: 'Perks',
    };
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ sidebarCollapsed: false, isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  componentDidMount() {
  //  console.log('DashboardLayout componentDidMount');
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', handleKeyAccessibility);
    document.addEventListener('click', handleClickAccessibility);

    const {
      getUser,
      fetchCompanyData,
      fetchComplianceData,
      fetchCompanyNotifications,
      fetchCompanyOwnerData,
      getRoles,
      getAdminRoles,
      fetchVoucherCategories,
      fetchCompanyVouchers,
      fetchAdminStatistics,
      fetchAdminSettings,
      fetchAppSettings,
      fetchStripeLoginLink,
      getStripeCompanyStatus,
      adminViewAsCompany,
      fetchBrandingData,
      fetchSignposts,
      fetchFAQLinks,
      fetchCompanyTabsData,
    //  logout,
      history,
      afterLogin,
      fetchDisablePerksTab,
      fetchPerksTabTitle,
      getShopifyAPI,
      getWoocommerceAPI,
      getActiveAPI,
      fetchLinkedAPIs,
    } = this.props;

    fetchCompanyTabsData();
    fetchFAQLinks();
    fetchSignposts();

    fetchBrandingData().then(() => {

      auth.onAuthStateChanged(user => {
      //  console.log('AuthStateChanged', user);

        if (user && user.uid) {
          getRoles();
          fetchVoucherCategories();
          getAdminRoles();

          fetchDisablePerksTab().then((res) => {
          //  console.log('fetchDisablePerksTab', res);
            this.setState({ perksDisabled: res });
          });

          fetchPerksTabTitle().then((res) => {
           // console.log('fetchPerksTabTitle', res);
            this.setState({ perkTitle: res || 'Perks' });
          });

          getUser().then((res) => {
            if (res) {

            //  console.log('AuthStateChanged getUser', res);

              if (!user.emailVerified && !res.pendingEmail) { // && (!res.roleClass || res.roleClass !== 'admin')
                this.setState({
                  uid: null,
                  userFetched: true,
                });

                if (res.pendingEmail) {
                  afterLogin();
                } else {
                  this.logout();
                }

              //  this.logout();
              } else {

                if (res.pendingEmail && user.emailVerified) {
                  afterLogin();
                }

                if (res.company) {
                  fetchCompanyNotifications(res.company);
                  fetchStripeLoginLink(res.company);
                  getStripeCompanyStatus(res.company);
                  fetchCompanyData(res.company, false);
                  fetchComplianceData(res.company);
                  fetchCompanyOwnerData(res.company);
                  fetchCompanyVouchers(res.company);

                  getShopifyAPI(res.company);
                  getWoocommerceAPI(res.company);
                  getActiveAPI(res.company);
                  fetchLinkedAPIs(res.company);
                }

                if (res && res.roleClass === 'admin') {
                  if (adminViewAsCompany) {
                    fetchCompanyNotifications(adminViewAsCompany);
                    fetchStripeLoginLink(adminViewAsCompany);
                    getStripeCompanyStatus(adminViewAsCompany);
                    fetchCompanyData(adminViewAsCompany, true);
                    fetchComplianceData(res.company);
                    fetchCompanyOwnerData(adminViewAsCompany);
                    fetchCompanyVouchers(adminViewAsCompany);
                  }

                  fetchAdminStatistics();
                  fetchAdminSettings();
                  fetchAppSettings();
                }
              }

              this.setState({ userFetched: true });
            } else {
              this.setState({ userFetched: true });
            }
          });

          this.setState({ uid: user.uid });
        } else {
          const { hostname, search, pathname } = window.location;

        //  console.log('pathname', pathname);

          if (pathname === '/reset-password-confirm' || pathname === '/email-verify' || pathname === '/signup' || pathname === '/login' || pathname === '/T&Cs' || pathname === '/T&Cs-app' || pathname === '/privacy-policy' || pathname === '/privacy-policy-app') {

          } else {
            history.push('/login');
          }

          this.setState({
            uid: null,
            userFetched: true,
          });
        }
      });
    });
  }

  componentDidUpdate(prev) {
  //  console.log('componentDidUpdate', this.props.location.pathname, this.state.isMobile);
    if (this.state.isMobile && prev.location.pathname !== this.props.location.pathname) {
      console.log('call toggleSideCollapse');
      this.toggleSideCollapse();
    }
  }

  componentWillUnmount() {
    const { cancelActiveListeners, cancelActiveCompanyListeners, unsubscribeVouchersListeners, unsubscribeAdminListeners } = this.props;
    window.removeEventListener('resize', this.handleResize);

    cancelActiveListeners();
    cancelActiveCompanyListeners();
    unsubscribeVouchersListeners();
    unsubscribeAdminListeners();
  }

  logout = () => {
    const { logout, cancelActiveListeners, cancelActiveCompanyListeners, unsubscribeVouchersListeners, unsubscribeAdminListeners } = this.props;

    logout();

    cancelActiveListeners();
    cancelActiveCompanyListeners();
    unsubscribeVouchersListeners();
    unsubscribeAdminListeners();
  }

  logoutVerify = () => new Promise((resolve, reject) => {
   // console.log('logoutVerify');
    const { logout, cancelActiveListeners, cancelActiveCompanyListeners, unsubscribeVouchersListeners, unsubscribeAdminListeners } = this.props;
    const promises = [];
    promises.push(logout());

    promises.push(cancelActiveListeners());
    promises.push(cancelActiveCompanyListeners());
    promises.push(unsubscribeVouchersListeners());
    promises.push(unsubscribeAdminListeners());

  //  console.log('logoutVerify promises', promises.length);

    Promise.all(promises).then(() => {
      console.log('logoutVerify then');
      return resolve();
    }).catch(err => {
      console.log('logoutVerify err');
      return resolve();
    })
  })

  toggleSideCollapse = () => {
    console.log('toggleSideCollapse');
    this.setState(prevState => ({ sidebarCollapsed: !prevState.sidebarCollapsed }));
  };

  /*closeChat = () => {
    this.setState({ showChat1: false });
  }; */

  shadeColor2 = (color, percent) => {
    var R = parseInt(color.substring(1,3),16);
    var G = parseInt(color.substring(3,5),16);
    var B = parseInt(color.substring(5,7),16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R<255)?R:255;
    G = (G<255)?G:255;
    B = (B<255)?B:255;

    var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
  }

  shadeColor = (col, amt) => {
    col = col.replace(/^#/, '')
    if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

    let [r, g, b] = col.match(/.{2}/g);

    if (amt > 0) {
      if (b > r && b > g) {
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt + 30])
      } else if (r > b && r > g) {
        ([r, g, b] = [parseInt(r, 16) + amt + 30, parseInt(g, 16) + amt, parseInt(b, 16) + amt])
      } else if (g > r && g > b) {
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt + 30, parseInt(b, 16) + amt])
      } else {
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])
      }
    } else {
      if (b > r && b > g) {
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt - 20])
      } else if (r > b && r > g) {
        ([r, g, b] = [parseInt(r, 16) + amt - 30, parseInt(g, 16) + amt, parseInt(b, 16) + amt])
      } else if (g > r && g > b) {
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt - 30, parseInt(b, 16) + amt])
      } else {
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])
      }
    }


    r = Math.max(Math.min(255, r), 0).toString(16)
    g = Math.max(Math.min(255, g), 0).toString(16)
    b = Math.max(Math.min(255, b), 0).toString(16)

    const rr = (r.length < 2 ? '0' : '') + r
    const gg = (g.length < 2 ? '0' : '') + g
    const bb = (b.length < 2 ? '0' : '') + b

    return `#${rr}${gg}${bb}`
  }

  getPageTooltip = () => {
    const { companyTabsTooltips } = this.props;

    let name;
    routes.map(prop => {
      if (
        matchPath(this.props.location.pathname, {
          path: prop.path,
          exact: true,
          strict: false
        })
      ) {
        name = prop.name;
      }
      return null;
    });

    if (name === 'Api') {
      name = 'api';
    } else if (name === 'Dashboard') {
      name = 'dashboard';
    } else if (name === 'Create New Campaign') {
      name = 'createnewcampaign';
    } else if (name === 'Basic Information') {
      name = 'basicinformation';
    } else if (name === 'Payments') {
      name = 'payments';
    } else if (name === 'Users') {
      name = 'users';
    } else if (name === 'Setup Help') {
      name = 'setupHelp';
    } else if (name === 'Contact') {
      name = 'contact';
    }

    if (companyTabsTooltips && companyTabsTooltips[name]) {
      return companyTabsTooltips[name].value;
    } else {
      return name;
    }
  }

  render() {
    const {
      location,
      user,
      company,
      isLoggedIn,
      userDataFetched,
      roles,
      adminViewAsCompany,
      branding,
      fetchPendingTabData,
      match,
      companyTabsTooltips,
      stripeChargesEnabled,
      stripePayoutsEnabled,
      stripeDataFetched,
      shopifyApiKey,
      woocommerceApiKey,
      activeAPI,
      linkedCompaniesAPIs,
    } = this.props;

    const { sidebarCollapsed, userFetched, perksDisabled, perkTitle, isMobile } = this.state;
    const sidebarCollapsedClass = sidebarCollapsed ? 'side-menu-collapsed' : '';

    console.log('user user', user);

  //  console.log('userFetched', userFetched);
  //  console.log('this.props', this.props);

    if (location && location.pathname && location.pathname === "/reset-password-confirm") {
      return (
        < ResetPasswordPage
          {...this.props}
          nav={nav}
          logo={branding.logo || null}
          logoText={branding.siteName || ''}
          isSidebarCollapsed={sidebarCollapsed}
          toggleSidebar={this.toggleSideCollapse}
          shadeColor={this.shadeColor}
          logout={this.logoutVerify}
        />
      )
    }

    if (location && location.pathname && location.pathname === "/email-verify") {
      return (
        < EmailVerifyPage
          {...this.props}
          nav={nav}
          logo={branding.logo || null}
          logoText={branding.siteName || ''}
          isSidebarCollapsed={sidebarCollapsed}
          toggleSidebar={this.toggleSideCollapse}
          shadeColor={this.shadeColor}
          logout={this.logoutVerify}
        />
      )
    }



    if (adminViewAsCompany) {
      return (
        <AdminAsCompanyLayout { ...this.props } />
      );
    }

    return (
      <ContextProviders>
        <Helmet>
          <title>{branding.tabInfo || ''}</title>
          {/*  <link rel="shortcut icon" href={branding.favicon || ''} /> */}
          <link id="favicon" rel="icon" href={branding.favicon || ''} />
        </Helmet>
        {userFetched ? (
          <>
        <div style={{ backgroundColor: branding.contentBackgroundColor ? branding.contentBackgroundColor : '#f2f5f9' }} className={`app ${sidebarCollapsedClass}`}>
          <PageAlert />
          <div className="app-body">
              <SidebarNav
                  {...this.props}
                  nav={nav}
                  logo={branding.logo || null}
                  logoText={branding.siteName || ''}
                  isSidebarCollapsed={sidebarCollapsed}
                  isMobile={isMobile}
                  toggleSidebar={this.toggleSideCollapse}
                  shadeColor={this.shadeColor}
                  logout={this.logout}
                  perksDisabled={perksDisabled}
                  perkTitle={perkTitle}
                  stripeChargesEnabled={stripeChargesEnabled}
                  stripePayoutsEnabled={stripePayoutsEnabled}
                  stripeDataFetched={stripeDataFetched}
                  shopifyApiKey={shopifyApiKey}
                  woocommerceApiKey={woocommerceApiKey}
                  activeAPI={activeAPI}
                  linkedCompaniesAPIs={linkedCompaniesAPIs}
              />
          {/*  {(isLoggedIn && user && user.companyNumber) ? (
              <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='headerTooltip'>
                <span>{this.getPageTooltip()}</span>
              </ReactTooltip>
            ):(null)}  */}
                <Page>
              <Header
                {...this.props}
                toggleSidebar={this.toggleSideCollapse}
                    isSidebarCollapsed={sidebarCollapsed}
                    isMobile={isMobile}
                routes={routes}
                perksDisabled={perksDisabled}
                perkTitle={perkTitle}
                isCompanyHeader={(isLoggedIn && user && user.roleInCompany && user.company)}
              >
              {/*  <HeaderNav user={user} company={company} isLoggedIn={isLoggedIn} roles={roles} />  */}
              </Header>
              <PageContent>
                  {isLoggedIn && user && user.emailVerified && (!user.status || user.status === 'pending') ? (
                    <>
                      <PendingPage fetchPendingTabData={fetchPendingTabData} />
                    </>
                  ):(
                    <Switch>
                      {routes.map((page, key) => (
                        <Route path={page.path} component={page.component} key={key} />
                      ))}

                          <Redirect from="/" to={`${(isLoggedIn && userDataFetched) ? (((user && (user.role === 'linkedCompanyUser' || user.companyNumber || user.companyType))) ? '/dashboard' : '/admin/dashboard') : '/login'}`} />
                    </Switch>
                  )}
              </PageContent>
            </Page>
          </div>
          <Footer>
            <FooterComponent />
            </Footer>
            
            </div>
          </>
        ) : (null)}
      </ContextProviders>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  company: state.company.company || {},
  vouchers: state.vouchers.vouchers || null,
  complianceData: state.company.complianceData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  userDataFetched: state.user.userDataFetched || false,
  roles: { ...state.users.companiesRoles, ...state.admin.adminsRoles } || {},
  adminStatistics: state.admin.adminStatistics || null,
  adminViewAsCompany: state.admin.adminViewAsCompany || null,
  branding: state.contentManagement.branding || {},
  signposts: state.contentManagement.signposts || {},
  companyTabsTooltips: state.contentManagement.companyTabsTooltips || {},

  stripeChargesEnabled: state.user.stripeChargesEnabled || null,
  stripePayoutsEnabled: state.user.stripePayoutsEnabled || null,
  stripeDataFetched: state.user.stripeDataFetched || null,

  shopifyApiKey: state.company.shopifyApiKey || null,
  woocommerceApiKey: state.company.woocommerceApiKey || null,
  activeAPI: state.company.activeAPI || null,
  linkedCompaniesAPIs: state.company.linkedCompaniesAPIs || null,
  faqLinks: state.contentManagement.faqLinks || {},
  faqLinksArray: state.contentManagement.faqLinksArray || [],
});

const mapDispatchToProps = {
  getUser: getUserData,
  getRoles: getAllRoles,
  getAdminRoles: getAllAdminsRoles,
  cancelActiveListeners: cancelListeners,
  fetchCompanyData: getCompanyData,
  fetchComplianceData: getComplianceData,
  fetchCompanyOwnerData: getCompanyOwnerData,
  fetchCompanyNotifications: getCompanyNotifications,
  cancelActiveCompanyListeners: cancelCompanyListeners,
  fetchVoucherCategories: getCategories,
  fetchCompanyVouchers: getCompanyVouchers,
  unsubscribeVouchersListeners: unsubscribeVouchers,
  fetchAdminStatistics: getAdminStatistics,
  fetchAdminSettings: getAdminSettings,
  fetchAppSettings: getAppSettings,
  unsubscribeAdminListeners: cancelAdminListeners,
  fetchStripeLoginLink: getStripeLoginLink,
  getStripeCompanyStatus: checkStripeCompanyStatus,
  fetchBrandingData: getBrandingData,
  fetchSignposts: getSignposts,
  fetchFAQLinks: getFAQLinks,
  fetchCompanyTabsData: getCompanyTabsData,
  fetchPendingTabData: getPendingTabData,
  logout: doLogout,
  afterLogin: afterEmailLogin,
  fetchDisablePerksTab: getDisablePerksTab,
  fetchPerksTabTitle: getPerksTabTitle,

  getShopifyAPI: getShopifyAPIData,
  getWoocommerceAPI: getWoocommerceAPIData,
  getActiveAPI: getActiveAPIData,
  fetchLinkedAPIs: getLinkedAPIs,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);

function HeaderNav({ user, company, isLoggedIn, roles }) {
  return (
    <React.Fragment>
      {(isLoggedIn && user && user.uid) ? (
        <>
            <div className="userDropdown-wrapper">
              <h4 className="userDropdown-company">
                {(company && company.name) ? (company.name) : (user.companyName)}
              </h4>
            </div>
            <div className="userDropdown-wrapper">
              <h2 className="userDropdown-name">
                {user.displayName}
              </h2>
              <p className="userDropdown-role">
                <td>{(user.role === 'admin') ? 'Admin' : ((user.role === 'teamOwner') ? user.roleInCompany : (roles[user.role] ? roles[user.role].name : null))}</td>
              </p>
            </div>
        </>
      ):(
        null
      )}
    </React.Fragment>
  );
}
