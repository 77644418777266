import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import { verifyEmail, afterEmailLogin, markAsVerified } from '../../../actions/user';

//import { Firebase, FirebaseRef } from '../../../lib/firebase';
import { auth, firestore } from '../../../lib/firebase';

class EmailVerifyUpdate extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }
  // static defaultProps = {}

  constructor(props) {
    super(props);
    this.state = {
      mode: null,
      lang: 'en',
      newPassword: '',
      uid: '',
      customToken: '',
      actionCode: '',
    };
  }

  componentDidMount () {
    const { logout } = this.props;
    const { search } = window.location;

    logout().then(() => {

      let mode;
      let actionCode;
      let continueUrl;
      let lang;
      let uid;
      let customToken;

      try {
        const urlParams = new URLSearchParams(search);
        mode = urlParams.get('mode');
        actionCode = urlParams.get('oobCode');
        continueUrl = urlParams.get('continueUrl');
        lang = urlParams.get('lang') || 'en';
        uid = urlParams.get('uid') || '';
        customToken= urlParams.get('customToken') || '';
      } catch (err) {
        mode = this.getParameterByName('mode');
        actionCode = this.getParameterByName('oobCode');
        continueUrl = this.getParameterByName('continueUrl');
        lang = this.getParameterByName('lang') || 'en';
        uid = '';
        customToken = '';
      }

      this.setState({ mode, actionCode, lang, uid, customToken });

      switch (mode) {
        case 'resetPassword':
          // Display reset password handler and UI.
        //  this.handleResetPassword(actionCode, continueUrl, lang, uid);
          break;
        case 'recoverEmail':
          // Display email recovery handler and UI.
        //  this.handleRecoverEmail(actionCode, lang, uid);
          break;
        case 'verifyEmail':
          // Display email verification handler and UI.
          this.handleVerifyEmail(actionCode, continueUrl, lang, uid, customToken);
          break;
        default:
        // Error: invalid mode.
      }
    });
  }

/*
  handleResetPassword = (actionCode, continueUrl, lang) => {
    const { history } = this.props;
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    //  var accountEmail;
    // Verify the password reset code is valid.
    Firebase.auth().verifyPasswordResetCode(actionCode).then((email) => {
      const accountEmail = email;

      history.push(`/reset-password?email=${email}&actionCode=${actionCode}&lang=${lang}`);
      // TODO: Show the reset screen with the user's email and ask the user for
      // the new password.

      this.setState({ accountEmail, actionCode });
    }).catch((error) => {
      console.log(error);
      // Invalid or expired action code. Ask user to try to reset the password
      // again.
    });
  }

  submitResetPassword = () => {
    console.log('submitResetPassword');
    const { history } = this.props;
    const { newPassword, actionCode } = this.state;

    console.log(newPassword);
    console.log(actionCode);

    if (newPassword && newPassword.length > 6) {
      // Save the new password.
      Firebase.auth().confirmPasswordReset(actionCode, newPassword).then((resp) => {
        // Password reset has been confirmed and new password updated.

      //  history.push('/landing');

        // TODO: Display a link back to the app, or sign-in the user directly
        // if the page belongs to the same domain as the app:
        // auth.signInWithEmailAndPassword(accountEmail, newPassword);

        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      }).catch(function(error) {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
      });
    }
  }

  handleRecoverEmail = (actionCode, lang) => {
    console.log('handleRecoverEmail', actionCode);
  //  const { history } = this.props;
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    let restoredEmail = null;
    // Confirm the action code is valid.
    Firebase.auth().checkActionCode(actionCode).then((info) => {
      // Get the restored email address.
      console.log('checkActionCode', info);
      restoredEmail = info['data']['email'];

      console.log(restoredEmail);

      // Revert to the old email.
      return Firebase.auth().applyActionCode(actionCode);
    }).then(function() {
      // Account email reverted to restoredEmail
      console.log('Account email reverted to restoredEmail', restoredEmail);

      // TODO: Display a confirmation message to the user.

      // You might also want to give the user the option to reset their password
      // in case the account was compromised:
    //  Firebase.auth().sendPasswordResetEmail(restoredEmail).then(() => {
        // Password reset confirmation sent. Ask user to check their email.
    //  }).catch(function(error) {
        // Error encountered while sending password reset code.
    //  });
    }).catch(function(error) {
      console.log('error', error);
      // Invalid code.
    });
  } */

  handleVerifyEmail = (actionCode, continueUrl, lang, uid, customToken) => {
    console.log('handleVerifyEmail');
    const { history, afterLogin, markVerified } = this.props;
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
    auth.applyActionCode(actionCode).then(async (resp) => {
      // Email address has been verified.
      console.log('resp', resp);

      console.log('customToken', customToken, uid);
      await markVerified(uid);

      await afterLogin(customToken).then(() => {
        history.push('/email-verify-success');
      });

    //  auth.signInWithCustomToken(customToken);

      //TO DO Change user email

    //  setTimeout(() => {
    //  const usersRef = firestore.collection('users').doc(uid);

    //  await usersRef.update({
    //    email: uid,
    //  });
    //    history.push('/email-verify-success');
        // afterLogin();
    //  }, 1000);



      // TODO: Display a confirmation message to the user.
      // You could also provide the user with a link back to the app.

      // TODO: If a continue URL is available, display a button which on
      // click redirects the user back to the app via continueUrl with
      // additional state determined from that URL's parameters.
    }).catch(function(error) {
       console.log(error);
      // Code is invalid or expired. Ask the user to verify their email address
      // again.
    });
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      [event.target.name]: value,
    });
  }

  render() {
    const { mode } = this.state;

    if (mode === 'verifyEmail') {
      console.log('render verify page');
      return (
        <div style={{ position: 'relative', textAlign: 'center', color: '#FFF' }}>
          Email verified successfully.
        </div>
      );
    }

    return null;
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
});

const mapDispatchToProps = {
  afterLogin: afterEmailLogin,
  markVerified: markAsVerified,
 // setVerified: setVerifiedEmail,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailVerifyUpdate));
