import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Input, Label, Card, CardBody, Button } from 'reactstrap';

import { connect } from 'react-redux';

import {
  useJsonToCsv
} from 'react-json-csv';

import moment from 'moment';
import Moment from 'react-moment';

import ReactTooltip from 'react-tooltip';

import momentLocalizer from 'react-widgets-moment';

import DashboardGraph from '../../../components/Graphs/DashboardGraph';

moment.locale('en-GB');
momentLocalizer();

function calculateInterval(interval, valuesArray, logData) {
  let counter = 1;
  let temp = {};

  let newValues = [];
  const valuesArrayCloned = [...valuesArray];

  if (logData) {
    console.log('calculateInterval', interval, valuesArray);
  }

  valuesArrayCloned.reverse().map((value, index) => {
    if (counter >= interval || index === valuesArrayCloned.length - 1) {

      if (logData) {
        console.log('set key', interval, counter);
      }
      
      if (parseInt(interval, 10) === 1 || counter === 1 || index === valuesArrayCloned.length - 1) {
        Object.keys(value).map(key => {
          //  if (counter !== 1 && (key === 'Total clients' || key === 'Total companies' || key === 'Total campaigns')) {
          //    return null;
          //  }

          if (counter !== 1 && (key === 'Total clients' || key === 'Total companies' || key === 'Total campaigns')) {
            return null;
          }

          if (key !== 'key' && key !== 'date') {
            if (logData) {
              console.log('set temp +', key, value.date, value[key]);
            }
            temp[key] = (temp[key] || 0) + (value[key] || 0);
          }

          return null;
        });

        temp.key = value.key;
        temp.date = value.date;
      }
    //  temp.key = value.key;

    //  console.log('temp', temp, counter, valuesArrayCloned.length, index, interval);

      if (temp['Redemptions count percentage']) {
      //  console.log('update Redemptions count percentage temp', (temp['Redemptions count percentage'] / interval).toFixed(2));
        temp['Redemptions count percentage'] = (((temp['Redemptions count percentage'] / interval) * 100).toFixed(2) * 100) / 100;
      }

      if (temp['Redemptions value percentage']) {
      //  console.log('update Redemptions value percentage temp', (temp['Redemptions value percentage'] / interval).toFixed(2));
        temp['Redemptions value percentage'] = (((temp['Redemptions value percentage'] / interval) * 100).toFixed(2) * 100) / 100;
      }

      if (temp['Commission']) {
      //  console.log('update Commission temp', (temp['Commission'] / interval).toFixed(2));
        temp['Commission'] = ((temp['Commission']).toFixed(2) * 100) / 100;
      }
      if (logData) {
        console.log('push temp', temp);
      }

      newValues.push(temp);
      temp = {};
      counter = 1;
    } else {
      if (logData) {
        console.log('dont push temp', value.key, value.date);
      }

      Object.keys(value).map(key => {
      //  if ((key === 'Total clients' || key === 'Total companies' || key === 'Total campaigns')) {
      //    return null;
      //  }


      //  if (key === 'Redemptions count percentage') {
      //    console.log('Redemptions count percentage', temp, counter, valuesArrayCloned.length, index);
      //  }

        if (key === 'Total clients' || key === 'Total companies' || key === 'Total campaigns') {
          if (counter === 1) {
            if (logData) {
              console.log('set temp', key, value.date, value[key]);
            }
            temp[key] = value[key] || 0;
          }

          return null;
        }

        if (key !== 'key' && key !== 'date') {
          if (logData) {
            console.log('set temp +', key, value.date, value[key]);
          }
          temp[key] = (temp[key] || 0) + (value[key] || 0);
        }
        return null;
      });
      if (counter === 1) {
        temp.key = value.key;
        temp.date = value.date;
      }

      //Redemptions count percentage

      counter = counter + 1;
    }
  });
  if (logData) {
    console.log('newValues', newValues);
  }

  return newValues.reverse();
}



class LiveMetrics extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
  //  vouchers: PropTypes.shape(),
    fetchCompanyPaymentsTotals: PropTypes.func.isRequired,
  };

  static defaultProps = {
  //  vouchers: {},
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    let d = new Date();
    d.setDate(d.getDate() - 1);

    this.state = {
      error: null,
      loading: false,
      showNoPermission: false,
      startDate: d,
      endDate: new Date(),
      interval: 7,
      campaignFilter: 'all',
      timeFilter: 'month',
      metricFilter: 'vouchersCount',
      statisticsSalesValue: [],
      statisticsSalesCount: [],
      statisticsRedemptions: [],
      statisticsRefunds: [],
      statisticsCancelledVouchers: [],

      statisticsCompanies: [],
      statisticsCampaigns: [],
      statisticsCustomers: [],
    };
  }

  componentDidMount() {
    const { user, fetchVoucherSales, adminViewAsCompany, fetchCompanyPaymentsTotals } = this.props;

    if (user.company) {
    //  fetchVoucherSales(user.company, null, 'month', null, null);
    //  fetchCompanyPaymentsTotals(user.company, null, 'month', null, null);
    } else if (user.roleClass === 'admin' && adminViewAsCompany) {
    //  fetchVoucherSales(adminViewAsCompany, null, 'month', null, null);
    //  fetchCompanyPaymentsTotals(adminViewAsCompany, null, 'month', null, null);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      adminStatisticsSalesValue,
      adminStatisticsSalesCount,
      adminStatisticsRedemptionsCount,
    //  adminStatisticsRedemptionsValue,
    //  adminStatisticsRedemptionsPercentages,
      adminStatisticsRedemptions,
      adminStatisticsRefunds,
      adminStatisticsCancelledVouchers,
      adminStatisticsCompanies,
      adminStatisticsCampaigns,
      adminStatisticsCustomers,
      interval,
      adminDashboardLastUpdate,
    } = this.props;

  //  console.log('prevProps', prevProps);
  //  console.log('adminDashboardLastUpdate', adminDashboardLastUpdate, prevProps.adminDashboardLastUpdate);

    if (adminStatisticsSalesValue && interval && (adminDashboardLastUpdate !== prevProps.adminDashboardLastUpdate || prevProps.interval !== interval || (!prevProps.adminStatisticsSalesValue || adminStatisticsSalesValue.length !== prevProps.adminStatisticsSalesValue.length))) {
  //    console.log('data has changed', adminStatisticsSalesValue, interval, prevProps.adminStatisticsSalesValue, prevProps.interval);

    //  let counter = 1;
    //  let temp = {};

      console.log('adminStatisticsSalesValue 3333', adminStatisticsSalesValue);

      let statisticsSalesValue = calculateInterval(interval, adminStatisticsSalesValue, false) || [];
      let statisticsSalesCount = calculateInterval(interval, adminStatisticsSalesCount, false) || [];
      let statisticsRedemptions = calculateInterval(interval, adminStatisticsRedemptions, false) || [];
      let statisticsRefunds = calculateInterval(interval, adminStatisticsRefunds, false) || [];
      let statisticsCancelledVouchers = calculateInterval(interval, adminStatisticsCancelledVouchers, false) || [];

      let statisticsCompanies = calculateInterval(interval, adminStatisticsCompanies, false) || [];
      let statisticsCampaigns = calculateInterval(interval, adminStatisticsCampaigns, false) || [];
      let statisticsCustomers = calculateInterval(interval, adminStatisticsCustomers, false) || [];


   //   console.log('adminStatisticsCompanies 4444 statisticsCompanies', statisticsCompanies);

    /*  adminStatisticsSalesValue.map(value => {
        if (counter >= interval || counter === adminStatisticsSalesValue.length) {
          Object.keys(value).map(key => {
            temp[key] = (temp[key] || 0) + (value[key] || 0);
          });
          temp.key = value.key;

          newAdminStatisticsSalesValue.push(temp);
          temp = {};
          counter = 1;
        } else {
          Object.keys(value).map(key => {
            temp[key] = (temp[key] || 0) + (value[key] || 0);
          });
          temp.key = value.key;

          counter = counter + 1;
        }
      }); */


  //    console.log('statisticsSalesValue', statisticsSalesValue);
  //    console.log('statisticsSalesCount', statisticsSalesCount);
  //    console.log('adminStatisticsRedemptionsCount', adminStatisticsRedemptionsCount);
      this.setState({ statisticsSalesValue, statisticsSalesCount, statisticsRedemptions, statisticsRefunds, statisticsCancelledVouchers, statisticsCompanies, statisticsCampaigns, statisticsCustomers });
    }

  //  if (Object.keys(vouchers).length !== Object.keys(prevState.vouchers).length) {
  //    this.filter();
  //  }
  }

  handleChangeFilter = (e, type) => {
    const { interval } = this.state;
    const val = e.target.value;

    this.setState({ [type]: e.target.value }, () => {
    /*  if (type === 'timeFilter') {
        if (val === 'month' || val === 'year') {
          if (interval <= 2) {
            this.setState({ interval: 7 });
          } else if (val === '6months' && interval <= 7) {
            this.setState({ interval: 14 });
          }
        } else if (val === 'day' || val === 'week') {
          if (interval >= 15) {
            this.setState({ interval: 14 });
          }
        }
      } */

      if (type !== 'interval') {
      //  this.filter();
      }
    });
  };

  changeStartDate = (date) => {
    const todayDate = new Date();

    if (date > todayDate) {
      this.setState({ startDate: todayDate }, () => {
      //  this.filter();
      });
    } else {
      this.setState({ startDate: date }, () => {
      //  this.filter();
      });
    }

  }

  changeEndDate = (date) => {
    const todayDate = new Date();

    if (date > todayDate) {
      this.setState({ endDate: todayDate }, () => {
      //  this.filter();
      });
    } else {
      this.setState({ endDate: date }, () => {
      //  this.filter();
      });
    }
  }

  filter = () => {
    const { user, fetchVoucherSales, adminViewAsCompany } = this.props;
    const { campaignFilter, timeFilter, startDate, endDate } = this.state;

  //  console.log('filter', user);

    if (campaignFilter === 'all') {
      if (user.company) {
        if (timeFilter === 'custom') {
          fetchVoucherSales(user.company, null, timeFilter, startDate, endDate);
        } else if (timeFilter === 'allTime') {
          let allTimeStartDate = new Date('2022-08-01T00:00:00');

          if (user.dateCreated) {
            allTimeStartDate = new Date(user.dateCreated);
          }

        //  console.log('filter allTimeStartDate', allTimeStartDate);

          fetchVoucherSales(user.company, null, timeFilter, allTimeStartDate, null);
        } else {
          fetchVoucherSales(user.company, null, timeFilter, null, null);
        }
      } else if (adminViewAsCompany) {
        if (timeFilter === 'custom') {
          fetchVoucherSales(adminViewAsCompany, null, timeFilter, startDate, endDate);
        } else if (timeFilter === 'allTime') {
          let allTimeStartDate = new Date('2022-08-01T00:00:00');

        //  console.log('filter allTimeStartDate', allTimeStartDate);

          fetchVoucherSales(user.company, null, timeFilter, allTimeStartDate, null);
        } else {
          fetchVoucherSales(adminViewAsCompany, null, timeFilter, null, null);
        }
      }
    } else {
      if (timeFilter === 'custom') {
        fetchVoucherSales(null, campaignFilter, timeFilter, startDate, endDate);
      } else {
        fetchVoucherSales(null, campaignFilter, timeFilter, null, null);
      }
    }
  }

/*
  refresh = () => {
    const { user, fetchVoucherSales, adminViewAsCompany } = this.props;
    const { campaignFilter, timeFilter, startDate, endDate } = this.state;

    if (campaignFilter === 'all') {
      if (user.company) {
        if (timeFilter === 'custom') {
          fetchVoucherSales(user.company, null, timeFilter, startDate, endDate);
        } else {
          fetchVoucherSales(user.company, null, timeFilter, null, null);
        }
      } else if (adminViewAsCompany) {
        if (timeFilter === 'custom') {
          fetchVoucherSales(adminViewAsCompany, null, timeFilter, startDate, endDate);
        } else {
          fetchVoucherSales(adminViewAsCompany, null, timeFilter, null, null);
        }
      }
    } else {
      if (timeFilter === 'custom') {
        fetchVoucherSales(null, campaignFilter, timeFilter, startDate, endDate);
      } else {
        fetchVoucherSales(null, campaignFilter, timeFilter, null, null);
      }
    }
  } */

  refresh = () => {
    const { user, fetchVoucherSales, adminViewAsCompany, refreshLiveMetrics } = this.props;
    const { campaignFilter, timeFilter, startDate, endDate } = this.state;


    if (user.company) {
      if (timeFilter === 'allTime') {
       let allTimeStartDate = new Date('2022-08-01T00:00:00');

       if (user.dateCreated) {
         allTimeStartDate = new Date(user.dateCreated);
       }

    //   console.log('filter allTimeStartDate', allTimeStartDate);

       refreshLiveMetrics(user.company, campaignFilter, timeFilter, allTimeStartDate, null);
     } else {
       refreshLiveMetrics(user.company, campaignFilter, timeFilter, startDate, endDate);
     }
    //  refreshLiveMetrics(user.company, campaignFilter, timeFilter, startDate, endDate)
    } else if (adminViewAsCompany) {
      refreshLiveMetrics(adminViewAsCompany, campaignFilter, timeFilter, startDate, endDate);
    }
  }

  renderCustomizedLabel = (props) => {
    const { vouchersByDate } = this.props;
    const { x, y, width, value, index } = props;
    const radius = 10;

    if (index === vouchersByDate.length - 1) {
      return (
        <g>
          <text x={x + (width || 1) / 2} y={y - radius} fill="#000" textAnchor="middle" dominantBaseline="middle">
            {value}
          </text>
        </g>
      );
    }

    return null;
  };


  downloadData = (data) => {
    console.log('downloadData', data);
    const { saveAsCsv } = useJsonToCsv();

      const dataFiltered = [];
      const fields = {}

      const first = data[0] || {};

      console.log('first', first);

      Object.keys(first).map((key) => {
        fields[key] = key;
      });

    /*  data.map((key) => {
        dataFiltered.push({
          ...data[key]
        });

        fields[key] = key;

        return null;
      }); */

      console.log('downloadData 2', dataFiltered, fields);

      saveAsCsv({ data: data, fields, filename: 'Data' });
  }

  render() {
    const {
      vouchers,
      vouchersCount,
      vouchersUsedCount,
      vouchersValue,
      vouchersByDate,
      dashboardTooltips,

      adminStatisticsFaceValueSales,
      adminStatisticsSalesValue,

    //  adminStatisticsRefunds,
      adminStatisticsSalesCount,
      adminStatisticsCommission,
      adminStatisticsRedemptionsCount,
      adminStatisticsRedemptionsValue,
      adminStatisticsRedemptionsPercentages,
      adminStatisticsRedemptions,
      adminStatisticsRefundsCount,
      adminStatisticsRefundsValue,
      adminStatisticsRefunds,
      adminStatisticsGiftedCount,
      adminStatisticsGiftedValue,

      adminStatisticsCompanies,
      adminStatisticsCampaigns,
      adminStatisticsCustomers,
      interval,
      adminDashboardLastUpdate,
    } = this.props;
    const {
      showNoPermission,
      campaignFilter,
      timeFilter,
      metricFilter,
      startDate,
      endDate,

      statisticsSalesValue,
      statisticsSalesCount,
      statisticsRedemptions,
      statisticsRefunds,
      statisticsCancelledVouchers,

      statisticsCompanies,
      statisticsCampaigns,
      statisticsCustomers,
    } = this.state;

    console.log('statisticsRefunds', statisticsRefunds);
    console.log('statisticsSalesCount', statisticsSalesCount);
    console.log('statisticsSalesValue', statisticsSalesValue);

    return (
      <>
        <Card className="live-metrics-card" style={{ marginTop: 10 }}>
          <CardBody>
            <Row className="live-metrics"> {/*className="live-metrics"*/}
              <Col sm="3" data-tip data-for='voucher-sales-count'>
                <Row>
                  <Col sm="12">
                    <Label for="voucher-sales-count" className="live-metric voucher-sales-count">
                      Units (number)
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={{ size: 12 }}>
                    {dashboardTooltips.voucherSalesCount ? (
                      <ReactTooltip place="top" className='react-tooltip-custom-class' effect="solid" id='voucher-sales-count'>
                        <span>{dashboardTooltips.voucherSalesCount ? dashboardTooltips.voucherSalesCount.value : ''}</span>
                      </ReactTooltip>
                    ) : (null)}
                    {/*  <DashboardGraph type={'line'} data={statisticsSalesCount} interval={1} grid={false} dataKey={'Sales count'} name={'Units (number)'} /> */}
                    <DashboardGraph
                      type={'multi'}
                      data={statisticsSalesCount}
                      interval={1}
                      grid={false}
                      fill="#D4E9F7"
                      //  dataKey={'data'}
                      dataKey={[
                        { data: 'Self count', type: 'area', name: 'Self', axis: 'left' },
                        { data: 'Gifting count', type: 'area', name: 'Gifting', axis: 'left' },
                        { data: 'Sales count', type: 'area', name: 'Total', axis: 'left' },
                      ]}
                      name={'Units (number)'}
                    />
                    {/*   <DashboardGraph isSampleData={isSampleData} type={'area'} fill="#D4E9F7" data={vouchersByDate} interval={1} dataKey={'vouchersCount'} name={'Voucher sales (#)'} /> */}
                    {/*  <Button className="live-metrics-export-button" color="link" onClick={() => this.downloadData(statisticsSalesCount)}>
                      Excel
                    </Button> */}
                  </Col>
                </Row>
              </Col>
              <Col sm="3" data-tip data-for='voucher-sales-sum'>
                <Row>
                  <Col sm="12">
                    <Label className="live-metric voucher-sales-sum">
                      Payments (value)
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={{ size: 12 }}>
                    {dashboardTooltips.voucherSales ? (
                      <ReactTooltip place="top" className='react-tooltip-custom-class' effect="solid" id='voucher-sales-sum'>
                        <span>{dashboardTooltips.voucherSales ? dashboardTooltips.voucherSales.value : ''}</span>
                      </ReactTooltip>
                    ) : (null)}
                    <DashboardGraph
                      type={'multi'}
                      data={statisticsSalesValue}
                      interval={1}
                      grid={false}
                      //  dataKey={'data'}
                      dataKey={[
                        { data: 'Commission', type: 'bar', name: 'Commission', axis: 'right' },
                        { data: 'Customer payments Voucher', type: 'area', name: 'All Vouchers', axis: 'left' },
                        { data: 'Customer payments Gifting', type: 'area', name: 'All Gifting', axis: 'left' },
                        { data: 'Customer payments', type: 'area', name: 'Total (Vouchers + Gifting)', axis: 'left' },
                        { data: 'Face value sales', type: 'area', name: 'Total face value', axis: 'left' },
                      ]}
                      name={'Voucher sales (£)'}
                    />
                    {/*   <Button className="live-metrics-export-button" color="link" onClick={() => this.downloadData(statisticsSalesValue)}>
                      Excel
                    </Button> */}

                  </Col>
                </Row>
              </Col>
              <Col sm="3" data-tip data-for='voucher-redemtions'>
                <Row>
                  <Col sm="12">
                    <Label className="live-metric voucher-redemtions">
                      Refunds (payments)
                    </Label>
                  </Col>
                </Row>
                <Row>

                  <Col sm={{ size: 12 }}>
                    {dashboardTooltips.refundsCount ? (
                      <ReactTooltip place="top" className='react-tooltip-custom-class' effect="solid" id='voucher-redemtions'>
                        <span>{dashboardTooltips.refundsCount ? dashboardTooltips.refundsCount.value : ''}</span>
                      </ReactTooltip>
                    ) : (null)}
                    <DashboardGraph
                      type={'multi'}
                      data={statisticsRefunds}
                      interval={1}
                      grid={false}
                      dataKey={[
                        { data: 'Refunds voucher count', type: 'area', name: 'Vouchers (#)', axis: 'left' },
                        { data: 'Refunds voucher value', type: 'area', name: 'Vouchers (£)', axis: 'right' },
                        { data: 'Refunds gift count', type: 'area', name: 'Gifting (#)', axis: 'left' },
                        { data: 'Refunds gift value', type: 'area', name: 'Gifting (£)', axis: 'right' },
                        { data: 'Refunds value', type: 'area', name: 'Total (£)', axis: 'right' },
                      ]}
                      name={'Refunds'}
                    />
                    {/*  <Button className="live-metrics-export-button" color="link" onClick={() => this.downloadData(statisticsRefunds)}>
                      Excel
                    </Button> */}
                    {/*  <DashboardGraph type={'bar'} data={adminStatisticsRefunds} interval={interval} dataKey={'data'} name={'Voucher sales (£)'} /> */}

                  </Col>
                </Row>
              </Col>

              <Col sm="3" data-tip data-for='voucher-redemtions'>
                <Row>
                  <Col sm="12">
                    <Label className="live-metric voucher-redemtions">
                      Cancellations (face values)
                    </Label>
                  </Col>
                </Row>
                <Row>

                  <Col sm={{ size: 12 }}>
                    {dashboardTooltips.cancelledCount ? (
                      <ReactTooltip place="top" className='react-tooltip-custom-class' effect="solid" id='voucher-redemtions'>
                        <span>{dashboardTooltips.cancelledCount ? dashboardTooltips.cancelledCount.value : ''}</span>
                      </ReactTooltip>
                    ) : (null)}
                    <DashboardGraph
                      type={'multi'}
                      data={statisticsCancelledVouchers}
                      interval={1}
                      grid={false}
                      dataKey={[
                        { data: 'Cancelled vouchers count', type: 'area', name: 'Vouchers (number)', axis: 'left' },
                        { data: 'Cancelled vouchers value', type: 'area', name: 'Vouchers (value)', axis: 'right' }
                      ]}
                      name={'Cancelled vouchers'}
                    />
                    {/*    <Button className="live-metrics-export-button" color="link" onClick={() => this.downloadData(statisticsCancelledVouchers)}>
                      Excel
                    </Button> */}
                    {/*  <DashboardGraph type={'bar'} data={adminStatisticsRefunds} interval={interval} dataKey={'data'} name={'Voucher sales (£)'} /> */}

                  </Col>
                </Row>
              </Col>
        </Row>
        </CardBody>
      </Card>
      <Card className="live-metrics-card">
        <CardBody>

        <Row className="live-metrics">
          <Col sm="4" data-tip data-for='voucher-sales-count'>
                <Row>
                  <Col sm="12">
                    <Label for="voucher-sales-count" className="live-metric voucher-sales-count">
                      Redemptions (number)
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={{ size: 12 }}>
                  {dashboardTooltips.redemptionsCount ? (
                    <ReactTooltip place="top" className='react-tooltip-custom-class' effect="solid" id='voucher-sales-count'>
                      <span>{dashboardTooltips.redemptionsCount ? dashboardTooltips.redemptionsCount.value : ''}</span>
                    </ReactTooltip>
                  ):(null)}
                    <DashboardGraph type={'area'} data={statisticsRedemptions} interval={1} grid={false} dataKey={'Redemptions count'} name={'Redemptions (number)'} />
                    {/*  <Button className="live-metrics-export-button" color="link" onClick={() => this.downloadData(statisticsRedemptions)}>
                    Excel
                  </Button> */}
                  </Col>
                </Row>
          </Col>
          <Col sm="4" data-tip data-for='voucher-sales-sum'>
                <Row>
                  <Col sm="12">
                    <Label className="live-metric voucher-sales-sum">
                      Redemptions (value)
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={{ size: 12 }}>
                  {dashboardTooltips.redemptionsValue ? (
                    <ReactTooltip place="top" className='react-tooltip-custom-class' effect="solid" id='voucher-sales-sum'>
                      <span>{dashboardTooltips.redemptionsValue ? dashboardTooltips.redemptionsValue.value : ''}</span>
                    </ReactTooltip>
                  ):(null)}
                    <DashboardGraph type={'area'} data={statisticsRedemptions} interval={1} grid={false} dataKey={'Redemptions value'} name={'Redemptions (value)'} />
                    {/* <Button className="live-metrics-export-button" color="link" onClick={() => this.downloadData(statisticsRedemptions)}>
                    Excel
                  </Button> */}
                  </Col>
                </Row>
          </Col>
          <Col sm="4" data-tip data-for='voucher-redemtions'>
                <Row>
                  <Col sm="12">
                    <Label className="live-metric voucher-redemtions">
                      Redemptions (%)
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={{ size: 12 }}>
                  {dashboardTooltips.refundsCount ? (
                    <ReactTooltip place="top" className='react-tooltip-custom-class' effect="solid" id='voucher-redemtions'>
                      <span>{dashboardTooltips.refundsCount ? dashboardTooltips.refundsCount.value : ''}</span>
                    </ReactTooltip>
                  ):(null)}

                  <DashboardGraph
                    type={'multi'}
                    data={statisticsRedemptions}
                    interval={1}
                    grid={false}
                    dataKey={[
                      { data: 'Redemptions count percentage', type: 'area', name: 'Redemptions % by number', axis: 'left'},
                      { data: 'Redemptions value percentage', type: 'area', name: 'Redemptions % by value', axis: 'right'}
                    ]}
                    name={'Refunds % (£)'}
                  />
                    {/*  <Button className="live-metrics-export-button" color="link" onClick={() => this.downloadData(statisticsRedemptions)}>
                    Excel
                  </Button> */}

                  </Col>
                </Row>
          </Col>
        </Row>
        </CardBody>
      </Card>
      <Card className="live-metrics-card">
        <CardBody>
        <Row className="live-metrics">
          <Col sm="4" data-tip data-for='voucher-sales-count'>

                <Row>
                  <Col sm="12">
                    <Label for="voucher-sales-count" className="live-metric voucher-sales-count">
                      Companies
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={{ size: 12 }}>
                  {dashboardTooltips.redemptionsCount ? (
                    <ReactTooltip place="top" className='react-tooltip-custom-class' effect="solid" id='voucher-sales-count'>
                      <span>{dashboardTooltips.redemptionsCount ? dashboardTooltips.redemptionsCount.value : ''}</span>
                    </ReactTooltip>
                  ):(null)}
                  <DashboardGraph
                    type={'multi'}
                    data={statisticsCompanies}
                    interval={1}
                    grid={false}
                    dataKey={[
                      {data: 'Total companies', type: 'bar', name: 'Total Companies', axis: 'right'},
                      { data: 'New companies', type: 'area', name: 'New Companies', axis: 'left'},
                      { data: 'Deleted companies', type: 'area', name: 'Deleted Companies', axis: 'left'}
                    ]}
                    name={'Companies'}
                  />
                    {/*  <Button className="live-metrics-export-button" color="link" onClick={() => this.downloadData(statisticsCompanies)}>
                    Excel
                  </Button> */}
                  </Col>
                </Row>
          </Col>
          <Col sm="4" data-tip data-for='voucher-sales-sum'>
                <Row>
                  <Col sm="12">
                    <Label className="live-metric voucher-sales-sum">
                      Campaigns
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={{ size: 12 }}>
                  {dashboardTooltips.redemptionsValue ? (
                    <ReactTooltip place="top" className='react-tooltip-custom-class' effect="solid" id='voucher-sales-sum'>
                      <span>{dashboardTooltips.redemptionsValue ? dashboardTooltips.redemptionsValue.value : ''}</span>
                    </ReactTooltip>
                  ):(null)}
                  <DashboardGraph
                    type={'multi'}
                    data={statisticsCampaigns}
                    interval={1}
                    grid={false}
                    dataKey={[
                      {data: 'Total campaigns', type: 'bar', name: 'Total Campaigns', axis: 'right'},
                      { data: 'New campaigns', type: 'area', name: 'New Campaigns', axis: 'left'},
                      { data: 'Ended campaigns', type: 'area', name: 'Ended Campaigns', axis: 'left'}
                    ]}
                    name={'Campaigns'}
                  />
                    {/*  <Button className="live-metrics-export-button" color="link" onClick={() => this.downloadData(statisticsCampaigns)}>
                    Excel
                  </Button */}
                  </Col>
                </Row>
          </Col>
          <Col sm="4" data-tip data-for='voucher-redemtions'>
                <Row>
                  <Col sm="12">
                    <Label className="live-metric voucher-redemtions">
                      Live customers
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={{ size: 12 }}>
                  {dashboardTooltips.refundsCount ? (
                    <ReactTooltip place="top" className='react-tooltip-custom-class' effect="solid" id='voucher-redemtions'>
                      <span>{dashboardTooltips.refundsCount ? dashboardTooltips.refundsCount.value : ''}</span>
                    </ReactTooltip>
                  ):(null)}
                  <DashboardGraph
                    type={'multi'}
                    data={statisticsCustomers}
                    interval={1}
                    grid={false}
                    dataKey={[
                      { data: 'Total clients', type: 'bar', name: 'Total customers', axis: 'right' },
                      { data: 'New clients', type: 'area', name: 'New customers', axis: 'left' },
                      { data: 'Deleted clients', type: 'area', name: 'Deleted customers', axis: 'left' }
                    ]}
                    name={'Customers'}
                  />
                    {/*  <Button className="live-metrics-export-button" color="link" onClick={() => this.downloadData(statisticsCustomers)}>
                    Excel
                  </Button> */}
                  </Col>
                </Row>

          </Col>
        </Row>
        </CardBody>
      </Card>


      {/*  <Row>
          <Col>
            <ResponsiveContainer width='92%' height={400} marginLeft={'auto'} marginRight={'auto'}>
              <BarChart data={vouchersByDate} margin={{ left: -10, right: 10 }}>
                <XAxis dataKey="key" />

                <YAxis width={80} yAxisId="left" tick={{ fontSize: 10 }} label={{ value: metricFilter === 'vouchersValue' ? '£' : '#', angle: 0, position: 'insideLeft', offset: 40 }} >
                </YAxis>

                <Tooltip />
                <Bar yAxisId="left" type="monotone"  dataKey={metricFilter} name={metricFilter === 'vouchersCount' ? 'Voucher sales (#)' : (metricFilter === 'vouchersUsed' ? 'Voucher redemptions (#)' : 'Voucher sales (£)')} fill="#5CB4FF" />
            </BarChart>
            </ResponsiveContainer>
          </Col>
        </Row> */}
      </>
    );
  }
}

const mapStateToProps = state => {
  return({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || {},
  vouchersData: state.vouchers || {},
  vouchersCount: state.vouchers.vouchersCount || 0,
  vouchersUsedCount: state.vouchers.vouchersUsedCount || 0,
  vouchersValue: state.vouchers.vouchersValue || 0,
  vouchersByDate: state.vouchers.vouchersByDate || [],
  adminViewAsCompany: state.admin.adminViewAsCompany || null,

  // adminDashboard: state.admin.adminDashboard || {},

  adminStatisticsLastRefresh: state.admin.adminStatisticsLastRefresh || null,

  adminStatisticsFaceValueSales: state.admin.adminStatisticsFaceValueSales || [],
  adminStatisticsSalesValue: state.admin.adminStatisticsSalesValue || [],
  adminStatisticsSalesCount: state.admin.adminStatisticsSalesCount || [],
  adminStatisticsCommission: state.admin.adminStatisticsCommission || [],
  adminStatisticsRedemptionsCount: state.admin.adminStatisticsRedemptionsCount || [],
  adminStatisticsRedemptionsValue: state.admin.adminStatisticsRedemptionsValue || [],
  adminStatisticsRefundsCount: state.admin.adminStatisticsRefundsCount || [],
  adminStatisticsRefundsValue: state.admin.adminStatisticsRefundsValue || [],
  adminStatisticsRefunds: state.admin.adminStatisticsRefunds || [],
  adminStatisticsCancelledVouchers: state.admin.adminStatisticsCancelledVouchers || [],
  adminStatisticsRedemptionsPercentages: state.admin.adminStatisticsRedemptionsPercentages || [],
  adminStatisticsRedemptions: state.admin.adminStatisticsRedemptions || [],
  adminStatisticsGiftedCount: state.admin.adminStatisticsGiftedCount || [],
  adminStatisticsGiftedValue: state.admin.adminStatisticsGiftedValue || [],

  adminStatisticsCompanies: state.admin.adminStatisticsCompanies || [],
  adminStatisticsCampaigns: state.admin.adminStatisticsCampaigns || [],
  adminStatisticsCustomers: state.admin.adminStatisticsCustomers || [],

  //adminDashboardAgregated: state.admin.adminDashboardAgregated || {},
  //adminDashboardCustom: state.admin.adminDashboardCustom || {},
  adminStatistics: state.admin.adminStatistics || {},
  adminDashboardLastUpdate: state.admin.adminDashboardLastUpdate || null,
  //adminDashboardTotals: state.admin.adminDashboardTotals || {},
  //adminDashboardStripe: state.admin.adminDashboardStripe || {},
  //adminDashboardStripeAgregated: state.admin.adminDashboardStripeAgregated || {},
  //adminDashboardStripeCustom: state.admin.adminDashboardStripeCustom || {},
  // adminAnalyticsTemplate: state.admin.adminAnalyticsTemplate || {},

  dashboardTooltips: state.contentManagement.dashboardTooltips || {},
});
}

const mapDispatchToProps = {
//  fetchVoucherSales: getVoucherSales,
//  fetchCompanyPayouts: getCompanyPayouts,
//  fetchCompanyPaymentsTotals: getCompanyPaymentsTotals,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveMetrics);
