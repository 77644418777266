import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Card, CardBody, Table, Button, Input, Label, Form, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { connect } from 'react-redux';

//import FileUploader from "react-firebase-file-uploader";
//import ReactPlayer from 'react-player/lazy';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState } from 'draft-js';
import 'draft-js/dist/Draft.css';

import { stateFromHTML } from 'draft-js-import-html';

import htmlToDraft from 'html-to-draftjs';

import {
  getTutorialVideos,
  getResourceCentre,
} from '../../../../actions/resourceCenter';

// import { storage } from '../../../../lib/firebase';

class Videos extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    fetchTutorialVideos: PropTypes.func.isRequired,
    fetchResourceCentre: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    const { fetchResourceCentre, fetchTutorialVideos, admin, user, isLoggedIn, history } = this.props;

    fetchTutorialVideos();
    //  fetchResourceCentre();
    fetchResourceCentre().then((res) => {
      if (res.payload) {
        //  let contentState = stateFromHTML(res.payload);

        const blocksFromHtml = htmlToDraft(res.payload);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        if (contentState) {
          this.setState({ editorState: EditorState.createWithContent(contentState) });
        }
      }
    });
  }

  openView = (url) => {
    this.setState({ viewVideoModal: true, activeVideo: url });
  }

  closeViewModal = () => {
    this.setState({ viewVideoModal: false, activeVideo: null });
  }

  render() {
    const { videos } = this.props;
    const { viewVideoModal, activeVideo, editorState } = this.state;

    return (
      <div>
        <Row>
          <Col xs="12">
              <Editor
                className="content-management-text-area"
                editorState={editorState}
                toolbarHidden
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                readOnly
              />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  videos: state.resourceCenter.videos || [],
  //  resourceCentreText: state.resourceCenter.resourceCentreText || '',
});

const mapDispatchToProps = {
  fetchTutorialVideos: getTutorialVideos,
  fetchResourceCentre: getResourceCentre,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Videos));

// export default Dashboard;
