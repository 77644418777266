import React, { Component } from 'react';
import * as Feather from 'react-feather';
import NavBadge from './NavBadge';
import NavSingleItem from './NavSingleItem';
import NavSingleItemWithCount from './NavSingleItemWithCount';

export default class NavDropdownItem extends Component {
  constructor(props) {
    super(props);

    const { item } = props;
    if (item.name === 'Admin' || item.name === 'Company') {
      this.state = {
        open: true
      };
    } else {
      this.state = {
        open: false
      };
    }
  }
  toggle = e => {
    this.setState(prevState => ({ open: !prevState.open }));
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    const { item, user, adminStatistics, branding, shadeColor } = this.props;

    const isExpanded = this.state.open ? 'open' : '';
    const Icon = item.icon ? Feather[item.icon] : null;
    const ExpandIcon = this.state.open
      ? Feather.ChevronDown
      : Feather.ChevronRight;
    return (
      <li className={`nav-item has-submenu ${isExpanded}`}>
        <a
          style={{
            color: branding.sidebarTextColor,
            backgroundColor: branding.sidebarPrimaryColor ? branding.sidebarPrimaryColor : '#0b1628'
          }}
          className="has-submenu-hover"
          href="#!"
          role="button"
          onClick={this.toggle}
        >
          {item.icon && Icon && <Icon className="side-nav-icon" />}
          <span className="nav-item-label">{item.name}</span>{' '}
          {item.badge && (
            <NavBadge color={item.badge.variant} text={item.badge.text} />
          )}
          <ExpandIcon className="menu-expand-icon" />
        </a>
        {(this.state.open || this.props.isSidebarCollapsed) && (
          <ul className="nav-submenu">
            {item.children.map((child, index) => {
              if (adminStatistics && adminStatistics.newCompaniesCount && adminStatistics.newCompaniesCount.count && child.name && child.name === 'Companies') {
                return (<NavSingleItemWithCount shadeColor={shadeColor} branding={branding} item={child} key={index} count={adminStatistics.newCompaniesCount.count} />)
              } else if (adminStatistics && adminStatistics.pendingVouchersCount && adminStatistics.pendingVouchersCount.count && child.name && child.name === 'Campaigns') {
                return (<NavSingleItemWithCount shadeColor={shadeColor} branding={branding} item={child} key={index} count={adminStatistics.pendingVouchersCount.count} />)
              } else if (child.name && child.name.includes('[Company name]')) {
                if (user && user.companyName) {
                  const newName = child.name.replace('[Company name]', user.companyName);
                  const newChild = { url: child.url, name: newName };

                  return (<NavSingleItem isChild={true} branding={branding} item={newChild} key={index} shadeColor={shadeColor} />)
                } else {
                //  const newChild = { url: child.url, name: 'Company Dashboard' }
                  return (<NavSingleItem isChild={true} branding={branding} item={child} key={index} shadeColor={shadeColor} />)
                }
              } else {
                return (<NavSingleItem isChild={true} branding={branding} item={child} key={index} shadeColor={shadeColor} />)
              }
            })}
          </ul>
        )}
      </li>
    );
  }
}
