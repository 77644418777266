import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import { Card, CardBody, Row, Col, Input, Label, Form, FormGroup, Button, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';

import { verifyEmail, afterEmailLogin, markAsVerified } from '../../../actions/user';

//import { Firebase, FirebaseRef } from '../../../lib/firebase';
import { auth, firestore } from '../../../lib/firebase';

class EmailVerify extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }
  // static defaultProps = {}

  constructor(props) {
    super(props);
    this.state = {
      mode: null,
      newPassword: '',
      uid: '',
      customToken: '',
      actionCode: '',
      isAppUser: false,
    };
  }

  componentDidMount() {
    const { logout, history, verifyUserEmail } = this.props;
    const { search } = window.location;

    console.log('componentDidMount emailVer');

    logout().then(() => {
      console.log('componentDidMount logout then');

      let mode;
      let actionCode;
      let continueUrl;
      let type;
      let uid;
      let customToken;
      let isAppUser;
      let isCompanyUser;

      try {
        const urlParams = new URLSearchParams(search);
        mode = urlParams.get('mode');
        actionCode = urlParams.get('oobCode');
        continueUrl = urlParams.get('continueUrl');
        type = urlParams.get('type') || 'verified';
        uid = urlParams.get('uid') || '';
        customToken= urlParams.get('customToken') || '';
        isAppUser= urlParams.get('isAppUser') || false;
        isCompanyUser= urlParams.get('isCompanyUser') || false;
      } catch (err) {
        mode = this.getParameterByName('mode');
        actionCode = this.getParameterByName('oobCode');
        continueUrl = this.getParameterByName('continueUrl');
        type = this.getParameterByName('type') || 'verified';
        uid = this.getParameterByName('uid') || '';
        customToken = this.getParameterByName('customToken') || '';
        isAppUser = this.getParameterByName('isAppUser') || false;
        isCompanyUser = this.getParameterByName('isCompanyUser') || false;
      }

      if (isAppUser === 'false') {
        isAppUser = false;
      }

      if (isCompanyUser === 'false') {
        isCompanyUser = false;
      }

      // https://app.steppr.com/email-verify?mode=&oobCode=Bb0lyxtFEiKVvvcZBuxRshl-tE2PFpBm_1PIeskj1JYAAAGE7npGQQ&apiKey=AIzaSyCOQPwaCEG54uhw0Z65e4Oy9HDAOzy1Ou0&lang=en

      this.setState({ mode, actionCode, uid, customToken, isAppUser, isCompanyUser });

      console.log('componentDidMount emailVerify', mode, actionCode, continueUrl, uid, customToken, type, isAppUser, isCompanyUser);

      switch (mode) {
        case 'resetPassword':
          // Display reset password handler and UI.
          this.handleResetPassword(actionCode, continueUrl, uid, customToken, type, isCompanyUser);
          break;
        case 'recoverEmail':
          // Display email recovery handler and UI.
          this.handleRecoverEmail(actionCode, uid, customToken, type);
          break;
        case 'verifyEmail':
          // Display email verification handler and UI.
          this.handleVerifyEmail(actionCode, continueUrl, uid, customToken, type);
          break;
        case 'revertSecondFactorAddition':
          this.handleRevertSecondFactorAddition(actionCode, continueUrl, uid, customToken, type);
          break;
        default:
        // Error: invalid mode.
      }
    }).catch(err => {
      console.log('componentDidMount logout err', err);
    })
  }


  handleResetPassword = (actionCode, continueUrl, uid, customToken, type, isCompanyUser) => {
    const { history } = this.props;
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    //  var accountEmail;
    // Verify the password reset code is valid.
    auth.verifyPasswordResetCode(actionCode).then((email) => {
      const accountEmail = email;

      history.push(`/reset-password-confirm?email=${email}&actionCode=${actionCode}&customToken=${customToken}&isCompanyUser=${isCompanyUser}`);
      // TODO: Show the reset screen with the user's email and ask the user for
      // the new password.

      this.setState({ accountEmail, actionCode });
    }).catch((error) => {
      console.log(error);

    //  history.push(`/reset-password-confirm?email=mikus.slaukstins@indriks.com&actionCode=${actionCode}&customToken=${customToken}&isCompanyUser=${isCompanyUser}`);

      this.setState({ error: error.code });
      // Invalid or expired action code. Ask user to try to reset the password
      // again.
    });
  }

  submitResetPassword = () => {
    console.log('submitResetPassword');
    const { history } = this.props;
    const { newPassword, actionCode } = this.state;

    console.log(newPassword);
    console.log(actionCode);

    if (newPassword && newPassword.length > 6) {
      // Save the new password.
      auth.confirmPasswordReset(actionCode, newPassword).then((resp) => {
        // Password reset has been confirmed and new password updated.

      //  history.push('/landing');

        // TODO: Display a link back to the app, or sign-in the user directly
        // if the page belongs to the same domain as the app:
        // auth.signInWithEmailAndPassword(accountEmail, newPassword);

        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      }).catch(function(error) {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
      });
    }
  }

  handleRecoverEmail = (actionCode, uid, customToken, type) => {
    console.log('handleRecoverEmail', actionCode);
  //  const { history } = this.props;
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    let restoredEmail = null;
    // Confirm the action code is valid.
    auth.checkActionCode(actionCode).then((info) => {
      // Get the restored email address.
      console.log('checkActionCode', info);
      restoredEmail = info['data']['email'];

      console.log(restoredEmail);

      // Revert to the old email.
      return auth.applyActionCode(actionCode);
    }).then((error) => {
      // Account email reverted to restoredEmail
      console.log('Account email reverted to restoredEmail', restoredEmail);

      console.log(error);
      this.setState({ error: error.code });

      // TODO: Display a confirmation message to the user.

      // You might also want to give the user the option to reset their password
      // in case the account was compromised:
    //  Firebase.auth().sendPasswordResetEmail(restoredEmail).then(() => {
        // Password reset confirmation sent. Ask user to check their email.
    //  }).catch(function(error) {
        // Error encountered while sending password reset code.
    //  });
  }).catch((error) => {
      console.log('error', error);
      // Invalid code.
    });
  }

  handleVerifyEmail = (actionCode, continueUrl, uid, customToken, type) => {
    console.log('handleVerifyEmail', actionCode, continueUrl, uid, customToken, type);
    console.log('handleVerifyEmail uid', uid);
    const { history, afterLogin, markVerified } = this.props;
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
    auth.applyActionCode(actionCode).then(async (resp) => {
      // Email address has been verified.
      console.log('resp', resp);

      if (type === 'updatedEmail') {
        await afterLogin(customToken).then((res) => {
          console.log('res', res);
          this.setState({ success: true });
        //  history.push('/');
        }).catch(err => {
          this.setState({ success: true });
          console.log('err', err);
        //  history.push('/');
        });
      } else {
        await markVerified(uid);

        this.setState({ success: true });
      //  history.push('/');
      }


    //  auth.signInWithCustomToken(customToken);

      //TO DO Change user email

    //  setTimeout(() => {
    //  const usersRef = firestore.collection('users').doc(uid);

    //  await usersRef.update({
    //    email: uid,
    //  });
    //    history.push('/email-verify-success');
        // afterLogin();
    //  }, 1000);



      // TODO: Display a confirmation message to the user.
      // You could also provide the user with a link back to the app.

      // TODO: If a continue URL is available, display a button which on
      // click redirects the user back to the app via continueUrl with
      // additional state determined from that URL's parameters.
    }).catch((error) => {
       console.log(error);
       this.setState({ error: error.code });
      // Code is invalid or expired. Ask the user to verify their email address
      // again.
    });
  }


  handleRevertSecondFactorAddition = (actionCode, continueUrl, uid, customToken, type) => {
    console.log('handleRevertSecondFactorAddition', actionCode, continueUrl, uid, customToken, type);
    const { history, afterLogin } = this.props;
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
    auth.applyActionCode(actionCode).then(async (resp) => {
      // Email address has been verified.
      console.log('resp', resp);

      this.setState({ success: true });
      //  history.push('/');

    }).catch((error) => {
       console.log(error);
       this.setState({ error: error.code });
      // Code is invalid or expired. Ask the user to verify their email address
      // again.
    });
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      [event.target.name]: value,
    });
  }

  toLogin = () => {
    const { history } = this.props;
    history.push('/');
  }

  render() {
    const { mode, error, success, isAppUser } = this.state;

    console.log('this.state', this.state);

    if (mode === 'verifyEmail') {
      console.log('render verify page');

      return (
        <Card style={{ position: 'relative', textAlign: 'center', color: '#000', width: '30%', minWidth: 200, margin: 'auto', marginTop: 100 }}>
        <CardBody>
            {(!!error && error !== "auth/invalid-action-code") && <Alert color={"danger"}>{error}</Alert>}
            {(!!success) && <Alert color={"success"}> Email verified successfully. </Alert>}
            {(error === "auth/invalid-action-code") && <Alert color={"success"}> Email verification link has already been verified. </Alert>}

          {isAppUser ? (
            <> {(!!success || error === "auth/invalid-action-code") && <div > You can now login on the app </div>} </>
          ):(
            <> {(!!success || error === "auth/invalid-action-code") && <Button color={"success"} onClick={this.toLogin}> Login </Button>} </>
          )}

          </CardBody>
        </Card>
      );
    } else if (mode === 'recoverEmail' ) {
      return (
        <Card style={{ position: 'relative', textAlign: 'center', color: '#000', width: '30%', minWidth: 200, margin: 'auto', marginTop: 100 }}>
        <CardBody>
            {(!!error && error !== "auth/invalid-action-code") && <Alert color={"danger"}>{error}</Alert>}
            {(!!success) && <Alert color={"success"}> Account email reverted to previous email. </Alert>}
            {(error === "auth/invalid-action-code") && <Alert color={"success"}> Email verification link has already been verified. </Alert>}

          {isAppUser ? (
            <> {(!!success || error === "auth/invalid-action-code") && <div > You can now login on the app </div>} </>
          ):(
            <> {(!!success || error === "auth/invalid-action-code") && <Button color={"success"} onClick={this.toLogin}> Login </Button>} </>
          )}

          </CardBody>
        </Card>
      );
    } else if (mode === 'revertSecondFactorAddition' ) {
      return (
        <Card style={{ position: 'relative', textAlign: 'center', color: '#000', width: '30%', minWidth: 200, margin: 'auto', marginTop: 100 }}>
        <CardBody>
          {(!!error) && <Alert color={"danger"}>{error}</Alert>}
          {(!!success) && <Alert color={"success"}> Two factor authentication reverted. </Alert>}

        {/*  {isAppUser ? (
            <> {(!!success) && <div > You can now login on the app </div>} </>
          ):(
            <> {(!!success) && <Button color={"success"} onClick={this.toLogin}> Login </Button>} </>
          )} */}

          </CardBody>
        </Card>
      );
    } else if (mode === 'resetPassword' ) {
      return (
        <Card style={{ position: 'relative', textAlign: 'center', color: '#000', width: '30%', minWidth: 200, margin: 'auto', marginTop: 100 }}>
          <CardBody>
            {(!!error && error !== "auth/invalid-action-code") && <Alert color={"danger"}>{error}</Alert>}
            {(!!success) && <Alert color={"success"}> Password reset succesfull. </Alert>}
            {(error === "auth/invalid-action-code") && <Alert color={"success"}> Email verification link has already been verified. </Alert>}

          </CardBody>
        </Card>
      );
    }

    return null;
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
});

const mapDispatchToProps = {
  afterLogin: afterEmailLogin,
  markVerified: markAsVerified,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailVerify));
