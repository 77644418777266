import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import Moment from 'react-moment';

import { Table, Column, HeaderCell, Cell, ColumnGroup } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';

class CustomerPayments extends Component {
  static propTypes = {
    user: PropTypes.shape(),
  };

  static defaultProps = {
    user: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      sortType: 'desc',
      sortColumn: 'paymentDateSeconds',
    };
  }

  componentDidMount() {
    const { payments } = this.props;
    const { sortType, sortColumn } = this.state;

    let newPayments = [...payments];

    if (sortType === 'asc') {
      newPayments.sort((a, b) => {
        if ( a[sortColumn] < b[sortColumn] ){
          return -1;
        }
        if ( a[sortColumn] > b[sortColumn] ){
          return 1;
        }
        return 0;
      });
    } else {
      newPayments.sort((a, b) => {
        if ( b[sortColumn] < a[sortColumn] ){
          return -1;
        }
        if ( b[sortColumn] > a[sortColumn] ){
          return 1;
        }
        return 0;
      });
    }

    console.log('newPayments', newPayments);

    this.setState({ payments: newPayments });
  }

  sort = (sortColumn, sortType) => {
    const { payments } = this.props;
  //  const { sortType, sortColumn } = this.state;

    let newPayments = [...payments];

    if (sortType === 'asc') {
      newPayments.sort((a, b) => {
        if ( a[sortColumn] < b[sortColumn] ){
          return -1;
        }
        if ( a[sortColumn] > b[sortColumn] ){
          return 1;
        }
        return 0;
      });
    } else {
      newPayments.sort((a, b) => {
        if ( b[sortColumn] < a[sortColumn] ){
          return -1;
        }
        if ( b[sortColumn] > a[sortColumn] ){
          return 1;
        }
        return 0;
      });
    }

    console.log('newPayments', newPayments);

    this.setState({ payments: newPayments, sortColumn, sortType });
  }

  render() {
    const {  sort } = this.props;
    const { sortType, sortColumn, payments } = this.state;

    if (!payments || payments.length === 0) {
      return null;
    }

    console.log('payments', payments, sortType, sortColumn);

    return (
      <Table
        style={{ fontSize: 14 }}
        sortType={sortType}
        sortColumn={sortColumn}
        data={payments}
        virtualized
        minHeight={500}
        height={500}
        headerHeight={92}
        onSortColumn={this.sort}
      >
        <Column width={170} sortable resizable>
          <HeaderCell>ID</HeaderCell>
          <Cell dataKey="paymentId" />
        </Column>

        <Column width={170} sortable resizable>
          <HeaderCell>Stripe ID</HeaderCell>
          <Cell dataKey="chargeId" />
        </Column>

        <Column width={120} sortable resizable>
          <HeaderCell>Purchase Date</HeaderCell>
          <Cell dataKey="paymentDateSeconds">
          {(rowData, rowIndex) => {
            return (
              <div>
                {rowData.paymentDate ? (
                  <Moment format="DD-MM-YYYY" locale="=uk">
                    {rowData.paymentDate.seconds * 1000}
                  </Moment>
                ):(null)}
              </div>
            )
          }}
          </Cell>
        </Column>

        <Column width={170} sortable resizable>
          <HeaderCell>Company name</HeaderCell>
          <Cell dataKey="companyName" />
        </Column>
        <Column width={170} sortable resizable>
          <HeaderCell>Voucher name</HeaderCell>
          <Cell dataKey="voucherName" />
        </Column>
        <ColumnGroup className="text-center" header="Purchase info">

          <Column width={280} sortable resizable>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="status">
            {(rowData, rowIndex) => {
              if (rowData.canceled) {
                if (rowData.refunded) {
                  return (
                    <div>
                      <span>Cancelled (refunded)</span>
                      {rowData.canceledDate ? (
                        <span>
                          {` - `}
                          <Moment format="DD/MM/YYYY" locale="=uk">
                            {rowData.canceledDate.seconds ? (rowData.canceledDate.seconds * 1000) : rowData.canceledDate}
                          </Moment>
                        </span>
                      ) : (null)}
                    </div>
                  )
                } else {
                  return (
                    <div>
                      <span>Cancelled</span>
                      {rowData.canceledDate ? (
                        <span>
                          {` - `}
                          <Moment format="DD/MM/YYYY" locale="=uk">
                            {rowData.canceledDate.seconds ? (rowData.canceledDate.seconds * 1000) : rowData.canceledDate}
                          </Moment>
                        </span>
                      ) : (null)}
                    </div>
                  )
                }
              } else if (rowData.used) {
                if (rowData.refunded) {
                  return (
                    <div>
                      <span>Redeemed (refunded)</span>
                      {rowData.usedDate ? (
                        <span>
                          {` - `}
                          <Moment format="DD/MM/YYYY" locale="=uk">
                            {rowData.usedDate.seconds ? (rowData.usedDate.seconds * 1000) : rowData.usedDate}
                          </Moment>
                        </span>
                      ) : (null)}
                    </div>
                  )
                } else {
                  return (
                    <div>
                      <span>Redeemed</span>
                      {rowData.usedDate ? (
                        <span>
                          {` - `}
                          <Moment format="DD/MM/YYYY" locale="=uk">
                            {rowData.usedDate.seconds ? (rowData.usedDate.seconds * 1000) : rowData.usedDate}
                          </Moment>
                        </span>
                      ) : (null)}
                    </div>
                  )
                }
              } else {
                if (rowData.refunded) {
                  if (rowData.canceled) {
                    return (
                      <div>
                        <span>Cancelled (refunded)</span>
                        {rowData.canceledDate ? (
                          <span>
                            {` - `}
                            <Moment format="DD/MM/YYYY" locale="=uk">
                              {rowData.canceledDate}
                            </Moment>
                          </span>
                        ) : (null)}
                      </div>
                    )
                  } else {
                    return <div> Unused (refunded) </div>
                  }
                } else {
                  if (rowData.canceled) {
                    return (
                      <div>
                        <span>Cancelled</span>
                        {rowData.canceledDate ? (
                          <span>
                            {` - `}
                            <Moment format="DD/MM/YYYY" locale="=uk">
                              {rowData.canceledDate}
                            </Moment>
                          </span>
                        ) : (null)}
                      </div>
                    )
                  } else {
                    return <div> Unused </div>
                  }
                }
              }
            }}
            </Cell>
          </Column>

          <Column width={150} sortable resizable>
            <HeaderCell>Face Value</HeaderCell>
            <Cell dataKey="faceValue" />
          </Column>
        </ColumnGroup>

        <ColumnGroup className="text-center" header="Payment in">
          <Column width={150} sortable resizable>
            <HeaderCell>Voucher payment</HeaderCell>
            <Cell dataKey="amount_captured">
              {(rowData, rowIndex) => {
                if (rowData.amount_captured) {
                  return <div> {(rowData.amount_captured / 100).toFixed(2)} </div>
                } else {
                  return <div />
                }
              }}
            </Cell>
          </Column>
          <Column width={0}>
            <HeaderCell>Payments count</HeaderCell>
            <Cell dataKey="capturedCount" />
          </Column>
        </ColumnGroup>

        <ColumnGroup className="text-center" header="Payment out">
          <Column width={150} sortable resizable>
            <HeaderCell>Pay to company</HeaderCell>
            <Cell dataKey="amount_captured">
              {(rowData, rowIndex) => {
                if (rowData.application_fee_amount) {
                  return <div> {`${((rowData.amount_captured - rowData.application_fee_amount) / 100).toFixed(2)}`} </div>
                } else {
                  return <div> {(rowData.amount_captured / 100).toFixed(2)} </div>
                }
              }}
            </Cell>
          </Column>

          <Column width={150} sortable resizable>
            <HeaderCell>Pay to POD</HeaderCell>
            <Cell dataKey="amount_captured">
              {(rowData, rowIndex) => {
                if (rowData.gift && rowData.podPrice) {
                  return <div> {rowData.podPrice} </div>
                } else {
                  return <div/>
                }
              }}
            </Cell>
          </Column>

          <Column width={150} sortable resizable>
            <HeaderCell>Pay to Steppr</HeaderCell>
            <Cell dataKey="amount_captured">
              {(rowData, rowIndex) => {
                if (rowData.application_fee_amount) {
                  if (rowData.gift && rowData.podPrice) {
                    return <div> {(rowData.podPrice + (rowData.application_fee_amount / 100)).toFixed(2)} </div>
                  } else {
                    return <div> {(rowData.application_fee_amount / 100).toFixed(2)} </div>
                  }
                } else {
                  return <div/>
                }
              }}
            </Cell>
          </Column>
        </ColumnGroup>

      {/*  <ColumnGroup className="text-center" header="Refunds">
          <Column width={150} sortable resizable>
            <HeaderCell>Amount refunded</HeaderCell>
            <Cell dataKey="amount_refunded">
              {(rowData, rowIndex) => {
                if (rowData.amount_refunded) {
                  return <div> {(rowData.amount_refunded / 100).toFixed(2)} </div>
                } else {
                  return <div />
                }
              }}
            </Cell>
          </Column>
          <Column width={0} sortable resizable>
            <HeaderCell>Refunded count</HeaderCell>
            <Cell dataKey="refundedCount" />
          </Column>
        </ColumnGroup> */}
      </Table>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  users: state.admin.companyOwners || {},
});

const mapDispatchToProps = {

};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerPayments));
