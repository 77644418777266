import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter, Link } from 'react-router-dom';

import { Eye, EyeOff } from 'react-feather';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

//import reactFeature from '../../assets/images/react-feature.svg';
//import sassFeature from '../../assets/images/sass-feature.svg';
//import bootstrapFeature from '../../assets/images/bootstrap-feature.svg';
//import responsiveFeature from '../../assets/images/responsive-feature.svg';
import { Card, CardBody, Row, Col, Input, Label, Form, FormGroup, Button, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';

import { connect } from 'react-redux';

import {
  doLogin,
  completePhoneVerification,
  completePhoneEnrollment,
  startPhoneEnrollment,
} from '../../actions/user';

import notifications from '../../const/notifications';

class Login extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    login: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      email: null,
      password: null,
      phoneCode: [],
      showPassword1: 'password',
    };

    this.digit1 = React.createRef();
    this.digit2 = React.createRef();
    this.digit3 = React.createRef();
    this.digit4 = React.createRef();
    this.digit5 = React.createRef();
    this.digit6 = React.createRef();
  }

  componentDidMount() {
    console.log('Login componentDidMount');
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleChangePhoneNumber = (phone) => {
    this.setState({ phoneNumber: phone });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    console.log('handleSubmit');

    const { login, history } = this.props;
    const { email, password, loading } = this.state;

    if(loading) return;

    this.setState({ loading: true });

    return login({ email: email, password: password })
      .then((res) => {
        console.log('login then', { res });
        if (res && res.status === 'success') {
          if (res.verificationType === 'enrollment') {
            this.setState({ loading: false, errorGreen: false, show2faPhoneModal: true, verificationType: res.verificationType });
          } else if (res.verificationId) {
            this.setState({ loading: false, errorGreen: false, show2faModal: true, verificationId: res.verificationId, verificationType: res.verificationType, resolver: res.resolver });
          } else {
            history.push('/');
          }
        }
      //  history.push('/');
      })
      .catch((err) => {
        console.log('login error', err);

        console.log(notifications.login);
        if (err === 'missing email') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.noEmail || err });
        } else if (err === 'missing password') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.noPassword || err });
        } else if (err === 'The email address is badly formatted.') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.badEmail || err });
        } else if (err === 'To access your account please click the verification link in the email sent to you.') {
          this.setState({ loading: false, errorGreen: true, error: notifications.login.needVerification || err });
        } else if (err === 'The password is invalid or the user does not have a password.' || 'There is no user record corresponding to this identifier. The user may have been deleted.') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.wrongLogin || err });
        } else {
          this.setState({ loading: false, errorGreen: false, error: err });
        }
      });
  }

  submitPhoneNumber = () => {
    const { startEnrollment } = this.props;
    const { phoneNumber } = this.state;

    console.log('phoneNumber', { phoneNumber: `+${phoneNumber}` });

    this.setState({ processingAdd2fa: true });

    if (phoneNumber && phoneNumber.length) {
      startEnrollment({ phoneNumber: `+${phoneNumber}` }).then((res) => {

        this.setState({ processingAdd2fa: null });

        this.setState({ show2faPhoneModal: false, show2faModal: true, verificationId: res.verificationId });
      });
    } else {
      this.setState({ processingAdd2fa: null });
    }
  }

  close2faPhoneModal = () => {
    this.setState({ show2faPhoneModal: false, verificationId: null, verificationType: null, resolver: null });
  }

  submitVerificationCode = () => {
    console.log('submitVerificationCode');
    const { completeVerification, completeEnrollment, history } = this.props;
    const { verificationId, verificationType, verificationCode, resolver, phoneCode } = this.state;

    this.setState({ processingSend2fa: true });

    console.log('submitVerificationCode verificationCode', verificationCode, verificationType, phoneCode);

    if (phoneCode && phoneCode.length === 6) {
      if (phoneCode.includes(null) || phoneCode.includes(undefined)) {
        console.log('invalid values');
      } else {
        console.log('submitVerificationCode phoneCode', phoneCode);

        const phoneCodeString = phoneCode.join('');

        console.log('phoneCodeString phoneCode', phoneCodeString);

        if (verificationType === 'enrollment') {
          completeEnrollment({ verificationId, verificationCode: phoneCodeString }).then((res) => {
            console.log('completeEnrollment', res);
            this.setState({ processingSend2fa: null });
            history.push('/dashboard');
          });
        } else {
          completeVerification({ verificationId, verificationCode: phoneCodeString, resolver }).then((res) => {
            console.log('completeVerification', res);
            this.setState({ processingSend2fa: null });
            history.push('/dashboard');
          });
        }
      }
    }

    if (verificationCode && verificationCode.length === 6) {
     /* if (verificationType === 'enrollment') {
        completeEnrollment({ verificationId, verificationCode }).then((res) => {
          console.log('completeEnrollment', res);
          this.setState({ processingSend2fa: null });
          history.push('/dashboard');
        });
      } else {
        completeVerification({ verificationId, verificationCode, resolver }).then((res) => {
          console.log('completeVerification', res);
          this.setState({ processingSend2fa: null });
          history.push('/dashboard');
        });
      } */
    } else {
      this.setState({ processingSend2fa: null });
    }
  }

  close2faModal = () => {
    this.setState({ show2faModal: false, verificationId: null, verificationType: null, resolver: null });
  }

  handleCodeChange = (e) => {
    const { phoneCode } = this.state;

    if (e.target) {
      const { value, name } = e.target;

      let newVal;

      if (e.keyCode === 8) {
        newVal = null;
      } else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
        if (e.keyCode === 48 || e.keyCode === 96) {
          newVal = 0;
        } else if (e.keyCode === 49 || e.keyCode === 97) {
          newVal = 1;
        } else if (e.keyCode === 50 || e.keyCode === 98) {
          newVal = 2;
        } else if (e.keyCode === 51 || e.keyCode === 99) {
          newVal = 3;
        } else if (e.keyCode === 52 || e.keyCode === 100) {
          newVal = 4;
        } else if (e.keyCode === 53 || e.keyCode === 101) {
          newVal = 5;
        } else if (e.keyCode === 54 || e.keyCode === 102) {
          newVal = 6;
        } else if (e.keyCode === 55 || e.keyCode === 103) {
          newVal = 7;
        } else if (e.keyCode === 56 || e.keyCode === 104) {
          newVal = 8;
        } else if (e.keyCode === 57 || e.keyCode === 105) {
          newVal = 9;
        }
      } else {
        return;
      }

      if (!newVal || !isNaN(newVal)) {
        if (newVal || newVal === 0) {
          phoneCode[name] = newVal.toString();
        } else {
          phoneCode[name] = null;
        }

        if (newVal || newVal === 0) {
          if (name === 0 || name === '0') {
            if (this.digit2) {
              this.digit2.current.focus();
            }
          } else if (name === 1 || name === '1') {
            if (this.digit3) {
              this.digit3.current.focus();
            }
          } else if (name === 2 || name === '2') {
            if (this.digit4) {
              this.digit4.current.focus();
            }
          } else if (name === 3 || name === '3') {
            if (this.digit5) {
              this.digit5.current.focus();
            }
          } else if (name === 4 || name === '4') {
            if (this.digit6) {
              this.digit6.current.focus();
            }
          }
        } else {
          if (name === 1 || name === '1') {
            if (this.digit1) {
              if (value || value === 0 || value === '0') {
                this.digit2.current.focus();
              } else {
                this.digit1.current.focus();
              }
            }
          } else if (name === 2 || name === '2') {
            if (this.digit2) {
              if (value || value === 0 || value === '0') {
                this.digit3.current.focus();
              } else {
                this.digit2.current.focus();
              }
            }
          } else if (name === 3 || name === '3') {
            if (this.digit3) {
              if (value || value === 0 || value === '0') {
                this.digit4.current.focus();
              } else {
                this.digit3.current.focus();
              }
            }
          } else if (name === 4 || name === '4') {
            if (this.digit4) {
              if (value || value === 0 || value === '0') {
                this.digit5.current.focus();
              } else {
                this.digit4.current.focus();
              }
            }
          } else if (name === 5 || name === '5') {
            if (this.digit5) {
              if (value || value === 0 || value === '0') {
                this.digit6.current.focus();
              } else {
                this.digit5.current.focus();
              }
            }
          }
        }

        this.setState({
          phoneCode,
        });
      }
    }
  }



  handleCodeChange2 = (e) => {
    const { phoneCode } = this.state;

    if (e.target) {
      const { value, name } = e.target;

      let newVal;

      console.log('value', name, value);

      if (value.length) {
        newVal = value[value.length - 1];
      } else {
        newVal = null;
      }

      console.log('newVal', newVal);
      

     /* if (e.keyCode === 8) {
        newVal = null;
      } else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
        if (e.keyCode === 48 || e.keyCode === 96) {
          newVal = 0;
        } else if (e.keyCode === 49 || e.keyCode === 97) {
          newVal = 1;
        } else if (e.keyCode === 50 || e.keyCode === 98) {
          newVal = 2;
        } else if (e.keyCode === 51 || e.keyCode === 99) {
          newVal = 3;
        } else if (e.keyCode === 52 || e.keyCode === 100) {
          newVal = 4;
        } else if (e.keyCode === 53 || e.keyCode === 101) {
          newVal = 5;
        } else if (e.keyCode === 54 || e.keyCode === 102) {
          newVal = 6;
        } else if (e.keyCode === 55 || e.keyCode === 103) {
          newVal = 7;
        } else if (e.keyCode === 56 || e.keyCode === 104) {
          newVal = 8;
        } else if (e.keyCode === 57 || e.keyCode === 105) {
          newVal = 9;
        }
      } else {
        return;
      } */

      if (!newVal || !isNaN(newVal)) {
        if (newVal || newVal === 0) {
          phoneCode[name] = newVal.toString();
        } else {
          phoneCode[name] = null;
        }

        if (newVal || newVal === 0) {
          if (name === 0 || name === '0') {
            if (this.digit2) {
              this.digit2.current.focus();
            }
          } else if (name === 1 || name === '1') {
            if (this.digit3) {
              this.digit3.current.focus();
            }
          } else if (name === 2 || name === '2') {
            if (this.digit4) {
              this.digit4.current.focus();
            }
          } else if (name === 3 || name === '3') {
            if (this.digit5) {
              this.digit5.current.focus();
            }
          } else if (name === 4 || name === '4') {
            if (this.digit6) {
              this.digit6.current.focus();
            }
          }
        } else {
          if (name === 1 || name === '1') {
            if (this.digit1) {
              if (value || value === 0 || value === '0') {
                this.digit2.current.focus();
              } else {
                this.digit1.current.focus();
              }
            }
          } else if (name === 2 || name === '2') {
            if (this.digit2) {
              if (value || value === 0 || value === '0') {
                this.digit3.current.focus();
              } else {
                this.digit2.current.focus();
              }
            }
          } else if (name === 3 || name === '3') {
            if (this.digit3) {
              if (value || value === 0 || value === '0') {
                this.digit4.current.focus();
              } else {
                this.digit3.current.focus();
              }
            }
          } else if (name === 4 || name === '4') {
            if (this.digit4) {
              if (value || value === 0 || value === '0') {
                this.digit5.current.focus();
              } else {
                this.digit4.current.focus();
              }
            }
          } else if (name === 5 || name === '5') {
            if (this.digit5) {
              if (value || value === 0 || value === '0') {
                this.digit6.current.focus();
              } else {
                this.digit5.current.focus();
              }
            }
          }
        }

        this.setState({
          phoneCode,
        });
      }
    }
  }

  toggleShowPassword = (id) => {
    console.log('toggleShowPassword');
    console.log(id);
    if (id === 1) {
      //  this.setState((prevState) => ({ showPassword1: !prevState.showPassword1 }));

      this.setState({
        showPassword1: this.state.showPassword1 === 'input' ? 'password' : 'input'
      })
    } else if (id === 2) {
      //  this.setState((prevState) => ({ showPassword2: !prevState.showPassword2 }));
      this.setState({
        showPassword2: this.state.showPassword2 === 'input' ? 'password' : 'input'
      })
    }
  }

  render() {
    const { branding } = this.props;
    const {
      email,
      password,
      phoneCode,
      error,
      errorGreen,
      show2faModal,
      verificationCode,
      show2faPhoneModal,
      phoneNumber,
      loading,
      processingSend2fa,
      processingAdd2fa,
      showPassword1,
    } = this.state;

    console.log('phoneCode', phoneCode);

    return (
      <div>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <Card>
              <CardBody>
                {(!!error) && <Alert color={errorGreen ? "success" : "danger"}>{error}</Alert>}
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      name="email"
                      id="email"
                      placeholder="john@doe.corp"
                      value={email}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup style={{ position: 'relative' }}>
                    <Label for="password">Password</Label>
                    {/* <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="••••••••"
                      value={password}
                      onChange={this.handleChange}
                    /> */}
                    <Input
                      type={showPassword1}
                      name="password"
                      id="password"
                      placeholder="••••••••"
                      value={password}
                      onChange={this.handleChange}
                    />
                    {showPassword1 === 'input' ? (
                      <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem', width: 20 }} onClick={() => this.toggleShowPassword(1)} />
                    ) : (
                      <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem', width: 20 }} onClick={() => this.toggleShowPassword(1)} />
                    )}
                  </FormGroup>
                  <Button disabled={loading} id="sign-in-button" className="custom-primary-button" color="primary">
                    Login
                    {loading ? (
                      <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem', marginTop: 3 }}> {''} </Spinner>
                    ):(null)}
                  </Button>
                </Form>

                <Row style={{ marginTop: 10 }}>
                  <Col sm="12">
                    <Link className="common-link" to="/reset-password" style={{ fontSize: 13, marginRight: 30, color: branding.sidebarPrimaryColor ? branding.sidebarPrimaryColor : '#162c50' }}>Forgot Password?</Link>
                    <Link className="common-link" to="/signup" style={{ fontSize: 13, color: branding.sidebarPrimaryColor ? branding.sidebarPrimaryColor : '#162c50' }} >New? Sign up here</Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal isOpen={show2faPhoneModal} toggle={this.close2faPhoneModal}>
          <ModalHeader toggle={this.close2faPhoneModal}>
            <div style={{ fontSize: 22 }}>Keep your account secure.</div>
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="role">
                  <span>
                    {`We require you to set up `}
                  </span>
                  <span style={{ textTransform: 'capitalize', color: branding.sidebarPrimaryColor ? branding.sidebarPrimaryColor : '#162c50' }}>
                    {`Two Factor Authentication `}
                  </span>
                  <span>
                    as a quick way to help keep your account secure. After entering your mobile number, we’ll send you an SMS verification code to allow you to login.
                  </span>
                </Label>
              </FormGroup>
            </Form>
            <Row>
              <Col xs="12" sm="12" md="9" style={{ marginBottom: 5 }}>
                <PhoneInput
                  country={'gb'}
                  value={phoneNumber}
                  placeholder="121 234 5678"
                  onChange={this.handleChangePhoneNumber}
                />
              </Col>
              <Col xs="12" sm="12" md="3">
                <Button style={{ float: 'right' }} color="primary" onClick={this.submitPhoneNumber}>
                  Submit
                  {processingAdd2fa ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem', marginTop: 3 }}> {''} </Spinner>
                  ):(null)}
                </Button>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </Modal>

        <Modal isOpen={show2faModal} toggle={this.close2faModal} size="md">
          <ModalHeader toggle={this.close2faModal}>
            <div style={{ fontSize: 22 }}>Two-Factor Authentication</div>
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="role">
                  For security, please enter your SMS verification code.
                </Label>
              </FormGroup>
            </Form>
            <Row>
              {/*  <Col xs="12" sm="9" md="8" lg="7">
              {/* <Input type="number" name="verificationCode" placeholder="123456" value={verificationCode} onChange={this.handleChange} /> */}
             {/* <Row> */}
                <Col style={{ paddingLeft: '1vw', paddingRight: '1vw', minWidth: 37, width: 37 }}> 
                    <Input
                      className="room-password-digit"
                      style={{ paddingLeft: '0.2em', paddingRight: '0.2em', textAlign: 'center', width: 37 }}
                      type="text"
                      name={0}
                      id="digit1"
                      value={phoneCode[0] || ''}
                    //  onKeyUp={this.handleCodeChange}
                      onChange={this.handleCodeChange2}
                      innerRef={this.digit1}
                    />
                  </Col>
                  <Col style={{ paddingLeft: '1vw', paddingRight: '1vw', minWidth: 37, width: 37 }}>
                    <Input
                      style={{ paddingLeft: '0.2em', paddingRight: '0.2em', textAlign: 'center', width: 37 }}
                      type="text"
                      name={1}
                      id="digit2"
                      value={phoneCode[1] || ''}
                     // onKeyDown={this.handleCodeChange2}
                      onChange={this.handleCodeChange2}
                      innerRef={this.digit2}
                    />
                  </Col>
                  <Col style={{ paddingLeft: '1vw', paddingRight: '1vw', minWidth: 37, width: 37 }}>
                    <Input
                      style={{ paddingLeft: '0.2em', paddingRight: '0.2em', textAlign: 'center', width: 37 }}
                      type="text"
                      name={2}
                      id="digit3"
                      value={phoneCode[2] || ''}
                    //  onKeyUp={this.handleCodeChange2}
                      onChange={this.handleCodeChange2}
                      innerRef={this.digit3}
                    />
                  </Col>
                  <Col style={{ paddingLeft: '1vw', paddingRight: '1vw', minWidth: 37, width: 37 }}>
                    <Input
                      style={{ paddingLeft: '0.2em', paddingRight: '0.2em', textAlign: 'center', width: 37 }}
                      type="text"
                      name={3}
                      id="digit4"
                      value={phoneCode[3] || ''}
                    //  onKeyUp={this.handleCodeChange2}
                      onChange={this.handleCodeChange2}
                      innerRef={this.digit4}
                    />
                  </Col>
                  <Col style={{ paddingLeft: '1vw', paddingRight: '1vw', minWidth: 37, width: 37 }}>
                    <Input
                      style={{ paddingLeft: '0.2em', paddingRight: '0.2em', textAlign: 'center', width: 37 }}
                      type="text"
                      name={4}
                      id="digit5"
                      value={phoneCode[4] || ''}
                    //  onKeyUp={this.handleCodeChange}
                      onChange={this.handleCodeChange2}
                      innerRef={this.digit5}
                    />
                  </Col>
                  <Col style={{ paddingLeft: '1vw', paddingRight: '1vw', minWidth: 37, width: 37 }}>
                    <Input
                      style={{ paddingLeft: '0.2em', paddingRight: '0.2em', textAlign: 'center', width: 37 }}
                      type="text"
                      name={5}
                      id="digit6"
                      value={phoneCode[5] || ''}
                     // onKeyUp={this.handleCodeChange}
                      onChange={this.handleCodeChange2}
                      innerRef={this.digit6}
                    />
                   </Col>
                {/* </Row>
              </Col> */}
                  <Col> {/*xs="4" sm="3" md="4" lg="5" */}
                <Button color="primary" onClick={this.submitVerificationCode} style={{ float: 'right' }}>
                  Submit
                  {processingSend2fa ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem', marginTop: 3 }}> {''} </Spinner>
                  ):(null)}
                </Button>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>

          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user || {},
  branding: state.contentManagement.branding || {},
});

const mapDispatchToProps = {
  login: doLogin,
  completeVerification: completePhoneVerification,
  completeEnrollment: completePhoneEnrollment,
  startEnrollment: startPhoneEnrollment,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));

// export default Dashboard;
