import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Card, CardBody, Row, Col, Button, Input, Label, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Alert, Nav, NavLink, NavItem, TabContent, TabPane } from 'reactstrap';

import { Eye, EyeOff } from 'react-feather';

import ReactTooltip from 'react-tooltip';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState } from 'draft-js';
import 'draft-js/dist/Draft.css';

import htmlToDraft from 'html-to-draftjs';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  getCompanyApiKey,
  getLinkedAPIs,
  updateLinkedAPI,
  addLinkedAPI,
  removeLinkedAPI,
} from '../../../../actions/company';

import {
  getLinkedCompanyUsers,
  createLinkedCompanyUser,
  deleteLinkedCompanyUser,
} from '../../../../actions/users';

import {
  getAPIPageData,
  getAPITooltips,
} from '../../../../actions/contentManagement';

class LinkedCompaniesAPIs extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    contactPage: PropTypes.shape(),
    apiTooltips: PropTypes.shape(),
    fetchAPITooltips: PropTypes.func.isRequired,
    changeActiveAPI: PropTypes.func.isRequired,
    fetchLinkedAPIs: PropTypes.func.isRequired,
    linkedAPIupdate: PropTypes.func.isRequired,
    linkedAPIAdd: PropTypes.func.isRequired,
    linkedAPIRemove: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    contactPage: {},
    apiTooltips: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      copiedSuccess: false,
      show: false,
      show2: false,
      showNoPermission: false,
      dontShowPage: false,
      hadExistingData: false,
      showConfirmModal: false,
      showConfirmModalShown: false,
      originalShopify: {},
      originalWoocommerce: {},
      hasOriginal: false,
    };
  }

  componentDidMount() {
    const { adminViewAsCompany, fetchCompanyApiKey, fetchAPIPageData, user, getShopifyAPI, getWoocommerceAPI, fetchAPITooltips, getActiveAPI, fetchLinkedAPIs, fetchLinkedCompanyUsers } = this.props;

    fetchAPITooltips();
    fetchAPIPageData().then((res) => {
      if (res) {
        const blocksFromHtml = htmlToDraft(res);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        if (contentState) {
          this.setState({ editorState: EditorState.createWithContent(contentState) });
        }
      }
    });

    if (user && user.company) {
      fetchCompanyApiKey(user.company);

      if (!user.linkedCompaniesAccount) {

      } else {
       // fetchLinkedAPIs(user.company);
      //  fetchLinkedCompanyUsers(user.company);
      }
    }

    let showPageAdmin = false;

    if (adminViewAsCompany && user.roleClass === 'admin') {
      if (user.role === 'admin') {
        this.setState({ showNoPermission: false, dontShowPage: false });
        showPageAdmin = true;
      } else {
        const { adminsRoles } = this.props;

        if (adminsRoles && adminsRoles[user.role] && adminsRoles[user.role].permissions && adminsRoles[user.role].permissions.companyAccess) {
          this.setState({ showNoPermission: false, dontShowPage: false });
          showPageAdmin = true;
        } else {
          this.setState({ showNoPermission: false, dontShowPage: true });
        }
      }

      if (showPageAdmin && adminViewAsCompany) {
        fetchCompanyApiKey(adminViewAsCompany);

       // fetchLinkedAPIs(adminViewAsCompany);
      //  fetchLinkedCompanyUsers(adminViewAsCompany);
      }

    } else if (user.company) {
      const { roles } = this.props;

      if (user.role === 'teamOwner') {
        this.setState({ showNoPermission: false });
      } else {
        if (roles && roles[user.role] && roles[user.role].permissions && roles[user.role].permissions.api) {
          if (roles[user.role].permissions.api === 2) {
            this.setState({ showNoPermission: false, dontShowPage: false });
          } else {
            this.setState({ showNoPermission: false, dontShowPage: false });
          }
        } else {
          this.setState({ showNoPermission: false, dontShowPage: true });
        }
      }
    }
  }

  copyToClipboard = () => {
    const { apiKey } = this.props;

    if (apiKey) {
      navigator.clipboard.writeText(apiKey);
      this.setState({ copiedSuccess: true });
    }
  }

  toggleShow = () => {
    this.setState((prevState) => ({ show: !prevState.show }));
  }

  toggleShow2 = () => {
    this.setState((prevState) => ({ show2: !prevState.show2 }));
  }

  handleChangeAPI = (e) => {
    e.preventDefault();
    const { showConfirmModalShown, hadExistingData } = this.state;

    if (hadExistingData && !showConfirmModalShown) {
      this.setState({ showConfirmModalShown: true, showConfirmModal: true });
    }

    if (e.target && e.target.name) {
      const { name, value } = e.target;

      this.setState({ [name]: value, changeAPIError: false });
    }
  }

  handleChangeEditData = (e) => {
    e.preventDefault();

    if (e.target && e.target.name) {
      const { name, value } = e.target;

      this.setState((prevState) => ({
        editData: {
          ...prevState.editData,
          [name]: value,
        }
      }));

      if (name === 'editManualUserName') {
        this.setState({ editManualUserName: value });
      }
    }
  }

  handleChange = (e) => {
    e.preventDefault();

    if (e.target && e.target.name) {
      const { name, value } = e.target;

      this.setState({ [name]: value });
    }
  }

  handleChangeType = (e) => {
    e.preventDefault();

    if (e.target && e.target.name) {
      const { name } = e.target;

      this.setState({ editType: name });
    }
  }

  openAddModal = () => {
    this.setState({
      editModal: false,
      addModal: true,
      editName: null,
      editEmail: null,
      editType: 'shopify',
      editData: null,
      editPassword: null,
      editManualUserName: null,
    });
  }

  openEditModal = (id) => {
    const { linkedCompaniesAPIs } = this.props;

    if (linkedCompaniesAPIs[id]) {
      this.setState({
        editModal: true,
        addModal: false,
        editId: id,
        editName: linkedCompaniesAPIs[id].name,
        editEmail: linkedCompaniesAPIs[id].email,
        editType: linkedCompaniesAPIs[id].type,
        editData: linkedCompaniesAPIs[id].apiData,
      });
    }
  }

  closeModal = () => {
    this.setState({
      editModal: false,
      addModal: false,
      editId: null,
      editName: null,
      editEmail: null,
      editType: null,
      editData: null,
      editPassword: null,
      editManualUserName: null,
    });
  }

  confirmAddAPI = () => {
    const { linkedAPIAdd, createLinkedUser, user, fetchLinkedAPIs } = this.props;
    const {
      editModal,
      addModal,
      editName,
      editEmail,
      editPassword,
      editManualUserName,
    //  confirmPassword,
      editType,
      editData,
    } = this.state;

    this.setState({ modalSaveSpinner: true });

    if (user && user.company) {
      if (editType === 'manual') {
        if (editPassword) {
          // companyId, email, password, name
          createLinkedUser(user.company, editEmail, editPassword, editManualUserName).then((res) => {
            console.log('createLinkedUser res', res);

          //  fetchLinkedAPIs(user.company);

            this.setState({ addModal: null, editId: null, modalSaveSpinner: null });
          });
        } else {
          this.setState({ modalError: "password missing" });
        }
      } else {
        linkedAPIAdd(user.company, editName, editEmail, editType, editData).then((res) => {
          console.log('linkedAPIAdd res', res);

        //  fetchLinkedAPIs(user.company);

          this.setState({ addModal: null, editId: null, modalSaveSpinner: null });
        });
      }
      // linkedAPIAdd(user.company, editName, editEmail, editType, editData);
    }
  }

  confirmEditAPI = () => {
    const { linkedAPIUpdate, createLinkedUser, user, fetchLinkedAPIs } = this.props;
    const {
    //  editModal,
    //  addModal,
      editId,
      editName,
      editEmail,
      editPassword,
      editManualUserName,
    //  confirmPassword,
      editType,
      editData,
    } = this.state;

    this.setState({ modalSaveSpinner: true });

    console.log('confirmEditAPI', editType, editData);

    if (user && user.company) {
      if (editType === 'manual') {
      //  if (editPassword === confirmPassword) {
     //   this.setState({ editModal: null, editId: null, modalSaveSpinner: null });
        //  createLinkedUser(user.company, editId, editName, editEmail, editPassword);
      //  } else {
      //    this.setState({ modalError: "password don't match" });
      //  }
        linkedAPIUpdate(user.company, editId, editName, editEmail, editType, editData).then((res) => {
          console.log('linkedAPIUpdate res', res);

        //  fetchLinkedAPIs(user.company);

          this.setState({ editModal: null, editId: null, modalSaveSpinner: null });
        });
      } else {
        linkedAPIUpdate(user.company, editId, editName, editEmail, editType, editData).then((res) => {
          console.log('linkedAPIUpdate res', res);

        //  fetchLinkedAPIs(user.company);

          this.setState({ editModal: null, editId: null, modalSaveSpinner: null });
        });
      }
    }
  }

  render() {
    const { linkedCompaniesAPIs } = this.props;
    const {
      show,
      show2,
      editorState,
      showConfirmModal,
      hasOriginal,
      editModal,
      addModal,
      editName,
      editEmail,
      editPassword,
      editManualUserName,
    //  confirmPassword,
      editType,
      editData,
      modalSaveSpinner,
    } = this.state;

    console.log(editModal, addModal, editName, editEmail, editType, editData);

  //  console.log('companyLinkedUsers', companyLinkedUsers);
    console.log('linkedCompaniesAPIs', linkedCompaniesAPIs)

    return (
      <Card>
        <CardBody>
          <div className="company-api">
            {(linkedCompaniesAPIs && Object.keys(linkedCompaniesAPIs).length) ? (
              <>
                <div style={{ fontSize: 28, marginBottom: 15 }}>
                  Your connected businesses
                </div>
                {linkedCompaniesAPIs ? (Object.keys(linkedCompaniesAPIs).map((key) => (
                  <>
                    {linkedCompaniesAPIs[key] ? (
                      <Row>
                        <Col xs="1">
                          <div className={`${(linkedCompaniesAPIs[key].valid) ? 'status-indicators-active' : 'status-indicators-inactive'}`} />
                        </Col>
                        <Col xs="2">
                          {linkedCompaniesAPIs[key].name}
                        </Col>
                        <Col xs="3">
                          {linkedCompaniesAPIs[key].email}
                        </Col>
                        <Col xs="2">
                          <Button color="link" onClick={() => this.openEditModal(key)}>
                            {linkedCompaniesAPIs[key].type}
                          </Button>
                        </Col>
                      </Row>
                    ) : (null)}
                  </>
                ))) : (null)}
                <Row>
                  <Col xs="6" />
                  <Col xs="2">
                    <Button onClick={this.openAddModal} style={{ marginLeft: 25 }}>
                      +
                    </Button>
              </Col>
              <Col xs="4" />
                </Row>
              </>
            ) : (
              <Row>
                <Col xs="12">
                    <Button onClick={this.openAddModal} style={{ padding: '15px 25px 20px 25px', fontSize: 22, color: '#7f8fa4' }}>
                      <span style={{ fontWeight: 900, fontSize: 28 }}>+</span> New website connection
                  </Button>
                </Col>
              </Row>
            )}

            <Modal size="lg" isOpen={addModal} toggle={this.closeModal}>
              <ModalHeader toggle={this.closeModal}>
                Add company
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col xs="12" style={{ marginBottom: 15 }}>
                    <Input
                      name="editName"
                      id="editName"
                      type="text"
                      placeholder="Business name"
                      value={editName}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" style={{ marginBottom: 15 }}>
                    <Input
                      name="editEmail"
                      id="editEmail"
                      type="text"
                      placeholder="Support email"
                      value={editEmail}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" style={{ marginBottom: 30 }}>
                    <Row>
                      <Col xs="4">
                        <Button
                          name="shopify"
                          id="shopify"
                          color={'secondary'}
                          value={'shopify'}
                          onClick={this.handleChangeType}
                          className={`basic-information-middle-companyType-button ${editType === 'shopify' ? 'basic-information-middle-companyType-button-active' : ''}`}
                        >
                          Shopify
                        </Button>
                      </Col>
                      <Col xs="4">
                        <Button
                          name="woocommerce"
                          id="woocommerce"
                          color={'secondary'}
                        value={'woocommerce'}
                          onClick={this.handleChangeType}
                          className={`basic-information-middle-companyType-button ${editType === 'woocommerce' ? 'basic-information-middle-companyType-button-active' : ''}`}
                        >
                          Woocommerce
                        </Button>
                      </Col>
                      <Col xs="4">
                        <Button
                          name="manual"
                          id="manual"
                          color={'secondary'}
                          value={'manual'}
                          onClick={this.handleChangeType}
                          className={`basic-information-middle-companyType-button ${editType === 'manual' ? 'basic-information-middle-companyType-button-active' : ''}`}
                        >
                          Manual
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" >
                    {editType === 'shopify' ? (
                      <>
                        <FormGroup style={{ position: 'relative', marginBottom: 10 }}>
                        <Label for="shopifyAPIAccessToken">
                          Access token<div className="mandatory">*</div>
                        </Label>
                        <Input
                          name="shopifyAPIAccessToken"
                          id="shopifyAPIAccessToken"
                          type={show ? 'text' : 'password'}
                            placeholder="Access token"
                            value={editData ? editData.shopifyAPIAccessToken : ''}
                          style={{ width: '100%' }}
                            onChange={this.handleChangeEditData}
                        />
                        {show ? (
                          <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow} />
                        ) : (
                          <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow} />
                        )}
                        </FormGroup>
                        <FormGroup style={{ position: 'relative', marginBottom: 10 }}>
                        <Label for="shopifyAPIShopName">
                          Web address<div className="mandatory">*</div>
                        </Label>
                        <Input
                          name="shopifyAPIShopName"
                          id="shopifyAPIShopName"
                          type="text"
                            placeholder="Web address"
                              value={editData ? editData.shopifyAPIShopName : ''}
                          style={{ width: '100%' }}
                              onChange={this.handleChangeEditData}
                        />
                        </FormGroup>
                      </>
                    ):(null)}

                    {editType === 'woocommerce' ? (
                      <>
                        <FormGroup style={{ position: 'relative', marginBottom: 10 }}>
                          <Label for="woocommerceConsumerKey">
                            Consumer Key<div className="mandatory">*</div>
                          </Label>
                          <Input
                            name="woocommerceConsumerKey"
                            id="woocommerceConsumerKey"
                            type={show ? 'text' : 'password'}
                            placeholder="Consumer Key"
                            style={{ width: '100%' }}
                            value={editData ? editData.woocommerceConsumerKey : ''}
                            onChange={this.handleChangeEditData}
                          />
                          {show ? (
                            <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow} />
                          ) : (
                            <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow} />
                          )}
                        </FormGroup>
                        <FormGroup style={{ position: 'relative', marginBottom: 10 }}>
                          <Label for="woocommerceConsumerSecret">
                            Consumer Secret<div className="mandatory">*</div>
                          </Label>
                          <Input
                            name="woocommerceConsumerSecret"
                            id="woocommerceConsumerSecret"
                            type={show2 ? 'text' : 'password'}
                            placeholder="Consumer Secret"
                            style={{ width: '100%' }}
                            value={editData ? editData.woocommerceConsumerSecret : ''}
                            onChange={this.handleChangeEditData}
                          />
                          {show2 ? (
                            <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow2} />
                          ) : (
                            <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow2} />
                          )}
                        </FormGroup>
                        <FormGroup style={{ position: 'relative', marginBottom: 10 }}>
                          <Label for="shopUrl">
                            Shop URL<div className="mandatory">*</div>
                          </Label>
                          <Input
                            name="shopUrl"
                            id="shopUrl"
                            type="text"
                            placeholder="Shop URL"
                            value={editData ? editData.shopUrl : ''}
                            style={{ width: '100%' }}
                            onChange={this.handleChangeEditData}
                          />
                        </FormGroup>
                      </>
                    ) : (null)}

                    {editType === 'manual' ? (
                      <>
                        <Label style={{ marginLeft: 35, marginBottom: 10 }}>
                          <div>
                            This will create an online voucher portal for this business to manually redeem vouchers.
                          </div>
                          <div>
                            We will send the portal login details to the email address above.
                            </div>
                        </Label>
                        <FormGroup style={{ position: 'relative', marginBottom: 10 }}>
                          <Input
                            name="editManualUserName"
                            id="editManualUserName"
                            type="text"
                            placeholder="Name"
                            value={editManualUserName}
                            onChange={this.handleChangeEditData}
                          />
                        </FormGroup>
                        <FormGroup style={{ position: 'relative', marginBottom: 10 }}>
                          <Input
                            name="editPassword"
                            id="editPassword"
                            type={show ? 'text' : 'password'}
                            placeholder="Password"
                            value={editPassword}
                            onChange={this.handleChange}
                          />
                          {show ? (
                            <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow} />
                          ) : (
                            <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow} />
                          )}
                        </FormGroup>
                      </>
                    ) : (null)}
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.confirmAddAPI}>
                  Confirm
                  {(modalSaveSpinner) ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                  ) : (null)}
                </Button>
              </ModalFooter>
            </Modal>


            <Modal size="lg" isOpen={editModal} toggle={this.closeModal}>
              <ModalHeader toggle={this.closeModal}>
                Edit
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col xs="12" style={{ marginBottom: 15 }}>
                    <Input
                      name="editName"
                      id="editName"
                      type="text"
                      placeholder="Business name"
                      value={editName}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" style={{ marginBottom: 15 }}>
                    <Input
                      name="editEmail"
                      id="editEmail"
                      type="text"
                      placeholder="Support email"
                      value={editEmail}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" style={{ marginBottom: 30 }}>
                    <Row>
                      <Col xs="4">
                        <Button
                          name="shopify"
                          id="shopify"
                          color={'secondary'}
                          value={'shopify'}
                          disabled
                          onClick={this.handleChangeType}
                          className={`basic-information-middle-companyType-button ${editType === 'shopify' ? 'basic-information-middle-companyType-button-active' : ''}`}
                        >
                          Shopify
                        </Button>
                      </Col>
                      <Col xs="4">
                        <Button
                          name="woocommerce"
                          id="woocommerce"
                          color={'secondary'}
                          value={'woocommerce'}
                          disabled
                          onClick={this.handleChangeType}
                          className={`basic-information-middle-companyType-button ${editType === 'woocommerce' ? 'basic-information-middle-companyType-button-active' : ''}`}
                        >
                          Woocommerce
                        </Button>
                      </Col>
                      <Col xs="4">
                        <Button
                          name="manual"
                          id="manual"
                          color={'secondary'}
                          value={'manual'}
                          disabled
                          onClick={this.handleChangeType}
                          className={`basic-information-middle-companyType-button ${editType === 'manual' ? 'basic-information-middle-companyType-button-active' : ''}`}
                        >
                          Manual
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" >
                    {editType === 'shopify' ? (
                      <>
                        <FormGroup style={{ position: 'relative', marginBottom: 10 }}>
                          <Label for="shopifyAPIAccessToken">
                            Access token<div className="mandatory">*</div>
                          </Label>
                          <Input
                            name="shopifyAPIAccessToken"
                            id="shopifyAPIAccessToken"
                            type={show ? 'text' : 'password'}
                            placeholder=" Access token"
                            value={editData ? editData.shopifyAPIAccessToken : ''}
                            style={{ width: '100%' }}
                            onChange={this.handleChangeEditData}
                          />
                          {show ? (
                            <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow} />
                          ) : (
                            <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow} />
                          )}
                        </FormGroup>
                        <FormGroup style={{ position: 'relative', marginBottom: 10 }}>
                          <Label for="shopifyAPIShopName">
                            Web address<div className="mandatory">*</div>
                          </Label>
                          <Input
                            name="shopifyAPIShopName"
                            id="shopifyAPIShopName"
                            type="text"
                            placeholder="Web address"
                            value={editData ? editData.shopifyAPIShopName : ''}
                            style={{ width: '100%' }}
                            onChange={this.handleChangeEditData}
                          />
                        </FormGroup>
                      </>
                    ) : (null)}

                    {editType === 'woocommerce' ? (
                      <>
                        <FormGroup style={{ position: 'relative', marginBottom: 10 }}>
                          <Label for="woocommerceConsumerKey">
                            Consumer Key<div className="mandatory">*</div>
                          </Label>
                          <Input
                            name="woocommerceConsumerKey"
                            id="woocommerceConsumerKey"
                            type={show ? 'text' : 'password'}
                            placeholder="Consumer Key"
                            style={{ width: '100%' }}
                            value={editData ? editData.woocommerceConsumerKey : ''}
                            onChange={this.handleChangeEditData}
                          />
                          {show ? (
                            <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow} />
                          ) : (
                            <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow} />
                          )}
                        </FormGroup>
                        <FormGroup style={{ position: 'relative', marginBottom: 10 }}>
                          <Label for="woocommerceConsumerSecret">
                            Consumer Secret<div className="mandatory">*</div>
                          </Label>
                          <Input
                            name="woocommerceConsumerSecret"
                            id="woocommerceConsumerSecret"
                            type={show2 ? 'text' : 'password'}
                            placeholder="Consumer Secret"
                            style={{ width: '100%' }}
                            value={editData ? editData.woocommerceConsumerSecret : ''}
                            onChange={this.handleChangeEditData}
                          />
                          {show2 ? (
                            <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow2} />
                          ) : (
                            <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow2} />
                          )}
                        </FormGroup>
                        <FormGroup style={{ position: 'relative', marginBottom: 10 }}>
                          <Label for="shopUrl">
                            Shop URL<div className="mandatory">*</div>
                          </Label>
                          <Input
                            name="shopUrl"
                            id="shopUrl"
                            type="text"
                            placeholder="Shop URL"
                            value={editData ? editData.shopUrl : ''}
                            style={{ width: '100%' }}
                            onChange={this.handleChangeEditData}
                          />
                        </FormGroup>
                      </>
                    ) : (null)}

                    {editType === 'manual' ? (
                      <>
                        <Label style={{ marginLeft: 35, marginBottom: 10 }}>
                          <div>
                            This will create an online voucher portal for this business to manually redeem vouchers.
                          </div>
                          <div>
                            We will send the portal login details to the email address above.
                          </div>
                        </Label>
                        <FormGroup style={{ position: 'relative', marginBottom: 15 }}>
                          <Input
                            name="editManualUserName"
                            id="editManualUserName"
                            type="text"
                            placeholder="Name"
                            value={editManualUserName}
                            onChange={this.handleChangeEditData}
                          />
                        </FormGroup>
                        <FormGroup style={{ position: 'relative', marginBottom: 15 }}>
                          <Input
                            name="editPassword"
                            id="editPassword"
                            type={show ? 'text' : 'password'}
                            placeholder="Password"
                            value={editPassword}
                            onChange={this.handleChange}
                          />
                          {show ? (
                            <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow} />
                          ) : (
                            <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow} />
                          )}
                        </FormGroup>
                      </>
                    ) : (null)}
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.confirmEditAPI}>
                  Confirm
                  {(modalSaveSpinner) ? (
                    <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                  ) : (null)}
                </Button>
              </ModalFooter>
            </Modal>

            {/* <TabContent activeTab={activeAPI} style={{ padding: 0, background: 'transparent' }}>
              <TabPane tabId="shopify">
                {!!changeAPIError ? (<Alert color="danger"> {changeAPIError} </Alert>) : null}

                <Row style={{ marginTop: 5 }}>
                  <Col>
                    <div className="company-api-input" style={{ position: 'relative', width: '35%', marginRight: 10 }}>

                      <Label for="shopifyAPIAccessToken">
                        Access token<div className="mandatory">*</div>
                      </Label>
                      <Input
                        name="shopifyAPIAccessToken"
                        id="shopifyAPIAccessToken"
                        type={show ? 'text' : 'password'}
                        placeholder=""
                        value={shopifyAPIAccessToken}
                        style={{ width: '100%' }}
                        onChange={this.handleChangeAPI}
                      >
                        {apiKey || null}
                      </Input>
                      {show ? (
                        <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow} />
                      ) : (
                        <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow} />
                      )}
                    </div>

                    <div className="company-api-input" style={{ width: '35%' }}>
                      <Label for="shopifyAPIShopName">
                        Web address<div className="mandatory">*</div>
                      </Label>
                      <Input
                        name="shopifyAPIShopName"
                        id="shopifyAPIShopName"
                        type="text"
                        placeholder=""
                        value={shopifyAPIShopName}
                        style={{ width: '100%' }}
                        onChange={this.handleChangeAPI}
                      >
                        {apiKey || null}
                      </Input>
                    </div>

                    <Button
                      style={{ top: -2 }}
                      disabled={showNoPermission}
                      className="company-api-button custom-modal-button"
                      color="primary"
                      onClick={this.changeShopifyAPI}
                      data-tip
                      data-for='confirmButton'
                      for="confirmButton"
                    >
                      Confirm
                      {(processing) ? (
                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                      ) : (null)}
                    </Button>

                    <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='confirmButton'>
                      <span>{apiTooltips.apiTabConfirm ? apiTooltips.apiTabConfirm.value : ''}</span>
                    </ReactTooltip>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="woocommerce">
                {!!changeAPIError ? (<Alert color="danger"> {changeAPIError} </Alert>) : null}

                <Row style={{ marginTop: 5 }}>
                  <Col>
                    <div className="company-api-input" style={{ position: 'relative', width: '35%', marginRight: 10 }}>

                      <Label for="woocommerceConsumerKey">
                        Consumer Key<div className="mandatory">*</div>
                      </Label>
                      <Input
                        name="woocommerceConsumerKey"
                        id="woocommerceConsumerKey"
                        type={show ? 'text' : 'password'}
                        placeholder=""
                        value={woocommerceConsumerKey}
                        style={{ width: '100%' }}
                        onChange={this.handleChangeAPI}
                      >
                        {apiKey || null}
                      </Input>
                      {show ? (
                        <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow} />
                      ) : (
                        <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow} />
                      )}
                    </div>

                    <div className="company-api-input" style={{ position: 'relative', width: '35%', marginRight: 10 }}>
                      <Label for="woocommerceConsumerSecret">
                        Consumer Secret<div className="mandatory">*</div>
                      </Label>
                      <Input
                        name="woocommerceConsumerSecret"
                        id="woocommerceConsumerSecret"
                        type={show2 ? 'text' : 'password'}
                        placeholder=""
                        value={woocommerceConsumerSecret}
                        style={{ width: '100%' }}
                        onChange={this.handleChangeAPI}
                      >
                        {apiKey || null}
                      </Input>
                      {show2 ? (
                        <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow2} />
                      ) : (
                        <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={this.toggleShow2} />
                      )}
                    </div>

                    <div className="company-api-input" style={{ width: '35%' }}>
                      <Label for="shopUrl">
                        Shop URL<div className="mandatory">*</div>
                      </Label>
                      <Input
                        name="shopUrl"
                        id="shopUrl"
                        type="text"
                        placeholder=""
                        value={shopUrl}
                        style={{ width: '100%' }}
                        onChange={this.handleChangeAPI}
                      >
                        {apiKey || null}
                      </Input>
                    </div>

                    <Button
                      style={{ top: -2 }}
                      disabled={showNoPermission}
                      className="company-api-button custom-modal-button"
                      color="primary"
                      onClick={this.changeWoocommerceAPI}
                      data-tip
                      data-for='confirmButton'
                      for="confirmButton"
                    >
                      Confirm
                      {(processing) ? (
                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                      ) : (null)}
                    </Button>

                    <ReactTooltip place="right" className='react-tooltip-custom-class' effect="solid" id='confirmButton'>
                      <span>{apiTooltips.apiTabConfirm ? apiTooltips.apiTabConfirm.value : ''}</span>
                    </ReactTooltip>
                  </Col>
                </Row>
              </TabPane>
            </TabContent> */}


            <Row>
              <Col>
                <Editor
                  className="content-management-text-area"
                  editorState={editorState}
                  toolbarHidden
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  readOnly
                />
              </Col>
            </Row>

            <Modal size="sm" isOpen={showConfirmModal} toggle={this.closeConfirmModal}>
              <ModalHeader toggle={this.closeConfirmModal}>
                <Button clasName="custom-modal-button" color="primary" onClick={this.approveConfirmModal}>
                  Confirm change
                </Button>
              </ModalHeader>
            </Modal>
          </div>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  apiKey: state.company.apiKey || '',
  roles: state.users.companiesRoles || {},
//  companyLinkedUsers: state.users.companyLinkedUsers || {},
  adminsRoles: state.admin.adminsRoles || {},
  apiTooltips: state.contentManagement.apiTooltips || {},
  activeAPI: state.company.activeAPI || 'shopify',
  adminViewAsCompany: state.admin.adminViewAsCompany || null,
});

const mapDispatchToProps = {
  fetchCompanyApiKey: getCompanyApiKey,
  fetchAPIPageData: getAPIPageData,

  fetchAPITooltips: getAPITooltips,

  fetchLinkedAPIs: getLinkedAPIs,
  linkedAPIupdate: updateLinkedAPI,
  linkedAPIAdd: addLinkedAPI,
  linkedAPIRemove: removeLinkedAPI,

  fetchLinkedCompanyUsers: getLinkedCompanyUsers,
  createLinkedUser: createLinkedCompanyUser,
  deleteLinkedUser: deleteLinkedCompanyUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LinkedCompaniesAPIs));
