import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import Draggable from 'react-draggable';
import { ChromePicker } from 'react-color';

import { ResizableBox } from 'react-resizable';
// const ResizableBox = require('react-resizable').ResizableBox;

import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';

import { Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label, Form, FormGroup, Alert, Media } from 'reactstrap';

import { connect } from 'react-redux';

import {
  saveCardTemplate,
  getGreetingCardsCategories,
} from '../../../actions/admin';

const FRAME_WIDTH = 620;
const FRAME_HEIGHT = 874;

// 620 x 874

//	1748 x 1240

// 2480 x 1748

class Draging extends Component {
  static propTypes = {
  };

  static defaultProps = {
  };

  constructor(props) {
    super(props);
    this.state = {
      elements: [

      ],
      types: ['text', 'picture', 'background'],
      editorValues: {},
      changeColor: false,
      changeElementData: false,
      cropData: {
        unit: 'px',
        width: 160,
        height: 90,
      },
      category: 1,
    };

    this.elementRefs = [];
  }

  componentDidMount() {
    const { fetchGreetingCardsCategories } = this.props;

    fetchGreetingCardsCategories();
  }

/*
  handleStart = (e) => {
    console.log(e);
  }

  handleDrag = (e) => {
    console.log(e);
  } */

  handleChangeSize = (e, index) => {
    const { value, name } = e.target;

    console.log('handleChange', { value, name, index });
    let elements = [ ...this.state.elements ];
    let editorValues = { ...this.state.editorValues };

    let element = { ...elements[index] };

    let nameVal = 'width';
    if (name === 'heightVal') {
      nameVal = 'height';
    }

    if (value.includes('%')) {
      const precentage = parseInt(value);
      const total = FRAME_WIDTH;
      if (nameVal === 'height') {
        total = FRAME_HEIGHT;
      }
      element[nameVal] = Math.round((precentage / 100) * total);
      editorValues[nameVal] = Math.round((precentage / 100) * total);

      element[name] = value;
      editorValues[name] = value;
    } else {
      element[nameVal] = parseInt(value);
      editorValues[nameVal] = parseInt(value);

      element[name] = value;
      editorValues[name] = value;
    }
    elements[index] = element;

    this.setState({ editorValues, elements, changeColor: null });
  }

  handleChange = (e, index) => {
    const { value, name } = e.target;

    console.log('handleChange', { value, name, index });

  //  if (name === 'margins') {
  //    if (value)
  //  }

    let elements = [ ...this.state.elements ];
    let editorValues = { ...this.state.editorValues };

    let element = { ...elements[index] };
    element[name] = parseFloat(value);
    elements[index] = element;

    editorValues[name] = parseFloat(value);

    this.setState({ editorValues, elements, changeColor: null });
  }

  changeProperty = (value, type, index) => {

    console.log('changeProperty', { value, type });

  //  if (name === 'margins') {
  //    if (value)
  //  }

    let elements = [ ...this.state.elements ];
    let editorValues = { ...this.state.editorValues };

    let element = { ...elements[index] };
    element[type] = value;
    elements[index] = element;

    editorValues[type] = value;

    this.setState({ editorValues, elements, changeColor: null });
  }

  toggleChangeColor = (type) => {
    if (type === 'color') {
      this.setState({ changeColor: type });
    } else if (type === 'borderColor') {
      this.setState({ changeColor: type });
    } else if (type === null) {
      this.setState({ changeColor: null });
    }
  }

  handleChangeColor = (color, type, index) => {
    const { hex } = color;

  //  console.log('handleChangeColor', { hex, type, index });
  //  console.log(this.state);

  //  if (name === 'margins') {
  //    if (value)
  //  }

    let elements = [ ...this.state.elements ];
    let editorValues = { ...this.state.editorValues };

    let element = { ...elements[index] };

    element[type] = hex;
    elements[index] = element;

    editorValues[type] = hex;

    this.setState({ editorValues, elements });
  }

  handleStop = (e, data, index) => {
    console.log(e);
    console.log(data);
    let elements = [ ...this.state.elements ];
    let editorValues = { ...this.state.editorValues };

    let element = { ...elements[index] };
  //  element.x = Math.round(data.x / 10) * 10;
  //  element.y = Math.round(data.y / 10) * 10;

    element.y = Math.round((data.y / FRAME_HEIGHT) * 100) / 100;
    element.x = Math.round((data.x / FRAME_WIDTH) * 100) / 100;

  //  editorValues.x = Math.round(data.x / 10) * 10;
  //  editorValues.y = Math.round(data.y / 10) * 10;

    editorValues.y = Math.round((data.y / FRAME_HEIGHT) * 100) / 100;
    editorValues.x = Math.round((data.x / FRAME_WIDTH) * 100) / 100;

    elements[index] = element;
  //  newElement = { ..element, x: e.clientX, y: e.clientY }

    this.setState({ elements, editorValues });
  }

  onClick = (e, index) => {
    console.log('onClick', { index });
    console.log(e.target);
    const { activeElement, elements } = this.state;

    if (activeElement !== index) {
      const editorValues = elements[index];

      console.log('onClick', { editorValues });

      this.setState({ activeElement: index, editorValues, changeColor: null });
    } else {
    //  e.preventDefault();
    }
  }

  addNewElement = (type) => {
    const { activeElement } = this.state;
    let elements = [ ...this.state.elements ];

    let placeholder = 'Tap to add text/picture';
    if (type === 'text') {
      placeholder = 'Tap to add text';
    } else if (type === 'picture') {
      placeholder = 'Tap to add picture';
    } else if (type === 'background') {
      placeholder = 'Tap to add background';
    }

    let element = {};

    if (type !== 'background') {
  /*  const editorValues = {
      type: type,
      placeholder: placeholder,
    //  id: 2,
      x: 0,
      y: 0,
      width: 100,
      height: 80,
      fontSize: 14,
      color: '#000',
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: 0,
      borderWidth: 0,
      borderColor: '#000'
    }; */

      element = {
        type: type,
        placeholder: placeholder,
      //  id: 2,
        x: 0,
        y: 0,
        width: 100,
        height: 80,
        fontSize: 14,
        textAlign: 'center',
        color: '#000',
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        marginBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: 0,
        borderWidth: 0,
        borderColor: '#000'
      };
    } else {
      element = {
        type: type,
        placeholder: placeholder,
        x: 0,
        y: 0,
        width: FRAME_WIDTH,
        height: FRAME_HEIGHT,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        marginBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: 0,
        borderWidth: 0,
        borderColor: '#000'
      };
    }

    elements.push(element);

    const lastIndex = elements.length - 1;

    console.log('addNewElement', { type, element });

    this.setState({ activeElement: lastIndex, editorValues: element, elements, changeColor: null });
  }

  onResizeMove = (e, data, index) => {
    const { resizingElement } = this.state;

  //  if (resizingElement === index) {
      console.log('onResizeMove', { data, index, resizingElement });
      let elements = [ ...this.state.elements ];
    //  let editorValues = { ...this.state.editorValues };

      let element = { ...elements[index] };

      console.log('element', { element });
      if ((element.x * FRAME_WIDTH) + element.width <= FRAME_WIDTH) {
        element.width = data.size.width;
      //  element.height = data.size.height;
      } else {
        element.width = data.size.width;
        element.x = Math.round((FRAME_WIDTH - data.size.width) / FRAME_WIDTH * 100) / 100;
      }

      if ((element.y * FRAME_HEIGHT) + element.height <= FRAME_HEIGHT) {
        element.height = data.size.height;
      } else {
        element.height = data.size.height;
      //  element.y = FRAME_HEIGHT - data.size.height;
        element.y = Math.round((FRAME_HEIGHT - data.size.height) / FRAME_HEIGHT * 100) / 100;
      }

      console.log('new element', { element });

    //  editorValues.x = element.x,
    //  editorValues.y = element.y,

      elements[index] = element;

      this.setState({ elements, editorValues: element });
  //  }
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState((prevState) => ({
          editorValues: {
            ...prevState.editorValues,
            dataSrc: reader.result,
          }
        }))
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    console.log('onImageLoaded');
    console.log(`image.naturalWidth - ${image.naturalWidth}`);
    console.log(`image.width - ${image.width}`);

    if (!image || (image && image.width < 160 || image.height < 90)) {
      console.log('image too small');
      this.imageRef = null;

        this.setState((prevState) => ({
          croppedImageUrl: null,
          editorValues: {
            ...prevState.editorValues,
            data: null,
            dataSrc: '',
          },
          cropData: null,
          error: 'Minimum image size is 160x90px',
        }));
    } else if (!image || (image && image.width > 4096 || image.height > 2160)) {
      console.log('image too large');
      this.imageRef = null;

        this.setState((prevState) => ({
          croppedImageUrl: null,
          editorValues: {
            ...prevState.editorValues,
            data: null,
            dataSrc: '',
          },
          cropData: null,
          error: 'Mazimum image size is 4096x2160px',
        }));
    } else {
      console.log('image size correct');
      this.setState({ error: null });

      console.log(image);

      this.imageRef = image;
    }
  };

  onCropComplete = (crop, index) => {
    console.log('onCropComplete', { crop });
    this.makeClientCrop(crop, index);
  };

  onCropChange = (crop) => {
    console.log('onCropChange', { crop });
    this.setState({ cropData: crop });
  };

  async makeClientCrop(crop, index) {
    console.log('makeClientCrop', { crop, index });
    if (this.imageRef && crop.width && crop.height) {
      const { croppedImageUrl, blob } = await this.getCroppedImg(
        this.imageRef,
        crop,
        `newFile-${index}.jpeg`
      );

      this.setState((prevState) => ({
        croppedImageUrl,
        editorValues: {
          ...prevState.editorValues,
          data: blob,
          dataUrl: croppedImageUrl,
        //  dataValue: blob,
        },
      }));
    }
  }

  getCroppedImg(image, crop, fileName) {
      const canvas = document.createElement('canvas');

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      canvas.width = crop.width * scaleX;
      canvas.height = crop.height * scaleY;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );

      return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }

          blob.name = fileName;
          blob.lastModifiedDate = new Date();

          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve({ croppedImageUrl: this.fileUrl, blob });
        }, 'image/jpeg');
      });
  }

  startChange = (value, index) => {
    console.log('startChange', { value, index });

    let elements = [ ...this.state.elements ];

    let element = { ...elements[index] };

    element.data = value || '';
  //  element.dataValue = value || '';

    elements[index] = element;

    this.setState({ elements, editorValues: element, changeElementData: true });
  }

  saveSrcChange = () => {
    const { activeElement, croppedImageUrl } = this.state;
    const editorValues = { ...this.state.editorValues };
    let elements = [ ...this.state.elements ];

    let element = editorValues;

  //  element.data = element.dataValue || null;
  //  element.dataValue = null;

    elements[activeElement] = element;

    this.setState({ elements, editorValues: element, changeElementData: false });
  }

  closeChange = () => {
    this.setState((prevState) => ({
      changeElementData: false
    }));
  }

  saveTemplate = () => {
    const  { saveTemplate } = this.props;
    const { elements, category } = this.state;

    console.log('saveTemplate', { elements });

    let data = { category: category };
    let templateData = [];
  //  let dataInfo = {};

    elements.map((element, index) => {
    //  if (element.data) {
    ///    dataInfo[index] = element.data;
    //  }

      templateData.push({
        placeholder: element.placeholder,
        type: element.type,
      //  data: element.data || null,
        style: {
        //  width: element.width,
        //  height: element.height,
          width: `${Math.round((element.width / FRAME_WIDTH) * 100)}%`,
          height: `${Math.round((element.height / FRAME_HEIGHT) * 100)}%`,
          position: 'absolute',
        //  top: Math.round((element.y / FRAME_HEIGHT) * 100) / 100,
        //  left: Math.round((element.x / FRAME_WIDTH) * 100) / 100,
        //  top: element.y * 100,
        //  left: element.x * 100,
          top: `${Math.round(element.y * 100)}%`,
          left: `${Math.round(element.x * 100)}%`,
          fontSize: element.fontSize,
          color: element.color,
          textAlign: element.textAlign,
        }
      });

      if (element.data) {
        templateData[index] = { ...templateData[index], data: element.data };
      }

    /*  if (element.marginLeft) {
        templateData[index] = { ...templateData[index], marginLeft: element.marginLeft };
      }

      if (element.marginRight) {
        templateData[index] = { ...templateData[index], marginRight: element.marginRight };
      }

      if (element.marginTop) {
        templateData[index] = { ...templateData[index], marginTop: element.marginTop };
      }

      if (element.marginBottom) {
        templateData[index] = { ...templateData[index], marginBottom: element.marginBottom };
      }

      if (element.paddingLeft) {
        templateData[index] = { ...templateData[index], paddingLeft: element.paddingLeft };
      }

      if (element.paddingRight) {
        templateData[index] = { ...templateData[index], paddingRight: element.paddingRight };
      }

      if (element.paddingTop) {
        templateData[index] = { ...templateData[index], paddingTop: element.paddingTop };
      }

      if (element.paddingBottom) {
        templateData[index] = { ...templateData[index], paddingBottom: element.paddingBottom };
      }

      if (element.borderRadius) {
        templateData[index] = { ...templateData[index], borderRadius: element.borderRadius };
      } */

      if (element.borderWidth) {
        templateData[index] = { ...templateData[index], borderWidth: element.borderWidth };
      }

      if (element.borderColor) {
        templateData[index] = { ...templateData[index], borderColor: element.borderColor };
      }

    });

    data.templateData = templateData;

    console.log('templateData', { templateData });

    saveTemplate(data, templateData).then((res) => {
      console.log('saveTemplate res', { res });
      this.setState({ elements: [] });
    });
  }

  handleChangeCategory = (e) => {
    this.setState({ category: e.target.value });
  };

  render() {
    const { users, cardCategories } = this.props;
    const {
      statex,
      statey,
      sortColumn,
      elements,
      activeElement,
      editorValues,
      changeColor,
      cropData,
      crop,
      error,
    //  croppedImageUrl,
      changeElementData,
      category,
     } = this.state;

     console.log(editorValues);
  //  console.log(cardCategories);

  console.log('FRAME_WIDTH', { FRAME_WIDTH, FRAME_HEIGHT });

    return (
      <Row>
        <Col sm="8">
          <div style={{ width: FRAME_WIDTH, height: FRAME_HEIGHT }} className="draggable-container">
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            {elements ? elements.map((element, index) => (
              <>
              {element.type !== 'background' ? (
              <Draggable
                axis="both"
                bounds='parent'
              //  bounds={{ left: 0, top: 0, right: FRAME_WIDTH - 3, bottom: FRAME_HEIGHT - 3 }}
                handle=".handle"
                defaultClassName="draggable-element"
                defaultPosition={{ x: 50, y: 0 }}
              //  position={null}
                position={{ x: element.x * FRAME_WIDTH, y: element.y * FRAME_HEIGHT }}
                grid={[1, 1]}
                scale={1}
              //  onStart={this.handleStart}
              //  onDrag={this.handleDrag}
                onMouseDown={(e) => {this.onClick(e, index)}}
                onStop={(e, data) => this.handleStop(e, data, index)}
                style={{ width: '100%', height: '100%', zIndex: element.type !== 'background' ? 500 : 50 }}
              //  style={{ width: element.width, height: element.height, zIndex: element.type !== 'background' ? 500 : 50 }}
              >
                <div className={`${activeElement === index ? 'draggable-active': 'draggable-not-active'}`}>

                  <div className="handle">+</div>
                    <ResizableBox
                      width={element.width}
                      height={element.height}
                      minConstraints={[100, 50]}
                      maxConstraints={[FRAME_WIDTH, FRAME_HEIGHT]}
                      handle={<div className="resize-handle">></div>}
                      onMouseDown={(e) => {this.onClick(e, index)}}
                      onResize={(e, data) => this.onResizeMove(e, data, index)}
                    >
                      <div
                        style={{
                          width: element.width,
                          height: element.height,
                        //  width: '100%',
                        //  height: '100%',
                          fontSize: element.fontSize,
                          color: element.color,
                          textAlign: element.textAlign,
                        //  borderWidth: element.borderWidth,
                      //    borderRadius: element.borderRadius,
                        //  borderColor: element.borderColor,
                        //  borderStyle: 'solid',
                        /*  marginLeft: element.marginLeft,
                          marginRight: element.marginRight,
                          marginTop: element.marginTop,
                          marginBottom: element.marginBottom,
                          paddingLeft: element.paddingLeft,
                          paddingRight: element.paddingRight,
                          paddingTop: element.paddingTop,
                          paddingBottom: element.paddingBottom, */
                          overflow: 'hidden',
                        //  minWidth: 100,
                        //  minHeight: 50,
                        }}
                      >
                      {/* <Media src={element.dataUrl} /> */}
                        {(element.type === 'picture' || (element.type[1] && element.type[1] === 'picture')) ? (
                          <>
                            {element.data ? (
                              <div style={{ backgroundImage: `url(${element.dataUrl})` }} className="draggable-element-image"> </div>
                            ):(
                              <div>{element.placeholder}</div>
                            )}
                          </>
                        ):(
                          <div>{element.placeholder}</div>
                        )}
                      </div>
                    </ResizableBox>
                </div>
              </Draggable>
              ): (
                <div
                  onClick={(e) => {this.onClick(e, index)}}
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                  //  borderWidth: element.borderWidth,
                  //  borderRadius: element.borderRadius,
                  //  borderColor: element.borderColor,
                    borderStyle: 'solid',
                  /*  marginLeft: element.marginLeft,
                    marginRight: element.marginRight,
                    marginTop: element.marginTop,
                    marginBottom: element.marginBottom,
                    paddingLeft: element.paddingLeft,
                    paddingRight: element.paddingRight,
                    paddingTop: element.paddingTop,
                    paddingBottom: element.paddingBottom, */
                    overflow: 'hidden',
                    minWidth: '100%',
                    minHeight: '100%',
                  }}
                >
                  <>
                    {element.data ? (
                      <div style={{ backgroundImage: `url(${element.dataUrl})` }} className="draggable-element-image"> </div>
                    ):(
                      <div>{element.placeholder}</div>
                    )}
                  </>
                </div>
              )}
              </>
            )):(null)}
            </div>
          </div>
        </Col>
        <Col sm="4">
          <Button className="draggable-edit-button" onClick={() => this.saveTemplate()}>
            Save Template
          </Button>
          {(cardCategories && Object.keys(cardCategories).length) ? (
            <Input name="category" type="select" value={category} onChange={this.handleChangeCategory}>
              {Object.keys(cardCategories).map((key, index) => (
                <option value={key}>{cardCategories[key].title}</option>
              ))}
            </Input>
          ):(null)}

          <Label> Add new element </Label>
          <Row className="draggable-edit-border-bottom">
            <Col sm="3" className="draggable-edit-button-wrapper">
              <Button className="draggable-edit-button" onClick={() => this.addNewElement('text')}>
                Text
              </Button>
            </Col>
            <Col sm="3" className="draggable-edit-button-wrapper">
              <Button className="draggable-edit-button" onClick={() => this.addNewElement('picture')}>
                Picture
              </Button>
            </Col>
            <Col sm="3" className="draggable-edit-button-wrapper">
              <Button className="draggable-edit-button" onClick={() => this.addNewElement(['text', 'picture'])}>
                Text/Picture
              </Button>
            </Col>
            <Col sm="3" className="draggable-edit-button-wrapper">
              <Button className="draggable-edit-button" onClick={() => this.addNewElement('background')}>
                Background
              </Button>
            </Col>
          </Row>
          <div className="draggable-edit">
            {(activeElement || activeElement === 0) ? (
              <>
                {(editorValues.type === 'picture' || (editorValues.type[1] && editorValues.type[1] === 'picture')) ? (
                  <div className="draggable-edit-border-bottom">
                    <Label> Image source </Label>
                    {(changeElementData) ? (
                      <Modal size="lg" isOpen={changeElementData} toggle={(e) => this.closeChange()}>
                        <ModalHeader toggle={(e) => this.closeChange()}>
                          Change data
                        </ModalHeader>
                        <ModalBody>
                          {(!!error) && <Alert color="danger">{error}</Alert>}
                          <div>
                            <input type="file" accept="image/*" onChange={(e) => this.onSelectFile(e)} />
                          </div>
                          {editorValues.dataSrc && (
                            <ReactCrop
                              src={editorValues.dataSrc}
                              crop={cropData}
                              ruleOfThirds
                              minWidth={160}
                              minHeight={90}
                              onImageLoaded={(e) => this.onImageLoaded(e)}
                              onComplete={(e) => this.onCropComplete(e, activeElement)}
                              onChange={(crop) => this.onCropChange(crop)}
                            />
                          )}
                          {editorValues.dataUrl && (
                            <>

                            <img alt="Crop" style={{ maxWidth: '100%' }} src={editorValues.dataUrl} />
                            <div style={{ backgroundImage: `url(${editorValues.dataUrl})` }} className="draggable-element-image"> </div>
                            </>
                          )}
                        </ModalBody>
                        <ModalFooter>
                          <Button color="primary" onClick={this.saveSrcChange}>Save</Button>
                          <Button color="secondary" onClick={(e) => this.closeChange()}>Close</Button>
                        </ModalFooter>
                      </Modal>
                    ):(null)}
                  {/*  <div style={{ display: 'inline-block', width: '40%', verticalAlign: 'top' }}>
                      {console.log(editorValues.dataUrl)}
                      {(elements[activeElement]) ? (
                        <Media style={{ maxHeight: 100 }} src={elements[activeElement].dataUrl} />
                      ):(null)}
                      <div style={{ backgroundImage: `url(${editorValues.dataUrl})`, width: 100, height: 100 }} className="draggable-element-image"> </div>
                    </div> */}
                    <Button style={{ display: 'inline-block' }} onClick={(e) => this.startChange(editorValues.data, activeElement)}>
                      Change
                    </Button>
                  </div>
                ):(null)}
                {editorValues.type !== 'background' ? (
                  <>
                    <Label> Positions </Label>
                    <Row>
                      <Col>
                        <Label> X </Label>
                        <Input
                          type="number"
                          name="x"
                          id="x"
                          value={editorValues.x}
                          onChange={(e) => this.handleChange(e, activeElement)}
                        />
                      </Col>
                      <Col>
                        <Label> Y </Label>
                        <Input
                          type="number"
                          name="y"
                          id="y"
                          value={editorValues.y}
                          onChange={(e) => this.handleChange(e, activeElement)}
                        />
                      </Col>
                    </Row>
                    <Label> Size </Label>
                    <Row className="draggable-edit-border-bottom">
                      <Col>
                        <Label> Width </Label>
                        <Input
                          type="text"
                          name="width"
                          id="width"
                          value={editorValues.width ? (Math.round(editorValues.width / FRAME_WIDTH * 100) / 100) : ''}
                        //  onChange={(e) => this.handleChangeSize(e, activeElement)}
                        />
                      </Col>
                      <Col>
                        <Label> Height </Label>
                        <Input
                          type="text"
                          name="height"
                          id="height"
                          value={editorValues.height ? (Math.round(editorValues.height / FRAME_HEIGHT * 100) / 100) : ''}
                        //  onChange={(e) => this.handleChangeSize(e, activeElement)}
                        />
                      </Col>
                    </Row>
                    {editorValues.type !== 'picture' ? (
                      <>
                        <Label> Text Align </Label>
                        <Row>
                          <Col sm="3" className="draggable-edit-button-wrapper">
                            <Button className={`draggable-edit-button ${(!editorValues.textAlign || editorValues.textAlign === 'left') ? 'draggable-edit-active' : null}` } onClick={() => this.changeProperty('left', 'textAlign', activeElement)}>
                              Left
                            </Button>
                          </Col>
                          <Col sm="3" className="draggable-edit-button-wrapper">
                            <Button className={`draggable-edit-button ${(editorValues.textAlign === 'center') ? 'draggable-edit-active' : null}` } onClick={() => this.changeProperty('center', 'textAlign', activeElement)}>
                              Center
                            </Button>
                          </Col>
                          <Col sm="3" className="draggable-edit-button-wrapper">
                            <Button className={`draggable-edit-button ${(editorValues.textAlign === 'right') ? 'draggable-edit-active' : null}` } onClick={() => this.changeProperty('right', 'textAlign', activeElement)}>
                              Right
                            </Button>
                          </Col>
                          <Col sm="3" className="draggable-edit-button-wrapper">
                            <Button className={`draggable-edit-button ${(editorValues.textAlign === 'justify') ? 'draggable-edit-active' : null}` } onClick={() => this.changeProperty('justify', 'textAlign', activeElement)}>
                              Justify
                            </Button>
                          </Col>
                        </Row>
                        <Label> Font Size </Label>
                        <Input
                          type="number"
                          name="fontSize"
                          id="fontSize"
                          value={editorValues.fontSize}
                          onChange={(e) => this.handleChange(e, activeElement)}
                        />
                        <div className="draggable-edit-border-bottom">
                          <Label> Text color </Label>
                          {(changeColor === 'color') ? (
                            <ChromePicker color={ editorValues.color || '#000' } onChange={ (color) => this.handleChangeColor(color, 'color', activeElement) }/>
                          ):(
                            <div style={{ background: editorValues.color || '#000' }} className='draggable-edit-color-button' onClick={() => this.toggleChangeColor('color')} />
                          )}
                        </div>
                      </>
                    ):(null)}
                  </>
                ):(null)}
              {/*  <div>
                  <Label> Border Width </Label>
                  <Input
                    type="number"
                    name="borderWidth"
                    id="borderWidth"
                    value={editorValues.borderWidth}
                    onChange={(e) => this.handleChange(e, activeElement)}
                  />
                </div>
                <Label> Border color </Label>
                {(changeColor === 'borderColor') ? (
                  <ChromePicker color={ editorValues.borderColor || '#000' } onChange={ (color) => this.handleChangeColor(color, 'borderColor', activeElement) }/>
                ):(
                  <div style={{ background: editorValues.borderColor || '#000' }} className='draggable-edit-color-button' onClick={() => this.toggleChangeColor('borderColor')} />
                )}
                <div className="draggable-edit-border-bottom">
                  <Label> Border Radius </Label>
                  <Input
                    type="number"
                    name="borderRadius"
                    id="borderRadius"
                    value={editorValues.borderRadius}
                    onChange={(e) => this.handleChange(e, activeElement)}
                  />
                </div> */}
              {/*  <Label> Margins </Label>
                <Row className="draggable-edit-border-bottom">
                  <Col>
                    <Label> Left </Label>
                    <Input
                      type="number"
                      name="marginLeft"
                      id="marginLeft"
                      value={editorValues.marginLeft}
                      onChange={(e) => this.handleChange(e, activeElement)}
                    />
                  </Col>
                  <Col>
                    <Label> Right </Label>
                    <Input
                      type="number"
                      name="marginRight"
                      id="marginRight"
                      value={editorValues.marginRight}
                      onChange={(e) => this.handleChange(e, activeElement)}
                    />
                  </Col>
                  <Col>
                    <Label> Top </Label>
                    <Input
                      type="number"
                      name="marginTop"
                      id="marginTop"
                      value={editorValues.marginTop}
                      onChange={(e) => this.handleChange(e, activeElement)}
                    />
                  </Col>
                  <Col>
                    <Label> Bottom </Label>
                    <Input
                      type="number"
                      name="marginBottom"
                      id="marginBottom"
                      value={editorValues.marginBottom}
                      onChange={(e) => this.handleChange(e, activeElement)}
                    />
                  </Col>
                </Row>
                <Label> Paddings </Label>
                <Row className="draggable-edit-border-bottom">
                  <Col>
                    <Label> Left </Label>
                    <Input
                      type="number"
                      name="paddingLeft"
                      id="paddingLeft"
                      value={editorValues.paddingLeft}
                      onChange={(e) => this.handleChange(e, activeElement)}
                    />
                  </Col>
                  <Col>
                    <Label> Right </Label>
                    <Input
                      type="number"
                      name="paddingRight"
                      id="paddingRight"
                      value={editorValues.paddingRight}
                      onChange={(e) => this.handleChange(e, activeElement)}
                    />
                  </Col>
                  <Col>
                    <Label> Top </Label>
                    <Input
                      type="number"
                      name="paddingTop"
                      id="paddingTop"
                      value={editorValues.paddingTop}
                      onChange={(e) => this.handleChange(e, activeElement)}
                    />
                  </Col>
                  <Col>
                    <Label> Bottom </Label>
                    <Input
                      type="number"
                      name="paddingBottom"
                      id="paddingBottom"
                      value={editorValues.paddingBottom}
                      onChange={(e) => this.handleChange(e, activeElement)}
                    />
                  </Col>
                </Row> */}
              </>
            ):null}
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  cardCategories: state.admin.cardCategories || {},
});

const mapDispatchToProps = {
  saveTemplate: saveCardTemplate,
  fetchGreetingCardsCategories: getGreetingCardsCategories,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Draging));
