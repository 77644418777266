import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Button, Input} from 'reactstrap';

import { connect } from 'react-redux';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';

import CustomerPayments from './CustomerPayments';

class CustomerModal extends Component {
  static propTypes = {
    customerModalId: PropTypes.string,
    customerModalData: PropTypes.shape(),
    fetchAppCustomerData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    customerModalId: null,
    customerModalData: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      startDate: new Date(),
      endDate: new Date(),
      timeFilter: 'total',
      sortColumn: 'paymentDateSeconds',
      sortType: 'descending',
    };
  }

  componentDidMount() {

  }

  changeStartDate = (date) => {
    this.setState({ startDate: date });
  }

  changeEndDate = (date) => {
    this.setState({ endDate: date });
  }

  handleChangeFilter = (e) => {
    const { fetchAppCustomerData, customerModalId } = this.props;
    const { startDate, endDate } = this.state;

    this.setState({ timeFilter: e.target.value });
    if (e.target.value === 'total') {
      fetchAppCustomerData(customerModalId, e.target.value, startDate, endDate);
    } else {
      fetchAppCustomerData(customerModalId, null, null, null);
    }
  };

  sort = (sortColumn, sortType) => {
    console.log('sort', sortColumn, sortType);
    this.setState({ sortType, sortColumn });
  }

  render() {
    const { customerModalData } = this.props;
    const {
      timeFilter,
      startDate,
      endDate,
      sortType,
      sortColumn,
     } = this.state;

     if (!customerModalData) {
       console.log('no customerModalData');
       return null;
     }

     console.log('customerModalData', customerModalData);

    return (
      <div>
      <Row>
        <Col sm="12">
          <div className="body-title">
            Payments
          </div>
        </Col>
      </Row>
      <Row className="campaign-details-section">
        <Col sm="12">
          <Row className="live-metrics-filters">
            <Col sm="5">
              <Input name="timeFilter" type="select" className="form-control" value={timeFilter} onChange={(e) => this.handleChangeFilter(e)}>
                <option value="total">Total</option>
                <option value="2weeks">2 Weeks</option>
                <option value="custom">Custom</option>
              </Input>

            </Col>
            <Col sm="2">
              <Button onClick={this.filter}> Filter </Button>
            </Col>
            {customerModalData ? (
              <Col sm="5">
                <div> {`# of companies purchased from - ${customerModalData.companiesPurchasedCount || 0}`} </div>
                <div> {`# of campaigns purchased from - ${customerModalData.campaignsPurchasedCount || 0}`} </div>
              </Col>
            ):(null)}
          </Row>
          {(timeFilter && timeFilter === 'custom') ? (
            <Row>
              <Col sm="6">
                <DateTimePicker
                  className="date-picker"
                  time={false}
                  culture="en"
                  format="MMM DD YYYY"
                  onChange={this.changeStartDate}
                  value={startDate}
                  defaultValue={new Date()}
                />
              </Col>
              <Col sm="6">
                <DateTimePicker
                  className="date-picker"
                  time={false}
                  culture="en"
                  format="MMM DD YYYY"
                  onChange={this.changeEndDate}
                  value={endDate}
                  defaultValue={new Date()}
                />
              </Col>
            </Row>
          ):null}
          <div class="table-responsive">
            <CustomerPayments
              sortType={sortType}
              sortColumn={sortColumn}
              payments={(customerModalData) ? customerModalData.stripeData : []}
              sort={this.sort}
            />
          </div>
        </Col>
      </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerModal));
