
import { auth, firebase, firestore, functions, storage } from '../../lib/firebase';
import {
  RESOURCE_CENTER_DATA,
  RESOURCE_CENTER_UPLOAD_VIDEO,
  RESOURCE_CENTER_VIDEOS,
  RESOURCE_CENTER_DELETE_VIDEO,
  RESOURCE_CENTER_ADD_VIDEO,
  RESOURCE_CENTER_EDIT_VIDEO,
//  RESOURCE_CENTER_CONTRACT,
//  RESOURCE_CENTER_CONTRACT_UPDATE,
//  RESOURCE_CENTER_FAQ,
//  RESOURCE_CENTER_FAQ_UPDATE,
  RESOURCE_CENTRE_TEXT,
  RESOURCE_CENTRE_TOP_LINKS,
} from '../index';


export const resetRCHighlight = () => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - resetRCHighlight');

  const user = auth.currentUser;

  console.log(user);

  if (user && user.uid) {
    const userRef = firestore.collection('users').doc(user.uid);

    await userRef.update({
      rcHighlight: true,
    });

    return resolve();
  }
});

export const getTutorialVideos = () => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('getTutorialVideos');
  const tutorialRef = firestore.collection('tutorialVideos');

  const snapshot = await tutorialRef.get();

  if (snapshot.docs) {
    let tutorialVideos = [];

    snapshot.docs.map((doc, index) => {
      if (doc.id !== 'count') {
        tutorialVideos.push({
        //  id: index,
          id: doc.id,
          ...doc.data(),
        })
      }
    });

    tutorialVideos.sort((a, b) => a.orderId - b.orderId);

  /*  let tutorialVideos2 = [];

    tutorialVideos.map((video, index) => {
      tutorialVideos2.push({
      //  id: index,
        ...video,
      })
    }) */

    console.log('tutorialVideos', { tutorialVideos });

    return resolve(dispatch({
      type: RESOURCE_CENTER_VIDEOS,
      payload: tutorialVideos,
    }));
  }


});

export const uploadTutorialVideo = (video, title) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - uploadTutorialVideo', { video, title });

  if (!video) return reject('no video');
  if (!title) return reject('no title');
//  if (!description) return reject('no description');

  const user = auth.currentUser;

  console.log(user);

  if (user) {

    const tutorialRef = firestore.collection('tutorialVideos');
    const tutorialCountRef = firestore.collection('tutorialVideos').doc('count');

  //  await tutorialCountRef.update({ count: firebase.firestore.FieldValue.increment(1) })

    let newDocId;

    let snapshot = await tutorialCountRef.get();
    let count = 0;
    if (snapshot.exists) {
      count = snapshot.data().count || 0;
    }

    console.log('count', { count });

    await tutorialRef.add({
      video,
      title,
    //  description,
      orderId: parseInt(count, 10),
    }).then((docRef) => {
      newDocId = docRef.id;
    });

    if (newDocId) {
      await tutorialCountRef.update({ count: firebase.firestore.FieldValue.increment(1) })

      return resolve(dispatch({
        type: RESOURCE_CENTER_ADD_VIDEO,
        payload: {
          id: newDocId,
          video,
          title,
        //  description,
          orderId: parseInt(count, 10),
        //  id: parseInt(count, 10),
        },
      }));
    }

  //  return resolve();
  }
});


export const editTutorialVideo = (id, video, title) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('action - editTutorialVideo', { id, video, title });

  if (!id) return reject('no id');
  if (!video) return reject('no video');
  if (!title) return reject('no title');
//  if (!description) return reject('no description');

  const user = auth.currentUser;

  if (user) {
    const tutorialRef = firestore.collection('tutorialVideos').doc(id);

    let updateSuccess = true;

    await tutorialRef.update({
      video,
      title,
    //  description,
    }).catch(err => {
      console.log(err);
      updateSuccess = false;
    });

    if (updateSuccess) {
      return resolve(dispatch({
        type: RESOURCE_CENTER_EDIT_VIDEO,
        payload: {
          id,
          video,
          title,
        //  description,
        },
      }));
    }
  }
});

export const changeOrderIdTutorialVideo = (videos) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!videos) return reject('no video');

  const user = auth.currentUser;

  if (user) {
    let promises = [];

    await videos.map((video, index) => {
      if ((index || index === 0) && video.id) {
        promises.push(new Promise(async (resolve2) => {
          const tutorialRef = firestore.collection('tutorialVideos').doc(video.id);

          await tutorialRef.update({
            orderId: index,
          }).catch(err => {
            console.log(err);
          });

          return resolve2();
        }));
      }
    });

    Promise.all(promises).then(() => {
      return resolve('success');
    }).catch(err => {
      console.log(err);
      return resolve();
    })
  }
});

export const deleteTutorialVideo = (id) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!id) return reject('no id');

  const tutorialRef = firestore.collection('tutorialVideos').doc(id);

  let updateSuccess = true;

  await tutorialRef.delete().catch(err => {
    console.log(err);
    updateSuccess = false;
  });

  if (updateSuccess) {
    return resolve(dispatch({
      type: RESOURCE_CENTER_DELETE_VIDEO,
      payload: id,
    }));
  }
});

export const updateResourceCentre = (value) => (dispatch) => new Promise(async (resolve, reject) => {
  const faqRef = firestore.collection('resourceCenter').doc('resourceCentreText');

  await faqRef.set({ text: value }, { merge: true });

  return resolve(dispatch({
    type: RESOURCE_CENTRE_TEXT,
    payload: value,
  }))
});

export const getResourceCentre = () => (dispatch) => new Promise(async (resolve, reject) => {
  const ref = firestore.collection('resourceCenter').doc('resourceCentreText');

  const snapshot = await ref.get();

  if (snapshot.exists) {
    return resolve(dispatch({
      type: RESOURCE_CENTRE_TEXT,
      payload: snapshot.data().text || null,
    }))
  }
});


export const updateResourceCentreTopLinks = (value) => (dispatch) => new Promise(async (resolve, reject) => {
  const faqRef = firestore.collection('resourceCenter').doc('resourceCentreTopLinks');

  await faqRef.set({ text: value }, { merge: true });

  return resolve(dispatch({
    type: RESOURCE_CENTRE_TOP_LINKS,
    payload: value,
  }))
});

export const getResourceCentreTopLinks = () => (dispatch) => new Promise(async (resolve, reject) => {
  const ref = firestore.collection('resourceCenter').doc('resourceCentreTopLinks');

  const snapshot = await ref.get();

  if (snapshot.exists) {
    return resolve(dispatch({
      type: RESOURCE_CENTRE_TOP_LINKS,
      payload: snapshot.data().text || null,
    }))
  }
});



export const updateFAQ = (value) => (dispatch) => new Promise(async (resolve, reject) => {
  const faqRef = firestore.collection('resourceCenter').doc('resourceCenter');

  await faqRef.set({ faq: value }, { merge: true });

  return resolve(dispatch({
    type: RESOURCE_CENTER_DATA,
    payload: { faq: value },
  }))
});

export const getResourceCenter = () => (dispatch) => new Promise(async (resolve, reject) => {
  const ref = firestore.collection('resourceCenter').doc('resourceCenter');

  const snapshot = await ref.get();

  if (snapshot.exists) {
    let contract = snapshot.data() ? snapshot.data().contract : null;
    let faq = snapshot.data() ? snapshot.data().faq : null;

    return resolve(dispatch({
      type: RESOURCE_CENTER_DATA,
      payload: { contract, faq },
    }))
  }
});


export const updateCompanyContract = (value) => (dispatch) => new Promise(async (resolve, reject) => {
  const companyContractRef = firestore.collection('resourceCenter').doc('resourceCenter');

  await companyContractRef.set({ contract: value }, { merge: true });

  return resolve(dispatch({
    type: RESOURCE_CENTER_DATA,
    payload: { contract: value },
  }))
});
