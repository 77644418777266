import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Card, CardBody, Input, Label, TabPane, TabContent } from 'reactstrap';

import { connect } from 'react-redux';

import moment from 'moment';
import Moment from 'react-moment';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';

import momentLocalizer from 'react-widgets-moment';

import {
  getAdminDashboard,
  getAdminDashboardCustom,
  addAdminAnalyticsTemplate,
  editAdminAnalyticsTemplate,
  getAdminAnalyticsTempates,
  removeAdminAnalyticsTemplate,
} from '../../../../actions/admin';

// import Notifications from '../Dashboard/Notifications';
import Chart from './Chart';
import DataFields from './DataFields';
import DataChart from './DataChart';

import AutoRefresh from '../../../components/AutoRefresh/AutoRefresh';
import LiveMetrics from './LiveMetrics';

import Toggle from '../../../components/Toggle/Toggle';

import notifications from '../../../../const/notifications';

moment.locale('en-GB');
momentLocalizer();

class Dashboard extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    fetchCompanyVouchers: PropTypes.func.isRequired,
    fetchAdminDashboardCustom: PropTypes.func.isRequired,
    fetchAdminAnalyticsTempates: PropTypes.func.isRequired,
    addAnalyticsTemplate: PropTypes.func.isRequired,
    editAnalyticsTemplate: PropTypes.func.isRequired,
    removeAnalyticsTemplate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    vouchers: {},
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);

    const d = new Date();

    this.state = {
      error: null,
      loading: false,
      showNoPermission: false,
      dontShowPage: false,
      timeFilter: 'month',
      chartFilter: 'sales',
      startDate: new Date(d.setDate(d.getDate() - 30)),
      endDate: new Date(),
      interval: 1,
      activeTab: "1",
      tabCount: 1,
    };
  }

  componentDidMount() {
    const { fetchAdminDashboard, user, history, fetchAdminAnalyticsTempates } = this.props;

    if (user && Object.keys(user).length > 0) {
      if (user.roleClass === 'admin') {
        fetchAdminAnalyticsTempates();

        if (user.role === 'admin') {
          fetchAdminDashboard('month', null, null);
          this.setState({ showNoPermission: false, dontShowPage: false });
        } else {
          const { roles } = this.props;

          if (roles && roles[user.role] && roles[user.role].permissions && roles[user.role].permissions.dashboard) {
            fetchAdminDashboard('month', null, null);
            if (roles[user.role].permissions.dashboard === 2) {
              this.setState({ showNoPermission: false, dontShowPage: false });

            } else {
              this.setState({ showNoPermission: true, dontShowPage: false });
            }

          //  this.setState({ showNoPermission: false });
          } else {
            fetchAdminDashboard('month', null, null);

            this.setState({ showNoPermission: true, dontShowPage: true });
          }
        }

      } else {
        history.push('/');
      }
    } else {
      history.push('/');
    }
  }



  handleChangeFilter = (e, type) => {
    console.log('handleChangeFilter', type, e.target.value);
    if (type === 'chartFilter') {
      this.setState({ chartFilter: e.target.value });
    } else if (type === 'interval') {
      this.setState({ interval: e.target.value });
    } else if (type === 'timeFilter') {
      const { fetchAdminDashboard, fetchAdminDashboardCustom, adminDashboard, adminDashboardStripe } = this.props;
      const { startDate, endDate } = this.state;

      if (e.target.value === 'custom') {
        if (!startDate || !endDate) {
          const d = new Date();
          const sd = new Date(d.setDate(d.getDate() - 1));
          const ed = new Date();

          fetchAdminDashboard(e.target.value, sd, ed);

          this.setState({ timeFilter: e.target.value, startDate: sd, endDate: ed });
        } else {
          // fetchAdminDashboardCustom(e.target.value, startDate, endDate);
          fetchAdminDashboard(e.target.value, startDate, endDate);

          this.setState({ timeFilter: e.target.value });
        }
      } else {
        if (e.target.value === 'allTime') {
          const { user } = this.props;
          const sd = new Date(2021, 6, 1, 0, 0, 0, 0);
          const ed = new Date();

          console.log('handleChangeFilter allTime', sd);

          let allTimeStartDate = new Date('2022-08-01T00:00:00');

          console.log('handleChangeFilter allTimeStartDate', allTimeStartDate);

          if (user.dateCreated) {
            allTimeStartDate = new Date(user.dateCreated);
          }

          fetchAdminDashboard( e.target.value, sd, null);

          this.setState({ timeFilter: e.target.value, startDate: sd, endDate: ed });
        } else {
          let dateLength = 1;

          if (e.target.value === 'month') {
            dateLength = 30;
          } else if (e.target.value === 'day') {
            dateLength = 1;
          } else if (e.target.value === 'week') {
            dateLength = 7;
          } else if (e.target.value === 'year') {
            dateLength = 365;
          }

          const d = new Date();
          const sd = new Date(d.setDate(d.getDate() - dateLength));
          const ed = new Date();

          fetchAdminDashboard(e.target.value, null, null);

          this.setState({ timeFilter: e.target.value, startDate: sd, endDate: ed });
        }
      }
    }
  };


  handleChangeFilter2 = (e, type) => {
    const { interval } = this.state;
    const val = e.target.value;

    this.setState({ [type]: e.target.value }, () => {
    /*  if (type === 'timeFilter') {
        if (val === 'month' || val === 'year') {
          if (interval <= 2) {
            this.setState({ interval: 7 });
          } else if (val === '6months' && interval <= 7) {
            this.setState({ interval: 14 });
          }
        } else if (val === 'day' || val === 'week') {
          if (interval >= 15) {
            this.setState({ interval: 14 });
          }
        }
      } */

      if (type !== 'interval') {
        this.filter();
      }
    });
  };

  filter = () => {
    const { user, fetchVoucherSales, adminViewAsCompany } = this.props;
    const { campaignFilter, timeFilter, startDate, endDate } = this.state;

    console.log('filter', user);

    if (campaignFilter === 'all') {
      if (user.company) {
        if (timeFilter === 'custom') {
          fetchVoucherSales(user.company, null, timeFilter, startDate, endDate);
        } else if (timeFilter === 'allTime') {
          let allTimeStartDate = new Date('2022-08-01T00:00:00');

          console.log('allTimeStartDate', allTimeStartDate);

          if (user.dateCreated) {
            allTimeStartDate = new Date(user.dateCreated);
          }

          console.log('filter allTimeStartDate', allTimeStartDate);

          fetchVoucherSales(user.company, null, timeFilter, allTimeStartDate, null);
        } else {
          fetchVoucherSales(user.company, null, timeFilter, null, null);
        }
      } else if (adminViewAsCompany) {
        if (timeFilter === 'custom') {
          fetchVoucherSales(adminViewAsCompany, null, timeFilter, startDate, endDate);
        } else if (timeFilter === 'allTime') {
          let allTimeStartDate = new Date('2022-08-01T00:00:00');

          console.log('filter allTimeStartDate', allTimeStartDate);

          fetchVoucherSales(user.company, null, timeFilter, allTimeStartDate, null);
        } else {
          fetchVoucherSales(adminViewAsCompany, null, timeFilter, null, null);
        }
      }
    } else {
      if (timeFilter === 'custom') {
        fetchVoucherSales(null, campaignFilter, timeFilter, startDate, endDate);
      } else {
        fetchVoucherSales(null, campaignFilter, timeFilter, null, null);
      }
    }
  }

  changeStartDate = (date) => {
    const { fetchAdminDashboard, fetchAdminDashboardCustom } = this.props;
    const { timeFilter, endDate } = this.state;

    fetchAdminDashboard(timeFilter, date, endDate);
  //  fetchAdminDashboardCustom(date, endDate);

    this.setState({ startDate: date });
  }

  changeEndDate = (date) => {
    const { fetchAdminDashboard, fetchAdminDashboardCustom } = this.props;
    const { timeFilter, startDate } = this.state;

    fetchAdminDashboard(timeFilter, startDate, date);
  //  fetchAdminDashboardCustom(startDate, date);

    this.setState({ endDate: date });
  }

  refresh = () => {
    console.log('refresh');

    const { fetchAdminDashboard } = this.props;
    const { timeFilter, startDate, endDate } = this.state;

    if (timeFilter) {
      if (timeFilter === 'custom') {
        if (!startDate || !endDate) {
          const d = new Date();
          const sd = new Date(d.setDate(d.getDate() - 1));
          const ed = new Date();

          fetchAdminDashboard(timeFilter, sd, ed);
        } else {
          fetchAdminDashboard(timeFilter, startDate, endDate);
        }
      } else {
        if (timeFilter === 'all') {
        //  const sd = new Date(2021, 6, 1, 0, 0, 0, 0);
        //  const ed = new Date();

          fetchAdminDashboard(timeFilter, null, null);
        } else {
        /*  let dateLength = 1;

          if (timeFilter === 'month') {
            dateLength = 30;
          } else if (timeFilter === 'day') {
            dateLength = 1;
          } else if (timeFilter === 'week') {
            dateLength = 7;
          } else if (timeFilter === 'year') {
            dateLength = 365;
          }

          const d = new Date();
          const sd = new Date(d.setDate(d.getDate() - dateLength));
          const ed = new Date(); */

          fetchAdminDashboard(timeFilter, null, null);
        }
      }
    }
  }

  toggle = () => {
    const { activeTab, tabCount } = this.state;

    if (activeTab === '2') {
      this.setState({ activeTab: '1' });
    } else {
      this.setState({ activeTab: '2', tabCount: tabCount + 1 });
      this.refresh();
    }
  }

  render() {
    const {
      adminDashboard,
      adminDashboardAgregated,
      adminStatistics,
      adminDashboardLastUpdate,
      adminDashboardTotals,
      adminDashboardStripe,
      adminDashboardStripeAgregated,
      adminAnalyticsTemplate,
      addAnalyticsTemplate,
      editAnalyticsTemplate,
      removeAnalyticsTemplate,
      adminDashboardCustom,
      adminDashboardStripeCustom,
    } = this.props;
    const {
      timeFilter, startDate, endDate, chartFilter, interval, activeTab, tabCount,
    } = this.state;

    return (
      <div>
      {/*  <Row>
          <Col xs="8" />
          <Col xs="2" style={{ position: 'relative' }}>
            <AutoRefresh refreshFunction={this.refresh} refreshTime={60} />
          </Col>
          <Col xs="2" style={{ position: 'relative' }}>
            <div style={{ position: 'relative', top: -25 }}>
              <span style={{ marginRight: 3 }}>Table view</span>
              <Toggle checked={activeTab === '2'} onChange={this.toggle} />
            </div>
          </Col>
        </Row> */}
        <Card>
          <CardBody>
        <Row>
            {/*  <Col sm="4">
                <div className="admin-dashboard-notifications">
                  <Label> Notifications </Label>
                  <Notifications
                    newCompaniesCount={adminStatistics.newCompaniesCount}
                    pendingVouchersCount={adminStatistics.pendingVouchersCount}
                  />
                </div>
              </Col> */}
          <Col sm="4">
            <Input name="timeFilter" type="select" className="form-control" value={timeFilter} onChange={(e) => this.handleChangeFilter(e, 'timeFilter')}>
              <option value="day">1 Day</option>
              <option value="week">1 Week</option>
              <option value="month">1 Month</option>
              <option value="year">1 Year</option>
              <option value="allTime">All time</option>
              <option value="custom">Custom</option>
            </Input>
            {(timeFilter && timeFilter === 'custom') ? (
              <Row>
                <Col sm="6">
                  <DateTimePicker
                    className="date-picker"
                    time={false}
                    culture="en"
                    format="MMM DD YYYY"
                    onChange={this.changeStartDate}
                    value={startDate}
                    defaultValue={new Date()}
                  />
                </Col>
                <Col sm="6">
                  <DateTimePicker
                    className="date-picker"
                    time={false}
                    culture="en"
                    format="MMM DD YYYY"
                    onChange={this.changeEndDate}
                    value={endDate}
                    defaultValue={new Date()}
                  />
                </Col>
              </Row>
            ) : null}
          </Col>
          <Col sm="4">
            <Input name="interval" type="select" className="form-control" value={interval} onChange={(e) => this.handleChangeFilter(e, 'interval')}>
              <option value={1}>Daily</option>
              <option value={7}>Weekly</option>
              <option value={30}>Monthly</option>
              <option value={365}>Yearly</option>
            </Input>
          </Col>
          <Col xs="2" style={{ position: 'relative' }}>
          </Col>
          <Col xs="2" style={{ position: 'relative' }}>
            <div style={{ position: 'relative', float: 'right' }}>
              <span style={{ marginRight: 3 }}>Table view</span>
              <Toggle checked={activeTab === '2'} onChange={this.toggle} />
            </div>
          </Col>

            {/*  <Col sm="2">
                <Label for="chartFilter"> Chart Metric </Label>
                <Input name="chartFilter" type="select" className="form-control" value={chartFilter} onChange={(e) => this.handleChangeFilter(e, 'chartFilter')}>
                  <option value="redemptions">Redemptions</option>
                  <option value="gifts">Gifts</option>
                  <option value="sales">Sales</option>
                </Input>
              </Col> */}
            </Row>
          </CardBody>
        </Card>

        <div>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <LiveMetrics interval={interval} />
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <DataFields
                    timeFilter={timeFilter}
                    adminDashboard={adminDashboard}
                    adminDashboardAgregated={adminDashboardAgregated}
                    adminDashboardCustom={adminDashboardCustom}
                    adminDashboardLastUpdate={adminDashboardLastUpdate}
                    adminDashboardTotals={adminDashboardTotals}
                    adminDashboardStripe={adminDashboardStripe}
                    adminDashboardStripeAgregated={adminDashboardStripeAgregated}
                    adminDashboardStripeCustom={adminDashboardStripeCustom}
                    adminAnalyticsTemplate={adminAnalyticsTemplate}
                    addAnalyticsTemplate={addAnalyticsTemplate}
                    editAnalyticsTemplate={editAnalyticsTemplate}
                    removeAnalyticsTemplate={removeAnalyticsTemplate}
                  />
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId="2">
              {activeTab === '2' && (
                <DataChart
                  activeTab={activeTab}
                  tabCount={tabCount}
                interval={interval}
                timeFilter={timeFilter}
                adminDashboard={adminDashboard}
                adminDashboardAgregated={adminDashboardAgregated}
                adminDashboardCustom={adminDashboardCustom}
                adminDashboardLastUpdate={adminDashboardLastUpdate}
                adminDashboardTotals={adminDashboardTotals}
                adminDashboardStripe={adminDashboardStripe}
                adminDashboardStripeAgregated={adminDashboardStripeAgregated}
                adminDashboardStripeCustom={adminDashboardStripeCustom}
                adminAnalyticsTemplate={adminAnalyticsTemplate}
                addAnalyticsTemplate={addAnalyticsTemplate}
                editAnalyticsTemplate={editAnalyticsTemplate}
                removeAnalyticsTemplate={removeAnalyticsTemplate}
                />
              )}
            </TabPane>

          </TabContent>
        </div>
        <div>
          <div style={{ textDecoration: 'none', display: 'inline-block', marginLeft: 15, fontSize: 12, color: '#6d6d6d', textAlign: 'right' }}>
            {notifications.admin.dashboard.infoText || 'Values, which update each minute, are only indicative and may change.'}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  roles: state.users.roles || {},
  adminDashboard: state.admin.adminDashboard || {},
  adminDashboardAgregated: state.admin.adminDashboardAgregated || {},
  adminDashboardCustom: state.admin.adminDashboardCustom || {},
  adminStatistics: state.admin.adminStatistics || {},
  adminDashboardLastUpdate: state.admin.adminDashboardLastUpdate || null,
  adminDashboardTotals: state.admin.adminDashboardTotals || {},
  adminDashboardStripe: state.admin.adminDashboardStripe || {},
  adminDashboardStripeAgregated: state.admin.adminDashboardStripeAgregated || {},
  adminDashboardStripeCustom: state.admin.adminDashboardStripeCustom || {},
  adminAnalyticsTemplate: state.admin.adminAnalyticsTemplate || {},
});

const mapDispatchToProps = {
  fetchAdminDashboard: getAdminDashboard,
  fetchAdminDashboardCustom: getAdminDashboardCustom,
  fetchAdminAnalyticsTempates: getAdminAnalyticsTempates,
  addAnalyticsTemplate: addAdminAnalyticsTemplate,
  editAnalyticsTemplate: editAdminAnalyticsTemplate,
  removeAnalyticsTemplate: removeAdminAnalyticsTemplate,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
