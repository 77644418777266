import {
  GET_COMPANY_USERS,
  GET_COMPANY_DATA,
  GET_CATEGORIES,
  GET_REWARDS_UNLOCKED,
  GET_COMPANY_CHANGES,
  GET_COMPANY_OWNER_DATA,
  COMPANY_NOTIFICATIONS,
  UPDATE_API_KEY,
  UPDATE_SHOPIFY_API,
  UPDATE_WOOCOMMERCE_API,
  UPDATE_ACTIVE_API,
  RESET_COMPANY_DATA,
  COMPLIANCE_DATA,
  SORT_COMPANY_PAYMENTS,
  GET_COMPANY_PAYMENTS,
  GET_MORE_COMPANY_PAYMENTS,
  GET_COMPANY_PAYMENTS_TOTAL,
  GET_SINGLE_COMPANY_PAYMENT,
  GET_COMPANY_LINKED_COMPANIES,
  UPDATE_COMPANY_LINKED_COMPANIES,
} from '../../actions';

const defaultState = {
    company: {},
    categories: {},
};

const userReducer = (state=defaultState, action) => {
    switch(action.type) {
        case GET_COMPANY_DATA: {
          return {
            ...state,
            company: action.payload,
          };
        }

        case GET_COMPANY_OWNER_DATA: {
          return {
            ...state,
            companyOwnerData: action.payload,
          };
        }

        case GET_COMPANY_PAYMENTS: {
          const { typeFilter, payload, lastKey, orderBy, sortType } = action;

          let arr = [];
        let totals = {};

        console.log('GET_COMPANY_PAYMENTS', payload);

          Object.keys(payload).map((key, index) => {
            arr.push({
              ...payload[key],
              dbId: key,
              chargeId: (payload[key] && payload[key].chargeId) ? payload[key].chargeId : null,
              companyName: (payload[key] && payload[key].companyName) ? payload[key].companyName : payload[key].destination,
              companyId: (payload[key] && payload[key].companyId) ? payload[key].companyId : null,
              customerEmail: (payload[key] && payload[key].customerEmail) ? payload[key].customerEmail : payload[key].customer,
              customerId: (payload[key] && payload[key].customerId) ? payload[key].customerId : null,
              customerName: (payload[key] && payload[key].customerName) ? payload[key].customerName : null,
              voucherId: (payload[key] && payload[key].voucherId) ? payload[key].voucherId : null,
              voucherName: (payload[key] && payload[key].voucherName) ? payload[key].voucherName : null,
              discount: (payload[key] && payload[key].discount) ? payload[key].discount : null,
              discountStep: (payload[key] && payload[key].discountStep) ? payload[key].discountStep : null,
              faceValue: (payload[key] && payload[key].faceValue) ? payload[key].faceValue : null,
              gift: (payload[key] && payload[key].gift) ? payload[key].gift : null,
              giftingData: (payload[key] && payload[key].giftingData) ? payload[key].giftingData : null,
              redeemed: (payload[key] && payload[key].redeemed && payload[key].redeemed === 'true') ? payload[key].redeemed : null,
              refunded: (payload[key] && payload[key].refunded) ? payload[key].refunded : false,
              refundValue: (payload[key] && payload[key].refundValue) ? payload[key].refundValue : null,
              refundDate: (payload[key] && payload[key].refundDate) ? payload[key].refundDate : null,
            });

            totals = {
              faceValue: (totals.faceValue || 0) + ((payload[key] && payload[key].faceValue) ? parseInt(payload[key].faceValue, 10) : 0),
              amount_captured: (totals.amount_captured || 0) + (payload[key].amount_captured || 0),
              gift: (payload[key] && payload[key].gift) ? (totals.gift || 0) + 1 : totals.gift || 0,
              redeemed: (payload[key] && payload[key].redeemed && payload[key].redeemed === 'true') ? (totals.redeemed || 0) + 1 : totals.redeemed || 0,
              application_fee_amount: (totals.application_fee_amount || 0) + (payload[key].application_fee_amount || 0),
            }
          });

          if (sortType === 'asc') {
            arr.sort((a, b) => {
              if (b[orderBy] > a[orderBy]) {
                return 1;
              } else {
                return -1;
              }
            });
          } else {
            arr.sort((a, b) => {
              if (a[orderBy] > b[orderBy]) {
                return 1;
              } else {
                return -1;
              }
            });
          }

          return { ...state, companyPayments: arr.reverse(), lastPaymentId: lastKey };
        }

        case GET_MORE_COMPANY_PAYMENTS: {
          const { payload, lastKey, orderBy, sortType } = action;
          const { lastPaymentId, companyPayments } = state;

          let arr = [];

          if (lastKey === lastPaymentId) {
            return { ...state, lastPaymentId: null };
          }

          Object.keys(payload).map((key, index) => {
            arr.push({
              ...payload[key],
              dbId: key,
              chargeId: (payload[key] && payload[key].chargeId) ? payload[key].chargeId : null,
              companyName: (payload[key] && payload[key].companyName) ? payload[key].companyName : payload[key].destination,
              companyId: (payload[key] && payload[key].companyId) ? payload[key].companyId : null,
              customerEmail: (payload[key] && payload[key].customerEmail) ? payload[key].customerEmail : payload[key].customer,
              customerId: (payload[key] && payload[key].customerId) ? payload[key].customerId : null,
              customerName: (payload[key] && payload[key].customerName) ? payload[key].customerName : null,
              voucherId: (payload[key] && payload[key].voucherId) ? payload[key].voucherId : null,
              voucherName: (payload[key] && payload[key].voucherName) ? payload[key].voucherName : null,
              discount: (payload[key] && payload[key].discount) ? payload[key].discount : null,
              discountStep: (payload[key] && payload[key].discountStep) ? payload[key].discountStep : null,
              faceValue: (payload[key] && payload[key].faceValue) ? payload[key].faceValue : null,
              gift: (payload[key] && payload[key].gift) ? payload[key].gift : null,
              redeemed: (payload[key] && payload[key].redeemed && payload[key].redeemed === 'true') ? payload[key].redeemed : null,
              giftingData: (payload[key] && payload[key].giftingData) ? payload[key].giftingData : null,
              refunded: (payload[key] && payload[key].refunded) ? payload[key].refunded : false,
              refundValue: (payload[key] && payload[key].refundValue) ? payload[key].refundValue : null,
              refundDate: (payload[key] && payload[key].refundDate) ? payload[key].refundDate : null,
            });
          });

          if (sortType === 'asc') {
            arr.sort((a, b) => {
              if (b[orderBy] > a[orderBy]) {
                return 1;
              } else {
                return -1;
              }
            });
          } else {
            arr.sort((a, b) => {
              if (a[orderBy] > b[orderBy]) {
                return 1;
              } else {
                return -1;
              }
            });
          }

          let newPayments = [...companyPayments, ...arr.reverse()];

          return { ...state, companyPayments: newPayments, lastPaymentId: lastKey };
        }



        case GET_SINGLE_COMPANY_PAYMENT: {
          const { payload, dbId } = action;
          const { companyPayments } = state;

          if (!payload) {
            return null;
        }

        console.log('GET_SINGLE_COMPANY_PAYMENT', payload);

          const index = companyPayments.findIndex(i => i.dbId === dbId);

          const newCompanyPayments = [ ...companyPayments ];

          newCompanyPayments[index] = {
            ...companyPayments[index],
            ...payload,
            dbId: dbId,
            chargeId: (payload.chargeId) ? payload.chargeId : null,
            companyName: (payload.companyName) ? payload.companyName : payload.destination,
            companyId: (payload.companyId) ? payload.companyId : null,
            customerEmail: (payload.customerEmail) ? payload.customerEmail : payload.customer,
            customerId: (payload.customerId) ? payload.customerId : null,
            customerName: (payload.customerName) ? payload.customerName : null,
            voucherId: (payload.voucherId) ? payload.voucherId : null,
            voucherName: (payload.voucherName) ? payload.voucherName : null,
            discount: (payload.discount) ? payload.discount : null,
            discountStep: (payload.discountStep) ? payload.discountStep : null,
            faceValue: (payload.faceValue) ? payload.faceValue : null,
            gift: (payload.gift) ? payload.gift : null,
            giftingData: (payload && payload.giftingData) ? payload.giftingData : null,
            redeemed: (payload.redeemed && payload.redeemed === 'true') ? payload.redeemed : null,
            refunded: (payload.refunded) ? payload.refunded : false,
            refundValue: (payload.refundValue) ? payload.refundValue : null,
            refundDate: (payload.refundDate) ? payload.refundDate : null,
          }

          return { ...state, companyPayments: newCompanyPayments };
        }



        case SORT_COMPANY_PAYMENTS: {
          const { sortColumn, sortType } = action;
          const { companyPayments } = state;

            if (sortType && sortColumn) {
              if (sortType === 'desc') {
                companyPayments.sort((a, b) => {
                  if (b[sortColumn] > a[sortColumn]) {
                    return 1;
                  } else {
                    return -1;
                  }
                });
              } else if (sortType === 'asc') {
                companyPayments.sort((a, b) => {
                  if (a[sortColumn] > b[sortColumn]) {
                    return 1;
                  } else {
                    return -1;
                  }
                });
              }
            }

            return { ...state, companyPayments: companyPayments };
        }

        case GET_COMPANY_PAYMENTS_TOTAL: {
          const { payload, totalsPerCampaign } = action;

            let totals = payload;

            return { ...state, companyPaymentsTotals: totals, companyPaymentsTotalsPerCampaign: totalsPerCampaign };

        }

        case COMPANY_NOTIFICATIONS: {
          return {
            ...state,
            companyNotifications: action.payload,
          };
        }
        case UPDATE_API_KEY: {
          return {
            ...state,
            apiKey: action.payload,
          };
        }

        case UPDATE_SHOPIFY_API: {
          return {
            ...state,
            shopifyApiKey: action.payload,
          };
      }

      case UPDATE_WOOCOMMERCE_API: {
        return {
          ...state,
          woocommerceApiKey: action.payload,
        };
      }

      case UPDATE_ACTIVE_API: {
        return {
          ...state,
          activeAPI: action.payload,
        };
      }

      case GET_COMPANY_LINKED_COMPANIES: {
        return {
          ...state,
          linkedCompaniesAPIs: action.payload,
        };
      }

      case UPDATE_COMPANY_LINKED_COMPANIES: {
        if (action.key && state.linkedCompaniesAPIs && state.linkedCompaniesAPIs[action.key]) {
          return {
            ...state,
            linkedCompaniesAPIs: {
              ...state.linkedCompaniesAPIs,
              [action.key]: {
                ...state.linkedCompaniesAPIs[action.key],
                ...action.payload,
              },
            }
          };
        } else {
          return {
            ...state,
            linkedCompaniesAPIs: {
              ...state.linkedCompaniesAPIs,
              [action.key]: {
                ...action.payload,
              },
            }
          };
        }
      }
        

        case RESET_COMPANY_DATA: {
          return {
            ...state,
            company: {},
            companyOwnerData: {},
            companyNotifications: [],
          };
      }

      case COMPLIANCE_DATA: {
        return {
          ...state, 
          complianceData: action.data,
        };
      }

        case GET_CATEGORIES: {
          return {
            ...state,
            categories: action.payload,
          };
        }
        case GET_REWARDS_UNLOCKED: {
          return {
            ...state,
            rewardsUnlocked: action.payload,
          };
        }
        case GET_COMPANY_CHANGES: {
          return {
            ...state,
            companyChanges: action.payload,
          };
        }
        default:
            return state;
    }
};

export default userReducer;
