import React from 'react';
import { NavLink } from 'react-router-dom';
import * as Feather from 'react-feather';
import NavBadge from './NavBadge';

const NavSingleItem = ({ rcHighlight, isActive, item, count, branding, shadeColor, location, isCompanyPerksTab }) => {
  const Icon = item.icon && Feather[item.icon] ? Feather[item.icon] : null;

  const [hoverActive, setHoverActive] = React.useState(false);

  if (item.external) {
    const rel = item.target && item.target === '_blank' ? 'noopener noreferrer' : null;

    return (
      <li
        className="nav-item nav-item-withCounter"
        onMouseEnter={() => setHoverActive(true)}
        onMouseLeave={() => setHoverActive(false)}
      >
        <a href={item.url} target={item.target} rel={rel}>
          {item.icon && Icon && <Icon className="side-nav-icon" />}
          <span className="nav-item-label">{item.name}</span>
          {item.badge && <NavBadge color={item.badge.variant} text={item.badge.text} />}
        </a>
      </li>
    );
  } else {
    // Force relative URLs to start with a slash
    const url = item.url.charAt(0) === '/' ? item.url : `/${item.url}`;

    let isActiveLink = false;
    if (location === item.url) {
      isActiveLink = true;
    }

    let bgColor = '#0b1628';

    if (branding.sidebarPrimaryColor) {
      bgColor = branding.sidebarPrimaryColor;
    }

    if (isCompanyPerksTab && branding.perksBackgroundColor) {
      bgColor = branding.perksBackgroundColor;
    }

    let isActive2 = true;

    //`nav-item nav-item-withCounter ${isActive ? 'box' : ''}`
    return (
      <li className={`nav-item nav-item-withCounter`}>
        <NavLink
          to={url}
          style={{
            color: (isCompanyPerksTab && branding.perksTextColor) ? branding.perksTextColor : branding.sidebarTextColor,
            background: (isActive || isActiveLink || hoverActive) ? shadeColor(bgColor, 20) : bgColor,
          //  border: isActive ? "3px solid #ff6a00" : '0',
          }}
          activeClassName="active"
          onMouseEnter={() => setHoverActive(true)}
          onMouseLeave={() => setHoverActive(false)}
        >
          {item.icon && Icon && <Icon className="side-nav-icon" />}
          <span className="nav-item-label">{item.name}</span>
          {item.badge && <NavBadge color={item.badge.variant} text={item.badge.text} />}
        </NavLink>
      </li>
    );
  }
};

export default NavSingleItem;
