import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Media, Row, Col } from 'reactstrap';

class CampaignChangesVoucherImages extends Component {
  static propTypes = {
    newVoucherImages: PropTypes.arrayOf(PropTypes.shape()),
    oldVoucherImages: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    newVoucherImages: null,
    oldVoucherImages: null,
  };

  constructor(props) {
    super(props);
    this.state = {
    //  url: null,
    };
  }

  componentDidMount() {
    const { image } = this.props;

  }

  render() {
    const { newVoucherImages, oldVoucherImages } = this.props;

      return (
        <Row>
          <Col sm='4'> Voucher images </Col>
          <Col sm='4'>
            {oldVoucherImages ? (oldVoucherImages.map((image) => (
              <Media src={image} />
            ))):(null)}
          </Col>
          <Col sm='4'>
            {newVoucherImages ? (newVoucherImages.map((image) => (
              <Media src={image} />
            ))):(null)}
          </Col>
        </Row>
      );

  }
}

export default CampaignChangesVoucherImages;
