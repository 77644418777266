import {
  GET_COMPANY_USERS,
  GET_COMPANY_ROLES,
  UPDATE_COMPANY_USER,
  DELETE_COMPANY_USER,
  GET_COMPANY_LINKED_USERS,
  UPDATE_COMPANY_LINKED_USER,
  DELETE_COMPANY_LINKED_USER,
} from '../../actions';
import { auth } from '../../lib/firebase';

const defaultState = {
  users: {}
};

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_COMPANY_USERS:
      return { ...state, users: action.payload };
    case GET_COMPANY_ROLES: {
      let newRoles = {};

      if (action.payload) {
        Object.keys(action.payload).map((key) => {
          const { permissions } = action.payload[key];

          let sortValue = 0;
          if (permissions) {
            Object.keys(permissions).map((key2) => {
              if (permissions[key2] === 1 || permissions[key2] === 2 || permissions[key2] === 15) { // 15 allows to show voucher portal role directly under Admin
                sortValue = sortValue + permissions[key2];
              }
              return null;
            });
          }

          newRoles[key] = { ...action.payload[key], sortValue };


          return null;
        })
      }

      return { ...state, companiesRoles: newRoles };
    }
    case UPDATE_COMPANY_USER: {
      if (action.key && state.users && state.users[action.key]) {
        return {
          ...state,
          users: {
            ...state.users,
            [action.key]: {
              ...state.users[action.key],
              ...action.payload,
            },
          }
        };
      } else {
        return {
          ...state,
          users: {
            ...state.users,
            [action.key]: {
              ...action.payload,
            },
          }
        };
      }
    }
    case DELETE_COMPANY_USER: {
      let newUsers = { ...state.users };
      delete newUsers[action.key];

      return {
        ...state,
        users: newUsers,
      };
    }


    case GET_COMPANY_LINKED_USERS:
      return { ...state, companyLinkedUsers: action.payload };

    case UPDATE_COMPANY_LINKED_USER: {
      if (action.key && state.companyLinkedUsers && state.companyLinkedUsers[action.key]) {
        return {
          ...state,
          companyLinkedUsers: {
            ...state.companyLinkedUsers,
            [action.key]: {
              ...state.companyLinkedUsers[action.key],
              ...action.payload,
            },
          }
        };
      } else {
        return {
          ...state,
          companyLinkedUsers: {
            ...state.companyLinkedUsers,
            [action.key]: {
              ...action.payload,
            },
          }
        };
      }
    }

    case DELETE_COMPANY_LINKED_USER: {
      let newUsers = { ...state.companyLinkedUsers };
      delete newUsers[action.key];

      return {
        ...state,
        companyLinkedUsers: newUsers,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
