import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Card, CardBody, Table, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label, Form, FormGroup, Alert } from 'reactstrap';

import { connect } from 'react-redux';
import Moment from 'react-moment';

import {
  updateLastActive,
} from '../../../actions/company';

import {
  getCompanyVouchers,
  updateVoucher,
  deleteVoucher,
  getVoucherCategories,
} from '../../../actions/vouchers';

class Vouchers extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    vouchers: PropTypes.shape(),
    fetchCompanyVouchers: PropTypes.func.isRequired,
    fetchVoucherCategories: PropTypes.func.isRequired,
    createVoucher: PropTypes.func.isRequired,
    changeVoucher: PropTypes.func.isRequired,
    deleteVoucher: PropTypes.func.isRequired,
  };

  static defaultProps = {
    vouchers: {},
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      updateVoucherModal: false,
      voucherToUpdate: null,
      deleteVoucherModal: false,
      deleteVoucherErr: null,
      voucherToDelete: null,
      discount: {},
      availableVouchers: {},
      showNoPermission: false,
    };
  }

  componentDidMount() {
    const { fetchVoucherCategories, user, history, updateLastActiveFunc } = this.props;

    if (user && Object.keys(user).length > 0) {
      if (user.company) {
        updateLastActiveFunc(user.company);

        if (user.role === 'teamOwner') {
          fetchVoucherCategories();
          this.setState({ showNoPermission: false });
        } else {
          const { roles } = this.props;

          if (roles && roles[user.role] && roles[user.role].permissions && roles[user.role].permissions.manageVouchers) {
            fetchVoucherCategories();
            this.setState({ showNoPermission: false });
          } else {
            this.setState({ showNoPermission: true });
          }
        }

      }
    } else {
      history.push('/');
    }
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleChangeDiscount = (e, id) => {
    const value = e.target.value;

    const valueParsed = parseInt(value, 10);

    this.setState((prevState) => ({ discount: { ...prevState.discount, [id]: valueParsed } }))
  }

  handleChangeAvailableVouchers = (e, id) => {
    const value = e.target.value;

    const valueParsed = parseInt(value, 10);

    this.setState((prevState) => ({ availableVouchers: { ...prevState.availableVouchers, [id]: valueParsed } }));
  }

  openUpdateVoucher = async (id, voucher) => {
    this.setState({updateVoucherModal: true, voucherToUpdate: id, availableVouchers: voucher.availableVouchers, name: voucher.name, discount: voucher.discount });
  }

  closeUpdateVoucher = () => {
    this.setState({ updateVoucherModal: false, voucherToUpdate: null });
  }

  updateVoucher = () => {
    const { changeVoucher, user, fetchCompanyVouchers } = this.props;
    const { voucherToUpdate, availableVouchers, name, discount } = this.state;

    changeVoucher(user.company, voucherToUpdate, { availableVouchers, name, discount }).then(res => {
      if (res === 'success') {
        this.setState({ updateVoucherModal: false, voucherToUpdate: null, availableVouchers: {}, name: null, discount: {} });

        fetchCompanyVouchers(user.company);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  toggleDeleteVoucher = (e, uid) => {
    e.stopPropagation();
    const { deleteVoucherModal } = this.state;
    if (deleteVoucherModal) {
      this.setState({ deleteVoucherModal: false, voucherToDelete: null, deleteVoucherErr: null });
    } else {
      this.setState({ deleteVoucherModal: true, voucherToDelete: uid, deleteVoucherErr: null });
    }
  }

  closeDeleteVoucher = () => {
    this.setState({ deleteVoucherModal: false,voucherToDelete: null, deleteVoucherErr: null });
  }

  deleteVoucher = () => {
    const { removeVoucher, user, fetchCompanyVouchers } = this.props;
    const { voucherToDelete } = this.state;

    removeVoucher(user.company, voucherToDelete).then(res => {
      this.setState({ deleteVoucherModal: false,voucherToDelete: null, deleteVoucherErr: null });

      if (res === 'success') {
        fetchCompanyVouchers(user.company);
      }
    }).catch(err => {
      console.log(err);
      this.setState({ deleteVoucherErr: err });
    })
  }

  render() {
    const { vouchers, user, roles } = this.props;
    const {
      updateVoucherModal,
      availableVouchers,
      name,
      discount,
      deleteVoucherModal,
      deleteVoucherErr,
      showNoPermission,
    } = this.state;

    if (showNoPermission) {
      return (
        <div>
          You don't have permission to view other company users
        </div>
      )
    }

    return (
      <div>
        <Card>
            <CardBody>
              {user && user.companyName ? (
                <div className="body-title">
                  {`${user.companyName} dashboard`}
                </div>
              ):(null)}
                <div class="table-responsive">
                <Table striped className="table">
                    <thead>
                        <tr>
                          <th>#</th>
                          <th>Name </th>
                          <th>Available Vouchers</th>
                          <th>Description</th>
                          <th>Category</th>
                          <th>Discount</th>
                          <th>Status</th>
                          <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                      {vouchers && Object.keys(vouchers).map((key, index) => {
                        return(
                          <tr>
                              <td>{index + 1}</td>
                              <td>{vouchers[key].name}</td>
                              <td>{vouchers[key].availableVouchers ? (Object.keys(vouchers[key].availableVouchers).map(key2 => (<span>{`${vouchers[key].availableVouchers[key2]}£,`} </span>))) : null} </td>
                              <td>{vouchers[key].description}</td>
                              <td></td>
                              <td>{vouchers[key].discount ? (Object.keys(vouchers[key].discount).map(key2 => (<span>{`${vouchers[key].discount[key2]}%,`} </span>))) : null} </td>
                              <td>{vouchers[key].status}</td>
                              <td>
                                {(user.role === 'teamOwner' || (roles && roles[user.role] && roles[user.role].permissions && roles[user.role].permissions.manageVouchers)) && (
                                  <Button onClick={(e) => this.toggleDeleteVoucher(e, key)}>
                                    Delete
                                  </Button>
                                )}
                              </td>
                          </tr>
                        )
                      })}
                    </tbody>
                </Table>
                </div>
            </CardBody>
        </Card>

        <Modal isOpen={updateVoucherModal} toggle={this.closeUpdateVoucher}>
          <ModalHeader toggle={this.closeUpdateVoucher}>
            Update Voucher
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="availableVouchers">
                  Available Vouchers<div className="mandatory">*</div>
                </Label>
                <Row>
                  <Col sm="4">
                    <Input
                      type="text"
                      name="availableVouchers1"
                      id="availableVouchers1"
                      value={availableVouchers['0']}
                      onChange={(e) => this.handleChangeAvailableVouchers(e, '0')}
                    />
                  </Col>
                  <Col sm="4">
                    <Input
                      type="number"
                      name="availableVouchers2"
                      id="availableVouchers2"
                      value={availableVouchers['1']}
                      onChange={(e) => this.handleChangeAvailableVouchers(e, '1')}
                    />
                  </Col>
                  <Col sm="4">
                    <Input
                      type="number"
                      name="availableVouchers3"
                      id="availableVouchers3"
                      value={availableVouchers['2']}
                      onChange={(e) => this.handleChangeAvailableVouchers(e, '2')}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="name">
                  Name<div className="mandatory">*</div>
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={this.handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="discount">
                  Discount<div className="mandatory">*</div>
                </Label>
                <Row>
                  <Col sm="4">
                    <Input
                      type="number"
                      name="discount1"
                      id="discount1"
                      value={discount['1']}
                      onChange={(e) => this.handleChangeDiscount(e, '1')}
                    />
                  </Col>
                  <Col sm="4">
                    <Input
                      type="number"
                      name="discount2"
                      id="discount2"
                      value={discount['2']}
                      onChange={(e) => this.handleChangeDiscount(e, '2')}
                    />
                  </Col>
                  <Col sm="4">
                    <Input
                      type="number"
                      name="discount3"
                      id="discount3"
                      value={discount['3']}
                      onChange={(e) => this.handleChangeDiscount(e, '3')}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Form>
            <div> <div className="mandatory">*</div>Mandatory fields </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.updateVoucher}>Edit</Button>
            <Button color="secondary" onClick={this.closeUpdateVoucher}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={deleteVoucherModal} toggle={this.closeDeleteVoucher}>
          <ModalHeader toggle={this.closeDeleteVoucher}>
            Are you sure you want to delete this Voucher?
          </ModalHeader>
          <ModalBody>
            {(!!deleteVoucherErr) && <Alert color="danger">{deleteVoucherErr}</Alert>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.deleteVoucher}>Delete Voucher</Button>
            <Button color="secondary" onClick={this.closeDeleteVoucher}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || {},
  vouchers: state.vouchers.vouchers || {},
  roles: state.users.roles || {},
  categories: state.company.categories || {},
});

const mapDispatchToProps = {
  fetchCompanyVouchers: getCompanyVouchers,
  fetchVoucherCategories: getVoucherCategories,
  changeVoucher: updateVoucher,
  removeVoucher: deleteVoucher,
  updateLastActiveFunc: updateLastActive,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Vouchers));
