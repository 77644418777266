import {
  RESOURCE_CENTER_DATA,
  RESOURCE_CENTER_UPLOAD_VIDEO,
  RESOURCE_CENTER_VIDEOS,
  RESOURCE_CENTER_DELETE_VIDEO,
  RESOURCE_CENTER_ADD_VIDEO,
  RESOURCE_CENTER_EDIT_VIDEO,
  RESOURCE_CENTRE_TEXT,
  RESOURCE_CENTRE_TOP_LINKS,
//  RESOURCE_CENTER_CONTRACT,
//  RESOURCE_CENTER_CONTRACT_UPDATE,
//  RESOURCE_CENTER_FAQ,
//  RESOURCE_CENTER_FAQ_UPDATE
} from '../../actions';

const defaultState = {
    resourceCenter: {}
};

const resourceCenterReducer = (state=defaultState, action) => {
    switch(action.type) {
        case RESOURCE_CENTER_VIDEOS:
            return { ...state, videos: action.payload };
        case RESOURCE_CENTER_ADD_VIDEO: {
            console.log('RESOURCE_CENTER_ADD_VIDEO');
            const videos = [ ...state.videos ];

            videos.push(action.payload);

            console.log('newvideos', { videos });
            return { ...state, videos: videos };
        }
        case RESOURCE_CENTER_DELETE_VIDEO: {
            console.log('RESOURCE_CENTER_DELETE_VIDEO');
            const videos = [ ...state.videos ];

            const index = videos.findIndex(prop => prop.id === action.payload);

            console.log(index);
            videos.splice(index, 1);

            console.log('newvideos', { videos });

            return { ...state, videos: videos };
        }
        case RESOURCE_CENTER_EDIT_VIDEO: {
            console.log('RESOURCE_CENTER_EDIT_VIDEO', { payload: action.payload });
            const videos = [ ...state.videos ];

            console.log(videos);

            const index = videos.findIndex(prop => prop.id === action.payload.id);

            console.log(index);
            videos[index] = { ...videos[index], ...action.payload };

            console.log('newvideos', { videos });

            return { ...state, videos: videos };
        }
        case RESOURCE_CENTER_DATA: {
          console.log('RESOURCE_CENTER_DATA', { data: action.payload });
          return { ...state, data: { ...state.data, ...action.payload } };
        }
        case RESOURCE_CENTRE_TEXT: {
          console.log('RESOURCE_CENTRE_TEXT', { data: action.payload });
          return { ...state, resourceCentreText: action.payload };
      }

      case RESOURCE_CENTRE_TOP_LINKS: {
        console.log('RESOURCE_CENTRE_TOP_LINKS', { data: action.payload });
        return { ...state, resourceCentreTopLinks: action.payload };
      }
      //  case RESOURCE_CENTER_CONTRACT: {
      //      return { ...state, contract: action.payload };
      //  }
      //  case RESOURCE_CENTER_FAQ: {
      //      return { ...state, faq: action.payload };
      //  }
        default:
            return state;
    }
};

export default resourceCenterReducer;
