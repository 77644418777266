import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Button, Alert, Spinner } from 'reactstrap';

import { connect } from 'react-redux';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState , convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';

import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import {
  getContactPageData,
  updateContactPageData,
} from '../../../../actions/contentManagement';

import notifications from '../../../../const/notifications';

class ContactPage extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    fetchData: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    dashboardTooltips: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      data: '',
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    const { fetchData } = this.props;

    fetchData().then((res) => {
      if (res) {
        this.setState({ data: res });

      //  let contentState = stateFromHTML(res);

        const blocksFromHtml = htmlToDraft(res);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        if (contentState) {
          this.setState({ editorState: EditorState.createWithContent(contentState) });
        }
      } else {
        this.setState({ editorState: EditorState.createEmpty() });
      }
    });
  }

  onChange = (editorState) => {
    this.setState({ editorState })
  };

  handleChange = (e) => {
    e.preventDefault();

    if (e.target) {
      const { value } = e.target;
      this.setState((prevState) => ({
        data: value,
        error: null,
        success: null,
      }))
    }
  };

  updateData = () => {
    const { updateData, user } = this.props;
    const { editorState } = this.state;

    if (editorState) {
      let html = stateToHTML(editorState.getCurrentContent());

      const rawContentState = convertToRaw(editorState.getCurrentContent());

      const markup = draftToHtml(
        rawContentState
      );

      this.setState({ processing: true });

      let adminName;

      if (user.name || (user.firstName && user.lastName)) {
        adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
      }

      updateData(markup, adminName).then((res) => {
        if (res === 'success') {
          this.setState({ processing: null, success: true, error: null });
        } else if (res === 'error') {
          this.setState({ processing: null, success: null, error: true });
        }
      });
    }
  }

  render() {
    const { showNoPermission } = this.props;
    const {
      error,
      success,
      editorState,
      processing,
    } = this.state;

    return (
      <div>
        {(!!success) && <Alert color="success">{notifications.admin.contentManagement.updateSuccessful}</Alert>}
        {(!!error) && <Alert color="danger">{notifications.admin.contentManagement.updateFailed}</Alert>}
        <Row>
          <Col sm="12">
            <Editor
              className="content-management-text-area"
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={this.onChange}
            />
          </Col>
        </Row>
        <Button disabled={showNoPermission} onClick={this.updateData}>
          Save
          {(processing) ? (
            <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
          ):(null)}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
});

const mapDispatchToProps = {
  fetchData: getContactPageData,
  updateData: updateContactPageData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactPage));
