import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { NavLink, Switch, Route, Redirect } from 'react-router-dom';
import { Button, Badge, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Header, SidebarNavAsAdmin, Footer, PageContent, Avatar, Chat, PageAlert, Page } from '../vibe';

import { Helmet } from "react-helmet";

import PendingPage from '../views/pages/Company/Pending';

import Logo from '../assets/images/vibe-logo.svg';
import nav from '../_nav';
import routes from '../views';
import ContextProviders from '../vibe/components/utilities/ContextProviders';
import handleKeyAccessibility, { handleClickAccessibility } from '../vibe/helpers/handleTabAccessibility';

import adminAsCompanyNav from '../_adminAsCompanyNav';

import { connect } from 'react-redux';

import { auth } from '../lib/firebase';
import { LogoutButton } from '../vibe';

import FooterComponent from '../views/components/Footer/Footer';

 import {
//  getAllRoles,
  getAllAdminRoles,
  getAllAdminsRoles,
  getAdminStatistics,
  cancelAdminListeners,
  getAdminSettings,
  leaveCompanyDashboard,
} from '../actions/admin';

import {
  getAllRoles,
  cancelListeners,
} from '../actions/users';

import {
  getCompanyData,
  getCompanyOwnerData,
  cancelCompanyListeners,
  getCategories,
  getCompanyNotifications,
  getLinkedAPIs,
} from '../actions/company';

import {
  getUserData,
  getStripeLoginLink,
  checkStripeCompanyStatus,
} from '../actions/user';

import {
  getCompanyVouchers,
  unsubscribeVouchers,
} from '../actions/vouchers';

import {
  getDisablePerksTab,
  getPerksTabTitle,
} from '../actions/perks';

const MOBILE_SIZE = 992;

class AdminAsCompanyLayout extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    getRoles: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    getAdminRoles: PropTypes.func.isRequired,
    fetchCompanyData: PropTypes.func.isRequired,
    fetchCompanyOwnerData: PropTypes.func.isRequired,
    fetchCompanyNotifications: PropTypes.func.isRequired,
    cancelActiveListeners: PropTypes.func.isRequired,
    cancelActiveCompanyListeners: PropTypes.func.isRequired,
    fetchVoucherCategories: PropTypes.func.isRequired,
    fetchCompanyVouchers: PropTypes.func.isRequired,
    fetchAdminStatistics: PropTypes.func.isRequired,
    fetchAdminSettings: PropTypes.func.isRequired,
    unsubscribeAdminListeners: PropTypes.func.isRequired,
    fetchStripeLoginLink: PropTypes.func.isRequired,
    getStripeCompanyStatus: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      sidebarCollapsed: false,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      showChat1: true,
      userFetched: false,
    };
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ sidebarCollapsed: false, isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', handleKeyAccessibility);
    document.addEventListener('click', handleClickAccessibility);

    const {
      fetchCompanyData,
      fetchCompanyNotifications,
      fetchCompanyOwnerData,
      fetchCompanyVouchers,
      fetchStripeLoginLink,
      getStripeCompanyStatus,
      adminViewAsCompany,
      fetchVoucherCategories,
      fetchDisablePerksTab,
      fetchPerksTabTitle,
      fetchLinkedAPIs,
    } = this.props;


    this.setState({ userFetched: false });

    fetchVoucherCategories();

    fetchDisablePerksTab().then((res) => {
    //  console.log('fetchDisablePerksTab', res);
      this.setState({ perksDisabled: res });
    });

    fetchPerksTabTitle().then((res) => {
    //  console.log('fetchPerksTabTitle', res);
      this.setState({ perkTitle: res || 'Perks' });
    });

    if (adminViewAsCompany) {
      fetchCompanyNotifications(adminViewAsCompany);
      fetchStripeLoginLink(adminViewAsCompany);
      getStripeCompanyStatus(adminViewAsCompany);
      fetchCompanyData(adminViewAsCompany, true);
      fetchCompanyOwnerData(adminViewAsCompany);
      fetchCompanyVouchers(adminViewAsCompany);
      fetchLinkedAPIs(adminViewAsCompany);
    }

    this.setState({ userFetched: true });

  //  this.setState({ uid: user.uid });
  }

  componentDidUpdate(prev) {
    if (this.state.isMobile && prev.location.pathname !== this.props.location.pathname) {
      this.toggleSideCollapse();
    }
  }

  componentWillUnmount() {
    const { cancelActiveListeners, cancelActiveCompanyListeners, unsubscribeVouchersListeners, unsubscribeAdminListeners } = this.props;
    window.removeEventListener('resize', this.handleResize);

    cancelActiveListeners();
    cancelActiveCompanyListeners();
    unsubscribeVouchersListeners();
    unsubscribeAdminListeners();
  }

  logout = () => {
    const { logout, cancelActiveListeners, cancelActiveCompanyListeners, unsubscribeVouchersListeners, unsubscribeAdminListeners } = this.props;

    logout();

    cancelActiveListeners();
    cancelActiveCompanyListeners();
    unsubscribeVouchersListeners();
    unsubscribeAdminListeners();
  }

  toggleSideCollapse = () => {
    this.setState(prevState => ({ sidebarCollapsed: !prevState.sidebarCollapsed }));
  };

  shadeColor = (col, amt) => {
    col = col.replace(/^#/, '')
    if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

    let [r, g, b] = col.match(/.{2}/g);

    if (amt > 0) {
      if (b > r && b > g) {
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt + 30])
      } else if (r > b && r > g) {
        ([r, g, b] = [parseInt(r, 16) + amt + 30, parseInt(g, 16) + amt, parseInt(b, 16) + amt])
      } else if (g > r && g > b) {
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt + 30, parseInt(b, 16) + amt])
      } else {
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])
      }
    } else {
      if (b > r && b > g) {
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt - 20])
      } else if (r > b && r > g) {
        ([r, g, b] = [parseInt(r, 16) + amt - 30, parseInt(g, 16) + amt, parseInt(b, 16) + amt])
      } else if (g > r && g > b) {
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt - 30, parseInt(b, 16) + amt])
      } else {
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])
      }
    }


    r = Math.max(Math.min(255, r), 0).toString(16)
    g = Math.max(Math.min(255, g), 0).toString(16)
    b = Math.max(Math.min(255, b), 0).toString(16)

    const rr = (r.length < 2 ? '0' : '') + r
    const gg = (g.length < 2 ? '0' : '') + g
    const bb = (b.length < 2 ? '0' : '') + b

    return `#${rr}${gg}${bb}`
  }

  render() {
    const { user, isLoggedIn, roles, adminViewAsCompany, adminViewAsCompanyName, leaveDashboard, branding } = this.props;
    const { sidebarCollapsed, userFetched, perksDisabled, perkTitle } = this.state;
    const sidebarCollapsedClass = sidebarCollapsed ? 'side-menu-collapsed' : '';

    if (!userFetched) {
      return null;
    }

    return (
      <ContextProviders>
        <div className={`app ${sidebarCollapsedClass}`}>
          <PageAlert />
          <div className="app-body">
            <Helmet>
              <title>{branding.tabInfo || ''}</title>
              <link rel="icon" type="image/png" href={branding.favicon || ''} sizes="16x16" />
            </Helmet>
            <SidebarNavAsAdmin
              {...this.props}
              nav={adminAsCompanyNav}
              logo={branding.logo || null}
              logoText={branding.siteName || ''}
              isSidebarCollapsed={sidebarCollapsed}
              toggleSidebar={this.toggleSideCollapse}
              shadeColor={this.shadeColor}
              logout={this.logout}
              perksDisabled={perksDisabled}
              perkTitle={perkTitle}
            />
            <Page>
              <Header
                {...this.props}
                toggleSidebar={this.toggleSideCollapse}
                isSidebarCollapsed={sidebarCollapsed}
                routes={routes}
                adminViewAsCompany={adminViewAsCompany}
                adminViewAsCompanyName={adminViewAsCompanyName}
                leaveCompanyDashboard={leaveDashboard}
                perksDisabled={perksDisabled}
                perkTitle={perkTitle}
              >
                <HeaderNav user={user} isLoggedIn={isLoggedIn} roles={roles} />
              </Header>
              <PageContent>

                  {isLoggedIn && user && (!user.status || user.status === 'pending') ? (
                    <PendingPage />
                  ):(
                    <Switch>
                      {routes.map((page, key) => (
                        <Route path={page.path} component={page.component} key={key} />
                      ))}
                      <Redirect from="/" to={`${isLoggedIn ? '/dashboard' : '/login'}`} />
                    </Switch>
                  )}
              </PageContent>
            </Page>
          </div>
          <Footer>
          {/*  <span>Copyright © 2019 Nice Dash. All rights reserved.</span>
            <span>
              <a href="#!">Terms</a> | <a href="#!">Privacy Policy</a>
            </span> */}
            <FooterComponent />
          </Footer>
        {/*  <Chat.Container>
            {this.state.showChat1 && (
              <Chat.ChatBox name="Messages" status="online" image={avatar1} close={this.closeChat} />
            )}
          </Chat.Container>  */}
        </div>
      </ContextProviders>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  vouchers: state.vouchers.vouchers || null,
  isLoggedIn: state.user.isLoggedIn || false,
  userDataFetched: state.user.userDataFetched || false,
  roles: { ...state.users.roles, ...state.admin.adminRoles } || {},
  adminStatistics: state.admin.adminStatistics || null,
  adminViewAsCompany: state.admin.adminViewAsCompany || null,
  adminViewAsCompanyName: state.admin.adminViewAsCompanyName || null,
  branding: state.contentManagement.branding || {},
});

const mapDispatchToProps = {
  getUser: getUserData,
  getRoles: getAllRoles,
  // getAdminRoles: getAllAdminRoles,
  getAdminRoles: getAllAdminsRoles,
  cancelActiveListeners: cancelListeners,
  fetchCompanyData: getCompanyData,
  fetchCompanyOwnerData: getCompanyOwnerData,
  fetchCompanyNotifications: getCompanyNotifications,
  cancelActiveCompanyListeners: cancelCompanyListeners,
  fetchVoucherCategories: getCategories,
  fetchCompanyVouchers: getCompanyVouchers,
  unsubscribeVouchersListeners: unsubscribeVouchers,
  fetchAdminStatistics: getAdminStatistics,
  fetchAdminSettings: getAdminSettings,
  unsubscribeAdminListeners: cancelAdminListeners,
  fetchStripeLoginLink: getStripeLoginLink,
  getStripeCompanyStatus: checkStripeCompanyStatus,
  leaveDashboard: leaveCompanyDashboard,
  fetchDisablePerksTab: getDisablePerksTab,
  fetchPerksTabTitle: getPerksTabTitle,
  fetchLinkedAPIs: getLinkedAPIs,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminAsCompanyLayout);

function HeaderNav({ user, isLoggedIn, roles }) {
  return (
    <React.Fragment>
    {/*  <NavItem>
        <form className="form-inline">
          <input className="form-control mr-sm-1" type="search" placeholder="Search" aria-label="Search" />
          <Button type="submit" className="d-none d-sm-block">
            <i className="fa fa-search" />
          </Button>
        </form>
      </NavItem>  */}
      {(isLoggedIn && user && user.uid) ? (
        <>
      {/*  <UncontrolledDropdown className="userDropdown" nav inNavbar>
          <DropdownToggle nav caret>  */}
            <div className="userDropdown-wrapper">
              <h2 className="userDropdown-name">
                {user.displayName}
              </h2>
              <h4 className="userDropdown-company">
                {user.companyName}
              </h4>
              <p className="userDropdown-role">
                <td>{(user.role === 'admin') ? 'Admin' : ((user.role === 'teamOwner') ? user.roleInCompany : (roles[user.role] ? roles[user.role].name : null))}</td>
              </p>
            </div>
        {/*  </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem>Option 1</DropdownItem>
            <DropdownItem divider />
            <DropdownItem><LogoutButton /></DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown> */}
        </>
      ):(null)}
      {/*  <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav>
              User
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem>
              <NavLink to="/login" className="navbar-brand">
                Login
              </NavLink>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem>
              <NavLink to="/signup" className="navbar-brand">
                Sign Up
              </NavLink>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>  */}
    </React.Fragment>
  );
}
