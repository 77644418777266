import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

import moment from 'moment';

class Chart extends Component {
  static propTypes = {
    image: PropTypes.string,
  };

  static defaultProps = {
    image: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      url: null,
      data: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { chartFilter, adminDashboard, timeFilter, adminDashboardLastUpdate, startDate, endDate } = this.props;

    let dateLength = null;
    let startId;
    let endId;

    if (timeFilter === '4weeks') {
      dateLength = 30;
    } else if (timeFilter === 'day') {
      dateLength = 1;
    } else if (timeFilter === '1week') {
      dateLength = 7;
    } else if (timeFilter === '2weeks') {
      dateLength = 14;
    } else if (timeFilter === '6months') {
      dateLength = 183;
    }

    if (dateLength) {
      const startDateSplit = moment().subtract(dateLength, 'days').format("DD-MM-YYYY").split("-");

      const startDay = startDateSplit[0];
      const startMonth = startDateSplit[1];
      const startYear = startDateSplit[2];

      startId = `${startYear}${startMonth}${startDay}`;
    } else if (timeFilter === 'custom') {
      const { startDate, endDate } = this.props;

      if (startDate && endDate) {
        const startDateSplit = moment(startDate).format("DD-MM-YYYY").split("-");
        const endDateSplit = moment(endDate).format("DD-MM-YYYY").split("-");

        const startDay = startDateSplit[0];
        const startMonth = startDateSplit[1];
        const startYear = startDateSplit[2];

        startId = `${startYear}${startMonth}${startDay}`;

        const endDay = endDateSplit[0];
        const endMonth = endDateSplit[1];
        const endYear = endDateSplit[2];

        endId = `${endYear}${endMonth}${endDay}`;
      }
    }

    if (adminDashboardLastUpdate !== prevProps.adminDashboardLastUpdate || timeFilter !== prevProps.timeFilter || chartFilter !== prevProps.chartFilter || startDate !== prevProps.startDate || endDate !== prevProps.endDate) { // adminDashboardLastUpdate !== prevProps.adminDashboardLastUpdate ||
      let data = [];
      Object.keys(adminDashboard).map((key, index) => {

        if (timeFilter === 'custom') {
          if (!startId || !startId) {
            return null;
          }
          if (parseInt(key, 10) < parseInt(startId, 10)) {
            return null;
          }
          if (parseInt(key, 10) > parseInt(endId, 10)) {
            return null;
          }
        } else if (startId && parseInt(key, 10) < parseInt(startId, 10)) {
          return null;
        }

        if (chartFilter === 'redemptions') {
          data.push({ count: adminDashboard[key].redemptionsCount, value: adminDashboard[key].redemptionsValue, date: adminDashboard[key].date });
        } else if (chartFilter === 'sales') {
          data.push({ count: adminDashboard[key].salesCount, value: adminDashboard[key].salesValue, date: adminDashboard[key].date });
        } else if (chartFilter === 'gifts') {
          data.push({ count: adminDashboard[key].giftedCount, value: adminDashboard[key].giftedValue, date: adminDashboard[key].date });
        }
        return null;
      });

      this.setState({ data });
    }


  }

  render() {
    const { data } = this.state;

      return (
        <div style={{ width: '100%', position: 'relative' }}>
        <ResponsiveContainer id="chart" width='99%' height={240}>
          <BarChart width={500} height={240} data={data}>
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="count" fill="orange" />
          </BarChart>
        </ResponsiveContainer>
        </div>
      );
  }
}

export default Chart;
