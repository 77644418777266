import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Button, Input, Label, Form, FormGroup, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';

import { connect } from 'react-redux';

import {
  getSignposts,
  updateSignposts,
} from '../../../../actions/contentManagement';

import notifications from '../../../../const/notifications';

class Signposts extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    categories: PropTypes.arrayOf(PropTypes.shape()),
    getSignpost: PropTypes.func.isRequired,
    updateSignpost: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    signposts: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      data: {},
      signposts: {},
    };
  }

  componentDidMount() {
    const { getSignpost } = this.props;

    getSignpost().then((res) => {
      if (res && res.signposts) {
        this.setState({ signposts: res.signposts });
      }
    });
  }

  handleChange = (e) => {
    e.preventDefault();

    if (e.target) {
      const { value, name } = e.target;
      this.setState((prevState) => ({
        signposts: {
          ...prevState.signposts,
          [name]: value,
        },
        error: null,
        success: null,
      }))
    }
  };

  updateSignpost = () => {
    const { updateSignpost, user } = this.props;
    const { data, signposts } = this.state;

    this.setState({ processing: true });

    let adminName;

    if (user.name || (user.firstName && user.lastName)) {
      adminName = user.name ? user.name : `${user.firstName} ${user.lastName}`;
    }

    console.log('updateSignpost', signposts, adminName);

    updateSignpost(signposts, adminName).then((res) => {
      if (res === 'success') {
        this.setState({ processing: null, signposts: signposts, success: true, error: false, addModal: false, editModal: false });
      } else {
        this.setState({ processing: null, success: false, error: true, addModal: false, editModal: false });
      }
    }).catch(err => {
      if (err === 'no value') {
        this.setState({ processing: null, error: false, success: false, addError: notifications.admin.contentManagement.noValue });
      } else {
        this.setState({ processing: null, error: false, success: false, addError: err });
      }
    });
  }

  render() {
    const { showNoPermission } = this.props;
    const {
      error,
      success,
      signposts,
      processing,
    } = this.state;

    return (
      <div>
        {(!!success) && <Alert color="success">{notifications.admin.contentManagement.updateSuccessful}</Alert>}
        {(!!error) && <Alert color="danger">{notifications.admin.contentManagement.updateFailed}</Alert>}

        <Row>
          <Col sm="3">
            Name
          </Col>
          <Col sm="8">
            Value
          </Col>
          <Col sm="3">
            Edit
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            Button text
          </Col>
          <Col sm="9">
            <Input
              type="text"
              value={signposts.buttonText}
              name="buttonText"
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            Button link
          </Col>
          <Col sm="9">
            <Input
              type="text"
              value={signposts.buttonLink}
              name="buttonLink"
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            Text
          </Col>
          <Col sm="9">
            <Input
              type="text"
              value={signposts.text}
              name="text"
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            Text
          </Col>
          <Col sm="9">
            <Input
              type="text"
              value={signposts.textLink}
              name="textLink"
              onChange={this.handleChange}
            />
          </Col>
        </Row>

        <Row>
          <Col sm="9">
            Button text
          </Col>
          <Col sm="3">
            <Button disabled={showNoPermission} onClick={() => this.updateSignpost()}>
              Save
              {(processing) ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ) : (null)}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  signposts: state.contentManagement.signposts || {},
});

const mapDispatchToProps = {
  getSignpost: getSignposts,
  updateSignpost: updateSignposts,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signposts));
