import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter, Link } from 'react-router-dom';

import { Row, Col, Media, Card, CardBody, Table, Button, Input, Label, Form, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane, Alert } from 'reactstrap';

import { connect } from 'react-redux';

import { Mail } from 'react-feather';

import {
  getFooters,
  getFooterIcons,
} from '../../../actions/contentManagement';

class Footer extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    pendingTabData: PropTypes.shape(),
    fetchFooters: PropTypes.func.isRequired,
    fetchFooterIcons: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    dashboardTooltips: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      data: {},
    };
  }

  componentDidMount() {
    const { fetchFooters, fetchFooterIcons, admin, user, isLoggedIn, history } = this.props;
    fetchFooterIcons();
    fetchFooters().then((res) => {
      if (res && res.data) {
        this.setState({ data: res.data });
      }
    });
  }

  render() {
    const { user, footerIcons } = this.props;
    const {
      error,
      success,
      name,
      data,
    } = this.state;

    return (
      <div style={{ width: '100%' }}>
        <Row>
          <Col sm="12">
            <Row>
              <Col sm="12" style={{ paddingBottom: 5 }}>
                <Link style={{ textDecoration: 'none', display: 'inline-block', marginRight: 25, color: '#000', fontSize: 12 }} to="/T&Cs">
                  T&Cs
                </Link>
                <Link style={{ textDecoration: 'none', display: 'inline-block', marginRight: 25, color: '#000', fontSize: 12 }} to="/privacy-policy">
                  Privacy Policy
                </Link>
                <div style={{ textDecoration: 'none', display: 'inline-block', marginLeft: 15, fontSize: 12 }}>
                  {data.textNotice1 && data.textNotice1.value ? (
                    <span> {data.textNotice1.value} </span>
                  ): ('')}
                </div>
                <div style={{ float: 'right', textDecoration: 'none', display: 'inline-block', marginLeft: 15, fontSize: 12 }}>
                  {(data.contactEmail && data.contactEmail.value) ? (
                    <a style={{ display: 'inline-block', marginTop: -4, marginLeft: 10, marginRight: 5, float: 'right', color: '#000' }} target="_blank" rel="noreferrer noopener" href={`mailto: ${data.contactEmail.value}`}>
                      <Mail style={{ height: 20, width: 40 }} />
                    </a>
                  ):(null)}
                </div>
              {/*  <Link style={{ display: 'inline-block', marginRight: 25, color: '#000' }} to="/cookie-policy">
                  Cookie Policy
                </Link>  */}
              </Col>
            </Row>
          {/*  <Row>
              <Col sm="12">
                {data.textNotice1 && data.textNotice1.value ? (
                  <span> {data.textNotice1.value} </span>
                ): ('')}
              </Col>
            </Row>  */}
          </Col>
        {/*  <Col sm="6">
            <Row>
              <Col sm="12" style={{ height: 30 }} />
            </Row>
            <Row>
              <Col sm="12">
                {(data.linkedIn && data.linkedIn.value && footerIcons.linkedIn && footerIcons.linkedIn.url) ? (
                  <a style={{ display: 'inline-block', marginLeft: 10, marginRight: 5, float: 'right' }} target="_blank" rel="noreferrer noopener" href={data.linkedIn.value}>
                    <Media style={{ maxHeight: 20 }} src={footerIcons.linkedIn.url} />
                  </a>
                ):(null)}
                {(data.youtube && data.youtube.value && footerIcons.youtube && footerIcons.youtube.url) ? (
                  <a style={{ display: 'inline-block', marginLeft: 10, marginRight: 5, float: 'right' }} target="_blank" rel="noreferrer noopener" href={data.youtube.value}>
                    <Media style={{ maxHeight: 20 }} src={footerIcons.youtube.url} />
                  </a>
                ):(null)}
                {(data.facebook && data.facebook.value && footerIcons.facebook && footerIcons.facebook.url) ? (
                  <a style={{ display: 'inline-block', marginLeft: 10, marginRight: 5, float: 'right' }} target="_blank" rel="noreferrer noopener" href={data.facebook.value}>
                    <Media style={{ maxHeight: 20 }} src={footerIcons.facebook.url} />
                  </a>
                ):(null)}
                {(data.twitter && data.twitter.value && footerIcons.twitter && footerIcons.twitter.url) ? (
                  <a style={{ display: 'inline-block', marginLeft: 10, marginRight: 5, float: 'right' }} target="_blank" rel="noreferrer noopener" href={data.twitter.value}>
                    <Media style={{ maxHeight: 20 }} src={footerIcons.twitter.url} />
                  </a>
                ):(null)}
                {(data.contactEmail && data.contactEmail.value) ? (
                  <a style={{ display: 'inline-block', marginTop: -4, marginLeft: 10, marginRight: 5, float: 'right', color: '#000' }} target="_blank" rel="noreferrer noopener" href={`mailto: ${data.contactEmail.value}`}>
                    <Mail style={{ height: 20, width: 40 }} />
                  </a>
                ):(null)}
              </Col>
            </Row>
          </Col>  */}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  footerIcons: state.contentManagement.footerIcons || {},
});

const mapDispatchToProps = {
  fetchFooters: getFooters,
  fetchFooterIcons: getFooterIcons,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
