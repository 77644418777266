import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Link, withRouter } from 'react-router-dom';

import { Button } from 'reactstrap';

import { connect } from 'react-redux';

import {
  doLogout,
} from '../../../actions/user';

import {
  cancelAdminListeners,
} from '../../../actions/admin';

import {
  cancelListeners,
} from '../../../actions/users';

import {
  cancelCompanyListeners,
} from '../../../actions/company';

import {
  unsubscribeVouchers,
} from '../../../actions/vouchers';


class LogoutButton extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    cancelActiveListeners: PropTypes.func.isRequired,
    cancelActiveCompanyListeners: PropTypes.func.isRequired,
    unsubscribeVouchersListeners: PropTypes.func.isRequired,
    unsubscribeAdminListeners: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  logout = () => {
    const { logout, history } = this.props;

    console.log('logout');

    return logout()
      .then(() => {
        console.log('logout then');
        const { cancelActiveListeners, cancelActiveCompanyListeners, unsubscribeVouchersListeners, unsubscribeAdminListeners } = this.props;

        cancelActiveListeners();
        cancelActiveCompanyListeners();
        unsubscribeVouchersListeners();
        unsubscribeAdminListeners();

        history.push('/login');
      });
  }

  render() {
    const { user } = this.props;

    return (
      <>
        {(user && user.isLoggedIn) ? (
          <Button onClick={this.logout}>Logout</Button>
        ) : (
          <Link to='/login'> <Button>Login</Button> </Link>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user || {},
});

const mapDispatchToProps = {
  logout: doLogout,
  cancelActiveListeners: cancelListeners,
  cancelActiveCompanyListeners: cancelCompanyListeners,
  unsubscribeVouchersListeners: unsubscribeVouchers,
  unsubscribeAdminListeners: cancelAdminListeners,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogoutButton));

// export default Dashboard;
