import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Card, CardBody, Table, Button, Input, Label, Form, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane, Alert } from 'reactstrap';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';

import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';


import { connect } from 'react-redux';

import {
  getFooterPage,
} from '../../../actions/contentManagement';

class TAndCPage extends Component {
  static propTypes = {
  //  user: PropTypes.shape(),
  //  admin: PropTypes.shape(),
    fetchFooterPage: PropTypes.func.isRequired,
  };

  static defaultProps = {
  //  user: {},
  //  admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      content: null,
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    const { fetchFooterPage, admin, user, isLoggedIn, history } = this.props;

    fetchFooterPage('webTC').then((res) => {
      if (res && res.text) {
        let contentState = stateFromHTML(res.text);

        if (contentState) {
          this.setState({ editorState: EditorState.createWithContent(contentState) });
        }

        this.setState({ content: res.text });
      }
    });
  }


  render() {
    const {
      content,
      editorState,
    } = this.state;

    return (
      <div>
        <Editor
          className="content-management-text-area"
          readOnly
          toolbarHidden
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
        //  onEditorStateChange={this.onChange}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
//  user: state.user.userData || {},
//  footerPages: state.contentManagement.footerPages || {},
});

const mapDispatchToProps = {
  fetchFooterPage: getFooterPage,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TAndCPage));
