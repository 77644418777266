export default {
  client: [
    {
      name: 'Dashboard',
      url: '/dashboard',
    },
    {
      name: 'Create New Campaign',
      url: '/new-voucher',
    },
    {
      name: 'Perks',
      url: '/perks',
    },
    { name: 'divider' },

    {
      name: 'Payments',
      url: '/payments',
    },
    {
      name: 'Website Connection',
      url: '/api',
    },
    {
      name: 'Basic Information',
      url: '/basic-information',
    },
    { name: 'divider' },
    {
      name: 'Setup Help',
      url: '/setup-help',
    }
  ],

  bottom: [
    {
      name: 'Account',
      url: '/dashboard',
      icon: 'User',
      badge: {
        variant: 'success',
        text: '3',
      },
    },
  ],
};
