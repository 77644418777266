import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import ScrollArea from 'react-scrollbar';
import { Eye, EyeOff } from 'react-feather';

import { Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label, Form, FormGroup, Alert, Spinner } from 'reactstrap';

import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';

import { connect } from 'react-redux';
import Moment from 'react-moment';

import {
  getAllAdminRoles,
  createAdmin,
  deleteAdmin,
  changeUserRole,
  getAdminsFiltered,
  getDataFilteredNextPage,
  sortData,
} from '../../../actions/admin';

import {
  getUserData,
} from '../../../actions/user';

import notifications from '../../../const/notifications';

class Admins extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admins: PropTypes.shape(),
    roles: PropTypes.shape(),
    getUser: PropTypes.func.isRequired,
    getRoles: PropTypes.func.isRequired,
    createU: PropTypes.func.isRequired,
    deleteU: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admins: {},
    roles: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      deleteUserModal: false,
      deleteUserErr: null,
      userToDelete: null,
      showNoPermission: false,
      dontShowPage: false,
      createAdminErr: null,
      showPassword1: 'password',
      showPassword2: 'password',
      roles: {
        teamOwner: 'Admin',
      },
    };
  }

  componentDidMount() {
    const { user, isLoggedIn, history, getRoles, fetchAdminsFiltered } = this.props;

    if (user && Object.keys(user).length > 0) {
      if (!user || !isLoggedIn || !user || !user.roleClass || user.roleClass !== 'admin') {
        history.push('/');
      } else {
        if (user.role === 'admin') {
        //  fetchAdminsFiltered('admins', null, null, null);
          fetchAdminsFiltered(null, null, null);

          this.setState({ showNoPermission: false, dontShowPage: false });
        } else {
          getRoles().then(() => {
            const { roles } = this.props;

            if (roles && roles[user.role] && roles[user.role].permissions && roles[user.role].permissions.admins) {
            //  fetchAdminsFiltered('admins', null, null, null);
              fetchAdminsFiltered(null, null, null);

              if (roles[user.role].permissions.admins === 2) {
                this.setState({ showNoPermission: false, dontShowPage: false });

              } else {
                this.setState({ showNoPermission: true, dontShowPage: false });
              }
            } else {
              this.setState({ showNoPermission: true, dontShowPage: true });
            }
          })
        }
      }
    } else {
      history.push('/');
    }
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  selectRole = (e) => {
    this.setState({ role: e.target.value });
  }

  selectRoleNew = (role) => {
    this.setState({ role: role });
  }

  openCreateUser = () => {
    this.setState({ createUserModal: true, role: 'admin', createAdminErr: null });
  }

  closeCreateUser = () => {
    this.setState({ createUserModal: false, role: null, createAdminErr: null, processing: null });
  }

  createUser = () => {
    const { createU } = this.props;
    const { email, password, confirmPassword, name, role } = this.state;

    if (!email) {
      this.setState({ createAdminErr: notifications.admin.admins.missingEmail || 'Missing email' });

      if (this.modalScrollbar) {
        this.modalScrollbar.scrollTop();
      }
    } else if (!confirmPassword || !password) {
      this.setState({ createAdminErr: notifications.admin.admins.missingPassword || 'Missing password' });

      if (this.modalScrollbar) {
        this.modalScrollbar.scrollTop();
      }
    } else if (confirmPassword !== password) {
      this.setState({ createAdminErr: notifications.admin.admins.passwordsDontMatch || 'Passwords dont match' });

      if (this.modalScrollbar) {
        this.modalScrollbar.scrollTop();
      }
    } else if (!name) {
      this.setState({ createAdminErr: notifications.admin.admins.missingName || 'Missing name' });

      if (this.modalScrollbar) {
        this.modalScrollbar.scrollTop();
      }
    } else if (!role) {
      this.setState({ createAdminErr: notifications.admin.admins.missingRole || 'Missing role' });

      if (this.modalScrollbar) {
        this.modalScrollbar.scrollTop();
      }
    } else {
      if (password.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$/)) {
        this.setState({ createAdminErr: null, processing: 'invite' });

        createU(email, password, name, role).then(res => {
          this.setState({ processing: null, createUserModal: false, email: null, password: null, name: null, createAdminErr: null });
        }).catch(err => {
          this.setState({ processing: null, createAdminErr: notifications.admin.admins.failedCreate || 'Failed to create user, please try again later' });

          if (this.modalScrollbar) {
            this.modalScrollbar.scrollTop();
          }
        })
      } else {
        this.setState({ createAdminErr: notifications.admin.admins.insecurePassword || 'Password needs to be at least 8 characters, have at least 1 uppercase, lowercase, number, symbol.' });

        if (this.modalScrollbar) {
          this.modalScrollbar.scrollTop();
        }
      }
    }
  }

  openChangeRole = (e, uid, role) => {
    e.stopPropagation();
    this.setState({ changeRoleModal: true, changeRoleUid: uid, role });
  }

  closeChangeRole = () => {
    this.setState({ changeRoleModal: false, changeRoleUid: null, role: null });
  }

  changeRole = () => {
    const { changeRole, fetchAdminsFiltered } = this.props;
    const { changeRoleUid, role } = this.state;

    this.setState({ processing: 'update-update' });

    changeRole(changeRoleUid, role).then(res => {
      fetchAdminsFiltered(null, null, null);

      this.setState({ processing: null, changeRoleModal: false, changeRoleUid: null, role: null });
    }).catch(err => {
      console.log(err);
    });
  }

  toggleDeleteUser = (e, uid) => {
    e.stopPropagation();
    const { deleteUserModal, changeRoleUid } = this.state;
    if (deleteUserModal) {
      this.setState({ deleteUserModal: false, userToDelete: null, deleteUserErr: null });
    } else {
      this.setState({ deleteUserModal: true, userToDelete: changeRoleUid, deleteUserErr: null });
    }
  }

  closeDeleteUser = () => {
    this.setState({ deleteUserModal: false, userToDelete: null, deleteUserErr: null });
  }

  deleteUser = () => {
    const { deleteU } = this.props;
    const { userToDelete } = this.state;

    this.setState({ processing: 'update-delete' });

    deleteU(userToDelete).then(res => {
      this.setState({ processing: null, deleteUserModal: false, userToDelete: null, deleteUserErr: null });
    }).catch(err => {
      console.log(err);
      this.setState({ deleteUserErr: notifications.admin.admins.deleteUserErr || err });
    });
  }

  toggleShowPassword = (id) => {
    if (id === 1) {
      this.setState({
        showPassword1: this.state.showPassword1 === 'input' ? 'password' : 'input'
      })
    } else if (id === 2) {
      this.setState({
        showPassword2: this.state.showPassword2 === 'input' ? 'password' : 'input'
      })
    }
  }

  handleChangeFilter = (e) => {
    if (this.state.typingTimeout) {
       clearTimeout(this.state.typingTimeout);
    }

    this.setState({
       [e.target.name]: e.target.value,
       typingTimeout: setTimeout(() => {
         this.filter();
       }, 900)
    });
  }

  filter = () => {
    const { fetchAdminsFiltered } = this.props;
    const { filterValue } = this.state;

  //  fetchAdminsFiltered('admins', null, null, filterValue);
    fetchAdminsFiltered(null, null, filterValue);
    this.setState({ currentOrdering: null });
  }

  fetchAdminsFiltered = (e, orderId) => {
    e.stopPropagation();
    const { fetchAdminsFiltered } = this.props;
    const { currentOrdering, isAscending, filterValue } = this.state;

    if (currentOrdering !== orderId) {
      fetchAdminsFiltered(orderId, 'ascending', filterValue);
      this.setState({ currentOrdering: orderId, isAscending: true });
    } else {
      if (isAscending) {
        fetchAdminsFiltered(orderId, 'descending', filterValue);
        this.setState({ currentOrdering: orderId, isAscending: false });
      } else {
        fetchAdminsFiltered(orderId, 'ascending', filterValue);
        this.setState({ currentOrdering: orderId, isAscending: true });
      }
    }
  }

  fetchAdminsFilteredNextPage = (limit) => {
    const { fetchAdminsFilteredNextPage } = this.props;
    const { currentOrdering, isAscending, filterValue } = this.state;

      if (isAscending) {
        fetchAdminsFilteredNextPage('admins', currentOrdering, 'ascending', filterValue, limit);
      } else {
        fetchAdminsFilteredNextPage('admins', currentOrdering, 'descending', filterValue, limit);
      }
  }

  sort = (sortColumn, sortType) => {
    const { fetchAdminsFiltered, sortAdminsData } = this.props;
    const { filterValue } = this.state;

    let ordering = 'ascending';
    let isAscending = true;
    if (sortType === 'desc') {
      ordering = 'descending';
      isAscending = false;
    }

    let orderId;
    if (sortColumn === 'dateCreated') {
      orderId = 'dateCreated';
    } else {
      orderId = sortColumn;
    }

    sortAdminsData('admins', orderId, sortType).then(() => {
      this.setState({ sortType, sortColumn, currentOrdering: orderId, isAscending });
    });

  //  fetchAdminsFiltered('admins', orderId, ordering, filterValue).then(() => {
    //  this.setState({ sortType, sortColumn, currentOrdering: orderId, isAscending });
  //  });
  }


  render() {
    const { admins, user, roles } = this.props;
    const {
      createUserModal,
      changeRoleModal,
      email,
      password,
      confirmPassword,
      showPassword1,
      showPassword2,
      name,
      role,
      deleteUserModal,
      deleteUserErr,
      showNoPermission,
      dontShowPage,
      createAdminErr,
      filterValue,
      sortType,
      sortColumn,
      processing,
    } = this.state;

    if (!user.role || user.roleClass !== 'admin') {
      return (
        <div>
          <Card>
            <CardBody>
              You need admin permissions to view all admins
            </CardBody>
          </Card>
        </div>
      );
    }

    if (dontShowPage) {
      return (
        <div>
          <Card>
            <CardBody>
              You don't have permissions to view admins
            </CardBody>
          </Card>
        </div>
      );
    }

    return (
      <div>
        <Card>
            <CardBody>
                <Button disabled={showNoPermission} className="body-add-button" onClick={this.openCreateUser}>
                  +
                </Button>
                <Row className="body-filters">
                  <Col sm="5">
                    <Input
                      type="text"
                      name="filterValue"
                      id="filterValue"
                      value={filterValue}
                      onChange={this.handleChangeFilter}
                    />
                  </Col>
                </Row>
                <div class="table-responsive">

                <Table
                  style={{ fontSize: 14 }}
                  sortType={sortType}
                  sortColumn={sortColumn}
                  data={admins}
                  virtualized
                  minHeight={600}
                  height={600}
                  onSortColumn={this.sort}
                  shouldUpdateScroll={false}
                  rowKey='uid'
                  rowHeight={46}
                  rowClassName={(rowData) => {
                    return (`${`${(rowData && rowData.newUser) ? ' admin-table-new-user' : ''}`}`)
                  }}
                  onRowClick={(data, e) => {
                    if (e.target && e.target.type && e.target.type === 'button') {
                      console.log('ignore click');
                    }
                  }}
                >
                  <Column width={160} sortable resizable>
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey="nameLower" />
                  </Column>
                  <Column width={170} sortable resizable>
                    <HeaderCell>Email</HeaderCell>
                    <Cell dataKey="emailLower" />
                  </Column>

                  <Column width={170} sortable resizable>
                    <HeaderCell>Last Activity</HeaderCell>
                    <Cell dataKey="lastActivity">
                      {(rowData, rowIndex) => {
                        return (
                          <div>
                            {(rowData.lastActivity) ? (
                              <Moment format="DD-MM-YYYY HH:mm" locale="=uk">
                                {rowData.lastActivity.seconds * 1000}
                              </Moment>
                            ):(null)}
                          </div>
                        )
                      }}
                    </Cell>
                  </Column>

                  <Column width={170} sortable resizable>
                    <HeaderCell>Date Created</HeaderCell>
                    <Cell dataKey="dateCreated">
                      {(rowData, rowIndex) => {
                        return (
                          <div>
                            {(rowData.dateCreated) ? (
                              <Moment format="DD-MM-YYYY" locale="=uk">
                                {rowData.dateCreated}
                              </Moment>
                            ):(null)}
                          </div>
                        )
                      }}
                    </Cell>
                  </Column>

                  <Column width={160} resizable>
                    <HeaderCell>Permission level</HeaderCell>
                    <Cell dataKey="role">
                      {(rowData, rowIndex) => {
                        return (
                          <div>
                            <Button color="link" disabled={showNoPermission} onClick={(e) => this.openChangeRole(e, rowData.uid, rowData.role)}>
                              {rowData.role === 'admin' ? 'Admin' : (rowData.role === 'readOnly' ? 'No Access' : (roles[rowData.role] ? roles[rowData.role].name : null))}
                            </Button>
                          </div>
                        )
                      }}
                    </Cell>
                  </Column>
                </Table>
              {/*  <Button className="admin-table-more-button" onClick={(e) => this.fetchAdminsFilteredNextPage(10)}>
                  Get 10 more
                </Button>
                <Button className="admin-table-more-button" onClick={(e) => this.fetchAdminsFilteredNextPage(100)}>
                  Get 100 more
                </Button>
                <Button className="admin-table-more-button" onClick={(e) => this.fetchAdminsFilteredNextPage('all')}>
                  Get all
                </Button> */}
                </div>
            </CardBody>
        </Card>
        <Modal size="lg" isOpen={createUserModal} toggle={this.closeCreateUser} contentClassName="modal-max-height-page">
          <ModalHeader toggle={this.closeCreateUser}>
            Create new admin
          </ModalHeader>
          <ScrollArea
            speed={0.6}
            smoothScrolling={true}
            className="area"
            contentClassName="content"
            horizontal={false}
            ref={(el) => { this.modalScrollbar = el; }}
          >
          <ModalBody>
          {(!!createAdminErr) && <Alert color="danger">{createAdminErr}</Alert>}
            <Form>
              <Row>
                <Col xs="12">
                  <FormGroup>
                      <Label for="name">
                      Name<div className="mandatory">*</div>
                    </Label>
                    <Input
                      type="text"
                        name="name"
                        id="name"
                        value={name}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label for="email">
                  Email<div className="mandatory">*</div>
                </Label>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  value={email}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="password">Password<div className="mandatory">*</div></Label>
                <Input
                  type={showPassword1}
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  value={password}
                  onChange={this.handleChange}
                />
                {showPassword1 === 'input' ? (
                  <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={() => this.toggleShowPassword(1)}/>
                ):(
                  <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={() => this.toggleShowPassword(1)}/>
                )}

              </FormGroup>
              <FormGroup>
                <Label for="confirmPassword">Confirm Password<div className="mandatory">*</div></Label>
                <Input
                  type={showPassword2}
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="••••••••"
                  value={confirmPassword}
                  onChange={this.handleChange}
                />
                {showPassword2 === 'input' ? (
                  <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={() => this.toggleShowPassword(2)}/>
                ):(
                  <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={() => this.toggleShowPassword(2)}/>
                )}
              </FormGroup>

              <FormGroup>
                  <Label for="role">
                    Permission level<div className="mandatory">*</div>
                  </Label>
                  <Row className="roles-select-row">
                    <Col xs="1" />
                    <Col xs="1" className="roles-select-input">
                      <Input color="success" type="radio" name="role" value={'admin'} checked={role === 'admin'} onChange={this.selectRole} />
                    </Col>
                    <Col xs="3" className="roles-select-name">
                      Admin
                    </Col>
                    <Col xs="7" className="roles-select-description">
                      Main admin role
                    </Col>
                  </Row>
                  <Row className="roles-select-row">
                    <Col xs="1" />
                    <Col xs="1" className="roles-select-input">
                      <Input color="success" type="radio" name="role" value={'readOnly'} checked={role === 'readOnly'} onChange={this.selectRole} />
                    </Col>
                    <Col xs="3" className="roles-select-name">
                      No Access
                    </Col>
                    <Col xs="7" className="roles-select-description">
                      No Access
                    </Col>
                  </Row>
                  {roles && Object.keys(roles).sort((a, b) => { return roles[b].sortValue - roles[a].sortValue }).map(key => (
                    <Row>
                      <Col xs="1" />
                      <Col xs="1" className="roles-select-input">
                        <Input type="radio" name="role" value={key} checked={role === key} onChange={this.selectRole} />
                      </Col>
                      <Col xs="3" className="roles-select-name">
                        {roles[key].name}
                      </Col>
                      <Col xs="7" className="roles-select-description">
                        {roles[key].description}
                      </Col>
                    </Row>
                  ))}
                </FormGroup>
            </Form>
            <div> <div className="mandatory">*</div>Mandatory fields </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.createUser}>
              Invite
              {(processing === 'invite') ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ):(null)}
            </Button>
            <Button color="secondary" onClick={this.closeCreateUser}>Close</Button>
          </ModalFooter>
          </ScrollArea>
        </Modal>
        <Modal size="lg" isOpen={changeRoleModal} toggle={this.closeChangeRole}>
          <ModalHeader toggle={this.closeChangeRole}>
            Change permission level
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="role">
                  Permission level<div className="mandatory">*</div>
                </Label>
                <Row className="roles-select-row">
                  <Col xs="1" />
                  <Col xs="1" className="roles-select-input">
                    <Input color="success" type="radio" name="role" value={'admin'} checked={role === 'admin'} onChange={this.selectRole} />
                  </Col>
                  <Col xs="3" className="roles-select-name">
                    Admin
                  </Col>
                  <Col xs="7" className="roles-select-description">
                    Main admin role
                  </Col>
                </Row>
                <Row className="roles-select-row">
                  <Col xs="1" />
                  <Col xs="1" className="roles-select-input">
                    <Input color="success" type="radio" name="role" value={'readOnly'} checked={role === 'readOnly'} onChange={this.selectRole} />
                  </Col>
                  <Col xs="3" className="roles-select-name">
                    No Access
                  </Col>
                  <Col xs="7" className="roles-select-description">
                    No Access
                  </Col>
                </Row>
                {roles && Object.keys(roles).sort((a, b) => { return roles[b].sortValue - roles[a].sortValue }).map(key => (
                  <Row>
                    <Col xs="1" />
                    <Col xs="1" className="roles-select-input">
                      <Input type="radio" name="role" value={key} checked={role === key} onChange={this.selectRole} />
                    </Col>
                    <Col xs="3" className="roles-select-name">
                      {roles[key].name}
                    </Col>
                    <Col xs="7" className="roles-select-description">
                      {roles[key].description}
                    </Col>
                  </Row>
                ))}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.changeRole}>
              Update
              {(processing === 'update-update') ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ):(null)}
            </Button>
            <Button color="secondary" onClick={this.closeChangeRole}>Close</Button>
            <Button disabled={showNoPermission} color="danger" onClick={this.toggleDeleteUser}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={deleteUserModal} toggle={this.closeDeleteUser}>
          <ModalHeader toggle={this.closeDeleteUser}>
            Are you sure?
          </ModalHeader>
          <ModalBody>
            {(!!deleteUserErr) && <Alert color="danger">{deleteUserErr}</Alert>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.deleteUser}>
              Delete User
              {(processing === 'update-delete') ? (
                <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
              ):(null)}
            </Button>
            <Button color="secondary" onClick={this.closeDeleteUser}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  admins: state.admin.admins || {},
  roles: state.admin.adminsRoles || {},
});

const mapDispatchToProps = {
  createU: createAdmin,
  deleteU: deleteAdmin,
  getUser: getUserData,
  getRoles: getAllAdminRoles,
  changeRole: changeUserRole,
  fetchAdminsFiltered: getAdminsFiltered,
  sortAdminsData: sortData,
  fetchAdminsFilteredNextPage: getDataFilteredNextPage,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admins));
