import { DO_LOGIN, DO_LOGOUT, GET_USER_DATA, STRIPE_LOGIN_LINK, STRIPE_ACCOUNT_STATUS } from '../../actions';
import { auth } from '../../lib/firebase';

const defaultState = {
  isLoggedIn: auth.currentUser ? true : false,
  loginTime: auth.currentUser ? auth.currentUser.metadata.lastSignInDate : null,
  userData: {},
  userDataFetched: false,
};

const userReducer = (state=defaultState, action) => {
    switch(action.type) {
      case DO_LOGIN: {
        console.log('DO_LOGIN');
        if (action.doNotUpdateLastLogin) {
          return { ...state, isLoggedIn: true };
        }
        return { ...state, isLoggedIn: true, loginTime: Date.now() };
      }
        case DO_LOGOUT:
            return { ...defaultState, isLoggedIn: false };
        case GET_USER_DATA:
            return { ...state, userData: action.payload, isLoggedIn: action.has2fa, userDataFetched: true };
        case STRIPE_LOGIN_LINK:
            return { ...state, stripeLoginLink: action.payload };

        case STRIPE_ACCOUNT_STATUS:
        return { ...state, stripeAccountStatus: action.payload, stripeChargesEnabled: action.charges_enabled, stripePayoutsEnabled: action.payouts_enabled, stripeDataFetched: true };
        default:
            return state;
    }
};

export default userReducer;
