import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Row, Col, Card, CardBody, Table, Button, Input, Label, Form, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane, Alert } from 'reactstrap';

import { connect } from 'react-redux';

import {
  updateAdminSettings,
  updateStepprPricePerCard,
  updateStepprVATPerCard,
  getCardQuote,
  getDeliveryDescription,
  updateDeliveryDescription,
  setDeliveryDescription,
} from '../../../../actions/admin';

class GreetingCardsPrice extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    fetchCardQuote: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
    updateDescription: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      stepprPrice: null,
      descriptions: {},
    };
  }

  componentDidMount() {
    const { admin, user, stepprPrice, history, fetchCardQuote, getDescription } = this.props;

    console.log('GreetingCardsPrice componentDidMount ', { stepprPrice });

    fetchCardQuote();
    getDescription().then((res) => {
      console.log('getDescription res', { res });
      if (res.descriptions) {
        this.setState({ descriptions: res.descriptions });
      }
    });

    this.timer = null;

    if (stepprPrice) {
      this.setState({ stepprPrice: stepprPrice });
    }
  }

  handleChange = (e) => {
    const { value, name } = e.target;

    console.log('handleChange', { value, name });

    this.setState({ [name]: value });
  }

  changePrice = () => {
    const { changePrice } = this.props;
    const { stepprPriceState } = this.state;

    if (stepprPriceState) {
      changePrice(stepprPriceState).then(() => {
      //  this.setState({ stepprPriceState });
      });
    }
  }

  changeVAT = () => {
    const { changeVAT } = this.props;
    const { cardVATState } = this.state;

    if (cardVATState) {
      changeVAT(cardVATState).then(() => {
      //  this.setState({ stepprPriceState });
      });
    }
  }

  handleChangeDescription = (e, id) => {
    const { updateDescription } = this.props;
    const { descriptions } = this.state;

    clearTimeout(this.timer);

    const { value, name } = e.target;

    console.log('handleChangeDescription', { id, descriptions });
    console.log(value);

    this.setState({ descriptions: { ...descriptions, [id]: e.target.value }});

    this.timer = setTimeout(() => {
      const newDescriptions = this.state.descriptions;

      console.log('start update', { newDescriptions: newDescriptions });

      if (newDescriptions) {
        updateDescription(newDescriptions).then((res) => {
          if (res && res.descriptions) {
            this.setState({ descriptions: res.descriptions });
          }
        })
      }
    }, 1200);

  //  this.timer = setTimeout(updateDescription(descriptions).then((res) => {
  //    if (res && res.descriptions) {
  //      this.setState({ descriptions: res.descriptions });
  //    }
  //  }), 1250);
  }

  render() {
    const { user, cardTemplates, cardCategories, stepprPrice, cardQuote, cardProductData, cardVAT, showNoPermission } = this.props;
    const {
      stepprPriceState,
      descriptions,
      cardVATState,
    } = this.state;

    console.log('cardQuote', { cardQuote });

    return (
      <div>
        <div>Bespoke email (£)</div>
        <Row>
          <Col sm="3">
            POD Company
          </Col>
          <Col sm="3">
            Prodigi
          </Col>
          <Col sm="6" />
        </Row>
        <Row>
          <Col sm="3">
            Card size (in)
          </Col>
          <Col sm="3">
          {(cardProductData && cardProductData.productDimensions) ? (
            <>
              {cardProductData.productDimensions.units === 'in' ? (
                <>
                  {`${Math.round(cardProductData.productDimensions.width)}x${Math.round(cardProductData.productDimensions.height)}"`}
                </>
              ):(null)}
              {cardProductData.productDimensions.units === 'cm' ? (
                <>
                  {`${Math.round(cardProductData.productDimensions.width / 2.54 * 10) / 10}x${Math.round(cardProductData.productDimensions.height / 2.54 * 10) / 10}"`}
                </>
              ):(null)}
            </>
          ):(null)}
          </Col>
          <Col sm="6" />
        </Row>

        <Row>
          <Col sm="3">
            Price per card (£)
          </Col>
          <Col sm="3">
          {(cardQuote && cardQuote[0] &&  cardQuote[0].costSummary) ? (
            <>
              {cardQuote[0].costSummary.items && cardQuote[0].costSummary.items.amount ? (
                <>
                  {`${parseFloat(cardQuote[0].costSummary.items.amount).toFixed(2)}`}
                </>
              ):(null)}
            </>
          ):(null)}
          </Col>
          <Col sm="6" />
        </Row>

        <Row>
          <Col sm="3">
            Steppr price per card (£)
          </Col>
          <Col sm="3">
            <Input
              type="text"
              name="stepprPriceState"
              id="stepprPriceState"
              value={stepprPriceState || stepprPrice}
              onChange={this.handleChange}
            />
          </Col>
          <Col sm="3">
            <Button disabled={showNoPermission} onClick={this.changePrice}>
              Update
            </Button>
          </Col>
          <Col sm="3" />
        </Row>
        <Row>
          <Col sm="3">
            Greetings card VAT(%)
          </Col>
          <Col sm="3">
            <Input
              type="text"
              name="cardVATState"
              id="cardVATState"
              value={cardVATState || cardVAT}
              onChange={this.handleChange}
            />

          </Col>
          <Col sm="3">
            <Button disabled={showNoPermission} onClick={this.changeVAT}>
              Update
            </Button>
          </Col>
          <Col sm="3" />
        </Row>



        <Row>
          <Col sm="3" className="text-bold">
            Delivery option
          </Col>
          <Col sm="3" className="text-bold">
            Price (£)
          </Col>
          <Col sm="6" className="text-bold">
            Description
          </Col>
        </Row>
        {(cardQuote && cardQuote.length) ? (cardQuote.map(quote => (
          <Row>
            <Col sm="3">
            {(quote.shipmentMethod) ? (
              quote.shipmentMethod
            ):(null)}
            </Col>
            <Col sm="3">
              {(quote.costSummary) ? (
                <>
                {/*  {`${(parseFloat(cardQuote.costSummary.items.amount) + parseFloat(cardQuote.costSummary.shipping.amount)).toFixed(2)}`} */}

                  {`${parseFloat(quote.costSummary.shipping.amount).toFixed(2)}`}
                </>
              ):(null)}
            </Col>
            <Col sm="6">
            {/*  <Input type="text" onChange={(e) => this.handleChangeDescription(e, quote.shipmentMethod)} />  */}
              <Input
                type="text"
                name={`description-${quote.shipmentMethod}`}
                id={`description-${quote.shipmentMethod}`}
                value={descriptions[quote.shipmentMethod]}
                onChange={(e) => this.handleChangeDescription(e, quote.shipmentMethod)}
              />
            </Col>
          </Row>
        ))) : (null)}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  stepprPrice: state.admin.appSettings ? state.admin.appSettings.stepprCardPrice : null,
  cardQuote: state.admin.cardQuote || {},
  cardQuoteDescriptions: state.admin.cardQuoteDescriptions || {},
  cardProductData: state.admin.cardProductData || {},
});

const mapDispatchToProps = {
  changePrice: updateStepprPricePerCard,
  changeVAT: updateStepprVATPerCard,
  fetchCardQuote: getCardQuote,
  getDescription: getDeliveryDescription,
  updateDescription: updateDeliveryDescription,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GreetingCardsPrice));

// export default Dashboard;
