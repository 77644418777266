
const formattedNumber = (n, toFixed) => {
  if (toFixed){
    return (n.toLocaleString(undefined, {
      minimumFractionDigits: toFixed,
      maximumFractionDigits: toFixed
    }));
  } else {
    return (n.toLocaleString());
  }
};

export default formattedNumber;
